import React from 'react';

import { css } from '@emotion/css';

import { Tooltip } from 'components/Tooltip';

export function EllipsisTruncatedLabel({ maxWidth, label }: { maxWidth: string; label: string }) {
  // avoid creating tooltip if label fits within maxWidth characters
  if (maxWidth.endsWith('ch')) {
    const maxChars = parseInt(maxWidth.slice(0, -2), 10);
    if (label.length <= maxChars) {
      return <>{label}</>;
    }
  }

  return (
    <Tooltip title={label}>
      <div
        className={css`
          max-width: ${maxWidth};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {label}
      </div>
    </Tooltip>
  );
}
