import React from 'react';

import { useHistory } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';
import { PostCreateTenantBodyParams } from '@recurrency/core-api-schema/dist/tenants/postCreateTenant';
import { Form, notification, Radio, Space } from 'antd';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { defaultFormTailLayout, InputFormItem, responsiveFormLayout } from 'components/FormItems';
import { PageHeader } from 'components/PageHeader';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes } from 'utils/routes';

export const TenantNewPage = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const onSubmit = async (bodyParams: PostCreateTenantBodyParams) => {
    try {
      bodyParams.databaseSchema = bodyParams.databaseSchema.replace(/[^a-z0-9]/gi, '').toLowerCase();
      const response = await coreApiFetch(schemas.tenants.postCreateTenant, {
        bodyParams,
      });
      notification.success({ message: 'New tenant created.' });
      if (response?.data?.id) {
        history.push(routes.internal.tenantDetails(response.data.id));
      } else {
        history.push(routes.internal.tenantList());
      }
    } catch (err) {
      captureAndShowError(err, `Error while creating tenant`);
    }
  };

  return (
    <Container>
      <PageHeader title="New Tenant" onBack={() => history.push(routes.internal.tenantList())} />
      <Form.Provider>
        <Form {...responsiveFormLayout} name="taskForm" form={form} onFinish={onSubmit} onError={console.error}>
          <InputFormItem label="Name" name="name" rules={[{ required: true, message: 'Please add a name.' }]} />
          <InputFormItem
            name="databaseSchema"
            label="Postgres DB Schema"
            rules={[
              {
                required: true,
                max: 50,
                type: 'regexp',
                pattern: new RegExp(/^[a-z]+/g),
                message: 'Please add a database schema with [a-z] characters, max 50',
              },
            ]}
          />
          <Form.Item label="ERP Type" name="erpType" rules={[{ required: true, message: 'Please select a type.' }]}>
            <Radio.Group>
              <Space direction="vertical">
                {Object.values(IntegratedErps).map((erp) => (
                  <Radio key={erp} value={erp}>
                    {erp}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          <InputFormItem
            label="Company Name"
            name="companyName"
            rules={[{ required: true, message: 'Please add a company name.' }]}
          />

          <InputFormItem label="Slug" name="slug" rules={[{ required: true, message: 'Please add a slug.' }]} />

          <Form.Item {...defaultFormTailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Form.Provider>
    </Container>
  );
};
