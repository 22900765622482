import React, { useRef } from 'react';

import { TableProps } from 'antd/lib/table';

import { loaderSpinProps } from 'components/Loaders';

import * as Styled from './ResponsiveTable.style';

export type Props = TableProps<any>;

export const ResponsiveTable = (props: Props): JSX.Element => {
  // When Antd Table switches between loading -> data -> loading states
  // data goes empty, which makes columns have a big layout shift, which is jarring to the user.
  // We preserve last value of data and use that when table is re-loading.
  // User can't interact with old table, but still sees a grayed out version of old data.
  const prevDataSourceRef = useRef<Obj[]>();
  const isFilledDataSource = props.dataSource && Array.isArray(props.dataSource) && props.dataSource.length > 0;
  if (isFilledDataSource) {
    // @ts-expect-error - react type overloading incorrectly detects this as readonly ref
    prevDataSourceRef.current = props.dataSource;
  }
  if (props.loading) {
    const dataSource = !isFilledDataSource ? prevDataSourceRef.current : props.dataSource;
    props = { ...props, dataSource, loading: loaderSpinProps };
  }

  return (
    <Styled.TableWrapper>
      <Styled.Table {...props} />
    </Styled.TableWrapper>
  );
};
