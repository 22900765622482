import React from 'react';

import { useHistory, Link, Redirect } from 'react-router-dom';

import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  DownOutlined,
  FileTextOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Menu, Modal, notification } from 'antd';

import { QuoteOrderLineNotes } from 'pages/orders/orders/OrderDetailsPage/SSFC/QuoteOrderLineNotes';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { Dropdown } from 'components/Dropdown';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { addressPropertyListItem } from 'components/recipes/detailPage/propertyListItemUtils';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import {
  formatDate,
  dashesIfEmpty,
  EMPTY_VALUE_DASHES,
  formatAddressMultiLine,
  getErpName,
  formatUSD,
  formatPhoneNumber,
} from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes, usePathParams, IdPathParams } from 'utils/routes';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { getHashStateFromQuote } from '../quoteUtils';
import { QuoteDocumentsTable } from './tabs/QuoteDocumentsTable';
import { QuoteLinesTable } from './tabs/QuoteLinesTable';

export const QuoteDetailsPage = () => {
  const { id: salesQuoteId } = usePathParams<IdPathParams>();

  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();

  const {
    data: quote,
    error: quoteError,
    isLoading: isQuoteLoading,
  } = useCoreApi(schemas.salesQuotes.getSalesQuoteDetails, {
    pathParams: { salesQuoteId },
  });

  // Only redirect quote -> order for p21, not sapb1
  // In sapb1, quote and order can have same DocNum, but could could be entirely different entities
  if (!isQuoteLoading && !quote && isTenantErpTypeP21(activeTenant.erpType)) {
    return <Redirect to={routes.orders.orderDetails(salesQuoteId)} />;
  }

  if (quoteError) {
    return <CenteredError error={quoteError} />;
  }

  if (isQuoteLoading || !quote) {
    return <CenteredLoader />;
  }

  const headerSections: DetailPageSection[] = [
    {
      title: 'Customer',
      rows: [
        [
          {
            icon: <UserOutlined />,
            label: 'Customer',
            value: <Link to={routes.sales.customerDetails(quote.customerId)}>{quote.customerName}</Link>,
            id: quote.customerId,
          },
          {
            icon: <UserOutlined />,
            label: 'Contact',
            value: dashesIfEmpty(quote.customerContact?.contactName),
          },
          { label: 'PO No.', value: dashesIfEmpty(quote.customerPORef), icon: <FileTextOutlined /> },
          {
            icon: <UserOutlined />,
            label: 'Sales Rep',
            value: dashesIfEmpty(quote.salesRepName),
            id: quote.salesRepId,
          },
          isTenantErpTypeP21(activeTenant.erpType)
            ? {
                label: 'Terms',
                value: dashesIfEmpty(quote.terms),
                icon: <FileTextOutlined />,
              }
            : undefined,
        ].filter(truthy),
      ],
    },
    {
      title: 'Quote Configuration',
      rows: [
        [
          {
            icon: <CalendarOutlined />,
            label: 'Quote Date',
            value: formatDate(quote.quoteDate),
          },
          { icon: <CalendarOutlined />, label: 'Due Date', value: formatDate(quote.dueDate) },
          { icon: <CalendarOutlined />, label: 'Expiration Date', value: formatDate(quote.expirationDate) },
          {
            label: 'Approved',
            value: quote.approved ? 'Yes' : 'No',
            icon: quote.approved ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
          },
          isTenantErpTypeP21(activeTenant.erpType)
            ? { label: 'Taker', value: dashesIfEmpty(quote.takerName), id: quote.takerId, icon: <UserOutlined /> }
            : undefined,
          {
            label: 'Total Price',
            value: quote.totalPrice ? formatUSD(quote.totalPrice, true) : EMPTY_VALUE_DASHES,
            icon: <DollarOutlined />,
          },
          quote.class1Id
            ? {
                label: 'Class 1',
                value: quote.class1Name,
              }
            : null,
        ].filter(truthy),
      ],
    },
    {
      title: 'Line Notes (Pinned)',
      children: (
        <div>
          <QuoteOrderLineNotes orderLines={quote.lines} pinned />
        </div>
      ),
    },
    {
      title: 'Logistics',
      rows: [
        [
          {
            label: 'Sales Location',
            value: quote.locationId
              ? `${quote.locationName}${quote.willCall ? ` (Will Call)` : ``}`
              : EMPTY_VALUE_DASHES,
            id: quote.locationId,
          },
          // Freights aren't supported by SAPB1
          isTenantErpTypeP21(activeTenant.erpType)
            ? {
                label: 'Freight Type',
                value: dashesIfEmpty(quote.freightName),
                id: quote.freightUid,
              }
            : undefined,
          {
            label: 'Carrier',
            value: dashesIfEmpty(quote.carrierName),
            id: quote.carrierId,
          },
        ].filter(truthy),
        [
          addressPropertyListItem({
            label: 'Bill to',
            value: formatAddressMultiLine({
              name: quote.customerName,
              address1: quote.billToAddress?.address1,
              address2: quote.billToAddress?.address2,
              address3: quote.billToAddress?.address3,
              city: quote.billToAddress?.city,
              state: quote.billToAddress?.state,
              zip: quote.billToAddress?.postalCode,
            }),
            id: quote.customerId,
          }),
          addressPropertyListItem({
            label: 'Ship To',
            value: quote.shipToAddress
              ? formatAddressMultiLine({
                  name: quote.shipToAddress?.addressName,
                  address1: quote.shipToAddress?.address1,
                  address2: quote.shipToAddress?.address2,
                  city: quote.shipToAddress?.city,
                  state: quote.shipToAddress?.state,
                  zip: quote.shipToAddress?.postalCode,
                  phone: quote.shipToPhone ? formatPhoneNumber(quote.shipToPhone) : undefined,
                })
              : quote.shipToAddressFormatted,
            id: quote.shipToId,
          }),
          quote.deliveryInstructions && quote.deliveryInstructions.length > 0
            ? {
                label: 'Delivery Instructions',
                value: <div style={{ whiteSpace: 'pre-line' }}>{quote.deliveryInstructions}</div>,
              }
            : null,
        ].filter(truthy),
      ],
    },
  ];

  const handleConvertQuoteToOrder = async () => {
    try {
      const shouldProceed = await new Promise((resolve) => {
        Modal.confirm({
          title: 'Convert to Order',
          content: (
            <>
              This quote will be permanently converted to an order in {getErpName(activeTenant.erpType)} and you will no
              longer be able to edit this quote in Recurrency. Please confirm you wish to proceed.
            </>
          ),
          okText: 'Convert',
          cancelText: 'Cancel',
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          },
        });
      });

      if (!shouldProceed) {
        return;
      }

      track(TrackEvent.Orders_ConvertQuoteToOrder, {});
      const { data: response } = await coreApiFetch(schemas.salesQuotes.postConvertSalesQuote, {
        pathParams: { salesQuoteId },
      });
      notification.success({ message: `Successfully converted to order.` });
      if (response.warnings.length) {
        notification.warning({
          message: `Warning`,
          description: (
            <div
              className={css`
                white-space: pre-wrap;
              `}
            >
              {response.warnings.join('\n')}
            </div>
          ),
        });
      }

      history.push(routes.orders.orderDetails(salesQuoteId));
    } catch (err) {
      captureAndShowError(err, `Error while converting quote to order`, {
        renderDescription: (errorDescription) => (
          <>
            {errorDescription}
            <br />
            Try again or click 'Copy as New Order.'
          </>
        ),
      });
    }
  };

  return (
    <Container>
      <PageHeader
        title={salesQuoteId}
        copyable
        entity={{ kind: 'Quote', badge: <ErpOrderStatusBadge erpOrderStatus={quote.status} /> }}
        headerActions={
          <>
            {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersConvertQuoteToOrder) && (
              <AsyncButton type="primary" onClick={handleConvertQuoteToOrder}>
                Convert to Order
              </AsyncButton>
            )}
            {(shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) ||
              shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote)) && (
              <Dropdown
                overlay={
                  <Menu>
                    {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) && (
                      <Menu.Item
                        onClick={() => {
                          track(TrackEvent.Nav_CtaClick, {
                            ctaName: CtaName.NewQuote,
                            ctaType: CtaType.Button,
                          });
                          history.push(routes.orders.quoteNew(getHashStateFromQuote(quote)));
                        }}
                      >
                        New Quote
                      </Menu.Item>
                    )}
                    {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) && (
                      <Menu.Item
                        onClick={() => {
                          track(TrackEvent.Nav_CtaClick, {
                            ctaName: CtaName.NewOrder,
                            ctaType: CtaType.Button,
                          });
                          history.push(routes.orders.orderNew(getHashStateFromQuote(quote)));
                        }}
                      >
                        New Order
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  Copy as <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </>
        }
      />
      <DetailPageSections sections={headerSections} />
      <NavTabs
        tabs={[
          { header: 'Quote Lines', content: <QuoteLinesTable quoteLines={quote.lines || []} /> },
          { header: 'Documents', content: <QuoteDocumentsTable salesQuoteId={quote.salesQuoteId} /> },
        ]}
      />
    </Container>
  );
};
