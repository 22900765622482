import React, { useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { CustomerPaymentMethodDTO } from '@recurrency/core-api-schema/dist/payments/getCustomerPaymentMethods';

import { AsyncSelect, tableFilterSelectClassName } from 'components/AsyncSelect';
import { UseAsyncSelectProps, fuzzyFilterAndHighlight } from 'components/AsyncSelect/useAsyncSelectProps';

import { useCoreApi } from 'hooks/useApi';

import { capitalize } from 'utils/formatting';

import { ISODateStr } from 'types/hash-state';

import './styles/NewPaymentMethodStyle.css';

export interface PaymentsFormData {
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  label: string;
  fingerprint: string;
  tenantCustomerId: string;
  tenantCustomerName: string;
  created: ISODateStr;
  updated: ISODateStr;
  enabled: boolean;
  orderTotalAmount: string;
  id: string;
  customerId: string;
  name: string;
  type: string;
  selectedOption: any;
  last4: string;
}

export interface PaymentInfoModalProps {
  initialValues: Partial<PaymentsFormData>;
  selectedValue: Maybe<PaymentMethodOption>;
  paymentMethodsInSession: Maybe<PaymentMethodOption[]>;
  onPaymentMethodSelect: (selectedValue: PaymentMethodOption) => void;
  onPaymentMethodClear: () => void;
}

export type PaymentMethodOption = {
  value: string;
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  created: string;
  updated: string;
  processorPaymentMethodId: string;
  processorCustomerId: string;
  name: string;
  fingerprint: string;
  type: string;
  last4: string;
  saveForFutureUse: boolean;
  iin: string;
  postalCode: string;
};

export const PaymentMethodSelect = ({
  initialValues,
  selectedValue,
  paymentMethodsInSession,
  onPaymentMethodSelect,
  onPaymentMethodClear,
}: PaymentInfoModalProps) => {
  const paymentMethodsSelectProps = usePaymentMethodsSelectProps(
    initialValues.tenantCustomerId!,
    'card', // currently only support credit card
    paymentMethodsInSession,
  );

  let customerPartialId = initialValues.tenantCustomerId || 'Unknown Customer';
  const index = customerPartialId.indexOf('||');
  customerPartialId = index !== -1 ? customerPartialId.substring(0, index) : customerPartialId;

  return (
    <AsyncSelect
      style={{ flexGrow: 1, padding: 0 }}
      className={tableFilterSelectClassName}
      selectProps={paymentMethodsSelectProps}
      entityPlural="payment methods"
      allowClear
      onClear={onPaymentMethodClear}
      onSelect={(_, option: any) => {
        onPaymentMethodSelect(option);
      }}
      value={selectedValue && selectedValue.value}
    />
  );
};

export function usePaymentMethodsSelectProps(
  tenantCustomerUid: string,
  paymentType?: string,
  paymentMethodsInSession?: Maybe<PaymentMethodOption[]>,
): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading, reload } = useCoreApi(schemas.payments.getCustomerPaymentMethods, {
    queryParams: { filter: { customerId: tenantCustomerUid, type: paymentType || undefined } },
  });

  // For the same credit card number, which shares the same fingerprint, we will only show the most recent card
  // NOTE: we are using the card fingerprint as the unique key for the option
  const items: CustomerPaymentMethodDTO[] = data?.items || [];
  items.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
  const options: PaymentMethodOption[] = [];
  const cardFingerprints = new Set();

  // fingerprint is used to uniquely identifies a particular credit card number.
  // we use this attribute to check whether two card have the same card number,
  // add paymentMethodsInSession to options
  if (paymentMethodsInSession) {
    paymentMethodsInSession.forEach((paymentMethod) => {
      if (!cardFingerprints.has(paymentMethod.fingerprint)) {
        cardFingerprints.add(paymentMethod.fingerprint);
        options.push(paymentMethod);
      }
    });
  }

  // add customerPaymentMethodDTOs to options
  items.forEach((paymentMethod) => {
    const card = paymentMethod.card!;
    if (!cardFingerprints.has(card.fingerprint)) {
      cardFingerprints.add(card.fingerprint);
      const option: PaymentMethodOption = customerPaymentMethodToOption(paymentMethod, false);
      options.push(option);
    }
  });

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
    reload,
  };
}

export interface PaymentInfo {
  orderNo: string;
  invoiceNo: string;
  customerId: string;
  customerName: string;
  contactId: string;
  contactName: string;
  companyId: string;
  companyName: string;
  shipToId: string;
  shipToName: string;
  requestedDate: string;
  rmaExpirationDate: string;
  salesSubTotal: string;
  taxTotal: string;
  cfTotalDue: string;
  totalFreight: string;
  totalOtherCharge: string;
  downpayment: string;
  downpaymentPaid: string;
}

export function customerPaymentMethodToOption(
  paymentMethod: CustomerPaymentMethodDTO,
  saveForFutureUse: boolean,
): PaymentMethodOption {
  const card = paymentMethod.card!;
  const { billingDetails } = paymentMethod;
  const optionLabel = `${capitalize(card.brand)}-${card.last4} (Exp. ${card.expirationMonth}/${card.expirationYear})`;

  const option: PaymentMethodOption = {
    value: optionLabel,
    last4: card.last4,
    brand: card.brand,
    expirationMonth: card.expirationMonth,
    expirationYear: card.expirationYear,
    created: paymentMethod.created,
    updated: paymentMethod.updated || paymentMethod.created,
    processorPaymentMethodId: paymentMethod.id,
    processorCustomerId: paymentMethod.customer || '',
    name: billingDetails?.name || '',
    fingerprint: card.fingerprint || '',
    type: paymentMethod.type || '',
    iin: card.iin || '',
    saveForFutureUse,
    postalCode: billingDetails?.address?.postalCode || '',
  };
  return option;
}
