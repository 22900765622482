import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import {
  SearchParams as TypesenseSearchParams,
  SearchResponse as TypesenseSearchResponse,
} from 'typesense/lib/Typesense/Documents';
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch';

import { getGlobalAppState } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { isObjEmpty } from 'utils/object';

import {
  SearchRequest,
  DEFAULT_HITS_PER_PAGE,
  SearchResponse,
  FacetValues,
  MAX_VALUES_PER_FACET,
  GetAllRecordsRequest,
} from './types';

/** typesense support max 250 hits per page */
const MAX_HITS_PER_PAGE = 250;
/** see https://typesense.org/docs/0.24.0/api/federated-multi-search.html#multi-search-parameters  */
const MAX_SEARCHES_PER_MULTI_SEARCH = 50;

export interface ObjectWithID {
  id: string;
}

const searchableFieldsByIndex: Partial<Record<SearchIndexName, string[]>> = {
  [SearchIndexName.Customers]: [
    'customer_id',
    'customer_name',
    'phys_address1',
    'phys_city',
    'phys_state',
    'phys_postal_code',
    'central_phone_number',
    'email_address',
  ],
  [SearchIndexName.Items]: ['item_id', 'item_desc', 'extended_desc', 'keywords', 'short_code', 'customer_part_ids'],
  [SearchIndexName.Vendors]: ['vendor_id', 'vendor_name'],
  [SearchIndexName.ShipTos]: ['ship_to_id', 'ship_to_name', 'ship_to_address', 'ship_to_phone'],
  [SearchIndexName.SalesOrders]: ['order_no', 'customer_name', 'customer_po_ref'],
  [SearchIndexName.PurchaseOrders]: ['po_no', 'vendor_desc', 'supplier_desc'],
  [SearchIndexName.Planning]: ['item_id', 'item_name', 'item_desc', 'item_group', 'short_code'],
  [SearchIndexName.Forecasts]: ['item_id', 'item_name', 'item_desc', 'item_group', 'short_code'],
};

export async function multiSearchTypesenseCollection(searches: SearchRequest[]): Promise<Array<SearchResponse<Any>>> {
  const {
    typesenseSearchClient,
    activeTenant: { databaseSchema },
  } = getGlobalAppState();

  const queries: MultiSearchRequestSchema[] = searches.map((options) => ({
    collection: getCollectionId(databaseSchema, options.indexName),
    ...convertToTypesenseSearchParams(options),
  }));

  const response = await typesenseSearchClient.multiSearch.perform({
    searches: queries,
  });

  return response.results.map(convertToSearchResponse);
}

export async function searchTypesenseCollectionForFacetValues(
  facetField: string,
  facetQuery: string,
  searchRequest: SearchRequest,
): Promise<FacetValues> {
  const {
    typesenseSearchClient,
    activeTenant: { databaseSchema },
  } = getGlobalAppState();

  const collectionId = getCollectionId(databaseSchema, searchRequest.indexName);
  const facetSearchRequest: SearchRequest = {
    ...searchRequest,
    facetFields: [facetField],
    hitsPerPage: 0,
    page: 0,
  };

  const response = await typesenseSearchClient
    .collections(collectionId)
    .documents()
    .search(
      {
        ...convertToTypesenseSearchParams(facetSearchRequest),
        facet_query: `${facetField}:${facetQuery}`,
      },
      {},
    );

  return response.facet_counts?.[0].counts || [];
}

export async function getAllRecordsInTypesenseCollection<RecordT>(request: GetAllRecordsRequest): Promise<RecordT[]> {
  const hits: RecordT[] = [];

  const searchRequest = {
    ...request,
    hitsPerPage: MAX_HITS_PER_PAGE,
    page: 0,
  };

  // get first response to get total pages
  const [firstResponse] = await multiSearchTypesenseCollection([searchRequest]);
  hits.push(...firstResponse.hits);

  // iterate through the pages
  const requests: SearchRequest[] = [];
  for (let page = 1; page < firstResponse.nbPages; ++page) {
    requests.push({ ...searchRequest, page });

    const isLastPage = page === firstResponse.nbPages - 1;
    const isAtMultiSearchLimit = requests.length === MAX_SEARCHES_PER_MULTI_SEARCH;
    if (isLastPage || isAtMultiSearchLimit) {
      const responses = await multiSearchTypesenseCollection(requests);
      hits.push(...responses.flatMap((r) => r.hits));
      requests.splice(0, requests.length); // clear requests
    }
  }

  return hits;
}

function convertToTypesenseSearchParams(searchRequest: SearchRequest): TypesenseSearchParams {
  // see https://typesense.org/docs/0.25.1/api/search.html#search-parameters
  const searchParams: TypesenseSearchParams = {
    // search settings
    prioritize_token_position: true,
    prioritize_exact_match: true,
    text_match_type: 'max_weight',
    facet_query_num_typos: 0,

    // query params
    q: searchRequest.query || '',
    query_by: (searchableFieldsByIndex[searchRequest.indexName] ?? []).join(','),
    include_fields: searchRequest.fieldsToRetrieve?.join(','),
    facet_by: searchRequest.facetFields?.join(','),
    max_facet_values: searchRequest.maxValuesPerFacet ?? MAX_VALUES_PER_FACET,
    sort_by: searchRequest.sortBy ? `${searchRequest.sortBy.field}:${searchRequest.sortBy.order}` : undefined,
    per_page: searchRequest.hitsPerPage ?? DEFAULT_HITS_PER_PAGE,
    page: (searchRequest.page ?? 0) + 1,
    filter_by:
      [
        searchRequest.filters && !isObjEmpty(searchRequest.filters)
          ? `${Object.entries(searchRequest.filters)
              .map(([field, vals]) => `${field}:=[${vals.map(escapeFilterValue).join(',')}]`)
              .join(' && ')}`
          : undefined,
      ]
        .filter(truthy)
        .join(' && ') || undefined,
  };
  return searchParams;
}

function getCollectionId(databaseSchema: string, indexName: SearchIndexName): string {
  return `${databaseSchema}_${indexName}`;
}

function escapeFilterValue(value: string): string {
  // don't backtick escape true/false/numbers
  // see https://github.com/typesense/typesense-instantsearch-adapter/blob/57c9a5413a9424c04f86295f957898f9cbb66878/src/SearchRequestAdapter.js#L185-L191
  if (typeof value === 'boolean' || typeof value === 'number') {
    return `${value}`;
  }

  if (/^(true|false|[0-9]+|\w+)$/.test(value)) {
    return value;
  }

  // typesense uses backtick escape
  return `\`${value.replaceAll('`', '\\`')}\``;
}

function convertToSearchResponse<ObjectT>(
  typesenseResponse: TypesenseSearchResponse<ObjectT>,
): SearchResponse<ObjectT> {
  checkForErrorResponse(typesenseResponse);

  const searchResponse: SearchResponse<ObjectT> = {
    hits: typesenseResponse.hits?.map((h) => h.document) ?? [],
    nbHits: typesenseResponse.found,
    page: typesenseResponse.page - 1, // algolia is 0 based
    // typesense doesn't pass total pages, so we have to calculate it
    nbPages: Math.ceil(typesenseResponse.found / typesenseResponse.request_params.per_page!),
    hitsPerPage: typesenseResponse.request_params.per_page || 1,
    facets: Object.fromEntries(
      (typesenseResponse.facet_counts ?? []).map((fieldCounts) => [fieldCounts.field_name, fieldCounts.counts]),
    ),
  };
  return searchResponse;
}

function checkForErrorResponse(response: any): void {
  // typesense client has a bug where errors come in as a valid response
  // so we have to check for them manually
  if (typeof response.error === 'string') {
    throw new Error(response.error);
  }
}
