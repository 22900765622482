import React, { useState } from 'react';

import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { colors } from 'theme/colors';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { truthy } from 'utils/boolean';

export interface PanelContent {
  title: string;
  tooltip?: string;
  content: React.ReactNode;
}

export function CollapsePanels({
  panels,
  arrowPosition = 'left',
}: {
  panels: Array<PanelContent | null>;
  arrowPosition?: 'left' | 'right';
}) {
  return (
    <div>
      {panels.filter(truthy).map((panel, idx) => (
        <CollapsePanel key={idx} panel={panel} arrowPosition={arrowPosition} />
      ))}
    </div>
  );
}

function CollapsePanel({ panel, arrowPosition }: { panel: PanelContent; arrowPosition: 'left' | 'right' }) {
  const [showContent, setShowContent] = useState(true);

  const showHideContentArrow = (
    <div
      className={css`
        cursor: pointer;
      `}
      onClick={() => setShowContent(!showContent)}
    >
      {showContent ? <DownOutlined /> : <RightOutlined />}
    </div>
  );

  return (
    <div
      className={css`
        border-top: 1px solid ${colors.neutral[300]};
        margin-top: 12px;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          padding: 12px 0 8px 0;
        `}
      >
        {arrowPosition === 'left' && showHideContentArrow}
        <div
          className={css`
            cursor: pointer;
            font-weight: 700;
            flex: 1;
          `}
          onClick={() => setShowContent(!showContent)}
        >
          <ConditionalWrapper
            condition={!!panel.tooltip}
            wrapper={(children: React.ReactElement) => <InfoTooltip title={panel.tooltip}>{children}</InfoTooltip>}
          >
            <>{panel.title}</>
          </ConditionalWrapper>
        </div>
        {arrowPosition === 'right' && showHideContentArrow}
      </div>

      {showContent ? panel.content : null}
    </div>
  );
}
