import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Form } from 'antd';

import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { Modal } from 'components/Modal';

import { validateDateInFuture } from 'utils/validation';

import { ISODateStr, QuoteLineItemP21 } from 'types/hash-state';

interface SelectLineItemRequiredDateProps {
  lineItem: QuoteLineItemP21;
  defaultRequiredDate?: ISODateStr;
  onClose: (requiredDate?: ISODateStr | undefined) => void;
}

export function ItemRequiredDateModal({
  lineItem,
  defaultRequiredDate,
  onClose,
}: SelectLineItemRequiredDateProps): JSX.Element {
  const [selectedRequiredDate, setSelectedRequiredDate] = useState<ISODateStr | undefined>(defaultRequiredDate);

  const [form] = Form.useForm();

  const handleFormValuesChange = (changedValues: any) => {
    if (changedValues.requiredDate) {
      setSelectedRequiredDate(changedValues.requiredDate);
    }
  };

  return (
    <Modal
      visible
      title={`Required Date for Item #${lineItem.foreignId}`}
      width={500}
      onCancel={() => onClose()}
      onOk={() => onClose(selectedRequiredDate)}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 14px;
        `}
      />

      <Form form={form} onValuesChange={handleFormValuesChange} initialValues={{ requiredDate: selectedRequiredDate }}>
        <Form.Item
          name="requiredDate"
          label="Required Date"
          rules={[{ validator: validateDateInFuture, message: "Required date can't be in the past" }]}
        >
          <IsoDatePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
}
