import React, { ReactNode } from 'react';

import { useHistory } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { Spin, Space } from 'antd';
import { theme } from 'theme';
import { colors } from 'theme/colors';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { getSentryUrl, HttpError } from 'utils/error';
import { track, TrackEvent } from 'utils/track';

import { Button } from './Button';
import { RecurrencyWordmark } from './Icons';
import { ExternalLink } from './Links';

function checkIsUserWithNoTenantError(err: HttpError) {
  return err.message.startsWith('USER_NOT_MAPPED');
}

const LoaderContainer = ({ children }: { children: ReactNode }) => (
  <div
    className={css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      align-items: center;
      justify-content: center;
      text-align: center;
    `}
  >
    {children}
  </div>
);

export const loaderSpinProps = {
  spinning: true,
  size: 'large' as const,
  indicator: <LoadingOutlined style={{ color: colors.neutral[400] }} />,
};

export const CenteredLoader = () => (
  <LoaderContainer>
    <Spin {...loaderSpinProps} />
  </LoaderContainer>
);

export const SmallLoader = ({ extraCss }: { extraCss?: string }) => (
  <div
    className={cx(
      css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      `,
      extraCss,
    )}
  >
    <Spin {...loaderSpinProps} />
  </div>
);

export const CenteredError = ({
  error,
  componentStack,
  sentryEventId,
}: {
  error?: Error;
  componentStack?: string;
  sentryEventId?: string | null;
}) => {
  const history = useHistory();
  const errorMessage = error?.message || error?.toString() || '';
  const { logout, activeUser } = useGlobalApp();
  const sentryUrl = getSentryUrl(sentryEventId);

  if (error) {
    track(TrackEvent.Components_Loaders_ShowError, {
      errorName: error.name,
      errorMessage,
      errorStack: error.stack || '',
      statusCode: error instanceof HttpError ? error.statusCode : undefined,
      componentStack,
      sentryUrl,
    });

    // log to console so e2e tests fail
    console.error(error);

    if (error instanceof HttpError) {
      const isUserWithNoTenantsError = checkIsUserWithNoTenantError(error);
      if (isUserWithNoTenantsError) {
        return (
          <LoaderContainer>
            <div>
              This user is not assigned to any tenant.
              <br />
              If you think this is an error, please contact{' '}
              <a href="mailto:zsupport@recurrency.com">zsupport@recurrency.com</a>.
              <br />
              <Space style={{ marginTop: '24px' }}>
                <Button type="primary" onClick={() => window.location.assign('https://help.recurrency.com/hc')}>
                  Help Center
                </Button>
                <Button onClick={() => logout()}>Log Out</Button>
              </Space>
            </div>
          </LoaderContainer>
        );
      }

      if (error.statusCode === 404 /* Not Found */) {
        // TODO: add other status code specific responses
        return (
          <LoaderContainer>
            <div>
              Sorry, we can't find the record. <br />
              If this record should exist, please contact{' '}
              <a href="mailto:zsupport@recurrency.com">zsupport@recurrency.com</a>.
            </div>
            <br />
            <Button type="primary" onClick={history.goBack}>
              Go Back
            </Button>
          </LoaderContainer>
        );
      }
    }
  }

  return (
    <LoaderContainer>
      <div>
        Sorry, this should not have happened. An error has occurred.
        <br />
        Our engineers have been notified. Please try again and if this issue persists, contact{' '}
        <a href="mailto:zsupport@recurrency.com">zsupport@recurrency.com</a>.
        <br />
        <Space style={{ marginTop: '24px' }}>
          <Button
            type="primary"
            // using only location.pathname, so if the error is due to hashState, we clear that out.
            onClick={() => window.location.assign(window.location.pathname)}
          >
            Reload Page
          </Button>
          <Button onClick={() => logout()}>Log Out</Button>
        </Space>
        {/* Show error message in in small light font not steal visual attention (but still visible in fullstory session) */}
        <pre style={{ marginTop: '24px', fontSize: '10px', color: theme.colors.neutral[400] }}>
          <span>{errorMessage}</span>

          {sentryUrl && activeUser.isRecurrencyAdmin ? (
            <ExternalLink to={sentryUrl}>&nbsp;sentry →</ExternalLink>
          ) : null}
        </pre>
      </div>
    </LoaderContainer>
  );
};

export const CenteredAccountError = () => {
  const { logout } = useGlobalApp();

  return (
    <LoaderContainer>
      <div>
        <div>
          <RecurrencyWordmark
            className={css`
              height: 32px;
              fill: ${theme.colors.primary[600]};
            `}
          />
        </div>
        <div
          className={css`
            padding: 20px;
          `}
        >
          Your account is almost ready.
          <br />
          Please contact your Recurrency Administrator to finish set up.
        </div>
        <div>
          <Button onClick={() => logout()}>Log Out</Button>
        </div>
      </div>
    </LoaderContainer>
  );
};
