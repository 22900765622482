import React from 'react';

import { css, cx } from '@emotion/css';
import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type Heights = {
  small: string;
  middle: string;
  large: string;
};

const heights: Heights = {
  small: '30px',
  middle: '40px',
  large: '50px',
};

const getHeights = (size: SizeType = 'middle') => heights[size];

export const DatePicker = ({ className, ...props }: DatePickerProps) => (
  <AntdDatePicker
    className={cx(
      css`
        border-radius: 8px;
        height: ${getHeights(props.size)};
      `,
      className,
    )}
    {...props}
  />
);
