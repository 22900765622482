import type { ElementType, HTMLAttributes, ReactElement } from 'react';
import React, { forwardRef } from 'react';

import { cx, css } from '@emotion/css';
import { theme } from 'theme';

import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
}

export interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string | ReactElement;
  headerActions?: ReactElement;
  useTooltip?: boolean;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(({ children, tag = 'div', className, ...props }, ref) => {
  const Element = tag;
  return (
    <Element
      ref={ref}
      className={cx(
        css`
          background: ${theme.colors.neutral[0]};
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          border: 1px solid ${theme.colors.neutral[300]};
          box-shadow: 2px 2px 4px 0px ${theme.colors.neutral[100]};
          overflow: hidden;
        `,
        className,
      )}
      {...props}
    >
      {children}
    </Element>
  );
});

export const CardHeader = forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ title, description, useTooltip, headerActions, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        css`
          padding: 16px 16px;
          border-radius: 8px 8px 0 0;
          border-bottom: solid 1px ${theme.colors.neutral[300]};
          background-color: ${theme.colors.neutral[50]};
          ${theme.breakpoints.mobile} {
            padding: 16px;
          }
        `,
        className,
      )}
      {...props}
    >
      <div
        className={css`
          justify-content: space-between;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {useTooltip && title && (
            <InfoTooltip title={description}>
              <div
                className={css`
                  ${theme.typography.h3};
                  font-weight: 600;
                  color: ${theme.colors.neutral[900]};
                  ${theme.breakpoints.mobile} {
                    ${theme.typography.h4};
                    font-weight: 600;
                  }
                `}
              >
                {title}
              </div>
            </InfoTooltip>
          )}
          {!useTooltip && title && (
            <div
              className={css`
                ${theme.typography.h3};
                font-weight: 600;
                color: ${theme.colors.neutral[900]};
                ${theme.breakpoints.mobile} {
                  ${theme.typography.h4};
                  font-weight: 600;
                }
              `}
            >
              {title}
            </div>
          )}
          {!useTooltip && description && (
            <div
              className={css`
                ${theme.typography.pSmall};
                color: ${theme.colors.neutral[400]};
              `}
            >
              {description}
            </div>
          )}
        </div>
        <div>{headerActions && headerActions}</div>
      </div>
    </div>
  ),
);

export const CardSection = forwardRef<HTMLDivElement, CardProps>(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cx(
      css`
        padding: 16px;
        border-bottom: solid 1px ${theme.colors.neutral[200]};
        &:last-child {
          border: none;
        }
        ${theme.breakpoints.mobile} {
          padding: 24px 16px;
        }
      `,
      className,
    )}
    {...props}
  >
    {children}
  </div>
));
