import React from 'react';

import { css } from '@emotion/css';
import { TenantSettingsFieldNumberGridBase } from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';
import { Form, FormInstance } from 'antd';
import { AlignType } from 'rc-table/lib/interface';

import { Input } from 'components/Input';
import { Table } from 'components/Table';
import { Typography } from 'components/Typography';

import { validateNumberMaximumInclusive, validateNumberMinimumInclusive } from 'utils/validation';

export function SettingsFieldNumberGrid({
  field,
  form,
  readOnly,
}: {
  field: TenantSettingsFieldNumberGridBase;
  form: FormInstance;
  readOnly?: boolean;
}) {
  const numberGrid = form.getFieldValue(field.key);

  const dataColumns = numberGrid.xKeys.map((xKey: number, colIndex: number) => ({
    title: (
      <Typography type="subtitle">
        {colIndex < numberGrid.xKeys.length - 1
          ? `${(numberGrid.xKeys[colIndex - 1] ?? -1) + 1} - ${xKey} `
          : `${numberGrid.xKeys[colIndex - 1]}+ `}
      </Typography>
    ),
    dataIndex: `xKeys[${colIndex}]`,
    align: 'center' as AlignType,
    render: (_: number, __: string, rowIndex: number) => (
      <Form.Item
        name={[field.key, 'values', rowIndex, colIndex]}
        rules={[
          { required: true, message: 'Please enter a number' },
          { validator: (rule, value) => validateNumberMinimumInclusive(rule, value, field.min) },
          { validator: (rule, value) => validateNumberMaximumInclusive(rule, value, field.max) },
        ]}
        className={css`
          margin-bottom: 0;
        `}
      >
        <Input
          type="number"
          min={field.min}
          max={field.max}
          step={0.1}
          disabled={readOnly}
          style={readOnly ? { backgroundColor: 'white', border: 'none', color: 'black' } : {}}
        />
      </Form.Item>
    ),
  }));

  const spanColumns = [
    {
      title: field.yTitle,
      dataIndex: '',
      align: 'center' as AlignType,
      render: (_: number, __: string, rowIndex: number) => ({
        props: { style: { verticalAlign: 'middle' } },
        children: `${(numberGrid.yKeys[rowIndex - 1] ?? 0) + 1} - ${numberGrid.yKeys[rowIndex]}`,
      }),
    },
    {
      title: field.xTitle,
      dataIndex: '',
      children: dataColumns,
    },
  ];

  const dataSource = numberGrid.yKeys.map((_: number, rowIndex: number) => {
    const rowData: Record<string, number | string> = {
      key: rowIndex.toString(),
    };
    // ignore header column when building row data due to no data index
    dataColumns.forEach((__: never, columnIndex: number) => {
      rowData[`xKeys[${columnIndex}]`] = numberGrid.yKeys[rowIndex][columnIndex];
    });
    return rowData;
  });

  return (
    <Table
      className={css`
        margin-top: 10px;
        margin-bottom: 30px;
      `}
      data={dataSource}
      columns={spanColumns}
      pagination={false}
      bordered
    />
  );
}
