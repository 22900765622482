import { PurchaseTargetLineStatus, TransferUomOptions } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TransferTargetLineDTO, TransferTargetDTO } from '@recurrency/core-api-schema/dist/transferOrders/common';
import { SortOrder } from 'antd/lib/table/interface';
import { fuzzyFilter } from 'fuzzbunny';
import moment from 'moment';

import { getTenantSetting } from 'utils/tenantSettings';

import { TransferLineUpdates, TransferTargetSummary, UpdatedTransferLine } from 'types/hash-state';

import { DocSummaryType, TransferSummaryLine } from '../PurchaseTargetsPage/types';

export const getTransferTargetSummaries = (
  updates?: TransferLineUpdates[],
  transferLinesData?: TransferTargetLineDTO[],
  transferTargets?: TransferTargetDTO[],
): TransferTargetSummary[] => {
  const transferSummaries: TransferTargetSummary[] = [];

  transferLinesData?.forEach((line) => {
    const lineUpdates = updates?.find(
      (update) =>
        update.itemId === line.itemId &&
        update.destinationLocationId === line.destinationLocationId &&
        update.sourceLocationId === line.sourceLocationId,
    );
    const qtyToTransfer = lineUpdates?.qtyToTransfer ?? line.defaultTransferQty ?? 0;

    const transferSummaryLine: TransferSummaryLine = {
      purchaseLineKey: '', // TODO @brunno-recurrency figure out how to set this
      itemId: line.itemCode,
      itemName: line.itemName,
      itemUid: line.itemId,
      unitQuantity: qtyToTransfer,
      unitSize: lineUpdates?.selectedUom === 'sales' ? line.salesUnitSize : line.purchasingUnitSize,
      unitOfMeasure: lineUpdates?.selectedUom === 'sales' ? line.salesUom : line.purchasingUom,
      qtyAvailable: line.sourceQtyAvailable,
    };

    const summaryIndex = transferSummaries.findIndex(
      (summary) =>
        summary.sourceLocation.foreignId === line.sourceLocationId &&
        summary.destinationLocation.foreignId === line.destinationLocationId,
    );

    const transferTarget = transferTargets?.find(
      (target) =>
        target.sourceLocationId === line.sourceLocationId &&
        target.destinationLocationId === line.destinationLocationId,
    );

    // Only add line to summary if there is a quantity to transfer
    if (qtyToTransfer > 0) {
      // If summary object already exists, update it with additional line
      if (summaryIndex >= 0) {
        const summary = transferSummaries[summaryIndex];
        transferSummaries[summaryIndex] = {
          ...summary,
          totalCost: (summary?.totalCost || 0) + (line.unitCost || 0) * qtyToTransfer,
          totalWeight: (summary?.totalWeight || 0) + line.unitWeight * qtyToTransfer,
          totalVolume: (summary?.totalVolume || 0) + line.unitVolume * qtyToTransfer,
          totalLines: (summary?.transferLineCount || 0) + 1,
          transferLineCount: (summary?.transferLineCount || 0) + 1,
          lines: [...(summary?.lines || []), transferSummaryLine],
        };
      } else {
        // Otherwise, create a new summary object
        transferSummaries.push({
          kind: DocSummaryType.TransferOrder,
          sourceLocation: { foreignId: line.sourceLocationId, name: line.sourceLocationName },
          destinationLocation: { foreignId: line.destinationLocationId, name: line.destinationLocationName },
          companyId: transferTarget?.companyId ?? '',
          transferDays: transferTarget?.transferDays ?? 0,
          totalCost: line.unitCost * qtyToTransfer,
          totalWeight: line.unitWeight * qtyToTransfer,
          totalVolume: line.unitVolume * qtyToTransfer,
          totalLines: 1,
          transferLineCount: 1,
          lines: [transferSummaryLine],
        });
      }
    }
  });

  return transferSummaries;
};

export function getLineStatusCounts(lineItems: TransferTargetLineDTO[]): Record<PurchaseTargetLineStatus, number> {
  const lineStatusCounts: Obj<number> = {};

  for (const lineItem of lineItems) {
    lineStatusCounts[lineItem.status] = (lineStatusCounts[lineItem.status] ?? 0) + 1;
  }

  return lineStatusCounts as Record<PurchaseTargetLineStatus, number>;
}

// This function sets all current updates to 0 and also adds any lines with non-zero quantity to the updates
export function getResettedTransferLineUpdates(
  transferSummaries: TransferTargetSummary[],
  transferLineUpdates?: TransferLineUpdates[],
) {
  const itemsWithNonZeroQty = [];
  for (const transferSummary of transferSummaries) {
    for (const line of transferSummary.lines) {
      if (line.unitQuantity > 0) {
        itemsWithNonZeroQty.push({
          itemId: line.itemUid,
          destinationLocationId: transferSummary.destinationLocation.foreignId,
          sourceLocationId: transferSummary.sourceLocation.foreignId,
        });
      }
    }
  }

  return [
    ...itemsWithNonZeroQty.map((line) => ({
      itemId: line.itemId,
      destinationLocationId: line.destinationLocationId,
      sourceLocationId: line.sourceLocationId,
      qtyToTransfer: 0,
    })),
    ...(transferLineUpdates?.map((line) => ({
      ...line,
      qtyToTransfer: 0,
    })) || []),
  ];
}

// find a matching line from the updates with the same item/source/destination
export function findMatchingLineFromUpdates(record: TransferTargetLineDTO, updates?: TransferLineUpdates[]) {
  return updates?.find(
    (line) =>
      line.itemId === record.itemId &&
      line.destinationLocationId === record.destinationLocationId &&
      line.sourceLocationId === record.sourceLocationId,
  );
}

export const convertTransferQtyToUom = (qty: number, unitSize: number) => Math.ceil(qty / (unitSize || 1));

export const getTransferQty = (record: UpdatedTransferLine) => {
  const { selectedUnitSize } = getSelectedUomAndUnitSize(record);
  return convertTransferQtyToUom(record.qtyToTransfer ?? record.defaultTransferQty, selectedUnitSize);
};

export const getDefaultTransferQtyInUom = (record: UpdatedTransferLine) => {
  const { selectedUnitSize } = getSelectedUomAndUnitSize(record);
  return convertTransferQtyToUom(record.defaultTransferQty, selectedUnitSize);
};

export function getSelectedUomAndUnitSize(record: UpdatedTransferLine) {
  const defaultUomSetting = getTenantSetting(TenantSettingKey.FeatureTransferReplenishmentDefaultUom);
  const uom = record.selectedUom ?? defaultUomSetting;
  if (uom === TransferUomOptions.Sales) {
    return { selectedUnitSize: record.salesUnitSize, selectedUom: record.salesUom };
  }
  if (uom === TransferUomOptions.Base) {
    return { selectedUnitSize: record.baseUnitSize, selectedUom: record.baseUom };
  }
  return { selectedUnitSize: record.purchasingUnitSize, selectedUom: record.purchasingUom };
}

// Sort and filter transfer line rows
export function sortAndFilterTransferLineRows(
  transferLineRows: UpdatedTransferLine[],
  sortField: string,
  sortDir: SortOrder,
  searchQuery: string,
): UpdatedTransferLine[] {
  if (transferLineRows.length === 0) return transferLineRows;

  const matchingQueryLines = fuzzyFilter(transferLineRows, searchQuery, {
    fields: ['itemCode', 'itemName'],
  }).map((i) => i.item);

  const sortDirMultiplier = sortDir === 'descend' ? -1 : 1;
  const sortKey = sortField as keyof UpdatedTransferLine;

  // get sort function
  let sortFn: (a: UpdatedTransferLine, b: UpdatedTransferLine) => number;
  if (sortKey === 'defaultTransferQty') {
    sortFn = (a, b) => getDefaultTransferQtyInUom(a) - getDefaultTransferQtyInUom(b);
  } else {
    sortFn = (matchingQueryLines[0] ? typeof matchingQueryLines[0][sortKey] === 'number' : false)
      ? (a: UpdatedTransferLine, b: UpdatedTransferLine) => (a[sortKey] as number) - (b[sortKey] as number)
      : (a: UpdatedTransferLine, b: UpdatedTransferLine) =>
          ((a[sortKey] as string) || '').localeCompare(b[sortKey] as string);
  }

  // sort by field, then itemId asc
  return matchingQueryLines.sort((a, b) => {
    const sortCompare = sortFn(a, b) * sortDirMultiplier;
    if (sortCompare !== 0) return sortCompare;

    const itemIdCompare = a.itemId.localeCompare(b.itemId);
    if (itemIdCompare !== 0) return itemIdCompare;

    return 0;
  });
}

export function getTransferInputKey(record: TransferTargetSummary) {
  return `${record.sourceLocation.foreignId}|${record.destinationLocation.foreignId}`;
}

// Set up to offset the date from UTC to the timezone of the user
export function getOffsetDate(date: string) {
  const timezoneOffsetMinutes = new Date().getTimezoneOffset();
  return moment(date).add(timezoneOffsetMinutes, 'minutes');
}
