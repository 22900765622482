import React from 'react';

import { css } from '@emotion/css';
import { GetSalesInvoiceLinesReportGroupBy } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { SmallLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { SalesInvoiceLinesTable } from 'components/recipes/SalesInvoiceLinesTable';
import { useSalesHistoryReport } from 'components/recipes/salesReport/useSalesHistoryReport';

import { optArrFromVal } from 'utils/array';
import { formatUSD, formatUSDAbbreviated } from 'utils/formatting';

export function SalesHistoryModal({
  customerId,
  shipToId,
  itemId,
  itemName,
  onClose,
}: {
  customerId: string;
  shipToId: string;
  itemId: string;
  itemName: string;
  onClose: () => void;
}) {
  const numYearsOfSparkline = 5;
  const { data: salesHistoryData, isLoading } = useSalesHistoryReport({
    groupBy: GetSalesInvoiceLinesReportGroupBy.ShipTo,
    filter: {
      shipToIds: optArrFromVal(shipToId),
      itemIds: optArrFromVal(itemId),
    },
    numYearsOfSparkline,
  });

  const salesMonthly = salesHistoryData?.items[0]?.salesMonthly || {};

  return (
    <Modal
      destroyOnClose
      visible
      title={`Sales History for ${itemId}: ${itemName}`}
      width={1200}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="small">
          Close
        </Button>
      }
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        {isLoading ? (
          <SmallLoader
            extraCss={css`
              height: 200px;
            `}
          />
        ) : (
          <SalesHistoryChart salesMonthly={salesMonthly} numYearsOfSparkline={numYearsOfSparkline} />
        )}
        <SalesInvoiceLinesTable itemId={itemId} customerId={customerId} shipToId={shipToId} />
      </div>
    </Modal>
  );
}

function SalesHistoryChart({
  salesMonthly,
  numYearsOfSparkline,
}: {
  salesMonthly: Record<string, number>;
  numYearsOfSparkline: number;
}) {
  const startDate = new Date(new Date().getFullYear() - numYearsOfSparkline, 0, 1);
  const endDate = new Date();
  const timeLineSeries: Array<{ date: string; sales: number }> = [];

  // NOTE: getMonth is 0-indexed
  let year = startDate.getFullYear();
  let month = startDate.getMonth();
  while (year < endDate.getFullYear() || (year === endDate.getFullYear() && month <= endDate.getMonth())) {
    const date = `${year}-${String(month + 1).padStart(2, '0')}-01`;
    const sales = salesMonthly[date] || 0;
    timeLineSeries.push({ date, sales });

    month += 1;
    if (month === 12) {
      year += 1;
      month = 0;
    }
  }

  const years: string[] = [];
  for (year = startDate.getFullYear(); year <= endDate.getFullYear(); ++year) {
    years.push(`${year}`);
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        gap: 8px;
      `}
    >
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={timeLineSeries} margin={{}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(dateStr) => dateStr.slice(0, 4)}
            ticks={years.map((yr) => `${yr}-01-01`)}
          />
          <YAxis tickFormatter={formatUSDAbbreviated} />
          <Tooltip cursor={false} formatter={(value) => formatUSD(Number(value))} />
          <Line type="linear" dataKey="sales" stroke={theme.colors.chart.sunsetPurple} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
