import React from 'react';

import { Link } from 'react-router-dom';

import { DashboardOutlined, EnvironmentOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';
import { NewPurchaseOrderButton } from 'components/recipes/NewPurchaseOrderButton';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatDateShorthand, toTitleCase } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableDollarColumn, sortableNumberColumn } from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { SearchIndexPurchaseOrder } from 'types/search-collections';

export const PurchaseOrderListPage = () => {
  const { activeTenant } = useGlobalApp();

  const columns: (ColumnType<SearchIndexPurchaseOrder> | null)[] = [
    {
      title: 'PO No',
      dataIndex: 'po_no',
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // Unlike sales orders and quotes, typesense currently returns these as lowercase ErpOrderStatus values
      render: (status: ErpOrderStatus) => <ErpOrderStatusBadge erpOrderStatus={status} />,
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      render: (orderDate: string) => formatDateShorthand(orderDate),
      sorter: true,
    },
    {
      title: 'Expected Date',
      dataIndex: 'expected_date',
      render: (expectedDate: string | null) => formatDateShorthand(expectedDate),
    },
    {
      title: 'Buyer',
      dataIndex: 'buyer_desc',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier_desc',
    },
    sortableDollarColumn({
      title: 'Total Amount',
      dataIndex: 'purchase_value',
    }),
    sortableNumberColumn({ title: 'Line Count', dataIndex: 'line_count' }),
    {
      title: '',
      dataIndex: 'po_no',
      render: (orderNo: string) => ButtonLink(routes.purchasing.purchaseOrderDetails(orderNo)),
    },
  ];

  const valueFacets: (ValueFacet<SearchIndexPurchaseOrder> | null)[] = [
    {
      title: 'Status',
      field: 'status',
      icon: <DashboardOutlined />,
      labelFormatFn: toTitleCase,
    },
    {
      title: 'Buyer',
      field: 'buyer_desc',
      queryPlaceholder: 'Search buyers',
      icon: <UserOutlined />,
    },
    {
      title: 'Location',
      field: 'location_desc',
      queryPlaceholder: 'Search locations',
      icon: <EnvironmentOutlined />,
    },
    {
      title: 'Supplier',
      field: 'supplier_desc',
      queryPlaceholder: 'Search suppliers',
      icon: <ShopOutlined />,
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Vendor',
          field: 'vendor_desc',
          queryPlaceholder: 'Search vendors',
          icon: <ShopOutlined />,
        }
      : null,
  ];

  return (
    <SearchFrame<SearchIndexPurchaseOrder>
      title={
        <InfoTooltip title="Recurrency imports 2 years of purchase orders for search, but includes all of them when calculating statistics">
          Purchase Orders
        </InfoTooltip>
      }
      indexName={SearchIndexName.PurchaseOrders}
      queryPlaceholder="Search recent purchase orders"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      headerActions={<NewPurchaseOrderButton />}
    />
  );
};
