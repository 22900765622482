import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { SalesOrderDetailLineDTO } from '@recurrency/core-api-schema/dist/v2/salesOrders/getSalesOrderDetails';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Typography } from 'components/Typography';

import { OrderAssemblyLinesAgnosticTable } from '../../../../../../components/recipes/ErpAgnostic/OrderAssemblyLinesAgnosticTable';

export function OrderAssemblyLinesAgnosticModal({
  itemId,
  orderLineUid,
  orderLines,
  onClose,
}: {
  itemId: string;
  orderLineUid: string;
  orderLines: SalesOrderDetailLineDTO[];
  onClose: () => void;
}) {
  // Use state to store an array of objects, each containing the previous values of the current itemID and currentOrderLineUid
  const [previousItemIdAndOrderLineUid, setPreviousItemIdAndOrderLineUid] = useState<
    { itemCode: string; orderLineId: string }[]
  >([]);

  const [currentItemCode, setCurrentItemCode] = useState(itemId);
  const [currentOrderLineId, setCurrentOrderLineId] = useState(orderLineUid);

  const handleClick = (itemCode?: string, orderLineId?: string) => {
    if (!itemCode || !orderLineId) return;
    setPreviousItemIdAndOrderLineUid([
      ...previousItemIdAndOrderLineUid,
      { itemCode: currentItemCode, orderLineId: currentOrderLineId },
    ]);
    setCurrentItemCode(itemId);
    setCurrentOrderLineId(orderLineId);
  };

  const handleBackClick = () => {
    const itemIdAndOrderLineUid = previousItemIdAndOrderLineUid.pop();
    setCurrentItemCode(itemIdAndOrderLineUid?.itemCode || '0');
    setCurrentOrderLineId(itemIdAndOrderLineUid?.orderLineId || '0');
  };

  return (
    <Modal
      destroyOnClose
      visible
      title={
        <>
          <Button
            size="small"
            type="link"
            onClick={currentItemCode !== itemId && currentOrderLineId ? handleBackClick : onClose}
            style={{ display: 'inline-block' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <Typography style={{ display: 'inline-block', color: theme.colors.neutral[700] }}>ASSEMBLY: #</Typography>
          <Typography copyable style={{ display: 'inline-block', color: theme.colors.neutral[700] }}>
            {currentItemCode}
          </Typography>
        </>
      }
      width={1200}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="small">
          Close
        </Button>
      }
    >
      <OrderAssemblyLinesAgnosticTable
        orderLines={orderLines}
        orderLineUid={currentOrderLineId}
        onClick={handleClick}
      />
    </Modal>
  );
}
