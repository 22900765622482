import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Note } from '@recurrency/core-api-schema/dist/common/notes';

import { MiniTable } from 'components/MiniTable';
import { NoteCell } from 'components/recipes/sidePane/NoteCell';
import { ViewNotesModal } from 'components/recipes/ViewNotesModal/ViewNotesModal';

import { useCoreApi } from 'hooks/useApi';

import { groupByFields } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { splitIfIdNameStr } from 'utils/formatting';

type SupplierNoteList = {
  supplierId: string;
  supplierName: string;
  items: Note[];
};

const MANDATORY_TOOLTIP_TEXT = 'Supplier note marked as mandatory';

export function SupplierNotesPanelContent({ uniqueSuppliers }: { uniqueSuppliers: string[] }) {
  const uniqueSupplierIds = useMemo(
    () =>
      uniqueSuppliers.reduce<{ [key: string]: string }>((acc, joinedStr) => {
        const result = splitIfIdNameStr(joinedStr);
        if (result?.foreignId != null) {
          acc[result.foreignId] = joinedStr;
        }
        return acc;
      }, {}),
    [uniqueSuppliers],
  );

  const DEFAULT_NOTE_LIMIT = 3;
  const { data, isLoading } = useCoreApi(schemas.notes.getSupplierNotes, {
    queryParams: {
      filter: { supplierIds: Object.keys(uniqueSupplierIds), noteLimit: DEFAULT_NOTE_LIMIT },
    },
  });

  const groupedData = useMemo(() => {
    if (data) return groupByFields(data.items, ['supplierName', 'supplierId']) as SupplierNoteList[];
    return [];
  }, [data]);

  const handleModal = (supplier: SupplierNoteList) => {
    showAsyncModal(ViewNotesModal, {
      title: <>Notes for Supplier {uniqueSupplierIds[supplier.supplierId]}</>,
      endpoint: schemas.notes.getSupplierNotes,
      filter: { supplierIds: [`${supplier.supplierId}`] },
      mandatoryText: MANDATORY_TOOLTIP_TEXT,
    });
  };
  if (isLoading) return <MiniTable columns={[]} data={[]} isLoading />;
  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-top: 10px;
        `}
      >
        {groupedData?.length ? (
          groupedData?.map((supplier: SupplierNoteList, idx: number) => (
            <NoteCell
              header={`${supplier.supplierId}: ${supplier.supplierName}`}
              notes={supplier.items}
              key={`sp_not_${idx}`}
              onViewAll={() => handleModal(supplier)}
              mandatoryTooltipText={MANDATORY_TOOLTIP_TEXT}
            />
          ))
        ) : (
          // Empty state
          <MiniTable columns={[]} data={[]} />
        )}
      </div>
    </>
  );
}
