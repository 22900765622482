import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { Button } from 'components/Button';
import { FlexSpace } from 'components/FlexSpace';
import { Modal } from 'components/Modal';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { MinMaxSubmissionType, PlanningModalType, track, TrackEvent } from 'utils/track';

export function SAPB1PlanningExportMinMax({ onClose }: { onClose: () => void }) {
  const { activeTenant } = useGlobalApp();

  const exportMinMaxes = async () => {
    onClose();
    const submitNotification = createSubmissionNotification({
      entityName: 'Min/Maxes',
      expectedWaitSeconds: 120,
      erpType: activeTenant.erpType,
    });
    try {
      const response = await coreApiFetch(schemas.purchasing.postBatchExportSAPB1MinMaxes, {
        pathParams: { tenantId: activeTenant.id },
      });

      track(TrackEvent.DemandPlanning_MinMax_Submission, {
        itemCount: response.data.numItemsSynced,
        modalType: PlanningModalType.SAPB1Export,
        submissionType: MinMaxSubmissionType.CustomMinMax,
      });

      submitNotification.success();
    } catch (err) {
      submitNotification.error(err);
    }
  };

  return (
    <Modal
      visible
      title="Sync Min/Max Updates to SAP B1"
      onCancel={() => onClose()}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button onClick={exportMinMaxes} type="primary">
            Send to SAP B1
          </Button>
        </>
      }
      width={400}
    >
      <FlexSpace direction="column" alignItems="stretch">
        This will update all Min/Max values in SAP B1 to match the current values in Recurrency.
      </FlexSpace>
    </Modal>
  );
}
