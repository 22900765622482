import React from 'react';

import { css } from '@emotion/css';
import { fontWeights } from 'theme/typography';

/** Standard header titles for sections inside a modal */
export function ModalSectionTitle({ title }: { title: React.ReactNode }) {
  return (
    <div
      className={css`
        font-weight: ${fontWeights.medium};
        font-size: 16px;
        line-height: 22px;
      `}
    >
      {title}
    </div>
  );
}
