import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Form, FormInstance } from 'antd';
import moment from 'moment';

import { getCustomerDerivedFieldDefaults } from 'pages/orders/quotes/quoteUtils';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  ShipToSelectOption,
  useCarriersSelectProps,
  useContactsSelectProps,
  useCustomersSelectProps,
  useLocationsSelectProps,
  useShipTosSelectProps,
  useShippingRoutesSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { captureError } from 'utils/error';
import { joinIfIdNameObj, splitIdNameStr } from 'utils/formatting';
import { objKeys, objPickKeys } from 'utils/object';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { UpdateFormHashStateOptions } from 'utils/routes';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { validateDateInFuture } from 'utils/validation';

import { OrderEditHashStateSAPB1 } from 'types/hash-state';

export function OrderEditHeaderStep({
  form,
  docState,
  onDocStateChange: onQuoteStateChange,
}: {
  form: FormInstance<OrderEditHashStateSAPB1>;
  docState: OrderEditHashStateSAPB1;
  onDocStateChange: (update: Partial<OrderEditHashStateSAPB1>, options?: UpdateFormHashStateOptions) => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const defaultQuoteFieldData = activeTenant.defaultData.quote;
  const quoteOrderFormConfig = getTenantConfiguration(activeTenant).quoteOrderEntryFlow.formConfig;

  useEffect(() => {
    // explicit pick, so undefined values are explicitly set, and override internal form state
    const fieldsValue = objPickKeys(
      docState,
      'customer',
      'shipTo',
      'contact',
      'location',
      'carrier',
      'shippingRouteId',
      'customerPORef',
      'dueDate',
      'ocrCode',
      'ocrCode2',
    );
    // use docState as the source of state
    form.setFieldsValue(fieldsValue);
  }, [form, docState]);

  const customerId = docState.customer ? splitIdNameStr(docState.customer).foreignId : '';
  const customersSelectProps = useCustomersSelectProps({});
  const contactsSelectProps = useContactsSelectProps({ customerId });
  const shipTosSelectProps = useShipTosSelectProps({ customerId });
  const locationsSelectProps = useLocationsSelectProps({});
  const carriersSelectProps = useCarriersSelectProps();
  const shippingRoutesSelectProps = useShippingRoutesSelectProps();

  const smartFieldDefaults = {
    shipTo: {
      default: undefined,
      selectProps: shipTosSelectProps,
    },
    carrier: {
      default: joinIfIdNameObj(defaultQuoteFieldData.carrier),
      selectProps: carriersSelectProps,
    },
    location: {
      default: joinIfIdNameObj(defaultQuoteFieldData.location),
      selectProps: locationsSelectProps,
    },
  };

  // make smart default selection for field values
  // either the settings specified default, or the only item in options
  for (const field of objKeys(smartFieldDefaults)) {
    const smartDefault = smartFieldDefaults[field];
    if (!docState[field]) {
      const defaultValue =
        smartDefault.default ||
        (smartDefault.selectProps.options.length === 1 &&
          !smartDefault.selectProps.isLoading &&
          !smartDefault.selectProps.searchQuery &&
          smartDefault.selectProps.options[0].value);
      if (defaultValue) {
        requestAnimationFrame(() => {
          onQuoteStateChange({ [field]: defaultValue }, { defaultValue: true });
          if (field === 'shipTo') {
            onSelectShipTo(smartDefault.selectProps.options[0]);
          }
        });
      }
    }
  }

  // add date field defaults
  if (!docState.dueDate) {
    requestAnimationFrame(() => {
      onQuoteStateChange({ dueDate: moment().startOf('day').toISOString() }, { defaultValue: true });
    });
  }

  // set ocrCode and ocrCode2 if necessary once customer is reloaded
  useEffect(() => {
    getCustomerDerivedFieldDefaults(customerId, quoteOrderFormConfig)
      .then((stateUpdate) => stateUpdate && onQuoteStateChange(stateUpdate))
      .catch((err) => captureError(err));
  }, [customerId, onQuoteStateChange, quoteOrderFormConfig]);

  const onSelectCustomer = () => {
    // clear form when customer changes if there was a customer
    onQuoteStateChange({
      contact: undefined,
      shipTo: undefined,
      location: undefined,
      carrier: undefined,
      dueDate: undefined,
      customerPORef: undefined,
      ocrCode: undefined,
      ocrCode2: undefined,
      items: docState.items?.map((item) => ({ foreignId: item.foreignId, name: item.name, quantity: item.quantity })),
    });
  };

  const onSelectShipTo = (option: ShipToSelectOption) => {
    onQuoteStateChange({ customer: joinIfIdNameObj(option.customer) });
  };

  return (
    <div
      className={css`
        width: 100%;

        .ant-form-item {
          margin-bottom: 4px;
        }
      `}
    >
      <Form
        form={form}
        onFinishFailed={console.error}
        onValuesChange={(changedValues) => onQuoteStateChange(changedValues)}
        {...responsiveFormLayout}
      >
        <Form.Item name="customer" label="Customer" rules={[{ required: true, message: 'Please add a customer.' }]}>
          <AsyncSelect
            selectProps={customersSelectProps}
            entityPlural="customers"
            onSelect={() => onSelectCustomer()}
            onClear={() => onSelectCustomer()}
            allowClear
          />
        </Form.Item>
        <Form.Item name="shipTo" label="Ship To" rules={[{ required: true, message: 'Please add a ship to.' }]}>
          <AsyncSelect
            selectProps={shipTosSelectProps}
            entityPlural="ship tos"
            onSelect={(_, option) => onSelectShipTo(option as ShipToSelectOption)}
          />
        </Form.Item>
        <Form.Item name="contact" label="Contact">
          <AsyncSelect
            selectProps={contactsSelectProps}
            entityPlural="contacts"
            disabled={!customerId}
            placeholder={!customerId ? 'Please select a customer first' : ''}
            allowClear
          />
        </Form.Item>
        <br />
        <Form.Item
          name="dueDate"
          label="Due Date"
          rules={[
            { required: true, message: 'Please add a due date.' },
            { validator: validateDateInFuture, message: 'Due date must be after today' },
          ]}
        >
          <IsoDatePicker
            quickButtons={[
              { name: 'in a week', value: moment().startOf('day').add(7, 'd') },
              { name: 'in a month', value: moment().startOf('day').add(1, 'M') },
            ]}
          />
        </Form.Item>
        <br />
        <Form.Item name="location" label="Location" rules={[{ required: true, message: 'Please add a location.' }]}>
          <AsyncSelect selectProps={locationsSelectProps} entityPlural="locations" />
        </Form.Item>
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeCarrier) && (
          <Form.Item name="carrier" label="Carrier">
            <AsyncSelect selectProps={carriersSelectProps} entityPlural="carriers" allowClear />
          </Form.Item>
        )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeShippingRoute) && (
          <Form.Item name="shippingRouteId" label="Route">
            <AsyncSelect selectProps={shippingRoutesSelectProps} entityPlural="routes" allowClear />
          </Form.Item>
        )}
        <br />
        <Form.Item name="customerPORef" label="Customer PO No">
          <Input />
        </Form.Item>
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersEnableNotesExportForSAPB1) && (
          <Form.Item name="customerNote" label="Customer Note">
            <Input />
          </Form.Item>
        )}
      </Form>
    </div>
  );
}
