import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { message } from 'antd';
import moment from 'moment';
import { colors } from 'theme/colors';

import { getRecReasonText } from 'pages/orders/OpportunitiesPage/utils';

import { MiniTableMessage, MiniTableSkeleton } from 'components/MiniTable';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { capitalize, formatPercent, splitIfIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { track, TrackEvent, OrderType, RecommendedItemType } from 'utils/track';

import { ErpAgnosticOrderHashState } from 'types/hash-state';

import { ActionButton } from '../../Button/ActionButton';
import { SidePane } from './SidePane';
import { RecommendedItem } from './types';

export function LineItemRecommendationsSidePane({
  orderState,
  orderType,
  onRecommendedItemAdd,
}: {
  orderState: ErpAgnosticOrderHashState;
  orderType: OrderType;
  onRecommendedItemAdd: (lineItem: RecommendedItem) => void;
}) {
  const customerId = splitIfIdNameStr(orderState.customer)?.foreignId;
  const shipToId = splitIfIdNameStr(orderState.shipTo)?.foreignId;
  const lineItems = orderState.items || [];
  const lineItemCodesSet = new Set(lineItems.map((lineItem) => lineItem.foreignId));
  const numRecItems = 3;

  if (!customerId || !shipToId) {
    return <MiniTableMessage message="Select customer and ship-to to load recommendations." />;
  }

  return (
    <SidePane
      title="Recommended Items"
      panels={[
        {
          settingKey: 'recent',
          title: 'Recent',
          content: (
            <RecRecentItemsList
              customerId={customerId}
              shipToId={shipToId}
              lineItemCodesSet={lineItemCodesSet}
              numRecItems={numRecItems}
              orderType={orderType}
              onRecommendedItemAdd={onRecommendedItemAdd}
            />
          ),
        },
        {
          settingKey: 'reorder',
          title: 'Reorder',
          content: (
            <RecReorderItemsList
              customerId={customerId}
              shipToId={shipToId}
              lineItemCodesSet={lineItemCodesSet}
              numRecItems={numRecItems}
              orderType={orderType}
              onRecommendedItemAdd={onRecommendedItemAdd}
            />
          ),
        },
        {
          settingKey: 'new',
          title: 'New',
          content: (
            <RecNewItemsList
              customerId={customerId}
              lineItemCodesSet={lineItemCodesSet}
              numRecItems={numRecItems}
              orderType={orderType}
              onRecommendedItemAdd={onRecommendedItemAdd}
            />
          ),
        },
      ]}
    />
  );
}

function RecRecentItemsList({
  customerId,
  shipToId,
  numRecItems,
  lineItemCodesSet,
  orderType,
  onRecommendedItemAdd,
}: {
  customerId: string;
  shipToId: string;
  numRecItems: number;
  lineItemCodesSet: Set<string>;
  orderType: OrderType;
  onRecommendedItemAdd: (item: RecommendedItem) => void;
}) {
  const { data, isLoading, error } = useCoreApi(schemas.salesOrderLines.getSalesOrderLinesV2, {
    queryParams: {
      filter: { customerId, shipToId },
      limit: numRecItems,
    },
  });

  if (isLoading) {
    return <MiniTableSkeleton />;
  }

  const recItems: RecommendedItem[] = (data?.items || [])
    .filter((recItem) => !lineItemCodesSet.has(recItem.itemCode ?? ''))
    .map(
      (line) =>
        ({
          itemCode: line.itemCode,
          itemName: line.itemName,
          lastQty: line.quantityOrdered,
          lastUnitPrice: line.unitPrice,
          unitOfMeasure: line.unitOfMeasure,
          orderDate: line.orderDate,
        } as RecommendedItem),
    );

  if (error || recItems.length === 0) {
    return <MiniTableMessage message="No recent items sold to ship-to." />;
  }

  return (
    <div>
      {recItems.map((recItem, idx) => (
        <RecItem
          key={idx}
          recItem={recItem}
          recItemType={RecommendedItemType.Recent}
          recReason={moment(recItem.orderDate).fromNow()}
          orderType={orderType}
          onRecommendedItemAdd={onRecommendedItemAdd}
        />
      ))}
    </div>
  );
}

function RecReorderItemsList({
  customerId,
  shipToId,
  numRecItems,
  lineItemCodesSet,
  orderType,
  onRecommendedItemAdd,
}: {
  customerId: string;
  shipToId: string;
  numRecItems: number;
  lineItemCodesSet: Set<string>;
  orderType: OrderType;
  onRecommendedItemAdd: (item: RecommendedItem) => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const { data, isLoading, error } = useCoreApi(schemas.ml.getRecommendedQuotes, {
    queryParams: {
      filter: { customerId, shipToId },
      limit: numRecItems,
    },
  });

  if (isLoading) {
    return <MiniTableSkeleton />;
  }

  const recItems = (data?.items[0]?.quotes[0]?.lineItems || [])
    .filter((recItem) => !lineItemCodesSet.has(recItem.itemId))
    .map((recItem) => ({ ...recItem, itemCode: recItem.itemId }))
    .slice(0, numRecItems);

  if (error || recItems.length === 0) {
    return <MiniTableMessage message="No reorder item recommendations found." />;
  }

  return (
    <div>
      {recItems.map((recItem, idx) => (
        <RecItem
          key={idx}
          recItem={recItem}
          recItemType={RecommendedItemType.Reorders}
          recReason={
            <>
              {getRecReasonText(recItem)} {capitalize(moment(recItem.lastOrderDate).fromNow(true))} since{' '}
              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesReordersFromInvoiceData) ? (
                'last invoice'
              ) : (
                <a target="_blank" href={routes.orders.orderDetails(recItem.lastOrderId)} rel="noreferrer">
                  last order
                </a>
              )}
              .
            </>
          }
          orderType={orderType}
          onRecommendedItemAdd={onRecommendedItemAdd}
        />
      ))}
    </div>
  );
}

function RecNewItemsList({
  customerId,
  numRecItems,
  lineItemCodesSet,
  orderType,
  onRecommendedItemAdd,
}: {
  customerId: string;
  numRecItems: number;
  lineItemCodesSet: Set<string>;
  orderType: OrderType;
  onRecommendedItemAdd: (item: RecommendedItem) => void;
}) {
  const { data, isLoading, error } = useCoreApi(schemas.ml.getRecommendedItemsToCustomer, {
    pathParams: { customerId },
    queryParams: {
      limit: numRecItems,
    },
  });

  if (isLoading) {
    return <MiniTableSkeleton />;
  }

  const recItems = (data?.items || [])
    .filter((recItem) => !lineItemCodesSet.has(recItem.itemId))
    .map((recItem) => ({ ...recItem, itemCode: recItem.itemId }));

  if (error || recItems.length === 0) {
    return <MiniTableMessage message="No new item recommendations found." />;
  }

  return (
    <div>
      {recItems.map((recItem, idx) => (
        <RecItem
          key={idx}
          recItem={recItem}
          recItemType={RecommendedItemType.New}
          recReason={`${formatPercent(recItem.matchRank)} Match`}
          orderType={orderType}
          onRecommendedItemAdd={onRecommendedItemAdd}
        />
      ))}
    </div>
  );
}

function RecItem({
  recItem,
  recReason,
  recItemType,
  orderType,
  onRecommendedItemAdd,
}: {
  recItem: RecommendedItem;
  recReason: React.ReactChild;
  recItemType: RecommendedItemType;
  orderType: OrderType;
  onRecommendedItemAdd: (item: RecommendedItem) => void;
}) {
  return (
    <div
      className={css`
        margin-bottom: 10px;
      `}
    >
      <div className={css``}>
        <a
          className={css`
            color: inherit;
          `}
          target="_blank"
          href={routes.sales.itemDetails(recItem.itemCode)}
          rel="noreferrer"
        >
          {recItem.itemCode}
        </a>
        : {recItem.itemName}
      </div>

      <div
        className={css`
          color: ${colors.neutral[600]};
          font-size: 12px;
          white-space: pre-line;
        `}
      >
        {recReason}
      </div>

      <ActionButton
        label="Add"
        onClick={() => {
          track(TrackEvent.Quotes_EditQuote_AddRecommendedItem, {
            recommendedItemType: recItemType,
            orderType,
          });
          onRecommendedItemAdd(recItem);
          message.success(`Recommended Item #${recItem.itemCode} has been added`);
        }}
      />
    </div>
  );
}
