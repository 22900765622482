// import reflect-metadata for core-api-schema to load
import 'reflect-metadata';
import React from 'react';

import ReactDOM from 'react-dom';

import { perfMark, PerfMarkName } from 'utils/perfMeasure';
import { registerSentry } from 'utils/sentry';

import { App } from './App';
import { extScriptsInit } from './extScriptsInit';
import './index.less';

function init() {
  const { protocol, hostname, pathname, search, hash } = window.location;
  // redirect .ai -> .com
  if (hostname === 'app.recurrency.ai' || hostname === 'app-staging.recurrency.ai') {
    window.location.replace(`https://${hostname.replace('.ai', '.com')}${pathname}${search}${hash}`);
    return;
  }

  // force https
  if (protocol === 'http:' && hostname !== 'localhost') {
    window.location.replace(`https://${hostname}${pathname}${search}${hash}`);
    return;
  }

  perfMark(PerfMarkName.AppInit);
  registerSentry();

  // only initialize 3rd party tracking script if browser isn't ChromeHeadless
  // @see https://stackoverflow.com/a/54306469
  if (!window.navigator.webdriver) {
    extScriptsInit();
  }

  ReactDOM.render(<App />, document.getElementById('root'));
}

init();
