import React, { useEffect, useMemo, useState } from 'react';

import {
  AppstoreOutlined,
  DashboardOutlined,
  DownOutlined,
  EnvironmentOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { ISODateStr } from '@recurrency/core-api-schema/dist/common/types';
import { Menu, Radio } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { fuzzyFilter } from 'fuzzbunny';
import { useDebounce } from 'use-debounce/lib';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Dropdown } from 'components/Dropdown';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { RadioGroup } from 'components/Radio';
import { ProductGroupMultiSelect } from 'components/recipes/select/ProductGroupMultiSelect';
import { ResultCount } from 'components/ResultCount';
import { SearchInput } from 'components/SearchInput';
import { SplitPage } from 'components/SplitPage';

import { useEventListener } from 'hooks/useEventListener';

import { arrUnique } from 'utils/array';
import { formatIdNameObj } from 'utils/formatting';
import { useHashState } from 'utils/routes';
import { getTenantSetting } from 'utils/tenantSettings';

import {
  PurchaseTargetLinesHashState,
  PurchasingLineInput,
  SupplierLocationWTarget,
  PurchasingLineTransfer,
  PurchaseGroupHashState,
} from 'types/hash-state';

import { GenericTargetLineDTO, GenericTargetLineRow, PurchaseTargetLineRow } from '../types';
import { getHubAndSpokePOSummaries, getLineStatusCounts, getMergedPurchaseLines, getPOSummaries } from '../utils';
import { PurchaseTargetLinesTableColumns } from './PurchaseTargetLinesTableColumns';
import { ItemDetailsSidePane } from './sidePane/ItemDetailsSidePane';
import { POSummarySidePaneSidePane } from './sidePane/POSummarySidePane';
import { allStatuses, getUniqueItemGroupOptions, nonStockStatuses } from './utils';

enum TargetLinesFilter {
  All,
  Transferable,
  Purchasing,
}

export function PurchaseTargetLinesTable({
  supplierLocations,
  targetLines,
  purchasingLinesById,
  linesModalMode = false,
  onPurchasingLinesChange,
  onMinMaxChange,
  isHubAndSpoke,
  purchaseGroups,
}: {
  supplierLocations: SupplierLocationWTarget[];
  targetLines: GenericTargetLineDTO[];
  purchasingLinesById: Obj<PurchasingLineInput>;
  linesModalMode?: boolean;
  onPurchasingLinesChange: (purchasingLinesById: Obj<PurchasingLineInput>) => void;
  onMinMaxChange?: () => void;
  isHubAndSpoke?: boolean;
  purchaseGroups?: PurchaseGroupHashState[];
}) {
  const [hashState, updateHashState] = useHashState<PurchaseTargetLinesHashState>();
  const [focusedLine, setFocusedLine] = useState<GenericTargetLineRow | undefined>();
  const [locationIdsFilter, setLocationIdsFilter] = useState<string[]>([]);
  const [supplierIdsFilter, setSupplierIdsFilter] = useState<string[]>([]);
  const [itemGroupIdsFilter, setItemGroupIdsFilter] = useState<string[]>([]);
  const [purchaseGroupsIdsFilter, setPurchaseGroupsIdsFilter] = useState<string[]>([]);
  const [linesFilter, setLinesFilter] = useState<TargetLinesFilter>(
    linesModalMode ? TargetLinesFilter.Purchasing : TargetLinesFilter.All,
  );
  const [searchQuery, setSearchQuery] = useState(hashState.query || '');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [sortField, setSortField] = useState('qtyToOrder');
  const [sortDir, setSortDir] = useState<SortOrder>('descend');
  const statusesFilter = hashState.statuses ?? nonStockStatuses;

  // weight and volume target show extra 'Unit Weight/Volume', 'Ext Weight/Volume' columns.
  const targetType = supplierLocations[0]?.targetType;
  const uniqueLocations = arrUnique(
    supplierLocations.map((sl) => formatIdNameObj({ foreignId: sl.locationId, name: sl.locationName })),
  );
  const purchaseLocations = supplierLocations.filter((l) => l.locationId === l.purchasingLocationId);
  const uniqueSuppliers = arrUnique(
    supplierLocations.map((sl) => formatIdNameObj({ foreignId: sl.supplierId, name: sl.supplierName })),
  );

  const allLines = useMemo(
    () => getMergedPurchaseLines(targetLines, purchasingLinesById),
    [purchasingLinesById, targetLines],
  );
  const poSummaries = useMemo(
    () =>
      isHubAndSpoke && purchaseGroups
        ? getHubAndSpokePOSummaries(allLines, supplierLocations, purchaseGroups)
        : getPOSummaries(allLines, supplierLocations),
    [allLines, isHubAndSpoke, supplierLocations, purchaseGroups],
  );

  const filteredLines = useMemo(() => {
    const lines = fuzzyFilter(
      allLines.filter(
        (line) =>
          (linesFilter === TargetLinesFilter.Purchasing ? line.userQtyToOrder > 0 : true) &&
          (linesFilter === TargetLinesFilter.Transferable ? line.potentialTransfer : true) &&
          (statusesFilter.length === 0 || statusesFilter.includes(line.status)) &&
          (supplierIdsFilter.length === 0 || supplierIdsFilter.includes(line.supplierId)) &&
          (itemGroupIdsFilter.length === 0 || itemGroupIdsFilter.includes(line.itemGroupId ?? '-')) &&
          (locationIdsFilter.length === 0 ||
            locationIdsFilter.includes(isHubAndSpoke ? line.purchaseLocationId! : line.locationId!)) &&
          (purchaseGroupsIdsFilter.length === 0 ||
            (line.groupId != null && purchaseGroupsIdsFilter.includes(line.groupId))),
      ),
      debouncedSearchQuery,
      {
        fields: ['itemId', 'itemName'],
      },
    ).map((i) => i.item);

    sortTargetLineRows(lines, sortField, sortDir);
    return lines;
  }, [
    allLines,
    debouncedSearchQuery,
    sortField,
    sortDir,
    linesFilter,
    statusesFilter,
    supplierIdsFilter,
    itemGroupIdsFilter,
    locationIdsFilter,
    purchaseGroupsIdsFilter,
    isHubAndSpoke,
  ]);

  const lineStatusCounts = useMemo(() => getLineStatusCounts(filteredLines), [filteredLines]);
  const purchasingLines = useMemo(() => allLines.filter((line) => line.userQtyToOrder > 0), [allLines]);
  const transferableLines = useMemo(() => allLines.filter((line) => line.potentialTransfer), [allLines]);

  useEffect(() => {
    updateHashState({ query: debouncedSearchQuery });
  }, [debouncedSearchQuery, updateHashState]);

  useEffect(() => {
    // if targetLines are reloaded, remember focused line item
    if (focusedLine) {
      setFocusedLine(allLines.find((line) => line.key === focusedLine.key));
    }
  }, [allLines]); // eslint-disable-line react-hooks/exhaustive-deps

  // clear focused line item when clicking outside of lines table and side pane
  useEventListener(
    document.body,
    'click',
    (ev) => {
      /**  DatePicker mounts to <body> so it registers as an "outside click", causing sidepanel and table to re-render,
       * impeding datepicker functionality. If the click is in datepicker dropdown, exit early. */
      const isDatePickerClick = (ev.target as HTMLElement).closest('.ant-picker-dropdown') !== null;
      if (isDatePickerClick) return;

      const lineInfoEls = Array.from(
        document.querySelectorAll('.ant-table, div.SidePane, div.ant-modal-wrap:not(.purchase-target-lines-modal)'),
      );
      if (lineInfoEls.every((el) => !el.contains(ev.target as Node))) {
        setFocusedLine(undefined);
      }
    },
    {
      // evaluate at capture (instead of bubbling phase) so if a component stops event propagation
      // we still know where the user clicked
      capture: true,
    },
  );

  const handleQtyToOrderChange = (value: number, line: GenericTargetLineRow) => {
    const lineInput = purchasingLinesById[line.key];
    if (value === 0 && lineInput) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, qtyToOrder: undefined } });
    } else if (lineInput?.qtyToOrder !== value) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, qtyToOrder: value } });
    }
  };

  const handleTransferUpdate = (newTransfers: PurchasingLineTransfer[], line: PurchaseTargetLineRow) => {
    const existingLine = purchasingLinesById[line.key];

    // Calculate prevTotalQtyToTransfer from existingLine.transfers
    const prevTotalQtyToTransfer = existingLine?.transfers
      ? existingLine.transfers.reduce((total, transfer) => total + transfer.qtyToTransfer, 0)
      : 0;

    const newTotalQtyToTransfer = newTransfers.reduce((total, transfer) => total + transfer.qtyToTransfer, 0);
    const qtyToOrderAdjustment = prevTotalQtyToTransfer - newTotalQtyToTransfer;

    // Default currentQtyToOrder to 0 if existingLine.qtyToOrder is undefined
    const currentQtyToOrder = existingLine?.qtyToOrder ?? 0;

    if (existingLine) {
      const updatedQtyToOrder = Math.max(currentQtyToOrder + qtyToOrderAdjustment, 0);
      onPurchasingLinesChange({
        ...purchasingLinesById,
        [line.key]: {
          ...existingLine,
          qtyToOrder: updatedQtyToOrder,
          // Set transfers to undefined if newTransfers is empty, otherwise update with newTransfers
          transfers: newTransfers.length > 0 ? newTransfers : undefined,
        },
      });
    } else if (newTransfers.length === 0) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { transfers: undefined } });
    } else if (newTransfers.length > 0) {
      // For non recommended to order items, we still want to update newTransfers
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { transfers: newTransfers } });
    }
  };

  const handleUnitCostChange = (value: number, line: GenericTargetLineRow) => {
    const lineInput = purchasingLinesById[line.key];
    // unit cost editing is only allowed if qtyToOrder > 0, hence lineInput should always be defined
    if (lineInput && lineInput.unitCost !== value) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, unitCost: value } });
    }
  };

  const handleRequiredDateChange = (value: ISODateStr | undefined, line: GenericTargetLineRow) => {
    const lineInput = purchasingLinesById[line.key];

    if (lineInput && lineInput.requiredDate !== value) {
      onPurchasingLinesChange({ ...purchasingLinesById, [line.key]: { ...lineInput, requiredDate: value } });
    }
  };

  const getClearedLinesById = (clearTransfersOnly = false, clearPurchasingOnly = false) => {
    // clear out any lines matching selection of supplierLocations
    const clearedPurchasingLinesById = { ...purchasingLinesById };
    for (const lineKey of Object.keys(clearedPurchasingLinesById)) {
      // lineKey's first part is the group ID for hub and spoke items
      const [lineGroupId] = lineKey.split('.');
      const [lineSupplierId, lineLocationId] = lineKey.split('.');

      for (const supplierLocation of supplierLocations) {
        if (
          (supplierLocation.supplierId === lineSupplierId && supplierLocation.locationId === lineLocationId) ||
          (isHubAndSpoke && supplierLocation.groupId === lineGroupId)
        ) {
          if (clearTransfersOnly) {
            clearedPurchasingLinesById[lineKey].transfers = [];
            break;
          }

          const hasTransfers = (clearedPurchasingLinesById[lineKey].transfers || []).length > 0;
          if (clearPurchasingOnly && hasTransfers) {
            clearedPurchasingLinesById[lineKey].qtyToOrder = 0;
            break;
          }

          delete clearedPurchasingLinesById[lineKey];
          break;
        }
      }
    }
    return clearedPurchasingLinesById;
  };

  const handleClearAllLinesClick = () => {
    onPurchasingLinesChange(getClearedLinesById());
  };

  const handleClearPurchasingLinesClick = () => {
    onPurchasingLinesChange(getClearedLinesById(false, true));
  };
  const handleClearTransferLinesClick = () => {
    onPurchasingLinesChange(getClearedLinesById(true, false));
  };

  const handleResetLinesToRecommendedClick = () => {
    const newPurchasingLinesById = getClearedLinesById();
    for (const line of allLines) {
      for (const supplierLocation of supplierLocations) {
        if (
          (line.qtyToOrder > 0 &&
            supplierLocation.supplierId === line.supplierId &&
            supplierLocation.locationId === line.locationId) ||
          (isHubAndSpoke && supplierLocation.groupId === line.groupId)
        ) {
          newPurchasingLinesById[line.key] = { qtyToOrder: line.qtyToOrder };
        }
      }
    }
    onPurchasingLinesChange(newPurchasingLinesById);
  };

  return (
    <>
      <FilterBarBox dividerLine>
        <RadioGroup
          value={linesFilter}
          onChange={({ target: { value } }) => {
            setLinesFilter(value);
          }}
        >
          <Radio.Button value={TargetLinesFilter.All}>
            All <b>({allLines.length})</b>
          </Radio.Button>
          {getTenantSetting(TenantSettingKey.FeatureCreateTransfers) && !isHubAndSpoke && (
            <Radio.Button value={TargetLinesFilter.Transferable}>
              Transferable <b>({transferableLines.length})</b>
            </Radio.Button>
          )}
          <Radio.Button value={TargetLinesFilter.Purchasing}>
            Purchasing <b>({purchasingLines.length})</b>
          </Radio.Button>
        </RadioGroup>
        <AsyncMultiSelect
          label="Status"
          icon={<DashboardOutlined />}
          selectedValues={statusesFilter}
          onSelectedValuesChange={(values) => updateHashState({ statuses: values as PurchaseTargetLineStatus[] })}
          selectProps={{ options: allStatuses.map((status) => ({ label: status, value: status })) }}
        />
        {uniqueLocations.length > 1 || (isHubAndSpoke && purchaseLocations.length > 1) ? (
          <AsyncMultiSelect
            label={`${isHubAndSpoke ? 'Purchase ' : ''}Location`}
            icon={<EnvironmentOutlined />}
            queryPlaceholder="Search locations"
            selectedValues={locationIdsFilter}
            onSelectedValuesChange={(values) => setLocationIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                (isHubAndSpoke ? purchaseLocations : supplierLocations).map((sl) => ({
                  label: `${sl.locationId}: ${sl.locationName}`,
                  value: sl.locationId,
                })),
                'value',
              ),
            }}
          />
        ) : null}
        {uniqueSuppliers.length > 1 ? (
          <AsyncMultiSelect
            label="Supplier"
            icon={<ShopOutlined />}
            queryPlaceholder="Search suppliers"
            selectedValues={supplierIdsFilter}
            onSelectedValuesChange={(values) => setSupplierIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                supplierLocations.map((sl) => ({
                  label: `${sl.supplierId}: ${sl.supplierName}`,
                  value: sl.supplierId,
                })),
                'value',
              ),
            }}
          />
        ) : null}

        <ProductGroupMultiSelect
          selectedValues={itemGroupIdsFilter}
          onSelectedValuesChange={(values: string[]) => setItemGroupIdsFilter(values)}
          selectProps={{
            options: getUniqueItemGroupOptions(targetLines),
          }}
        />
        {isHubAndSpoke && purchaseGroups && purchaseGroups.length > 1 && (
          <AsyncMultiSelect
            label="Purchase Group"
            icon={<AppstoreOutlined />}
            selectedValues={purchaseGroupsIdsFilter}
            queryPlaceholder="Search purchase groups"
            onSelectedValuesChange={(values: string[]) => setPurchaseGroupsIdsFilter(values)}
            selectProps={{
              options: purchaseGroups.map((pg) => ({
                label: pg.groupName,
                value: pg.groupId,
              })),
            }}
          />
        )}
        <FlexSpacer />
        {getTenantSetting(TenantSettingKey.FeatureCreateTransfers) ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleClearPurchasingLinesClick()}>Clear All Purchasing</Menu.Item>
                {!isHubAndSpoke && (
                  <>
                    <Menu.Item onClick={() => handleClearTransferLinesClick()}>Clear All Transfers</Menu.Item>
                    <Menu.Item onClick={() => handleClearAllLinesClick()}>Clear All</Menu.Item>
                  </>
                )}
                <Menu.Item onClick={() => handleResetLinesToRecommendedClick()}>Reset All to Recommended</Menu.Item>
              </Menu>
            }
          >
            <Button size="small">
              Reset <DownOutlined />
            </Button>
          </Dropdown>
        ) : (
          <ActionButton
            label={purchasingLines.length ? 'Clear All Purchasing' : 'Reset to All Recommended'}
            onClick={() => (purchasingLines.length ? handleClearAllLinesClick() : handleResetLinesToRecommendedClick())}
          />
        )}

        <SearchInput
          query={searchQuery}
          onQueryChange={(value) => setSearchQuery(value)}
          placeholder="Search Item ID / Description"
        />
        <ResultCount count={filteredLines.length} />
      </FilterBarBox>
      <SplitPage
        left={
          <PurchaseTargetLinesTableColumns
            isHubAndSpoke={isHubAndSpoke}
            filteredLines={filteredLines}
            focusedLine={focusedLine}
            uniqueSuppliers={uniqueSuppliers}
            uniqueLocations={uniqueLocations}
            targetType={targetType}
            setSortDir={setSortDir}
            setSortField={setSortField}
            setFocusedLine={setFocusedLine}
            handleQtyToOrderChange={handleQtyToOrderChange}
            getTotalUserTransfersQty={getTotalUserTransfersQty}
            handleTransferUpdate={handleTransferUpdate}
            handleUnitCostChange={handleUnitCostChange}
            handleRequiredDateChange={handleRequiredDateChange}
          />
        }
        right={
          focusedLine ? (
            <ItemDetailsSidePane
              isHubAndSpoke={isHubAndSpoke}
              purchaseGroup={purchaseGroups?.find((pg) => pg.groupId === focusedLine.groupId)}
              itemInfo={focusedLine}
              allowMinMaxEdit={!!onMinMaxChange}
              onMinMaxChange={onMinMaxChange}
            />
          ) : (
            <POSummarySidePaneSidePane
              lineStatusCounts={lineStatusCounts}
              poSummaries={poSummaries}
              uniqueSuppliers={uniqueSuppliers}
            />
          )
        }
      />
    </>
  );
}

// we use a custom sorting function in multi location mode
// because we need to sort by multiple fields to preserve order of items and locations.
function sortTargetLineRows(
  targetLineRows: GenericTargetLineRow[],
  sortField: string,
  sortDir: SortOrder | undefined,
): GenericTargetLineRow[] {
  // early exit if nothing to sort
  if (targetLineRows.length === 0) return targetLineRows;

  const sortDirMultiplier = sortDir === 'descend' ? -1 : 1;
  const sortFn = getSortFunctionForSoftField(sortField, targetLineRows[0]);

  // sort by field, then itemId asc, then locationId asc
  return targetLineRows.sort((a, b) => {
    const sortCompare = sortFn(a, b) * sortDirMultiplier;
    if (sortCompare !== 0) return sortCompare;

    const itemIdCompare = a.itemId.localeCompare(b.itemId);
    if (itemIdCompare !== 0) return itemIdCompare;

    if (a.locationId && b.locationId) {
      const locationIdCompare = a.locationId.localeCompare(b.locationId);
      if (locationIdCompare !== 0) return locationIdCompare;
    }

    return 0;
  });
}

function getSortFunctionForSoftField(
  sortField: string,
  firstPurchaseRow: GenericTargetLineRow,
): (a: GenericTargetLineRow, b: GenericTargetLineRow) => number {
  // extCost is a computed field so we need to sort by (userQtyToOrder * unitCost)
  if (sortField === 'extCost') {
    return (a, b) => a.userQtyToOrder * a.userUnitCost - b.userQtyToOrder * b.userUnitCost;
  }
  // userTransfers is a computed field so we need to sort by sum of all the transfer lines
  if (sortField === 'userTransfers') {
    return (a, b) => getTotalUserTransfersQty(a.userTransfers) - getTotalUserTransfersQty(b.userTransfers);
  }

  const sortKey = sortField as keyof GenericTargetLineRow; // casting as typed field to appease typescript
  return typeof firstPurchaseRow[sortKey] === 'number'
    ? (a, b) => (a[sortKey] as number) - (b[sortKey] as number)
    : (a, b) => ((a[sortKey] as string) || '').localeCompare(b[sortKey] as string);
}

function getTotalUserTransfersQty(transfers?: PurchasingLineTransfer[]): number {
  return (transfers || []).reduce((total, transfer) => total + transfer.qtyToTransfer, 0);
}
