import React from 'react';

import { Link } from 'react-router-dom';

import { DashboardOutlined, EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';
import { NewQuoteButton } from 'components/recipes/NewQuoteButton';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatDate } from 'utils/formatting';
import { isAdmin } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { sortableDateColumn, sortableDollarColumn, sortableNumberColumn, typedColumn } from 'utils/tables';

import { SearchIndexSalesOrder } from 'types/search-collections';

export const QuoteListPage = () => {
  const { activeErpRole } = useGlobalApp();
  const isUserAdminRole = isAdmin(activeErpRole.foreignId, activeErpRole.name);

  const columns: (ColumnType<SearchIndexSalesOrder> | null)[] = [
    typedColumn({
      title: 'Quote No',
      dataIndex: 'order_no',
      render: (_, order: SearchIndexSalesOrder) => (
        <Link
          to={
            order.order_type === 'Order'
              ? routes.orders.orderDetails(order.order_no)
              : routes.orders.quoteDetails(order.order_no)
          }
        >
          {order.order_no}
        </Link>
      ),
    }),
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <ErpOrderStatusBadge erpOrderStatus={status.toLowerCase() as ErpOrderStatus} />,
    },
    sortableDateColumn({
      title: 'Quote Date',
      dataIndex: 'order_date',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (dt: string) => formatDate(dt),
    }),
    {
      title: 'Customer ID',
      dataIndex: 'customer_id',
      render: (id: string) => <Link to={routes.sales.customerDetails(id)}>{id}</Link>,
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
    },
    sortableDollarColumn({
      title: 'Total Amount',
      dataIndex: 'total_value',
      sorter: true,
    }),
    sortableDollarColumn({
      title: 'GM',
      dataIndex: 'gross_margin',
      sorter: true,
    }),
    sortableNumberColumn({ title: 'Line Count', dataIndex: 'item_count', sorter: true }),
    {
      title: '',
      dataIndex: 'order_no',
      render: (orderNo: string) => ButtonLink(routes.orders.quoteDetails(orderNo)),
    },
  ].filter(filterCostAndGM);

  const valueFacets: (ValueFacet<SearchIndexSalesOrder> | null)[] = [
    {
      title: 'Status',
      field: 'status',
      icon: <DashboardOutlined />,
    },
    {
      field: 'order_type',
      hidden: true,
      defaultValue: 'quote',
    },
    {
      title: 'Quote Taker',
      field: 'taker',
      queryPlaceholder: 'Search takers',
      icon: <UserOutlined />,
    },
    isUserAdminRole
      ? {
          title: 'Sales Rep',
          queryPlaceholder: 'Search sales reps',
          field: 'salesrep_name',
          icon: <UserOutlined />,
        }
      : null,
    {
      title: 'Location',
      field: 'location_name',
      queryPlaceholder: 'Search locations',
      icon: <EnvironmentOutlined />,
    },
  ];

  return (
    <SearchFrame<SearchIndexSalesOrder>
      title={
        <InfoTooltip title="Recurrency imports 1 year of quotes for search, but includes all of them when calculating statistics.">
          Quotes
        </InfoTooltip>
      }
      indexName={SearchIndexName.SalesOrders}
      queryPlaceholder="Search recent quotes by order, customer, ship to, PO, salesrep, or location"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      headerActions={<NewQuoteButton />}
    />
  );
};
