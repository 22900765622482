import React, { useCallback, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { TxnDTO } from '@recurrency/core-api-schema/dist/payments/getTransactions';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { AsyncCursorPaginationTable } from 'components/AsyncTable';
import { useCursorPaginationTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Badge } from 'components/Badge';
import { Container } from 'components/Container';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { PageHeader } from 'components/PageHeader';

import { capitalize, formatDate, formatCents, convertSnakeCaseToSpaceSplit } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';

import { ISODateStr, PaymentsTransactionsHashState } from 'types/hash-state';

interface DateRange {
  startDate?: ISODateStr;
  endDate?: ISODateStr;
}

const columns: ColumnType<TxnDTO>[] = [
  {
    title: 'Customer',
    dataIndex: 'customerId',
    render: (customerId: string) =>
      customerId ? (
        <Link to={routes.sales.customerDetails(customerId.split('||')[1])}>{customerId.split('||')[1]}</Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Cardholder',
    dataIndex: 'billingPayerName',
    render: (_: any, record: TxnDTO) => `${record.billingPayerName || '-'}`,
  },
  {
    title: 'Card',
    dataIndex: 'creditCardDetails',
    render: (card: any, record: TxnDTO) =>
      record.creditCardDetails ? `${capitalize(card.brand) || '-'}-${card?.last4 || '-'}` : '-',
  },
  {
    title: 'Payment Date',
    dataIndex: 'created',
    render: (_: any, record: TxnDTO) => formatDate(record.created),
  },
  {
    title: 'Order',
    dataIndex: 'orderId',
    render: (orderId: string) => (orderId ? <Link to={routes.orders.orderDetails(orderId)}>{orderId}</Link> : '-'),
  },
  {
    title: 'Invoice',
    dataIndex: 'invoiceId',
    render: (_: any, record: TxnDTO) => `${record.invoiceId || '-'}`,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (_: any, record: TxnDTO) => <Badge label={convertSnakeCaseToSpaceSplit(record.type)} />,
  },
  {
    title: 'Amount Paid',
    dataIndex: 'gross',
    render: (_: any, record: TxnDTO) => `${formatCents(record.gross)}`,
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    render: (_: any, record: TxnDTO) => `${formatCents(record.fee)}`,
  },
  {
    title: 'Net',
    dataIndex: 'net',
    render: (_: any, record: TxnDTO) => `${formatCents(record.net)}`,
  },
];

export function PayoutDetailsPage() {
  // Define local and hash states
  const [hashState, updateHashState] = useHashState<PaymentsTransactionsHashState>();
  const dateRange = useMemo(
    (): DateRange => ({
      startDate: hashState.startDate,
      endDate: hashState.endDate,
    }),
    [hashState.startDate, hashState.endDate],
  );

  const handleDateRangeChange = useCallback(
    (dates, _) => {
      updateHashState({
        startDate: dates ? dates[0]?.toISOString() : undefined,
        endDate: dates ? dates[1]?.toISOString() : undefined,
      });
    },
    [updateHashState],
  );

  // state updates
  const tableProps = useCursorPaginationTableProps({
    schema: schemas.payments.getTransactions,
    queryParams: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      payoutId: hashState.payoutId,
    },
  });

  useEffect(() => {
    tableProps.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashState]);

  // filter out the payout item
  tableProps.items = tableProps.items.filter((item) => item.type !== 'payout');

  return (
    <Container>
      {hashState.payoutId && (
        <PageHeader
          title={`${formatCents(hashState.amount)} on ${formatDate(hashState.createDate)}`}
          entity={{ kind: 'Payout' }}
        />
      )}
      <FilterBarBox dividerLine>
        <DateRangePicker
          onChange={handleDateRangeChange}
          format="MM/DD/YYYY"
          value={
            hashState.startDate && hashState.endDate
              ? [moment(hashState.startDate), moment(hashState.endDate)]
              : undefined
          }
        />
      </FilterBarBox>
      <AsyncCursorPaginationTable
        columns={columns}
        rowKey={(record) => record.id}
        tableProps={{
          isLoading: tableProps.isLoading,
          items: tableProps.items,
          hasNextPage: tableProps.hasNextPage,
          hasPrevPage: tableProps.hasPrevPage,
          goNextPage: tableProps.goNextPage,
          goPrevPage: tableProps.goPrevPage,
        }}
      />
    </Container>
  );
}
