import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { IntegratedErps, RecurrencyReplenishmentMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { Button, Radio, Space } from 'antd';

import { ItemSetupMinMaxRadioGroup } from 'pages/planning/OnboardingPage/ItemSetupMinMaxRadioGroup';

import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { NotificationLink } from 'components/Links';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { capitalize, formatNumber, getErpName, splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';
import { PlanningModalType, track, TrackEvent } from 'utils/track';

import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import { PositiveNumberInput } from './PositiveNumberInput';
import { getPlanningListSelectionFromFilterSelection, MultiModalSelection } from './utils';

enum MinMaxSubmissionType {
  Recommended = 'recommended',
  RecommendedMinMax = 'recommended min/max',
  Custom = 'custom',
}

export function MultiUpdateReplenishmentSettingsModal({
  selection,
  onClose,
  searchIndexReload,
  isOpOqVariant,
  isOnboardingVariant = false,
}: {
  selection: MultiModalSelection;
  onClose: (isSubmit: boolean) => void;
  searchIndexReload: () => void;
  isOpOqVariant: boolean;
  isOnboardingVariant?: boolean;
}) {
  const [customMin, setCustomMin] = useState<number | undefined>();
  const [customMax, setCustomMax] = useState<number | undefined>();
  const [submissionType, setSubmissionType] = useState<MinMaxSubmissionType>(MinMaxSubmissionType.Recommended);
  const { activeTenant } = useGlobalApp();

  /** always use custom min value or recommended min */
  const getExportedMin = (record: SearchPlanningDTO): number => {
    if (submissionType === MinMaxSubmissionType.Custom) {
      // This is required to be defined by the time the user submits
      return customMin!;
    }
    return record.recommended_min;
  };

  /** use custom max values for custom, recommended max for min/max versions and current max for op/oq */
  const getExportedMax = (record: SearchPlanningDTO): number => {
    if (submissionType === MinMaxSubmissionType.Custom) {
      // This is required to be defined by the time the user submits
      return customMax!;
    }
    if (isOpOqVariant && submissionType === MinMaxSubmissionType.Recommended) {
      return record.current_max;
    }
    return record.recommended_max;
  };

  /** if it is min/max, always specify min/max.
   * if it is op/oq but recommended min/max, then set min/max
   * otherwise, return op/oq
   */
  const exportedReplenishmentMethod = useMemo(() => {
    if (!isOpOqVariant) {
      return RecurrencyReplenishmentMethod.MinMax;
    }
    if (submissionType === MinMaxSubmissionType.RecommendedMinMax) {
      return RecurrencyReplenishmentMethod.MinMax;
    }
    return RecurrencyReplenishmentMethod.OPOQ;
  }, [isOpOqVariant, submissionType]);

  const hasInvalidInputs = () => {
    if (submissionType === MinMaxSubmissionType.Custom) {
      return (
        customMin === undefined ||
        customMin < 0 ||
        customMax === undefined ||
        customMax < 0 ||
        (!isOpOqVariant && customMin > customMax)
      );
    }
    return false;
  };

  const handleMinMaxUpdate = async () => {
    const entityName = 'replenishment settings';
    const submitNotification = createSubmissionNotification({
      entityName: 'Replenishment Settings',
      // 1 minute + 30 sec per 5k records
      expectedWaitSeconds: 60 + Math.round(selection.count / 5_000) * 30,
      erpType: activeTenant.erpType,
      submittingMessage:
        activeTenant.erpType === IntegratedErps.P21
          ? `Updating ${entityName} to ${activeTenant.erpType.toUpperCase()}`
          : `Saving ${entityName}`,
    });
    const hasAsyncMinMaxExportsSetting = getTenantSetting(TenantSettingKey.FeatureAsyncMinMaxExports);

    try {
      onClose(true);

      if (selection.type === 'filter') {
        selection = await getPlanningListSelectionFromFilterSelection(selection, [
          'item_id',
          'item_uid',
          'location',
          'current_min',
          'current_max',
          'recommended_min',
          'recommended_max',
        ]);
      }

      const response = await coreApiFetch(schemas.purchasing.postUpdateItemLocationMinMax, {
        bodyParams: {
          updates: selection.items.map((record) => ({
            itemId: record.item_id,
            itemUid: record.item_uid,
            locationId: splitIdNameStr(record.location).foreignId,
            newReplenishmentMethod: exportedReplenishmentMethod,
            newMin: getExportedMin(record),
            newMax: getExportedMax(record),
          })),
        },
      });
      const { jobId } = response.data;

      track(TrackEvent.DemandPlanning_MinMax_Submission, {
        modalType: isOpOqVariant ? PlanningModalType.MultiOpOq : PlanningModalType.MultiMinMax,
        itemCount: selection.count,
        submissionType,
      });
      searchIndexReload();
      submitNotification.success({
        duration: 10,
        description:
          jobId && hasAsyncMinMaxExportsSetting
            ? (notificationKey) => (
                <>
                  <div>
                    {capitalize(entityName)} will export to {getErpName(activeTenant.erpType)} in background.
                  </div>
                  <NotificationLink notificationKey={notificationKey} to={routes.settings.exportJobs()}>
                    View Export Jobs
                  </NotificationLink>
                </>
              )
            : undefined,
      });
    } catch (err) {
      submitNotification.error(err);
    }
  };

  return (
    <Modal
      visible
      title={
        isOnboardingVariant
          ? `Setting Up ${formatNumber(selection.count)} Items to use Recurrency`
          : `Updating Replenishment Settings for ${formatNumber(selection.count)} Items`
      }
      onCancel={() => onClose(false)}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={handleMinMaxUpdate} type="primary" disabled={hasInvalidInputs()}>
            {activeTenant.erpType === IntegratedErps.P21 ? `Send to ${getErpName(activeTenant.erpType)}` : 'Save'}
          </Button>
        </>
      }
      width={900}
    >
      <FlexSpace direction="column" alignItems="stretch">
        {isOnboardingVariant && (
          <p
            className={css`
              margin-bottom: 20px;
            `}
          >
            {isTenantErpTypeP21(activeTenant.erpType) ? (
              <>
                Setting up an item to use Recurrency will change the replenishment method in your ERP to use Min/Max in
                addition to updating the minimum and maximum values. From there, the item will have continuous
                recommendations updated in the Planning page that can be reviewed. Learn more about setting up items in
                Recurrency here.
              </>
            ) : (
              <>
                Setting up an item to use Recurrency will update the minimum and maximum values in Recurrency. From
                there, the item will have continuous recommendations updated in the Planning page that can be reviewed.
                Learn more about setting up items in Recurrency here.
              </>
            )}
          </p>
        )}

        {isOnboardingVariant ? (
          <ItemSetupMinMaxRadioGroup
            value={submissionType}
            options={{ recommended: MinMaxSubmissionType.Recommended, custom: MinMaxSubmissionType.Custom }}
            onChange={setSubmissionType}
            customMin={customMin}
            customMax={customMax}
            setCustomMin={setCustomMin}
            setCustomMax={setCustomMax}
          />
        ) : (
          <RadioGroup
            value={submissionType}
            onChange={({ target: { value } }) => {
              if (value !== MinMaxSubmissionType.Custom) {
                setCustomMin(undefined);
                setCustomMax(undefined);
              }
              setSubmissionType(value);
            }}
          >
            <Space direction="vertical">
              <Radio value={MinMaxSubmissionType.Recommended}>Recommended {isOpOqVariant ? 'OP/OQ' : ''}</Radio>
              {isOpOqVariant && <Radio value={MinMaxSubmissionType.RecommendedMinMax}>Recommended Min/Max</Radio>}
              <Radio value={MinMaxSubmissionType.Custom}>
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  `}
                >
                  Custom {isOpOqVariant ? 'OP/OQ' : ''}
                  <PropertyListItem
                    label={isOpOqVariant ? 'Order Point' : 'Min'}
                    value={
                      <PositiveNumberInput
                        onChange={(inputValue) => {
                          setSubmissionType(MinMaxSubmissionType.Custom);
                          setCustomMin(inputValue);
                        }}
                        disabled={submissionType !== MinMaxSubmissionType.Custom}
                        value={customMin}
                      />
                    }
                  />
                  <PropertyListItem
                    label={isOpOqVariant ? 'Order Quantity' : 'Max'}
                    value={
                      <PositiveNumberInput
                        onChange={(inputValue) => {
                          setSubmissionType(MinMaxSubmissionType.Custom);
                          setCustomMax(inputValue);
                        }}
                        value={customMax}
                        disabled={submissionType !== MinMaxSubmissionType.Custom}
                        min={customMin}
                      />
                    }
                  />
                </div>
              </Radio>
            </Space>
          </RadioGroup>
        )}
        <DividerLine />
        <MultiUpdateReplenishmentSettingsTable
          isOnboardingVariant={isOnboardingVariant}
          selection={selection}
          bulkUpdate={{
            type: 'minMax',
            customMin,
            customMax,
            isOpOqVariant,
            submissionType,
          }}
        />
      </FlexSpace>
    </Modal>
  );
}
