import React from 'react';

import { useHistory } from 'react-router';

import { Button, Props } from 'components/Button';

export interface LinkButtonProps extends Props {
  route: string;
}

export const LinkButton = (props: LinkButtonProps) => {
  const { route, ...otherProps } = props;
  const history = useHistory();
  return (
    <Button
      onClick={() => {
        history.push(route);
      }}
      {...otherProps}
    />
  );
};
