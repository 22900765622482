import React, { forwardRef } from 'react';

import { css, CSSInterpolation, cx } from '@emotion/css';

/**
 * styled-components like function interface - styled(elem)`<cssStr>`
 * that calls @emotion/css under the hood.
 */
export function styled<ComponentT extends keyof JSX.IntrinsicElements | React.ComponentType<any>>(
  component: ComponentT,
): (
  template: TemplateStringsArray,
  ...cssArgs: Array<CSSInterpolation>
) => ComponentT extends keyof JSX.IntrinsicElements
  ? React.JSXElementConstructor<JSX.IntrinsicElements[ComponentT]>
  : ComponentT {
  return (template: TemplateStringsArray, ...cssArgs: Array<CSSInterpolation>): any => {
    const className = css(template, ...cssArgs);
    return forwardRef((props: any, ref) => {
      props = { ...props, className: cx(className, props.className), ref };
      return React.createElement(component, props);
    });
  };
}
