/** helper function to fetch last element of array */
export function arrLastElement<T>(arr: T[] | readonly T[]): T {
  return arr[arr.length - 1];
}

/** shallow array equal comparison of values */
export function arrIsEqual(a: Any[] | undefined | null, b: Any[] | undefined | null): boolean {
  if (a === b) {
    return true;
  }

  if (a === undefined || b === undefined || a === null || b === null) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

/** get unique elements from array  */
export function arrUnique<ItemT>(arr: ItemT[], key?: keyof ItemT): ItemT[] {
  return arr.filter(
    key
      ? (item, index) => arr.findIndex((i) => i[key] === item[key]) === index
      : (item, index) => arr.indexOf(item) === index,
  );
}

/**
 * Filter an array of objects bey specific values of a key
 * @example arrFilterByValues([{a: 1}, {a: 2}, {a: 3}], 'a', [1, 3]) => [{a: 1}, {a: 3}]
 */
export function arrFilterByValues<ItemT, KeyT extends keyof ItemT>(
  arr: ItemT[],
  key: KeyT,
  values: ItemT[KeyT][],
): ItemT[] {
  const picked: ItemT[] = [];
  for (const value of values) {
    const item = arr.find((i) => i[key] === value);
    if (item) {
      picked.push(item);
    }
  }
  return picked;
}

export type OptArr<T> = T[] | undefined;
export type OptVal<T> = T | undefined;

/** return [val] if value is defined, else undefined */
export function optArrFromVal<T>(val: OptVal<T>): OptArr<T> {
  return val !== undefined && val !== null ? [val] : undefined;
}

/** add value to optional array if not present */
export function optArrAddVal<T>(arr: OptArr<T>, val: OptVal<T>): OptArr<T> {
  if (val) {
    if (!arr) {
      return [val];
    }
    if (!arr.includes(val)) {
      return [...arr, val];
    }
  }
  return arr;
}

/** remove value from optional array, if array is empty, return undefined */
export function optArrRemoveVal<T>(arr: OptArr<T>, val: OptVal<T>): OptArr<T> {
  if (val && arr) {
    arr = arr.filter((v) => v !== val);
    if (arr.length === 0) {
      return undefined;
    }
  }
  return arr;
}

interface GroupedResult<T> {
  items: T[];
  [key: string]: unknown;
}

export function groupByFields<T, K extends keyof T>(
  array: T[],
  fields: K[],
): (GroupedResult<T> & { [key in K]: T[key] })[] {
  const result: Record<string, GroupedResult<T> & { [key in K]: T[key] }> = {};

  array.forEach((item) => {
    const key = fields.map((field) => String(item[field])).join('_');

    if (!result[key]) {
      const fieldValues = Object.fromEntries(fields.map((field) => [field, item[field]])) as { [key in K]: T[key] };
      result[key] = {
        items: [],
        ...fieldValues,
      };
    }
    result[key].items.push(item);
  });

  return Object.values(result);
}
