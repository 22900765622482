import React, { useState } from 'react';

import Form from 'antd/lib/form';

import { AsyncSelect, AsyncSelectVariant } from 'components/AsyncSelect';
import { ContactSelectOption, useContactsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

export function ComposeEmailModal({
  title,
  customerId,
  onClose,
}: {
  title: string;
  customerId: string;
  onClose: (contact?: { email: string; name: string }) => void;
}) {
  const [form] = Form.useForm();
  const [selectedContact, setSelectedContact] = useState<ContactSelectOption>();
  const contactsSelectProps = useContactsSelectProps({ customerId });

  return (
    <Modal
      visible
      title={title}
      width={600}
      onCancel={() => onClose()}
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                await form.validateFields();
                onClose(selectedContact ? { email: selectedContact.email, name: selectedContact.name } : undefined);
              } catch (err) {}
            }}
            type="primary"
          >
            Compose Email
          </Button>
        </>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="contact"
          label="Select Contact"
          rules={[{ required: true, message: 'Please select a contact' }]}
        >
          <AsyncSelect
            selectProps={contactsSelectProps}
            entityPlural="contacts"
            variant={AsyncSelectVariant.Search}
            onSelect={(_, option) => setSelectedContact(option as ContactSelectOption)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
