import React, { useState } from 'react';

import { ContainerOutlined, DownOutlined, UpOutlined, SearchOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import {
  RecommendedQuoteDTO,
  RecommendedQuotesListDTO,
  RecommendedQuotesRecordDTO,
  WorkflowState,
} from '@recurrency/core-api-schema/dist/ml/getRecommendedQuotes';
import Empty from 'antd/lib/empty';
import { colors } from 'theme/colors';
import { fontWeights } from 'theme/typography';

import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { SmallLoader } from 'components/Loaders';

import { formatNumber, formatUSDAbbreviated } from 'utils/formatting';

export function ShipToSelectColumn({
  recQuotes,
  selectedRecQuote,
  onSelectedRecQuoteChange,
  searchQuery,
  onSearchQueryChange,
}: {
  recQuotes: RecommendedQuotesListDTO | undefined;
  selectedRecQuote: RecommendedQuoteDTO | undefined;
  onSelectedRecQuoteChange: (recQuote: RecommendedQuoteDTO) => void;
  searchQuery: string;
  onSearchQueryChange: (searchQuery: string) => void;
}) {
  return (
    <div
      className={cx(
        'ShipToSelectColumn',
        css`
          flex: 1;
          align-self: flex-start;
          position: sticky;
          top: 120px;
          border: 1px solid ${colors.neutral[300]};
          border-radius: 8px;
          max-width: 400px;
        `,
      )}
    >
      <div
        className={cx(
          'searchBox',
          css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 20px;
            border-bottom: 1px solid ${colors.neutral[200]};
          `,
        )}
      >
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 16px;
            line-height: 20px;
            font-weight: ${fontWeights.bold};
          `}
        >
          <span>Customers</span>
        </div>
        <Input
          prefix={<SearchOutlined style={{ color: colors.neutral[300] }} />}
          type="search"
          value={searchQuery}
          placeholder="Search customers, ship tos and items"
          onChange={(ev) => onSearchQueryChange(ev.target.value)}
          size="small"
          className={css`
            width: 100%;
          `}
        />
      </div>
      <div
        className={cx(
          'scrollBox',
          css`
            min-height: 400px;
            height: calc(100vh - 330px);
            overflow-x: auto;
          `,
        )}
      >
        {recQuotes && recQuotes.items.length > 0 ? (
          recQuotes.items.map((customer, custIdx) => (
            <CustomerShipTosCell
              key={custIdx}
              customer={customer}
              selectedRecQuote={selectedRecQuote}
              onSelectedRecQuoteChange={onSelectedRecQuoteChange}
            />
          ))
        ) : (
          <div
            className={css`
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
            `}
          >
            {recQuotes ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results found." />
            ) : (
              <SmallLoader />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function CustomerShipTosCell({
  customer,
  selectedRecQuote,
  onSelectedRecQuoteChange,
}: {
  customer: RecommendedQuotesRecordDTO;
  selectedRecQuote: RecommendedQuoteDTO | undefined;
  onSelectedRecQuoteChange: (recQuote: RecommendedQuoteDTO) => void;
}) {
  const [isExpanded, setIsExpanded] = useState(true);
  const customerWithWorkflowStateCount = getCustomerWorkflowStateCounts(customer);

  return (
    <div
      className={css`
        line-height: 20px;
      `}
    >
      <div
        className={css`
          border-bottom: 1px solid ${colors.neutral[200]};
          padding: 12px 16px;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: row;
            gap: 4px;
            white-space: nowrap;
          `}
        >
          <div
            className={css`
              text-transform: uppercase;
              font-weight: ${fontWeights.bold};
              text-overflow: ellipsis;
              overflow: hidden;
            `}
            title={customer.customerName}
          >
            {customer.customerName}
          </div>
          <div
            className={css`
              color: ${colors.neutral[500]};
            `}
          >
            #{customer.customerId}
          </div>
          <div
            className={css`
              height: 20px;
              margin-left: auto;
            `}
          >
            <Button
              size="small"
              type="text"
              icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
        </div>
        <div
          className={css`
            color: ${colors.neutral[500]};
          `}
        >
          <div>{formatUSDAbbreviated(customer.potentialValue)} est. value</div>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              gap: 12px;
            `}
          >
            <span>
              <ContainerOutlined />{' '}
              {formatNumber(customerWithWorkflowStateCount.workflowStateCounts[WorkflowState.Open])} open
            </span>
          </div>
        </div>
      </div>
      {isExpanded
        ? customer.quotes.map((quote, quoteIdx) => (
            <div
              key={quoteIdx}
              className={css`
                padding: 12px 16px 12px 24px;
                border-bottom: 1px solid ${colors.neutral[200]};
                border-left: 3px solid ${selectedRecQuote === quote ? colors.primary[500] : `transparent`};
                cursor: pointer;

                &:hover {
                  background-color: ${colors.neutral[75]};
                }
              `}
              onClick={() => onSelectedRecQuoteChange(quote)}
            >
              <div
                className={css`
                  display: flex;
                  flex-direction: row;
                  gap: 4px;
                  text-transform: uppercase;
                `}
              >
                <div
                  className={css`
                    font-weight: ${fontWeights.medium};
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  `}
                  title={quote.shipToName || quote.shipToId}
                >
                  {quote.shipToName || quote.shipToId}
                </div>
                {
                  // SAPB1 doesn't have shipToName, id is the name
                  quote.shipToId && quote.shipToName ? (
                    <div
                      className={css`
                        color: ${colors.neutral[500]};
                      `}
                    >
                      #{quote.shipToId}
                    </div>
                  ) : null
                }
              </div>
              <div className={css``} title={quote.shipToAddress}>
                {quote.shipToAddress}
              </div>
              <div
                className={css`
                  color: ${colors.neutral[500]};
                `}
              >
                <div>{formatUSDAbbreviated(quote.potentialValue)} est. value</div>
                <div
                  className={css`
                    display: flex;
                    flex-direction: row;
                    gap: 12px;
                  `}
                >
                  <span>
                    <ContainerOutlined />{' '}
                    {customerWithWorkflowStateCount.shipToCounts[quoteIdx].workflowStateCounts[WorkflowState.Open]} open
                  </span>
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
}

interface CustomerWorkflowStateCounts {
  workflowStateCounts: Record<WorkflowState, number>;
  shipToCounts: ShipToWorkflowStateCounts[];
}
interface ShipToWorkflowStateCounts {
  workflowStateCounts: Record<WorkflowState, number>;
}

function getCustomerWorkflowStateCounts(customer: RecommendedQuotesRecordDTO): CustomerWorkflowStateCounts {
  const zeroStateCounts = () => ({
    [WorkflowState.NeedsReview]: 0,
    [WorkflowState.Open]: 0,
    [WorkflowState.Won]: 0,
    [WorkflowState.Lost]: 0,
    [WorkflowState.Rejected]: 0,
  });

  const shipToCounts: ShipToWorkflowStateCounts[] = customer.quotes.map((shipTo) => {
    const workflowStateCounts = zeroStateCounts();
    for (const lineItem of shipTo.lineItems) {
      workflowStateCounts[lineItem.workflowState ?? WorkflowState.Open]++;
    }
    return { workflowStateCounts };
  });

  const workflowStateCounts = zeroStateCounts();
  const customerWorkflowStateCounts: CustomerWorkflowStateCounts = {
    workflowStateCounts,
    shipToCounts,
  };

  for (const shipToCount of shipToCounts) {
    workflowStateCounts[WorkflowState.Open] += shipToCount.workflowStateCounts[WorkflowState.Open];
  }

  return customerWorkflowStateCounts;
}
