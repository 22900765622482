import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { ButtonProps, notification } from 'antd';

import { AsyncButton } from 'components/Button/AsyncButton';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { PdfDeliveryType, PdfDocumentType, track, TrackEvent } from 'utils/track';

import PdfLoadingIcon from 'assets/svgs-raw/pdf-loading.svg';

// foreignId for draft quotes is just the Recurrency id for the draft
export const openPdfInNewTab = async ({ foreignId, type }: { foreignId: string; type: PdfDocumentType }) => {
  track(TrackEvent.Components_PdfButtonClick, {
    documentType: type,
    deliveryType: PdfDeliveryType.NewTab,
  });
  let pdfTab;
  try {
    // most browsers block new window/new tab unless stack trace is linked to a user click
    // if we open tab after a network call, then it's likely to get blocked
    // so we immediately open a tab, and change the location.href once we have the new url
    // see https://stackoverflow.com/questions/2587677/avoid-browser-popup-blockers
    pdfTab = window.open('', '_blank');

    if (pdfTab) {
      pdfTab.document.write(`
      <div
        style="
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-family:Arial, sans-serif;
            line-height: 12px
          "
        >
        <div style="text-align: center">
          <div style="margin-left:-20px">${PdfLoadingIcon}</div>
          <p style="font-size: 24px;">Generating your PDF...</p>
          <p style="font-size: 16px;">This could take a few seconds</p>
          </div>
      </div>
      `);

      try {
        const response =
          type === PdfDocumentType.Quote
            ? await coreApiFetch(schemas.salesQuotes.getSalesQuotePdf, {
                pathParams: { salesQuoteId: foreignId },
                queryParams: { sendEmail: false },
              })
            : type === PdfDocumentType.DraftQuote
            ? await coreApiFetch(schemas.salesQuoteDrafts.getSalesQuoteDraftPdf, {
                pathParams: { salesQuoteDraftId: foreignId },
                queryParams: { sendEmail: false },
              })
            : type === PdfDocumentType.Order || type === PdfDocumentType.WorkOrder
            ? await coreApiFetch(schemas.salesOrders.getSalesOrderPdf, {
                pathParams: { salesOrderId: foreignId },
                queryParams: { sendEmail: false },
              })
            : type === PdfDocumentType.Invoice
            ? await coreApiFetch(schemas.invoices.getInvoicePdf, {
                pathParams: { invoiceId: foreignId },
                queryParams: { sendEmail: false },
              })
            : type === PdfDocumentType.PackingList
            ? await coreApiFetch(schemas.invoices.getPackingListPdf, {
                pathParams: { invoiceId: foreignId },
                queryParams: { sendEmail: false },
              })
            : type === PdfDocumentType.PurchaseOrder
            ? await coreApiFetch(schemas.purchaseOrders.getPurchaseOrderPdf, {
                pathParams: { purchaseOrderId: foreignId },
                queryParams: { sendEmail: false },
              })
            : (() => {
                throw new Error(`Unknown document type: ${type} to open PDF`);
              })();
        pdfTab.location.href = response.data.pdfUrl;
      } catch (err) {
        // If an error occurs, close the window and rethrow
        pdfTab.close();
        throw err;
      }
    } else {
      // This should rarely ever happen, but if someone has force blocked popups
      // then it's a nice message to show why the pdf tab didn't open
      notification.error({
        message: `Couldn't open PDF in a new tab`,
        description: 'Please enable pop-ups in your browser',
      });
    }
  } catch (err) {
    captureAndShowError(err, 'Error while opening PDF');
  }
};

export const OpenPdfButton = ({
  foreignId,
  type,
  props,
  content = 'Open PDF',
}: {
  foreignId: string;
  type: PdfDocumentType;
  props?: ButtonProps;
  content?: React.ReactNode;
}) => (
  <AsyncButton {...props} onClick={() => openPdfInNewTab({ foreignId, type })}>
    {content}
  </AsyncButton>
);
