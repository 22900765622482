import React, { useEffect } from 'react';

import { message, Space } from 'antd';

import { Button } from 'components/Button';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { captureAndShowError } from 'utils/error';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';
import { zendeskAnonymousRequest } from 'utils/zendesk';

enum UpgradeIntent {
  UpgradeNow = 'upgrade now',
  LearnMore = 'learn more',
}

export const UpgradeButtons = ({ simple = false }: { simple?: boolean }) => {
  const { activeTenant, activeUser } = useGlobalApp();

  // On initial mount, track that user has loaded upgrade info
  useEffect(() => {
    track(TrackEvent.FreeTrial_ViewUpgradeInfo, {});
  }, []);

  const onRequestUpgrade = async (intent: UpgradeIntent) => {
    try {
      await zendeskAnonymousRequest({
        requesterName: activeUser.fullName,
        subject: `Requesting upgrade for ${activeTenant.name}`,
        body: `${activeUser.fullName} (${activeUser.email}) would like ${activeTenant.name} to upgrade to the full version of Recurrency. They clicked the ${intent} link on ${window.location.pathname}.`,
      });
      track(TrackEvent.Nav_CtaClick, {
        ctaName: intent === UpgradeIntent.UpgradeNow ? CtaName.FreeTrialUpgradeNow : CtaName.FreeTrialLearnMore,
        ctaType: CtaType.Button,
      });
      message.success(
        `Upgrade ${
          intent === UpgradeIntent.LearnMore ? 'information' : ''
        } requested! Our sales team will be in touch.`,
      );
    } catch (err) {
      captureAndShowError(err, `Error while requesting upgrade, please try again later.`);
    }
  };

  return (
    <Space>
      <Button type="primary" size="small" onClick={() => onRequestUpgrade(UpgradeIntent.UpgradeNow)}>
        Request Upgrade
      </Button>
      {!simple && (
        <Button size="small" onClick={() => onRequestUpgrade(UpgradeIntent.LearnMore)}>
          Learn More
        </Button>
      )}
    </Space>
  );
};
