import React from 'react';

import { css } from '@emotion/css';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useSearchIndexTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { linkablePhoneNumber, linkableEmail, linkableAddress } from 'components/Links';
import { NewShipToButton } from 'components/recipes/NewShipToButton';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { sortableIdColumn } from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { SearchIndexShipTo } from 'types/search-collections';

export const ShipTosTable = ({
  customerId,
  companyId,
  showNewShipToButton,
}: {
  customerId: string;
  companyId: string;
  showNewShipToButton: boolean;
}) => {
  const { activeTenant } = useGlobalApp();

  const columns: ColumnType<SearchIndexShipTo>[] = [
    sortableIdColumn({
      title: isTenantErpTypeP21(activeTenant.erpType) ? 'Ship To ID' : 'Ship To',
      dataIndex: 'ship_to_id',
      sorter: false,
    }),
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Ship To',
          dataIndex: 'ship_to_name',
        }
      : undefined,
    {
      title: 'Physical Address',
      dataIndex: 'ship_to_address',
      render: (address: string) => linkableAddress(address),
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Email',
          dataIndex: 'ship_to_email',
          render: (email: string) => linkableEmail(email),
        }
      : undefined,
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Phone',
          dataIndex: 'ship_to_phone',
          render: (phone: string) => linkablePhoneNumber(phone),
        }
      : undefined,
  ].filter(truthy);

  const tableProps = useSearchIndexTableProps<SearchIndexShipTo>({
    indexName: SearchIndexName.ShipTos,
    options: {
      filters: {
        customer_id: [customerId],
        company_id: [companyId],
      },
    },
  });

  return (
    <>
      {showNewShipToButton ? (
        <div
          className={css`
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 10px;
          `}
        >
          <NewShipToButton
            size="small"
            type="primary"
            initialValues={{
              companyId,
              customerId,
            }}
            onSubmit={tableProps.reload}
          />
        </div>
      ) : null}
      <AsyncTable
        tableProps={tableProps}
        columns={columns}
        rowKey="shipToId"
        loading={tableProps.isLoading || tableProps.isReloading}
      />
    </>
  );
};
