import React from 'react';

import { css } from '@emotion/css';
import { Switch } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowFreeProduct } from 'utils/roleAndTenant';

export const RecommendedMaxToggle = ({
  isChecked,
  onToggle,
  labelCssStyle,
  tooltipPlacement = 'top',
}: {
  isChecked: boolean;
  onToggle: (checked: boolean) => void;
  labelCssStyle?: string;
  tooltipPlacement?: TooltipPlacement;
}) => {
  const { activeTenant } = useGlobalApp();
  const shouldShowToggle = !shouldShowFreeProduct(activeTenant);
  return shouldShowToggle ? (
    <Tooltip
      slow
      title="Instead of using the maximum saved in your ERP, this will use Recurrency's recommended maximum for determining overstocked items"
      placement={tooltipPlacement}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          width: fit-content;
        `}
      >
        <div
          className={
            labelCssStyle ??
            css`
              margin-right: 8px;
            `
          }
        >
          Use recommended max
        </div>
        <Switch
          className={css`
            align-self: center;
          `}
          size="small"
          checked={isChecked}
          onClick={onToggle}
        />
      </div>
    </Tooltip>
  ) : null;
};
