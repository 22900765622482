export const zIndices = {
  // anything position: fixed gets its own stacking context,
  // hence needs zIndex to avoid overlapping issues
  // ant-tables columns have z-index of 1, so using 10 for nav
  primaryNav: 10,
  secondaryNav: 10,
  tertiaryNav: 10,
  // because .ant-modal-mask has zIndex of 1000
  portal: 1001,
};

export type ThemeZIndices = typeof zIndices;
