import React from 'react';

import { css } from '@emotion/css';
import { Avatar as AntAvatar } from 'antd';
import { theme } from 'theme';

import { styled } from 'utils/styledComponent';

import RecurrencyLogomark from 'assets/svgs/logomark.svg';
import UserProfile from 'assets/svgs/user-profile.svg';
import RecurrencyWordmark from 'assets/svgs/wordmark.svg';

import { PRIMARY_NAVIGATION_HEIGHT } from '../navConstants';

export const Container = styled('div')`
  width: 100%;
  height: ${PRIMARY_NAVIGATION_HEIGHT}px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndices.primaryNav};

  background-color: ${theme.colors.primary[700]};
  transition: all ${theme.transitions.fast};

  // see https://headway.gitbooks.io/docs/content/widget.html#styling-the-badge
  .HW_badge_cont {
    position: absolute;
    top: -0.9rem;
    left: 0.25rem;
  }

  .HW_badge {
    border: 3px solid #1e4158;
    height: 21px;
    width: 21px;
  }
`;

export const IconOnlyButton = styled('div')`
  color: ${theme.colors.primary[100]};
  font-size: 1.25rem;
  position: relative;
  margin-right: 8px;
  margin-left: 4px;
  cursor: pointer;
`;

export const Content = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <div
    className={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;

      button {
        background: rgba(255, 255, 255, 0.15);
        color: white;
        border: none;
        ${isDesktopView && `padding: 0 8px`};
      }
      margin-bottom: 20px;
    `}
  >
    {children}
  </div>
);

export const ContentChild = ({
  flex,
  isDesktopView,
  isMobileSearchOpen,
  children,
}: {
  flex: number;
  isDesktopView: boolean;
  isMobileSearchOpen?: boolean;
  children: React.ReactNode;
}) => (
  <div
    className={css`
      display: flex;
      align-items: center;
      justify-content: ${isDesktopView ? 'center' : 'flex-start'};
      flex: ${flex};
      padding: ${isMobileSearchOpen ? 8 : 0}px;
    `}
  >
    {children}
  </div>
);

export const Logo = styled(RecurrencyWordmark)`
  height: 27px;
`;

export const MobileLogo = styled(RecurrencyLogomark)`
  height: 27px;
`;

export const UserProfileIcon = styled(UserProfile)`
  width: 16px;
  height: 16px;
  fill: ${theme.colors.primary[700]};
`;

export const Avatar = styled(AntAvatar)`
  background-color: ${theme.colors.primary[100]};
`;

export const LogoWrapper = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 24px;
  height: 100%;
`;

export const Icon = styled('div')`
  display: flex;
  width: 32px;
  justify-content: center;
  align-self: center;
`;

export const SmallIcon = styled(Icon)`
  font-size: 0.6em;
`;

export const MenuItem = styled('div')`
  cursor: pointer;
  padding: 8px;
`;

export const MenuItemChild = styled('div')`
  color: ${theme.colors.primary[100]};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Label = styled('div')`
  font-weight: 400;
  white-space: nowrap;
  margin-left: 8px;
`;

export const SearchInput = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <div
    className={css`
      flex: 1;
      max-width: ${isDesktopView ? '70' : '100'}%;
      display: flex;
      justify-content: flex-end;
    `}
  >
    {children}
  </div>
);

export const DropdownWrapper = styled('div')`
  margin-right: 8px;
`;

export const LeftPadButton = styled('div')`
  margin-left: 8px;
`;

export const CreateButton = styled(DropdownWrapper)`
  margin-left: 8px;
  margin-right: 16px;
`;
