import React, { useState } from 'react';

import Form from 'antd/lib/form';

import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { Select } from 'components/Select';

enum Reasons {
  Other = 'Other',
}

export function ReasonChooserModal({
  title,
  reasons,
  onClose,
}: {
  title: string;
  reasons: string[];
  onClose: (reason?: string | undefined) => void;
}) {
  const [form] = Form.useForm();
  const [selectedReason, setSelectedReason] = useState<string | undefined>();
  const [otherReason, setOtherReason] = useState('');

  return (
    <Modal
      visible
      title={title}
      width={600}
      onCancel={() => onClose()}
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                await form.validateFields();
                onClose(selectedReason === Reasons.Other ? otherReason : selectedReason);
              } catch (err) {}
            }}
            type="primary"
          >
            OK
          </Button>
        </>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item name="reason" label="Reason" rules={[{ required: true, message: 'Please select a reason' }]}>
          <Select
            options={reasons.map((reason) => ({ value: reason }))}
            value={selectedReason}
            onChange={setSelectedReason}
            placeholder="Select a reason"
          />
        </Form.Item>
        {selectedReason === Reasons.Other ? (
          <Form.Item
            name="otherReason"
            label="Other Reason"
            rules={[{ required: true, message: 'Please enter a reason' }]}
          >
            <Input
              placeholder="Enter other reason"
              value={otherReason}
              onChange={(ev) => setOtherReason(ev.target.value)}
            />
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
}
