import React from 'react';

import { DeleteOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TargetGroupType } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { PurchaseGroupWithTargetDTO } from '@recurrency/core-api-schema/dist/purchaseGroups/getPurchaseGroups';
import { PurchaseTargetDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargets';
import { GetSuppliersQueryParams } from '@recurrency/core-api-schema/dist/suppliers/getSuppliers';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Counter } from 'components/Counter';
import { FlexSpace } from 'components/FlexSpace';
import { ConfirmModal } from 'components/Modal/ConfirmModal';
import { EditTargetActionButton } from 'components/recipes/EditTargetActionButton';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { Tooltip } from 'components/Tooltip';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { formatDate, formatNumber, formatPercent, formatUSD, truncate } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';
import {
  asKeyOf,
  sortableDateColumn,
  sortableDollarColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortablePercentColumn,
  sortDirections,
} from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

import { editButtonOnClick, TargetTypeLabels } from '../utils';
import { SpecialOrderFlag } from './components/SpecialOrderFlag';
import { PurchaseGroupsHashState } from './PurchaseGroupsPage.types';

export function usePurchaseGroupsTableColumns({
  hashState,
  handleTargetValueChange,
  reloadPurchaseGroups,
  onPressEdit,
  onPressOpen,
}: {
  hashState: PurchaseGroupsHashState;
  handleTargetValueChange: () => void;
  reloadPurchaseGroups: () => void;
  onPressEdit: (record: PurchaseGroupWithTargetDTO) => void;
  onPressOpen: (groupId: string) => void;
}) {
  async function handleDelete(groupId: string, groupName: string) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Purchase Group',
      expectedWaitSeconds: 5,
      submittingMessage: 'Deleting Purchase Group',
      fireInProgressNotification: false,
    });

    try {
      const didUserConfirm = await showAsyncModal(ConfirmModal, {
        title: `Delete purchase group ${groupName}?`,
        content: 'Are you sure you want to delete this purchase group? This action cannot be undone.',
        okText: 'Delete',
        okType: 'primary',
        width: 450,
      });

      if (!didUserConfirm) return;

      submitNotification.inProgress();
      await coreApiFetch(schemas.purchaseGroups.deletePurchaseGroup, {
        pathParams: {
          groupId,
        },
      });
      submitNotification.success({ successMessage: 'Purchase Group deleted' });
      reloadPurchaseGroups();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  const { targetGroupType } = hashState;

  const showSpecialOrders = getTenantSetting(TenantSettingKey.FeaturePurchasingShowSpecialOrders);

  const tableColumns: PersistedColumn<PurchaseGroupWithTargetDTO>[] = [
    sortableIdColumn({
      title: 'Group',
      dataIndex: 'groupName',
      sorter: true,
      required: true,
      settingKey: 'groupName',
      render: (_, record: PurchaseGroupWithTargetDTO) => (
        <FlexSpace justify="space-between">
          <ConditionalWrapper
            condition={record.groupName.length >= 18}
            wrapper={(children) => (
              <Tooltip slow title={record.groupName} placement="top">
                <span
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  {children}
                </span>
              </Tooltip>
            )}
          >
            <span
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              {truncate(record.groupName, 20)}
            </span>
          </ConditionalWrapper>
          <Tooltip
            overlayStyle={{ whiteSpace: 'pre-line' }}
            slow
            title={`${record.purchaseLocationId}: ${record.purchaseLocationName}
            ${
              record.spokeLocations ? `${record.spokeLocations.map((l) => `${l.foreignId}: ${l.name}`).join('\n')}` : ''
            }`}
            placement="right"
          >
            <Counter
              className={css`
                margin-left: 5px;
              `}
              value={(record.spokeLocations?.length || 0) + 1}
              selected={false}
              variant="primary"
            />
          </Tooltip>
        </FlexSpace>
      ),
    }),
    sortableIdColumn({
      title: 'Supplier',
      dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('supplierId'),
      sorter: true,
      settingKey: 'supplierId',
      render: (_, record: PurchaseGroupWithTargetDTO) => `${record.supplierId}: ${record.supplierName}`,
    }),
    sortableIdColumn({
      title: 'Purchase Location',
      dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('purchaseLocationId'),
      sorter: true,
      settingKey: 'purchaseLocationId',
      render: (_, record: PurchaseGroupWithTargetDTO) => `${record.purchaseLocationId}: ${record.purchaseLocationName}`,
    }),
    ...(targetGroupType === TargetGroupType.ReviewCycle
      ? [
          sortableDateColumn({
            title: 'Last Reviewed',
            dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('lastReviewDate'),
            align: 'right' as const,
            sorter: true,
            settingKey: 'lastReviewDate',
          }),
          sortableNumberColumn({
            title: 'Review Cycle (Days)',
            dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('reviewCycleDays'),
            align: 'right' as const,
            sorter: true,
            settingKey: 'reviewCycleDays',
            render: (reviewCycleDays: number, record: PurchaseGroupWithTargetDTO) => (
              <EditTargetActionButton
                value={reviewCycleDays}
                onClick={() => editButtonOnClick(record.supplierId, record.purchaseLocationId, handleTargetValueChange)}
              />
            ),
          }),
          sortableDateColumn({
            title: 'Next Review',
            dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('nextReviewDate'),
            align: 'right' as const,
            sorter: true,
            settingKey: 'nextReviewDate',
          }),
        ]
      : [
          {
            title: 'Current',
            dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('currentValue'),
            sorter: true,
            sortDirections,
            align: 'right' as const,
            settingKey: 'currentValue',
            render: (_: unknown, record: PurchaseGroupWithTargetDTO) =>
              record.targetType === 'dollars' ? formatUSD(record.currentValue) : formatNumber(record.currentValue, 0),
          },
          {
            title: 'Target',
            dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('targetValue'),
            sorter: true,
            align: 'right' as const,
            settingKey: 'targetValue',
            render: (_: unknown, record: PurchaseGroupWithTargetDTO) => (
              <EditTargetActionButton
                value={
                  record.targetType === 'dollars' ? formatUSD(record.targetValue) : formatNumber(record.targetValue)
                }
                onClick={() => editButtonOnClick(record.supplierId, record.purchaseLocationId, handleTargetValueChange)}
              />
            ),
          },
          {
            title: 'Target Type',
            dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('targetType'),
            align: 'right' as const,
            settingKey: 'targetType',
            render: (_: unknown, record: PurchaseGroupWithTargetDTO) =>
              record.targetType ? TargetTypeLabels[record.targetType] : '-',
          },
          sortablePercentColumn({
            title: 'Target %',
            dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('goalPercent'),
            settingKey: 'goalPercent',
            render: (_, record: PurchaseGroupWithTargetDTO) => formatPercent(record.goalPercent, 0),
            sorter: true,
            sortDirections,
          }),
        ]),
    {
      title: 'Status',
      dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('status'),
      settingKey: 'status',
      render: (_, record) => <StatusBadge status={BadgeStatus[record.status]} />,
    },
    sortableDateColumn({
      title: 'Last Ordered',
      dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('lastOrderDate'),
      align: 'right',
      sorter: true,
      settingKey: 'lastOrderDate',
      render: (_, record: PurchaseGroupWithTargetDTO) => `${formatDate(record.lastOrderDate)}`,
    }),
    sortableDollarColumn({
      title: 'PO Cost',
      dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('orderCost'),
      sorter: true,
      settingKey: 'orderCost',
      render: (_, record: PurchaseGroupWithTargetDTO) => `${formatUSD(record.orderCost)}`,
    }),
    sortableNumberColumn({
      title: 'Lines',
      settingKey: 'numLines',
      dataIndex: asKeyOf<PurchaseGroupWithTargetDTO>('numLines'),
      sorter: true,
      render: (_, row) => (
        <>
          {showSpecialOrders && row?.hasSpecialOrder && <SpecialOrderFlag />}
          {row.numLines}
        </>
      ),
    }),
    {
      title: 'Actions',
      width: '100px',
      align: 'left',
      settingKey: 'actions',
      render: (_, record) => (
        <FlexSpace gap={5}>
          <Tooltip title="Edit">
            <Button
              key="edit-button"
              size="small"
              disabled={hashState.selectedSupplierLocations && hashState.selectedSupplierLocations?.length > 0}
              onClick={() => onPressEdit(record)}
            >
              <EditOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Delete">
            <Button key="delete-button" size="small" onClick={() => handleDelete(record.groupId, record.groupName)}>
              <DeleteOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Lines">
            <AsyncButton
              key="open-po-button"
              size="small"
              onClick={() => onPressOpen(record.groupId)}
              spinnerPosition="absolute"
            >
              <RightOutlined />
            </AsyncButton>
          </Tooltip>
        </FlexSpace>
      ),
    },
  ];

  return { tableColumns };
}

export function usePurchaseTargets() {
  async function fetchPurchaseTargets({ locationIds, supplierIds }: { locationIds: string[]; supplierIds: string[] }) {
    const { data } = await coreApiFetch(schemas.purchasing.getPurchaseTargets, {
      queryParams: {
        filter: {
          locationIds,
          supplierIds,
        },
        offset: 0,
      },
    });

    return data.items;
  }

  function getCompanyIdFromPurchaseTargets(
    groups: (PurchaseGroupWithTargetDTO & { purchaseTargets: PurchaseTargetDTO[] })[],
  ) {
    if (
      !Array.isArray(groups) ||
      groups.length === 0 ||
      !Array.isArray(groups[0].purchaseTargets) ||
      groups[0].purchaseTargets.length === 0
    )
      return undefined;

    return groups[0].purchaseTargets[0].companyId;
  }

  async function populateGroupsWithPurchaseTargets(selectedGroups: PurchaseGroupWithTargetDTO[]) {
    const locationIds = selectedGroups.flatMap((g) => g?.spokeLocations?.map((s) => s.foreignId)).filter(truthy);
    const supplierIds = selectedGroups.map((g) => g.supplierId);

    const purchaseTargets = await fetchPurchaseTargets({ locationIds, supplierIds });

    return selectedGroups.map((group) => {
      const groupLocationIds = group.spokeLocations?.map((s) => s.foreignId) || [];
      const groupPurchaseTargets = purchaseTargets.filter(
        (pt) => groupLocationIds.includes(pt.purchaseLocationId) && group.supplierId === pt.supplierId,
      );

      return {
        ...group,
        purchaseTargets: groupPurchaseTargets,
      };
    });
  }

  return { fetchPurchaseTargets, populateGroupsWithPurchaseTargets, getCompanyIdFromPurchaseTargets };
}

export function useSuppliers() {
  async function fetchSuppliers(queryParams: GetSuppliersQueryParams) {
    const { data } = await coreApiFetch(schemas.suppliers.getSuppliers, { queryParams });

    return data.items;
  }

  async function getSupplierIdAndName(cachedSuppliers: { value: string }[], supplierId: string) {
    const supplierIdAndName = cachedSuppliers.find((s) => s.value.split(':')[0] === supplierId)?.value;
    if (supplierIdAndName) return supplierIdAndName;

    const fetchedSuppliers = await fetchSuppliers({ filter: { searchQuery: supplierId } });
    const supplier = fetchedSuppliers.find((s) => s.supplierId === supplierId);
    if (!supplier) return '';

    return `${supplier.supplierId}: ${supplier.supplierName}`;
  }

  return { fetchSuppliers, getSupplierIdAndName };
}
