export { DatePickerFormItem } from './DatePickerFormItem';
export { SelectFormItem } from './SelectFormItem';
export { InputFormItem } from './InputFormItem';
export { RadioFormItem } from './RadioFormItem';
export { CheckboxFormItem } from './CheckboxFormItem';
export { AlgoliaSelectFormItem } from './AlgoliaSelectFormItem';
export { SpinnerFormItem } from './SpinnerFormItem';

export const defaultFormLayout = {
  labelCol: { offset: 2, span: 8 },
  wrapperCol: { span: 8 },
};

export const defaultFormTailLayout = {
  wrapperCol: {
    sm: {
      offset: 4,
      span: 2,
    },
    md: {
      offset: 10,
      span: 2,
    },
  },
};

export const responsiveFormLayout2 = {
  labelCol: {
    sm: {
      offset: 2,
      span: 8,
    },
    md: {
      offset: 2,
      span: 8,
    },
  },
  wrapperCol: {
    sm: {
      offset: 2,
      span: 16,
    },
    md: {
      offset: 0,
      span: 16,
    },
  },
};

export const responsiveFormLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
    sm: {
      span: 5,
    },
    md: {
      offset: 1,
      span: 4,
    },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 15 },
    md: { span: 14 },
  },
};
