import React from 'react';

import { Squash as Hamburger } from 'hamburger-react';
import { colors } from 'theme/colors';

import { NavModule, NavSubModule } from '../types';
import * as Styled from './TertiaryNavigation.style';

export interface TertiaryNavigationProps {
  isDesktopView: boolean;
  curNavModule: NavModule | undefined;
  onSubModuleSelect: (navSubModule: NavSubModule) => void;
  curSubModuleSlug: string | undefined;
  isHamburgerOpen: boolean;
  toggleHamburger: () => void;
}

export const TertiaryNavigation = ({
  isDesktopView,
  curNavModule,
  onSubModuleSelect,
  curSubModuleSlug,
  isHamburgerOpen,
  toggleHamburger,
}: TertiaryNavigationProps) => (
  <Styled.Container isDesktopView={isDesktopView} data-test-id="tertiaryNavContainer">
    <Styled.Content>
      {!isDesktopView && (
        <Styled.HamburgerWrapper>
          <Hamburger
            toggled={isHamburgerOpen}
            toggle={toggleHamburger}
            color={colors.neutral[900]}
            size={10}
            duration={0.2}
          />
        </Styled.HamburgerWrapper>
      )}
      {(curNavModule?.subModules || []).map((subModule) => (
        <Styled.SubItem
          key={subModule.slug}
          selected={subModule.slug === curSubModuleSlug || subModule.entitySlug === curSubModuleSlug}
          onClick={(ev) => {
            // allow ctrl/cmd+click to open in new tab
            if (!ev.ctrlKey && !ev.metaKey) {
              // route internally (href shown for accessibility)
              ev.preventDefault();
              onSubModuleSelect(subModule);
            }
          }}
          href={subModule.routeFn()}
        >
          {subModule.label}
        </Styled.SubItem>
      ))}
      <div>&nbsp;</div>
    </Styled.Content>
  </Styled.Container>
);
