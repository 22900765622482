export enum AppEnv {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export type Env = {
  CORE_API_URL: string;
  APP_ENV: AppEnv;
  AUTH0_AUDIENCE: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  TYPESENSE_HOST: string;
  STRIPE_CLIENT_KEY: string;
  APP_VERSION: string;
};

export const env: Env = {
  APP_ENV: process.env.APP_ENV as AppEnv,
  CORE_API_URL: process.env.CORE_API_URL as string,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN as string,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE as string,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID as string,
  TYPESENSE_HOST: process.env.TYPESENSE_HOST as string,
  STRIPE_CLIENT_KEY: process.env.STRIPE_CLIENT_KEY as string,
  APP_VERSION: process.env.APP_VERSION as string,
};
