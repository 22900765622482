import React from 'react';

import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';

import { Alert } from 'components/Alert';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowFeatureFlag } from 'utils/roleAndTenant';

export const TrialBanner = () => {
  const { activeTenant, activeUser } = useGlobalApp();

  if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersTrialBanner)) {
    return (
      <Alert
        message={
          <span>
            <strong>Free Trial</strong>: Pricing and inventory information will not be up to date.
          </span>
        }
        banner
        type="warning"
      />
    );
  }

  return null;
};
