import React, { Key, MutableRefObject, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Radio } from 'antd';
import { TableLocale, TableRowSelection } from 'antd/lib/table/interface';
import { useDebounce } from 'use-debounce/lib';
import { ZipCelXSheet, ZipCelXCell } from 'zipcelx';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import {
  getPillFacetOptions,
  getSearchFacetMultiSelectOptions,
  PillFilterOption,
} from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { ActionButton } from 'components/Button/ActionButton';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { linkableText } from 'components/Links';
import { PageHeader } from 'components/PageHeader';
import { ResultCount } from 'components/ResultCount';
import { SearchInput } from 'components/SearchInput';
import { Table } from 'components/Table';

import { usePromise } from 'hooks/usePromise';

import { arrFilterByValues } from 'utils/array';
import { captureAndShowError } from 'utils/error';
import { formatNumber, pluralize } from 'utils/formatting';
import { objGet, objRemoveUndefinedValues } from 'utils/object';
import { useHashState } from 'utils/routes';
import { getAllRecordsFromSearchIndex, searchIndex } from 'utils/search/search';
import { SearchRequest, DEFAULT_HITS_PER_PAGE } from 'utils/search/types';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { ExportColumn, withSortedColumn } from 'utils/tables';
import { timePromise, track, TrackEvent } from 'utils/track';

import { SearchFrameHashState } from 'types/hash-state';
import { ObjectWithId } from 'types/search-collections';

import { Counter } from '../../Counter';
import { RadioGroup } from '../../Radio';
import { ColumnChooserSection } from '../ColumnChooserSection';
import { TableSplitPage } from '../TableSplitPage';
import { FacetRenderType, SearchFrameContext } from './types';
import { flattenExportColumns } from './utils';

export type FilterType = 'multiple' | 'single';

export interface NumericFacet<HitT> {
  title: string;
  field: keyof HitT extends string ? string : never;
  labelFormatFn?: (value: number) => string;
}

export interface ValueFacet<HitT> {
  title?: string;
  icon?: ReactElement;
  field: keyof HitT extends string ? string : never;
  hidden?: boolean;
  hideIfNoMoreThanOneValue?: boolean;
  queryPlaceholder?: string;
  /** Use a string to choose a specific value (or string array for multiple) or true to use the first value */
  defaultValue?: string | string[] | boolean;
  filterType?: FilterType;
  renderType?: FacetRenderType;
  /** IMPORTANT NOTE: this will only frontend sort, so if there are more that 100 facet values, this will not work */
  sortBy?: 'alpha' | 'count' | 'none';
  labelFormatFn?: (value: string) => string;
  /** Static list of options for the facet */
  options?: MultiSelectOption[] | PillFilterOption[];
  triggerButton?: ReactNode;
}

export interface ExternalFacet {
  title: string;
  icon?: ReactElement;
  field: string;
  options: MultiSelectOption[];
  /** Use a string to choose a specific value or true to use the first value */
  defaultValue?: string;
  filterType?: FilterType;
}

export interface SearchFrameRowSelection<HitT> {
  selectedRows?: HitT[];
  selectedAll?: boolean;
}

export interface SearchFrameProps<HitT> {
  indexName: SearchIndexName;
  /** large title of the search page */
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  headerDescription?: string | ReactNode;
  queryPlaceholder: string;
  valueFacets?: ValueFacet<HitT>[];
  externalFacets?: ExternalFacet[];
  columns: ExportColumn<HitT>[];
  // function to run on the list of hits before displaying
  hitFn?: (hits: HitT[]) => Any[];
  /** component that shows besides search, e.g New Order CTA button */
  headerActions?: ReactNode | ((searchFrameContext: SearchFrameContext<HitT>) => ReactNode);
  tableRowKey?: keyof HitT;
  /** if tableSettingsKey is set, then column customization is shown  */
  tableColumnsSettingKey?: ViewSettingKey;
  tableRowSelection?: TableRowSelection<HitT> | 'selectAll';
  contextRef?: MutableRefObject<SearchFrameContext<HitT> | undefined>;
  sidePane?: (props: { record: HitT | null; searchIndexReload: () => void }) => ReactNode;
  locale?: TableLocale & { notFoundText?: React.ReactNode };
  defaultSortBy?: SearchFrameHashState['sortBy'];
  secondarySortBy?: SearchFrameHashState['secondarySortBy'];
}

export function SearchFrame<HitT extends ObjectWithId>({
  indexName,
  title,
  subtitle,
  headerDescription,
  queryPlaceholder,
  valueFacets = [],
  externalFacets = [],
  columns,
  hitFn,
  headerActions,
  tableRowKey = 'id',
  tableColumnsSettingKey,
  tableRowSelection,
  contextRef,
  sidePane,
  locale,
  defaultSortBy,
  secondarySortBy,
}: SearchFrameProps<HitT>) {
  const [hashState, updateHashState] = useHashState<SearchFrameHashState>();
  const [debouncedQuery] = useDebounce(hashState.query, 300);
  const [focusedRecord, setFocusedRecord] = useState<HitT | null>(null);
  // Don't use the set selections of the use states, use the other functions instead for safety
  const [selectedRows, setSelectedRows] = useState<HitT[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const persistableColumns: PersistedColumn<HitT>[] = (columns as Any[]).filter((col) => col && !!col.settingKey);
  const defaultColumnKeys = persistableColumns
    .filter((col) => !col.optional && !col.exportOnly)
    .map((col) => col.settingKey);
  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(tableColumnsSettingKey, defaultColumnKeys);

  const downloadColumns = tableColumnsSettingKey
    ? arrFilterByValues(persistableColumns, 'settingKey', visibleColumnKeys)
    : columns.filter((col) => !(col as PersistedColumn<HitT>).optional);

  const visibleColumns = downloadColumns.filter((col) => !col.exportOnly);

  function setSelectedAll(newIsAllSelected: boolean) {
    if (isAllSelected === newIsAllSelected) {
      return;
    }
    if (newIsAllSelected) {
      setIsAllSelected(true);
      setSelectedRows([]);
    } else {
      setIsAllSelected(false);
      setSelectedRows([]);
    }
  }

  function setSelection(newSelectedRows: HitT[]) {
    if (isAllSelected) {
      setSelectedAll(false);
    } else {
      setSelectedRows(newSelectedRows);
    }
  }

  function getRowKey(record: HitT): Key {
    return record[tableRowKey] as unknown as Key;
  }

  // update default filter values (if any)
  // don't mutate the hashState object directly
  const filters = { ...hashState.where };
  for (const facet of valueFacets) {
    if (facet.defaultValue && !filters[facet.field]) {
      if (typeof facet.defaultValue === 'string') {
        filters[facet.field] = [facet.defaultValue];
      } else if (Array.isArray(facet.defaultValue)) {
        filters[facet.field] = facet.defaultValue;
      }
    }
  }

  const searchRequest: SearchRequest = {
    indexName,
    query: debouncedQuery?.trim(),
    filters,
    facetFields: valueFacets.filter((f) => !f.hidden).map((f) => f.field),
    page: hashState.page,
    sortBy: hashState.sortBy || defaultSortBy,
    secondarySortBy: secondarySortBy ?? undefined,
  };

  const {
    data: searchIndexResponse,
    isLoading,
    reload: searchIndexReload,
  } = usePromise(
    () =>
      timePromise(searchIndex<HitT>(searchRequest), (resDurationMs) => {
        track(TrackEvent.Components_SearchFrameQuery, {
          indexName,
          searchQuery: searchRequest.query ?? '',
          searchQueryLength: searchRequest.query?.length ?? 0,
          facetValueFilterKeys: Object.keys(searchRequest.filters || {}),
          sortByField: searchRequest.sortBy?.field,
          sortByDir: searchRequest.sortBy?.order,
          resDurationMs,
        });
      }),
    [searchRequest],
    {
      cacheKey: `searchframe/${indexName}:${JSON.stringify(searchRequest)}`,
      onError: (err) =>
        captureAndShowError(err, 'Error while searching', { notificationKey: `searchframe/${indexName}` }),
    },
  );

  const records: HitT[] = useMemo(() => {
    const hits = searchIndexResponse?.hits || [];
    return hitFn ? hitFn(hits) : hits;
  }, [searchIndexResponse, hitFn]);

  // update external value facets (if any)
  for (const facet of externalFacets) {
    if (facet.defaultValue && !hashState?.extFilter?.[facet.field]) {
      updateHashState({
        extFilter: objRemoveUndefinedValues({
          ...hashState.extFilter,
          [facet.field]: [facet.defaultValue],
        }),
      });
    }
  }

  // If records have changed, update focused row accordingly
  useEffect(() => {
    if (sidePane) {
      const recordMatch = focusedRecord
        ? records.find((record) => record[tableRowKey] === focusedRecord[tableRowKey])
        : undefined;

      // focus on new record with same id
      if (focusedRecord && recordMatch && focusedRecord !== recordMatch) {
        setFocusedRecord(recordMatch);
      }
      // if nothing matches, then focus on first row that matches result set
      else if (!recordMatch && records.length > 0) {
        setFocusedRecord(records[0]);
      }
      // otherwise clear focusedRecord if no records
      else if (records.length === 0) {
        setFocusedRecord(null);
      }
    }
  }, [focusedRecord, records, sidePane, tableRowKey]);

  // Filter out columns that have a corresponding value facet with 'hideIfNoMoreThanOneValue' set to true
  // if the search response for that facet only contains one value.
  // e.g. for single-company tanent we will filter out 'company' column in the list
  columns = columns.filter((column) => {
    const matchFacet = valueFacets.find((facet) => facet.field === column.dataIndex);
    const shouldHide =
      matchFacet?.hideIfNoMoreThanOneValue && (searchIndexResponse?.facets?.[matchFacet.field]?.length ?? 0) <= 1;
    return !shouldHide;
  });

  const rowSelection: (TableRowSelection<HitT> & SearchFrameRowSelection<HitT>) | undefined =
    tableRowSelection === 'selectAll'
      ? {
          selectedAll: isAllSelected,
          preserveSelectedRowKeys: true,
          selectedRowKeys: isAllSelected
            ? searchIndexResponse?.hits.map((hit) => getRowKey(hit))
            : selectedRows.map((hit) => getRowKey(hit)),
          selectedRows,
          onSelect: (record, selected) => {
            if (!selected) {
              setSelection(selectedRows.filter((value) => getRowKey(value) !== getRowKey(record)));
            } else {
              setSelection([...selectedRows, record]);
            }
          },
          onChange: (_, newSelectedRows) => {
            setSelection(newSelectedRows);
          },
        }
      : tableRowSelection;

  const joinIfArray = (val: string | boolean | string[] | undefined) => (Array.isArray(val) ? val.join(',') : val);

  const renderPillFilter = (facet: ValueFacet<HitT>) => {
    const selectionProps = getPillFacetOptions({
      facetValues: searchIndexResponse?.facets?.[facet.field] || [],
      labelFormatFn: facet.labelFormatFn,
      staticOptions: facet.options,
    })();

    const currentValue = joinIfArray(filters[facet.field] ?? facet.defaultValue);

    /** Don't show filter while loading or if there's only one option */
    if (selectionProps.options.length <= 1) {
      return null;
    }

    return (
      <FlexSpace
        className={css`
          margin-top: 0px;
          margin-bottom: 24px;
        `}
      >
        <RadioGroup
          value={currentValue}
          onChange={(ev) => {
            updateHashState({
              where: objRemoveUndefinedValues({
                ...hashState.where,
                [facet.field]: ev.target.value.split(','),
              }),
              page: 0,
            });
            setSelectedAll(false);
          }}
        >
          {selectionProps.options.map((option) => (
            <Radio.Button key={JSON.stringify(option.value)} value={joinIfArray(option.value)}>
              {option.label}
              <Counter
                className={css`
                  margin-left: 6px;
                `}
                variant="primary"
                selected={joinIfArray(currentValue) === joinIfArray(option.value)}
                value={option.count ?? 0}
              />
            </Radio.Button>
          ))}
        </RadioGroup>
      </FlexSpace>
    );
  };

  const canDownload = !!(!rowSelection || rowSelection.selectedAll || rowSelection.selectedRowKeys?.length);

  const searchFrameContext: SearchFrameContext<HitT> = {
    hashState,
    searchRequest,
    searchIndexResponse,
    tableRowSelection: rowSelection,
    searchIndexReload,
    canDownload,
    getDownloadData: () =>
      fetchDownloadXSheet({
        indexName,
        columns: downloadColumns,
        rowSelection,
        searchRequest,
      }),
  };

  if (contextRef) {
    contextRef.current = searchFrameContext;
  }

  return (
    <Container>
      <PageHeader
        title={title}
        subtitle={subtitle}
        description={headerDescription}
        headerActions={typeof headerActions === 'function' ? headerActions(searchFrameContext) : headerActions}
      />

      {valueFacets
        .filter((facet) => !facet.hidden && facet.renderType === FacetRenderType.Pill)
        .map((facet) => renderPillFilter(facet))}

      <FilterBarBox dividerLine>
        {valueFacets
          .filter(
            (facet) =>
              !facet.hidden &&
              facet.renderType !== FacetRenderType.Pill &&
              (facet.hideIfNoMoreThanOneValue ? (searchIndexResponse?.facets?.[facet.field]?.length ?? 0) > 1 : true),
          )
          .map((facet, idx) => (
            <AsyncMultiSelect
              key={idx}
              label={facet.title}
              icon={facet.icon}
              mode={facet.filterType}
              selectProps={getSearchFacetMultiSelectOptions({
                facetValues: searchIndexResponse?.facets?.[facet.field] || [],
                facetField: facet.field,
                searchRequest,
                labelFormatFn: facet.labelFormatFn,
                sortBy: facet.sortBy,
                staticOptions: facet.options as MultiSelectOption[],
              })}
              triggerButton={facet.triggerButton || undefined}
              selectedValues={filters[facet.field] ?? []}
              queryPlaceholder={facet.queryPlaceholder}
              onSelectedValuesChange={(values) => {
                updateHashState({
                  where: objRemoveUndefinedValues({
                    ...hashState.where,
                    [facet.field]: values.length > 0 ? values : undefined,
                  }),
                  page: 0,
                });
              }}
            />
          ))}
        {hashState.where && Object.keys(hashState.where).length > 0 ? (
          <ActionButton onClick={() => updateHashState({ where: undefined, query: undefined })} label="Clear All" />
        ) : null}
        <FlexSpacer />
        {externalFacets.map((facet, idx) => (
          <AsyncMultiSelect
            key={valueFacets.length + idx}
            label={facet.title}
            icon={facet.icon}
            mode={facet.filterType}
            selectProps={{ options: facet.options ?? [] }}
            selectedValues={hashState.extFilter?.[facet.field] ?? []}
            onSelectedValuesChange={(values) => {
              updateHashState({
                extFilter: objRemoveUndefinedValues({
                  ...hashState.extFilter,
                  [facet.field]: values.length > 0 ? values : undefined,
                }),
                page: 0,
              });
            }}
          />
        ))}
        <SearchInput
          placeholder={queryPlaceholder}
          query={hashState.query}
          onQueryChange={(query) => updateHashState({ query, page: 0 })}
        />
        <ResultCount count={searchIndexResponse?.nbHits || 0} dataTestId="search-frame-result-count" />
      </FilterBarBox>

      {rowSelection?.selectedRowKeys?.length ? (
        <FlexSpace
          gap={16}
          className={css`
            justify-content: center;
            margin-bottom: 16px;
          `}
        >
          {
            // if only the first page is selected, then show a message asking if the user wants to select all
            !isAllSelected &&
            !debouncedQuery &&
            selectedRows.length === searchIndexResponse?.hits.map(getRowKey).length &&
            selectedRows.every(
              (element, index) => getRowKey(element) === searchIndexResponse?.hits.map(getRowKey)[index],
            ) &&
            searchIndexResponse.nbHits > selectedRows.length ? (
              <>
                <span>
                  All <b>{selectedRows.length}</b> items on this page are selected
                </span>
                {linkableText({
                  onClick: () => setSelectedAll(true),
                  text: `Select all ${formatNumber(searchIndexResponse.nbHits)} items`,
                })}
              </>
            ) : rowSelection.selectedRowKeys?.length ? (
              // if not, then show the amount of items selected
              <span>
                {rowSelection.selectedAll ? (
                  <>
                    <b>{formatNumber(searchFrameContext.searchIndexResponse?.nbHits)}</b> items
                  </>
                ) : (
                  <>
                    <b>{rowSelection.selectedRowKeys?.length}</b>{' '}
                    {pluralize(rowSelection.selectedRowKeys?.length ?? 0, 'item')}
                  </>
                )}
                {` selected `}
              </span>
            ) : null
          }
          {
            // If there is a selection, then show a button to allow for clearing
            rowSelection.selectedRowKeys?.length
              ? linkableText({
                  onClick: () =>
                    searchFrameContext.tableRowSelection?.onSelectNone?.() ??
                    searchFrameContext.tableRowSelection?.onChange?.([], []),
                  text: 'Clear',
                })
              : null
          }
        </FlexSpace>
      ) : null}

      <ConditionalWrapper
        condition={!!sidePane}
        wrapper={(children) => (
          <TableSplitPage
            left={children}
            right={sidePane!({
              record: focusedRecord,
              searchIndexReload,
            })}
          />
        )}
      >
        <>
          {tableColumnsSettingKey ? (
            <ColumnChooserSection
              tableKey={tableColumnsSettingKey}
              columns={persistableColumns}
              visibleColumnKeys={visibleColumnKeys}
              setVisibleColumnKeys={setVisibleColumnKeys}
            />
          ) : null}
          <Table
            stickyHeader
            scroll={{ x: true }}
            columns={withSortedColumn(visibleColumns, searchRequest.sortBy?.field, searchRequest.sortBy?.order)}
            isLoading={isLoading}
            data={records}
            rowKey={tableRowKey as string}
            rowSelection={rowSelection}
            rowClassName={(record: HitT) =>
              [sidePane ? 'focusable-row' : '', record === focusedRecord ? 'focused-row' : ''].join(' ')
            }
            onRow={
              sidePane
                ? (record) => ({
                    onClick: () => setFocusedRecord(record),
                  })
                : undefined
            }
            pagination={
              searchIndexResponse && searchIndexResponse.nbPages > 1
                ? {
                    simple: true,
                    // NOTE: Pagination component uses 1-based index, search index returns 0 based index
                    onChange: (oneIndexedPageNum) => updateHashState({ page: oneIndexedPageNum - 1 }),
                    pageSize: DEFAULT_HITS_PER_PAGE,
                    current: searchIndexResponse.page + 1,
                    total: searchIndexResponse.nbPages * DEFAULT_HITS_PER_PAGE,
                  }
                : false
            }
            onChange={(_pagination, _filters, sorter, { action }) => {
              // !Array.isArray is there to typeguard into one sorter field
              // all our search frame tables use single sorting, so this is okay
              if (action === 'sort' && !Array.isArray(sorter)) {
                updateHashState({
                  sortBy: { field: sorter.field as string, order: sorter.order === 'descend' ? 'desc' : 'asc' },
                  page: 0,
                });
              }
            }}
            locale={{
              ...locale,
              emptyText:
                (hashState.where && Object.keys(hashState.where).length) ||
                (hashState.query && hashState.query?.length > 0)
                  ? locale?.notFoundText
                  : locale?.emptyText,
            }}
          />
        </>
      </ConditionalWrapper>
    </Container>
  );
}

/** query core-api to fetch filtered/selected rows */
export async function fetchDownloadXSheet<HitT>({
  indexName,
  columns,
  rowSelection,
  searchRequest,
}: {
  indexName: SearchIndexName;
  columns: ExportColumn<HitT>[];
  rowSelection: SearchFrameRowSelection<HitT> | undefined;
  searchRequest: SearchRequest;
}): Promise<ZipCelXSheet> {
  const expandedColumns = columns.reduce(
    (flattened: ExportColumn<HitT>[], column) =>
      // expand columns with children into multiple export columns
      flattened.concat(column.children ? column.children : [column]),
    [],
  );

  const exportColumns = flattenExportColumns(expandedColumns).filter((column) => column.title && column.dataIndex);

  let exportData: Any[];
  // if selected all then get from search index, otherwise use selected rows
  if (!rowSelection || !rowSelection.selectedRows || rowSelection.selectedAll) {
    const fieldsToRetrieve: string[] = Array.from(new Set(exportColumns.map((column) => column.dataIndex)));

    exportData = await getAllRecordsFromSearchIndex({
      indexName,
      filters: searchRequest.filters,
      fieldsToRetrieve,
    });
  } else {
    exportData = rowSelection.selectedRows;
  }

  const headerRow = exportColumns.map((column) => ({
    value: column.title,
    type: column.exportType ? column.exportType : 'string',
  }));

  const dataRows = exportData.map((hit) =>
    exportColumns.map((column) => {
      const value =
        typeof column.exportValue === 'function'
          ? column.exportValue(objGet(hit, column.dataIndex))
          : objGet(hit, column.dataIndex);

      return {
        value: typeof value !== 'object' ? value : '',
        type: column.exportType ? column.exportType : typeof value === 'number' ? 'number' : 'string',
      } as ZipCelXCell;
    }),
  );

  const sheet: ZipCelXSheet = {
    data: [headerRow, ...dataRows],
  };

  return sheet;
}
