import React from 'react';

import { useHistory } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { UserCreatePayload } from '@recurrency/core-api-schema/dist/users/postCreateUser';
import { Form, Input, notification, PageHeader } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { CheckboxFormItem, defaultFormTailLayout, InputFormItem, responsiveFormLayout } from 'components/FormItems';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { generateTempPassword } from 'utils/password';
import { routes } from 'utils/routes';

export const UserNewPage = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const onSubmit = async (data: UserCreatePayload) => {
    try {
      const response = await coreApiFetch(schemas.users.createUser, {
        bodyParams: data,
      });
      notification.success({ message: 'New user created.' });
      if (response?.data?.id) {
        history.push(routes.internal.userDetails(response.data.id));
      } else {
        history.push(routes.internal.userList());
      }
    } catch (err) {
      captureAndShowError(err, `Error while creating user`);
    }
  };

  const tempPassword = generateTempPassword();

  return (
    <Container>
      <PageHeader title="New User" onBack={() => history.push(routes.internal.userList())} />
      <Form.Provider>
        <Form
          {...responsiveFormLayout}
          name="taskForm"
          form={form}
          onFinish={onSubmit}
          onError={console.error}
          initialValues={{ verifyEmail: false }}
        >
          <InputFormItem
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please add a first name.' }]}
          />

          <InputFormItem
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please add a last name.' }]}
          />

          <InputFormItem
            label="Email"
            name="email"
            rules={[
              { type: 'email', message: 'Must be an email' },
              { required: true, message: 'Please add an email.' },
            ]}
          />

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please add a temporary password.' }]}
            extra={
              <div>
                Suggested temporary password: <Paragraph copyable>{tempPassword}</Paragraph>
              </div>
            }
          >
            <Input.Password />
          </Form.Item>

          <CheckboxFormItem name="sendWelcomeEmail" label="Send Welcome Email" />
          <InputFormItem name="invitedBy" label="Invited by" />

          <Form.Item {...defaultFormTailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Form.Provider>
    </Container>
  );
};
