import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Alert, notification } from 'antd';
import { theme } from 'theme';

import { ActionButton } from 'components/Button/ActionButton';
import { AsyncButton } from 'components/Button/AsyncButton';
import { openPdfInNewTab } from 'components/recipes/OpenPdfButton';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { HttpError, captureAndShowError } from 'utils/error';
import { formatCents, formatUSD } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { PdfDocumentType } from 'utils/track';

import { getAmountMultiplyBy100ToInteger } from '../common/paymentUtils';
import { NewPaymentMethodModal } from './NewPaymentMethodModal';
import { PaymentMethodSelect, PaymentMethodOption, PaymentInfo } from './PaymentInfoModal';

interface PaymentMethodsTabProps {
  paymentInfo: PaymentInfo;
  transactionFee: Maybe<number>;
  setFee: (feeAmount: number) => void;
  isDirectCharge: boolean;
  onClose?: () => void;
}

interface InitialValues {
  tenantCustomerId: string;
  tenantCustomerName: string;
  orderTotalAmount: string;
}

export const PaymentMethodsTab = ({
  paymentInfo,
  transactionFee,
  setFee,
  isDirectCharge,
  onClose = () => {},
}: PaymentMethodsTabProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodOption | null>();
  const [paymentMethodsInSession, setPaymentMethodsInSession] = useState<PaymentMethodOption[]>();
  const { activeTenant, activeUser } = useGlobalApp();
  const [successfulMessage, setSuccessfulMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const initialValues: InitialValues = {
    tenantCustomerId: `${paymentInfo.companyId}||${paymentInfo.customerId}`,
    tenantCustomerName: paymentInfo.customerName,
    orderTotalAmount: formatUSD(paymentInfo.cfTotalDue, true),
  };

  const handlePaymentMethodSelect = async (paymentMethod: Maybe<PaymentMethodOption>) => {
    setSelectedPaymentMethod(paymentMethod);
    if (!paymentMethod) {
      setFee(0);
      return;
    }

    if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.PaymentsIncludeSurcharge)) {
      const { data } = await coreApiFetch(schemas.transactionFees.calculateTransactionFee, {
        bodyParams: {
          amount: Number(paymentInfo.cfTotalDue),
          region: paymentMethod.postalCode,
          nicn: paymentMethod.iin,
          mTxId: paymentInfo.orderNo,
          processor: 'default',
          data: [`customerId-${initialValues.tenantCustomerId}`, `orderId-${paymentInfo.orderNo}`],
        },
      });
      setFee(data.transactionFee);
    }
  };

  const handleSubmit = async () => {
    // avoid the imprecision that arises due to the way floating-point numbers are represented in JavaScript.
    const grandTotal = getAmountMultiplyBy100ToInteger(Number(paymentInfo.cfTotalDue) + (transactionFee || 0));

    if (isDirectCharge) {
      const submitNotification = createSubmissionNotification({
        entityName: 'Payment Method',
        submittingMessage: 'Processing Payment...',
        expectedWaitSeconds: 60,
        erpType: activeTenant.erpType,
      });

      try {
        const response = await coreApiFetch(schemas.payments.createDirectChargePaymentIntent, {
          bodyParams: {
            amount: grandTotal,
            currency: 'usd',
            processorPaymentMethodId: selectedPaymentMethod!.processorPaymentMethodId,
            processorCustomerId: selectedPaymentMethod!.processorCustomerId,
            customerId: initialValues.tenantCustomerId,
            customerName: initialValues.tenantCustomerName,
            orderId: paymentInfo.orderNo,
            invoiceId: paymentInfo.invoiceNo,
            paymentMethodTypes: ['card'],
            saveForFutureUse: selectedPaymentMethod!.saveForFutureUse,
            isDirectCharge,
          },
        });

        if (response.data.status === 'succeeded') {
          submitNotification.success({
            successMessage: `Payment of ${formatCents(response.data.amount)} Successful`,
            description: (_notificationKey, _entityName) => (
              <div>
                <ActionButton
                  label="Open Invoice PDF"
                  onClick={() => openPdfInNewTab({ foreignId: paymentInfo.invoiceNo, type: PdfDocumentType.Invoice })}
                />
              </div>
            ),
          });
          onClose();
        } else {
          const msg = `Charge failed: ${
            response.data.lastPaymentError?.message || 'Unknown error, please close the payment popup and try again.'
          }`;
          setErrorMessage(msg);
          submitNotification.error(msg);
        }
      } catch (err) {
        if (err instanceof HttpError) {
          if ([500, 422, 404].includes(err.statusCode)) {
            setErrorMessage(
              'Charge succeeded, but there was an issue syncing to P21. Our engineers have been notified and we will send you an update shortly.',
            );
          } else {
            setErrorMessage(`${err.message} Please close the payment popup and retry with a new payment method.`);
          }
        }
        submitNotification.error(err);
      }
    } else {
      try {
        const authPromise = await coreApiFetch(schemas.payments.createAndConfirmPaymentIntent, {
          bodyParams: {
            amount: grandTotal,
            currency: 'usd',
            processorPaymentMethodId: selectedPaymentMethod!.processorPaymentMethodId,
            processorCustomerId: selectedPaymentMethod!.processorCustomerId,
            customerId: initialValues.tenantCustomerId,
            customerName: initialValues.tenantCustomerName,
            orderId: paymentInfo.orderNo,
            paymentMethodTypes: ['card'],
            isDirectCharge: false,
            saveForFutureUse: selectedPaymentMethod!.saveForFutureUse,
          },
        });
        setSuccessfulMessage('Successful Authorized , please close the payment popup.');
        notification.success({
          message: `Authorized #${paymentInfo.orderNo} for ${formatCents(authPromise.data.amount)}`,
          duration: 60,
        });
      } catch (err) {
        setErrorMessage('Authorization Error, please close the payment popup and try again.');
        captureAndShowError(err, 'Failed Authorization', {
          duration: 60,
        });
      }
    }
  };

  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          max-width: 580px;
        `}
      >
        <div>
          {successfulMessage && (
            <Alert
              message={successfulMessage}
              closable
              banner
              type="success"
              className={css`
                margin-bottom: 8px;
                border: 1px solid ${theme.colors.success[500]};
                border-radius: 4px;
              `}
            />
          )}
          {errorMessage && (
            <Alert
              message={errorMessage}
              closable
              banner
              type="error"
              className={css`
                margin-bottom: 8px;
                border: 1px solid ${theme.colors.warning[500]};
                border-radius: 4px;
              `}
            />
          )}
        </div>
        <div
          className={css`
            display: flex;
            gap: 12px;
          `}
        >
          <PaymentMethodSelect
            initialValues={{
              tenantCustomerId: initialValues.tenantCustomerId,
              tenantCustomerName: initialValues.tenantCustomerName,
              orderTotalAmount: initialValues.orderTotalAmount,
            }}
            selectedValue={selectedPaymentMethod}
            paymentMethodsInSession={paymentMethodsInSession}
            onPaymentMethodSelect={handlePaymentMethodSelect}
            onPaymentMethodClear={() => handlePaymentMethodSelect(null)}
          />
          <AsyncButton
            onClick={async () => {
              // TODO: track event
              const modalData = await showAsyncModal(NewPaymentMethodModal, { paymentInfo });
              if (modalData?.paymentMethod) {
                handlePaymentMethodSelect(modalData.paymentMethod);
                setPaymentMethodsInSession((prev) => [...(prev || []), modalData.paymentMethod]);
              }
            }}
            type="default"
            size="middle"
            icon={<PlusOutlined />}
            style={{ maxWidth: '200px' }}
          >
            Payment Method
          </AsyncButton>
        </div>
        <div
          className={css`
            margin-bottom: 12px;
          `}
        >
          {transactionFee != null && (
            <Typography
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '14px',
                padding: '4px 0 4px 0',
                color: theme.colors.warning[600],
              }}
            >
              Transaction Fee: {formatUSD(transactionFee, true, 2)}
            </Typography>
          )}
        </div>
        <div>
          <AsyncButton
            onClick={handleSubmit}
            type="primary"
            disabled={!selectedPaymentMethod || successfulMessage ? true : !!(false || errorMessage)}
          >
            {isDirectCharge ? 'Charge' : 'Authorize Payment'}
          </AsyncButton>
        </div>
      </div>
    </>
  );
};
