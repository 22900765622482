import React, { useState } from 'react';

import { css } from '@emotion/css';
import { QuoteOrderLineNoteAreas } from '@recurrency/core-api-schema/dist/common/enums';
import { Form, Modal, Switch } from 'antd';

import { LabelContainer, LabelTitle } from 'components/AsyncSelect/AsyncSelect.style';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Select } from 'components/Select';
import { TextArea } from 'components/TextArea';
import { EllipsisTruncatedLabel } from 'components/Typography/EllpsisTruncatedLabel';

import { showAsyncModal } from 'utils/asyncModal';

import { QuoteLineItemP21, LineNote } from 'types/hash-state';

export function NotesPanelContent({
  lineItem,
  onLineNotesChange,
}: {
  lineItem: QuoteLineItemP21;
  onLineNotesChange: (lineNotes: LineNote[]) => void;
}) {
  const lineNotes = lineItem.notes ?? [];

  const showEditModal = async (lineNoteIdx: number) => {
    const editedLineNotes = await showAsyncModal(LineItemNoteEditModal, {
      lineNotes,
      lineNoteIdx,
    });
    if (editedLineNotes) {
      onLineNotesChange(editedLineNotes);
    }
  };

  return (
    <>
      <div>
        {lineNotes.map((lineNote, lineNoteIdx) => (
          <div
            key={lineNoteIdx}
            className={css`
              margin-bottom: 8px;
            `}
          >
            <EllipsisTruncatedLabel label={lineNote.note} maxWidth="150ch" />
            <ActionButton label="Edit" onClick={() => showEditModal(lineNoteIdx)} />
          </div>
        ))}
      </div>
      <ActionButton label="New" onClick={() => showEditModal(lineNotes.length)} />
    </>
  );
}

function LineItemNoteEditModal({
  lineNotes,
  lineNoteIdx,
  onClose,
}: {
  lineNotes: LineNote[];
  lineNoteIdx: number;
  onClose: (editedLineNotes?: LineNote[]) => void;
}) {
  const defaultLineItemNote: LineNote = {
    topic: 'Order Entry Line Note',
    note: '',
    areas: [QuoteOrderLineNoteAreas.OrderEntry],
    mandatory: false,
  };
  const lineNoteAreas = Object.values(QuoteOrderLineNoteAreas);
  const [editedLineNote, setEditedLineNote] = useState<LineNote>(lineNotes[lineNoteIdx] ?? defaultLineItemNote);

  const updateEditedLineNote = (update: Partial<LineNote>) => {
    setEditedLineNote({ ...editedLineNote, ...update });
  };

  return (
    <Modal
      visible
      title="Note"
      onCancel={() => onClose()}
      footer={
        <>
          <Button
            danger
            onClick={() => {
              const editedLineNotes = [...lineNotes];
              editedLineNotes.splice(lineNoteIdx, 1);
              onClose(editedLineNotes);
            }}
          >
            Delete
          </Button>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            onClick={() => {
              const editedLineNotes = [...lineNotes];
              editedLineNotes[lineNoteIdx] = editedLineNote;
              onClose(editedLineNotes);
            }}
            type="primary"
          >
            Save
          </Button>
        </>
      }
      width={900}
    >
      <div
        className={css`
          label {
            width: 150px;
          }
        `}
      >
        <Form.Item label="Message">
          <TextArea
            rows={3}
            maxLength={255}
            placeholder="Note"
            value={editedLineNote.note}
            onChange={(ev) => {
              updateEditedLineNote({ note: ev.target.value });
            }}
          />
        </Form.Item>
        <Form.Item label="Note Visibility">
          <Select
            mode="multiple"
            placeholder="Please select"
            value={editedLineNote.areas}
            onChange={(newAreas) => {
              updateEditedLineNote({ areas: newAreas });
            }}
            options={lineNoteAreas.map((area, idx) => ({
              value: area,
              label: (
                <LabelContainer key={idx}>
                  <LabelTitle>{area}</LabelTitle>
                </LabelContainer>
              ),
            }))}
            className={css`
              .ant-select-multiple {
                .ant-select-selection-item {
                  padding: 6px;
                  border-radius: 3px;
                  margin-right: 8px;
                }
              }
            `}
          />
        </Form.Item>
        <Form.Item label="Pinned (Mandatory)">
          <Switch
            checked={editedLineNote.mandatory}
            onChange={(checked) => {
              updateEditedLineNote({ mandatory: checked });
            }}
          />
        </Form.Item>
      </div>
    </Modal>
  );
}
