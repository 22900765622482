import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import type { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import { initStripeClient } from 'extScriptsInit';

import { Modal } from 'components/Modal';

import { getAmountMultiplyBy100ToInteger } from '../common/paymentUtils';
import { CheckoutForm } from './CheckoutForm';
import { PaymentInfo, PaymentMethodOption } from './PaymentInfoModal';

interface NewPaymentMethodModalProps {
  paymentInfo: PaymentInfo;
  onClose: (closeData?: { paymentMethod: PaymentMethodOption }) => void;
}

export const NewPaymentMethodModal = ({ paymentInfo, onClose }: NewPaymentMethodModalProps) => {
  // adjust Stripe Elements font Appearance
  const appearance: Appearance = {
    theme: 'flat',
    variables: {
      fontSizeSm: '0.8rem',
      fontSizeBase: '16px',
    },
  };

  // lazy load Stripe client
  const stripePromise = initStripeClient();

  const options: StripeElementsOptions = {
    appearance,
    paymentMethodCreation: 'manual',
    mode: 'payment',
    currency: 'usd',
    paymentMethodTypes: ['card'],
    captureMethod: 'manual',
    // avoid the imprecision that arises due to the way floating-point numbers are represented in JavaScript.
    // Note: this amount is not final, it will be updated when we finally confirm the Stripe Payment Intent
    amount: getAmountMultiplyBy100ToInteger(paymentInfo.cfTotalDue),
  };

  return (
    <>
      <Modal visible footer={null} title="Add a Payment Method" centered width={600} closable={false}>
        <Elements options={options} stripe={stripePromise}>
          <div className="payment-container">
            {/* it's required to have all Payment Elements wrapped in its own component to provide complete context:
            https://stackoverflow.com/a/64109114 */}
            <CheckoutForm
              paymentInfo={paymentInfo}
              onCancel={onClose}
              onSuccess={(paymentMethod) => onClose({ paymentMethod })}
            />
          </div>
        </Elements>
      </Modal>
    </>
  );
};
