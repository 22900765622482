import React from 'react';

import { useHistory, Link } from 'react-router-dom';

import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  DownOutlined,
  FileTextOutlined,
  LoadingOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag, ErpOrderStatus, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { Menu, Modal, notification } from 'antd';

import { getHashStateFromOrder } from 'pages/orders/quotes/quoteUtils';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { Dropdown } from 'components/Dropdown';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSections, DetailPageSection } from 'components/recipes/detailPage/DetailPageSections';
import { addressPropertyListItem } from 'components/recipes/detailPage/propertyListItemUtils';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import {
  dashesIfEmpty,
  EMPTY_VALUE_DASHES,
  formatAddressMultiLine,
  formatDate,
  formatPhoneNumber,
  formatUSD,
  getErpName,
} from 'utils/formatting';
import { shouldShowFeatureFlag, shouldShowProduct } from 'utils/roleAndTenant';
import { routes, usePathParams, IdPathParams } from 'utils/routes';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { InvoiceChecksAlerts } from './InvoiceChecksAlerts';
import { QuoteOrderLineNotes } from './QuoteOrderLineNotes';
import { OrderDocumentsTable } from './tabs/OrderDocumentsTable';
import { OrderLinesTable } from './tabs/OrderLinesTable';
import { OrderShipmentsTable } from './tabs/OrderShipmentsTable';
import { OrderTransferLinesTable } from './tabs/OrderTransferLinesTable';

export const OrderDetailsPageSsfc = () => {
  const { id: salesOrderId } = usePathParams<IdPathParams>();
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();
  const tenantHasCreateInvoiceFeatureFlag = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersCreateOrderInvoice,
  );

  const {
    data: order,
    error: orderError,
    isLoading: isOrderLoading,
    reload: reloadOrder,
  } = useCoreApi(schemas.salesOrders.getSalesOrderData, {
    pathParams: { salesOrderId },
  });

  const { data: invoiceChecksData, isLoading: isInvoiceChecksLoading } = useCoreApi(
    schemas.orders.getInvoiceChecks,
    tenantHasCreateInvoiceFeatureFlag
      ? {
          pathParams: { orderNumber: salesOrderId },
        }
      : null,
  );

  if (orderError) {
    return <CenteredError error={orderError} />;
  }

  if (isOrderLoading || !order) {
    return <CenteredLoader />;
  }

  if (Object.keys(order).length === 0) {
    return <NoDataMessage />;
  }

  const complete = order.status === ErpOrderStatus.Completed;
  const canceled = order.status === ErpOrderStatus.Canceled;

  const orderCanBeInvoiced = !canceled && !complete;

  const headerSections: DetailPageSection[] = [
    {
      title: 'Customer',
      rows: [
        [
          {
            icon: <UserOutlined />,
            label: 'Customer',
            value: <Link to={routes.sales.customerDetails(order.customerId)}>{order.customerName}</Link>,
            id: order.customerId,
          },
          {
            icon: <UserOutlined />,
            label: 'Contact',
            value: dashesIfEmpty(order.customerContact?.contactName),
          },
          { label: 'PO No.', value: dashesIfEmpty(order.customerPORef), icon: <FileTextOutlined /> },
          {
            icon: <UserOutlined />,
            label: 'Sales Rep',
            value: dashesIfEmpty(order.salesRepName),
            id: order.salesRepId,
          },
          isTenantErpTypeP21(activeTenant.erpType)
            ? {
                label: 'Terms',
                value: dashesIfEmpty(order.terms),
                icon: <FileTextOutlined />,
              }
            : undefined,
        ].filter(truthy),
      ],
    },
    {
      title: 'Order Configuration',
      rows: [
        [
          {
            icon: <CalendarOutlined />,
            label: 'Order Date',
            value: formatDate(order.orderDate),
          },
          {
            icon: order.approved ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
            label: 'Approved',
            value: order.approved ? 'Yes' : 'No',
          },
          { icon: <CalendarOutlined />, label: 'Due Date', value: formatDate(order.dueDate) },
          { label: 'Taker', value: dashesIfEmpty(order.takerName), icon: <UserOutlined />, id: order.takerId },
          {
            label: 'Total Price',
            value: order.totalPrice ? formatUSD(order.totalPrice, true) : EMPTY_VALUE_DASHES,
            icon: <DollarOutlined />,
          },
          {
            label: 'Total Open Price',
            value: order.totalPrice ? formatUSD(order.totalOpenPrice, true) : EMPTY_VALUE_DASHES,
            icon: <DollarOutlined />,
          },
          order.class1Id
            ? {
                label: 'Class 1',
                value: order.class1Name,
              }
            : null,
        ].filter(truthy),
      ],
    },
    {
      title: 'Line Notes (Pinned)',
      children: <QuoteOrderLineNotes orderLines={order.lines} pinned />,
    },
    {
      title: 'Logistics',
      rows: [
        [
          {
            label: 'Sales Location',
            value: order.locationName
              ? `${order.locationName}${order.willCall ? ` (Will Call)` : ``}`
              : EMPTY_VALUE_DASHES,
            id: order.locationId,
          },
          isTenantErpTypeP21(activeTenant.erpType)
            ? {
                label: 'Freight Type',
                value: dashesIfEmpty(order.freightName),
                id: order.freightUid,
              }
            : null,
          {
            label: 'Carrier',
            value: dashesIfEmpty(order.carrierName),
            id: order.carrierId,
          },
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangePackingBasis)
            ? { label: 'Packing Basis', value: dashesIfEmpty(order.packingBasis) }
            : null,
        ].filter(truthy),
        [
          addressPropertyListItem({
            label: 'Bill to',
            value: formatAddressMultiLine({
              name: order.customerName,
              address1: order.billToAddress?.address1,
              address2: order.billToAddress?.address2,
              address3: order.billToAddress?.address3,
              city: order.billToAddress?.city,
              state: order.billToAddress?.state,
              zip: order.billToAddress?.postalCode,
            }),
            id: order.customerId,
          }),
          addressPropertyListItem({
            label: 'Ship To',
            value: formatAddressMultiLine({
              name: order.customerName,
              address1: order.shipToAddress?.address1,
              address2: order.shipToAddress?.address2,
              address3: order.shipToAddress?.address3,
              city: order.shipToAddress?.city,
              state: order.shipToAddress?.state,
              zip: order.shipToAddress?.postalCode,
              phone: order.shipToPhone ? formatPhoneNumber(order.shipToPhone) : undefined,
            }),
            id: order.shipToId,
          }),
          order.deliveryInstructions && order.deliveryInstructions.length > 0
            ? {
                label: 'Delivery Instructions',
                value: <div style={{ whiteSpace: 'pre-line' }}>{order.deliveryInstructions}</div>,
              }
            : null,
        ].filter(truthy),
      ],
    },
  ];

  const handleCreateOrderInvoice = async () => {
    try {
      const shouldProceed = await new Promise((resolve) => {
        Modal.confirm({
          title: 'Invoice Order',
          content: `This order will be invoiced in ${getErpName(
            activeTenant.erpType,
          )}. Please confirm you would like to proceed.`,
          okText: 'Invoice',
          cancelText: 'Cancel',
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });

      if (!shouldProceed) {
        return;
      }

      track(TrackEvent.Orders_CreateInvoice, {});
      await coreApiFetch(schemas.orders.postCreateInvoice, { pathParams: { orderNumber: salesOrderId } });
      reloadOrder();
      notification.success({ message: `Successfully invoiced order #${salesOrderId}.`, duration: 10 });
    } catch (err) {
      captureAndShowError(err, `Error while invoicing order`);
    }
  };

  return (
    <Container>
      <PageHeader
        title={salesOrderId}
        copyable
        entity={{ kind: 'Order', badge: <ErpOrderStatusBadge erpOrderStatus={order.status} /> }}
        headerActions={
          <>
            {tenantHasCreateInvoiceFeatureFlag && orderCanBeInvoiced && (
              <AsyncButton
                type="primary"
                onClick={handleCreateOrderInvoice}
                disabled={isInvoiceChecksLoading || (invoiceChecksData && invoiceChecksData.errors.length > 0)}
                icon={isInvoiceChecksLoading ? <LoadingOutlined spin /> : <PlusOutlined />}
              >
                Create Invoice
              </AsyncButton>
            )}
            {(shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) ||
              shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote)) && (
              <Dropdown
                overlay={
                  <Menu>
                    {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) && (
                      <Menu.Item
                        onClick={() => {
                          track(TrackEvent.Nav_CtaClick, {
                            ctaName: CtaName.NewQuote,
                            ctaType: CtaType.Button,
                          });
                          history.push(routes.orders.quoteNew(getHashStateFromOrder(order)));
                        }}
                      >
                        New Quote
                      </Menu.Item>
                    )}
                    {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) && (
                      <Menu.Item
                        onClick={() => {
                          track(TrackEvent.Nav_CtaClick, {
                            ctaName: CtaName.NewOrder,
                            ctaType: CtaType.Button,
                          });
                          history.push(routes.orders.orderNew(getHashStateFromOrder(order)));
                        }}
                      >
                        New Order
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  Copy as <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </>
        }
      />

      {tenantHasCreateInvoiceFeatureFlag && orderCanBeInvoiced && (
        <InvoiceChecksAlerts errors={invoiceChecksData?.errors || []} warnings={invoiceChecksData?.warnings || []} />
      )}
      <DetailPageSections sections={headerSections} />
      <NavTabs
        tabs={[
          { header: 'Order Lines', content: <OrderLinesTable orderLines={order.lines || []} /> },
          shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) &&
          isTenantErpTypeP21(activeTenant.erpType)
            ? { header: 'Transfer Lines', content: <OrderTransferLinesTable orderNo={order.salesOrderId} /> }
            : undefined,
          shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) &&
          isTenantErpTypeP21(activeTenant.erpType)
            ? { header: 'Shipments', content: <OrderShipmentsTable orderNo={order.salesOrderId} /> }
            : undefined,
          shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) && {
            header: 'Documents',
            content: <OrderDocumentsTable salesOrderId={order.salesOrderId} />,
          },
        ].filter(truthy)}
      />
    </Container>
  );
};
