import React from 'react';

import { Link } from 'react-router-dom';

import { DeploymentUnitOutlined } from '@ant-design/icons';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SalesOrderDetailLineDTO } from '@recurrency/core-api-schema/dist/v2/salesOrders/getSalesOrderDetails';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { Table } from 'components/Table';

import { routes } from 'utils/routes';
import { sortableStringColumn, sortableNumberColumn, sortableDollarWithCentsColumn } from 'utils/tables';

import { ErpOrderStatusBadge } from '../ErpOrderStatusBadge';

export const OrderAssemblyLinesAgnosticTable = ({
  orderLines,
  orderLineUid,
  onClick,
}: {
  orderLines: SalesOrderDetailLineDTO[];
  orderLineUid: string;
  onClick: (itemId?: string, orderLineUid?: string) => void;
}) => {
  const columns: ColumnType<SalesOrderDetailLineDTO>[] = [
    sortableStringColumn({
      title: 'Item ID',
      dataIndex: 'itemCode',
      width: '150px',
      render: (itemCode: string, record: SalesOrderDetailLineDTO) => (
        <>
          <Link to={routes.sales.itemDetails(itemCode)}>{itemCode}</Link>&nbsp;
          {record.lineItemType === 'assembly' && record.orderLineId && (
            <Button size="small" onClick={() => onClick(record.itemCode, record.orderLineId || '0')}>
              <DeploymentUnitOutlined />
            </Button>
          )}
        </>
      ),
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemName',
    }),
    sortableStringColumn({
      title: 'Status',
      dataIndex: 'status',
      render: (status: ErpOrderStatus) => <ErpOrderStatusBadge erpOrderStatus={status} />,
    }),
    sortableNumberColumn({
      title: 'Qty per Assembly',
      dataIndex: 'quantityPerAssembly',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'quantityOrdered',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
    }),
  ];

  return (
    <Table
      columns={columns}
      style={{ height: '70vh' }}
      pageSize={10}
      data={orderLines.filter((line) => line.parentOrderLineId && line.parentOrderLineId === orderLineUid)}
    />
  );
};
