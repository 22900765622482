import React from 'react';

import { TenantSettingKey, TenantSettingsFields } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TenantSettingsFieldNumberGridBase } from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';
import { Form } from 'antd';

import { SettingsFieldNumberGrid } from 'pages/admin/SettingsPage/SettingsFieldNumberGrid';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

import { getTenantSetting } from 'utils/tenantSettings';

export function SparseStockGridModal({ onClose }: { onClose: () => void }) {
  const sparseStockSetting = getTenantSetting(TenantSettingKey.CalculationSparseMonthsStock);
  const field = TenantSettingsFields['calculation.sparseMonthsStock'] as TenantSettingsFieldNumberGridBase;
  // This is required since the table we use for this setting pulls the values from the form
  const initial = { [TenantSettingKey.CalculationSparseMonthsStock]: sparseStockSetting };
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose
      visible
      title="Current Sparse Items Settings"
      width={1025}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="small">
          Close
        </Button>
      }
    >
      <div id="sparse-item-grid">
        <p>
          Recurrency allows organizations to select how they want to plan for sparse items based on months of demand and
          lead time. These settings have been set by your admin team, and they can modify them via the settings page.
          Learn more about how Recurrency handles sparse items{' '}
          <a
            target="_blank"
            href="https://help.recurrency.com/hc/en-us/articles/23915571454491-Sparse-Item-Replenishment"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <Form form={form} initialValues={initial}>
          <SettingsFieldNumberGrid field={field} form={form} readOnly />
        </Form>
      </div>
    </Modal>
  );
}
