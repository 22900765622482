import React, { ReactNode } from 'react';

import { Layout } from 'antd';

import * as Styled from './Container.style';

export const Container = ({ children, className }: { children: ReactNode; className?: string }) => (
  <Styled.Layout className={className}>
    <Layout.Content>{children}</Layout.Content>
  </Styled.Layout>
);
