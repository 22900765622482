import React, { useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Form, notification } from 'antd';

import { DividerLine } from 'components/DividerLine';
import { InputFormItem } from 'components/FormItems';
import { Modal } from 'components/Modal';
import { ModalSectionTitle } from 'components/Modal/ModalSectionTitle';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError, captureError } from 'utils/error';
import { generateTempPassword } from 'utils/password';
import { asKeyOf } from 'utils/tables';

import { InviteUserFields } from './types';
import { UserPermissionsForm, convertUserPermissionsForSubmit } from './UserPermissions';

export function InviteUserModal({ onClose }: { onClose: (shouldReload?: boolean) => void }) {
  const { activeUser } = useGlobalApp();
  const [form] = Form.useForm<InviteUserFields>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInviteUser = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      notification.error({ message: 'Please resolve all validation errors and then resubmit.' });
      return;
    }
    setIsSubmitting(true);
    try {
      const fields = form.getFieldsValue();
      // Make three calls to ensure user is created, then tenantUser is created, then user has been sent welcome email
      // These are separate calls from the frontend rather than one combined endpoint to allow for smoother error handling UX if a specific part of the process fails
      // Create or get the user
      const { data: user } = await coreApiFetch(schemas.users.createUser, {
        bodyParams: {
          email: fields.email,
          password: generateTempPassword(),
          firstName: fields.firstName,
          lastName: fields.lastName,
        },
      });
      // Create the tenant user
      await coreApiFetch(schemas.tenants.postCreateTenantUser, {
        bodyParams: {
          userId: user.id,
          ...convertUserPermissionsForSubmit(fields),
        },
      });
      // Send welcome email if the user has never received one
      try {
        if (user.emailEvents?.length === 0) {
          await coreApiFetch(schemas.users.sendWelcomeEmail, {
            bodyParams: { id: user.id, invitedBy: activeUser.fullName },
          });
        }
      } catch (err) {
        // Separately catch welcome email errors as they do not affect the main flow
        captureError(err);
      }
      notification.success({
        message: `Invited user ${fields.email}.`,
      });
      onClose(true);
    } catch (err) {
      captureAndShowError(err, `Error inviting user. Please try again.`);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      visible
      onCancel={() => onClose()}
      title="Invite User"
      onOk={handleInviteUser}
      okText="Invite User"
      confirmLoading={isSubmitting}
    >
      <Form form={form} layout="vertical">
        <ModalSectionTitle title="Profile" />
        <div
          className={css`
            margin-top: 10px;
          `}
        >
          <InputFormItem
            name={asKeyOf<InviteUserFields>('firstName')}
            label="First Name"
            placeholder="First Name"
            rules={[{ required: true, message: 'First name is required' }]}
          />
          <InputFormItem
            name={asKeyOf<InviteUserFields>('lastName')}
            label="Last Name"
            placeholder="Last Name"
            rules={[{ required: true, message: 'Last name is required' }]}
          />
          <InputFormItem
            name={asKeyOf<InviteUserFields>('email')}
            label="Email"
            type="email"
            placeholder="user@email.com"
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Please enter a valid email address' },
            ]}
          />
        </div>
        <DividerLine marginTop={25} marginBottom={25} />
        <UserPermissionsForm form={form} />
      </Form>
    </Modal>
  );
}
