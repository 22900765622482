import React from 'react';

import { Button as AntdButton } from 'antd';

import * as Styled from './Button.style';

export type Props = Styled.ButtonProps;

export const Button = (props: Props): JSX.Element =>
  props?.type === 'text' ? <AntdButton {...props} /> : <Styled.Button {...props} />;
