import { MultiSelectOption } from 'components/AsyncSelect/types';
import { DemandPredictabilityTag } from 'components/recipes/DemandPredictabilityTag';
import { DemandPattern } from 'components/Tooltip/DemandPatternTooltip';

export const demandPatternOptions: MultiSelectOption[] = [
  {
    label: DemandPattern.SMOOTH,
    value: DemandPattern.SMOOTH,
  },
  {
    label: DemandPattern.SPARSE,
    value: DemandPattern.SPARSE,
  },
  {
    label: DemandPattern.ERRATIC,
    value: DemandPattern.ERRATIC,
  },
  {
    label: DemandPattern.SPORADIC,
    value: DemandPattern.SPORADIC,
  },
  {
    label: DemandPattern.UNSTABLE,
    value: DemandPattern.UNSTABLE,
  },
  {
    label: DemandPattern.NEW_ITEM,
    value: DemandPattern.NEW_ITEM,
  },
  {
    label: DemandPattern.NO_DEMAND,
    value: DemandPattern.NO_DEMAND,
  },
];

export const demandPredictabilityOptions: MultiSelectOption[] = [
  {
    label: DemandPredictabilityTag.VeryHigh,
    value: DemandPredictabilityTag.VeryHigh,
  },
  {
    label: DemandPredictabilityTag.High,
    value: DemandPredictabilityTag.High,
  },
  {
    label: DemandPredictabilityTag.Medium,
    value: DemandPredictabilityTag.Medium,
  },
  {
    label: DemandPredictabilityTag.Low,
    value: DemandPredictabilityTag.Low,
  },
];
