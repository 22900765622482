import { useCallback, useState } from 'react';

import { TenantFeatureFlags } from '@recurrency/core-api-schema/dist/common/enums';

import { ProspectHashState, QuoteEditHashStateP21, SearchFrameHashState } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import { captureError } from './error';

// 'rai:' prefixed keys are json encoded. We prefix so the keys don't collide with 3rd party keys.
export const enum LocalStorageKey {
  ActiveTenantSlug = 'rai:ActiveTenantSlug',
  ActiveErpRole = 'rai:ActiveRole',
  ActiveUserCompanyIds = 'rai:ActiveUserCompanyIds',
  SelectedItemsForNewQuote = 'rai:SelectedItemsForNewQuote',
  AdminFeatureFlags = 'rai:AdminFeatureFlags',
  Form_Quote_AutoSave = 'rai:Form.Quote.AutoSave',
  Form_Order_AutoSave = 'rai:Form.Order.AutoSave',
  Form_Prospect_AutoSave = 'rai:Form.Prospect.AutoSave',
  Forecasting_Onboarding_Dismissed = 'rai:Forecasting.Onboarding.Dismissed',
  Demand_Planning_Filters = 'rai:Demand.Planning.Filters',
  Demand_Planning_Recommended_Max_Toggle_Enabled = 'rai:Demand.Planning.RecommendedMaxToggleEnabled',
  E2EAuthToken = 'rai:E2E.AuthToken',
  IntegrationPausedBanner_AcknowledgedTenants = 'rai:IntegrationPausedBanner.AcknowledgedTenants',
  SidePane_Expanded = 'rai:SidePane.Expanded',
  ReplenishmentPathPane_Expanded = 'rai:ReplenishmentPathPane.Expanded',
}

export interface TypedLocalStorage {
  [LocalStorageKey.ActiveTenantSlug]: string;
  [LocalStorageKey.ActiveErpRole]: IdNameObj;
  [LocalStorageKey.ActiveUserCompanyIds]: string[] | null;
  [LocalStorageKey.SelectedItemsForNewQuote]: IdNameObj[];
  [LocalStorageKey.AdminFeatureFlags]: TenantFeatureFlags;
  [LocalStorageKey.Form_Quote_AutoSave]: QuoteEditHashStateP21;
  [LocalStorageKey.Form_Order_AutoSave]: QuoteEditHashStateP21;
  [LocalStorageKey.Form_Prospect_AutoSave]: ProspectHashState;
  [LocalStorageKey.Forecasting_Onboarding_Dismissed]: boolean;
  [LocalStorageKey.Demand_Planning_Filters]: SearchFrameHashState;
  [LocalStorageKey.Demand_Planning_Recommended_Max_Toggle_Enabled]: boolean;
  [LocalStorageKey.E2EAuthToken]: string;
  [LocalStorageKey.IntegrationPausedBanner_AcknowledgedTenants]: string[];
  [LocalStorageKey.SidePane_Expanded]: boolean;
  [LocalStorageKey.ReplenishmentPathPane_Expanded]: boolean;
}

export function setLocalStorageItem<KeyT extends keyof TypedLocalStorage>(key: KeyT, value: TypedLocalStorage[KeyT]) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    // setItem could fail with QuotaExceededError if the storage is full
    captureError(err);
  }
}

export function getLocalStorageItem<KeyT extends keyof TypedLocalStorage>(key: KeyT): TypedLocalStorage[KeyT] | null {
  try {
    const jsonStr = localStorage.getItem(key);
    if (jsonStr) {
      return JSON.parse(jsonStr);
    }
  } catch (err) {
    // gracefully fail if json parsing fails
    captureError(err);
    localStorage.removeItem(key);
  }
  return null;
}

export function removeLocalStorageItem(key: keyof TypedLocalStorage) {
  localStorage.removeItem(key);
}

export function hasLocalStorageItem<KeyT extends keyof TypedLocalStorage>(key: KeyT): boolean {
  return Object.prototype.hasOwnProperty.call(localStorage, key);
}

/** helper hook that keeps in-memory state in sync with localStorage state */
export function useLocalStorage<KeyT extends keyof TypedLocalStorage>(
  key: KeyT,
  defaultValue: TypedLocalStorage[KeyT],
): [TypedLocalStorage[KeyT], (value: TypedLocalStorage[KeyT]) => void] {
  const [itemState, setItemState] = useState<TypedLocalStorage[KeyT]>(() => getLocalStorageItem(key) ?? defaultValue);

  const setState = useCallback(
    (value: TypedLocalStorage[KeyT]) => {
      setItemState(value);
      setLocalStorageItem(key, value);
    },
    [key],
  );
  return [itemState, setState];
}
