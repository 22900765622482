import React, { useMemo, useState } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ResultCount } from 'components/ResultCount';
import { Table } from 'components/Table';

import { arrUnique } from 'utils/array';
import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD } from 'utils/formatting';
import { sortDirections, typedColumn } from 'utils/tables';

import { Button } from '../../../../../components/Button';
import { FlexSpace } from '../../../../../components/FlexSpace';
import { showAsyncModal } from '../../../../../utils/asyncModal';
import { TransferSummary } from '../../types';
import { PurchaseTargetTransferLinesModal } from '../PurchaseTargetTransferLinesModal';

export function PurchasingOrdersStepTransfersSection({
  transferSummaries,
  onTransferLineChange,
}: {
  transferSummaries: TransferSummary[];
  onTransferLineChange: (transferSummary: TransferSummary) => void;
}) {
  const [sourceLocationIdsFilter, setSourceLocationIdsFilter] = useState<string[]>([]);
  const [destinationLocationIdsFilter, setDestinationLocationIdsFilter] = useState<string[]>([]);
  const uniqueSources = arrUnique(
    transferSummaries.map((ts) => `${ts.sourceLocation.foreignId}: ${ts.sourceLocation.name}`),
  );
  const uniqueDestinations = arrUnique(
    transferSummaries.map((ts) => `${ts.destinationLocation.foreignId}: ${ts.destinationLocation.name}`),
  );

  const filteredTransferSummaries = useMemo(
    () =>
      transferSummaries.filter(
        (transferSummary) =>
          (sourceLocationIdsFilter.length === 0 ||
            sourceLocationIdsFilter.includes(transferSummary.sourceLocation.foreignId)) &&
          (destinationLocationIdsFilter.length === 0 ||
            destinationLocationIdsFilter.includes(transferSummary.destinationLocation.foreignId)),
      ),
    [transferSummaries, destinationLocationIdsFilter, sourceLocationIdsFilter],
  );

  const tableColumns: Array<ColumnType<TransferSummary> | null> = [
    typedColumn({
      title: 'Source Location',
      dataIndex: 'sourceLocation',
      width: `300px`,
      sortDirections,
      render: (fromLocation) => (
        <div>
          <div>{fromLocation.foreignId}</div>
          <div>{fromLocation.name}</div>
        </div>
      ),
    }),
    typedColumn({
      title: 'Destination Location',
      dataIndex: 'destinationLocation',
      width: `300px`,
      sortDirections,
      render: (purchasingLocation) => (
        <div>
          <div>{purchasingLocation.foreignId}</div>
          <div>{purchasingLocation.name}</div>
        </div>
      ),
    }),
    typedColumn({
      title: 'Weight',
      dataIndex: 'totalWeight',
      sortDirections,
      align: 'right',
      render: (totalWeight) => (Number.isFinite(totalWeight) ? formatNumber(totalWeight) : '-'),
    }),
    typedColumn({
      title: 'Transfer Days',
      dataIndex: 'transferDays',
      sortDirections,
      align: 'right',
      render: (transferDays) => (Number.isFinite(transferDays) ? formatNumber(transferDays) : '-'),
    }),
    typedColumn({
      title: 'Total Lines',
      dataIndex: 'totalLines',
      sortDirections,
      align: 'right',
      render: (totalLines) => formatNumber(totalLines),
    }),
    typedColumn({
      title: 'Total Cost',
      dataIndex: 'totalCost',
      sortDirections,
      align: 'right',
      render: (totalCost) => formatUSD(totalCost, true),
    }),
    {
      title: 'Actions',
      width: '100px',
      align: 'left',
      render: (record: TransferSummary) => (
        <FlexSpace>
          <Button
            size="small"
            onClick={async () => {
              const editedTransferLines = await showAsyncModal(PurchaseTargetTransferLinesModal, {
                initialTransferSummary: record,
              });
              if (editedTransferLines) {
                onTransferLineChange(editedTransferLines);
              }
            }}
          >
            Lines
          </Button>
        </FlexSpace>
      ),
    },
  ];

  return (
    <div>
      <FilterBarBox>
        {uniqueSources.length > 1 ? (
          <AsyncMultiSelect
            label="Source Location"
            icon={<EnvironmentOutlined />}
            queryPlaceholder="Search source locations"
            selectedValues={sourceLocationIdsFilter}
            onSelectedValuesChange={(values) => setSourceLocationIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                transferSummaries.map((ts) => ({
                  label: `${ts.sourceLocation.foreignId}: ${ts.sourceLocation.name}`,
                  value: ts.sourceLocation.foreignId,
                })),
                'value',
              ),
            }}
          />
        ) : null}
        {uniqueDestinations.length > 1 ? (
          <AsyncMultiSelect
            label="Destination Location"
            icon={<EnvironmentOutlined />}
            queryPlaceholder="Search destination locations"
            selectedValues={destinationLocationIdsFilter}
            onSelectedValuesChange={(values) => setDestinationLocationIdsFilter(values)}
            selectProps={{
              options: arrUnique(
                transferSummaries.map((ts) => ({
                  label: `${ts.destinationLocation.foreignId}: ${ts.destinationLocation.name}`,
                  value: ts.destinationLocation.foreignId,
                })),
                'value',
              ),
            }}
          />
        ) : null}
        <FlexSpacer />
        <ResultCount count={filteredTransferSummaries.length} />
      </FilterBarBox>
      <Table columns={tableColumns.filter(truthy)} data={filteredTransferSummaries} verticalAlign="middle" />
    </div>
  );
}
