import React, { useState } from 'react';

import { CarryOutOutlined, FileDoneOutlined, FileTextOutlined, MailOutlined, TeamOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import {
  RecommendedQuoteDTO,
  RecommendedQuoteLineDTO,
  WorkflowState,
} from '@recurrency/core-api-schema/dist/ml/getRecommendedQuotes';
import { colors } from 'theme/colors';
import { fontWeights } from 'theme/typography';

import { Button } from 'components/Button';
import { linkableText } from 'components/Links';
import { TaskModal } from 'components/recipes/TaskModal';
import { Tabs } from 'components/Tabs';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { formatUSDAbbreviated, capitalize, formatDate, joinIdNameObj } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { RecommendedQuoteItemListAction, track, TrackEvent } from 'utils/track';

import { ComposeEmailModal } from './ComposeEmailModal';
import { ContactListModal } from './ContactListModal';
import { RecItemCards } from './RecItemCards';
import { workflowStateName } from './utils';

export function RecItemsColumn({
  selectedRecQuote,
  selectedLineItems,
  onSelectedLineItemsChange,
  onLineItemWorkflowStateChange,
}: {
  selectedRecQuote: RecommendedQuoteDTO | undefined;
  selectedLineItems: RecommendedQuoteLineDTO[];
  onSelectedLineItemsChange: (lineItems: RecommendedQuoteLineDTO[]) => void;
  onLineItemWorkflowStateChange: (
    lineItem: RecommendedQuoteLineDTO,
    workflowState: WorkflowState,
    reason?: string,
  ) => void;
}) {
  const [selectedWfState, setSelectedWfState] = useState(WorkflowState.Open);
  const shouldShowWfActions = window.location.pathname.endsWith('/opportunities2');

  const lineItemsByState = {} as Record<WorkflowState, RecommendedQuoteLineDTO[]>;
  if (selectedRecQuote) {
    for (const workflowState of Object.values(WorkflowState)) {
      lineItemsByState[workflowState] = [];
    }
    for (const lineItem of selectedRecQuote.lineItems) {
      const workflowState = lineItem.workflowState ?? WorkflowState.Open;
      lineItemsByState[workflowState].push(lineItem);
    }
  }

  return (
    <div
      className={cx(
        'RecItemsColumn',
        css`
          flex: 2;
        `,
      )}
    >
      {selectedRecQuote ? (
        <>
          <div
            className={css`
              position: sticky;
              top: 90px;
              padding-top: 20px;
              background-color: white;
              z-index: 1;
            `}
          >
            <ShipToHeader selectedRecQuote={selectedRecQuote} />
            <Tabs
              tabs={[
                {
                  header: workflowStateName[WorkflowState.Open],
                  tabKey: WorkflowState.Open,
                  count: lineItemsByState[WorkflowState.Open].length,
                  content: <div />,
                },
                ...(shouldShowWfActions
                  ? [
                      {
                        header: workflowStateName[WorkflowState.Won],
                        tabKey: WorkflowState.Won,
                        count: lineItemsByState[WorkflowState.Won].length,
                        content: <div />,
                      },
                      {
                        header: workflowStateName[WorkflowState.Lost],
                        tabKey: WorkflowState.Lost,
                        count: lineItemsByState[WorkflowState.Lost].length,
                        content: <div />,
                      },
                      {
                        header: workflowStateName[WorkflowState.Rejected],
                        tabKey: WorkflowState.Rejected,
                        count: lineItemsByState[WorkflowState.Rejected].length,
                        content: <div />,
                      },
                    ]
                  : []),
              ]}
              activeTab={selectedWfState}
              onChange={(activeTabKey) => setSelectedWfState(activeTabKey as WorkflowState)}
            />
            {selectedWfState === WorkflowState.Open ? (
              <ActionsBar
                selectedRecQuote={selectedRecQuote}
                lineItems={lineItemsByState[selectedWfState]}
                selectedLineItems={selectedLineItems}
                onSelectedLineItemsChange={onSelectedLineItemsChange}
              />
            ) : null}
          </div>

          <RecItemCards
            selectedRecQuote={selectedRecQuote}
            selectedWfState={selectedWfState}
            shouldShowWfActions={shouldShowWfActions}
            onLineItemWorkflowStateChange={onLineItemWorkflowStateChange}
            lineItems={lineItemsByState[selectedWfState]}
            selectedLineItems={selectedLineItems}
            onSelectedLineItemsChange={onSelectedLineItemsChange}
          />
        </>
      ) : null}
    </div>
  );
}

function ShipToHeader({ selectedRecQuote }: { selectedRecQuote: RecommendedQuoteDTO }) {
  return (
    <div
      className={css`
        line-height: 20px;
        margin-bottom: 16px;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          gap: 4px;
        `}
      >
        <div
          className={css`
            font-weight: ${fontWeights.bold};
            text-transform: uppercase;
            font-size: 16px;
          `}
        >
          {selectedRecQuote.shipToName || selectedRecQuote.shipToId}
        </div>
        {selectedRecQuote.shipToId && selectedRecQuote.shipToName ? (
          <div
            className={css`
              color: ${colors.neutral[500]};
            `}
          >
            #{selectedRecQuote.shipToId}
          </div>
        ) : null}
      </div>
      <div>{selectedRecQuote.shipToAddress}</div>
      <div
        className={css`
          color: ${colors.neutral[500]};
        `}
      >
        {formatUSDAbbreviated(selectedRecQuote.potentialValue)} est. value
      </div>
    </div>
  );
}

function ActionsBar({
  selectedRecQuote,
  lineItems,
  selectedLineItems,
  onSelectedLineItemsChange,
}: {
  selectedRecQuote: RecommendedQuoteDTO;
  lineItems: RecommendedQuoteLineDTO[];
  selectedLineItems: RecommendedQuoteLineDTO[];
  onSelectedLineItemsChange: (newLineItems: RecommendedQuoteLineDTO[]) => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const lineItemActionsDisabled = selectedLineItems.length === 0;

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
        padding: 4px 0 20px 0;
      `}
    >
      {lineItems.length > 0 ? (
        <div
          className={css`
            margin-right: auto;
            display: flex;
            flex-direction: row;
            gap: 8px;
          `}
        >
          <span>
            <b>{selectedLineItems.length}</b> selected
          </span>
          {selectedLineItems.length > 0
            ? linkableText({
                onClick: () => onSelectedLineItemsChange([]),
                text: 'Clear',
              })
            : null}
        </div>
      ) : null}
      <Button
        size="small"
        icon={<TeamOutlined />}
        onClick={async () => {
          await showAsyncModal(ContactListModal, {
            customerId: selectedRecQuote.customerId,
            customerName: selectedRecQuote.customerName,
          });
          track(TrackEvent.RecommendedQuotes_ItemList_Action, {
            action: RecommendedQuoteItemListAction.OpenContactsModal,
            numLineItems: selectedLineItems.length,
            shipTo: joinIdNameObj({
              foreignId: selectedRecQuote.shipToId,
              name: selectedRecQuote.shipToName || '',
            }),
          });
        }}
      >
        Contacts
      </Button>
      <Button
        size="small"
        icon={<MailOutlined />}
        disabled={lineItemActionsDisabled}
        onClick={async () => {
          const contact = await showAsyncModal(ComposeEmailModal, {
            title: 'Compose Email',
            customerId: selectedRecQuote.customerId,
          });
          if (contact) {
            const contactFirstName = capitalize(contact.name.split(' ')[0].toLocaleLowerCase());
            const mailBody = [
              `Hi ${contactFirstName},`,
              `Would any of these reorder items be of interest to you?`,
              getSelectedItemsBodyText(selectedLineItems),
            ].join('\n\n');

            window.open(
              `mailto:${contact.email}?subject=${encodeURIComponent(
                `Recommended Reorders (${formatDate(new Date().toISOString())})`,
              )}&body=${encodeURIComponent(mailBody)}`,
              '_blank',
            );

            track(TrackEvent.RecommendedQuotes_ItemList_Action, {
              action: RecommendedQuoteItemListAction.ComposeEmail,
              numLineItems: selectedLineItems.length,
              shipTo: joinIdNameObj({
                foreignId: selectedRecQuote.shipToId,
                name: selectedRecQuote.shipToName || '',
              }),
            });
          }
        }}
      >
        Email
      </Button>
      <Button
        size="small"
        icon={<CarryOutOutlined />}
        disabled={lineItemActionsDisabled}
        onClick={async () => {
          const task = await showAsyncModal(TaskModal, {
            initialValues: {
              customer: {
                foreignId: selectedRecQuote.customerId,
                name: selectedRecQuote.customerName,
              },
              body: getSelectedItemsBodyText(selectedLineItems),
            },
          });

          if (task) {
            track(TrackEvent.RecommendedQuotes_ItemList_Action, {
              action: RecommendedQuoteItemListAction.NewTask,
              numLineItems: selectedLineItems.length,
              shipTo: joinIdNameObj({
                foreignId: selectedRecQuote.shipToId,
                name: selectedRecQuote.shipToName || '',
              }),
            });
          }
        }}
      >
        Task
      </Button>
      {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) ? (
        <Button
          size="small"
          icon={<FileTextOutlined />}
          disabled={lineItemActionsDisabled}
          onClick={() => {
            window.open(
              routes.orders.quoteNew({
                customer: joinIdNameObj({
                  foreignId: selectedRecQuote.customerId,
                  name: selectedRecQuote.customerName,
                }),
                // SAPB1 doesn't have enforced shipToId -> shipTo relationship.
                shipTo: joinIdNameObj({
                  foreignId: selectedRecQuote.shipToId,
                  name: selectedRecQuote.shipToName || '',
                }),
                items: selectedLineItems.map((lineItem) => ({
                  foreignId: lineItem.itemId,
                  name: lineItem.itemName,
                  price: lineItem.lastUnitPrice,
                  quantity: lineItem.lastQty,
                  unitOfMeasure: lineItem.unitOfMeasure,
                })),
              }),
              '_blank',
            );
            track(TrackEvent.RecommendedQuotes_ItemList_Action, {
              action: RecommendedQuoteItemListAction.NewQuote,
              numLineItems: selectedLineItems.length,
              shipTo: joinIdNameObj({
                foreignId: selectedRecQuote.shipToId,
                name: selectedRecQuote.shipToName || '',
              }),
            });
          }}
        >
          Quote
        </Button>
      ) : null}
      {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) ? (
        <Button
          size="small"
          icon={<FileDoneOutlined />}
          disabled={lineItemActionsDisabled}
          onClick={() => {
            window.open(
              routes.orders.orderNew({
                customer: joinIdNameObj({
                  foreignId: selectedRecQuote.customerId,
                  name: selectedRecQuote.customerName,
                }),
                // SAPB1 doesn't have enforced shipToId -> shipTo relationship.
                shipTo: joinIdNameObj({
                  foreignId: selectedRecQuote.shipToId,
                  name: selectedRecQuote.shipToName || '',
                }),
                items: selectedLineItems.map((lineItem) => ({
                  foreignId: lineItem.itemId,
                  name: lineItem.itemName,
                  price: lineItem.lastUnitPrice,
                  quantity: lineItem.lastQty,
                  unitOfMeasure: lineItem.unitOfMeasure,
                })),
              }),
              '_blank',
            );
            track(TrackEvent.RecommendedQuotes_ItemList_Action, {
              action: RecommendedQuoteItemListAction.NewOrder,
              numLineItems: selectedLineItems.length,
              shipTo: joinIdNameObj({
                foreignId: selectedRecQuote.shipToId,
                name: selectedRecQuote.shipToName || '',
              }),
            });
          }}
        >
          Order
        </Button>
      ) : null}
    </div>
  );
}

function getSelectedItemsBodyText(selectedLineItems: RecommendedQuoteLineDTO[]): string {
  const bodyText = selectedLineItems
    .map((lineItem) => [
      `Item ID: ${lineItem.itemId}`,
      `Item: ${lineItem.itemName}`,
      `Qty Recommended to Order: ${lineItem.lastQty / lineItem.lastUnitSize} ${lineItem.unitOfMeasure}`,
      `Last Ordered: ${formatDate(lineItem.lastOrderDate)}`,
      ``,
    ])
    .flat()
    .join('\n');

  return bodyText;
}
