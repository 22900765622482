import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

import { formatNumber } from 'utils/formatting';

export function ResultsCounter({ isLoading, count }: { isLoading: boolean; count?: number }) {
  return (
    <div
      className={css`
        font-size: 14px;
        padding-left: 8px;
      `}
    >
      {isLoading ? (
        <span>
          <LoadingOutlined />{' '}
        </span>
      ) : (
        <b>{formatNumber(count ?? 0)}</b>
      )}{' '}
      results
    </div>
  );
}
