import React from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { RecommendedCustomersRecordDTO } from '@recurrency/core-api-schema/dist/ml/getRecommendedCustomersForItem';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ButtonLink } from 'components/Links';

import { routes } from 'utils/routes';
import { sortableStringColumn, sortableIdColumn, sortablePercentColumn } from 'utils/tables';

export const RecommendedCustomersTable = ({ itemUid }: { itemUid: string }) => {
  const columns: ColumnType<RecommendedCustomersRecordDTO>[] = [
    sortableIdColumn({
      title: 'Customer ID',
      dataIndex: 'customerId',
      render: (customerId: string) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
    }),
    sortableStringColumn({
      title: 'Customer',
      dataIndex: 'customerName',
    }),
    sortablePercentColumn({
      title: 'Match %',
      dataIndex: 'matchRank',
      defaultSortOrder: 'descend',
    }),
    {
      render: ({ customerId }) => ButtonLink(routes.sales.customerDetails(customerId)),
    },
  ];

  return (
    <AsyncTable
      rowKey="customerId"
      tableProps={useCoreApiTableProps({
        schema: schemas.ml.getRecommendedCustomersForItem,
        pathParams: { itemUid },
      })}
      columns={columns}
    />
  );
};
