import React from 'react';

import {
  BuildOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  MonitorOutlined,
  ProfileOutlined,
  ShopOutlined,
  SkinOutlined,
  StockOutlined,
  TagOutlined,
} from '@ant-design/icons';

import { demandPatternOptions, demandPredictabilityOptions } from 'pages/purchasing/PlanningPage/utils';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import {
  useLocationsSelectProps,
  useItemGroupsSelectProps,
  useSuppliersSelectProps,
  useItemsSelectProps,
  usePurchaseClassSelectProps,
  usePurchasingBuyersSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { ActionButton } from 'components/Button/ActionButton';
import { FlexSpace } from 'components/FlexSpace';
import { ProductGroupMultiSelect } from 'components/recipes/select/ProductGroupMultiSelect';

import { toSnakeCase } from 'utils/formatting';

import { InventoryDashboardHashStateFilters } from 'types/hash-state';

export const InventoryDashboardFilters = ({
  label,
  filterState,
  onFiltersChange,
  showStockableFilter = false,
  showPurchaseClassFilter = false,
  showProductGroupFilter = false,
  showStatusFilter = false,
  showDemandPatternFilter = false,
  showDemandPredictabilityFilter = false,
  showBuyerFilter = false,
}: {
  label?: string;
  filterState?: InventoryDashboardHashStateFilters;
  onFiltersChange: (filters: InventoryDashboardHashStateFilters) => void;
  showStatusFilter?: boolean;
  showStockableFilter?: boolean;
  showPurchaseClassFilter?: boolean;
  showProductGroupFilter?: boolean;
  showDemandPatternFilter?: boolean;
  showDemandPredictabilityFilter?: boolean;
  showBuyerFilter?: boolean;
}) => {
  const locationsSelectProps = useLocationsSelectProps({});
  const itemGroupsSelectProps = useItemGroupsSelectProps();
  const supplierSelectProps = useSuppliersSelectProps();
  const purchaseClassSelectProps = usePurchaseClassSelectProps();
  const itemsSelectProps = useItemsSelectProps();
  const buyerSelectProps = usePurchasingBuyersSelectProps();

  const numberOfFiltersApplied = Object.values(filterState ?? {})
    .map((values) => (Array.isArray(values) ? values.length : 0))
    .reduce((a: number, b: number) => a + b, 0);

  const onHashStateFilterChange = (filterKey: keyof InventoryDashboardHashStateFilters, values: unknown) => {
    const filters = { ...filterState };
    if (filterKey !== 'timeSpan') {
      filters[filterKey] = values as string[];
    }

    onFiltersChange(filters);
  };

  return (
    <FlexSpace gap={8} wrap>
      {label ?? <div>{label}</div>}
      {showStatusFilter ? (
        <AsyncMultiSelect
          selectProps={{
            options: [
              {
                label: 'Normal',
                value: 'Normal',
              },
              {
                label: 'Overstock',
                value: 'Overstock',
              },
              {
                label: 'Dead Stock',
                value: 'Deadstock',
              },
            ],
          }}
          label="Status"
          selectedValues={filterState?.status ?? []}
          onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('status', values)}
          icon={<DashboardOutlined />}
        />
      ) : null}

      <AsyncMultiSelect
        selectProps={convertToMultiSelectProps(locationsSelectProps)}
        label="Location"
        queryPlaceholder="Search locations"
        selectedValues={filterState?.locationIds ?? []}
        onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('locationIds', values)}
        icon={<EnvironmentOutlined />}
      />
      {showStockableFilter && (
        <AsyncMultiSelect
          selectProps={{
            options: [
              {
                label: 'Stockable',
                value: 'true',
              },
              {
                label: 'Non-Stockable',
                value: 'false',
              },
            ],
          }}
          label="Stockable"
          selectedValues={filterState?.stockable ?? []}
          onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('stockable', values)}
          icon={<BuildOutlined />}
        />
      )}
      {showPurchaseClassFilter && (
        <AsyncMultiSelect
          selectProps={convertToMultiSelectProps(purchaseClassSelectProps)}
          label="ABC Class"
          queryPlaceholder="Search ABC classes"
          selectedValues={filterState?.purchaseClasses ?? []}
          onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('purchaseClasses', values)}
          icon={<TagOutlined />}
        />
      )}
      <AsyncMultiSelect
        selectProps={convertToMultiSelectProps(supplierSelectProps)}
        label="Primary Supplier"
        queryPlaceholder="Search suppliers"
        selectedValues={filterState?.supplierIds ?? []}
        onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('supplierIds', values)}
        icon={<ShopOutlined />}
      />
      {showBuyerFilter && (
        <AsyncMultiSelect
          selectProps={convertToMultiSelectProps(buyerSelectProps)}
          label="Buyer"
          queryPlaceholder="Search buyers"
          selectedValues={filterState?.buyerIds ?? []}
          onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('buyerIds', values)}
          icon={<ProfileOutlined />}
        />
      )}
      {showProductGroupFilter && (
        <ProductGroupMultiSelect
          selectProps={convertToMultiSelectProps(itemGroupsSelectProps)}
          selectedValues={filterState?.itemGroupIds ?? []}
          onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('itemGroupIds', values)}
        />
      )}
      <AsyncMultiSelect
        selectProps={convertToMultiSelectProps(itemsSelectProps)}
        label="Item"
        queryPlaceholder="Search items"
        selectedValues={filterState?.itemIds ?? []}
        onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('itemIds', values)}
        icon={<SkinOutlined />}
      />
      {showDemandPatternFilter ? (
        <AsyncMultiSelect
          selectProps={{
            // ml_demand_forecasts table uses snake_case for demand_pattern values
            options: demandPatternOptions.map((option) => ({ label: option.label, value: toSnakeCase(option.value) })),
          }}
          label="Demand Pattern"
          selectedValues={filterState?.demandPattern ?? []}
          onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('demandPattern', values)}
          icon={<StockOutlined />}
        />
      ) : null}
      {showDemandPredictabilityFilter ? (
        <AsyncMultiSelect
          selectProps={{
            options: demandPredictabilityOptions.map((option) => ({
              label: option.label,
              value: toSnakeCase(option.value),
            })),
          }}
          label="Demand Predictability"
          selectedValues={filterState?.demandPredictability ?? []}
          onSelectedValuesChange={(values: string[]) => onHashStateFilterChange('demandPredictability', values)}
          icon={<MonitorOutlined />}
        />
      ) : null}

      {numberOfFiltersApplied > 0 && <ActionButton onClick={() => onFiltersChange({})} label="Clear All" />}
    </FlexSpace>
  );
};
