import {
  ErpRole,
  RecurrencyRole,
  TenantFeatureFlag,
  TenantFeatureFlags,
  TenantProductName,
} from '@recurrency/core-api-schema/dist/common/enums';
import { CompanyDTO } from '@recurrency/core-api-schema/dist/companies/getCompanies';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { TenantUserErpRoleDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';

import { ExtendedUser, ExtendedRole } from 'hooks/useGlobalApp';

import { arrIsEqual } from './array';
import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from './localStorage';
import { makePath, parsePath, routePathsList } from './routes';

export function getTenantSlugFromPathOrLocalStorage(): string {
  // certain routes e.g '/account/settings' don't contain tenantSlug in url
  // in those scenarios we need to use the last activeTenantSlug saved in localStorage.
  const parsePathResult = parsePath(window.location.pathname);
  const pathTenantSlug = parsePathResult.tenantSlug;
  const lStorageTenantSlug = getLocalStorageItem(LocalStorageKey.ActiveTenantSlug);
  return pathTenantSlug || lStorageTenantSlug || ``;
}

/** Resets filters and autosaves in the local storage and returns a path without specific IDs */
function resetSavedValuesAndGetUpdatedPath(): string {
  const parsePathResult = parsePath(window.location.pathname);
  let { routePath } = parsePathResult;

  // Remove auto saved values so tenant information isn't populated into others
  setLocalStorageItem(LocalStorageKey.Demand_Planning_Filters, {});
  setLocalStorageItem(LocalStorageKey.Form_Quote_AutoSave, {});
  setLocalStorageItem(LocalStorageKey.Form_Prospect_AutoSave, {});
  setLocalStorageItem(LocalStorageKey.Form_Order_AutoSave, {});

  if (routePath.includes('/:id')) {
    // ids aren't shared across tenants, and the page is likely to 404
    // replace detail page with the list page e.g '<entity>/:id>' to '<entitiy>s'
    routePath = routePath.replace('/:id', 's');
    if (!routePathsList.includes(routePath)) {
      // redirect to homepage, no matching path found
      routePath = '/';
    }
  }
  return routePath;
}

export function reloadPageWithTenantSlug(activeTenantSlug: string) {
  const parsePathResult = parsePath(window.location.pathname);
  const routePath = resetSavedValuesAndGetUpdatedPath();

  const replacedPath = makePath(routePath, { ...parsePathResult.pathParams, tenant: activeTenantSlug });
  window.location.replace(replacedPath);
}

export function reloadPageWithUpdatedCompanySelection(selectedCompanyIds: string[]) {
  // keep it A-Z sorted so we can detect if this list is same as all allowed companies
  selectedCompanyIds = selectedCompanyIds.sort();
  setLocalStorageItem(LocalStorageKey.ActiveUserCompanyIds, selectedCompanyIds);
  const parsePathResult = parsePath(window.location.pathname);
  const routePath = resetSavedValuesAndGetUpdatedPath();

  const replacedPath = makePath(routePath, { ...parsePathResult.pathParams });
  window.location.replace(replacedPath);
}

export function getActiveErpRole(roles: ExtendedRole[]): ExtendedRole {
  const lStorageRole = getLocalStorageItem(LocalStorageKey.ActiveErpRole);
  const activeRole =
    (lStorageRole &&
      roles.find(
        (role: ExtendedRole) => role.name === lStorageRole.name && role.foreignId === lStorageRole.foreignId,
      )) ||
    roles[0];
  return activeRole;
}

/**
 * Get active company ids from local storage, and filter out any that are not in the allowed companies.
 *
 * if null, then user has access to all companies.
 * if not null, then guaranteed to at-least have 1 company.
 */
export function getActiveCompanyIds(userAllowedCompanies: CompanyDTO[]): string[] | null {
  const localStorageActiveCompanyIds = getLocalStorageItem(LocalStorageKey.ActiveUserCompanyIds) || [];
  const allowedCompanyIds = userAllowedCompanies.map((c) => c.companyId);
  let activeCompanyIds: string[] | null = localStorageActiveCompanyIds || [];

  // If user has any active companies that are not in their allowed users, remove them
  activeCompanyIds = activeCompanyIds.filter((id) => allowedCompanyIds.includes(id));

  // reset to allowedCompanyIds if activeCompanyIds are empty
  if (activeCompanyIds.length === 0) {
    activeCompanyIds = allowedCompanyIds;
  }

  // if user has access to all companies, we don't need to send companyIds to core-api per endpoint
  if (arrIsEqual(activeCompanyIds, allowedCompanyIds)) {
    activeCompanyIds = null;
  }

  // update local storage if active company ids have changed
  if (!arrIsEqual(activeCompanyIds, localStorageActiveCompanyIds)) {
    setLocalStorageItem(LocalStorageKey.ActiveUserCompanyIds, activeCompanyIds);
  }

  return activeCompanyIds;
}

export function reloadPageWithRole(role: ExtendedRole) {
  setLocalStorageItem(LocalStorageKey.ActiveErpRole, role);
  window.location.reload();
}

export function roleToString(role: ExtendedRole | undefined) {
  let roleString = '';
  if (role) {
    roleString = `${role.name.charAt(0).toUpperCase() + role.name.slice(1)}`;
    const roleContactName = role.contactName ? `: ${role.contactName}` : '';
    roleString += roleContactName;
  }
  return roleString;
}

export function isAdmin(foreignId: string | undefined, name: string | undefined): boolean {
  return (!foreignId || foreignId === '') && name === 'admin';
}

export function isManager(role: ExtendedRole): boolean {
  return role?.name === 'manager';
}

export function adminFeatureFlags(activeUser: ExtendedUser): TenantFeatureFlags {
  return ((activeUser.isRecurrencyAdmin && getLocalStorageItem(LocalStorageKey.AdminFeatureFlags)) ||
    {}) as TenantFeatureFlags;
}

// TODO: this is tech debt until better user permissioning is implemented
// https://airtable.com/appRWpw4uiMyCqJoA/tblrO0vDCR17Cm5MA/viwCrSatqxyhN1bAk/rec17s52S4HHNn1RC?blocks=hide
const productRoleOverrides: Partial<
  { [p in TenantProductName]: { roles: Partial<{ [r in RecurrencyRole]: boolean }>; default?: boolean } }
> = {
  [TenantProductName.Payments]: { roles: { [RecurrencyRole.PaymentsModuleViewer]: true }, default: false },
  [TenantProductName.SalesReporting]: { roles: { [RecurrencyRole.ReportsManager]: true }, default: undefined },
  [TenantProductName.SalesMissionControl]: { roles: { [RecurrencyRole.ReportsManager]: true }, default: undefined },
};

export function shouldShowProduct(activeTenant: TenantDTO, product: TenantProductName, useBackendEnabled = false) {
  if (product in productRoleOverrides) {
    for (const [role, value] of Object.entries(productRoleOverrides[product]!.roles)) {
      if (isRecurrencyRole(activeTenant, role as RecurrencyRole)) {
        return value;
      }
    }
    if (productRoleOverrides[product]?.default !== undefined) {
      return productRoleOverrides[product]?.default;
    }
  }
  if (useBackendEnabled) {
    return activeTenant.products?.[product].backendEnabled;
  }
  return activeTenant.products?.[product].productEnabled;
}

export function shouldShowFreeProduct(activeTenant: TenantDTO) {
  // Show the Recurrency Reporting logo and upsell banners to tenants who are not either paying for or trialing
  // paid product functionality:
  // Enterprise basic should be enabled for all paid tenants
  // DFPA search should cover all tenants paying for or trialing DFPA features
  // Sales search should cover all tenants paying for or trialing Sales features
  return !(
    shouldShowProduct(activeTenant, TenantProductName.EnterpriseBasic) ||
    shouldShowProduct(activeTenant, TenantProductName.DfpaSearchAndLookup) ||
    shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup)
  );
}

const searchIndexProductsMapping: Record<SearchIndexName, TenantProductName[]> = {
  [SearchIndexName.Customers]: [TenantProductName.SalesSearchAndLookup],
  [SearchIndexName.Items]: [TenantProductName.DfpaSearchAndLookup, TenantProductName.SalesSearchAndLookup],
  [SearchIndexName.SalesOrders]: [TenantProductName.DfpaSearchAndLookup, TenantProductName.SalesSearchAndLookup],
  [SearchIndexName.PurchaseOrders]: [TenantProductName.DfpaSearchAndLookup],
  [SearchIndexName.TransferOrders]: [TenantProductName.DfpaSearchAndLookup],
  [SearchIndexName.ShipTos]: [TenantProductName.SalesSearchAndLookup],
  [SearchIndexName.Vendors]: [TenantProductName.DfpaSearchAndLookup],
  [SearchIndexName.Forecasts]: [TenantProductName.DfpaDemandPlanning],
  [SearchIndexName.Planning]: [TenantProductName.DfpaDemandPlanning],
  [SearchIndexName.InventoryStatus]: [TenantProductName.DfpaDemandPlanning],
};

export function shouldShowSearchIndex(activeTenant: TenantDTO, searchIndex: SearchIndexName) {
  return searchIndexProductsMapping[searchIndex].some((product) => shouldShowProduct(activeTenant, product));
}

/**
 * Check Feature: Hide Cost and Gross Margin for users outside a specific role.
 * @returns boolean
 */
export function shouldHideCostAndGm(
  activeTenant: TenantDTO,
  activeUser: ExtendedUser,
  allowedRoles?: RecurrencyRole[],
) {
  const defaultAllowedCostAndGMRoles: RecurrencyRole[] = [
    RecurrencyRole.CustomerExperienceRepresentative,
    RecurrencyRole.PriceAndGrossMarginManager,
  ];
  const featureHidesCostAndGM = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeatureHideCostAndGrossMargin,
  );
  const allAllowedRoles = allowedRoles
    ? [...defaultAllowedCostAndGMRoles].concat(allowedRoles)
    : defaultAllowedCostAndGMRoles;
  if (featureHidesCostAndGM) {
    return !allAllowedRoles.some((role) => isRecurrencyRole(activeTenant, role));
  }
  return false;
}

export function shouldShowFeatureFlag(activeTenant: TenantDTO, activeUser: ExtendedUser, feature: TenantFeatureFlag) {
  // TODO: uses a roles to featureFlag mapping
  if (
    feature === TenantFeatureFlag.OrdersDefaultSalesLocationFromTakerDefaultLocation &&
    isRecurrencyRole(activeTenant, RecurrencyRole.CustomerExperienceRepresentative)
  ) {
    return false;
  }
  if (
    feature === TenantFeatureFlag.SalesShowCostAndGrossMargin &&
    (isRecurrencyRole(activeTenant, RecurrencyRole.CustomerExperienceRepresentative) ||
      isRecurrencyRole(activeTenant, RecurrencyRole.PriceAndGrossMarginManager))
  ) {
    return true;
  }
  if (
    feature === TenantFeatureFlag.OrdersDisablePriceAndGMChange &&
    isRecurrencyRole(activeTenant, RecurrencyRole.PriceAndGrossMarginManager)
  ) {
    return false;
  }
  if (feature === TenantFeatureFlag.SalesStatistics && isRecurrencyRole(activeTenant, RecurrencyRole.ReportsManager)) {
    return true;
  }
  if (
    feature === TenantFeatureFlag.SalesCreateItem &&
    isRecurrencyRole(activeTenant, RecurrencyRole.InventoryManager)
  ) {
    return true;
  }
  if (
    feature === TenantFeatureFlag.OrdersCreateOrder &&
    isRecurrencyRole(activeTenant, RecurrencyRole.SalesOrderManager)
  ) {
    return true;
  }
  // temporary for pre-release - needs better user-based access control
  if (feature === TenantFeatureFlag.PaymentsCreateRefund) {
    return isRecurrencyRole(activeTenant, RecurrencyRole.PaymentsRefundCreator);
  }
  return activeTenant.featureFlags?.[feature] || !!adminFeatureFlags(activeUser)[feature];
}

export function isPurchaserRole(role: Maybe<TenantUserErpRoleDTO>): boolean {
  return !!role && (role.name === ErpRole.Purchaser || isAdmin(role.foreignId, role.name));
}

export function isRecurrencyRole(activeTenant: TenantDTO, role: RecurrencyRole): boolean {
  return activeTenant.tenantUser.recurrencyRoles.map((r) => r.name).includes(role);
}

export function isAtLeastOneOfRecurrencyRoles(activeTenant: TenantDTO, roles: RecurrencyRole[] | undefined): boolean {
  return activeTenant.tenantUser.recurrencyRoles.some((role) => roles?.includes(role.name));
}
