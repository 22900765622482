import { ISODateStr } from '@recurrency/core-api-schema/dist/common/types';

import { SidePanePanelType } from 'utils/track';

export interface RecommendedItem {
  itemCode: string;
  itemName: string;
  lastQty?: number;
  lastUnitPrice?: number;
  unitOfMeasure?: string;
  orderDate?: ISODateStr;
}

export const panelTitleByType = {
  [SidePanePanelType.AccessoryItems]: 'Accessories',
  [SidePanePanelType.Notes]: 'Notes',
  [SidePanePanelType.SalesOfItemToCustomer]: 'Sales History for this Customer',
  [SidePanePanelType.QuotesOfItemToCustomer]: 'Quote History for this Customer',
  [SidePanePanelType.OrdersOfItemToCustomer]: 'Order History for this Customer',
  [SidePanePanelType.LocationAvailability]: 'Availability',
  [SidePanePanelType.SalesOfItemAllCustomers]: 'Sales History for all Customers',
};
