import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { CreateItemBody } from '@recurrency/core-api-schema/dist/items/createItem';
import { ErpItemDetailsDTO } from '@recurrency/core-api-schema/dist/items/erpItemDetailsDTO';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Checkbox, Form } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import * as Styled from 'pages/orders/quotes/QuoteEditFlowP21/QuoteLineItems/QuoteLineItems.style';

import { Alert } from 'components/Alert';
import { AsyncSelect } from 'components/AsyncSelect';
import {
  useCompaniesSelectProps,
  useItemGroupsSelectProps,
  useItemSalesTaxClassesSelectProps,
  useInventoryClassesSelectProps,
  useSuppliersSelectProps,
  useUnitOfMeasuresSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { TextArea } from 'components/TextArea';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { optArrFromVal } from 'utils/array';
import { truthy } from 'utils/boolean';
import { capitalize, joinIdNameObj, joinIfIdNameObj, splitIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { searchIndex } from 'utils/search/search';
import { typedColumn } from 'utils/tables';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { OrderType } from 'utils/track';
import { validateItemDescription, validateItemId, validateSupplierPartNo } from 'utils/validation';

import { ForeignIdNameStr } from 'types/hash-state';
import { SearchIndexItemPartial } from 'types/search-collections';

import { AssemblyComponentWithInfo, LineComponentItemActions } from './AssemblyTypes';
import { getLineComponentItemColumns } from './getLineComponentItemColumns';

const borderBoxCss = css`
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: 8px;
`;

const titleCss = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
  margin-left: 8px;
`;

interface ItemFormData {
  company: ForeignIdNameStr;
  itemId: string;
  itemName: string;
  itemDescription: string;
  itemGroup: ForeignIdNameStr;
  supplier: ForeignIdNameStr;
  isTemporary: boolean;
  unitOfMeasureObj: ForeignIdNameStr;
  unitSize: number;
  productType?: string; // temporary or regular item
  avalaraTaxClass?: ForeignIdNameStr; // optional field based on TenantFeatureFlag.ItemCreateTaxCode
  glAccountNo?: string;
  revenueAccountNo?: string;
  cosAccountNo?: string;
  location?: ForeignIdNameStr;
  itemAssemblyComponents?: AssemblyComponentWithInfo[];
  standardCost?: number; // optional field: passsing undefined will set default value 0
  poCost?: number; // optional field: passsing undefined will set default value 0
  price1?: number; // optional field: passsing undefined will set default value 0
  supplierPartNo?: string; // optional field based on TenantFeatureFlag.ItemCreateSupplierPartNo
  class2?: ForeignIdNameStr; // optional field base on TenantFeatureFlag.ItemCreateClassId2
  serialized?: boolean; // optional field TenantFeatureFlag.ItemCreateTrackSerialNumber
}

interface ItemModalProps {
  initialValues?: Partial<ItemFormData>;
  orderType?: OrderType;
  assemblyItem?: boolean; // create assembly item => true, non-assembly item => false
  onClose: (
    closeData?:
      | {
          newData?: CreateItemBody;
          existingData?: SearchIndexItemPartial;
        }
      | undefined,
  ) => void;
  onSubmit?: (item: ErpItemDetailsDTO) => void;
}

enum NewItemStep {
  ItemEntryStep,
  ComponentsEntryStep,
  ReviewStep,
}

export const NewItemModal = ({ initialValues, orderType, assemblyItem, onClose }: ItemModalProps) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const itemFormConfig = getTenantConfiguration(activeTenant).itemEntryFlow.formConfig;

  const quoteOrderEntryVariant = orderType !== undefined;
  const nextButtonText = quoteOrderEntryVariant
    ? `Add to ${capitalize(orderType)}`
    : `Submit to ${activeTenant.erpType.toUpperCase()}`;

  const [itemForm] = Form.useForm();
  const [itemState, setItemState] = useState<Partial<ItemFormData>>(() => ({
    ...initialValues,
    unitSize: 1,
  }));

  const unitOfMeasuresSelectProps = useUnitOfMeasuresSelectProps();
  const companiesSelectProps = useCompaniesSelectProps();
  if (!itemState.company) {
    itemState.company = activeTenant.defaultData.quote.company
      ? joinIfIdNameObj(activeTenant.defaultData.quote.company)
      : companiesSelectProps.options[0]?.value;
  }
  const hasSingleCompany = companiesSelectProps.options.length <= 1;
  const companyId = itemState.company ? splitIdNameStr(itemState.company).foreignId : '';
  const locationId = itemState.location ? splitIdNameStr(itemState.location).foreignId : undefined;
  const itemGroupsSelectProps = useItemGroupsSelectProps({ companyId, withAccountNoFields: true });
  const suppliersSelectProps = useSuppliersSelectProps();
  const itemSalesTaxClassesSelectProps = useItemSalesTaxClassesSelectProps();
  const inventoryClass2SelectProps = useInventoryClassesSelectProps({ classNumber: 2 });

  const { data: locationData } = useCoreApi(schemas.locations.getLocations, {
    queryParams: { filter: { companyIds: optArrFromVal(companyId) } },
  });

  const locationSettingConfig = quoteOrderEntryVariant
    ? itemFormConfig.locationForQuoteEntry
    : itemFormConfig.locationForInventory;
  const locationIds =
    locationSettingConfig.selectMode === 'all'
      ? (locationData?.items || [])
          .filter((item) => item.locationName !== locationSettingConfig.excludeName)
          .map((item: any) => item.locationId)
      : [
          ...new Set(
            [
              locationSettingConfig.listOptions?.defaultLocation
                ? activeTenant.defaultData.quote.location?.foreignId
                : null,
              locationSettingConfig.listOptions?.quoteEntryLocation ? locationId : null,
            ].filter(truthy),
          ),
        ];
  const locationCopy =
    locationSettingConfig.selectMode === 'all' ? 'all locations' : `Location ${locationIds.join(',')}`;

  useEffect(() => {
    if (
      (itemFormConfig.itemGroup.defaultValue && !itemState.itemGroup) ||
      (itemFormConfig.class2.defaultValue && !itemState.class2)
    ) {
      if (itemFormConfig.itemGroup.defaultValue && !itemState.itemGroup)
        itemState.itemGroup = joinIdNameObj(itemFormConfig.itemGroup.defaultValue);
      if (itemFormConfig.class2.defaultValue && !itemState.class2)
        itemState.class2 = joinIdNameObj(itemFormConfig.class2.defaultValue);
      itemForm.setFieldsValue(itemState);
    }
  }, [itemForm, itemState, activeTenant.id, itemFormConfig]);

  // clear supplier on company change so supplier can reload
  useEffect(() => {
    itemState.supplier = undefined;
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      assemblyItem &&
      !itemState.supplier &&
      itemFormConfig.supplier.defaultValueForAssemblyByCompanyId &&
      companyId in itemFormConfig.supplier.defaultValueForAssemblyByCompanyId
    )
      itemState.supplier = joinIdNameObj(itemFormConfig.supplier.defaultValueForAssemblyByCompanyId[companyId]);
    itemForm.setFieldsValue(itemState);
  }, [
    itemForm,
    itemState,
    activeTenant.id,
    itemFormConfig.supplier.defaultValueForAssemblyByCompanyId,
    assemblyItem,
    companyId,
  ]);

  // when unitOfMeasure = 'EA', set unitSize = 1
  useEffect(() => {
    if (splitIdNameStr(itemState.unitOfMeasureObj || ':').foreignId === 'EA') {
      itemState.unitSize = 1;
      itemForm.setFieldsValue(itemState);
    }
  }, [itemState.unitOfMeasureObj, itemState.unitSize, itemForm, itemState]);

  // use the first unit of measure as the default base unit
  useEffect(() => {
    if (!itemState.unitOfMeasureObj) itemState.unitOfMeasureObj = unitOfMeasuresSelectProps.options[0]?.value;
    itemForm.setFieldsValue(itemState);
  }, [itemForm, itemState, unitOfMeasuresSelectProps.isLoading, unitOfMeasuresSelectProps.options]);

  // use itemState as the source of state for the form
  useEffect(() => {
    itemState.itemId = itemState.itemId?.toLocaleUpperCase();
    itemForm.setFieldsValue(itemState);
  }, [itemForm, itemState]);

  const [currentStep, setStep] = useState(NewItemStep.ItemEntryStep);
  const [selectedExistingItem, setSelectedExistingItem] = useState<SearchIndexItemPartial | undefined>(undefined);

  async function handleSubmit(data: ItemFormData) {
    onClose({
      newData: {
        companyId: data.company ? splitIdNameStr(data.company).foreignId : companyId || '',
        itemId: data.itemId.trim(),
        itemName: data.itemName,
        itemDescription: data.itemDescription || '',
        itemGroupId: splitIdNameStr(data.itemGroup).foreignId,
        supplierId: splitIdNameStr(data.supplier).foreignId,
        locationIds,
        productType: 'T',
        itemSalesTaxClass: data.avalaraTaxClass ? splitIdNameStr(data.avalaraTaxClass).foreignId : undefined,
        itemUom: {
          unitOfMeasure: splitIdNameStr(data.unitOfMeasureObj).foreignId,
          unitSize: Number(data.unitSize),
        },
        glAccountNo: data.glAccountNo,
        revenueAccountNo: data.revenueAccountNo,
        cosAccountNo: data.cosAccountNo,
        itemAssemblyComponents: data.itemAssemblyComponents
          ? data.itemAssemblyComponents
              .filter((item) => item.componentItemUid)
              .map((item) => ({
                quantity: item.quantity,
                componentItemUid: item.componentItemUid,
                taxable: true, // default value
                isAssembly: item.isAssembly,
                unitOfMeasure: item.unitOfMeasure,
              }))
          : undefined,
        standardCost: Number(data.standardCost) > 0 ? Number(data.standardCost) : undefined,
        poCost: Number(data.poCost) > 0 ? Number(data.poCost) : undefined,
        price1: Number(data.price1) > 0 ? Number(data.price1) : undefined,
        supplierPartNo:
          data.supplierPartNo && data.supplierPartNo.trim().length > 0 ? data.supplierPartNo.trim() : undefined,
        classId2: data.class2 ? splitIdNameStr(data.class2).foreignId : undefined,
        serialized: data.serialized,
      } as CreateItemBody,
    });
  }

  // use algolia to find similar items based on item name field
  const {
    data: searchIndexResponse,
    error: algoliaHitsError,
    isLoading: isAlgoliaHitsLoading,
  } = usePromise(
    () =>
      searchIndex<SearchIndexItemPartial>({
        indexName: SearchIndexName.Items,
        query: itemState.itemName,
        hitsPerPage: 10,
      }),
    [itemState.itemId, itemState.itemName],
  );

  const selectedLineItemRowIdx = useRef<number>();

  const lineComponentItemActions = useMemo(
    (): LineComponentItemActions => ({
      updateLineItem: (index: number, update: AssemblyComponentWithInfo) => {
        const newLineItems = [...(itemState.itemAssemblyComponents || [])];
        newLineItems[index] = { ...newLineItems[index], ...update };
        setItemState({ ...itemState, itemAssemblyComponents: newLineItems });
      },
      addEmptyLineItem: () => {
        const newLineItems = [...(itemState.itemAssemblyComponents || []), {}];
        setItemState({ ...itemState, itemAssemblyComponents: newLineItems });
      },
      deleteLineItem: (index: number) => {
        const newLineItems = itemState.itemAssemblyComponents!.filter((_, idx) => idx !== index);
        setItemState({ ...itemState, itemAssemblyComponents: newLineItems });
      },
      duplicateLineItem: (index: number) => {
        const newLineItems = [...(itemState.itemAssemblyComponents || [])];
        // insert duplicate item after its current index
        newLineItems.splice(index + 1, 0, { ...newLineItems[index] });
        setItemState({ ...itemState, itemAssemblyComponents: newLineItems });
      },
      moveLineItemUp: (index: number) => {
        const newLineItems = [...(itemState.itemAssemblyComponents || [])];
        if (index > 0) {
          const lineItem = newLineItems[index];
          newLineItems[index] = newLineItems[index - 1];
          newLineItems[index - 1] = lineItem;
          setItemState({ ...itemState, itemAssemblyComponents: newLineItems });
        }
      },
      moveLineItemDown: (index: number) => {
        const newLineItems = [...(itemState.itemAssemblyComponents || [])];
        if (index + 1 < newLineItems.length) {
          const lineItem = newLineItems[index];
          newLineItems[index] = newLineItems[index + 1];
          newLineItems[index + 1] = lineItem;
          setItemState({ ...itemState, itemAssemblyComponents: newLineItems });
        }
      },
    }),
    [itemState, setItemState],
  );

  async function goToReviewStep() {
    try {
      await itemForm.validateFields();
      if (!isAlgoliaHitsLoading && !algoliaHitsError && searchIndexResponse?.hits?.length === 0) {
        handleSubmit(itemState as ItemFormData);
      }
      setStep(NewItemStep.ReviewStep);
    } catch {}
  }

  async function goToComponentStep() {
    try {
      await itemForm.validateFields();
      setStep(NewItemStep.ComponentsEntryStep);
    } catch {}
  }

  const itemRows: SearchIndexItemPartial[] = searchIndexResponse?.hits || [];
  const tableColumns = (noLink?: Maybe<boolean>): ColumnType<SearchIndexItemPartial>[] => [
    typedColumn({
      title: 'ID',
      dataIndex: 'item_id',
      render: (itemId: string) =>
        noLink ? (
          itemId
        ) : (
          <Link
            className={css`
              padding: 0 0 0 0;
              margin: 0 0 0 0;
            `}
            to={routes.sales.itemDetails(itemId)}
          >
            {itemId}
          </Link>
        ),
      align: 'left' as const,
      width: '140px',
    }),
    typedColumn({
      title: 'Description',
      dataIndex: 'item_desc',
      align: 'left' as const,
      width: '200px',
    }),
    typedColumn({
      title: 'Extended Description',
      dataIndex: 'extended_desc',
      align: 'left' as const,
      width: '200px',
    }),
  ];

  // validate initialized field on item entry step
  itemForm.validateFields(['itemId']);

  return (
    <Modal
      visible
      footer={
        <div>
          {currentStep === NewItemStep.ItemEntryStep
            ? [
                <Button key="cancel" onClick={() => onClose()}>
                  Cancel
                </Button>,
                assemblyItem ? (
                  <Button key="next" type="primary" onClick={goToComponentStep} disabled={isAlgoliaHitsLoading}>
                    Add components
                  </Button>
                ) : (
                  <Button key="next" type="primary" onClick={goToReviewStep} disabled={isAlgoliaHitsLoading}>
                    {nextButtonText}
                  </Button>
                ),
              ]
            : currentStep === NewItemStep.ComponentsEntryStep
            ? [
                <Button key="prev" onClick={() => setStep(NewItemStep.ItemEntryStep)}>
                  Previous
                </Button>,
                <Button key="next" type="primary" onClick={goToReviewStep} disabled={isAlgoliaHitsLoading}>
                  {nextButtonText}
                </Button>,
              ]
            : [
                <Button
                  key="prev"
                  onClick={() =>
                    assemblyItem ? setStep(NewItemStep.ComponentsEntryStep) : setStep(NewItemStep.ItemEntryStep)
                  }
                >
                  Previous
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    if (selectedExistingItem === undefined) handleSubmit(itemState as ItemFormData);
                    else onClose({ existingData: selectedExistingItem });
                  }}
                >
                  {nextButtonText}
                </Button>,
              ]}
        </div>
      }
      title={assemblyItem ? 'New Assembly Item' : 'New Item'}
      onCancel={() => onClose()}
      centered
      width={850}
    >
      {currentStep === NewItemStep.ItemEntryStep ? (
        <Container>
          <div
            className={css`
              display: flex;
              margin-bottom: 24px;
              justify-content: center;
            `}
          >
            Create a {assemblyItem ? '' : 'temporary'} non-stockable {assemblyItem ? 'assembly ' : ''}item at{' '}
            {locationCopy}. The item will be created in {activeTenant.erpType.toUpperCase()}.
          </div>
          <Form.Provider>
            <Form
              name="itemForm"
              form={itemForm}
              onFinish={handleSubmit}
              onError={console.error}
              onFinishFailed={console.error}
              onValuesChange={(changedValues) => setItemState({ ...itemState, ...changedValues })}
              {...responsiveFormLayout}
            >
              <Form.Item
                name="company"
                label="Company"
                rules={[{ required: !hasSingleCompany, message: 'Please select a company.' }]}
                hidden={hasSingleCompany || quoteOrderEntryVariant}
              >
                <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" />
              </Form.Item>

              <Form.Item
                label="Item ID"
                name="itemId"
                rules={[
                  { min: 1, message: 'Enter an item ID' },
                  { required: true, message: 'Enter a unique item ID' },
                  { max: 40, message: 'Item ID cannot be more than 40 characters' },
                  { validator: validateItemId },
                ]}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                label="Description"
                name="itemName"
                rules={[
                  { required: true, message: 'Please add item description' },
                  { max: 40, message: 'Item description cannot be more than 40 characters' },
                  itemFormConfig.itemDescription.validate ? { validator: validateItemDescription } : null,
                ].filter(truthy)}
              >
                <Input type="text" allowClear />
              </Form.Item>

              <Form.Item
                label="Extended Desc."
                name="itemDescription"
                rules={[{ max: 255, message: 'Extended description cannot be more than 255 characters' }]}
              >
                <TextArea rows={2} />
              </Form.Item>

              <Form.Item
                label="Product Group"
                name="itemGroup"
                rules={[{ required: true, message: 'Please add a product group.' }]}
              >
                <AsyncSelect
                  selectProps={itemGroupsSelectProps}
                  entityPlural="product groups"
                  onSelect={(value, option) =>
                    setItemState({
                      ...itemState,
                      glAccountNo: option.glAccountNo,
                      revenueAccountNo: option.revenueAccountNo,
                      cosAccountNo: option.cosAccountNo,
                      itemGroup: value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                label="Supplier"
                name="supplier"
                rules={[{ required: true, message: 'Please choose a supplier' }]}
              >
                <AsyncSelect selectProps={suppliersSelectProps} entityPlural="suppliers" />
              </Form.Item>

              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemCreateSupplierPartNo) && (
                <>
                  <Form.Item
                    label="Supplier Part No"
                    name="supplierPartNo"
                    rules={[
                      { required: itemFormConfig.supplierPartNo.required, message: 'Please add supplier part no' },
                      { max: 40, message: 'Supplier part no cannot be more than 40 characters' },
                      { validator: validateSupplierPartNo },
                    ]}
                  >
                    <Input type="text" allowClear />
                  </Form.Item>
                </>
              )}

              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemCreateTrackSerialNumber) && (
                <Form.Item name="serialized" label="Track Serial No?" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              )}

              <Form.Item label="Unit of Measure">
                {/* Use div to put two form items on one row */}
                <div
                  className={css`
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    gap: 10px;
                    justify-content: space-between;
                    height: 40px;
                  `}
                >
                  <Form.Item
                    name="unitOfMeasureObj"
                    rules={[{ required: false }]}
                    className={css`
                      flex-grow: 1;
                      max-width: 175px;
                    `}
                  >
                    <AsyncSelect selectProps={unitOfMeasuresSelectProps} entityPlural="UOMs" />
                  </Form.Item>
                  <Form.Item
                    label={
                      <InfoTooltip
                        title={
                          <>
                            Enter the number of items per unit of measure.
                            <br />
                            For example, if the unit of measure is a box, enter the number of items per box.
                          </>
                        }
                      >
                        Quantity per UOM
                      </InfoTooltip>
                    }
                    name="unitSize"
                    rules={[{ required: false }]}
                  >
                    <Input
                      type="number"
                      min={1}
                      max={splitIdNameStr(itemState.unitOfMeasureObj || ':').foreignId === 'EA' ? 1 : undefined}
                      onChange={(ev) => Number(ev.target.value)}
                      className={css`
                        width: 80px;
                      `}
                    />
                  </Form.Item>
                </div>
              </Form.Item>

              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemCreateTaxCode) && (
                <>
                  <Form.Item label="Tax Class" name="avalaraTaxClass" rules={[{ required: false }]}>
                    <AsyncSelect selectProps={itemSalesTaxClassesSelectProps} entityPlural="tax classes" allowClear />
                  </Form.Item>
                </>
              )}

              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemCreateClassId2) && (
                <>
                  <Form.Item label="Item Class 2" name="class2" rules={[{ required: false }]}>
                    <AsyncSelect selectProps={inventoryClass2SelectProps} entityPlural="item classes" allowClear />
                  </Form.Item>
                </>
              )}

              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemCreatePrice1) && (
                <Form.Item label="Price1" name="price1" rules={[{ required: false }]}>
                  <Input type="number" min={0} onChange={(ev) => Number(ev.target.value)} prefix="$" />
                </Form.Item>
              )}

              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemCreateStandardCost) && (
                <Form.Item label="Standard Cost" name="standardCost" rules={[{ required: false }]}>
                  <Input type="number" min={0} onChange={(ev) => Number(ev.target.value)} prefix="$" />
                </Form.Item>
              )}

              {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemCreatePOCost) && (
                <Form.Item label="PO Cost" name="poCost" rules={[{ required: false }]}>
                  <Input type="number" min={0} onChange={(ev) => Number(ev.target.value)} prefix="$" />
                </Form.Item>
              )}
            </Form>
          </Form.Provider>
        </Container>
      ) : currentStep === NewItemStep.ComponentsEntryStep ? (
        <>
          <div
            className={css`
              display: flex;
              margin-bottom: 24px;
              justify-content: left;
              font-weight: bold;
            `}
          >
            Add components for {itemState.itemId}
          </div>
          <Styled.ItemTable
            data={itemState.itemAssemblyComponents || [{}]}
            columns={getLineComponentItemColumns({
              selectedLineItemRowIdx: selectedLineItemRowIdx.current,
              lineComponentItems: itemState.itemAssemblyComponents || [{}],
              lineComponentItemActions,
              locationId,
              companyId,
              tenantId: activeTenant.id,
            })}
            pagination={false}
            size="small"
          />
          <br />
          <Button block onClick={lineComponentItemActions.addEmptyLineItem}>
            <Styled.AddButtonContent>
              <PlusOutlined /> Add Line
            </Styled.AddButtonContent>
          </Button>
        </>
      ) : !isAlgoliaHitsLoading && !algoliaHitsError && (searchIndexResponse?.hits?.length ?? 0) > 0 ? (
        <>
          <div
            className={css`
              margin-bottom: 10px;
            `}
          >
            <Alert
              icon={
                <InfoCircleOutlined
                  className={css`
                    margin-right: 8px;
                  `}
                />
              }
              showIcon
              message={
                <>
                  There may be duplicate items that match the item you are trying to create. You can either create the
                  new item or
                  {quoteOrderEntryVariant
                    ? ` add an existing item to the ${orderType}.`
                    : ` navigate to an existing item.`}
                </>
              }
              type="warning"
            />
          </div>
          <div
            className={css`
              margin-bottom: 20px;
            `}
          >
            <div className={titleCss}>New item</div>
            <div className={borderBoxCss}>
              <Table
                data={[
                  {
                    item_id: itemState.itemId,
                    item_desc: itemState.itemName,
                    extended_desc: itemState.itemDescription,
                  },
                ]}
                columns={tableColumns(true)}
                rowSelection={
                  quoteOrderEntryVariant && {
                    type: 'radio',
                    selectedRowKeys: [selectedExistingItem === undefined ? itemState.itemId : undefined],
                    onChange: () => setSelectedExistingItem(undefined),
                  }
                }
                rowKey="item_id"
              />
            </div>
          </div>
          <div className={titleCss}>Possible duplicates</div>
          <div className={borderBoxCss}>
            <Table
              data={itemRows}
              columns={tableColumns(quoteOrderEntryVariant)}
              rowSelection={
                quoteOrderEntryVariant && {
                  type: 'radio',
                  selectedRowKeys: [selectedExistingItem?.item_id],
                  onChange: (_: any, selectedRows: SearchIndexItemPartial[]) =>
                    setSelectedExistingItem(selectedRows[0]),
                }
              }
              rowKey="item_id"
              style={{ height: 300 }}
            />
          </div>
        </>
      ) : (
        `No duplicate matches found. Click ${nextButtonText} below to create the item.`
      )}
    </Modal>
  );
};
