import React from 'react';

import { useHistory } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { UserUpdatePayload } from '@recurrency/core-api-schema/dist/users/putUpdateUser';
import { Form, notification } from 'antd';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { InputFormItem, defaultFormLayout, defaultFormTailLayout, SelectFormItem } from 'components/FormItems';
import { CenteredLoader, CenteredError } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes, IdPathParams, usePathParams } from 'utils/routes';
import { getTimezoneOptions } from 'utils/timezones';

const tzOptions = getTimezoneOptions();

export const UserEditPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const {
    data: user,
    isLoading,
    error,
    reload,
  } = useCoreApi(schemas.users.getUserById, {
    pathParams: { id },
  });

  const history = useHistory();

  const [form] = Form.useForm();

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !user) {
    return <CenteredLoader />;
  }

  const onSubmit = async (data: UserUpdatePayload) => {
    try {
      await coreApiFetch(schemas.users.updateUser, {
        pathParams: { id },
        bodyParams: data,
      });
      notification.success({ message: 'User updated.' });
      reload(); // Despite redirecting, this reload is important for if the user returns to this page
      history.push(routes.internal.userDetails(user.id));
    } catch (err) {
      captureAndShowError(err, `Error while updating user`);
    }
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === 'contactForm') {
          const { taskForm } = forms;

          taskForm.setFieldsValue({
            contact: `${values.firstName} ${values.lastName}`,
          });
        }
      }}
    >
      <Container>
        <PageHeader title="Edit User" onBack={() => history.goBack()} />
        <div
          style={{
            marginBottom: 24,
            paddingLeft: 24,
            marginLeft: '12rem',
            marginRight: '16rem',
          }}
        >
          <Form {...defaultFormLayout} form={form} onFinish={onSubmit} onError={console.error} initialValues={user}>
            <InputFormItem
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please add a first name.' }]}
            />

            <InputFormItem
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please add a last name.' }]}
            />

            <InputFormItem label="Email" name="email" rules={[{ required: true, message: 'Please add an email.' }]} />

            <SelectFormItem
              isLoading={false}
              initialValue=""
              label="Timezone"
              name="timezone"
              options={tzOptions}
              setFieldsValue={form.setFieldsValue}
            />

            <Form.Item {...defaultFormTailLayout}>
              <Button id="updateUserButton" type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    </Form.Provider>
  );
};
