import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { CalendarOutlined, EnvironmentOutlined, LeftOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Form, Space, Steps } from 'antd';
import { theme } from 'theme';

// TODO: move shared styles and components to a recipe folder
import * as Styled from 'pages/orders/quotes/QuoteEditFlowP21/QuoteEditFlowP21.style';

import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { AsyncButton } from 'components/Button/AsyncButton';
import { FixedFooter } from 'components/FixedFooter';
import { NotificationLink } from 'components/Links';
import { DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { openPdfInNewTab } from 'components/recipes/OpenPdfButton';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { formatDate, getErpName } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';
import { scrollToTop } from 'utils/scroll';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { PdfDocumentType, track, TrackEvent } from 'utils/track';

import { PurchaseOrderEditHashState, PurchaseOrderEditStep } from 'types/hash-state';

import { PurchaseOrderEditHeaderStep } from './PurchaseOrderEditHeaderStep';
import { PurchaseOrderEditLineItemsStep } from './PurchaseOrderEditLineItemsStep';
import { PurchaseOrderEditHeaderFormState } from './types';
import { createPurchaseOrderBodyFromHashState, getLineItemTrackProps, validateLineItems } from './utils';

export function PurchaseOrderEditFlow() {
  const history = useHistory();
  const [docState, updateDocState] = useHashState<PurchaseOrderEditHashState>();

  const { step: currentStep = PurchaseOrderEditStep.Header } = docState;
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [headerForm] = Form.useForm<PurchaseOrderEditHeaderFormState>();
  const { activeTenant, activeUser } = useGlobalApp();
  const purchaseOrderFormConfig = getTenantConfiguration(activeTenant).purchaseOrderEntryFlow.formConfig;

  const handleDocStateChange = (newDocState: Partial<PurchaseOrderEditHashState>) => {
    updateDocState(newDocState);
  };

  const handlePOSubmit = async () => {
    // ensure no validation errors
    const canSubmitPO = await validateStep(PurchaseOrderEditStep.LineItems);
    if (!canSubmitPO) {
      return;
    }

    const submitNotification = createSubmissionNotification({
      entityName: 'Purchase Order',
      expectedWaitSeconds: 60,
      erpType: activeTenant.erpType,
    });

    try {
      track(TrackEvent.Purchasing_EditPurchaseOrder_Submit, getLineItemTrackProps(docState.lineItems || []));

      const bodyParams = createPurchaseOrderBodyFromHashState(docState, purchaseOrderFormConfig);
      const response = await coreApiFetch(schemas.purchaseOrders.createPurchaseOrder, { bodyParams });
      const createdPurchaseOrderId = response.data.purchaseOrderId!;
      submitNotification.success({
        description: (notificationKey, entityName) => (
          <div>
            <NotificationLink
              notificationKey={notificationKey}
              to={routes.purchasing.purchaseOrderDetails(createdPurchaseOrderId)}
            >
              View {entityName} #{createdPurchaseOrderId}
            </NotificationLink>
            <ActionButton
              label="Open PDF"
              onClick={() =>
                openPdfInNewTab({ foreignId: createdPurchaseOrderId, type: PdfDocumentType.PurchaseOrder })
              }
            />
          </div>
        ),
      });
      history.push(routes.purchasing.purchaseOrderDetails(createdPurchaseOrderId));
    } catch (err) {
      submitNotification.error(err, {
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.purchasing.purchaseOrderNew(docState)}>
            Return to {entityName}
          </NotificationLink>
        ),
      });
    }
  };

  const validateStep = useCallback(
    async (step: PurchaseOrderEditStep): Promise<boolean> => {
      if (step >= PurchaseOrderEditStep.Header) {
        try {
          // antd Form makes you jumps through async try/catch hoop just to know whether a form is valid or not
          await headerForm.validateFields();
        } catch (err) {
          setValidationErrors(['Please fill required header fields.']);
          return false;
        }
      }
      if (step === PurchaseOrderEditStep.LineItems) {
        const errors = validateLineItems(docState.lineItems || []);
        setValidationErrors(errors);
        return errors.length === 0;
      }
      setValidationErrors([]);
      return true;
    },
    [docState.lineItems, headerForm],
  );

  const goToStep = async (step: PurchaseOrderEditStep) => {
    // don't go to next step, until every previous state is valid
    if (step >= currentStep && !(await validateStep(currentStep))) {
      return;
    }
    updateDocState({ step });
    scrollToTop();
  };

  return (
    <Styled.Container>
      <Form.Provider>
        <Styled.Header>
          <Space>
            <Styled.VerticalCenter>
              <Typography type="large" style={{ fontWeight: 'bold' }}>
                New Purchase Order
              </Typography>
            </Styled.VerticalCenter>
          </Space>
        </Styled.Header>
        <Steps current={currentStep} style={{ marginTop: 24 }} onChange={(newStep) => goToStep(newStep)}>
          {['Header', 'Line Items'].map((step) => (
            <Steps.Step key={step} title={step} />
          ))}
        </Steps>
        {currentStep === PurchaseOrderEditStep.Header && (
          <Styled.Content>
            <PurchaseOrderEditHeaderStep
              form={headerForm}
              docState={docState}
              onDocStateChange={handleDocStateChange}
            />
          </Styled.Content>
        )}
        {currentStep === PurchaseOrderEditStep.LineItems && (
          <>
            <PurchaseOrderHeaderDetails docState={docState} />
            <PurchaseOrderEditLineItemsStep docState={docState} onDocStateChange={handleDocStateChange} />
          </>
        )}
        {validationErrors.length ? (
          <div
            className={css`
              display: flex;
              justify-content: center;
              color: ${theme.colors.danger[500]};
            `}
          >
            {validationErrors.map((error) => (
              <>
                {error}
                <br />
              </>
            ))}
          </div>
        ) : null}
        <FixedFooter>
          <Space>
            <Button onClick={() => goToStep(currentStep - 1)} disabled={currentStep === 0}>
              <LeftOutlined />
              Previous
            </Button>
            {currentStep === PurchaseOrderEditStep.LineItems ? (
              <Tooltip
                title={`This PO will be sent to ${getErpName(
                  activeTenant.erpType,
                )} and will no longer be editable in Recurrency.`}
              >
                <AsyncButton
                  type="primary"
                  onClick={handlePOSubmit}
                  disabled={
                    !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.PurchasingCreatePurchaseOrder)
                  }
                >
                  Send to {getErpName(activeTenant.erpType)}
                </AsyncButton>
              </Tooltip>
            ) : (
              <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
                Next
                <RightOutlined />
              </Button>
            )}
          </Space>
        </FixedFooter>
      </Form.Provider>
    </Styled.Container>
  );
}

function PurchaseOrderHeaderDetails({ docState }: { docState: PurchaseOrderEditHashState }) {
  return (
    <DetailPageSections
      sections={[
        {
          title: 'Details',
          rows: [
            [
              docState.vendor
                ? {
                    label: 'Vendor',
                    value: docState.vendor?.name,
                    id: docState.vendor?.foreignId,
                  }
                : null,
              {
                label: 'Supplier',
                value: docState.supplier?.name,
                id: docState.supplier?.foreignId,
              },
              {
                icon: <EnvironmentOutlined />,
                label: 'Location',
                value: docState.location?.name,
                id: docState.location?.foreignId,
              },
              docState.buyer
                ? {
                    icon: <UserOutlined />,
                    label: 'Buyer',
                    value: docState.buyer?.name,
                    id: docState.buyer?.foreignId,
                  }
                : null,
              docState.carrier
                ? {
                    label: 'Carrier',
                    value: docState.carrier?.name,
                    id: docState.carrier?.foreignId,
                  }
                : null,
              {
                icon: <CalendarOutlined />,
                label: 'Due Date',
                value: formatDate(docState.dueDate),
              },
            ].filter(truthy),
          ],
        },
      ]}
    />
  );
}
