import React, { useEffect, useState } from 'react';

import { Input, InputProps } from './Input';

export interface ParsedInputProps<ParsedValueT extends string | number> extends InputProps {
  valueParser: (value: string | number) => ParsedValueT;
  onValueChange: (value: ParsedValueT) => void;
  /** This option allows onValueChange to be called immediately when the user backspaces to empty string.
   *  Default behavior is to not immediately call onValueChange until user clicks elsewhere (onBlur) for empty inputs. */
  triggerChangeOnBlankValue?: boolean;
}

export function ParsedInput<ParsedValueT extends string | number>({
  valueParser,
  onValueChange,
  triggerChangeOnBlankValue,
  ...props
}: ParsedInputProps<ParsedValueT>) {
  // use internal state so we let the user type freely without overriding with parsed value
  const [editValue, setEditValue] = useState(props.value);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      setEditValue(props.value);
    }
  }, [props.value, isFocused]);

  return (
    <Input
      {...props}
      value={isFocused ? editValue : props.value}
      onChange={(ev) => {
        const { value } = ev.target;
        setEditValue(value);
        // allow user to backspace to empty string and enter new value
        if (triggerChangeOnBlankValue || value !== '') {
          onValueChange(valueParser(value));
        }
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      // when user changes focus, publish parsed value
      onBlur={() => {
        setIsFocused(false);
        onValueChange(valueParser(editValue as string));
      }}
    />
  );
}
