import { schemas } from '@recurrency/core-api-schema';
import { GetSalesInvoiceLinesReportGroupBy } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';

import { Filter } from 'components/recipes/salesReport/salesReportUtils';

import { useCoreApi } from 'hooks/useApi';

export function useSalesHistoryReport({
  groupBy,
  filter,
  numYearsOfSparkline = 5,
  ignoreRoleSalesRepIds = false,
}: {
  groupBy: GetSalesInvoiceLinesReportGroupBy;
  filter: Filter;
  numYearsOfSparkline?: number;
  ignoreRoleSalesRepIds?: boolean;
}) {
  const { isLoading, data, error } = useCoreApi(
    schemas.reports.getSalesInvoiceLinesReport,
    groupBy
      ? {
          queryParams: {
            groupBy,
            filter,
            numPrevYearsForSalesMonthly: numYearsOfSparkline,
            yearToDate: true,
            ignoreRoleSalesRepIds,
          },
        }
      : null,
  );
  return { isLoading, data, error };
}
