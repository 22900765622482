import React from 'react';

import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Row, Col, Form } from 'antd';

import { AvailabilityTable } from 'pages/items/ItemDetailsPage/tabs/AvailabilityTable';

import { Container } from 'components/Container';
import { AlgoliaSelectFormItem } from 'components/FormItems';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { NewQuoteButton } from 'components/recipes/NewQuoteButton';
import { SalesInvoiceLinesTable } from 'components/recipes/SalesInvoiceLinesTable';
import { SalesQuoteLinesTable } from 'components/recipes/SalesQuoteLinesTable';

import { truthy } from 'utils/boolean';
import { splitIfIdNameStr } from 'utils/formatting';
import { useHashState } from 'utils/routes';

import { PricingHashState } from 'types/hash-state';
import { SearchIndexCustomer, SearchIndexItem } from 'types/search-collections';

interface ItemOption {
  /** {foreignId} * */
  key: string | undefined;
  /** {foreignId}: {name} * */
  value: string | undefined;
  itemInvMastUid: string | undefined;
}

interface CustomerOption {
  /** {foreignId} * */
  key: string | undefined;
  /** {foreignId}: {name} * */
  value: string | undefined;
  /** {foreignId}: {name} * */
  company: string | undefined;
}

export const PricingPage = () => {
  const [hashState, updateHashState] = useHashState<PricingHashState>();
  const [form] = Form.useForm();
  const { customer: customerIdNameStr, company: companyIdNameStr, item: itemIdNameStr, itemInvMastUid } = hashState;
  const customer = splitIfIdNameStr(customerIdNameStr);
  const item = splitIfIdNameStr(itemIdNameStr);

  return (
    <Container>
      <Form layout="vertical" name="pricingForm" form={form} onFinishFailed={console.error}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <PageHeader title="Pricing & Availability" />
          <NewQuoteButton
            props={{ disabled: !(itemIdNameStr && customerIdNameStr) }}
            hashState={{
              customer: customerIdNameStr,
              company: companyIdNameStr,
              items: [splitIfIdNameStr(itemIdNameStr)].filter(truthy),
            }}
          />
        </div>
        <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }}>
          <Col xs={24} sm={24} md={12} lg={6}>
            <AlgoliaSelectFormItem<SearchIndexCustomer>
              label="Customer"
              name="customer"
              initialValue={customerIdNameStr}
              indexName={SearchIndexName.Customers}
              allowClear
              rules={[{ required: true, message: 'Please add a customer.' }]}
              mapHitFn={(hit) => ({
                key: hit.customer_id,
                value: `${hit.customer_id}: ${hit.customer_name}`,
                company: hit.company,
              })}
              setFieldsValue={form.setFieldsValue}
              postValueChange={(value, option: CustomerOption) => {
                updateHashState({ customer: option.value, company: option.company });
              }}
              salesRepRefined
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            <AlgoliaSelectFormItem<SearchIndexItem>
              label="Item"
              name="item"
              initialValue={itemIdNameStr}
              indexName={SearchIndexName.Items}
              allowClear
              mapHitFn={(hit) => ({
                key: hit.item_id,
                value: `${hit.item_id}: ${hit.item_desc}`,
                itemInvMastUid: hit.inv_mast_uid,
              })}
              rules={[{ required: true, message: 'Please add an item.' }]}
              setFieldsValue={form.setFieldsValue}
              postValueChange={(value, option: ItemOption) => {
                updateHashState({ item: option.value, itemInvMastUid: option.itemInvMastUid });
              }}
            />
          </Col>
        </Row>
        {customer && item && customer.foreignId && itemInvMastUid && (
          <div style={{ marginTop: '2rem' }}>
            <NavTabs
              tabs={[
                {
                  header: 'Availability by Location',
                  content: <AvailabilityTable itemUid={itemInvMastUid} />,
                },
                {
                  header: 'Sales of Item',
                  content: <SalesInvoiceLinesTable itemId={item.foreignId} key={`${item.foreignId}`} />,
                },
                {
                  header: 'Sales of Item to Customer',
                  content: (
                    <SalesInvoiceLinesTable
                      itemId={item.foreignId}
                      customerId={customer.foreignId}
                      key={`${item.foreignId}|${customer.foreignId}`}
                    />
                  ),
                },
                {
                  header: 'Quotes of Item to Customer',
                  content: (
                    <SalesQuoteLinesTable
                      itemId={item.foreignId}
                      customerId={customer.foreignId}
                      key={`${item.foreignId}|${customer.foreignId}`}
                    />
                  ),
                },
              ]}
            />
          </div>
        )}
      </Form>
    </Container>
  );
};
