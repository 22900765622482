import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Checkbox, Form, FormInstance } from 'antd';
import moment from 'moment';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  useCarriersSelectProps,
  useCompaniesSelectProps,
  useVendorsSelectProps,
  useLocationsSelectProps,
  useVendorSuppliersSelectProps,
  usePurchasingBuyersSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { responsiveFormLayout } from 'components/FormItems';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { optArrFromVal } from 'utils/array';
import { joinIfIdNameObj, splitIfIdNameStr } from 'utils/formatting';
import { objKeys } from 'utils/object';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { isTenantErpTypeSAPB1 } from 'utils/tenants';
import { validateDateInFuture } from 'utils/validation';

import { PurchaseOrderEditHashState } from 'types/hash-state';

import { PurchaseOrderEditHeaderFormState } from './types';

export function PurchaseOrderEditHeaderStep({
  form,
  docState,
  onDocStateChange,
}: {
  form: FormInstance<PurchaseOrderEditHeaderFormState>;
  docState: PurchaseOrderEditHashState;
  onDocStateChange: (update: Partial<PurchaseOrderEditHashState>) => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const isTenantSAPB1 = isTenantErpTypeSAPB1(activeTenant.erpType);

  useEffect(() => {
    // use docState as the source of state
    form.setFieldsValue({
      company: joinIfIdNameObj(docState.company),
      vendor: joinIfIdNameObj(docState.vendor),
      supplier: joinIfIdNameObj(docState.supplier),
      location: joinIfIdNameObj(docState.location),
      carrier: joinIfIdNameObj(docState.carrier),
      buyer: joinIfIdNameObj(docState.buyer),
      dueDate: docState.dueDate,
      approved: docState.approved,
    });
  }, [form, docState]);

  // antd dropdowns take options values as string, but we want to store {foreignId, name} objects in state,
  // hence need to keep track of which fields are dropdowns
  const selectFieldKeys = ['company', 'vendor', 'supplier', 'location', 'carrier', 'buyer'];
  const handleFormValuesChanged = (changedValues: PurchaseOrderEditHeaderFormState) => {
    const update: Obj = {};
    for (const formField of objKeys(changedValues)) {
      const value = changedValues[formField];
      update[formField] = selectFieldKeys.includes(formField) ? splitIfIdNameStr(value as string) : value;
    }

    // clear supplier when vendor is changed
    if (changedValues.vendor) {
      update.supplier = undefined;
    }

    onDocStateChange(update);
  };

  const companiesSelectProps = useCompaniesSelectProps();
  const vendorSelectProps = useVendorsSelectProps({ companyId: docState.company?.foreignId });
  const vendorSuppliersSelectProps = useVendorSuppliersSelectProps({
    vendorId: docState.vendor?.foreignId,
    companyId: docState.company?.foreignId,
  });

  const supplierSelectProps = isTenantSAPB1 ? vendorSelectProps : vendorSuppliersSelectProps;
  const locationsSelectProps = useLocationsSelectProps({ companyIds: optArrFromVal(docState.company?.foreignId) });
  const carriersSelectProps = useCarriersSelectProps();
  const buyersSelectProps = usePurchasingBuyersSelectProps();

  // defaults logic
  const smartFieldDefaults = {
    company: {
      default: undefined,
      selectProps: companiesSelectProps,
    },
    supplier: {
      default: undefined,
      selectProps: supplierSelectProps,
    },
    carrier: {
      default: undefined,
      selectProps: carriersSelectProps,
    },
  };

  const defaultsUpdatedDocState: Partial<PurchaseOrderEditHashState> = {};
  // if only item in smart default field, set it
  for (const field of objKeys(smartFieldDefaults)) {
    const smartDefault = smartFieldDefaults[field];
    if (!docState[field]) {
      const defaultValue =
        smartDefault.selectProps.options.length === 1 &&
        !smartDefault.selectProps.isLoading &&
        !smartDefault.selectProps.searchQuery &&
        smartDefault.selectProps.options[0].value;
      if (defaultValue) {
        defaultsUpdatedDocState[field] = splitIfIdNameStr(defaultValue);
      }
    }
  }
  if (docState.dueDate === undefined) {
    defaultsUpdatedDocState.dueDate = moment().add(7, 'd').toISOString();
  }
  if (docState.approved === undefined && !isTenantSAPB1) {
    defaultsUpdatedDocState.approved = true;
  }
  if (
    docState.buyer === undefined &&
    !buyersSelectProps.isLoading &&
    !buyersSelectProps.searchQuery &&
    buyersSelectProps.options.length > 1
  ) {
    const defaultBuyerOption = buyersSelectProps.options.find((buyer) => {
      const buyerVal = buyer.value.toLowerCase();
      const buyerEmail = buyer.data?.email?.toLowerCase();
      return (
        buyerEmail?.includes((activeUser.email || '').toLowerCase()) ||
        buyerVal.includes((activeUser.fullName || '').toLowerCase())
      );
    });
    if (defaultBuyerOption) {
      defaultsUpdatedDocState.buyer = splitIfIdNameStr(defaultBuyerOption.value);
    }
  }
  if (Object.keys(defaultsUpdatedDocState).length > 0) {
    requestAnimationFrame(() => onDocStateChange(defaultsUpdatedDocState));
  }

  return (
    <div
      className={css`
        width: 100%;

        .ant-form-item {
          margin-bottom: 4px;
        }
      `}
    >
      <Form
        form={form}
        onFinishFailed={console.error}
        onValuesChange={(changedValues) => handleFormValuesChanged(changedValues)}
        {...responsiveFormLayout}
      >
        {!isTenantSAPB1 && (
          <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true, message: 'Please select a company.' }]}
            hidden={companiesSelectProps.options.length <= 1}
          >
            <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" />
          </Form.Item>
        )}
        {!isTenantSAPB1 && (
          <Form.Item name="vendor" label="Vendor" rules={[{ required: true, message: 'Please select a vendor.' }]}>
            <AsyncSelect selectProps={vendorSelectProps} disabled={!docState.company} entityPlural="vendors" />
          </Form.Item>
        )}
        <Form.Item name="supplier" label="Supplier" rules={[{ required: true, message: 'Please select a supplier.' }]}>
          <AsyncSelect
            selectProps={supplierSelectProps}
            entityPlural="suppliers"
            allowClear
            disabled={!docState.vendor && !isTenantSAPB1}
            placeholder={!docState.vendor && !isTenantSAPB1 ? 'Please select a vendor first' : ''}
          />
        </Form.Item>
        <br />
        <Form.Item
          name="dueDate"
          label="Due Date"
          rules={[{ validator: validateDateInFuture, message: 'Due date must be after today' }]}
        >
          <IsoDatePicker
            quickButtons={[
              { name: 'in a week', value: moment().startOf('day').add(7, 'd') },
              { name: 'in a month', value: moment().startOf('day').add(1, 'M') },
            ]}
          />
        </Form.Item>
        <br />
        <Form.Item name="location" label="Location" rules={[{ required: true, message: 'Please select a location.' }]}>
          <AsyncSelect
            selectProps={locationsSelectProps}
            disabled={!docState.company && !isTenantSAPB1}
            entityPlural="locations"
          />
        </Form.Item>
        <Form.Item name="buyer" label="Buyer" rules={[{ required: !isTenantSAPB1, message: 'Please select a buyer.' }]}>
          <AsyncSelect selectProps={buyersSelectProps} entityPlural="buyers" allowClear />
        </Form.Item>
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeCarrier) && (
          <Form.Item name="carrier" label="Carrier">
            <AsyncSelect selectProps={carriersSelectProps} entityPlural="carriers" allowClear />
          </Form.Item>
        )}
        {!isTenantSAPB1 && (
          <Form.Item name="approved" label="Approved" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        )}
      </Form>
    </div>
  );
}
