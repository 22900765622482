import React from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';

import { Button } from 'components/Button';
import { SmallLoader } from 'components/Loaders';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { openZendeskModal } from 'utils/zendesk';

import { DownloadNgrokAgentModal } from './DownloadNgrokAgentModal';

export function DownloadNgrokAgentButton() {
  const { activeTenant } = useGlobalApp();
  const {
    data: agents,
    isLoading,
    error,
  } = useCoreApi(schemas.integrations.getAllAgentsByTenant, {
    pathParams: { tenantId: activeTenant.id },
  });
  if (isLoading) {
    return <SmallLoader />;
  }
  if (error || !agents || agents.items.length === 0) {
    return (
      <>
        Error preparing your agent automatically, please contact your Solutions Engineer.
        <Button type="link" size="small" onClick={openZendeskModal}>
          Don't have a Solutions Engineer? Reach out to us here.
        </Button>
      </>
    );
  }
  return (
    <Button
      type="primary"
      onClick={() =>
        showAsyncModal(DownloadNgrokAgentModal, {
          agent: agents.items[0],
          tenant: activeTenant,
        })
      }
    >
      <DownloadOutlined /> Configure Agent
    </Button>
  );
}
