// /**
//  * This module encapsulates data fetching needs for various table entities,
//  * with an uniform UseAsyncTableResponse interface.
//  */

import { useCallback, useState } from 'react';

import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { EndpointSchema } from '@recurrency/core-api-schema/dist/utils/apiSchema';
import { AxiosError } from 'axios';

import { DEFAULT_PAGE_SIZE } from 'components/Table';

import { useCoreApi, useLegacyApi } from 'hooks/useApi';

import { useSearchIndex } from 'utils/search/search';
import { SearchRequest } from 'utils/search/types';

import { ListResponse } from 'types/legacy-api';
import { ObjectWithId } from 'types/search-collections';

/// interfaces ///

export interface UseAsyncTableProps<ItemT> {
  isLoading: boolean;
  error: AxiosError | Error | undefined;
  items: ItemT[];
  totalCount: number;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
  reload: () => void;
  isReloading: boolean;
  sortBy?: string;
  setSortBy?: (sortBy: string) => void;
  sortDir?: 'asc' | 'desc';
  setSortDir?: (sortDir: 'asc' | 'desc') => void;
}

export interface UseCursorPaginationTableProps<ItemT> {
  isLoading: boolean;
  error: AxiosError | Error | undefined;
  items: ItemT[];
  pageSize: number;
  hasNextPage: boolean;
  goNextPage: () => void;
  hasPrevPage: boolean;
  goPrevPage: () => void;
  reload?: () => void;
  isReloading?: boolean;
  reset: () => void;
}

/// util functions ///
export function useLegacyApiTableProps<ItemT>({
  apiPath,
  queryParams,
  mapItemFn,
}: {
  apiPath: string;
  queryParams?: Obj<string | number | boolean | string[][] | undefined>;
  mapItemFn?: (item: Any) => ItemT;
}): UseAsyncTableProps<ItemT> {
  const [page, setPage] = useState(1);
  const pageSize = DEFAULT_PAGE_SIZE;

  const { data, isLoading, isReloading, error, reload } = useLegacyApi<ListResponse<ItemT>>(apiPath, {
    offset: ((page ?? 1) - 1) * pageSize,
    limit: pageSize,
    ...queryParams,
  });

  const { items, totalCount } = data || { items: [], totalCount: 0 };

  return {
    isLoading,
    isReloading,
    error,
    items: mapItemFn ? items.map(mapItemFn) : items,
    totalCount,
    page,
    pageSize,
    setPage,
    reload,
  };
}

export function useCoreApiTableProps<EndpointSchemaT extends EndpointSchema>({
  schema,
  pathParams,
  queryParams,
  mapItemFn,
  pageSize,
  initialState,
}: {
  schema: EndpointSchemaT;
  pathParams?: Omit<InstanceType<EndpointSchemaT['pathParams']>, 'tenantId' | 'erpType'>;
  queryParams?: InstanceType<EndpointSchemaT['queryParams']>;
  mapItemFn?: (item: Any) => Any;
  pageSize?: number;
  initialState?: {
    page?: number;
    sortBy?: string;
    sortDir?: 'asc' | 'desc';
  };
}): UseAsyncTableProps<InstanceType<EndpointSchemaT['responseBody']>['items'][number]> {
  const [page, setPage] = useState(initialState?.page ?? 1);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | undefined>(initialState?.sortDir);
  const [sortBy, setSortBy] = useState<string | undefined>(initialState?.sortBy);
  pageSize = pageSize ?? DEFAULT_PAGE_SIZE;

  const { data, isLoading, error, reload, isReloading } = useCoreApi(schema, {
    pathParams,
    queryParams: {
      offset: ((page ?? 1) - 1) * pageSize,
      limit: pageSize,
      sortBy,
      sortDir,
      ...queryParams,
    } as Any,
  });

  const { items, totalCount } = (data as Any) || { items: [], totalCount: 0 };

  return {
    isLoading,
    error,
    items: mapItemFn ? items.map(mapItemFn) : items,
    totalCount,
    pageSize,
    page,
    setPage,
    sortBy,
    setSortBy,
    sortDir,
    setSortDir,
    reload,
    isReloading,
  };
}

export function useCursorPaginationTableProps<EndpointSchemaT extends EndpointSchema>({
  schema,
  pathParams,
  queryParams,
  pageSize,
}: {
  schema: EndpointSchemaT;
  pathParams?: Omit<InstanceType<EndpointSchemaT['pathParams']>, 'tenantId' | 'erpType'>;
  queryParams?: InstanceType<EndpointSchemaT['queryParams']>;
  pageSize?: number;
}): UseCursorPaginationTableProps<InstanceType<EndpointSchemaT['responseBody']>['items'][number]> {
  const [startingAfter, setStartingAfter] = useState<(string | undefined)[]>([undefined]);
  const [startingAfterIndex, setStartingAfterIndex] = useState(0);
  pageSize = pageSize ?? DEFAULT_PAGE_SIZE;

  const getCurrentStartingAfter = useCallback(() => {
    if (startingAfter.length === 0) {
      return undefined;
    }
    return startingAfter[startingAfterIndex];
  }, [startingAfter, startingAfterIndex]);

  const reset = () => {
    setStartingAfterIndex(0);
  };

  const { data, isLoading, error, reload, isReloading } = useCoreApi(schema, {
    pathParams,
    queryParams: {
      limit: pageSize,
      startingAfter: getCurrentStartingAfter(),
      ...queryParams,
    } as Any,
  });

  // Add new startingAfter
  if (!isLoading) {
    const newStartingAfter = data && data.hasMore ? data.startingAfter : undefined;
    if (newStartingAfter && startingAfter.indexOf(newStartingAfter) === -1) {
      setStartingAfter([...startingAfter, newStartingAfter]);
    }
  }
  const { items, hasMore } = (data as Any) || { items: [], hasMore: false };

  return {
    isLoading,
    error,
    items,
    pageSize,
    hasNextPage: hasMore,
    goNextPage: () => setStartingAfterIndex(startingAfterIndex + 1),
    hasPrevPage: startingAfterIndex > 0,
    goPrevPage: () => setStartingAfterIndex(startingAfterIndex - 1),
    isReloading,
    reload,
    reset,
  };
}

export function useSearchIndexTableProps<ItemT extends ObjectWithId>({
  indexName,
  mapItemFn,
  options,
}: {
  indexName: SearchIndexName;
  mapItemFn?: (item: Any) => ItemT;
  options?: Omit<SearchRequest, 'indexName'>;
}): UseAsyncTableProps<ItemT> {
  const [page, setPage] = useState(1);
  const pageSize = DEFAULT_PAGE_SIZE;

  const { data, isLoading, isReloading, error, reload } = useSearchIndex<ItemT>({
    indexName,
    page: page - 1, // algolia is 0 indexed, AsyncTable pagination is 1 indexed
    hitsPerPage: pageSize,
    ...options,
  });

  return {
    isLoading,
    isReloading,
    error,
    items: mapItemFn ? (data?.hits ?? [])?.map(mapItemFn) : data?.hits ?? [],
    totalCount: data?.nbHits ?? 0,
    page,
    pageSize,
    setPage,
    reload,
  };
}
