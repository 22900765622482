import React from 'react';

import { Link } from 'react-router-dom';

import { DownOutlined } from '@ant-design/icons';
import { QuoteStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SalesQuoteDraft } from '@recurrency/core-api-schema/dist/salesQuoteDrafts/common';
import { Menu } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { QuoteWithTotalInfo } from 'pages/orders/quotes/types';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { EmailDraftQuoteOrderPDFMenuItem } from 'components/recipes/EmailQuotePDFMenuItem';
import { openPdfInNewTab } from 'components/recipes/OpenPdfButton';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';

import { browserHistory } from 'utils/browserHistory';
import { routes } from 'utils/routes';
import {
  sortableStringColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortableIdColumn,
  combinedIdStringColumn,
} from 'utils/tables';
import { PdfDocumentType } from 'utils/track';

export const getDraftQuoteListColumns = (
  deleteQuote: (id: string) => void,
  onCopyAs: (id: string, description: string) => void,
  onRename: (id: string, name: string) => void,
) => {
  const columns: ColumnType<QuoteWithTotalInfo>[] = [
    sortableIdColumn({
      title: 'Customer ID',
      dataIndex: ['metadata', 'customer', 'foreignId'],
      render: (foreignId: string) =>
        foreignId ? <Link to={routes.sales.customerDetails(foreignId)}>{foreignId}</Link> : '',
    }),
    sortableStringColumn({
      title: 'Customer',
      dataIndex: ['metadata', 'customer', 'name'],
    }),
    combinedIdStringColumn({ title: 'Ship To', dataIndex: ['metadata', 'shipTo'] }),
    sortableStringColumn({
      title: 'Draft Name',
      render: (record: SalesQuoteDraft) => <Link to={routes.orders.quoteEdit(record.id)}>{record.description}</Link>,
    }),
    {
      title: 'Status',
      render: (record: SalesQuoteDraft) => (
        <StatusBadge
          status={
            record.status === QuoteStatus.Submitted
              ? BadgeStatus.Submitted
              : record.validUntilDate && Date.now() > Date.parse(String(record.validUntilDate))
              ? BadgeStatus.Expired
              : BadgeStatus.Draft
          }
        />
      ),
    },
    combinedIdStringColumn({ title: 'Contact', dataIndex: ['metadata', 'contact'] }),
    sortableDateColumn({
      title: 'Created',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
    }),
    sortableDateColumn({
      title: 'Modified',
      dataIndex: 'updatedAt',
    }),
    sortableDateColumn({
      title: 'Expiration',
      dataIndex: 'validUntilDate',
    }),
    sortableDollarWithCentsColumn({ title: 'Total Amount', dataIndex: 'totalPrice' }),
    sortableNumberColumn({
      title: 'Line Count',
      dataIndex: ['metadata', 'items'],
      render: (items: SalesQuoteDraft['metadata']['items']) => items?.length ?? 0,
    }),
    {
      title: 'Actions',
      dataIndex: '',
      render: (record: QuoteWithTotalInfo) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => openPdfInNewTab({ foreignId: record.id, type: PdfDocumentType.DraftQuote })}>
                View PDF
              </Menu.Item>
              <EmailDraftQuoteOrderPDFMenuItem selectedQuoteId={record.id} />
              {record.status === QuoteStatus.Draft && (
                <Menu.Item onClick={() => browserHistory.push(routes.orders.quoteEdit(record.id))}>Edit</Menu.Item>
              )}
              <Menu.Item onClick={() => onCopyAs(record.id, record.description || '')}>
                Save As New Draft Quote
              </Menu.Item>
              <Menu.Item onClick={() => onRename(record.id, record.description || '')}>Rename</Menu.Item>
              <Menu.Item onClick={() => deleteQuote(record.id)} danger>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <Button>
            <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];
  return columns;
};
