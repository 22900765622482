import React from 'react';

import { useHistory } from 'react-router';

import { PlusOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Grid } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { Button } from 'components/Button';
import { NotificationLink } from 'components/Links';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { searchIndexItemByIdCache } from 'utils/search/search';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { NewItemModal } from './NewItemModal';

export interface NewItemButtonProps {
  size?: SizeType;
  type?: ButtonType;
  disabled?: boolean;
  collapsible?: boolean;
  assemblyItem?: boolean;
}

export function NewItemButton({ size, type, disabled, collapsible, assemblyItem }: NewItemButtonProps) {
  const history = useHistory();
  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;
  const { activeTenant, activeUser } = useGlobalApp();
  const createItemEnabled = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateItem);
  const createAssemblyItemEnabled =
    createItemEnabled && shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateAssemblyItem);

  return (
    <Button
      size={size}
      type={type || 'default'}
      onClick={async () => {
        track(TrackEvent.Nav_CtaClick, {
          ctaName: CtaName.NewItem,
          ctaType: CtaType.Button,
        });
        const modalData = await showAsyncModal(NewItemModal, { assemblyItem });
        if (modalData?.newData) {
          const itemData = modalData.newData;
          const submitNotification = createSubmissionNotification({
            entityName: 'Item',
            expectedWaitSeconds: 30,
            erpType: activeTenant.erpType,
          });
          try {
            const response = await coreApiFetch(schemas.items.createItem, { bodyParams: itemData });
            const createdItemId = response.data.itemId;
            track(TrackEvent.Items_CreateItem_Submit, {
              itemId: response.data.itemId,
              itemUid: response.data.itemUid,
            });

            // remove the created item ID from the algolia cache because new algolia results are required
            searchIndexItemByIdCache.delete(createdItemId);
            submitNotification.success({
              description: (notificationKey, entityName) => (
                <NotificationLink notificationKey={notificationKey} to={routes.sales.itemDetails(createdItemId)}>
                  View {entityName} #{createdItemId}
                </NotificationLink>
              ),
            });
          } catch (err) {
            submitNotification.error(err);
          }
        } else if (modalData?.existingData) {
          const itemData = modalData.existingData;
          history.push(routes.sales.itemDetails(itemData.item_id));
        }
      }}
      icon={<PlusOutlined />}
      disabled={disabled || (!assemblyItem && !createItemEnabled) || (assemblyItem && !createAssemblyItemEnabled)}
    >
      {(isDesktopView || !collapsible) && (assemblyItem ? 'New Assembly' : 'New Item')}
    </Button>
  );
}
