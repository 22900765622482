import React from 'react';

import { WarningIconTooltip } from 'components/recipes/sidePane/WarningIconTooltip';

export function SpecialOrderFlag() {
  return (
    <WarningIconTooltip
      tooltipText="Special order available. Purchase through your ERP to combine with stockable items. Recurrency will support special order purchasing in the future."
      wrapText
    />
  );
}
