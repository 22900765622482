import React from 'react';

import { css } from '@emotion/css';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowOpOqVariant } from '../../../../pages/purchasing/utils';
import { MinMaxQtyToOrderEquation } from './MinMaxQtyToOrderEquation';
import { OPOQQtyToOrderEquation } from './OPOQQtyToOrderEquation';
import { OrderWarningTextPopover } from './OrderWarningTextPopover';
import { QtyToOrderEquationRecord } from './types';

export interface QtyToOrderRecord {
  replenishmentMethod?: string;
  status: PurchaseTargetLineStatus;
  stockable: boolean;
  invMin: number;
  invMax: number;
  netStock: number;
  unitSize: number;
  unitOfMeasure: string;
  qtyBackordered: number;
  qtyToOrder: number;
  qtyToOrderUnaligned: number;
  qtyToOrderAlignUnitOfMeasure?: string;
  qtyToOrderAlignUnitSize?: number;
  isHub?: boolean;
}

export const QtyToOrderPopover = ({
  record,
  title = 'Quantity Required Calculation',
  resultProperty = 'Qty Required',
}: {
  record: QtyToOrderRecord;
  title?: 'Quantity Required Calculation' | 'Quantity to Order Calculation';
  resultProperty?: 'Qty Required' | 'Qty To Order';
}) => {
  const { activeTenant, activeUser } = useGlobalApp();

  if (
    record.isHub === false &&
    (record.status === PurchaseTargetLineStatus.Understock || record.status === PurchaseTargetLineStatus.Backordered)
  ) {
    return (
      <OrderWarningTextPopover title={title}>
        <div
          className={css`
            max-width: 350px;
          `}
        >
          According to its replenishment path, this item is replenished from another location and should not be
          purchased here.
        </div>
      </OrderWarningTextPopover>
    );
  }

  const alignNeeded =
    record.qtyToOrderUnaligned !== record.qtyToOrder &&
    record.qtyToOrderAlignUnitOfMeasure &&
    (record.qtyToOrderAlignUnitSize || 0) > 1;

  const equationRecord: QtyToOrderEquationRecord = {
    replenishmentMethod: record.replenishmentMethod,
    alignNeeded: !!alignNeeded,
    isStatusNormal: record.status === PurchaseTargetLineStatus.Normal,
    isOverstock: record.status === PurchaseTargetLineStatus.Overstock,
    stockable: record.stockable,
    currentMin: record.invMin,
    currentMax: record.invMax,
    netStock: record.netStock,
    qtyToOrder: record.qtyToOrder,
    qtyToOrderUnaligned: record.qtyToOrderUnaligned,
    unitOfMeasure: record.unitOfMeasure,
    qtyToOrderAlignUnitOfMeasure: record.qtyToOrderAlignUnitOfMeasure,
    qtyToOrderAlignUnitSize: record.qtyToOrderAlignUnitSize,
    qtyBackordered: record.qtyBackordered,
    unitSize: record.unitSize,
  };

  const shouldShowOpOq = shouldShowOpOqVariant(activeTenant, activeUser, equationRecord.replenishmentMethod);

  return shouldShowOpOq ? (
    <OPOQQtyToOrderEquation record={equationRecord} title={title} resultProperty={resultProperty} />
  ) : (
    <MinMaxQtyToOrderEquation record={equationRecord} title={title} resultProperty={resultProperty} />
  );
};
