import React from 'react';

import { useHistory } from 'react-router';

import { LockOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { TenantFeatureFlag, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { ButtonProps } from 'antd/lib/button';

import { AsyncButton } from 'components/Button/AsyncButton';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowFeatureFlag, shouldShowProduct } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { track, TrackEvent, CtaName, CtaType } from 'utils/track';

import { PurchaseOrderEditHashState } from 'types/hash-state';

import { UpgradePopover } from './Upgrade/UpgradePopover';

export const NewPurchaseOrderButton = ({
  hashState,
  label = 'New PO',
  buttonProps,
}: {
  hashState?: PurchaseOrderEditHashState | (() => PurchaseOrderEditHashState | PurchaseOrderEditHashState[]);
  label?: string;
  buttonProps?: ButtonProps;
}) => {
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();
  const shouldShowTeaser = !shouldShowProduct(activeTenant, TenantProductName.DfpaPurchasingAutomation);
  const shouldAllowCreatePO =
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.PurchasingCreatePurchaseOrder) ||
    shouldShowTeaser;

  if (shouldAllowCreatePO) {
    return (
      <div
        className={css`
          position: relative;
        `}
      >
        <AsyncButton
          type="primary"
          onClick={async () => {
            const plainHashState = typeof hashState === 'function' ? hashState() : hashState;
            track(TrackEvent.Nav_CtaClick, {
              ctaName: CtaName.NewPurchaseOrder,
              ctaType: CtaType.Button,
            });

            if (Array.isArray(plainHashState)) {
              // open multi location POs in new tabs
              plainHashState.forEach((hState) => {
                window.open(routes.purchasing.purchaseOrderNew(hState), '_blank');
              });
            } else {
              history.push(routes.purchasing.purchaseOrderNew(plainHashState));
            }
          }}
          icon={shouldShowTeaser ? <LockOutlined /> : <PlusOutlined />}
          {...buttonProps}
          disabled={shouldShowTeaser || buttonProps?.disabled}
        >
          {label}
        </AsyncButton>
        {shouldShowTeaser && (
          <UpgradePopover
            title="Create Purchase Orders"
            description="Enter purchase orders in 30 seconds, with all the information you need at your fingertips."
          />
        )}
      </div>
    );
  }
  return null;
};
