import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Col, Row } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import {
  getNumLineItems,
  QuoteLineItemP21WithInfo,
  getTotalPrice,
  getRequiredDate,
} from 'pages/orders/quotes/quoteUtils';
import { Disposition } from 'pages/orders/quotes/types';

import { Alert } from 'components/Alert';
import { Typography } from 'components/Typography';

import { NULLIFY_API_CALL, useCoreApi, useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy as isTruthy } from 'utils/boolean';
import { formatDate, formatUSD, getErpName, splitIdNameStr, splitIfIdNameStr, toTitleCase } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { encodeLegacyApiParam } from 'utils/routes';
import { sortableStringColumn, sortableNumberColumn, sortableDollarWithCentsColumn } from 'utils/tables';
import { isTenantGALarson, isTenantGallagherTire, isTenantRichardsSupply } from 'utils/tenants';
import { OrderType } from 'utils/track';

import { QuoteEditHashStateP21, QuoteLineItemP21 } from 'types/hash-state';
import {
  CustomerRestrictionsOrderEntryAction,
  CustomerRestrictionsResponse,
  CustomerRestrictionsValidationAction,
  customerRestrictionsValidationMessageMap,
} from 'types/legacy-api';

import { QuoteLineItemSummaryStats, SummaryLine } from '../QuoteLineItemSummaryStats';
import * as Styled from './QuoteReview.style';

export const QuoteReview = ({
  defaultApproval,
  quoteState,
  orderType,
  pricePrecision,
  lineItemsWithInfo,
  additionalSummaryLines,
}: {
  defaultApproval?: boolean;
  quoteState: QuoteEditHashStateP21;
  orderType: OrderType;
  pricePrecision: number;
  lineItemsWithInfo: QuoteLineItemP21WithInfo[];
  additionalSummaryLines: SummaryLine[];
}) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const customerId = quoteState.customer ? splitIdNameStr(quoteState.customer).foreignId : '';
  // apply customer restrictions only apply to orders, not quotes
  const { data: customerRestrictionsData } = useLegacyApi<CustomerRestrictionsResponse>(
    customerId && orderType === OrderType.Order
      ? `/v3/customers/${encodeLegacyApiParam(customerId)}/restrictions`
      : NULLIFY_API_CALL,
  );
  const { data: shippingRoutesData } = useCoreApi(schemas.shippingRoutes.getShippingRoutes);
  const { data: orderClassesData } = useCoreApi(schemas.orderClasses.getOrderClasses);

  const lineItems = quoteState.items || [];
  const lineItemColumns: ColumnType<QuoteLineItemP21>[] = [
    sortableStringColumn({
      title: 'Item ID',
      dataIndex: 'foreignId',
    }),
    sortableStringColumn({
      title: 'Item',
      render: (item: QuoteLineItemP21) => {
        const salesLocation = splitIdNameStr(quoteState.location!);
        const sourceLocation = item.sourceLocation || salesLocation;
        const shipLocation = item.shipLocation || salesLocation;
        return (
          <>
            <div>{item.name}</div>
            {item.customerPartId && (
              <div
                className={css`
                  color: ${theme.colors.neutral[500]};
                `}
              >
                Customer Part No.: {item.customerPartId}
              </div>
            )}
            {item.transferCarrier && sourceLocation && (
              <div
                className={css`
                  color: ${theme.colors.neutral[500]};
                `}
              >
                Transfer from {`${sourceLocation?.foreignId}: ${sourceLocation?.name}`}
                {item.transferCarrier?.name ? ` via ${item.transferCarrier?.name}` : null}
                {shipLocation ? ` to ${shipLocation.foreignId}: ${shipLocation.name}` : null}
              </div>
            )}
            {item.disposition === Disposition.DirectShip && (
              <div
                className={css`
                  color: ${theme.colors.neutral[500]};
                `}
              >
                Direct ship from{' '}
                {` ${item.supplier?.foreignId}: ${item.supplier?.name} to: ${quoteState.shipTo}, PO Cost: $${
                  item.poCost || 0
                }`}
              </div>
            )}
            {item.disposition === Disposition.Special && (
              <div
                className={css`
                  color: ${theme.colors.neutral[500]};
                `}
              >
                Special Order from
                {` ${item.supplier?.foreignId}: ${item.supplier?.name} to: ${item.shipLocation?.foreignId}: ${
                  item.shipLocation?.name
                }, PO Cost: $${item.poCost || 0}`}
              </div>
            )}
            {item.disposition === Disposition.BackOrder && (
              <div
                className={css`
                  color: ${theme.colors.neutral[500]};
                `}
              >
                Backorder -- No quantity will be allocated for this line item.
              </div>
            )}
            {sourceLocation.foreignId === shipLocation.foreignId &&
              sourceLocation.foreignId !== salesLocation.foreignId && (
                <div
                  className={css`
                    color: ${theme.colors.neutral[500]};
                  `}
                >
                  Line location:
                  {` ${item.sourceLocation?.foreignId}: ${item.sourceLocation?.name}`}
                </div>
              )}
          </>
        );
      },
    }),
    sortableNumberColumn({ title: 'Quantity', dataIndex: 'quantity' }),
    sortableStringColumn({ title: 'UOM', dataIndex: 'unitOfMeasure' }),
    sortableDollarWithCentsColumn({ title: 'Price', dataIndex: 'price' }, pricePrecision),
    {
      title: 'Total Price',
      render: (_, lineItem) => formatUSD((lineItem.price || 0) * (lineItem.quantity || 0), true, 2),
      align: 'right' as const,
    },
  ];

  const headerRows = [
    {
      title: 'Company',
      content: quoteState.company,
    },
    {
      title: 'Customer',
      content: quoteState.customer,
    },
    customerRestrictionsData?.creditStatus?.orderEntryAction === CustomerRestrictionsOrderEntryAction.Warning &&
    customerRestrictionsData.creditStatus.validationAction === CustomerRestrictionsValidationAction.PlaceOnHold
      ? {
          title: 'Credit Issue',
          content: customerRestrictionsValidationMessageMap[customerRestrictionsData.creditStatus.validationAction],
        }
      : null,
    {
      title: 'Ship To',
      content: quoteState.shipTo,
    },
    {
      title: 'Contact',
      content: quoteState.contact,
    },
    {
      title: 'Terms',
      content: quoteState.terms,
    },
    {
      title: 'Required Date',
      content: formatDate(getRequiredDate(quoteState, activeTenant)),
    },
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersWarnMinMaxApproval)
      ? {
          title: 'Order Approved',
          content: defaultApproval ? `Yes` : `No`,
        }
      : null,
    orderType === OrderType.Quote
      ? {
          title: 'Valid Until Date',
          content: formatDate(quoteState.validUntilDate),
        }
      : null,
    {
      title: 'Sales Location',
      content: quoteState.willCall ? `${quoteState.location} (Will Call)` : quoteState.location,
    },
    {
      title: 'Freight',
      content: quoteState.freightType,
    },
    quoteState.freightCharge
      ? {
          title: 'Freight Charge',
          content: quoteState.freightCharge,
        }
      : null,
    {
      title: 'Carrier',
      content: quoteState.carrier,
    },
    orderType === OrderType.Order &&
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersFrontCounterEnabled)
      ? {
          title: 'Front Counter',
          content: quoteState.frontCounter ? 'Yes' : 'No',
        }
      : null,
    orderType === OrderType.Quote && isTenantRichardsSupply(activeTenant.id)
      ? {
          title: 'Quote For Order',
          content: quoteState.qteForOrder === 'Y' ? 'Yes' : 'No',
        }
      : null,
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeShippingRoute)
      ? {
          title: 'Route',
          content:
            quoteState.shippingRouteId &&
            `${quoteState.shippingRouteId}: ${
              shippingRoutesData?.items?.filter((o) => o.shippingRouteId === quoteState.shippingRouteId)[0]
                ?.shippingRouteName
            }`,
        }
      : null,
    quoteState.class1
      ? isTenantGallagherTire(activeTenant.id)
        ? {
            title: toTitleCase(
              orderClassesData?.items
                .filter((c) => c.classId === splitIfIdNameStr(quoteState.class1)?.foreignId)[0]
                .classDescription.toLocaleLowerCase() || 'Class 1',
            ),
            content: 'Yes',
          }
        : {
            title: 'Order Class 1',
            content: quoteState.class1,
          }
      : null,
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangePackingBasis)
      ? {
          title: 'Packing Basis',
          content: quoteState.packingBasis,
        }
      : null,
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeDeliveryInstructions)
      ? {
          title: 'Delivery Instructions',
          content: quoteState.deliveryInstructions,
        }
      : null,
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeShipToEmailAddress)
      ? {
          title: 'Email Address',
          content: quoteState.shipToEmailAddress,
        }
      : null,
    quoteState.orderTypePriority
      ? {
          title: 'Order Priority',
          content: quoteState.orderTypePriority,
        }
      : null,
    {
      title: 'PO No',
      content: quoteState.poNo,
    },
    quoteState.customerJobNo != null && isTenantGALarson(activeTenant.id)
      ? {
          title: 'Customer Job No',
          content: quoteState.customerJobNo,
        }
      : null,
    quoteState.headerNote
      ? {
          title: 'Header Note',
          content: quoteState.headerNote,
        }
      : null,
    quoteState.priceOverrideReason
      ? {
          title: 'Price Override Reason',
          content: quoteState.priceOverrideReason,
        }
      : null,
    quoteState.priceOverrideReason
      ? {
          title: 'Price Override Comments',
          content: quoteState.priceOverrideComments,
        }
      : null,
  ].filter(isTruthy);

  const creditLimit = customerRestrictionsData?.creditLimit ?? 1;

  return (
    <Styled.Container>
      {orderType === OrderType.Order &&
      creditLimit > 0 &&
      (customerRestrictionsData?.creditLimitUsed ?? 0) + getTotalPrice(lineItemsWithInfo) > creditLimit ? (
        <Alert
          message={`Customer is over credit limit. This order will be placed on hold after it is submitted to ${getErpName(
            activeTenant.erpType,
          )}.`}
          type="warning"
          showIcon
          className={css`
            margin-bottom: 16px;
          `}
        />
      ) : null}
      <Row gutter={24}>
        <Col xs={24} lg={18}>
          <Styled.Content>
            <Styled.Header>
              <Typography>Items ({getNumLineItems(lineItems)})</Typography>
            </Styled.Header>
            <Styled.ItemTable data={lineItems} columns={lineItemColumns} rowKey="id" pagination={false} size="small" />
          </Styled.Content>
          <Styled.Footer>
            <Styled.FooterContent>
              <QuoteLineItemSummaryStats
                lineItemsWithInfo={lineItemsWithInfo}
                additionalLines={additionalSummaryLines}
              />
            </Styled.FooterContent>
          </Styled.Footer>
        </Col>
        <Col xs={24} lg={6}>
          <Styled.Box>
            {headerRows.map((headerRow, idx) => (
              <Styled.HeaderRow key={idx}>
                <Styled.HeaderTitle>
                  <Typography type="subtitle">{headerRow.title}:</Typography>
                </Styled.HeaderTitle>
                <div>{headerRow.content}</div>
              </Styled.HeaderRow>
            ))}
          </Styled.Box>
          <br />
        </Col>
      </Row>
    </Styled.Container>
  );
};
