import React from 'react';

import { useHistory } from 'react-router';

import { PlusOutlined } from '@ant-design/icons';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ButtonProps } from 'antd';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { pluralize } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { track, TrackEvent, CtaName, CtaType } from 'utils/track';

import { QuoteEditHashStateP21 } from 'types/hash-state';

import { Button } from '../Button';

export const NewQuoteButton = ({
  props,
  hashState,
  showItemCount = false,
  label = 'New Quote',
}: {
  props?: ButtonProps;
  hashState?: QuoteEditHashStateP21;
  showItemCount?: boolean;
  label?: string;
}) => {
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();
  return shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) ? (
    <Button
      {...props}
      type="primary"
      onClick={(ev) => {
        props?.onClick?.(ev);
        track(TrackEvent.Nav_CtaClick, {
          ctaName: CtaName.NewQuote,
          ctaType: CtaType.Button,
        });
        history.push(routes.orders.quoteNew(hashState));
      }}
      icon={<PlusOutlined />}
    >
      {label} {showItemCount && `(${pluralize(hashState?.items?.length ?? 0, 'item', 'items', true)})`}
    </Button>
  ) : null;
};
