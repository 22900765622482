import { reactNodeToText } from 'utils/react';
import { ExportColumn } from 'utils/tables';

export function flattenExportColumns<HitT>(columns: ExportColumn<HitT>[]) {
  return columns
    .reduce(
      (flattened: ExportColumn<HitT>[], column) =>
        // expand exportValue object into multiple export columns e.g `{'Item ID': 'itemId', 'Item Name': 'itemName'}`
        flattened.concat(
          typeof column.exportValue === 'object'
            ? Object.entries(column.exportValue).map(([subColTitle, subColExportValue]) => ({
                ...column,
                exportTitle: subColTitle,
                exportValue: subColExportValue,
              }))
            : [column],
        ),
      [],
    )
    .map((column) => ({
      ...column,
      // sanitize title & dataIndex to be string
      title: column.exportTitle || reactNodeToText(column.title),
      dataIndex:
        typeof column.exportValue === 'string'
          ? column.exportValue
          : Array.isArray(column.dataIndex)
          ? column.dataIndex[0]
          : column.dataIndex,
    }));
}
