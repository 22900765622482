import React, { forwardRef } from 'react';

import { css, cx } from '@emotion/css';
import { Select as AntdSelect, SelectProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type Heights = {
  small: string;
  middle: string;
  large: string;
};

const heights: Heights = {
  small: '32px',
  middle: '40px',
  large: '50px',
};

const getHeights = (size: SizeType = 'middle') => heights[size];

export const Select = forwardRef(({ className, ...props }: SelectProps<any>, ref: Any) => (
  <AntdSelect
    ref={ref}
    className={cx(
      css`
        &.ant-select {
          .ant-select-selector {
            border-radius: 8px;
            min-width: 50px;
            min-height: ${getHeights(props.size)} !important;
            .ant-select-selection-item,
            .ant-select-selection-search,
            .ant-select-selection-placeholder {
              display: flex;
              align-items: center;
            }
          }
        }
        &.ant-select-single {
          span.ant-select-selection-item {
            width: 1px;
            padding-right: 0;
            margin-right: 21px;
          }
        }
      `,
      className,
    )}
    {...props}
  />
));

export const TagSelect = forwardRef(({ className, ...props }: SelectProps<any>, ref: Any) => (
  <AntdSelect
    ref={ref}
    className={cx(
      css`
        &.ant-select {
          .ant-select-selector {
            border-radius: 8px;
            min-width: 50px;
            height: ${getHeights(props.size)} !important;
            .ant-select-selection-item,
            .ant-select-selection-search,
            .ant-select-selection-placeholder {
              display: flex;
              align-items: center;
            }
          }
        }
      `,
      className,
    )}
    {...props}
  />
));
