import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';

import { RecommendedMinPopover } from 'pages/purchasing/PlanningPage/modals/RecommendedMinPopover';
import { UpdateReplenishmentSettingsModal } from 'pages/purchasing/PlanningPage/modals/UpdateReplenishmentSettingsModal';
import { GenericTargetLineRow } from 'pages/purchasing/PurchaseTargetsPage/types';

import { ActionButton } from 'components/Button/ActionButton';

import { showAsyncModal } from 'utils/asyncModal';

import { MinMaxCell } from './MinMaxCell';

export interface MinMax {
  min: number | undefined;
  max: number | undefined;
}

export function OrderPointOrderQuantityPanelContent({
  minMaxRecord,
  itemInfo,
  allowOpOqEdit = false,
  onOpOqChange,
}: {
  minMaxRecord: SearchPlanningDTO | undefined;
  itemInfo?: GenericTargetLineRow;
  allowOpOqEdit?: boolean;
  onOpOqChange?: () => void;
}) {
  const currentOpLabel = 'Current OP';
  const currentOqLabel = 'Current OQ';
  const recommendedOpLabel = 'Recommended OP';

  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
      `}
    >
      {minMaxRecord ? (
        <>
          <MinMaxCell
            label={currentOpLabel}
            value={minMaxRecord.current_min}
            unitOfMeasure={minMaxRecord.unit_of_measure}
          />
          <MinMaxCell
            label={recommendedOpLabel}
            value={minMaxRecord.recommended_min}
            unitOfMeasure={minMaxRecord.unit_of_measure}
            equationPopover={<RecommendedMinPopover record={minMaxRecord} showOpOqVariant />}
          />
          <MinMaxCell
            label={currentOqLabel}
            value={minMaxRecord.current_max}
            unitOfMeasure={minMaxRecord.unit_of_measure}
          />
          {allowOpOqEdit && (
            <>
              <br />
              <ActionButton
                label={
                  <>
                    <EditOutlined /> Edit
                  </>
                }
                onClick={() => {
                  showAsyncModal(UpdateReplenishmentSettingsModal, {
                    record: minMaxRecord,
                    searchIndexReload: onOpOqChange,
                  });
                }}
              />
            </>
          )}
        </>
      ) : itemInfo ? (
        // Only inventory items with usage have min/max records in search index.
        // If we don't have min/max with recommended values, show the current op/oq from the inv_loc item.
        <>
          <MinMaxCell label={currentOpLabel} value={itemInfo.invMin} unitOfMeasure={itemInfo.unitOfMeasure} />
          <MinMaxCell label={recommendedOpLabel} value={undefined} unitOfMeasure="" />
          <MinMaxCell label={currentOqLabel} value={itemInfo.invMax} unitOfMeasure={itemInfo.unitOfMeasure} />
        </>
      ) : null}
    </div>
  );
}
