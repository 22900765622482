import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig } = config.quoteOrderEntryFlow;
  quoteOrderFormConfig.approved.defaultValue = false;
  quoteOrderFormConfig.contact.required = true;
  quoteOrderFormConfig.customerPONumber.required = true;
  return config;
}
