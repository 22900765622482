import React from 'react';

import { Tooltip } from 'components/Tooltip';

import { capitalize } from 'utils/formatting';

interface Card {
  expirationMonth: number;
  expirationYear: number;
  brand: string;
  last4: string;
}

export interface CardColumnProps {
  card: Card;
}

export function CardColumn(props: CardColumnProps): JSX.Element {
  return (
    <Tooltip title={`${props.card.expirationMonth}/${props.card.expirationYear}`}>
      <div>
        {capitalize(props.card.brand)}-{props.card.last4}
      </div>
    </Tooltip>
  );
}
