import React from 'react';

import { css, cx } from '@emotion/css';
import { Radio } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type SizeTypeObj = {
  small: string;
  middle: string;
  large: string;
};

const radius: SizeTypeObj = {
  small: '6px',
  middle: '8px',
  large: '10px',
};

const padding: SizeTypeObj = {
  small: '0 8px',
  middle: '0 15px',
  large: '0 20px',
};

const fontSize: SizeTypeObj = {
  small: '13px',
  middle: '14px',
  large: '16px',
};

const lineHeight: SizeTypeObj = {
  small: '27px',
  middle: '30px',
  large: '36px',
};

const height: SizeTypeObj = {
  small: '29px',
  middle: '32px',
  large: '38px',
};

const getAttrValue = (size: SizeType = 'middle', attrProps: SizeTypeObj) => attrProps[size];

export const StyledRadioGroup = ({ size, className, ...props }: { size?: SizeType; className?: string }) => (
  <Radio.Group
    className={cx(
      css`
        .ant-radio-button-wrapper {
          padding: ${getAttrValue(size, padding)};
          font-size: ${getAttrValue(size, fontSize)};
          line-height: ${getAttrValue(size, lineHeight)};
          height: ${getAttrValue(size, height)};
        }

        .ant-radio-button-wrapper:first-child {
          border-radius: ${`${getAttrValue(size, radius)} 0px 0px ${getAttrValue(size, radius)}`};
        }

        .ant-radio-button-wrapper:last-child {
          border-radius: ${`0px ${getAttrValue(size, radius)} ${getAttrValue(size, radius)} 0px`};
        }
      `,
      className,
    )}
    {...props}
  />
);
