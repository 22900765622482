import React, { useEffect, useState } from 'react';

import { IntegratedErps, IntegrationEnvironment, IntegrationType } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantIntegrationBodyParams } from '@recurrency/core-api-schema/dist/integrations/postCreateIntegration';
import {
  TenantIntegrationDTO,
  TenantIntegrationReadTarget,
  TenantIntegrationWriteTarget,
} from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Form } from 'antd';

import { Button } from 'components/Button';
import { InputFormItem, RadioFormItem, responsiveFormLayout2 } from 'components/FormItems';
import { Modal } from 'components/Modal';
import { Typography } from 'components/Typography';

const enum SecretsType {
  ReadOnly = 'readOnly',
  ReadAndWrite = 'readAndWrite',
}

const ErpType = IntegratedErps;

const DbType = TenantIntegrationReadTarget;
const dbTypes = [
  { value: DbType.Mssql, label: 'MSSQL DB' },
  { value: DbType.Hana, label: 'HANA DB' },
];

const secretsTypes = [
  { value: SecretsType.ReadOnly, label: 'Read Only' },
  { value: SecretsType.ReadAndWrite, label: 'Read and Write' },
];

const environments = [
  { value: IntegrationEnvironment.Production, label: 'Production' },
  { value: IntegrationEnvironment.Staging, label: 'Staging' },
  { value: IntegrationEnvironment.Test, label: 'Test' },
];

export interface IntegrationSecretFormField {
  name: string;
  label: string;
  required: boolean;
  defaultValue?: string;
  type?: 'number' | 'password';
  // filters (applied if value is defined)
  secretsType?: SecretsType;
  erpType?: IntegratedErps;
  dbType?: TenantIntegrationReadTarget;
  integrationType?: IntegrationType;
}

export function TenantIntegrationEditForm({
  integration,
  tenant,
  onClose,
  onSubmit,
}: {
  integration: TenantIntegrationDTO | null;
  tenant: TenantDTO;
  onClose: () => void;
  onSubmit: (fields: TenantIntegrationBodyParams) => void;
}) {
  const [form] = Form.useForm<TenantIntegrationDTO>();
  // form changes don't trigger a re-render, this is better than binding all form state to useState
  // TODO: once we update antd, use https://ant.design/components/form/#Form.useWatch
  const [refresh, setRefresh] = useState(false);

  // if editing integration, bind it to form
  useEffect(() => {
    if (integration) {
      form.setFields(Object.entries(integration).map(([key, value]) => ({ name: key, value })));
    }
  }, [form, integration]);

  // the available integration types depends on the tenant's ERP type
  const integrationTypes =
    tenant.erpType === ErpType.NETSUITE
      ? [{ value: IntegrationType.Api, label: 'API' }]
      : [
          { value: IntegrationType.Database, label: 'Database' },
          { value: IntegrationType.Api, label: 'API' },
        ];

  const selectedIntegrationType: IntegrationType = form.getFieldValue('type');
  const selectedSecretsType: SecretsType = form.getFieldValue('secretsType');
  const selectedDbType: TenantIntegrationReadTarget = form.getFieldValue(['syncSources', 'read', 'target']);

  const secretFormFields: IntegrationSecretFormField[] = [
    // common database fields
    { name: 'host', label: 'DB Host', required: true, integrationType: IntegrationType.Database },
    {
      name: 'port',
      label: 'DB Port',
      required: true,
      type: 'number',
      integrationType: IntegrationType.Database,
    },
    { name: 'database', label: 'DB Name', required: true, integrationType: IntegrationType.Database },
    { name: 'username', label: 'DB Username', required: true, integrationType: IntegrationType.Database },
    {
      name: 'password',
      label: 'DB Password',
      required: true,
      type: 'password',
      integrationType: IntegrationType.Database,
    },

    // P21 cloud api fields
    { name: 'host', label: 'API Host', required: true, erpType: ErpType.P21, integrationType: IntegrationType.Api },
    {
      name: 'port',
      label: 'API Port',
      required: true,
      type: 'number',
      erpType: ErpType.P21,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'username',
      label: 'API Username',
      required: true,
      erpType: ErpType.P21,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'password',
      label: 'API Password',
      required: true,
      type: 'password',
      erpType: ErpType.P21,
      integrationType: IntegrationType.Api,
    },

    // p21 transaction api fields
    {
      name: 'api_host',
      label: 'API Host',
      required: false,
      erpType: ErpType.P21,
      secretsType: SecretsType.ReadAndWrite,
      integrationType: IntegrationType.Database,
    },

    // netsuite specific fields
    {
      name: 'api_host',
      label: 'API Host',
      required: true,
      erpType: ErpType.NETSUITE,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'api_consumer_key',
      label: 'Consumer Key',
      required: true,
      type: 'password',
      erpType: ErpType.NETSUITE,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'api_consumer_secret',
      label: 'Consumer Secret',
      required: true,
      type: 'password',
      erpType: ErpType.NETSUITE,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'api_access_token',
      label: 'Access Token',
      required: true,
      type: 'password',
      erpType: ErpType.NETSUITE,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'api_token_secret',
      label: 'Token Secret',
      required: true,
      type: 'password',
      erpType: ErpType.NETSUITE,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'realm',
      label: 'Realm',
      required: true,
      erpType: ErpType.NETSUITE,
      integrationType: IntegrationType.Api,
    },
    {
      name: 'signature_method',
      label: 'Signature Method',
      required: true,
      erpType: ErpType.NETSUITE,
      defaultValue: 'HMAC-SHA256',
      integrationType: IntegrationType.Api,
    },

    // sapb1 specific fields
    {
      name: 'sapb1_db_schema',
      label: 'DB Schema',
      required: true,
      erpType: ErpType.SAPB1,
      integrationType: IntegrationType.Database,
      dbType: DbType.Hana,
    },
    {
      name: 'sapb1_api_url',
      label: 'SL API URL Fragment',
      required: true,
      erpType: ErpType.SAPB1,
      secretsType: SecretsType.ReadAndWrite,
      integrationType: IntegrationType.Database,
    },
    {
      name: 'sapb1_api_port',
      label: 'SL API Port',
      type: 'number',
      required: true,
      erpType: ErpType.SAPB1,
      secretsType: SecretsType.ReadAndWrite,
      integrationType: IntegrationType.Database,
    },
    {
      name: 'sapb1_api_username',
      label: 'SL API UserName',
      required: true,
      erpType: ErpType.SAPB1,
      secretsType: SecretsType.ReadAndWrite,
      integrationType: IntegrationType.Database,
    },
    {
      name: 'sapb1_api_password',
      label: 'SL API Password',
      type: 'password',
      required: true,
      erpType: ErpType.SAPB1,
      secretsType: SecretsType.ReadAndWrite,
      integrationType: IntegrationType.Database,
    },
    {
      name: 'sapb1_api_company_db',
      label: 'SL API CompanyDB',
      required: true,
      erpType: ErpType.SAPB1,
      secretsType: SecretsType.ReadAndWrite,
      integrationType: IntegrationType.Database,
    },
  ];

  const filteredSecretFormFields = secretFormFields.filter((formField) => {
    if (formField.erpType !== undefined && formField.erpType !== tenant.erpType) {
      return false;
    }
    if (formField.integrationType !== undefined && formField.integrationType !== selectedIntegrationType) {
      return false;
    }
    if (formField.secretsType !== undefined && formField.secretsType !== selectedSecretsType) {
      return false;
    }
    if (formField.dbType !== undefined && formField.dbType !== selectedDbType) {
      return false;
    }
    return true;
  });

  const handleFormSubmit = (fields: Any) => {
    const data = { ...fields };

    if (data.secrets) {
      data.secrets.port = parseInt(data.secrets.port, 10);
      if (data.secrets.sapb1_api_port) {
        data.secrets.sapb1_api_port = parseInt(data.secrets.sapb1_api_port, 10);
      }
    }
    if (!data.syncSources) {
      if (tenant.erpType === ErpType.NETSUITE) {
        data.syncSources = {
          read: { target: TenantIntegrationReadTarget.NetsuiteRestApi },
          write: { target: TenantIntegrationWriteTarget.NetsuiteRestApi },
        };
      } else {
        data.syncSources = { read: { target: TenantIntegrationReadTarget.Mssql } };
      }
    }
    onSubmit(data);
  };

  return (
    <Modal
      visible
      width="600px"
      title="Manage Integration"
      onCancel={onClose}
      footer={
        <div>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={form.submit} disabled={!tenant.id}>
            Save Changes
          </Button>
        </div>
      }
      centered
    >
      <Form {...responsiveFormLayout2} form={form} onFinish={handleFormSubmit}>
        <InputFormItem
          name="name"
          label="Integration Name"
          rules={[{ required: true, message: 'Please add an integration name.' }]}
        />
        <RadioFormItem
          label="Environment"
          name="environment"
          values={environments}
          rules={[{ required: true, message: 'Please select an environment.' }]}
          onChange={() => {}}
          optionType="default"
        />
        <RadioFormItem
          label="Integration Type"
          name="type"
          values={integrationTypes}
          rules={[{ required: true, message: 'Please select an integration type.' }]}
          onChange={() => setRefresh(!refresh)}
          optionType="default"
        />
        {tenant.erpType === ErpType.SAPB1 ? (
          <RadioFormItem
            label="DB Type"
            name={['syncSources', 'read', 'target']}
            values={dbTypes}
            rules={[{ required: true, message: 'Please select a DB type.' }]}
            onChange={() => setRefresh(!refresh)}
            optionType="default"
          />
        ) : null}
        {tenant.erpType !== ErpType.NETSUITE ? (
          <RadioFormItem
            label="Secrets Type"
            name="secretsType"
            values={secretsTypes}
            rules={[{ required: true, message: 'Please select a secrets type.' }]}
            onChange={() => setRefresh(!refresh)}
            optionType="default"
          />
        ) : null}
        {integration ? (
          <Typography type="subtitle" style={{ marginBottom: '12px' }}>
            <p>To update database credentials, provide a full new set of login details.</p>
            <p>
              If using ngrok, <strong>DB Host</strong> should be 127.0.0.1 and <strong>DB Port</strong> should be the
              existing port assigned to this integration in the stunnel config.
            </p>
          </Typography>
        ) : null}
        {filteredSecretFormFields.map((field) => (
          <InputFormItem
            key={field.name}
            name={['secrets', field.name]}
            label={field.label}
            defaultValue={field.defaultValue}
            type={field.type}
            rules={field.required ? [{ required: true, message: `Please add a ${field.label}.` }] : []}
          />
        ))}
      </Form>
    </Modal>
  );
}
