import React from 'react';

import { css } from '@emotion/css';

import { MiniTable } from 'components/MiniTable';

import { formatUSD } from 'utils/formatting';

import { OrderLineItemSAPB1 } from 'types/hash-state';

import { getNumLineItems, getTotalPrice } from '../orderUtils';

export function OrderEditLineItemSummaryStats({ lineItems }: { lineItems: OrderLineItemSAPB1[] }) {
  const summaryStats = [
    { name: 'Total Lines', value: getNumLineItems(lineItems) },
    { name: 'Total Price', value: formatUSD(getTotalPrice(lineItems), true) },
  ];

  return (
    <MiniTable
      numRowsShown={summaryStats.length}
      data={summaryStats}
      columns={[
        {
          render: (record) => `${record.name}:`,
          align: 'right',
        },
        {
          render: (record) => (
            <strong
              className={css`
                margin-left: 16px;
              `}
            >
              {record.value}
            </strong>
          ),
          align: 'right',
          maxWidth: '200px',
        },
      ]}
    />
  );
}
