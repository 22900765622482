import React, { useState } from 'react';

import { ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  GetItemUsageInheritanceDTO,
  InheritanceOverrideBodyParams,
} from '@recurrency/core-api-schema/dist/items/getItemUsageInheritanceOverride';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { notification } from 'antd';

import { Alert } from 'components/Alert';
import { SearchSelect } from 'components/AsyncSelect/SearchSelect';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';

export interface UsageInheritanceData {
  descendantItemId: string;
  descendantItemCode: string;
  existingOverrides: GetItemUsageInheritanceDTO[];
  onDataChanged: () => void;
  nextUpdateDays: number | null;
}

export interface EditUsageInheritanceModalProps {
  usageInheritanceData: UsageInheritanceData;
  onClose: () => void;
}

interface ItemOption {
  /** {foreignId}: {name} * */
  value?: string | undefined;
  /** {item Uid} * */
  uid: string;
}

export const UsageInheritanceModal = ({ usageInheritanceData, onClose }: EditUsageInheritanceModalProps) => {
  const { descendantItemId, descendantItemCode, existingOverrides, onDataChanged, nextUpdateDays } =
    usageInheritanceData;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ItemOption[]>(
    existingOverrides.map((override) => ({
      uid: override.itemId,
      value: `${override.itemCode}: ${override.itemName}`,
    })),
  );
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const nonEmptyItems = selectedItems.filter((item) => item.uid.trim() !== '');
      const bodyParams: InheritanceOverrideBodyParams = {
        ancestors: nonEmptyItems.map((item) => ({
          ancestorItemId: item.uid,
        })),
      };

      await coreApiFetch(schemas.items.putUpdateItemUsageInheritanceOverrides, {
        pathParams: { itemUid: descendantItemId },
        bodyParams,
      });
      notification.success({
        message: 'Usage Inheritance Updated',
        description: `Usage inheritance set for item ${descendantItemCode}`,
      });
      onDataChanged();
      onClose();
    } catch (error) {
      captureAndShowError(error, 'Error updating usage inheritance');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      visible
      title="Edit Usage Inheritance"
      onCancel={onClose}
      footer={
        <div>
          <Button onClick={onClose}>Cancel</Button>
          <Button key="submit" type="primary" onClick={handleSubmit} loading={isSubmitting}>
            Save
          </Button>
        </div>
      }
      width={800}
      centered
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
        `}
      >
        <Alert
          icon={<ExclamationCircleOutlined />}
          message={`Changes will only be reflected the next time usage calculations are run${
            nextUpdateDays !== null ? `, in ${nextUpdateDays} days` : ''
          }.`}
          type="info"
          banner
        />
        <div
          className={css`
            display: flex;
            flex-direction: column;
            gap: 12px;
          `}
        >
          {selectedItems.map((item, index) => (
            <div
              key={`item-${index}-${item.uid}`}
              className={css`
                display: flex;
                align-items: center;
                gap: 8px;
                width: 100%;
              `}
            >
              <SearchSelect
                className={css`
                  flex: 1;
                  min-width: 0;
                `}
                searchParams={{
                  searchIndex: SearchIndexName.Items,
                }}
                entityPlural="items"
                value={item.value}
                onChange={(value, option: any) => {
                  const newSelectedItems = [...selectedItems];
                  newSelectedItems[index] = {
                    uid: option.uid,
                    value,
                  };
                  setSelectedItems(newSelectedItems);
                }}
              />

              <Button
                danger
                onClick={() => {
                  const newSelectedItems = [...selectedItems];
                  newSelectedItems.splice(index, 1);
                  setSelectedItems(newSelectedItems);
                }}
              >
                <MinusCircleOutlined /> Delete
              </Button>
            </div>
          ))}
        </div>
        <Button
          type="default"
          onClick={() => setSelectedItems([...selectedItems, { uid: '', value: '' }])}
          className={css`
            width: 100%;
          `}
        >
          Add Usage Ancestor
        </Button>
      </div>
    </Modal>
  );
};
