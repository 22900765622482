import React, { ReactNode } from 'react';

import { Descriptions } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { DescriptionsContainer } from 'components/DescriptionsContainer';

export interface Description {
  label: ReactNode;
  value: ReactNode;
  span?: number;
}
export const EntityDescriptions = ({
  descriptions,
  bordered = false,
  column = { xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 },
  style = {},
}: {
  descriptions: Description[];
  bordered?: boolean;
  column?: Partial<Record<Breakpoint, number>> | number;
  style?: React.CSSProperties;
}) => (
  <DescriptionsContainer column={column} bordered={bordered} style={style}>
    {descriptions.map(({ label, value, span = 1 }, index) => (
      <Descriptions.Item key={index} label={label} span={span}>
        {value}
      </Descriptions.Item>
    ))}
  </DescriptionsContainer>
);
