import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  LockOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  IntegratedErps,
  IntegrationEnvironment,
  TenantIntegrationStatus,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantIntegrationBodyParams } from '@recurrency/core-api-schema/dist/integrations/postCreateIntegration';
import {
  TenantIntegrationDTO,
  TenantIntegrationReadTarget,
  TenantIntegrationWriteTarget,
} from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { Form, Input, message, Space, Steps, Typography, Radio, Collapse } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { InputFormItem } from 'components/FormItems';
import { Modal } from 'components/Modal';
import { PageHeader } from 'components/PageHeader';
import { RadioGroup } from 'components/Radio';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { env } from 'utils/env';
import { captureAndShowError, captureError } from 'utils/error';
import { capitalize } from 'utils/formatting';
import { generateTempPassword } from 'utils/password';
import { routes } from 'utils/routes';
import { openZendeskModal, zendeskAnonymousRequest } from 'utils/zendesk';

import { DownloadNgrokAgentButton } from './DownloadNgrokAgentButton';

const { Step } = Steps;
enum IntegrationStep {
  GettingStarted,
  NetworkConnection,
  DatabaseCredentials,
  Sync,
}

enum DatabasePermissionsType {
  ReadAndWrite,
  Read,
}

export interface SelfServeIntegrationFormData {
  name: string;
  type: string;
  host: string;
  port: string;
  username: string;
  password: string;
}

const generatedPassword = generateTempPassword();

export const Integration = () => {
  const { activeTenant, activeUser } = useGlobalApp();

  const [databasePermissionsType, setDatabasePermissionsType] = useState(DatabasePermissionsType.ReadAndWrite);
  const [networkVerificationLoading, setNetworkVerificationLoading] = useState(false);
  const [shouldCollectNetworkFields, setShouldCollectNetworkFields] = useState(false);

  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
  const [integrationForm] = Form.useForm();

  const [currentStep, setCurrentStep] = useState<IntegrationStep>(
    // TODO: migrate activeTenant to core-api-schema and TenantDTO
    (activeTenant.integrations?.[0] as unknown as TenantIntegrationDTO)?.status === TenantIntegrationStatus.Active
      ? IntegrationStep.Sync
      : 0,
  );

  const onIntegrationSubmit = async (data: TenantIntegrationBodyParams) => {
    // Some tenant names include the environment, while some do not. We separate both versions here:
    const integrationEnvironment = env.APP_ENV as string as IntegrationEnvironment;
    const integrationChunks = activeTenant.name.split(' ');
    const integrationlessName =
      integrationChunks.pop()?.toLowerCase() === integrationEnvironment
        ? integrationChunks.join(' ')
        : activeTenant.name;
    data.name = `${integrationlessName} ${capitalize(integrationEnvironment)}`;
    if (data.secrets.port) {
      data.secrets.port = parseInt(`${data.secrets.port}`, 10);
    }
    data.secrets.username = data.secrets.username || 'recurrencyuser';

    if (activeTenant.erpType === IntegratedErps.NETSUITE) {
      data.syncSources = {
        read: { target: TenantIntegrationReadTarget.NetsuiteRestApi },
        write: { target: TenantIntegrationWriteTarget.NetsuiteRestApi },
      };
    } else {
      data.syncSources = { read: { target: TenantIntegrationReadTarget.Mssql } };
    }

    try {
      const {
        data: { items: integrations },
      } = await coreApiFetch(schemas.integrations.getTenantIntegrations, { pathParams: { tenantId: activeTenant.id } });

      if (integrations.length > 0) {
        await coreApiFetch(schemas.integrations.patchUpdatePartialIntegration, {
          pathParams: { tenantId: activeTenant.id, integrationId: integrations[0].id },
          bodyParams: data,
        });
      } else {
        await coreApiFetch(schemas.integrations.postCreateIntegration, {
          pathParams: { tenantId: activeTenant.id },
          bodyParams: data,
        });
      }
      setIsIntegrationModalOpen(false);
      message.success(`Database credentials submitted.`);
      setCurrentStep(currentStep + 1);
      try {
        await zendeskAnonymousRequest({
          requesterName: activeUser.fullName,
          subject: `${activeTenant.name} Database Connection form completed`,
          body: `Hi Solutions, ${activeUser.email} has completed the Database Connection form. Time to verify connection and run DAGs.`,
        });
      } catch (zendeskErr) {
        captureError(zendeskErr);
      }
    } catch (err) {
      captureAndShowError(err, `Error while submitting credentials. Our team will reach out to resolve this issue.`);
    }
  };

  const verifyConnection = async () => {
    setNetworkVerificationLoading(true);
    try {
      const res = await coreApiFetch(schemas.integrations.getAgentStatus);
      if (res.data.status) {
        setNetworkVerificationLoading(false);
        setCurrentStep(currentStep + 1);
        message.success('Network connection verified.');
      } else {
        message.error('Agent could not be reached, please try again or reach out to our Solutions Engineering team.');
      }
    } catch (err) {
      captureAndShowError(
        err,
        'Failed to verify network connection, please try again or reach out to our Solutions Engineering team.',
      );
    }
    setNetworkVerificationLoading(false);
  };

  const sqlLines = [
    `create login recurrencyuser with password = '${generatedPassword}'`,
    'create user recurrencyuser for login recurrencyuser with DEFAULT_SCHEMA=[dbo]',
    "exec sp_addrolemember 'db_datareader', 'recurrencyuser'",
    databasePermissionsType === DatabasePermissionsType.ReadAndWrite &&
      "exec sp_addrolemember 'db_datawriter', 'recurrencyuser'",
    databasePermissionsType === DatabasePermissionsType.ReadAndWrite && 'grant execute to recurrencyuser',
  ].filter(truthy);

  return (
    <Container>
      <PageHeader
        title="Secure Integration Portal"
        headerActions={
          <Space>
            <Link to={routes.settings.users()}>
              <Button icon={<PlusOutlined />}>Invite Technical Contact</Button>
            </Link>
            <Button type="primary" icon={<QuestionCircleOutlined />} onClick={openZendeskModal}>
              Ask an Expert
            </Button>
          </Space>
        }
      />
      {!activeTenant.isActive ? (
        <>
          <Steps current={currentStep} onChange={(c) => setCurrentStep(c)}>
            <Step title="Getting Started" />
            <Step title="Network Connection" />
            <Step title="Database Credentials" />
            <Step title="Initial Sync" />
          </Steps>
          <br />
          {currentStep === IntegrationStep.GettingStarted && (
            <>
              <Typography.Title level={4}>Welcome</Typography.Title>
              <Typography
                className={css`
                  margin-bottom: 20px;
                  margin-top: 0px;
                `}
              >
                You have been invited to Recurrency. We are going to help you get started and connected.
                <br /> <br />
                Let's get {activeTenant.name} set up. This should take <strong>about 20 minutes</strong>. You will need
                the following to complete this setup:
                <br /> <br />
                <ol>
                  <li>Remote desktop access to the server that runs your database.</li>
                  <li>Permissions to create a new user and login on that database.</li>
                </ol>
                You can always choose to send an invite to the appropriate person at your company to complete this
                setup.
              </Typography>
              <Space>
                <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>
                  Get Started <ArrowRightOutlined />
                </Button>
                <Link to={routes.settings.users()}>
                  <Button icon={<PlusOutlined />}>Invite Technical Contact</Button>
                </Link>
              </Space>
            </>
          )}
          {currentStep === IntegrationStep.NetworkConnection && (
            <>
              <Typography.Title level={4}>Network Connection</Typography.Title>
              <Typography>
                Recurrency establishes a secure tunnel to connect to your network without any changes to your firewall.
                <br /> <br />
                Let's walk through installing the Recurrency Agent on a machine within your network and verifying that
                network connection.
              </Typography>
              <Typography.Title level={5}>Identifying a host machine</Typography.Title>
              <Typography>
                The Recurrency Agent can run on any device connected to your network, but we usually recommend that you
                run it on the same server as your database.
              </Typography>
              <Typography.Title level={5}>Configuring your agent</Typography.Title>
              <Typography>
                To configure a custom agent, you will need to know:
                <br /> <br />
                <ol>
                  <li>Will you run the agent on the same server as your database or another machine?</li>
                  <li>Does that machine run a 64-bit or 32-bit version of Windows Server?</li>
                  <li>If the agent will run on a separate machine, what is the internal IP of your database server?</li>
                  <li>What port does your database run on?</li>
                </ol>
              </Typography>
              <DownloadNgrokAgentButton />
              <br /> <br />
              <Typography.Title level={5}>Installing agent</Typography.Title>
              <Typography>
                Once you've downloaded the Recurrency agent, here's what comes next:
                <br /> <br />
                <ol>
                  <li>Move the agent zip file to the host machine within your network.</li>
                  <li>Extract the zip in the C: drive and open the agent folder.</li>
                  <li>
                    Open an Administrator Command Prompt and navigate to the agent folder.
                    <br />
                    <Typography.Text italic>
                      Tip: You can quickly navigate to the same folder by copying the path from the header of Windows
                      Explorer and typing "cd [path]"
                    </Typography.Text>
                  </li>
                  <li>Once inside the agent folder, run the "install-agent.bat" command.</li>
                </ol>
                That's it! Once the agent is installed, verify connection to move to the next step.
                <br /> <br />
              </Typography>
              <Button
                icon={<CheckCircleOutlined />}
                type="primary"
                onClick={verifyConnection}
                loading={networkVerificationLoading}
              >
                Verify Network Connection
              </Button>
              <Button type="text" onClick={() => setCurrentStep(currentStep + 1)}>
                Continue without verifying <ArrowRightOutlined />
              </Button>
              <br /> <br />
              <Collapse ghost accordion>
                <Collapse.Panel header="Having trouble with the agent?" key="allowlist">
                  <Typography>
                    If you are unable to install the agent or establish a secure tunnel, Recurrency can also connect to
                    your network if you allowlist the IP address of our private network.
                    <br /> <br />
                    To do this, go to your network settings and allowlist Recurrency's IP address:
                    <br /> <br />
                    <Typography.Text strong copyable>
                      52.8.122.155
                    </Typography.Text>
                  </Typography>
                  <br />
                  <Button
                    type="primary"
                    onClick={() => {
                      setShouldCollectNetworkFields(true);
                      setCurrentStep(currentStep + 1);
                    }}
                  >
                    Next Step <ArrowRightOutlined />
                  </Button>
                </Collapse.Panel>
              </Collapse>
            </>
          )}
          {currentStep === IntegrationStep.DatabaseCredentials && (
            <>
              <Typography.Title level={4}>Database Connection</Typography.Title>
              <Typography>
                Recurrency connects directly to your production database in order to ensure data is live and up-to-date
                at all times.
                <br /> <br />
                In this step we'll create a new Recurrency database user to establish this connection. This also makes
                it easy to trace all database activity performed by Recurrency.
              </Typography>
              <Typography.Title level={5}>Generate database credentials</Typography.Title>
              <Typography>
                Based on the features your team will use in Recurrency, you can choose between two levels of database
                access.
              </Typography>
              <br />
              <RadioGroup
                value={databasePermissionsType}
                onChange={({ target: { value } }) => setDatabasePermissionsType(value)}
              >
                <Space direction="vertical">
                  <Radio value={DatabasePermissionsType.ReadAndWrite}>
                    <strong>Read and Write</strong>: Enables all Recurrency functionality, including creating quotes,
                    orders, prospects, contacts, setting mins, maxes, and more.
                  </Radio>
                  <Radio value={DatabasePermissionsType.Read}>
                    <strong>Read Only</strong>: Enables global search, record lookups, and intelligence including quote
                    recommendations and forecasting, but no record creation.
                  </Radio>
                </Space>
              </RadioGroup>
              <br />
              <br />
              <Typography>Based on the permissions you've selected, here's the SQL to run:</Typography>
              <br />
              <Paragraph copyable={{ text: sqlLines.join('\n') }}>Copy SQL</Paragraph>
              <pre
                className={css`
                  padding: 8px;
                  background: ${theme.colors.neutral[100]};
                  border: 2px solid ${theme.colors.neutral[300]};
                  border-radius: 8px;
                  display: inline-block;
                `}
              >
                <code>
                  {sqlLines.map((sqlLine) => (
                    <>
                      {sqlLine}
                      <br />
                    </>
                  ))}
                </code>
              </pre>
              <br />
              <Typography>
                Once you've created Recurrency database credentials, submit them through this secure form:
              </Typography>
              <br />
              <Button icon={<LockOutlined />} type="primary" onClick={() => setIsIntegrationModalOpen(true)}>
                Submit Credentials
              </Button>
            </>
          )}
          {currentStep === IntegrationStep.Sync && (
            <>
              {' '}
              <Typography.Title level={4}>Initial Sync</Typography.Title>
              <Typography>
                Now that we've verified our connection to your network and database, all that's left is to kick off the
                initial sync.
                <br /> <br />
                This shouldn't create significant load on your database, but to be safe we run it after hours. Once this
                first sync has completed, future syncs are lightning fast.
                <br /> <br />
                Our Solutions Engineering team will sync your database tonight.
                <br /> <br />
                <strong>
                  Next, you will receive an email confirmation from us when you are ready to start using Recurrency!
                </strong>
              </Typography>
            </>
          )}
        </>
      ) : (
        <Typography>
          <Typography.Title level={5}>ERP integration complete!</Typography.Title>
          <Link to={routes.sales.dashboard()}>
            <Button type="primary">
              Open Dashboard <ArrowRightOutlined />
            </Button>
          </Link>
        </Typography>
      )}
      <Modal
        visible={isIntegrationModalOpen}
        title="Database Credentials"
        onCancel={() => setIsIntegrationModalOpen(false)}
        footer={
          <div>
            <Button onClick={() => setIsIntegrationModalOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={integrationForm.submit}>
              Submit
            </Button>
          </div>
        }
        centered
        width={450}
      >
        <Form layout="vertical" name="integrationForm" form={integrationForm} onFinish={onIntegrationSubmit}>
          {shouldCollectNetworkFields && (
            <>
              <InputFormItem
                name={['secrets', 'host']}
                label="Host Address"
                rules={[{ required: true, message: 'Please add a host address.' }]}
              />

              <InputFormItem
                name={['secrets', 'port']}
                label="Port"
                type="number"
                rules={[{ required: true, message: 'Please add a port.' }]}
              />
            </>
          )}

          <InputFormItem
            name={['secrets', 'database']}
            label="Database Name"
            rules={[{ required: true, message: 'Please add a database name.' }]}
          />

          <InputFormItem name={['secrets', 'username']} label="Username" defaultValue="recurrencyuser" />

          <Form.Item
            label="Password"
            name={['secrets', 'password']}
            rules={[{ required: true, message: 'Please add a password.' }]}
            initialValue={generatedPassword}
          >
            <Input.Password
              className={css`
                height: 40px;
                border-radius: 8px;
              `}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  );
};
