import type { ThemeBreakpoints } from './breakpoints';
import { breakpoints } from './breakpoints';
import type { ColorPalette } from './colors';
import { colors } from './colors';
import type { ThemeElevations } from './elevations';
import { elevations } from './elevations';
import type { ThemeTransitions } from './transitions';
import { transitions } from './transitions';
import type { ThemeTypography } from './typography';
import { typography } from './typography';
import type { ThemeZIndices } from './zIndices';
import { zIndices } from './zIndices';

export type Theme = {
  breakpoints: ThemeBreakpoints;
  colors: ColorPalette;
  transitions: ThemeTransitions;
  typography: ThemeTypography;
  elevations: ThemeElevations;
  zIndices: ThemeZIndices;
};

export const theme = {
  breakpoints,
  colors,
  transitions,
  elevations,
  typography,
  zIndices,
};
