import React, { useState } from 'react';

import { DownOutlined, InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { TenantProductDescriptions, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantProductDTO, TenantProductsDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Form, Menu, notification, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Rule } from 'rc-field-form/lib/interface';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { CheckboxFormItem } from 'components/FormItems';
import { Table } from 'components/Table';

import { formatDate } from 'utils/formatting';
import { objGet } from 'utils/object';
import { sortableStringColumn, sortDirections } from 'utils/tables';

import {
  PRODUCT_FULLY_DISABLED,
  ProductsPreset,
  productDependencies,
  productsPresetTemplates,
} from './tenantProductConstants';

type ProductRecord = TenantProductDTO & {
  productName: TenantProductName;
};

const getOptionalBooleanSortValue = (bool?: boolean) => (bool === undefined ? -1 : bool ? 1 : 0);

const sortableCheckboxColumn = ({
  dataIndex,
  title,
  rowKey,
  getRules,
  ...rest
}: ColumnType<any> & {
  rowKey: string;
  getRules?: (record: Obj) => Rule[];
}): ColumnType<any> => ({
  title,
  dataIndex,
  render: (_, record: Obj) => <CheckboxFormItem name={[record[rowKey], dataIndex]} rules={getRules?.(record)} />,
  sorter: (a: Obj, b: Obj) =>
    getOptionalBooleanSortValue(objGet(a, dataIndex)) - getOptionalBooleanSortValue(objGet(b, dataIndex)),
  sortDirections,
  ...rest,
});

export function TenantProductsTable({
  products,
  onProductsChange,
}: {
  products: TenantProductsDTO;
  onProductsChange: (newProducts: TenantProductsDTO) => void;
}) {
  const [refresh, setRefresh] = useState(true);
  const [form] = Form.useForm();

  const productsData = Object.entries(products).map(([productName, product]) => ({ ...product, productName }));

  const currentPreset = Object.entries(productsPresetTemplates).filter(([_presetKey, presetProducts]) =>
    Object.entries(Object.keys(form.getFieldsValue()).length > 0 ? form.getFieldsValue() : products).every(
      ([productKey, productObj]) =>
        Object.keys(PRODUCT_FULLY_DISABLED).every(
          (productObjKey) =>
            (presetProducts[productKey as TenantProductName] ?? PRODUCT_FULLY_DISABLED)[
              productObjKey as keyof TenantProductDTO
            ] === (productObj as TenantProductDTO)[productObjKey as keyof TenantProductDTO],
        ),
    ),
  )[0]?.[0];

  const columns: ColumnType<ProductRecord>[] = [
    sortableStringColumn({
      title: 'Product',
      dataIndex: 'productName',
    }),
    {
      title: 'Description',
      dataIndex: 'productName',
      render: (product: TenantProductName) => `${TenantProductDescriptions[product] || '-'}`,
    },
    sortableCheckboxColumn({
      width: 200,
      title: 'Purchased',
      dataIndex: 'purchased',
      rowKey: 'productName',
    }),
    sortableCheckboxColumn({
      width: 200,
      title: 'Product Enabled',
      dataIndex: 'productEnabled',
      rowKey: 'productName',
      getRules: (record) => [
        ({ getFieldValue }) => ({
          validator(_rule, value) {
            const backendEnabled = getFieldValue([record.productName, 'backendEnabled']);
            if (value && !backendEnabled) {
              return Promise.reject(new Error('Cannot enable product without backend'));
            }
            return Promise.resolve();
          },
        }),
        ({ getFieldValue }) => ({
          validator(_rule, value) {
            const dependencies = productDependencies[record.productName as TenantProductName];
            if (value && dependencies !== undefined) {
              const dependencyValues = dependencies.map((dep) => getFieldValue([dep, 'backendEnabled']));
              if (!dependencyValues.some(Boolean)) {
                return Promise.reject(new Error(`Missing dependencies: ${dependencies.join(' or ')}`));
              }
            }
            return Promise.resolve();
          },
        }),
      ],
    }),
    sortableCheckboxColumn({
      width: 200,
      title: 'Backend Enabled',
      dataIndex: 'backendEnabled',
      rowKey: 'productName',
    }),
    {
      title: '',
      dataIndex: 'productName',
      render: (productName: TenantProductName, record: TenantProductDTO) => (
        <FlexSpace>
          <Tooltip
            title={
              record.updatedAt
                ? `Last updated ${formatDate(record.updatedAt, true)} (UTC) by ${record.updatedBy}`
                : 'Never updated'
            }
            placement="left"
          >
            <InfoCircleOutlined />
          </Tooltip>
          <Tooltip title={`Search for ${productName} product in frontend code`} placement="left">
            <a
              href={`https://github.com/search?type=code&q=repo%3Arecurrency%2Ffrontend+TenantProductName.${productName}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="ghost" size="small">
                FE
              </Button>
            </a>
          </Tooltip>
          <Tooltip
            title={`Search for ${productName} product in backend code (may be empty as not all products have backend logic)`}
            placement="left"
          >
            <a
              href={`https://github.com/search?type=code&q=repo%3Arecurrency%2Fbackend+${productName}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="ghost" size="small">
                BE
              </Button>
            </a>
          </Tooltip>
        </FlexSpace>
      ),
    },
  ];

  const selectPreset = (preset: ProductsPreset) => {
    const presetTemplate = productsPresetTemplates[preset];
    const values = form.getFieldsValue();
    for (const key of Object.keys(values)) {
      if (key in presetTemplate) {
        values[key] = presetTemplate[key as TenantProductName];
      } else {
        values[key] = Object.fromEntries(Object.keys(values[key]).map((subKey) => [subKey, false]));
      }
    }
    form.setFieldsValue(values);
    setRefresh(!refresh);
  };

  return (
    <div
      className={css`
        .ant-form-item {
          margin-bottom: 0;
        }
      `}
    >
      <Form.Provider>
        <Form
          form={form}
          onChange={() => {
            form.validateFields();
            setRefresh(!refresh);
          }}
          onFinish={() => onProductsChange(form.getFieldsValue())}
          onFinishFailed={() =>
            notification.error({ message: 'Changes not saved, resolve all validation errors first.' })
          }
          initialValues={products}
        >
          <div
            className={css`
              margin-bottom: 8px;
              display: flex;
              gap: 8px;
            `}
          >
            <Dropdown
              overlay={
                <Menu>
                  {Object.keys(productsPresetTemplates).map((preset) => (
                    <Menu.Item onClick={() => selectPreset(preset as ProductsPreset)} key={preset}>
                      {preset}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button>
                {currentPreset ?? 'Select Preset'}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              onClick={() => {
                form.resetFields();
                setRefresh(!refresh);
              }}
            >
              <ReloadOutlined />
              Revert Changes
            </Button>
            <FlexSpacer />
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div>
          <Table columns={columns} data={productsData} rowKey="productName" />
        </Form>
      </Form.Provider>
    </div>
  );
}
