import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { EnvironmentOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { SalesOrderLineDTO } from '@recurrency/core-api-schema/dist/v2/salesOrderLines/index';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { AsyncMultiSelect, filterMultiSelectOptions } from 'components/AsyncSelect/AsyncMultiSelect';
import { SearchSelect } from 'components/AsyncSelect/SearchSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ButtonLink } from 'components/Links';
import { RadioGroup } from 'components/Radio';
import { ResultCount } from 'components/ResultCount';

import { optArrFromVal } from 'utils/array';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { splitIdNameStr, formatShipTo } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableStringColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortablePercentColumn,
  erpStatusColumn,
  asKeyOf,
} from 'utils/tables';

export const SalesOrderLinesTable = ({
  itemId,
  companyId,
  customerId,
  locationIds,
  orderStatus,
}: {
  itemId: string | undefined;
  companyId?: string;
  customerId?: string;
  locationIds?: string[];
  orderStatus?: ErpOrderStatus;
}) => {
  const columns: ColumnType<SalesOrderLineDTO>[] = [
    sortableIdColumn({
      title: 'Order No',
      dataIndex: asKeyOf<SalesOrderLineDTO>('orderNumber'),
      render: (orderNumber: string) => <Link to={routes.orders.orderDetails(orderNumber)}>{orderNumber}</Link>,
      sorter: true,
    }),
    erpStatusColumn(),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: asKeyOf<SalesOrderLineDTO>('orderDate'),
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableDateColumn({
      title: 'Required Date',
      dataIndex: asKeyOf<SalesOrderLineDTO>('requiredDate'),
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableIdColumn({
      title: 'Location',
      dataIndex: asKeyOf<SalesOrderLineDTO>('locationId'),
      render: (_, record: SalesOrderLineDTO) =>
        record.locationId ? `${record.locationId}: ${record.locationName}` : '-',
      sorter: true,
    }),
    !customerId
      ? sortableIdColumn({
          title: 'Customer',
          dataIndex: asKeyOf<SalesOrderLineDTO>('customerId'),
          render: (lineCustomerId: string, record: SalesOrderLineDTO) => (
            <Link to={routes.sales.customerDetails(lineCustomerId)}>
              {lineCustomerId}: {record.customerName}
            </Link>
          ),
          sorter: true,
        })
      : null,
    sortableIdColumn({
      title: 'Ship To',
      dataIndex: asKeyOf<SalesOrderLineDTO>('shipToId'),
      render: (_, record: SalesOrderLineDTO) =>
        record.shipToId ? formatShipTo(record.shipToId, record.shipToName) : '-',
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: asKeyOf<SalesOrderLineDTO>('quantityOrdered'),
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Qty Open',
      dataIndex: asKeyOf<SalesOrderLineDTO>('quantityOpen'),
      sorter: true,
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: asKeyOf<SalesOrderLineDTO>('unitOfMeasure'),
      sorter: true,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: asKeyOf<SalesOrderLineDTO>('unitPrice'),
      sorter: true,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: asKeyOf<SalesOrderLineDTO>('unitCost'),
      sorter: true,
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: asKeyOf<SalesOrderLineDTO>('grossMarginPercent'),
      sorter: true,
    }),
    {
      render: ({ orderNumber }: { orderNumber: string }) => ButtonLink(routes.orders.orderDetails(orderNumber)),
    },
  ].filter(filterCostAndGM);

  const locationsSelectProps = useLocationsSelectProps({});
  const [status, setStatus] = useState<ErpOrderStatus | undefined>(orderStatus);
  const [filteredCustomerId, setFilteredCustomerId] = useState<string | undefined>(customerId);
  const [filteredShipToId, setFilteredShipToId] = useState<string>();
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [filteredDate, setFilteredDate] = useState<DateFilter>(getDefaultDateFilter);

  const tableProps = useCoreApiTableProps({
    schema: schemas.salesOrderLines.getSalesOrderLinesV2,
    queryParams: {
      filter: {
        status,
        itemId,
        companyIds: optArrFromVal(companyId),
        customerId: filteredCustomerId,
        shipToId: filteredShipToId,
        locationIds: filteredLocationIds.length > 0 ? filteredLocationIds : locationIds,
        orderDateFrom: formatMomentDateToSqlDate(filteredDate.from),
        orderDateTo: formatMomentDateToSqlDate(filteredDate.to),
      },
    },
  });

  const { setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredCustomerId, filteredShipToId, itemId, status, setPage]);

  return (
    <>
      <FilterBarBox>
        <RadioGroup value={status} onChange={({ target: { value } }) => setStatus(value)}>
          <Radio.Button value={undefined}>All</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Open}>Open</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Completed}>Completed</Radio.Button>
        </RadioGroup>
        {(!locationIds || locationIds.length > 1) && (
          <AsyncMultiSelect
            selectProps={filterMultiSelectOptions(convertToMultiSelectProps(locationsSelectProps), locationIds)}
            label="Location"
            queryPlaceholder="Search locations"
            selectedValues={filteredLocationIds}
            onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
            icon={<EnvironmentOutlined />}
          />
        )}
        {!customerId && (
          <>
            <SearchSelect
              searchParams={{ searchIndex: SearchIndexName.Customers }}
              className={tableFilterSelectClassName}
              entityPlural="customers"
              variant={AsyncSelectVariant.Filter}
              onSelect={(_, option) => setFilteredCustomerId(splitIdNameStr(option.value).foreignId)}
              onClear={() => setFilteredCustomerId(undefined)}
              allowClear
              size="small"
            />
            <SearchSelect
              searchParams={{ searchIndex: SearchIndexName.ShipTos }}
              className={tableFilterSelectClassName}
              entityPlural="ship tos"
              variant={AsyncSelectVariant.Filter}
              onSelect={(_, option) => setFilteredShipToId(splitIdNameStr(option.value).foreignId)}
              onClear={() => setFilteredShipToId(undefined)}
              allowClear
              size="small"
            />
          </>
        )}
        <FlexSpacer />
        <DateRangePicker
          value={[filteredDate.from, filteredDate.to]}
          onChange={(values) => setFilteredDate(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={columns} />
    </>
  );
};
