import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import { PurchaseOrderLinesTable } from 'components/recipes/PurchaseOrderLinesTable';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';
import { DEFAULT_PAGE_SIZE } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { optArrFromVal } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { formatDateShorthand, formatErpOrderStatus, formatUSD } from 'utils/formatting';

import { QtyByMonthSparkline } from './QtyByMonthSparkline';

export function PurchaseOrderLinesPanelContent({
  itemId,
  companyId,
  vendorId,
  locationIds,
}: {
  itemId: string;
  companyId?: string;
  vendorId?: string;
  locationIds?: string[];
}) {
  const { data, isLoading, error } = useCoreApi(schemas.purchaseOrderLines.getPurchaseOrderLinesV2, {
    queryParams: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      filter: { itemId, companyIds: optArrFromVal(companyId), vendorId, locationIds },
    },
  });

  return (
    <>
      <QtyByMonthSparkline
        data={data?.aggs}
        dateField="orderDate"
        quantityField="quantityOrdered"
        seriesTitle="Qty Ordered"
      />
      <MiniTable
        numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
        data={data?.items}
        columns={[
          { render: (record) => formatDateShorthand(record.orderDate), width: '90px' },
          { render: (record) => formatErpOrderStatus(record.status), width: '100px' },
          { render: (record) => formatUSD(record.unitPrice, true), align: `right` },
          { render: (record) => `${record.quantityOrdered} ${record.unitOfMeasure || ``}`, align: `right` },
        ]}
        isLoading={isLoading}
        error={error}
        onMoreButtonClick={() => {
          showAsyncModal(ViewAllModal, {
            title: `Purchase Lines for Item #${itemId}${vendorId ? ` from Vendor #${vendorId}` : ''}`,
            content: (
              <PurchaseOrderLinesTable
                itemId={itemId}
                companyId={companyId}
                vendorId={vendorId}
                locationIds={locationIds}
              />
            ),
          });
        }}
      />
    </>
  );
}
