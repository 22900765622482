import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import {
  TenantSettingFieldType,
  TenantSettingNumberUnits,
  TenantSettingsField,
} from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';
import { FormInstance } from 'antd';
import { colors } from 'theme/colors';

import { Badge } from 'components/Badge';
import { CheckboxFormItem, InputFormItem, SelectFormItem } from 'components/FormItems';
import { Props as InputFormItemProps } from 'components/FormItems/InputFormItem';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { validateNumberMaximumInclusive, validateNumberMinimumInclusive } from 'utils/validation';

import { SettingsFieldNumberGrid } from '../../pages/admin/SettingsPage/SettingsFieldNumberGrid';

export function SettingsField({
  field,
  form,
  allowClear = false,
}: {
  field: TenantSettingsField;
  form: FormInstance;
  allowClear?: boolean;
}) {
  const { activeTenant } = useGlobalApp();
  const name = field.key;
  const titleElement = (
    <div id={field.title.toLowerCase().replaceAll(' ', '-')}>
      {field.title}
      {field.helpCenterUrl && (
        <a
          className={css`
            margin-left: 4px;
          `}
          href={field.helpCenterUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <QuestionCircleOutlined />
        </a>
      )}
      {field.internalOnly && (
        <Tooltip
          className={css`
            margin-left: 4px;
          `}
          title="Only visible to Recurrency team"
        >
          <Badge variant="danger" label="Internal" />
        </Tooltip>
      )}
    </div>
  );
  const descriptionElement = (
    <div
      className={css`
        color: ${colors.neutral[500]};
        font-weight: normal;
      `}
    >
      {field.description}
    </div>
  );
  const labelElement = (
    <div>
      {titleElement}
      {descriptionElement}
    </div>
  );

  switch (field.type) {
    case TenantSettingFieldType.Boolean:
      return (
        <div>
          {/* Separating out the title so it's not clickable */}
          <div className="ant-form-item-label">{titleElement}</div>
          <CheckboxFormItem
            name={name}
            checkboxLabel={descriptionElement}
            className={css`
              margin-bottom: 28px;
              > div > div {
                // Prevent one line checkbox labels from having more padding than multi line ones
                min-height: 0;
              }
            `}
          />
        </div>
      );
    case TenantSettingFieldType.Select: {
      const filteredOptions = field.options.filter(
        (option) => !option.erpTypes || option.erpTypes.includes(activeTenant.erpType),
      );
      return (
        <SelectFormItem
          name={name}
          label={labelElement}
          allowClear={allowClear}
          placeholder="Select an option"
          options={filteredOptions.map((option) => ({
            value: option.value,
            label: option.label ?? option.value,
          }))}
          rules={[{ type: 'enum', enum: filteredOptions.map((option) => option.value) }]}
        />
      );
    }
    case TenantSettingFieldType.String:
      return <InputFormItem name={name} label={labelElement} allowClear={allowClear} />;
    case TenantSettingFieldType.Number: {
      const additionalProps = field.units ? NumberUnitsConfiguration[field.units] : {};
      return (
        <InputFormItem
          name={name}
          label={labelElement}
          allowClear={allowClear}
          type="number"
          min={field.min}
          max={field.max}
          rules={[
            { validator: (rule, value) => validateNumberMinimumInclusive(rule, value, field.min) },
            { validator: (rule, value) => validateNumberMaximumInclusive(rule, value, field.max) },
          ]}
          step={0.1}
          {...additionalProps}
        />
      );
    }
    case TenantSettingFieldType.NumberGrid:
      return (
        <div>
          {labelElement}
          <SettingsFieldNumberGrid field={field} form={form} />
        </div>
      );
    default:
      return <div>Field not rendered because it is an unrecognized type</div>;
  }
}

const NumberUnitsConfiguration: Record<TenantSettingNumberUnits, Partial<InputFormItemProps>> = {
  [TenantSettingNumberUnits.Dollars]: {
    prefix: '$',
    step: 0.01,
  },
  [TenantSettingNumberUnits.Percent]: {
    suffix: '%',
    step: 1,
  },
};
