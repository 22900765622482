import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { GetInventoryDashboardQueryParams } from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import { InventoryStatusResultDTO } from '@recurrency/core-api-schema/dist/inventory/inventoryDashboardResultDTO';
import { theme } from 'theme';

import { Card, CardSection } from 'components/Card';
import { FlexSpace } from 'components/FlexSpace';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

import { formatNumber, formatUSD } from 'utils/formatting';

import { InventoryDashboardStatusHashState } from 'types/hash-state';

export function KPIBar({
  hashState,
  apiQueryParams,
  showRecommendedMaxVariant,
}: {
  hashState: InventoryDashboardStatusHashState;
  apiQueryParams: GetInventoryDashboardQueryParams;
  showRecommendedMaxVariant?: boolean;
}) {
  // Status Totals
  const {
    isLoading: breakdownDataLoading,
    data: breakdownData,
    error: breakdownDataError,
  } = useCoreApi(schemas.inventory.getInventoryDashboardStatusBreakdown, {
    queryParams: {
      ...apiQueryParams,
      limit: 10,
      offset: 0,
      groupBy: undefined,
    },
  });

  const viewValue = () => hashState.displayUnit === 'value';
  const statusData = (statusField: string): number => {
    const apiData = breakdownData?.items ?? [];
    const totalsData = (apiData[0] ?? {}) as Omit<InventoryStatusResultDTO, 'current' | 'netStock' | 'id'>;
    const key = statusField as keyof typeof totalsData;
    const keyData = totalsData[key] ?? { value: 0, volume: 0 };
    return viewValue() ? keyData.value : keyData.volume;
  };

  const formatTotals = (total?: number) => (hashState.displayUnit === 'value' ? formatUSD(total) : formatNumber(total));

  const KPICard = ({ label, value, isLoading }: { label: string; value: string; isLoading: boolean }) => (
    <Card
      className={css`
        min-width: 140px;
        width: 100%;
        border-color: ${theme.colors.neutral[300]};
      `}
    >
      <CardSection
        className={css`
          padding: 10px 16px 10px;
        `}
      >
        <Typography type="subtitle">{label}</Typography>
        <Typography type="middle" style={{ fontWeight: 500 }}>
          {isLoading ? <LoadingOutlined /> : value}
        </Typography>
      </CardSection>
    </Card>
  );

  if (breakdownDataError) {
    return <></>;
  }

  return (
    <FlexSpace
      gap={8}
      className={css`
        margin-bottom: 20px;
      `}
    >
      <KPICard
        label="Normal Stock"
        value={formatTotals(statusData(showRecommendedMaxVariant ? 'recommendedNormalstock' : 'normalstock'))}
        isLoading={breakdownDataLoading}
      />
      <KPICard
        label="Overstock"
        value={formatTotals(statusData(showRecommendedMaxVariant ? 'recommendedOverstock' : 'overstock'))}
        isLoading={breakdownDataLoading}
      />
      <KPICard label="Dead Stock" value={formatTotals(statusData('deadstock'))} isLoading={breakdownDataLoading} />
      <KPICard label="On Hand" value={formatTotals(statusData('onHand'))} isLoading={breakdownDataLoading} />
      <KPICard label="Allocated" value={formatTotals(statusData('allocated'))} isLoading={breakdownDataLoading} />
      <KPICard
        label="Available"
        value={formatTotals(statusData('onHand') - statusData('allocated'))}
        isLoading={breakdownDataLoading}
      />
    </FlexSpace>
  );
}
