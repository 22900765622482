import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import { ParsedInput } from 'components/Input/ParsedInput';
import { NetStockPopover } from 'components/recipes/equation/NetStockPopover';
import { PurchaseTargetGroupNameToolTip } from 'components/recipes/PurchaseTargetGroupNameToolTip';
import { TargetLineStatusBadge } from 'components/recipes/TargetLineStatusBadge';
import { SplitPage } from 'components/SplitPage';
import { Table } from 'components/Table';

import { useEventListener } from 'hooks/useEventListener';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD, joinIdNameObj } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import {
  sortDirections,
  sortableDollarColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortableStringColumn,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { floatOrDefault } from 'utils/units';

import { PurchaseTargetLinesHashState, SupplierLocationWTarget } from 'types/hash-state';

import { GenericAutoTrimFillTableRow, POSummary } from '../../types';
import { ItemDetailsSidePane } from '../sidePane/ItemDetailsSidePane';
import { POSummarySidePaneSidePane } from '../sidePane/POSummarySidePane';
import { focusedRowClassName } from '../utils';

export function PurchaseAutoTargetFillTrimTable({
  filteredLines,
  poSummaries,
  lineStatusCounts,
  locations,
  selectedLineKeys,
  isHubAndSpoke,
  emptyView,
  onRowSelectionChange,
  onOrderQtyChange,
}: {
  filteredLines: GenericAutoTrimFillTableRow[];
  poSummaries: POSummary[];
  lineStatusCounts: Record<PurchaseTargetLineStatus, number>;
  selectedLineKeys: string[];
  isHubAndSpoke: boolean;
  emptyView?: React.ReactNode;
  locations: SupplierLocationWTarget[];
  onRowSelectionChange: (newSelectedRowKeys: string[]) => void;
  onOrderQtyChange: (value: number, record: GenericAutoTrimFillTableRow) => void;
}) {
  const { activeTenant } = useGlobalApp();
  const [focusedLine, setFocusedLine] = useState<GenericAutoTrimFillTableRow | undefined>();
  const [hashState] = useHashState<PurchaseTargetLinesHashState>();

  const suppliers = useMemo(
    () => locations.map((loc) => joinIdNameObj({ foreignId: loc.supplierId, name: loc.supplierName })),
    [locations],
  );

  // clear focused line item when clicking outside of lines table and side pane
  useEventListener(
    document.body,
    'click',
    (ev) => {
      const lineInfoEls = Array.from(
        document.querySelectorAll(
          '.ant-table, div.SidePane, div.ant-modal-wrap:not(.purchase-auto-target-fill-trim-modal)',
        ),
      );
      if (lineInfoEls.every((el) => !el.contains(ev.target as Node))) {
        setFocusedLine(undefined);
      }
    },
    {
      // evaluate at capture (instead of bubbling phase) so if a component stops event propagation
      // we still know where the user clicked
      capture: true,
    },
  );
  const tableColumns: ColumnType<GenericAutoTrimFillTableRow>[] = [
    sortableIdColumn({
      title: 'Item',
      dataIndex: 'itemId',
      width: '300px',
      fixed: true,
      render: (_: string, record: GenericAutoTrimFillTableRow) => (
        <div>
          <a href={routes.purchasing.itemDetails(record.itemId)} target="_blank" rel="noreferrer">
            {record.itemId}
          </a>
          <div>{record.itemName}</div>
        </div>
      ),
    }),
    isHubAndSpoke && {
      title: 'Group Name',
      dataIndex: 'groupName',
      width: `200px`,
      fixed: true,
      render: (name: string, record: GenericAutoTrimFillTableRow) => (
        <PurchaseTargetGroupNameToolTip
          groupName={name}
          purchaseLocationId={record.purchaseLocationId}
          purchaseLocationName={record.purchaseLocationName}
          spokeLocations={record.spokeLocations}
        />
      ),
    },
    !isHubAndSpoke && locations.length > 1
      ? sortableIdColumn({
          title: 'Location',
          dataIndex: 'locationId',
          width: `200px`,
          fixed: true,
          render: (_: string, record: GenericAutoTrimFillTableRow) => (
            <div>
              <div>{record.locationId}</div>
              <div>{record.locationName}</div>
            </div>
          ),
        })
      : null,
    {
      title: 'Quantity to Order',
      children: [
        sortableNumberColumn({
          title: 'Current',
          dataIndex: 'currentQtyToOrder',
          align: 'right' as const,
          render: (value: number, record: GenericAutoTrimFillTableRow) => (
            <>
              {formatNumber(value)} {record.unitOfMeasure}
            </>
          ),
        }),
        sortableNumberColumn({
          title: 'Recommended Change',
          dataIndex: 'recommendedQtyToOrder',
          align: 'right' as const,
          render: (value: number, record: GenericAutoTrimFillTableRow) => {
            const qtyChanged = (record.recommendedQtyToOrder ?? 0) - record.currentQtyToOrder;
            return `${qtyChanged > 0 ? '+' : ''}${qtyChanged}`;
          },
        }),
        {
          title: 'New',
          width: '140px',
          align: 'right' as const,
          render: (_: number, record: GenericAutoTrimFillTableRow) => (
            <ParsedInput<number>
              size="small"
              valueParser={(value) => floatOrDefault(value, 0)}
              value={record.userQtyToOrder}
              onValueChange={(value: number) => onOrderQtyChange(value, record)}
            />
          ),
        },
      ].filter(truthy),
    },
    isTenantErpTypeP21(activeTenant.erpType) &&
      sortableStringColumn({
        title: 'ABC Class',
        dataIndex: 'purchaseClass',
        width: '80px',
      }),
    sortableNumberColumn({
      title: 'Net Stock',
      dataIndex: 'netStock',
      align: 'right' as const,
      render: (value: number, record: GenericAutoTrimFillTableRow) => (
        <>
          {formatNumber(value)} {record.unitOfMeasure}
          <NetStockPopover record={record} />
        </>
      ),
    }),
    sortableNumberColumn({
      title: 'Days of Stock',
      dataIndex: 'projectedDaysOfSupply',
      align: 'right' as const,
      render: (value: number, record: GenericAutoTrimFillTableRow) => {
        if (value == null && record.daysOfSupply == null) {
          return '-';
        }
        return `${formatNumber(record.daysOfSupply)} -> ${formatNumber(value)}`;
      },
    }),
    sortableDollarColumn({
      title: 'Unit Cost',
      dataIndex: 'userUnitCost',
      width: '110px',
      align: 'right' as const,
      render: (userUnitCost: number) => formatUSD(userUnitCost, true, 2),
    }),
    {
      title: 'Ext Cost',
      align: 'right' as const,
      dataIndex: 'extCost',
      sorter: (a: GenericAutoTrimFillTableRow, b: GenericAutoTrimFillTableRow) =>
        a.userQtyToOrder * a.userUnitCost - b.userQtyToOrder * b.userUnitCost,
      sortDirections,
      render: (_: null, record: GenericAutoTrimFillTableRow) =>
        formatUSD(record.userQtyToOrder * record.userUnitCost, true, 2),
    },
    {
      title: 'Current Status',
      dataIndex: 'status',
      render: (status: PurchaseTargetLineStatus) => <TargetLineStatusBadge status={status} />,
    },
  ].filter(truthy);

  return (
    <SplitPage
      left={
        emptyView != null ? (
          emptyView
        ) : (
          <Table
            className={css`
              tr.ant-table-row.focused-row > td:first-child {
                border-left-style: solid;
                border-left-color: ${theme.colors.primary[600]};
              }
            `}
            columns={tableColumns}
            data={filteredLines}
            size="small"
            rowSelection={{
              preserveSelectedRowKeys: true,
              selectedRowKeys: selectedLineKeys,
              onChange: onRowSelectionChange,
            }}
            rowClassName={(record: GenericAutoTrimFillTableRow) => focusedRowClassName(record, focusedLine)}
            onRow={(record: GenericAutoTrimFillTableRow) => ({
              onClick: () => setFocusedLine(record),
            })}
            pageSize={10}
          />
        )
      }
      right={
        focusedLine ? (
          <ItemDetailsSidePane
            itemInfo={focusedLine}
            isHubAndSpoke={isHubAndSpoke}
            purchaseGroup={hashState.purchaseGroups?.find((g) => g.groupId === focusedLine.groupId)}
          />
        ) : (
          <POSummarySidePaneSidePane
            lineStatusCounts={lineStatusCounts}
            poSummaries={poSummaries}
            uniqueSuppliers={suppliers}
          />
        )
      }
    />
  );
}
