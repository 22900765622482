import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  RecommendedQuoteDTO,
  RecommendedQuoteLineDTO,
  GetRecommendedQuotesQueryParams,
  WorkflowState,
} from '@recurrency/core-api-schema/dist/ml/getRecommendedQuotes';
import { notification } from 'antd';
import useDebounce from 'use-debounce/lib/useDebounce';
import { ZipCelXSheet } from 'zipcelx';

import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { CenteredError } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from 'components/recipes/DownloadButton';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { formatNumber, joinIdNameObj } from 'utils/formatting';
import { track, TrackEvent } from 'utils/track';

import { RecItemsColumn } from './RecItemsColumn';
import { ShipToSelectColumn } from './ShipToSelectColumn';
import { workflowStateName, getRecReasonText } from './utils';

export function OpportunitiesPage() {
  const [selectedRecQuote, setSelectedRecQuote] = useState<RecommendedQuoteDTO | undefined>();
  const [selectedLineItems, setSelectedLineItems] = useState<RecommendedQuoteLineDTO[]>([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const apiQueryParams: GetRecommendedQuotesQueryParams = {
    searchQuery: debouncedSearchQuery,
    filter: {},
    limit: 100,
  };

  const { data: recommendedQuotesData, error } = useCoreApi(schemas.ml.getRecommendedQuotes, {
    queryParams: apiQueryParams,
  });

  useEffect(() => {
    if (!selectedRecQuote && recommendedQuotesData && recommendedQuotesData.items.length > 0) {
      setSelectedRecQuote(recommendedQuotesData.items[0].quotes[0]);
    }
  }, [selectedRecQuote, recommendedQuotesData]);

  const [refresh, setRefresh] = useState(false);
  // NOTE: hacky prototypey code to set workflow state, will be reworked with api call
  const setLineItemWorkflowState = (
    lineItem: RecommendedQuoteLineDTO,
    workflowState: WorkflowState,
    _reason?: string,
  ) => {
    lineItem.workflowState = workflowState;
    notification.info({ message: `Item #${lineItem.itemId} moved to ${workflowStateName[workflowState]}` });
    setRefresh(!refresh); // hack to refresh UI
  };

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip title="Opportunities leverages our intelligence platform to recommend reorders throughout the week. It suggests not only which customers to talk to but also which specific items to consider selling to them.">
            Opportunities
          </InfoTooltip>
        }
        headerActions={
          <DownloadButton recordType="Opportunities" getDownloadData={() => getDownloadData(apiQueryParams)} />
        }
      />
      <DividerLine />
      <div
        className={css`
          display: flex;
          flex-direction: row;
          gap: 20px;
        `}
      >
        <ShipToSelectColumn
          recQuotes={recommendedQuotesData}
          selectedRecQuote={selectedRecQuote}
          onSelectedRecQuoteChange={(recQuote) => {
            setSelectedRecQuote(recQuote);
            setSelectedLineItems([]); // clear selected line items when changing ship to
            track(TrackEvent.RecommendedQuotes_ShipToList_SelectRow, {
              customer: joinIdNameObj({ foreignId: recQuote.customerId, name: recQuote.customerName }),
              shipTo: joinIdNameObj({ foreignId: recQuote.shipToId, name: recQuote.shipToName || '' }),
              potentialValue: recQuote.potentialValue,
              numLineItems: recQuote.numLineItems,
            });
          }}
          searchQuery={searchQuery}
          onSearchQueryChange={setSearchQuery}
        />
        <RecItemsColumn
          selectedRecQuote={selectedRecQuote}
          selectedLineItems={selectedLineItems}
          onSelectedLineItemsChange={setSelectedLineItems}
          onLineItemWorkflowStateChange={setLineItemWorkflowState}
        />
      </div>
    </Container>
  );
}

async function getDownloadData(apiQueryParams: GetRecommendedQuotesQueryParams): Promise<ZipCelXSheet> {
  const response = await coreApiFetch(schemas.ml.getRecommendedQuotes, {
    queryParams: {
      ...apiQueryParams,
      // override pagination for download
      offset: 0,
      limit: 1000,
    },
  });

  const recQuoteLines = response.data.items
    .map((customer) =>
      customer.quotes.map((shipTo) =>
        shipTo.lineItems.map((lineItem) => ({
          customerId: customer.customerId,
          customerName: customer.customerName,
          shipToId: shipTo.shipToId,
          shipToName: shipTo.shipToName,
          shipToAddress: shipTo.shipToAddress,
          ...lineItem,
        })),
      ),
    )
    .flat(2);

  const exportColumns: Array<DownloadXSheetColumn<typeof recQuoteLines[number]>> = [
    { title: 'Customer ID', type: 'string', value: (record) => record.customerId },
    { title: 'Customer Name', type: 'string', value: (record) => record.customerName },
    { title: 'ShipTo ID', type: 'string', value: (record) => record.shipToId },
    { title: 'ShipTo Name', type: 'string', value: (record) => record.shipToName || `` },
    { title: 'ShipTo Address', type: 'string', value: (record) => record.shipToAddress || `` },
    { title: 'Location ID', type: 'string', value: (record) => record.lastLocationId },
    { title: 'Item ID', type: 'string', value: (record) => record.itemId || `` },
    { title: 'Item Name', type: 'string', value: (record) => record.itemName || `` },
    { title: 'Reason', type: 'string', value: (record) => getRecReasonText(record) },
    { title: 'Last Order No', type: 'string', value: (record) => record.lastOrderId },
    {
      title: 'Last Order Date',
      type: 'string',
      value: (record) => new Date(record.lastOrderDate).toLocaleDateString(),
    },
    { title: 'Qty', type: 'number', value: (record) => formatNumber(record.lastQty / record.lastUnitSize) },
    { title: 'UOM', type: 'string', value: (record) => record.unitOfMeasure || `` },
    { title: 'Unit Price', type: 'number', value: (record) => record.lastUnitPrice },
    { title: 'Ext. Price', type: 'number', value: (record) => record.lastExtPrice },
  ];

  return recordsToXSheet(recQuoteLines, exportColumns);
}
