import React from 'react';

import { FundOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { theme } from 'theme';

export function ExploreButton() {
  return (
    <div
      className={css`
        color: ${theme.colors.primary[500]};
        font-weight: 500;
      `}
    >
      <FundOutlined style={{ color: theme.colors.primary[500] }} /> Explore
    </div>
  );
}
