import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { EnvironmentOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { InvoiceLineDTO } from '@recurrency/core-api-schema/dist/v2/invoiceLines';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { AsyncMultiSelect, filterMultiSelectOptions } from 'components/AsyncSelect/AsyncMultiSelect';
import { SearchSelect } from 'components/AsyncSelect/SearchSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ResultCount } from 'components/ResultCount';

import { optArrFromVal } from 'utils/array';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatId, formatUSD, splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableIdColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortableNumberColumn,
  sortablePercentColumn,
  sortableStringColumn,
} from 'utils/tables';

export const SalesInvoiceLinesTable = ({
  itemId,
  companyId,
  customerId,
  shipToId,
  locationIds,
}: {
  itemId?: string;
  companyId?: string;
  customerId?: string;
  shipToId?: string;
  locationIds?: string[];
}) => {
  const locationsSelectProps = useLocationsSelectProps({ companyIds: optArrFromVal(companyId) });
  const [filteredCustomerId, setFilteredCustomerId] = useState<string | undefined>(customerId);
  const [filteredItemId, setFilteredItemId] = useState<string | undefined>(itemId);
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [filteredShipToId, setFilteredShipToId] = useState<string | undefined>(shipToId);
  const [filteredDate, setFilteredDate] = useState<DateFilter>(getDefaultDateFilter);

  const tableProps = useCoreApiTableProps({
    schema: schemas.salesInvoiceLines.getSalesInvoiceLinesV2,
    queryParams: {
      filter: {
        itemId: filteredItemId,
        companyIds: optArrFromVal(companyId),
        customerId: filteredCustomerId,
        shipToId: filteredShipToId,
        locationIds: filteredLocationIds.length > 0 ? filteredLocationIds : locationIds,
        invoiceDateFrom: formatMomentDateToSqlDate(filteredDate.from),
        invoiceDateTo: formatMomentDateToSqlDate(filteredDate.to),
      },
    },
  });

  const { setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredCustomerId, filteredItemId, filteredLocationIds, filteredShipToId, setPage]);

  const tableColumns: (ColumnType<InvoiceLineDTO> | null)[] = [
    sortableIdColumn({
      title: 'Invoice No',
      dataIndex: 'invoiceNumber',
      sorter: true,
    }),
    sortableDateColumn({
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    {
      title: 'Invoice Type',
      dataIndex: 'lineSource',
    },
    sortableNumberColumn({
      title: 'Order No',
      dataIndex: 'orderNumber',
      render: (orderNumber?: string) =>
        orderNumber ? <Link to={routes.orders.orderDetails(orderNumber)}>{formatId(orderNumber)}</Link> : null,
      sorter: true,
    }),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: 'orderDate',
      sorter: true,
    }),
    sortableIdColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record: InvoiceLineDTO) => (record.locationId ? `${record.locationId}: ${record.locationName}` : '-'),
      sorter: true,
    }),
    // If customerId is passed in, then we don't show 'Customer' column
    !customerId
      ? sortableIdColumn({
          title: 'Customer',
          dataIndex: 'customerId',
          render: (_, record: InvoiceLineDTO) => (
            <Link to={routes.sales.customerDetails(record.customerId)}>
              {record.customerId}: {record.customerName}
            </Link>
          ),
          sorter: true,
        })
      : null,
    // If itemId is passed in, then we don't show 'Item' column
    !itemId
      ? sortableIdColumn({
          title: 'Item',
          dataIndex: 'itemCode',
          render: (_, record: InvoiceLineDTO) =>
            record.itemCode ? (
              <Link to={routes.sales.itemDetails(record.itemCode)}>
                {record.itemCode}: {record.itemName}
              </Link>
            ) : null,
          sorter: true,
        })
      : null,
    sortableNumberColumn({
      title: 'Qty Shipped',
      dataIndex: 'quantityShipped',
      sorter: true,
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      sorter: true,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      sorter: true,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      sorter: true,
      render: (unitCost: number) => formatUSD(unitCost, true),
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Price',
      dataIndex: 'lineTotal',
      sorter: true,
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: 'grossMarginPercent',
      sorter: true,
    }),
  ];

  return (
    <>
      <FilterBarBox>
        {(!locationIds || locationIds.length > 1) && (
          <AsyncMultiSelect
            selectProps={filterMultiSelectOptions(convertToMultiSelectProps(locationsSelectProps), locationIds)}
            label="Location"
            queryPlaceholder="Search locations"
            selectedValues={filteredLocationIds}
            onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
            icon={<EnvironmentOutlined />}
          />
        )}
        {!customerId && (
          <>
            <SearchSelect
              searchParams={{ searchIndex: SearchIndexName.Customers, selectParams: { companyId } }}
              className={tableFilterSelectClassName}
              entityPlural="customers"
              variant={AsyncSelectVariant.Filter}
              onSelect={(_, option) => setFilteredCustomerId(splitIdNameStr(option.value).foreignId)}
              onClear={() => setFilteredCustomerId(undefined)}
              allowClear
              size="small"
            />
            <SearchSelect
              searchParams={{ searchIndex: SearchIndexName.ShipTos, selectParams: { companyId } }}
              className={tableFilterSelectClassName}
              entityPlural="ship tos"
              variant={AsyncSelectVariant.Filter}
              onSelect={(_, option) => setFilteredShipToId(splitIdNameStr(option.value).foreignId)}
              onClear={() => setFilteredShipToId(undefined)}
              allowClear
              size="small"
            />
          </>
        )}
        {!itemId && (
          <SearchSelect
            searchParams={{ searchIndex: SearchIndexName.Items }}
            className={tableFilterSelectClassName}
            entityPlural="items"
            variant={AsyncSelectVariant.Filter}
            onSelect={(_, option) => setFilteredItemId(splitIdNameStr(option.value).foreignId)}
            onClear={() => setFilteredItemId(undefined)}
            allowClear
            size="small"
          />
        )}
        <FlexSpacer />
        <DateRangePicker
          value={[filteredDate.from, filteredDate.to]}
          onChange={(values) => setFilteredDate(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={tableColumns.filter(filterCostAndGM)} />
    </>
  );
};
