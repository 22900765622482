import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowProduct } from 'utils/roleAndTenant';

import { OrderDetailsPageAgnostic } from './Agnostic/OrderDetailsPageAgnostic';
import { OrderDetailsPageSsfc } from './SSFC/OrderDetailsPageSsfc';

export const OrderDetailsPage = () => {
  const { activeTenant } = useGlobalApp();

  if (shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup)) {
    return OrderDetailsPageSsfc();
  }
  return OrderDetailsPageAgnostic();
};
