import React from 'react';

import { Empty, EmptyProps } from 'antd';

import { styled } from 'utils/styledComponent';

const NoDataMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoDataMessage = (props: EmptyProps) => (
  <NoDataMessageContainer>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...props} />
  </NoDataMessageContainer>
);
