import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag, UnitCostSource } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { ItemAvailabilityDTO } from '@recurrency/core-api-schema/dist/v2/items/getItemAvailability';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatNumber, formatUSD } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import {
  sortableNumberColumn,
  sortableDollarWithCentsColumn,
  sortableIdColumn,
  sortableStringColumn,
  asKeyOf,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { unitCostSettingLabelMap } from '../common';

export const AvailabilityTable = ({ itemUid }: { itemUid: string }) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const shouldShowQtyInVessel = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesItemAvailabilityWithQtyOnVessel,
  );

  const isErpTypeP21 = isTenantErpTypeP21(activeTenant.erpType);

  const { data: settings } = useCoreApi(schemas.tenantSettings.getTenantSettings, {
    queryParams: { settingKeys: [TenantSettingKey.CalculationInventoryUnitCost] },
  });

  const columns: (ColumnType<ItemAvailabilityDTO> | null)[] = [
    sortableIdColumn({
      title: 'Location',
      dataIndex: asKeyOf<ItemAvailabilityDTO>('locationId'),
      render: (_, record: ItemAvailabilityDTO) => `${record.locationId}: ${record.locationName}`,
    }),
    isErpTypeP21
      ? sortableStringColumn({
          title: 'Stockable',
          dataIndex: asKeyOf<ItemAvailabilityDTO>('stockable'),
          render: (value: string) => (value === 'Y' ? <CheckOutlined /> : null),
        })
      : null,
    isErpTypeP21
      ? sortableStringColumn({
          title: 'Product Group',
          dataIndex: asKeyOf<ItemAvailabilityDTO>('itemGroupName'),
          render: (_, record: ItemAvailabilityDTO) => `${record.itemGroupName} #${record.itemGroupId}`,
        })
      : null,
    sortableStringColumn({
      title: 'UOM',
      dataIndex: asKeyOf<ItemAvailabilityDTO>('inventoryUnitOfMeasure'),
    }),
    sortableNumberColumn({
      title: 'Qty on Hand',
      dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyOnHand'),
      render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyOnHand ?? 0),
    }),
    sortableNumberColumn({
      title: isErpTypeP21 ? 'Qty Allocated' : 'Qty Committed',
      dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyAllocated'),
      render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyAllocated ?? 0),
    }),
    sortableNumberColumn({
      title: 'Qty Available',
      dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyAvailable'),
      render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyAvailable ?? 0),
    }),
    sortableNumberColumn({
      title: 'Qty on Order',
      dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyOnPurchaseOrder'),
      render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyOnPurchaseOrder ?? 0),
    }),
    isErpTypeP21
      ? sortableNumberColumn({
          title: 'Qty B/O',
          dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyBackordered'),
          render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyBackordered ?? 0),
        })
      : null,
    isErpTypeP21
      ? sortableNumberColumn({
          title: 'Qty in Process',
          dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyInProcess'),
          render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyInProcess ?? 0),
        })
      : null,
    isErpTypeP21
      ? sortableNumberColumn({
          title: 'Qty on Transfer',
          dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyInTransit'),
          render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyInTransit ?? 0),
        })
      : null,
    shouldShowQtyInVessel
      ? sortableNumberColumn({
          title: 'Qty on Vessel',
          dataIndex: asKeyOf<ItemAvailabilityDTO>('qtyOnVessel'),
          render: (_, record: ItemAvailabilityDTO) => formatNumber(record.qtyOnVessel ?? 0),
        })
      : null,
    sortableDollarWithCentsColumn({
      title: `${unitCostSettingLabelMap(
        settings?.settings['calculation.inventoryUnitCost'] ?? UnitCostSource.MovingAverage,
      )}`,
      dataIndex: asKeyOf<ItemAvailabilityDTO>('unitCost'),
      render: (unitCost: number, record: ItemAvailabilityDTO) =>
        formatUSD(unitCost === undefined ? record.averageUnitCost : unitCost, true),
    }),
  ];

  return (
    <AsyncTable
      tableProps={useCoreApiTableProps({
        // use getItemSalesAvailability endpoint since it gives us live qty data if available
        schema: schemas.items.getItemSalesAvailability,
        pathParams: { itemUid },
        queryParams: {
          withQuantityOnVessel: shouldShowQtyInVessel,
          // @ts-expect-error - undefined useCoreApiTableProps doesn't inject offset/limit
          offset: undefined,
          limit: undefined,
        },
        pageSize: 100, // show all locations, don't paginate
      })}
      columns={columns.filter(filterCostAndGM)}
      rowKey="locationId"
    />
  );
};
