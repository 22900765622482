import React, { useMemo } from 'react';

import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';

import { CollapsePanels } from 'components/recipes/sidePane/CollapsePanels';
import { SplitPage } from 'components/SplitPage';

import { getTenantSetting } from 'utils/tenantSettings';

import { PurchasingPOFinalizeInput, PurchasingTOFinalizeInput } from 'types/hash-state';

import { GenericTargetLineRow, GroupedPOSummary, TransferSummary } from '../../types';
import { getLineStatusCounts } from '../../utils';
import { POSummarySidePaneSidePane } from '../sidePane/POSummarySidePane';
import { PurchasingFinalizePurchaseOrdersSection } from './PurchasingFinalizePurchaseOrdersSection';
import { PurchasingFinalizeTransfersSection } from './PurchasingFinalizeTransfersSection';

export function PurchasingFinalizeStep({
  poSummaries,
  transferSummaries,
  poFinalizeInputByKey,
  toFinalizeInputByKey,
  onPOFinalizeInputChange,
  onTOFinalizeInputChange,
  uniqueSuppliers,
  targetLines,
}: {
  poSummaries: GroupedPOSummary[];
  transferSummaries: TransferSummary[];
  poFinalizeInputByKey: Obj<PurchasingPOFinalizeInput>;
  toFinalizeInputByKey: Obj<PurchasingTOFinalizeInput>;
  onPOFinalizeInputChange: (newPOFinalizeInputByKey: Obj<PurchasingPOFinalizeInput>) => void;
  onTOFinalizeInputChange: (newTOFinalizeInputByKey: Obj<PurchasingTOFinalizeInput>) => void;
  uniqueSuppliers: string[];
  targetLines: GenericTargetLineRow[];
}) {
  const lineStatusCounts = useMemo(() => getLineStatusCounts(targetLines), [targetLines]);

  const panels = [
    {
      title: 'Purchase Orders',
      content: (
        <PurchasingFinalizePurchaseOrdersSection
          finalizedPOSummaries={poSummaries}
          poFinalizeInputByKey={poFinalizeInputByKey}
          onPOFinalizeInputChange={onPOFinalizeInputChange}
        />
      ),
    },
    getTenantSetting(TenantSettingKey.FeatureCreateTransfers) && transferSummaries.length > 0
      ? {
          title: 'Transfers',
          content: (
            <PurchasingFinalizeTransfersSection
              transferSummaries={transferSummaries}
              toFinalizeInputByKey={toFinalizeInputByKey}
              onTOFinalizeInputChange={onTOFinalizeInputChange}
            />
          ),
        }
      : null,
  ];

  return (
    <SplitPage
      left={<CollapsePanels panels={panels} />}
      right={
        <POSummarySidePaneSidePane
          lineStatusCounts={lineStatusCounts}
          poSummaries={poSummaries}
          uniqueSuppliers={uniqueSuppliers}
        />
      }
    />
  );
}
