import React, { ReactNode } from 'react';

import { css } from '@emotion/css';
import { captureException as sentryCaptureException } from '@sentry/react';
import { notification } from 'antd';

import { track, TrackEvent } from './track';

export interface CaptureAndShowErrorOptions {
  /** Key to identify notification, so error can be closed after user clicks on NotificationLink */
  notificationKey?: string;
  /** Error description renderer function for custom formatting */
  renderDescription?: (errorDescription: string) => ReactNode;
  /** Duration in seconds. By default all errors are sticky, to allow users to read error and explicitly close notification. */
  duration?: number;
}

export function captureAndShowError(
  err: any,
  title: string,
  { notificationKey, renderDescription, duration = 0 }: CaptureAndShowErrorOptions = {},
) {
  const sentryEventId = captureError(err);
  track(TrackEvent.Components_Notification_ShowError, {
    notificationTitle: title,
    errorName: err.name,
    errorMessage: err.message,
    errorStack: err.stack || '',
    sentryUrl: getSentryUrl(sentryEventId),
  });

  const errorDescription =
    err instanceof HttpError && err.statusCode < 500
      ? err.message
      : `${err.message}.\nOur engineers have been notified. If error persists, contact zsupport@recurrency.com.`;

  notification.error({
    message: title,
    key: notificationKey,
    description: (
      <div
        className={css`
          white-space: pre-wrap;
        `}
      >
        {renderDescription ? renderDescription(errorDescription) : errorDescription}
      </div>
    ),
    duration,
  });
}

const capturedErrors = new Set<Error>();

/** Logs error to console + sentry and @returns sentryEventId */
export function captureError(error: any): string | undefined {
  // We capture every network error in api.ts, as well as other components.
  // To prevent same errors being double tracked, we check if the error is already captured.
  if (capturedErrors.has(error)) {
    return undefined;
  }
  capturedErrors.add(error);

  if (error instanceof HttpError) {
    // merge details into message, for more detailed error messages in #frontend-sentry
    error = new HttpError(error.statusCode, [error.message, ...error.details].join('\n'), error.requestId);
  }

  console.error(error);
  return sentryCaptureException(error);
}

export function getSentryUrl(sentryEventId: string | null | undefined) {
  return sentryEventId ? `https://sentry.io/recurrency/frontend/events/${sentryEventId}` : undefined;
}

export class HttpError extends Error {
  public statusCode: number;
  public message: string;
  public details: string[];
  public requestId?: string;

  constructor(statusCode: number, message: string, requestId?: string) {
    super(message);
    this.statusCode = statusCode;
    this.message = message;
    this.details = [];
    this.requestId = requestId;
  }
}
