import React from 'react';

import { Link } from 'react-router-dom';

import { DeploymentUnitOutlined } from '@ant-design/icons';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SalesOrderDetailLineDTO } from '@recurrency/core-api-schema/dist/v2/salesOrders/getSalesOrderDetails';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';
import { Table } from 'components/Table';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { routes } from 'utils/routes';
import {
  sortableIdColumn,
  sortableNumberColumn,
  sortableStringColumn,
  sortableDollarWithCentsColumn,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { OrderAssemblyLinesAgnosticModal } from '../modals/OrderAssemblyLinesAgnosticModal';

export const OrderLinesAgnosticTable = ({ orderLines }: { orderLines: SalesOrderDetailLineDTO[] }) => {
  const { activeTenant } = useGlobalApp();

  const lineItemLineTypeMap: Obj<string> = {
    'special-order': 'Special Order',
    'production-order': 'Production Order',
    'drop-ship': 'Drop Ship',
    backorder: 'Backorder',
    hold: 'Hold',
    transfer: 'Transfer',
    'future-production-order': 'Future Production Order',
    canceled: 'Canceled',
    quote: 'Quote',
    'non-stock': 'NonStock',
  };

  const orderLineColumns: (ColumnType<SalesOrderDetailLineDTO> | null)[] = [
    sortableIdColumn({
      title: 'Item ID',
      dataIndex: 'itemCode',
      fixed: 'left',
      render: (itemCode: string, record: SalesOrderDetailLineDTO) => (
        <>
          <Link to={routes.sales.itemDetails(itemCode)}> {itemCode} </Link>
          {record.lineItemType === 'assembly' && record.orderLineId && (
            <Button
              size="small"
              onClick={() => {
                if (record.itemCode) {
                  showAsyncModal(OrderAssemblyLinesAgnosticModal, {
                    itemId: record.itemCode,
                    orderLineUid: record.orderLineId || '0',
                    orderLines,
                  });
                }
              }}
            >
              <DeploymentUnitOutlined />
            </Button>
          )}
        </>
      ),
    }),
    sortableStringColumn({
      title: 'Item',
      fixed: 'left',
      dataIndex: 'itemName',
    }),
    sortableStringColumn({
      title: 'Status',
      dataIndex: 'status',
      render: (status: ErpOrderStatus) => <ErpOrderStatusBadge erpOrderStatus={status} />,
    }),
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Disposition',
          dataIndex: 'lineType',
          render: (lineType: string) => lineItemLineTypeMap[lineType] || lineType || '-',
        }
      : null,
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'quantityOrdered',
    }),
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty Allocated',
          dataIndex: 'quantityAllocated',
        })
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty on Pick Ticket',
          dataIndex: 'quantityOnPickTickets',
        })
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty Canceled',
          dataIndex: 'quantityCanceled',
        })
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? sortableNumberColumn({
          title: 'Qty Invoiced',
          dataIndex: 'quantityInvoiced',
        })
      : null,
    sortableNumberColumn({
      title: 'Qty Open',
      dataIndex: 'quantityOpen',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Price',
      dataIndex: 'lineTotal',
    }),
    sortableDollarWithCentsColumn({
      title: 'Ext. Margin',
      dataIndex: 'lineGrossMargin',
    }),
  ];

  return (
    <Table
      columns={orderLineColumns.filter(filterCostAndGM)}
      scroll={{ x: true }}
      data={orderLines.filter((line) => !line.parentOrderLineId || line.parentOrderLineId === '0')}
    />
  );
};
