import { schemas } from '@recurrency/core-api-schema';
import {
  IntegratedErps,
  OrderStatus,
  P21Disposition,
  TenantFeatureFlag,
} from '@recurrency/core-api-schema/dist/common/enums';
import { ItemAvailabilityDTO } from '@recurrency/core-api-schema/dist/items/getItemAvailability';
import { OrderCreateBodyParams } from '@recurrency/core-api-schema/dist/orders/postCreateDTO';
import { SalesOrderDetailsDTO } from '@recurrency/core-api-schema/dist/salesOrders/getSalesOrderDetails';
import { SalesQuoteDraft } from '@recurrency/core-api-schema/dist/salesQuoteDrafts/common';
import { CreateSalesQuoteBody } from '@recurrency/core-api-schema/dist/salesQuotes/createSalesQuote';
import { SalesQuoteDetailsDTO } from '@recurrency/core-api-schema/dist/salesQuotes/getSalesQuoteDetails';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';

import { ExtendedUser } from 'hooks/useGlobalApp';

import { coreApiFetch, legacyApiFetch } from 'utils/api';
import { captureError } from 'utils/error';
import {
  roundToPrecision,
  joinIdNameObj,
  joinIfIdNameObj,
  roundTo2Decimals,
  splitIfIdNameStr,
  getErpName,
  formatUSD,
} from 'utils/formatting';
import { objOmitKeys, objRemoveUndefinedValues } from 'utils/object';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { encodeLegacyApiParam } from 'utils/routes';
import { getSearchIndexCustomerByCustomerId } from 'utils/search/search';
import { priceUnitConverter, qtyUnitConverter } from 'utils/units';

import {
  ISODateStr,
  QuoteEditHashStateP21,
  QuoteEditHashStateSAPB1,
  QuoteEditStep,
  QuoteLineItemP21,
} from 'types/hash-state';
import {
  ItemContract,
  ItemPriceInfo,
  ItemUnitOfMeasure,
  QuoteLineItemP21PriceInfo,
  UnitOfMeasureTags,
  ItemPriceResponseV4,
  LineItemRequest,
  IdNameObj,
  ItemPriceResults,
} from 'types/legacy-api';
import { SearchIndexItemPartial } from 'types/search-collections';

import { Disposition } from './types';

/// helper interfaces ///

export interface LineItemInfoCacheValue {
  // params are stored, so the price info can be reloaded if params change
  params: {
    companyId: string;
    customerId: string;
    salesLocationId: string;
    shipToId: string;
  };
  priceInfo?: QuoteLineItemP21PriceInfo;
  algoliaItem?: SearchIndexItemPartial;
  availabilityInfo?: Record<string, ItemAvailabilityDTO>;
  isLoading: boolean;
}

export interface QuoteLineItemP21WithInfo extends QuoteLineItemP21 {
  priceInfo?: LineItemInfoCacheValue['priceInfo'];
  algoliaItem?: SearchIndexItemPartial;
  availabilityInfo?: Record<string, ItemAvailabilityDTO>;
  isLoading: boolean;
  errors: {
    [key in keyof QuoteLineItemP21]?: string | null;
  };
}

export type LineItemsInfoCache = Obj<LineItemInfoCacheValue>;

export enum TransferType {
  LineLocation = 'lineLocation',
  SourceLocation = 'sourceLocation',
  ShipLocation = 'shipLocation',
  SourceAndShipLocation = 'sourceAndShipLocation',
  DirectShip = 'directShip',
  SpecialOrder = 'specialOrder',
  Substitute = 'substitute',
}

/// helper functions ///

export function getUnitOfMeasure(symbol: string | undefined, item: QuoteLineItemP21WithInfo) {
  return item.priceInfo?.unitOfMeasureOptions?.find((uomObj) => uomObj.symbol === symbol);
}

export const getNumLineItems = (items: QuoteLineItemP21[]) => items.filter((item) => !!item.foreignId).length;

export const getTotalPrice = (items: QuoteLineItemP21[]) =>
  roundTo2Decimals(items.reduce((total: number, item) => (item.quantity || 0) * (item.price || 0) + total, 0));

export const IVA_TAX_RATE = 1.16;

export const validateGrossMargin = (value?: string) => !isNaN(Number(value)) && Number(value) <= 100;

export const validateName = (item: QuoteLineItemP21WithInfo) => {
  if (!item.name || !item.foreignId) return 'Please select a valid item or delete this line item.';
  return null;
};

const dispositionLabels: Record<Disposition, string> = {
  [Disposition.DirectShip]: 'Direct Ship',
  [Disposition.Special]: 'Special Order',
  [Disposition.BackOrder]: 'Backorder',
  [Disposition.Hold]: 'Hold',
  [Disposition.Cancel]: 'Cancel',
  [Disposition.Transfer]: 'Transfer',
};

const dispositionTooltips: Record<Disposition, string> = {
  [Disposition.DirectShip]: 'Item will be shipped directly from supplier to customer',
  [Disposition.Special]: 'Item will be special ordered from supplier to location',
  [Disposition.BackOrder]: 'Item will be backordered',
  [Disposition.Hold]: 'Item will be hold',
  [Disposition.Cancel]: 'Item will be cancelled',
  [Disposition.Transfer]: 'Item will be transferred',
};

export const validateQuantity = (
  item: QuoteLineItemP21WithInfo,
  erpType: IntegratedErps,
  location: IdNameObj | undefined,
  duplicateLineItemQtyAllocated: number,
) => {
  if (item.foreignId === undefined || item.isLoading || location === undefined) {
    return [null, null];
  }

  const locationId = location.foreignId;

  if (!item.quantity) {
    return ['Minimum > 0', null];
  }

  if (!locationId) {
    // NOTE: this shouldn't happen but if it does it's caught as warning, not error
    return [null, null];
  }

  const locationAvailability = item.availabilityInfo?.[locationId];

  const nonSellable =
    item.algoliaItem?.sellable_location_ids && !item.algoliaItem?.sellable_location_ids?.includes(locationId);

  if (!locationAvailability || nonSellable) {
    return [
      `This item has not been allocated for sale at this location. Please allocate it in ${getErpName(erpType)} first`,
      null,
    ];
  }

  const systemCalculated = item.priceInfo?.current?.systemCalculatedPrices[0];
  const qtyLimit = systemCalculated?.qtyLimit;

  if (typeof qtyLimit === 'number' && item.quantity > qtyLimit) {
    return [
      `Contract limit: ${qtyLimit}`,
      `The current contract prices apply to this limit. To sell more than this limit, please duplicate this line.`,
    ];
  }

  const nonBuyable =
    item.algoliaItem?.buyable_location_ids && !item.algoliaItem?.buyable_location_ids?.includes(locationId);

  const nonStockable =
    item.algoliaItem?.stockable_location_ids && !item.algoliaItem?.stockable_location_ids?.includes(locationId);

  // if the item is (1) not buyable (2). not in transfer. (3). not (nonStockable and can sell) at location,
  // no backorders from this location are allowed. user should only sell remaining stock.
  const noBackorder = nonBuyable && !isItemOnTransfer(location, item) && !(nonStockable && !nonSellable);

  const qtyAvailableAtLocation = locationAvailability.quantityAvailable;
  const backorderQuantity = Math.min(duplicateLineItemQtyAllocated - qtyAvailableAtLocation, item.quantity || 0);

  if (noBackorder && backorderQuantity > 0) {
    return [
      `Unable to backorder`,
      `This item is not buyable from the selected source location. Consider transferring or changing sales location.`,
    ];
  }

  return [null, null];
};

export const validatePrice = (item: QuoteLineItemP21WithInfo, minMaxIsError: boolean, showMinMaxValues: boolean) => {
  if (item.foreignId === undefined || item.isLoading) {
    return null;
  }
  if (typeof item.price !== 'number') {
    return 'Price is empty';
  }
  if (minMaxIsError) {
    return minMaxLimit(item, showMinMaxValues);
  }

  return null;
};

export const minMaxLimit = (item: QuoteLineItemP21WithInfo, showMinMaxValues?: boolean) => {
  // If the price is the same as the p21 price then ignore min/max limits
  if (item.price === item?.priceInfo?.current?.systemCalculatedPrices[0]?.price?.amount) {
    return null;
  }

  const { minUnitPrice: minOrNull, maxUnitPrice: maxOrNull } = item.priceInfo?.contract?.policies[0] || {};

  const min = minOrNull
    ? roundToPrecision(
        qtyUnitConverter(
          minOrNull.amount,
          item.priceInfo?.current?.unitOfMeasure.size || 1,
          minOrNull.unitOfMeasure.size,
        ),
        item.priceInfo?.current?.priceDecimalPrecision || 2,
        Math.ceil,
      )
    : null;
  const max = maxOrNull
    ? roundToPrecision(
        qtyUnitConverter(
          maxOrNull.amount,
          item.priceInfo?.current?.unitOfMeasure.size || 1,
          maxOrNull.unitOfMeasure.size,
        ),
        item.priceInfo?.current?.priceDecimalPrecision || 2,
        Math.ceil,
      )
    : null;

  if (min && typeof item?.price === 'number' && item.price < min) {
    return showMinMaxValues ? `Minimum: ${min}` : `Minimum Limit`;
  }
  if (max && typeof item?.price === 'number' && item.price > max) {
    return showMinMaxValues ? `Maximum: ${max}` : `Maximum Limit`;
  }

  return null;
};

export const warnPrice = (item: QuoteLineItemP21WithInfo, minMaxIsWarning: boolean, showMinMaxValues: boolean) => {
  if (validatePrice(item, !minMaxIsWarning, showMinMaxValues) !== null) {
    return null;
  }
  if (item?.price === 0) {
    return 'Price is 0';
  }

  if (minMaxIsWarning) {
    return minMaxLimit(item, showMinMaxValues);
  }

  return null;
};

export const warnQuantity = (
  item: QuoteLineItemP21WithInfo,
  location: IdNameObj | undefined,
  duplicateLineItemQtyAllocated: number,
  qtyAllocatedForDisposition: boolean,
) => {
  if (!location || !location.foreignId) {
    return ['Location not specified', null];
  }
  const locationAvailability = item.availabilityInfo?.[location.foreignId];

  // Not available at location means error, not warning
  if (!locationAvailability) {
    return [null, null];
  }
  const unitOfMeasure = getUnitOfMeasure(item.unitOfMeasure, item);

  const qtyAvailableAtLocation = locationAvailability.quantityAvailable;

  if (!unitOfMeasure) {
    // This shouldn't happen, but if it does there's no error/warning at this point (need to load UOM)
    return [null, null];
  }

  if (item.quantity) {
    if (isItemOnTransfer(location, item)) {
      return [`Transfer: ${item.quantity}`];
    }

    const backorderQuantity = Math.min(duplicateLineItemQtyAllocated - qtyAvailableAtLocation, item.quantity || 0);
    const dispositionQuantity =
      item.disposition === Disposition.BackOrder
        ? item.quantity || 0
        : qtyAllocatedForDisposition
        ? backorderQuantity
        : item.quantity || 0;

    if (item.disposition) {
      const disposition = dispositionLabels[item.disposition] || 'Unknown';
      const tooltip = dispositionTooltips[item.disposition] || 'Unknown';
      return [`${disposition}: ${dispositionQuantity}`, tooltip];
    }

    const nonBuyable =
      item.algoliaItem?.buyable_location_ids && !item.algoliaItem?.buyable_location_ids?.includes(location.foreignId);

    const nonSellable =
      item.algoliaItem?.sellable_location_ids && !item.algoliaItem?.sellable_location_ids?.includes(location.foreignId);

    const nonStockable =
      item.algoliaItem?.stockable_location_ids &&
      !item.algoliaItem?.stockable_location_ids?.includes(location.foreignId);

    const noBackorder = nonBuyable && !(nonStockable && !nonSellable);
    if (!noBackorder && backorderQuantity > 0) {
      return [`Backorder: ${backorderQuantity}`, `Backordering quantity not currently available at location`];
    }
  }
  return [null, null];
};

export const extendedPriceWithIvaTax = (item: QuoteLineItemP21WithInfo, shouldShow: boolean) => {
  const extendedPrice = (item?.price ?? 0) * (item?.quantity ?? 0);

  if (!shouldShow || extendedPrice <= 0) {
    return null;
  }
  return `Ext Price: ${formatUSD(extendedPrice, true)} with IVA: ${formatUSD(IVA_TAX_RATE * extendedPrice, true)}`;
};

/** gm = ((price - cost) / price) * 100 */
export const calcGM = (price: number, cost: number) =>
  price === 0 ? 0 : roundTo2Decimals(((price - cost) / price) * 100);

/** price = cost / (1 - gm / 100) */
export const calcPrice = (gm: number, cost: number, precision: number) =>
  roundToPrecision(cost / (1 - gm / 100), precision);

/** cost = cost / (1 - gm / 100) */
export const calcCost = (gm: number, price: number, precision: number) =>
  roundToPrecision(price * (1 - gm / 100), precision);

export const getItemCost = (item: QuoteLineItemP21WithInfo, activeTenant: TenantDTO): number | undefined => {
  const cost = activeTenant.featureFlags?.[TenantFeatureFlag.SalesStandardCostBasedGrossMargin]
    ? item.priceInfo?.cost?.standardCost?.amount
    : item.priceInfo?.cost?.movingAverageUnitCost?.amount;
  if (activeTenant.featureFlags?.[TenantFeatureFlag.OrdersChangeCommissionCost] && item.commissionCost) {
    return item.commissionCost / (getUnitOfMeasure(item.unitOfMeasure, item)?.size || 1);
  }
  return activeTenant.featureFlags?.[TenantFeatureFlag.SalesCommissionCostPreferred]
    ? item.priceInfo?.cost?.commissionCost?.amount || cost
    : cost;
};

export const getTotalGrossMargin = (items: QuoteLineItemP21WithInfo[], activeTenant: TenantDTO) =>
  roundTo2Decimals(
    items.reduce(
      (total: number, item) =>
        (item.quantity || 0) * ((item.price || 0) - (getItemCost(item, activeTenant) || 0)) + total,
      0,
    ),
  );

export const getAverageGrossMarginPct = (items: QuoteLineItemP21WithInfo[], activeTenant: TenantDTO): number => {
  const filteredGMs: number[] = items
    .filter((lineItem) => {
      const cost = getItemCost(lineItem, activeTenant);
      return !(cost === null || cost === undefined);
    })
    .map((lineItem) => calcGM(lineItem.price || 0, getItemCost(lineItem, activeTenant) || 0));
  return filteredGMs.length > 0 ? roundTo2Decimals(filteredGMs.reduce((a, b) => a + b) / filteredGMs.length) : NaN;
};

export function getLineItemTrackProps(lineItems: QuoteLineItemP21WithInfo[], activeTenant: TenantDTO) {
  return {
    numLineItems: getNumLineItems(lineItems),
    totalPrice: getTotalPrice(lineItems),
    totalGrossMargin: getTotalGrossMargin(lineItems, activeTenant),
    avgGrossMarginPercentage: getAverageGrossMarginPct(lineItems, activeTenant),
  };
}

export function getUnitCost(item: QuoteLineItemP21WithInfo, activeTenant: TenantDTO) {
  const targetSize = getUnitOfMeasure(item.unitOfMeasure, item)?.size || 1;
  const unitCost = getItemCost(item, activeTenant) || item.priceInfo?.cost?.unitCost?.amount || 0;
  return priceUnitConverter(unitCost, targetSize, item.priceInfo?.cost?.unitOfMeasure?.size);
}

export function getOtherCost(item: QuoteLineItemP21WithInfo) {
  const targetSize = getUnitOfMeasure(item.unitOfMeasure, item)?.size || 1;
  const unitOtherCost = item.priceInfo?.current?.systemCalculatedPrices[0]?.otherCost || 0;
  return priceUnitConverter(unitOtherCost, targetSize, item.priceInfo?.cost?.unitOfMeasure?.size);
}

/** returns net weight in lbs */
export const getItemNetWeight = (item: QuoteLineItemP21WithInfo): number =>
  (item.algoliaItem?.net_weight || 0) * (getUnitOfMeasure(item.unitOfMeasure, item)?.size || 1);

export const getTotalNetWeight = (items: QuoteLineItemP21WithInfo[]) =>
  roundTo2Decimals(items.reduce((total: number, item) => (item.quantity || 0) * getItemNetWeight(item) + total, 0));

export function quoteRequestFromHashState(
  hashState: QuoteEditHashStateP21,
  activeTenant: TenantDTO,
  activeUser: ExtendedUser,
  approved: boolean,
  quoteOrderFormConfig: Record<string, any>,
): CreateSalesQuoteBody {
  return {
    date: getRequiredDate(hashState, activeTenant)!,
    validUntilDate: hashState.validUntilDate!,
    purchaseOrder: hashState.poNo || '',
    approved,
    description: hashState.description,
    metadata: {
      headerNote: shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableNotesExportForP21)
        ? undefined
        : hashState.headerNote,
      company: splitIfIdNameStr(hashState.company)!,
      freightType: splitIfIdNameStr(hashState.freightType)!,
      freightChargeUid: splitIfIdNameStr(hashState.freightCharge)?.foreignId,
      carrier: shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableCarrierExport)
        ? undefined
        : splitIfIdNameStr(hashState.carrier),
      customer: splitIfIdNameStr(hashState.customer)!,
      contact: splitIfIdNameStr(hashState.contact),
      location: splitIfIdNameStr(hashState.location)!,
      sourceLocation: splitIfIdNameStr(hashState.sourceLocation)!,
      shipTo: splitIfIdNameStr(hashState.shipTo)!,
      shippingRouteId: hashState.shippingRouteId,
      packingBasis: shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisablePackingBasisExport)
        ? undefined
        : hashState.packingBasis,
      deliveryInstructions: hashState.deliveryInstructions,
      shipToEmailAddress: hashState.shipToEmailAddress,
      orderClass1: splitIfIdNameStr(hashState.class1),
      orderTypePriority: hashState.orderTypePriority,
      priceOverrideReason: hashState.priceOverrideReason,
      priceOverrideComments: hashState.priceOverrideComments,
      // We filter out any empty lines in the case that the user has attempted to save a draft with empty lines
      items: (hashState.items || [])
        .filter((item) => !!item.foreignId)
        .map((i) => ({
          // Omitting keys that are unused downstream or need to be defined somewhere else
          ...objOmitKeys(i, 'supplier'),
          supplier: joinIfIdNameObj(i.supplier),
        })),
      taker: hashState.taker,
      jobNumber: hashState.jobNumber,
      customerJobNo: hashState.customerJobNo,
      qteForOrder: hashState.qteForOrder,
      terms: quoteOrderFormConfig.terms.enableExport ? hashState.terms : undefined,
    },
  };
}

export function orderRequestFromHashState(
  hashState: QuoteEditHashStateP21,
  activeTenant: TenantDTO,
  activeUser: ExtendedUser,
  approved: boolean,
  quoteOrderFormConfig: Record<string, any>,
): OrderCreateBodyParams {
  return {
    sendPdfEmailAfterSubmit: true,
    tenantId: activeTenant.id,
    date: getRequiredDate(hashState, activeTenant)!,
    purchaseOrder: hashState.poNo || '',
    approved,
    status: OrderStatus.Submitted,
    metadata: {
      headerNote: shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableNotesExportForP21)
        ? undefined
        : hashState.headerNote,
      company: splitIfIdNameStr(hashState.company)!,
      freightType: splitIfIdNameStr(hashState.freightType),
      freightChargeUid: splitIfIdNameStr(hashState.freightCharge)?.foreignId,
      carrier: shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableCarrierExport)
        ? undefined
        : splitIfIdNameStr(hashState.carrier),
      customer: splitIfIdNameStr(hashState.customer)!,
      contact: splitIfIdNameStr(hashState.contact),
      location: splitIfIdNameStr(hashState.location)!,
      sourceLocation: splitIfIdNameStr(hashState.sourceLocation)!,
      shipTo: splitIfIdNameStr(hashState.shipTo)!,
      shippingRouteId: hashState.shippingRouteId,
      packingBasis: shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisablePackingBasisExport)
        ? undefined
        : hashState.packingBasis,
      deliveryInstructions: hashState.deliveryInstructions,
      shipToEmailAddress: hashState.shipToEmailAddress,
      orderClass1: splitIfIdNameStr(hashState.class1),
      orderTypePriority: hashState.orderTypePriority,
      priceOverrideReason: hashState.priceOverrideReason,
      priceOverrideComments: hashState.priceOverrideComments,
      items: (hashState.items || []).map((i) => ({
        // Omitting keys that are unused downstream or need to be defined somewhere else
        ...objOmitKeys(i, 'transferCarrier', 'supplier', 'sourceLocation', 'shipLocation', 'disposition'),
        price: i.price!,
        quantity: i.quantity!,
        unitOfMeasure: i.unitOfMeasure!,
        shipLocationId: i.shipLocation?.foreignId,
        sourceLocationId: i.sourceLocation?.foreignId,
        supplier: joinIfIdNameObj(i.supplier),
        disposition: i.disposition as P21Disposition | undefined,
        transferInfo:
          i.sourceLocation?.foreignId !== i.shipLocation?.foreignId
            ? {
                fromLocation: i.sourceLocation || splitIfIdNameStr(hashState.location)!,
                carrier: i.transferCarrier?.foreignId ? i.transferCarrier : undefined,
                qtyAllocated: i.quantity!,
              }
            : undefined,
      })),
      taker: hashState.taker!,
      willCall: hashState.willCall,
      customerJobNo: hashState.customerJobNo,
      qteForOrder: hashState.qteForOrder,
      frontCounter: hashState.frontCounter,
      terms: quoteOrderFormConfig.terms.enableExport ? hashState.terms : undefined,
    },
  };
}

export function hashStateFromQuote(quoteData: SalesQuoteDraft): QuoteEditHashStateP21 {
  return {
    company: joinIfIdNameObj(quoteData.metadata.company),
    customer: joinIfIdNameObj(quoteData.metadata.customer),
    contact: joinIfIdNameObj(quoteData.metadata.contact),
    location: joinIfIdNameObj(quoteData.metadata.location),
    shipTo: joinIfIdNameObj(quoteData.metadata.shipTo),
    freightType: joinIfIdNameObj(quoteData.metadata.freightType),
    carrier: joinIfIdNameObj(quoteData.metadata.carrier),
    class1: joinIfIdNameObj(quoteData.metadata.orderClass1),
    packingBasis: quoteData.metadata.packingBasis,
    shippingRouteId: quoteData.metadata.shippingRouteId,
    requiredDate: String(quoteData.date),
    validUntilDate: String(quoteData.validUntilDate),
    poNo: quoteData.purchaseOrder,
    headerNote: quoteData.metadata.headerNote,
    // @ts-expect-error slight mismatch in FE vs core-api types but data is correct
    items: quoteData.metadata.items,
    jobNumber: quoteData.metadata?.jobNumber,
    description: quoteData.description || undefined,
  };
}

type ItemsOrError<T> = { error: true } | { error?: undefined; items: T };

function transformPriceResponse(
  priceInfoResponse: ItemsOrError<ItemPriceResponseV4>,
  contract?: QuoteLineItemP21PriceInfo['contract'],
): QuoteLineItemP21PriceInfo | { error: true } {
  if (priceInfoResponse.error) {
    return { error: priceInfoResponse.error };
  }

  const data = priceInfoResponse.items;
  const { cost, library } = data;

  const unitOfMeasureOptions: ItemUnitOfMeasure[] = data.unitsOfMeasure;
  unitOfMeasureOptions.sort((a, b) => a.size - b.size);

  const baseUnit =
    unitOfMeasureOptions.find((unitOfMeasure) => unitOfMeasure.tags.includes(UnitOfMeasureTags.Base)) ||
    unitOfMeasureOptions.find((unitOfMeasure) => unitOfMeasure.size === 1) ||
    unitOfMeasureOptions[0];
  const defaultUnit =
    unitOfMeasureOptions.find((unitOfMeasure) => unitOfMeasure.tags.includes(UnitOfMeasureTags.Sales)) ||
    data?.unitOfMeasure ||
    unitOfMeasureOptions[0];

  const current: ItemPriceInfo = {
    unitOfMeasure: data.unitOfMeasure,
    priceDecimalPrecision: data.priceDecimalPrecision,
    quantityDecimalPrecision: data.quantityDecimalPrecision,
    systemCalculatedPrices: data.systemCalculatedPrices,
  };

  return {
    baseUnit,
    defaultUnit,
    unitOfMeasureOptions,
    contract,
    current,
    cost,
    library,
  };
}

export async function getItemPriceInfo(
  line: LineItemRequest,
  params: LineItemInfoCacheValue['params'],
): Promise<QuoteLineItemP21PriceInfo | { error: true }> {
  // FIXME: this should be done differently but am plugging it into old setup for now and will disentangle later
  // NOTE: V4 is ready when no longer using any V3 content

  const [priceInfoResponseArray, contractInfoResponse] = await Promise.all([
    getItemPriceMulti(params.companyId, params.customerId, params.salesLocationId, params.shipToId, [line]),
    getItemContract(line.inv_mast_uid, params),
  ]);

  const priceInfoResponse = priceInfoResponseArray[0];

  const contract = contractInfoResponse.error ? null : contractInfoResponse.items;

  return transformPriceResponse(priceInfoResponse, contract);
}

export async function getItemContract(
  itemInvMastUId: string | undefined,
  params: LineItemInfoCacheValue['params'],
): Promise<ItemsOrError<ItemContract>> {
  try {
    const { data } = await legacyApiFetch<ItemContract>(
      `/v4/customers/${encodeLegacyApiParam(params?.customerId)}/contract`,
      {
        method: 'GET',
        data: objRemoveUndefinedValues({
          company_id: params.companyId,
          customer_id: params.customerId,
          inv_mast_uid: itemInvMastUId,
        }),
      },
    );
    return {
      items: data,
    };
  } catch (error) {
    captureError(error);
    return { error: true };
  }
}

async function getItemPriceMulti(
  companyId: string,
  customerId: string,
  salesLocationId: string,
  shipToId: string,
  lines: Array<LineItemRequest>,
): Promise<ItemsOrError<ItemPriceResponseV4>[]> {
  try {
    const { data } = await legacyApiFetch<ItemPriceResults[]>(`/v4/items/price/multi`, {
      method: 'POST',
      data: {
        company_id: companyId,
        customer_id: customerId,
        location_id: salesLocationId,
        ship_to_id: shipToId,
        lines,
      },
    });

    return data.map((item) => {
      if (item.error !== undefined) {
        return { error: true };
      }

      return {
        items: item.results,
      };
    });
  } catch (error) {
    captureError(error);
    return lines.map(() => ({ error: true }));
  }
}

const priceInfoMultiCache = new Map<string, Promise<Array<QuoteLineItemP21PriceInfo | { error: true }>>>();

// This function is used to get the price info for multiple items at once, caching the results
// only the first request for a unique key will trigger the API call,
// and subsequent concurrent requests will return the same results from original call.
// ensuring that the data fetching function is only called once per unique key.
export async function getCachedItemPriceInfoMulti(
  companyId: string,
  customerId: string,
  locationId: string,
  shipToId: string,
  lines: Array<LineItemRequest>,
): Promise<Array<QuoteLineItemP21PriceInfo | { error: true }>> {
  const cacheKey = JSON.stringify({ companyId, customerId, locationId, shipToId, lines });
  let promise = priceInfoMultiCache.get(cacheKey);

  if (!promise) {
    promise = getItemPriceMulti(companyId, customerId, locationId, shipToId, lines)
      .then((priceInfoResponseArray) => priceInfoResponseArray.map((line) => transformPriceResponse(line)))
      .catch((error) => {
        priceInfoMultiCache.delete(cacheKey);
        captureError(error);
        return lines.map(() => ({ error: true }));
      });
    priceInfoMultiCache.set(cacheKey, promise);
  }

  return promise;
}

const itemAvailabilityCache = new Map<string, Promise<Record<string, ItemAvailabilityDTO>>>();
export function getCachedItemAvailability(itemUid: string): Promise<Record<string, ItemAvailabilityDTO>> {
  if (!itemAvailabilityCache.has(itemUid)) {
    itemAvailabilityCache.set(
      itemUid,
      itemUid
        ? coreApiFetch(schemas.items.getItemAvailability, { pathParams: { itemUid } }).then((availabilityReponse) =>
            Object.fromEntries(availabilityReponse.data.items.map((item) => [item.locationId, item])),
          )
        : Promise.resolve({}),
    );
  }
  // value is always defined because we set it above
  return itemAvailabilityCache.get(itemUid)!;
}

export function getHashStateFromOrder(order: SalesOrderDetailsDTO): QuoteEditHashStateP21 {
  return {
    customer: order.customerId && joinIdNameObj({ foreignId: order.customerId, name: order.customerName }),
    company: order.companyId && joinIdNameObj({ foreignId: order.companyId, name: '' }),
    contact:
      order.customerContact?.contactId &&
      joinIdNameObj({
        foreignId: order.customerContact.contactId ?? '',
        name: order.customerContact.contactName,
      }),
    shipTo:
      order.shipToAddress && joinIdNameObj({ foreignId: order.shipToId, name: order.shipToAddress?.addressName ?? '' }),
    packingBasis: order.packingBasis,
    freightType: order.freightUid && joinIdNameObj({ foreignId: order.freightUid, name: order.freightName ?? '' }),
    carrier: order.carrierId && joinIdNameObj({ foreignId: order.carrierId, name: order.carrierName ?? '' }),
    location: order.locationId && joinIdNameObj({ foreignId: order.locationId, name: order.locationName }),
    items: order.lines
      // We filter out any component lines in the case that the original order contains assembly item (component lines embedded already)
      .filter((line) => !line.parentOrderLineUid || line.parentOrderLineUid === '0')
      .map((orderLine) => ({
        foreignId: orderLine.itemId,
        name: orderLine.itemName,
        unitOfMeasure: orderLine.unitOfMeasure,
        quantity: orderLine.quantityOrdered,
        price: orderLine.unitPrice,
      })),
  };
}

export function getHashStateFromQuote(quote: SalesQuoteDetailsDTO): QuoteEditHashStateP21 {
  return {
    customer: quote.customerId && joinIdNameObj({ foreignId: quote.customerId, name: quote.customerName }),
    company: quote.companyId && joinIdNameObj({ foreignId: quote.companyId, name: '' }),
    contact:
      quote.customerContact?.contactId &&
      joinIdNameObj({
        foreignId: quote.customerContact.contactId ?? '',
        name: quote.customerContact.contactName,
      }),
    shipTo:
      quote.shipToAddress && joinIdNameObj({ foreignId: quote.shipToId, name: quote.shipToAddress?.addressName ?? '' }),
    packingBasis: quote.packingBasis,
    freightType: quote.freightUid && joinIdNameObj({ foreignId: quote.freightUid, name: quote.freightName ?? '' }),
    carrier: quote.carrierId && joinIdNameObj({ foreignId: quote.carrierId, name: quote.carrierName ?? '' }),
    location: quote.locationId && joinIdNameObj({ foreignId: quote.locationId, name: quote.locationName }),
    items: quote.lines
      // We filter out any component lines in the case that the original quote contains assembly item (component lines embedded already)
      .filter((line) => !line.parentQuoteLineUid || line.parentQuoteLineUid === '0')
      .map((quoteLine) => ({
        foreignId: quoteLine.itemId,
        name: quoteLine.itemName,
        unitOfMeasure: quoteLine.unitOfMeasure,
        quantity: quoteLine.quantityOrdered,
        price: quoteLine.unitPrice,
      })),
  };
}

export function getTransferType(
  salesLocation?: IdNameObj,
  shipLocation?: IdNameObj,
  sourceLocation?: IdNameObj,
): TransferType | undefined {
  if (salesLocation?.foreignId === sourceLocation?.foreignId && salesLocation?.foreignId === shipLocation?.foreignId) {
    return undefined;
  }

  if (salesLocation?.foreignId === shipLocation?.foreignId && salesLocation?.foreignId !== sourceLocation?.foreignId) {
    return TransferType.SourceLocation;
  }
  if (salesLocation?.foreignId === sourceLocation?.foreignId && salesLocation?.foreignId !== shipLocation?.foreignId) {
    return TransferType.ShipLocation;
  }
  // Case in which sourceLocation !== shipLocation. and neither sourceLocation or shipLocation === salesLocation
  if (
    shipLocation?.foreignId !== sourceLocation?.foreignId &&
    sourceLocation?.foreignId !== salesLocation?.foreignId &&
    shipLocation?.foreignId !== salesLocation?.foreignId
  ) {
    return TransferType.SourceAndShipLocation;
  }
  return TransferType.LineLocation;
}

export function isItemOnTransfer(location: IdNameObj, item: QuoteLineItemP21WithInfo): boolean {
  return (
    getTransferType(location, item.shipLocation, item.sourceLocation) !== undefined &&
    getTransferType(location, item.shipLocation, item.sourceLocation) !== TransferType.LineLocation &&
    item.shipLocation !== undefined &&
    item.sourceLocation !== undefined
  );
}

export const getDuplicateLineItemsQty = (
  quoteStateItems: QuoteLineItemP21[] | undefined,
  item: QuoteLineItemP21,
  locationId: string | undefined,
  // Feature flag check to see if Direct Ship or Special Orders applies to tenant's qtyAllocated
  dispositionAffectQtyAllocated?: boolean,
) => {
  const { foreignId: itemForeignId } = item;
  const itemSourceLocationId = item.sourceLocation?.foreignId || locationId;
  const findDuplicates = (lineItems: QuoteLineItemP21[], shouldDispositionAffect?: boolean) => {
    const duplicates = lineItems.filter(
      (lineItem) =>
        lineItem.foreignId === itemForeignId &&
        (lineItem.sourceLocation?.foreignId || locationId) === itemSourceLocationId,
    );
    // There's a setting where special order and direct shipping means no quantity at location will be allocated
    if (!shouldDispositionAffect) {
      const duplicatesWithoutDisposition = duplicates.filter(
        (lineItem) => lineItem.disposition !== Disposition.DirectShip && lineItem.disposition !== Disposition.Special,
      );
      return duplicatesWithoutDisposition;
    }
    return duplicates;
  };

  const duplicateLineItems = quoteStateItems && findDuplicates(quoteStateItems, dispositionAffectQtyAllocated);
  if (duplicateLineItems?.length === 0) return 0;

  let qtyAllocatedToSubtract = 0;
  for (const duplicateLineItem of duplicateLineItems!) {
    qtyAllocatedToSubtract += duplicateLineItem.quantity!;
  }
  return qtyAllocatedToSubtract;
};

/// convert quote <-> order ///
const quoteToOrderHeaderOmitKeys: Array<keyof QuoteEditHashStateP21> = ['validUntilDate', 'qteForOrder'];
const quoteToOrderLineOmitKeys: Array<keyof QuoteLineItemP21> = [];
const orderToQuoteHeaderOmitKeys: Array<keyof QuoteEditHashStateP21> = ['willCall', 'frontCounter'];
const orderToQuoteLineOmitKeys: Array<keyof QuoteLineItemP21> = ['transferCarrier'];

export function convertQuoteHashStateToOrder(docState: QuoteEditHashStateP21): QuoteEditHashStateP21 {
  return {
    ...objOmitKeys(docState, ...quoteToOrderHeaderOmitKeys),
    items: (docState.items || []).map((item) => objOmitKeys(item, ...quoteToOrderLineOmitKeys) as QuoteLineItemP21),
    step: QuoteEditStep.Header,
  };
}

export function convertOrderHashStateToQuote(docState: QuoteEditHashStateP21): QuoteEditHashStateP21 {
  return {
    ...objOmitKeys(docState, ...orderToQuoteHeaderOmitKeys),
    items: (docState.items || []).map((item) => objOmitKeys(item, ...orderToQuoteLineOmitKeys) as QuoteLineItemP21),
    step: QuoteEditStep.Header,
  };
}

export function getLotInfoCacheKey(docState: QuoteEditHashStateP21, itemId: string) {
  return `${docState.customer}|${docState.location}|${itemId}`;
}

export function getCustomerPartIdInfoCacheKey(docState: QuoteEditHashStateP21, itemId: string) {
  return `${docState.customer}|${docState.company}|${itemId}`;
}

// If OrdersChangeRequiredDateForLineItem is turned on, adjust the header's requiredDate
// to be the maximum date of the line item's requiredDate if applicable.
export const getRequiredDate = (docState: QuoteEditHashStateP21, activeTenant: TenantDTO): ISODateStr | undefined => {
  const shouldChangeRequiredDateForLineItem =
    activeTenant.featureFlags?.[TenantFeatureFlag.OrdersChangeRequiredDateForLineItem];
  if (shouldChangeRequiredDateForLineItem) {
    const dates = [docState.requiredDate, ...(docState.items || []).map((item) => item.requiredDate)];
    return dates.filter((date) => date !== undefined).reduce((a, b) => (a && b && a > b ? a : b));
  }
  return docState.requiredDate;
};

// For SAPB1: if quoteOrderFormConfig.ocrCode(N) is turned on, derive value from defaultValueFieldFromAlgoliaCustomer
export async function getCustomerDerivedFieldDefaults(
  customerId: string | undefined,
  quoteOrderFormConfig: Record<string, any>,
): Promise<Partial<QuoteEditHashStateSAPB1> | undefined> {
  if (
    customerId &&
    ((quoteOrderFormConfig.ocrCode && quoteOrderFormConfig.ocrCode.enableExport) ||
      (quoteOrderFormConfig.ocrCode2 && quoteOrderFormConfig.ocrCode2.enableExport))
  ) {
    // fetch customer algolia record
    const algoliaCustomer: Record<string, any> = await getSearchIndexCustomerByCustomerId(
      customerId,
      '', // no companyId for SAPB1
    );
    const stateUpdate: Partial<QuoteEditHashStateSAPB1> = {};
    if (quoteOrderFormConfig.ocrCode && quoteOrderFormConfig.ocrCode.enableExport) {
      stateUpdate.ocrCode = algoliaCustomer[quoteOrderFormConfig.ocrCode.defaultValueFieldFromAlgoliaCustomer];
    }
    if (quoteOrderFormConfig.ocrCode2 && quoteOrderFormConfig.ocrCode2.enableExport) {
      stateUpdate.ocrCode2 = algoliaCustomer[quoteOrderFormConfig.ocrCode2.defaultValueFieldFromAlgoliaCustomer];
    }
    return stateUpdate;
  }
  return undefined;
}

export async function getStateUpdateWithCustomerTerms(
  customerId: string | undefined,
  companyId: string | undefined,
): Promise<Partial<QuoteEditHashStateP21> | undefined> {
  if (customerId) {
    // fetch customer algolia record
    const algoliaCustomer: Record<string, any> = await getSearchIndexCustomerByCustomerId(customerId, companyId || '');
    const stateUpdate: Partial<QuoteEditHashStateP21> = { terms: algoliaCustomer.terms_desc };
    return stateUpdate;
  }
  return undefined;
}
