import React, { MutableRefObject, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SortDirection, TaskFilter } from '@recurrency/core-api-schema/dist/common/enums';
import { TaskDTO } from '@recurrency/core-api-schema/dist/tasks/common';
import { GetTasksQueryParams, TaskListDTO } from '@recurrency/core-api-schema/dist/tasks/getTasks';
import { Menu, notification, Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { theme } from 'theme';
import { useDebounce } from 'use-debounce/lib';

import { TaskStatus } from 'pages/tasks/types';

import { AsyncSelect } from 'components/AsyncSelect';
import { useTenantUsersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { LinkButton } from 'components/Button/LinkButton';
import { Dropdown } from 'components/Dropdown';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ButtonLink } from 'components/Links';
import { CenteredError } from 'components/Loaders';
import { RadioGroup } from 'components/Radio';
import { EllipsisTruncatedLabel } from 'components/Typography/EllpsisTruncatedLabel';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import { formatDate, formatName, pluralize } from 'utils/formatting';
import { isAdmin } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';
import { asKeyOf, sortableDateColumn, sortableStringColumn } from 'utils/tables';

import { ALL_FILTER, TaskListHashState } from 'types/hash-state';

import { SearchInput } from '../SearchInput';
import { DEFAULT_PAGE_SIZE, Table } from '../Table';
import { NewTaskButton } from './NewTaskButton';

export interface TasksContextRef {
  queryParams: GetTasksQueryParams;
  tasks: TaskListDTO | undefined;
  reload: () => void;
}

export const TasksTable = ({
  dashboardMode,
  showNewTaskButton,
  companyId,
  customerId,
  customerName,
  contextRef,
}: {
  /* when rendered as dashboard table, filters, sorting and pagination is disabled */
  dashboardMode?: boolean;
  showNewTaskButton?: boolean;
  /* passed when rendering in customer details page */
  companyId?: string;
  customerId?: string;
  customerName?: string;
  /* context ref allows data lifecycle to be visible by parent component */
  contextRef?: MutableRefObject<TasksContextRef | undefined>;
}) => {
  const { activeUser, activeErpRole } = useGlobalApp();
  const isUserAdminRole = isAdmin(activeErpRole.foreignId, activeErpRole.name);
  const defaultFilter = isUserAdminRole ? ALL_FILTER : TaskFilter.Assigned;
  const [hashState, updateHashState] = useHashState<TaskListHashState>();
  const { filter = defaultFilter, status = TaskStatus.New, assigneeUserIdSelected } = hashState;
  const [searchQuery, setSearchQuery] = useState(hashState.searchQuery);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const tenantsUserSelectProps = useTenantUsersSelectProps();

  useEffect(() => {
    updateHashState({ searchQuery: debouncedSearchQuery });
  }, [debouncedSearchQuery, updateHashState]);

  const pageSize = DEFAULT_PAGE_SIZE;
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.Desc);

  const queryParams: GetTasksQueryParams = {
    filter: {
      status,
      userId: filter === TaskFilter.Created ? activeUser.id : undefined,
      assigneeUserId: filter === TaskFilter.Assigned ? activeUser.id : assigneeUserIdSelected,
      customerId,
    },
    searchQuery: debouncedSearchQuery,
    limit: pageSize,
    offset: ((page ?? 1) - 1) * pageSize,
    sortBy,
    sortDir,
  };

  const { data, error, isLoading, reload } = useCoreApi(schemas.tasks.getTasks, { queryParams });

  if (contextRef) {
    contextRef.current = {
      queryParams,
      tasks: data,
      reload,
    };
  }

  const today = moment().format('YYYY-MM-DD');

  const columns: Array<ColumnType<TaskDTO> | null> = [
    sortableStringColumn({
      title: 'Topic',
      dataIndex: asKeyOf<TaskDTO>('title'),
      sorter: !dashboardMode,
      render: (title: string, { id }: { id: string }) => <Link to={routes.tasks.taskDetails(id)}>{title}</Link>,
    }),
    !customerId
      ? {
          title: 'Customer',
          dataIndex: [asKeyOf<TaskDTO>('metadata'), 'customer'],
          render: (customer?: { name: string; foreignId: string } | undefined) =>
            customer ? (
              <Link
                to={routes.sales.customerDetails(customer.foreignId)}
              >{`${customer.foreignId}: ${customer.name}`}</Link>
            ) : (
              '-'
            ),
        }
      : null,
    {
      title: 'Comments',
      dataIndex: asKeyOf<TaskDTO>('body'),
      render: (body) => <EllipsisTruncatedLabel maxWidth="350px" label={body} />,
    },
    {
      title: 'Assigned To',
      dataIndex: [asKeyOf<TaskDTO>('assignee'), 'firstName'],
      render: (_, task: TaskDTO) => formatName(task.assignee?.firstName, task.assignee?.lastName),
    },
    sortableDateColumn({
      title: 'Due Date',
      dataIndex: asKeyOf<TaskDTO>('dueAt'),
      sorter: !dashboardMode,
      render: (date: string) => {
        const formatted = formatDate(date);

        if (moment(today).isSame(formatted)) {
          return (
            <div
              className={css`
                color: ${theme.colors.danger[500]};
              `}
            >
              {formatted}
            </div>
          );
        }

        return formatted;
      },
    }),
    sortableDateColumn({
      title: 'Last Modified',
      dataIndex: asKeyOf<TaskDTO>('updatedAt'),
      sorter: !dashboardMode,
      defaultSortOrder: 'descend',
    }),
    {
      render: ({ id }) => ButtonLink(routes.tasks.taskDetails(id)),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<TaskDTO[]>([]);

  const updateTaskStatuses = async (completed: boolean) => {
    const statusString = selectedRows.length > 1 ? 'statuses' : 'status';
    try {
      await Promise.all(
        selectedRows.map((task) =>
          coreApiFetch(schemas.tasks.updateTask, {
            pathParams: { taskId: task.id },
            bodyParams: {
              status: completed ? TaskStatus.Completed : TaskStatus.New,
            },
          }),
        ),
      );

      notification.success({ message: `Task ${statusString} updated.` });
      setSelectedRows([]);
      setSelectedRowKeys([]);
      reload();
    } catch (err) {
      captureAndShowError(err, `Error while updating task ${statusString}`);
    }
  };

  const onSelectChange = (selectedRowKeys: FIXME, selectedRows: FIXME) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const clearSelections = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <>
      <FilterBarBox dividerLine={!dashboardMode && !customerId}>
        {!dashboardMode && (
          <RadioGroup
            value={filter}
            onChange={({ target: { value } }) => {
              setPage(1);
              updateHashState({ filter: value });
              clearSelections();
            }}
          >
            {isUserAdminRole && (
              <Radio.Button value={ALL_FILTER} onClick={() => updateHashState({ assigneeUserIdSelected: undefined })}>
                All
              </Radio.Button>
            )}
            <Radio.Button
              value={TaskFilter.Assigned}
              onClick={() => updateHashState({ assigneeUserIdSelected: activeUser.id })}
            >
              Assigned to Me
            </Radio.Button>
            <Radio.Button
              value={TaskFilter.Created}
              onClick={() => updateHashState({ assigneeUserIdSelected: undefined })}
            >
              Created by Me
            </Radio.Button>
          </RadioGroup>
        )}
        <RadioGroup
          value={status}
          onChange={({ target: { value } }) => {
            setPage(1);
            updateHashState({ status: value });
            clearSelections();
          }}
        >
          <Radio.Button value={TaskStatus.New}>Incomplete</Radio.Button>
          <Radio.Button value={TaskStatus.Completed}>Completed</Radio.Button>
        </RadioGroup>
        {!dashboardMode && (
          <AsyncSelect
            value={assigneeUserIdSelected}
            selectProps={tenantsUserSelectProps}
            disabled={filter === TaskFilter.Assigned}
            entityPlural="users"
            placeholder="Filter assignee"
            allowClear
            onSelect={(value, _) => {
              setPage(1);
              updateHashState({ assigneeUserIdSelected: value });
            }}
            onClear={() => {
              setPage(1);
              updateHashState({ assigneeUserIdSelected: undefined });
            }}
            className={css`
              width: 220px;
            `}
            size="small"
          />
        )}
        <FlexSpacer />
        <SearchInput
          placeholder="Search topic, comments, or customer"
          query={searchQuery}
          onQueryChange={(newSearchQuery) => setSearchQuery(newSearchQuery)}
        />
        {hasSelected ? (
          <span>
            Selected {selectedRowKeys.length} {pluralize(selectedRowKeys.length, 'task')}
          </span>
        ) : null}
        <Dropdown
          disabled={!hasSelected}
          overlay={
            <Menu>
              <Menu.Item onClick={() => updateTaskStatuses(true)}>Completed</Menu.Item>
              <Menu.Item onClick={() => updateTaskStatuses(false)}>Incomplete</Menu.Item>
            </Menu>
          }
        >
          <Button type="primary" size="small" icon={<CheckOutlined />}>
            Mark As <DownOutlined />
          </Button>
        </Dropdown>
        {showNewTaskButton && (
          <NewTaskButton
            size="small"
            onSubmit={reload}
            initialValues={
              customerId && customerName
                ? {
                    customer: {
                      companyId,
                      foreignId: customerId,
                      name: customerName,
                    },
                  }
                : undefined
            }
          />
        )}
      </FilterBarBox>
      <Table
        rowSelection={rowSelection}
        columns={columns.filter(truthy)}
        data={data?.items || []}
        rowKey="id"
        isLoading={isLoading}
        onChange={(_pagination, _filters, sorter, { action }) => {
          if (action === 'sort' && !Array.isArray(sorter) && sorter.field) {
            setPage(1);
            setSortBy(sorter.field?.toString());
            setSortDir(sorter.order === 'ascend' ? SortDirection.Asc : SortDirection.Desc);
          }
        }}
        pagination={
          !isLoading &&
          !dashboardMode &&
          (data?.totalCount ?? 0) > pageSize && {
            onChange: (page) => setPage(page),
            pageSize,
            simple: true,
            current: page,
            total: data?.totalCount,
          }
        }
      />
      {dashboardMode && (
        <div
          className={css`
            margin-top: 16px;
            justify-content: center;
            display: flex;
          `}
        >
          {data?.items && <LinkButton route={routes.tasks.taskList()}>View All</LinkButton>}
        </div>
      )}
    </>
  );
};
