import React from 'react';

import { css } from '@emotion/css';
import { SalesOrderLineDTO } from '@recurrency/core-api-schema/dist/salesOrderLines/getSalesOrderLines';
import { SalesQuoteLineDTO } from '@recurrency/core-api-schema/dist/salesQuoteLines/getSalesQuoteLines';
import { theme } from 'theme';

import { Tooltip } from 'components/Tooltip';

/**
 * We Flatten Lines->Notes into a single array to pass into the component
 * using elements from both the line and note.
 */
interface FlattenedLineNote {
  title: string;
  note: string;
  mandatory?: boolean;
  areas: string[];
  topic: string;
  itemId: string;
}

/**
 * This returns a smaller custom table formatted to fit inside a Tooltip Popup
 */
function NoteTooltipDescription({ note }: { note: FlattenedLineNote }) {
  return (
    <div>
      <table
        className={css`
          width: 100%;
          tbody {
            tr {
              border-bottom: 1px solid rgb(255, 255, 255);
              &:last-child {
                border-bottom: none;
              }
            }
            td {
              &:first-child {
                vertical-align: top;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        `}
      >
        <tbody>
          <tr>
            <td>Topic:</td>
            <td>{note.topic}</td>
          </tr>
          <tr>
            <td>Areas:</td>
            <td>{`${note.areas.join(', ')}`}</td>
          </tr>
          <tr>
            <td>Mandatory:</td>
            <td>{note.mandatory ? 'Yes' : 'No'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function ItemHeader({ note }: { note: FlattenedLineNote }) {
  return (
    <div
      className={css`
        font-weight: 500;
        display: inline-block;
        color: ${theme.colors.neutral[500]};
      `}
    >
      <Tooltip slow title={<NoteTooltipDescription note={note} />} placement="bottom">
        <div>{note.title}</div>
      </Tooltip>
    </div>
  );
}

export function QuoteOrderLineNotes({
  orderLines,
  pinned = false,
  filter,
}: {
  orderLines: SalesOrderLineDTO[] | SalesQuoteLineDTO[];
  pinned: boolean;
  filter?: string;
}) {
  const lineNotes: FlattenedLineNote[] = [];
  orderLines?.forEach((line) => {
    line.notes?.forEach((note) => {
      if (pinned && !note.mandatory) return;
      if (!filter || filter === line.itemId) {
        lineNotes.push({
          title: `${line.itemId}: ${line.itemName}`,
          itemId: line.itemId,
          note: note.note,
          mandatory: note.mandatory,
          areas: note.areas,
          topic: note.topic,
        });
      }
    });
  });
  if (lineNotes.length) {
    return (
      <div>
        <ul
          className={css`
            width: 100%;
            padding-left: 0px;
            padding-top: 0px;
            list-style: none;
          `}
        >
          {lineNotes.map((lineNote, lineNoteIdx) => (
            <li
              key={`${lineNote.itemId}_${lineNoteIdx}`}
              className={css`
                padding-bottom: 8px;
              `}
            >
              <div>
                <ItemHeader note={lineNote} />
              </div>
              <div
                className={css`
                  padding-left: 8px;
                `}
              >
                {lineNote.note}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
}
