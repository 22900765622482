import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SalesQuoteLineDTO } from '@recurrency/core-api-schema/dist/salesQuoteLines/getSalesQuoteLines';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelect, AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { SearchSelect } from 'components/AsyncSelect/SearchSelect';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ButtonLink } from 'components/Links';
import { RadioGroup } from 'components/Radio/RadioGroup';
import { ResultCount } from 'components/ResultCount';

import { truthy } from 'utils/boolean';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { splitIdNameStr, formatShipTo, EMPTY_VALUE_DASHES } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableStringColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortablePercentColumn,
  erpStatusColumn,
} from 'utils/tables';

export const SalesQuoteLinesTable = ({
  itemId,
  companyId,
  customerId,
  locationId,
}: {
  itemId: string;
  companyId?: string;
  customerId?: string;
  locationId?: string;
}) => {
  const columns: ColumnType<SalesQuoteLineDTO>[] = [
    sortableIdColumn({
      title: 'Quote No',
      dataIndex: 'salesQuoteId',
      render: (salesQuoteId: string) => <Link to={routes.orders.quoteDetails(salesQuoteId)}>{salesQuoteId}</Link>,
      sorter: true,
    }),
    erpStatusColumn(),
    sortableDateColumn({
      title: 'Quote Date',
      dataIndex: 'quoteDate',
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableIdColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record: SalesQuoteLineDTO) =>
        record.locationId ? `${record.locationId}: ${record.locationName}` : '-',
      sorter: true,
    }),
    !customerId
      ? sortableIdColumn({
          title: 'Customer',
          dataIndex: 'customerId',
          render: (customerId: string, record: SalesQuoteLineDTO) => (
            <Link to={routes.sales.customerDetails(customerId)}>
              {customerId}: {record.customerName}
            </Link>
          ),
          sorter: true,
        })
      : undefined,
    sortableIdColumn({
      title: 'Ship To',
      dataIndex: 'shipToId',
      render: (_, record: SalesQuoteLineDTO) =>
        record.shipToId ? formatShipTo(record.shipToId, record.shipToName) : EMPTY_VALUE_DASHES,
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'quantityOrdered',
      sorter: true,
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      sorter: true,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      sorter: true,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      sorter: true,
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: 'grossMarginPercent',
      sorter: true,
    }),
    {
      render: ({ salesQuoteId }: { salesQuoteId: string }) => ButtonLink(routes.orders.quoteDetails(salesQuoteId)),
    },
  ].filter(truthy);

  const locationsSelectProps = useLocationsSelectProps({});
  const [status, setStatus] = useState<ErpOrderStatus>();
  const [filteredCustomerId, setFilteredCustomerId] = useState<string | undefined>(customerId);
  const [filteredShipToId, setFilteredShipToId] = useState<string>();
  const [filteredLocationId, setFilteredLocationId] = useState<string | undefined>(locationId);
  const [filteredDate, setFilteredDate] = useState<DateFilter>(getDefaultDateFilter);

  const tableProps = useCoreApiTableProps({
    schema: schemas.salesQuoteLines.getSalesQuoteLines,
    queryParams: {
      filter: {
        status,
        itemId,
        companyIds: companyId ? [companyId] : undefined,
        customerId: filteredCustomerId,
        shipToId: filteredShipToId,
        locationId: filteredLocationId,
        quoteDateFrom: formatMomentDateToSqlDate(filteredDate.from),
        quoteDateTo: formatMomentDateToSqlDate(filteredDate.to),
      },
    },
  });

  const { setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredCustomerId, filteredShipToId, itemId, filteredLocationId, status, setPage]);

  return (
    <>
      <FilterBarBox>
        <RadioGroup value={status} onChange={({ target: { value } }) => setStatus(value)}>
          <Radio.Button value={undefined}>All</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Open}>Open</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Completed}>Completed</Radio.Button>
        </RadioGroup>
        {!locationId && (
          <AsyncSelect
            className={tableFilterSelectClassName}
            selectProps={locationsSelectProps}
            entityPlural="locations"
            variant={AsyncSelectVariant.Filter}
            onSelect={(_, option) => setFilteredLocationId(splitIdNameStr(option.value).foreignId)}
            onClear={() => setFilteredLocationId(undefined)}
            allowClear
            size="small"
          />
        )}
        {!customerId && (
          <>
            <SearchSelect
              searchParams={{ searchIndex: SearchIndexName.Customers }}
              className={tableFilterSelectClassName}
              entityPlural="customers"
              variant={AsyncSelectVariant.Filter}
              onSelect={(_, option) => setFilteredCustomerId(splitIdNameStr(option.value).foreignId)}
              onClear={() => setFilteredCustomerId(undefined)}
              allowClear
              size="small"
            />
            <SearchSelect
              searchParams={{ searchIndex: SearchIndexName.ShipTos }}
              className={tableFilterSelectClassName}
              entityPlural="ship tos"
              variant={AsyncSelectVariant.Filter}
              onSelect={(_, option) => setFilteredShipToId(splitIdNameStr(option.value).foreignId)}
              onClear={() => setFilteredShipToId(undefined)}
              allowClear
              size="small"
            />
          </>
        )}
        <FlexSpacer />
        <DateRangePicker
          value={[filteredDate.from, filteredDate.to]}
          onChange={(values) => setFilteredDate(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={columns} />
    </>
  );
};
