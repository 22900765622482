import React, { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/css';

export interface SortableListProps<ItemT> {
  items: ItemT[];
  render: (item: ItemT) => JSX.Element;
  itemKey: (item: ItemT) => string;
  onChange: (newValue: ItemT[]) => void;
}

export function SortableList<ItemT>({ items, render, itemKey, onChange }: SortableListProps<ItemT>) {
  const [isDragging, setIsDragging] = useState(false);
  const [tempItems, setTempItems] = useState<ItemT[]>(items);
  const listRef = useRef<HTMLDivElement>(null);
  const draggedItemElRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTempItems(items);
  }, [items]);

  const handleDragHandleMouseDown = () => {
    setIsDragging(true);
  };

  const handleDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
    if (!isDragging) {
      ev.preventDefault();
      return;
    }

    draggedItemElRef.current = ev.currentTarget;
    ev.dataTransfer.effectAllowed = 'move';
    ev.currentTarget.classList.add('dragging');
  };

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = 'move';
  };

  const handleDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
    const draggedItemEl = draggedItemElRef.current;
    const targetEl = ev.currentTarget as HTMLElement;
    if (!draggedItemEl || draggedItemEl === targetEl) return;

    const itemEls = Array.from(listRef.current?.children || []) as HTMLElement[];
    const draggedIndex = itemEls.indexOf(draggedItemEl);
    const targetIndex = itemEls.indexOf(targetEl);

    // move draggedItem in correct position
    const draggedItem = items[draggedIndex];
    const newItems = items.filter((item) => item !== draggedItem);
    newItems.splice(targetIndex, 0, draggedItem);

    // drag animation
    if (!targetEl.getAnimations().length) {
      targetEl.animate(
        [
          {
            transform: `translateY(${draggedIndex > targetIndex ? '-' : ''}50%)`,
          },
          { transform: 'translateY(0%)' },
        ],
        {
          duration: 300,
          easing: 'ease-in-out',
        },
      );
    }

    onChange(newItems);
  };

  const handleDragEnd = (ev: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
    draggedItemElRef.current = null;
    ev.currentTarget.classList.remove('dragging');
    onChange(tempItems);
  };

  return (
    <div ref={listRef}>
      {tempItems.map((item) => (
        <div
          key={itemKey(item)}
          draggable={isDragging}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          className={css`
            display: flex;
            gap: 8px;
            align-items: center;
            transition: transform 0.2s ease;

            &.dragging {
              opacity: 0.1;
            }
          `}
        >
          <div
            id="dragHandle"
            onMouseDown={handleDragHandleMouseDown}
            className={css`
              cursor: move;
            `}
          >
            ⋮⋮
          </div>
          <div>{render(item)}</div>
        </div>
      ))}
    </div>
  );
}
