import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SalesOrderDTO } from '@recurrency/core-api-schema/dist/salesOrders/getSalesOrders';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelect, AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { SearchSelect } from 'components/AsyncSelect/SearchSelect';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ButtonLink } from 'components/Links';
import { RadioGroup } from 'components/Radio';
import { ResultCount } from 'components/ResultCount';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { optArrFromVal } from 'utils/array';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatShipTo, splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarColumn,
  sortablePercentColumn,
  sortableIdColumn,
  sortableBooleanColumn,
  erpStatusColumn,
} from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';

export const SalesOrdersTable = ({ customerId, companyId }: { customerId?: string; companyId?: string }) => {
  const { activeTenant } = useGlobalApp();
  const isErpTypeP21 = isTenantErpTypeP21(activeTenant.erpType);

  const columns: ColumnType<SalesOrderDTO>[] = [
    sortableIdColumn({
      title: 'Order No',
      dataIndex: 'salesOrderId',
      render: (id: string) => <Link to={routes.orders.orderDetails(id)}>{id}</Link>,
      sorter: true,
    }),
    erpStatusColumn(),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: 'orderDate',
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableDateColumn({
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableIdColumn({
      title: 'PO No',
      dataIndex: 'customerPORef',
      sorter: true,
    }),
    isErpTypeP21
      ? sortableIdColumn({
          title: 'Location',
          dataIndex: 'locationId',
          render: (locationId: string, record: SalesOrderDTO) => `${locationId}: ${record.locationName}`,
          sorter: true,
        })
      : null,
    sortableIdColumn({
      title: 'Ship To',
      dataIndex: 'shipToId',
      render: (_, record: SalesOrderDTO) => formatShipTo(record.shipToId, record.shipToName),
      sorter: true,
    }),
    sortableDollarColumn({
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      sorter: true,
    }),
    sortableDollarColumn({
      title: 'Open Amount',
      dataIndex: 'openAmount',
      sorter: true,
    }),
    sortableDollarColumn({
      title: 'GM',
      dataIndex: 'grossMargin',
      sorter: true,
    }),
    sortablePercentColumn({
      title: 'GM%',
      dataIndex: 'grossMarginPercent',
      sorter: true,
    }),
    isErpTypeP21
      ? sortableBooleanColumn({
          title: 'Back Ordered',
          dataIndex: 'numBackorderedLines',
          sorter: true,
        })
      : null,
    sortableNumberColumn({
      title: 'Line Count',
      dataIndex: 'lineCount',
      sorter: true,
    }),
    {
      render: ({ salesOrderId }: any) => ButtonLink(routes.orders.orderDetails(salesOrderId)),
    },
  ].filter(filterCostAndGM);

  const [status, setStatus] = useState<ErpOrderStatus>();

  const locationsSelectProps = useLocationsSelectProps({ companyIds: optArrFromVal(companyId) });
  const [filteredLocationId, setFilteredLocationId] = useState<string>();

  const [filteredShipToId, setFilteredShipToId] = useState<string>();
  const [filteredDate, setFilteredDate] = useState<DateFilter>(getDefaultDateFilter);

  const tableProps = useCoreApiTableProps({
    schema: schemas.salesOrders.getSalesOrders,
    queryParams: {
      filter: {
        status,
        customerId,
        locationId: filteredLocationId,
        shipToId: filteredShipToId,
        companyIds: companyId ? [companyId] : undefined,
        orderDateFrom: formatMomentDateToSqlDate(filteredDate.from),
        orderDateTo: formatMomentDateToSqlDate(filteredDate.to),
      },
    },
  });

  const { setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [customerId, filteredLocationId, filteredShipToId, status, setPage]);

  return (
    <>
      <FilterBarBox>
        <RadioGroup value={status} onChange={({ target: { value } }) => setStatus(value)}>
          <Radio.Button value={undefined}>All</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Open}>Open</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Completed}>Completed</Radio.Button>
        </RadioGroup>
        {isErpTypeP21 && (
          <AsyncSelect
            className={tableFilterSelectClassName}
            selectProps={locationsSelectProps}
            entityPlural="locations"
            variant={AsyncSelectVariant.Filter}
            onSelect={(_, option) => {
              setFilteredLocationId(splitIdNameStr(option.value).foreignId);
            }}
            onClear={() => setFilteredLocationId(undefined)}
            allowClear
            size="small"
          />
        )}
        <SearchSelect
          searchParams={{ searchIndex: SearchIndexName.ShipTos, selectParams: { customerId, companyId } }}
          className={tableFilterSelectClassName}
          entityPlural="ship tos"
          variant={AsyncSelectVariant.Filter}
          onSelect={(_, option) => setFilteredShipToId(splitIdNameStr(option.value).foreignId)}
          onClear={() => setFilteredShipToId(undefined)}
          allowClear
          size="small"
        />
        <FlexSpacer />
        <DateRangePicker
          value={[filteredDate.from, filteredDate.to]}
          onChange={(values) => setFilteredDate(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={columns} />
    </>
  );
};
