import React from 'react';

import { Col, Row } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { Typography } from 'components/Typography';

import { truthy } from 'utils/boolean';
import { formatDate, formatNumber, formatUSD, splitIfIdNameStr } from 'utils/formatting';
import { typedColumn } from 'utils/tables';

import { OrderEditHashStateSAPB1, OrderLineItemSAPB1 } from 'types/hash-state';

import * as Styled from '../../quotes/QuoteEditFlowP21/QuoteReview/QuoteReview.style';
import { OrderEditLineItemSummaryStats } from './OrderEditLineItemsStep/OrderEditLineItemSummaryStats';
import { getNumLineItems } from './orderUtils';

export function OrderEditReviewStep({ docState }: { docState: OrderEditHashStateSAPB1 }) {
  const lineItems = docState.items || [];
  const lineItemColumns: ColumnType<OrderLineItemSAPB1>[] = [
    typedColumn({
      title: '#',
      render: (_, _lineItem, index) => <>{index + 1}</>,
    }),
    typedColumn({
      title: 'Item ID',
      dataIndex: 'foreignId',
      width: '15%',
    }),
    typedColumn({
      title: 'Item Name',
      dataIndex: 'name',
    }),
    typedColumn({
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '10%',
      render: (val?: number) => formatNumber(val),
    }),
    typedColumn({ title: 'UOM', dataIndex: 'unitOfMeasure', width: '10%' }),
    typedColumn({
      title: 'Price per UOM',
      dataIndex: 'unitPrice',
      width: '10%',

      render: (val?: number) => formatUSD(val, true),
    }),
    {
      title: 'Ext. Price',
      width: '10%',
      render: (_, lineItem) => formatUSD((lineItem.unitPrice || 0) * (lineItem.quantity || 0), true, 2),
      align: 'right' as const,
    },
  ];

  const headerRows = [
    {
      title: 'Customer',
      content: docState.customer,
    },
    {
      title: 'Ship To',
      content: splitIfIdNameStr(docState.shipTo)?.foreignId || '',
    },
    {
      title: 'Contact',
      content: docState.contact,
    },
    {
      title: 'Location',
      content: docState.location,
    },
    {
      title: 'Carrier',
      content: docState.carrier,
    },
    docState.shippingRouteId
      ? {
          title: 'Shipping Route',
          content: docState.shippingRouteId,
        }
      : null,
    {
      title: 'Due Date',
      content: formatDate(docState.dueDate),
    },
    {
      title: 'Customer PO No',
      content: docState.customerPORef,
    },
    docState.customerNote
      ? {
          title: 'Customer Note',
          content: docState.customerNote,
        }
      : null,
  ].filter(truthy);

  return (
    <Styled.Container>
      <Row gutter={24}>
        <Col xs={24} lg={18}>
          <Styled.Content>
            <Styled.Header>
              <Typography>Items ({getNumLineItems(lineItems)})</Typography>
            </Styled.Header>
            <Styled.ItemTable data={lineItems} columns={lineItemColumns} pagination={false} size="small" />
          </Styled.Content>
          <Styled.Footer>
            <Styled.FooterContent>
              <OrderEditLineItemSummaryStats lineItems={lineItems} />
            </Styled.FooterContent>
          </Styled.Footer>
        </Col>
        <Col xs={24} lg={6}>
          <Styled.Box>
            {headerRows.map((headerRow, idx) => (
              <Styled.HeaderRow key={idx}>
                <Styled.HeaderTitle>
                  <Typography type="subtitle">{headerRow.title}:</Typography>
                </Styled.HeaderTitle>
                <div>{headerRow.content || ''}</div>
              </Styled.HeaderRow>
            ))}
          </Styled.Box>
          <br />
        </Col>
      </Row>
    </Styled.Container>
  );
}
