import React from 'react';

import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { browserHistory } from './browserHistory';
import { track, TrackEvent } from './track';

export interface CloseableModalProps<ValueT> {
  onClose: (value?: ValueT) => void;
}

export function showAsyncModal<PropsT extends CloseableModalProps<Any>>(
  modalComponent: (props: PropsT) => React.ReactElement,
  props: Omit<PropsT, 'onClose'>,
): Promise<Parameters<PropsT['onClose']>[0] | undefined> {
  return new Promise((resolve) => {
    const modalName = modalComponent.name;
    const containerEl = document.createElement('div');

    const unlisteners: Array<() => void> = [];
    const onClose = (returnVal?: Any) => {
      ReactDOM.unmountComponentAtNode(containerEl);
      track(TrackEvent.Components_Modal_VisibilityChange, {
        actionType: 'close',
        modalName,
      });
      unlisteners.forEach((unlisten) => unlisten());
      resolve(returnVal);
    };

    // when user clicks on link inside modal, that goes to another route, close the modal
    unlisteners.push(
      browserHistory.listen((_location, action) => {
        if (action !== 'REPLACE') {
          onClose();
        }
      }),
    );

    // Need to wrap modal with Router or else <Link> elements fail with 'You should not use <Link> outside a <Router>'
    const modalProps: Any = { ...props, onClose };
    ReactDOM.render(
      React.createElement(Router, { history: browserHistory }, React.createElement(modalComponent, modalProps)),
      containerEl,
    );
    track(TrackEvent.Components_Modal_VisibilityChange, {
      actionType: 'open',
      modalName,
    });
  });
}
