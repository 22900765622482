import React from 'react';

import { ButtonType } from 'antd/lib/button';

import { Button } from 'components/Button';

import { Modal } from './Modal';

/** similar interface to Modal.confirm but an actual recurrency styled modal */
export function ConfirmModal({
  title,
  content,
  okText = 'OK',
  okType = 'primary',
  cancelText = 'Cancel',
  width = 600,
  onClose,
}: {
  title: React.ReactNode;
  content?: React.ReactNode;
  okText?: string;
  okType?: ButtonType;
  cancelText?: string;
  width?: number;
  onClose: (didConfirm: boolean) => void;
}) {
  return (
    <Modal
      visible
      title={title}
      onCancel={() => onClose(false)}
      width={width}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>{cancelText}</Button>
          <Button onClick={() => onClose(true)} type={okType}>
            {okText}
          </Button>
        </>
      }
    >
      {content}
    </Modal>
  );
}
