import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { GetInventoryReportingQueryParams } from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import moment from 'moment';

import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';

import { useCoreApi } from 'hooks/useApi';

import { formatPercent } from 'utils/formatting';

const LOOKBACK_FROM_MONTHS = 6;
const LOOKBACK_TO_MONTHS = 12;

const compareDates = (lookbackMonths: number): string[] => {
  const startOfMonth = moment().startOf('month');
  return Array.from({ length: lookbackMonths }, (_, index) =>
    moment(startOfMonth)
      .subtract(index + 1, 'month')
      .toISOString(),
  );
};

export function FillRatePanelContent({ itemId, locationId }: { itemId: string; locationId: string }) {
  const apiQueryParams: GetInventoryReportingQueryParams = {
    filters: {
      companyIds: undefined,
      locationIds: [locationId],
      itemIds: [itemId],
      itemGroupIds: undefined,
      supplierIds: undefined,
      buyerIds: undefined,
      purchaseClasses: undefined,
      stockable: undefined,
    },
    groupBy: undefined,
    compareFrom: compareDates(LOOKBACK_FROM_MONTHS),
    compareTo: compareDates(LOOKBACK_TO_MONTHS),
    sortBy: undefined,
    limit: undefined,
    offset: undefined,
  };

  const { data, error, isLoading } = useCoreApi(schemas.inventory.getInventoryDashboardReporting, {
    queryParams: apiQueryParams,
  });

  const tableData =
    (data?.totalCount ?? 0) > 0
      ? [
          {
            name: 'Past 6 Months',
            value: formatPercent(Number(data?.items[0].compareFrom.fillRate) / 100),
          },
          {
            name: 'Past 12 Months',
            value: formatPercent(Number(data?.items[0].compareTo.fillRate) / 100),
          },
        ]
      : [];

  return (
    <>
      <MiniTable
        numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
        data={tableData}
        columns={[
          { render: (record) => record.name, align: `left` },
          { render: (record) => record.value, align: `right` },
        ]}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
}
