import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  GetInventoryDashboardQueryParams,
  InventoryDashboardGroupBy,
} from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import { InventoryStatusResultDTO } from '@recurrency/core-api-schema/dist/inventory/inventoryDashboardResultDTO';
import { Divider, Empty } from 'antd';
import { ResponsiveContainer } from 'recharts';
import { theme } from 'theme';

import { ActionButton } from 'components/Button/ActionButton';
import { FlexSpace } from 'components/FlexSpace';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

import { formatNumber, formatUSD } from 'utils/formatting';

import { InventoryDashboardHashStateFilters, InventoryDashboardStatusHashState } from 'types/hash-state';

import { CardLoader } from '../shared/CardLoader';
import { IconReferencDot } from '../shared/IconReferenceDot';
import { InteractiveBarChart } from '../shared/InteractiveBarChart';

export function ZoomInBar({
  hashState,
  updateHashStateFn,
  apiQueryParams,
}: {
  hashState: InventoryDashboardStatusHashState;
  updateHashStateFn: (update: Partial<InventoryDashboardStatusHashState>) => void;
  apiQueryParams: GetInventoryDashboardQueryParams;
}) {
  const viewValue = () => hashState.displayUnit === 'value';

  const updateFilterValue = (filterName: keyof InventoryDashboardHashStateFilters, value: string, reset = true) => {
    if (filterName === 'timeSpan') return;
    const updatedFilters = { ...(hashState.filters ?? {}) };
    updatedFilters[filterName] = reset ? [value] : [...(updatedFilters[filterName] ?? []), value];
    updateHashStateFn({ filters: updatedFilters });
  };

  const clearFilterValue = (filterName: keyof InventoryDashboardHashStateFilters) => {
    if (filterName === 'timeSpan') return;
    const updatedFilters = { ...(hashState.filters ?? {}) };
    updatedFilters[filterName] = [];
    updateHashStateFn({ filters: updatedFilters });
  };

  // Status Totals
  const {
    isLoading: statusTotalsLoading,
    data: statusTotalsData,
    error: statusTotalsError,
  } = useCoreApi(schemas.inventory.getInventoryDashboardStatusBreakdown, {
    queryParams: { ...apiQueryParams, limit: 10, groupBy: undefined },
  });

  const statusData = (statusField: string): number => {
    const apiData = statusTotalsData?.items ?? [];
    const totalsData = (apiData[0] ?? {}) as Omit<InventoryStatusResultDTO, 'current' | 'netStock' | 'id'>;
    const key = statusField as keyof typeof totalsData;
    const keyData = totalsData[key] ?? { value: 0, volume: 0 };
    return viewValue() ? keyData.value : keyData.volume;
  };

  const formatTotals = (total?: number) => (hashState.displayUnit === 'value' ? formatUSD(total) : formatNumber(total));

  const TotalRow = ({ field, text, color, value }: { field: string; text: string; color: string; value?: string }) => (
    <div
      className={css`
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 4px;
      `}
    >
      <div
        className={css`
          display: inline-flex;
          align-items: center;
        `}
      >
        <IconReferencDot color={color} />
        <Typography type="subtitle">{text}:</Typography>
      </div>
      <Typography type="subtitle">{value ?? formatTotals(statusData(field))}</Typography>
    </div>
  );

  return (
    <div
      className={css`
        margin-bottom: 20px;
        margin-top: 0px;
      `}
    >
      <div
        className={css`
          margin-bottom: 8px;
        `}
      >
        <ActionButton label="Hide Charts" onClick={() => updateHashStateFn({ zoomEnabled: undefined })} />
      </div>
      <FlexSpace gap={16}>
        <div
          className={css`
            border: 1px solid ${theme.colors.neutral[300]};
            border-radius: 8px;
            padding: 8px;
            min-width: 300px;
            width: 100%;
            box-shadow: 2px 2px 4px 0px ${theme.colors.neutral[100]};
            overflow: hidden;
          `}
        >
          <ResponsiveContainer minWidth={260} height={300}>
            {statusTotalsLoading ? (
              <CardLoader height={200} />
            ) : !statusTotalsData || statusTotalsError ? (
              <div
                className={css`
                  width: 100%;
                  min-width: 260px;
                  height: 100%;
                  min-height: 200px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <Empty />
              </div>
            ) : (
              <>
                <div
                  className={css`
                    padding: 0 4px 8px;
                  `}
                >
                  <Typography type="middle">Totals</Typography>
                  <Divider
                    className={css`
                      margin: 8px 0px;
                    `}
                  />
                  <TotalRow field="normalstock" text="Normal" color={theme.colors.chart.oceanTeal} />
                  <TotalRow field="overstock" text="Overstock" color={theme.colors.chart.sunriseYellow} />
                  <TotalRow field="deadstock" text="Dead Stock" color={theme.colors.danger[500]} />
                  <Divider
                    className={css`
                      margin: 8px 0px;
                    `}
                  />
                  <TotalRow field="onHand" text="On Hand" color="white" />
                  <TotalRow field="allocated" text="Allocated" color="white" />
                  <TotalRow
                    field="available"
                    text="Available"
                    color="white"
                    value={formatTotals(statusData('onHand') - statusData('allocated'))}
                  />
                  <Divider
                    className={css`
                      margin: 8px 0px;
                    `}
                  />
                </div>
              </>
            )}
          </ResponsiveContainer>
        </div>
        <InteractiveBarChart<InventoryDashboardHashStateFilters>
          displayUnit={hashState.displayUnit}
          groupBy={InventoryDashboardGroupBy.Location}
          queryParams={apiQueryParams}
          label="Location by ID"
          filterKey="locationIds"
          onUpdateState={updateFilterValue}
          onClearState={clearFilterValue}
        />
        <InteractiveBarChart<InventoryDashboardHashStateFilters>
          displayUnit={hashState.displayUnit}
          groupBy={InventoryDashboardGroupBy.ItemGroup}
          queryParams={apiQueryParams}
          label="Product Group by ID"
          filterKey="itemGroupIds"
          onUpdateState={updateFilterValue}
          onClearState={clearFilterValue}
        />
        <InteractiveBarChart<InventoryDashboardHashStateFilters>
          displayUnit={hashState.displayUnit}
          groupBy={InventoryDashboardGroupBy.PurchaseClass}
          queryParams={apiQueryParams}
          label="ABC Class"
          filterKey="purchaseClasses"
          onUpdateState={updateFilterValue}
          onClearState={clearFilterValue}
        />
      </FlexSpace>
    </div>
  );
}
