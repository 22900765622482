import React from 'react';

import { TenantDataSyncConfigs } from '@recurrency/core-api-schema/dist/tenantDataSyncConfigs/getTenantDataSyncConfigs';
import { Checkbox, Form, FormInstance, Modal, Select } from 'antd';

import { Button } from 'components/Button';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { TextArea } from 'components/TextArea';
import { Typography } from 'components/Typography';

import { ExtendedUser } from 'hooks/useGlobalApp';

export type TenantDataSyncConfigsModalProps = {
  onClose: () => void;
  activeUser: ExtendedUser;
  form: FormInstance<TenantDataSyncConfigs>;
  handleSubmit: (values: TenantDataSyncConfigs) => Promise<void>;
};

function processFilterString(filterString: string | string[] | undefined | null): string[] {
  if (filterString === undefined || filterString === null) {
    return [];
  }
  return Array.isArray(filterString)
    ? filterString
    : String(filterString)
        .split(',')
        .map((element) => element.trim())
        .filter((element) => element !== '');
}

export function TenantDataSyncConfigsModal({
  form,
  handleSubmit,
  onClose,
  activeUser,
}: TenantDataSyncConfigsModalProps) {
  async function onFinish(values: TenantDataSyncConfigs) {
    // Convert string to a number
    const parsedValues = {
      ...values,
      redisDedupeTtl: Number(values.redisDedupeTtl),
      taskChunkSize: Number(values.taskChunkSize),
      taskConcurrency: Number(values.taskConcurrency),
      taskExporterConcurrency: Number(values.taskExporterConcurrency),
      filtersExclude: processFilterString(values.filtersExclude),
      filtersInclude: processFilterString(values.filtersInclude),
    };
    await handleSubmit(parsedValues);
    onClose();
  }

  return (
    <Modal
      forceRender
      title="Edit Tenant Data Sync Configs"
      visible
      onCancel={onClose}
      width={800}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" form="tenantDataSyncConfigsForm" htmlType="submit">
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="tenantDataSyncConfigsForm"
        onFinish={onFinish}
        {...responsiveFormLayout}
        labelCol={{ span: 7 }}
      >
        <Form.Item label="AWS Region" name="awsRegion">
          <Select>
            <Select.Option value="us-west-1">US West 1 (us-west-1)</Select.Option>
            <Select.Option value="us-east-1">US East 1 (us-east-1)</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Filters Exclude" name="filtersExclude">
          <TextArea rows={3} placeholder="Comma-separated values w/o space" />
        </Form.Item>

        <Form.Item label="Filters Include" name="filtersInclude">
          <TextArea rows={2} placeholder="Comma-separated values w/o space" />
        </Form.Item>

        <Form.Item label="PG Schema S3 Key" name="postgresSchemaS3Key">
          <Input />
        </Form.Item>

        <Form.Item label="PG Schema S3 Bucket" name="postgresSchemaS3Bucket">
          <Input />
        </Form.Item>

        <Form.Item label="SF Schema S3 Key" name="snowflakeSchemaS3Key">
          <Input />
        </Form.Item>

        <Form.Item label="SF Schema S3 Bucket" name="snowflakeSchemaS3Bucket">
          <Input />
        </Form.Item>

        <Form.Item label="SF Recurrency Schema S3 Key" name="snowflakeRecurrencySchemaS3Key">
          <Input />
        </Form.Item>

        <Form.Item label="Redis Dedupe TTL" name="redisDedupeTtl">
          <Input type="number" min={1000} />
        </Form.Item>

        <Form.Item label="Change Tracking Enabled" name="taskChangeTracking" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item label="Task Chunk Size" name="taskChunkSize">
          <Input type="number" min={100} max={100_000} />
        </Form.Item>

        <Form.Item label="Broker Sync Concurrency" name="taskConcurrency">
          <Input type="number" min={1} max={20} />
        </Form.Item>

        <Form.Item label="Exporter Concurrency" name="taskExporterConcurrency">
          <Input type="number" min={1} max={12} />
        </Form.Item>

        <Form.Item label="Task Window" name="taskWindow">
          <Input />
        </Form.Item>

        <Form.Item label="Task Schedule" name="taskSchedule">
          <Input />
        </Form.Item>

        {/* Since below fields will be read-only fields, display them as plain text. */}
        <Form.Item label="Created At">
          <Typography>{form.getFieldValue('createdAt')}</Typography>
        </Form.Item>

        <Form.Item label="Updated At">
          <Typography>{form.getFieldValue('updatedAt')}</Typography>
        </Form.Item>

        <Form.Item label="Created By">
          <Typography>{form.getFieldValue('createdBy')}</Typography>
        </Form.Item>

        <Form.Item label="Updated By">
          <Typography>{form.getFieldValue('updatedBy')}</Typography>
        </Form.Item>

        <Form.Item label="Current User Email" name="updatedBy">
          <Typography>{activeUser.email}</Typography>
        </Form.Item>
      </Form>
    </Modal>
  );
}
