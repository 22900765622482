import React from 'react';

import { css } from '@emotion/css';

import { AsyncSelect } from '../../../../../components/AsyncSelect';
import { useCarriersSelectProps } from '../../../../../components/AsyncSelect/useAsyncSelectProps';
import { IdNameObj } from '../../../../../types/legacy-api';
import { joinIfIdNameObj, splitIdNameStr } from '../../../../../utils/formatting';

export function CarrierCell({ value, onChange }: { value?: IdNameObj; onChange: (newValue?: IdNameObj) => void }) {
  return (
    <AsyncSelect
      className={css`
        width: 100%;
      `}
      selectProps={useCarriersSelectProps()}
      entityPlural="carrier"
      value={joinIfIdNameObj(value)}
      onSelect={(newValue: string) => onChange(splitIdNameStr(newValue))}
      onClear={() => onChange(undefined)}
      dropdownMatchSelectWidth={550}
      allowClear
    />
  );
}
