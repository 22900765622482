import React from 'react';

import { QuoteOrderLineScheduleDTO } from '@recurrency/core-api-schema/dist/common/quoteOrderLineScheduleDTO';
import { ColumnType } from 'antd/lib/table';

import { Table } from 'components/Table';

import { formatDate, joinTruthy } from 'utils/formatting';
import { sortableNumberColumn, sortableStringColumn } from 'utils/tables';

import { lineItemDispositionMap } from 'types/legacy-api';

export const OrderLineSchedulesTable = ({
  orderLineSchedules,
}: {
  orderLineSchedules: QuoteOrderLineScheduleDTO[];
}) => {
  const orderLineScheduleColumns: ColumnType<QuoteOrderLineScheduleDTO>[] = [
    sortableStringColumn({
      title: 'Release No',
      fixed: 'left',
      dataIndex: 'releaseNo',
    }),
    sortableStringColumn({
      title: 'Release Date',
      dataIndex: 'releaseDate',
      render: (value) => formatDate(value),
    }),
    sortableNumberColumn({
      title: 'Qty Released',
      dataIndex: 'quantityReleased',
    }),
    {
      title: 'Expedite Time',
      dataIndex: 'expediteValue',
      render: (_: any, record: QuoteOrderLineScheduleDTO) =>
        record.expediteValue || record.expediteType
          ? joinTruthy([record.expediteValue, record.expediteType], ' ')
          : '-',
    },
    {
      title: 'Pick Time',
      dataIndex: 'pickValue',
      render: (_: any, record: QuoteOrderLineScheduleDTO) =>
        record.pickValue || record.pickType ? joinTruthy([record.pickValue, record.pickType], ' ') : '-',
    },
    {
      title: 'Disposition',
      dataIndex: 'disposition',
      render: (disposition: string) => lineItemDispositionMap[disposition] || disposition?.trim() || '-',
    },
    sortableNumberColumn({
      title: 'Qty Allocated',
      dataIndex: 'quantityAllocated',
    }),
    sortableNumberColumn({
      title: 'Qty Picked',
      dataIndex: 'quantityPicked',
    }),
    sortableNumberColumn({
      title: 'Qty Invoiced',
      dataIndex: 'quantityInvoiced',
    }),
    sortableNumberColumn({
      title: 'Qty Canceled',
      dataIndex: 'quantityCanceled',
    }),
  ];

  return <Table columns={orderLineScheduleColumns} scroll={{ x: true }} data={orderLineSchedules} />;
};
