import React from 'react';

import { ContactsTable } from 'pages/sales/customers/CustomerDetailsPage/tabs/ContactsTable';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

export function ContactListModal({
  customerId,
  customerName,
  onClose,
}: {
  customerId: string;
  customerName: string;
  onClose: () => void;
}) {
  return (
    <Modal
      destroyOnClose
      visible
      title="Contacts"
      width={1000}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="small">
          Close
        </Button>
      }
    >
      <ContactsTable customerId={customerId} customerName={customerName} showNewContactButton={false} />
    </Modal>
  );
}
