import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { Button } from 'components/Button';
import { responsiveFormLayout } from 'components/FormItems';
import { Modal } from 'components/Modal';
import { Select, SelectOption } from 'components/Select';

import { useCoreApi } from 'hooks/useApi';

import { QuoteEditHashStateP21 } from 'types/hash-state';

export interface PriceOverrideFormState {
  priceOverrideReason?: string;
  priceOverrideComments?: string;
}

export interface PriceOverrideModalProps {
  quoteState: QuoteEditHashStateP21;
  onOk: (value: PriceOverrideFormState) => void;
  onClose: () => void;
}

export const PriceOverrideModal = ({ quoteState, onOk, onClose }: PriceOverrideModalProps) => {
  const [priceOverrideReasonForm] = Form.useForm<PriceOverrideFormState>();
  const { data: priceOverrideReasonsData, isLoading: isPriceOverrideReasonsLoading } = useCoreApi(
    schemas.orderPriceOverrideReasons.getOrderPriceOverrideReasons,
  );

  const priceOverrideReasonSelectOptions: SelectOption[] = (priceOverrideReasonsData?.items || []).map(
    (priceOverrideItem) => ({
      // The transaction api uses the description, not the code, for selection
      value: priceOverrideItem.description,
      label: priceOverrideItem.description,
    }),
  );

  useEffect(() => {
    // when quoteState changes, we bind the values to priceOverrideReasonForm
    priceOverrideReasonForm.setFieldsValue({
      priceOverrideReason: quoteState.priceOverrideReason,
      priceOverrideComments: quoteState.priceOverrideComments,
    });
  }, [priceOverrideReasonForm, quoteState]);

  const handleFormSubmit = (formData: PriceOverrideFormState) => {
    onOk(formData);
    onClose();
  };

  return (
    <>
      <Modal
        visible
        centered
        closable={false}
        title="Reason for Changing Item(s) Prices"
        width={700}
        footer={
          <Button key="submit" type="primary" form="priceOverrideReasonForm" htmlType="submit">
            OK
          </Button>
        }
      >
        <div
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          You have changed pricing for item(s) on this order. Please enter a reason.
        </div>
        <br />

        <Form
          name="priceOverrideReasonForm"
          form={priceOverrideReasonForm}
          {...responsiveFormLayout}
          onFinish={handleFormSubmit}
        >
          <Form.Item
            label="Reason"
            name="priceOverrideReason"
            rules={[{ required: true, message: 'Please select a reason' }]}
          >
            <Select
              loading={isPriceOverrideReasonsLoading}
              options={priceOverrideReasonSelectOptions}
              placeholder="Please select a reason"
            />
          </Form.Item>
          <Form.Item
            label="Comments"
            name="priceOverrideComments"
            rules={[{ required: true, message: 'Please enter a comment' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
