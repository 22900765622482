import React from 'react';

import { css } from '@emotion/css';
import { PurchaseGroupWithTargetDTO } from '@recurrency/core-api-schema/dist/purchaseGroups/getPurchaseGroups';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import { AsyncTable } from 'components/AsyncTable';
import { UseAsyncTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { SelectionCountAndClear } from 'components/SelectionCountAndClear';

import { UpdateHashStateOptions } from 'utils/routes';

import { PurchaseGroupsHashState } from '../PurchaseGroupsPage.types';

const getRowKey = (record: PurchaseGroupWithTargetDTO) => record.groupId;

type PurchaseGroupsTableProps = {
  tableProps: UseAsyncTableProps<PurchaseGroupWithTargetDTO>;
  tableColumns: ColumnType<PurchaseGroupWithTargetDTO>[];
  selectedRowKeys: string[];
  updateHashState: (update: Partial<PurchaseGroupsHashState>, options?: UpdateHashStateOptions | undefined) => void;
};

export function PurchaseGroupsTable({
  tableColumns,
  tableProps,
  selectedRowKeys,
  updateHashState,
}: PurchaseGroupsTableProps) {
  return (
    <>
      <SelectionCountAndClear
        numSelected={selectedRowKeys.length}
        entitySingle="row"
        onClear={() => updateHashState({ selectedRowKeys: undefined })}
      />
      <AsyncTable<PurchaseGroupWithTargetDTO>
        className={css`
          .ant-table-row-level-1 {
            background-color: ${theme.colors.neutral[100]};
          }
        `}
        tableProps={tableProps}
        columns={tableColumns}
        rowKey={getRowKey}
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => {
            const newKeys = newSelectedRowKeys.map((k) => String(k));
            updateHashState({ selectedRowKeys: newKeys });
          },
        }}
      />
    </>
  );
}
