import { captureError } from './error';

// 'rai:' prefixed keys are json encoded. We prefix so the keys don't collide with 3rd party keys.
export const enum SessionStorageKey {
  SessionId = 'rai:SessionId',
}

export interface TypedSessionStorage {
  [SessionStorageKey.SessionId]: string;
}

export function setSessionStorageItem<KeyT extends keyof TypedSessionStorage>(
  key: KeyT,
  value: TypedSessionStorage[KeyT],
) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    // setItem could fail with QuotaExceededError if the storage is full
    captureError(err);
  }
}

export function getSessionStorageItem<KeyT extends keyof TypedSessionStorage>(
  key: KeyT,
): TypedSessionStorage[KeyT] | null {
  try {
    const jsonStr = sessionStorage.getItem(key);
    if (jsonStr) {
      return JSON.parse(jsonStr);
    }
  } catch (err) {
    // gracefully fail if json parsing fails
    captureError(err);
    sessionStorage.removeItem(key);
  }
  return null;
}

export function hasSessionStorageItem<KeyT extends keyof TypedSessionStorage>(key: KeyT): boolean {
  return Object.prototype.hasOwnProperty.call(sessionStorage, key);
}
