import React from 'react';

import { css, cx } from '@emotion/css';
import { theme } from 'theme';

export function ActionButton({
  onClick,
  label,
  className,
  dataTestId,
}: {
  label: React.ReactChild;
  onClick?: () => void;
  className?: string;
  dataTestId?: string;
}) {
  return (
    <div
      data-test-id={dataTestId}
      className={cx(
        css`
          color: ${theme.colors.info[600]};
          cursor: pointer;
          &:hover {
            color: ${theme.colors.info[500]};
          }
        `,
        className,
      )}
      onClick={onClick}
    >
      {label}
    </div>
  );
}
