import React, { useCallback, useMemo, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { QuoteStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { ForeignIdNameReference } from '@recurrency/core-api-schema/dist/salesQuoteDrafts/common';
import { CreateSalesQuoteBody } from '@recurrency/core-api-schema/dist/salesQuotes/createSalesQuote';
import { notification, Modal as AntdModal, message } from 'antd';

import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { Input } from 'components/Input/Input';
import { NotificationLink } from 'components/Links';
import { CenteredError } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { PageHeader } from 'components/PageHeader';
import { EmailQuoteOrderPDFButton } from 'components/recipes/EmailQuotePDFButton';
import { DEFAULT_PAGE_SIZE, Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes, useHashState } from 'utils/routes';

import { DraftQuotesListHashState } from 'types/hash-state';

import { QuoteWithTotalInfo } from '../types';
import { getDraftQuoteListColumns } from './tableColumns';

export const DraftQuoteListPage = () => {
  const [hashState, updateHashState] = useHashState<DraftQuotesListHashState>();
  // antd table uses 1 based page size
  const { page = 1 } = hashState;
  const pageSize = DEFAULT_PAGE_SIZE;
  const { data, isLoading, error, reload } = useCoreApi(schemas.salesQuoteDrafts.getSalesQuoteDrafts, {
    queryParams: {
      limit: pageSize,
      offset: (page - 1) * pageSize,
    },
  });

  const [isSaveAsModalOpen, setIsSaveAsModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newDraftName, setNewDraftName] = useState('');
  const [selectedQuoteId, setSelectedQuoteId] = useState<Maybe<string>>(undefined);

  const quotes: QuoteWithTotalInfo[] = useMemo(() => {
    if (data?.items) {
      return data.items.map((item) => ({
        ...item,
        totalLines: item?.metadata?.items?.length,
        totalPrice: item?.metadata?.items?.reduce(
          (total, metaItem) => (metaItem.quantity ?? 0) * metaItem.price + total,
          0,
        ),
      }));
    }
    return [];
  }, [data]);
  const totalCount = data?.totalCount ?? 0;

  const [selectedQuotes, setSelectedQuotes] = useState<{ id: string; customer: ForeignIdNameReference }[]>([]);

  const handleTableItemSelect = (selectedItem: QuoteWithTotalInfo, selected: boolean) => {
    const previouslySelected = selectedQuotes.map((q) => q.id).includes(selectedItem.id);
    if (selected && !previouslySelected) {
      setSelectedQuotes([
        ...selectedQuotes,
        {
          id: selectedItem.id,
          customer: selectedItem.metadata.customer,
        },
      ]);
    } else if (!selected && previouslySelected) {
      setSelectedQuotes(selectedQuotes.filter((q) => q.id !== selectedItem.id));
    }
  };

  const postDelete = useCallback(
    async (id: string) => {
      try {
        await coreApiFetch(schemas.salesQuoteDrafts.deleteSalesQuoteDraft, {
          pathParams: { salesQuoteDraftId: id },
        });
        notification.success({ message: 'Deleted quote.' });
        reload();
        setSelectedQuotes([]);
      } catch (err) {
        captureAndShowError(err, 'Error while deleting quote');
      }
    },
    [reload],
  );

  const copyAsNewQuote = useCallback(
    async (quoteId: string) => {
      try {
        const { data: quote } = await coreApiFetch(schemas.salesQuoteDrafts.getSalesQuoteDraft, {
          pathParams: { salesQuoteDraftId: quoteId },
        });
        const newDraftId = (
          await coreApiFetch(schemas.salesQuoteDrafts.postSalesQuoteDraft, {
            bodyParams: {
              // SalesQuoteDraft is defined as a model with Moment types but comes as string
              ...(quote as unknown as CreateSalesQuoteBody),
              description: newDraftName,
              status: QuoteStatus.Draft,
            },
          })
        ).data.id;
        notification.success({
          key: routes.orders.quoteEdit(newDraftId),
          message: 'Copied as new draft quote.',
          description: <NotificationLink to={routes.orders.quoteEdit(newDraftId)}>Edit quote</NotificationLink>,
        });
        reload();
      } catch (err) {
        captureAndShowError(err, 'Error while copying draft quote.');
      }
    },
    [reload, newDraftName],
  );

  const updateQuoteName = useCallback(
    async (id: string) => {
      try {
        const resp = await coreApiFetch(schemas.salesQuoteDrafts.patchSalesQuoteDraft, {
          pathParams: { salesQuoteDraftId: id },
          bodyParams: {
            description: newDraftName,
          },
        });
        message.success(`Quote renamed to "${resp.data.description}".`);
        reload();
      } catch (err) {
        captureAndShowError(err, 'Error while renaming quote.');
      }
    },
    [reload, newDraftName],
  );

  const onDeleteButton = useCallback(
    (id: string) => {
      AntdModal.confirm({
        title: 'Are you sure you want to delete this quote?',
        icon: <ExclamationCircleOutlined />,
        content: 'This action cannot be undone.',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          postDelete(id);
        },
        onCancel() {},
      });
    },
    [postDelete],
  );

  const onCopyAsButton = (id: string, description: string | null) => {
    setIsSaveAsModalOpen(true);
    setNewDraftName(description || '');
    setSelectedQuoteId(id);
  };

  const onRenameButton = (id: string, description: string | null) => {
    setIsRenameModalOpen(true);
    setNewDraftName(description || '');
    setSelectedQuoteId(id);
  };

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <>
      <Container>
        <PageHeader title="Draft Quotes" headerActions={<EmailQuoteOrderPDFButton selectedQuotes={selectedQuotes} />} />
        <DividerLine />
        <Table
          loading={isLoading || !data}
          columns={getDraftQuoteListColumns(onDeleteButton, onCopyAsButton, onRenameButton)}
          data={quotes.filter((quote) => !quote.foreignId)}
          rowKey="id"
          size="small"
          pagination={
            totalCount > pageSize && {
              current: page,
              pageSize,
              total: totalCount,
              onChange: (newPage: number) => updateHashState({ page: newPage }),
              simple: true,
            }
          }
          rowSelection={{
            selectedRowKeys: selectedQuotes.map((q) => q.id),
            onSelect: handleTableItemSelect,
            hideSelectAll: true,
          }}
        />
      </Container>
      <Modal
        title="Save As New Draft Quote"
        okText="Save"
        okType="primary"
        visible={isSaveAsModalOpen}
        onOk={() => {
          if (selectedQuoteId) copyAsNewQuote(selectedQuoteId);
          setNewDraftName('');
          setSelectedQuoteId(undefined);
          setIsSaveAsModalOpen(false);
        }}
        onCancel={() => {
          setNewDraftName('');
          setSelectedQuoteId(undefined);
          setIsSaveAsModalOpen(false);
        }}
        mask
        destroyOnClose
      >
        <Input
          title="Draft Name"
          defaultValue={newDraftName}
          onChange={(e) => {
            setNewDraftName(e.target.value);
          }}
        />
      </Modal>
      <Modal
        title="Rename Draft"
        okText="Save"
        okType="primary"
        visible={isRenameModalOpen}
        onOk={() => {
          if (selectedQuoteId) updateQuoteName(selectedQuoteId);
          setNewDraftName('');
          setSelectedQuoteId(undefined);
          setIsRenameModalOpen(false);
        }}
        onCancel={() => {
          setNewDraftName('');
          setSelectedQuoteId(undefined);
          setIsRenameModalOpen(false);
        }}
        mask
        destroyOnClose
      >
        <Input
          defaultValue={newDraftName}
          onChange={(e) => {
            setNewDraftName(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};
