import React from 'react';

import { css } from '@emotion/css';

import { Alert } from 'components/Alert';

import { useGlobalApp } from 'hooks/useGlobalApp';

export const SsoAlert = () => {
  const { activeTenant } = useGlobalApp();
  return (
    <Alert
      className={css`
        margin-bottom: 24px;
      `}
      message={
        <div
          className={css`
            padding: 10px 0;
          `}
        >
          {activeTenant.name} is using Azure AD SSO for user management.
          <ul
            className={css`
              margin-bottom: 0;
              padding-inline-start: 20px;
            `}
          >
            <li>Passwords cannot be reset within Recurrency, users must use their SSO credentials to log in.</li>
            <li>
              New users can be invited from this page or automatically provisioned on their first log in attempt using
              their group or user permissions set in Azure AD.
            </li>
            <li>
              Users who have been deprovisioned in Azure AD will lose access to Recurrency even if they are still listed
              in the user list below.
            </li>
          </ul>
        </div>
      }
      banner
      type="info"
    />
  );
};
