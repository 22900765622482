import React, { useMemo } from 'react';

import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';

import { CollapsePanels } from 'components/recipes/sidePane/CollapsePanels';
import { SplitPage } from 'components/SplitPage';

import { getTenantSetting } from 'utils/tenantSettings';

import { PurchaseGroupHashState, PurchasingLineInput, SupplierLocationWTarget } from 'types/hash-state';

import { GenericTargetLineRow, GroupedPOSummary, TransferSummary } from '../../types';
import { getLineStatusCounts } from '../../utils';
import { POSummarySidePaneSidePane } from '../sidePane/POSummarySidePane';
import { PurchasingOrdersStepPOSection } from './PurchasingOrdersStepPOSection';
import { PurchasingOrdersStepTransfersSection } from './PurchasingOrdersStepTransfersSection';

export function PurchasingOrdersStepPage({
  supplierLocations,
  targetLines,
  purchasingLinesById,
  transferSummaries,
  groupBuy,
  isHubAndSpoke,
  purchaseGroups,
  onPurchasingLinesChange,
  onTransferLineChange,
  onSupplierLocationsChange,
  onGroupBuyChange,
  uniqueSuppliers,
  poSummaries,
}: {
  supplierLocations: SupplierLocationWTarget[];
  targetLines: GenericTargetLineRow[];
  purchasingLinesById: Obj<PurchasingLineInput>;
  transferSummaries: TransferSummary[];
  groupBuy: boolean;
  isHubAndSpoke: boolean;
  purchaseGroups?: PurchaseGroupHashState[];
  onPurchasingLinesChange: (purchasingLinesById: Obj<PurchasingLineInput>) => void;
  onTransferLineChange: (transferSummary: TransferSummary) => void;
  onSupplierLocationsChange: (supplierLocations: SupplierLocationWTarget[]) => void;
  onGroupBuyChange: (groupBuy: boolean) => void;
  uniqueSuppliers: string[];
  poSummaries: GroupedPOSummary[];
}) {
  const lineStatusCounts = useMemo(() => getLineStatusCounts(targetLines), [targetLines]);

  const panels = [
    {
      title: 'Purchase Orders',
      content: (
        <PurchasingOrdersStepPOSection
          supplierLocations={supplierLocations}
          targetLines={targetLines}
          purchasingLinesById={purchasingLinesById}
          groupBuy={groupBuy}
          isHubAndSpoke={isHubAndSpoke}
          onPurchasingLinesChange={onPurchasingLinesChange}
          onSupplierLocationsChange={onSupplierLocationsChange}
          onGroupBuyChange={onGroupBuyChange}
          purchaseGroups={purchaseGroups}
        />
      ),
    },
    getTenantSetting(TenantSettingKey.FeatureCreateTransfers) && transferSummaries.length > 0
      ? {
          title: 'Transfers',
          content: (
            <PurchasingOrdersStepTransfersSection
              transferSummaries={transferSummaries}
              onTransferLineChange={onTransferLineChange}
            />
          ),
        }
      : null,
  ];
  return (
    <SplitPage
      left={<CollapsePanels panels={panels} />}
      right={
        <POSummarySidePaneSidePane
          lineStatusCounts={lineStatusCounts}
          poSummaries={poSummaries}
          uniqueSuppliers={uniqueSuppliers}
        />
      }
    />
  );
}
