import React, { useState } from 'react';

import { css } from '@emotion/css';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

import { arrIsEqual } from 'utils/array';

import { SelectableItemList } from './SelectableItemList';
import { UseAsyncMultiSelectProps } from './types';

export function MultiSelectModal({
  options,
  onClose,
  selectedValues,
  orderable,
  queryPlaceholder,
  title,
  defaultValues,
  description,
}: {
  options: UseAsyncMultiSelectProps;
  onClose: (newValues?: string[]) => void;
  selectedValues: string[];
  orderable?: boolean;
  queryPlaceholder?: string;
  title?: string;
  defaultValues?: string[];
  description?: string;
}) {
  const [curSelectedValues, setCurSelectedValues] = useState<string[]>(selectedValues);

  const applyValues = (newValues: string[]) => {
    options.setSearchQuery?.('');

    // if the new values are same as defaultValues,
    // we return empty array [] to indicate that the user doesn't want to override
    if (arrIsEqual(newValues, defaultValues)) {
      newValues = [];
    }

    onClose(newValues);
  };

  return (
    <Modal
      title={title}
      destroyOnClose
      visible
      onCancel={() => onClose()}
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button onClick={() => setCurSelectedValues(defaultValues || [])}>Reset to Default</Button>
          <Button type="primary" onClick={() => applyValues(curSelectedValues)}>
            Save
          </Button>
        </>
      }
    >
      <div
        className={css`
          margin-bottom: 24px;
        `}
      >
        {description}
      </div>
      <SelectableItemList
        selectProps={options}
        selectedValues={curSelectedValues}
        onSelectedValuesChange={setCurSelectedValues}
        orderable={orderable}
        mode="multiple"
        queryPlaceholder={queryPlaceholder}
      />
    </Modal>
  );
}
