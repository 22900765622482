import React from 'react';

import {
  TeamOutlined,
  ContainerOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  ProfileOutlined,
  DeploymentUnitOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { fuzzyFilter, fuzzyMatch } from 'fuzzbunny';

import { useGlobalApp, ExtendedRole } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { capitalize, formatNumber, splitIfIdNameStr } from 'utils/formatting';
import { isPurchaserRole, shouldShowProduct } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { multiSearchIndex } from 'utils/search/search';
import { SearchRequest } from 'utils/search/types';

import {
  SearchIndexCustomer,
  SearchIndexItem,
  SearchIndexSalesOrder,
  SearchIndexPurchaseOrder,
  SearchIndexVendor,
} from 'types/search-collections';

import { getNavigationOptions } from './navigationOptions';
import { MenuCategory, MenuOption } from './types';

interface SearchIndexCategoryConf<ItemT> {
  label: string;
  icon?: JSX.Element;
  indexName: SearchIndexName;
  /** where the option will link to */
  getItemHref: (item: ItemT) => string;
  /** url for the search/list page with prepopulated query */
  getItemsSearchHref: (query: string) => string;
  /** option label - matching parts from search string will be highlighted */
  getLabel: (item: ItemT) => string;
  /** If hasMultiCompany, shows companyName as subLabel to distinguish */
  getSubLabel?: (item: ItemT) => string;
  /** subtext usually shows id of an item */
  getSubtext?: (item: ItemT) => string | React.ReactNode;
  /** should this category be searched for active user's role */
  isEnabledForRole?: (activeRole: ExtendedRole) => boolean;
  /** filters that should be passed to search index */
  getFilters?: () => Record<keyof ItemT, string[]> | undefined;
  /** which product(s) enable this category to appear (if multiple, only one needs to be on) */
  products?: TenantProductName[];
}

/**
 * Helper function to get highlights from a matched string
 * @example
 *   getLabelHighlights("here is a match, yay!", "match")
 *   returns ["here is a", "match", ", yay!"]
 */
function getLabelHighlights(label: string, searchStr: string): string[] | undefined {
  return fuzzyMatch(label, searchStr)?.highlights;
}

const searchIndexCategoryConfs: SearchIndexCategoryConf<Any>[] = [
  {
    label: 'Customers',
    icon: <TeamOutlined />,
    indexName: SearchIndexName.Customers,
    getItemHref: (item) => routes.sales.customerDetails(item.customer_id, { companyId: item.company_id }),
    getItemsSearchHref: (query: string) => routes.sales.customerList({ query }),
    getLabel: (item) => item.customer_name,
    getSubLabel: (item) => splitIfIdNameStr(item.company)?.name || '',
    getSubtext: (item) => item.customer_id,
    products: [TenantProductName.SalesSearchAndLookup],
  } as SearchIndexCategoryConf<SearchIndexCustomer>,
  {
    label: 'Items',
    icon: <ContainerOutlined />,
    indexName: SearchIndexName.Items,
    getItemHref: (item) => routes.sales.itemDetails(item.item_id),
    getItemsSearchHref: (query: string) => routes.sales.itemList({ query }),
    getLabel: (item) => item.item_desc,
    getSubtext: (item) => (
      <>
        {item.item_id}
        {item.is_assembly ? (
          <>
            &nbsp;
            <DeploymentUnitOutlined />
          </>
        ) : null}
      </>
    ),
    products: [TenantProductName.DfpaSearchAndLookup, TenantProductName.SalesSearchAndLookup],
  } as SearchIndexCategoryConf<SearchIndexItem>,
  {
    label: 'Vendors',
    icon: <ShopOutlined />,
    indexName: SearchIndexName.Vendors,
    getItemHref: (item) => routes.purchasing.vendorDetails(item.vendor_id, { companyId: item.company_id }),
    getItemsSearchHref: (query: string) => routes.purchasing.vendorList({ query }),
    getLabel: (item) => item.vendor_name,
    getSubLabel: (item) => splitIfIdNameStr(item.company)?.name || '',
    getSubtext: (item) => item.vendor_id,
    isEnabledForRole: (activeRole) => isPurchaserRole(activeRole),
    products: [TenantProductName.DfpaSearchAndLookup],
  } as SearchIndexCategoryConf<SearchIndexVendor>,
  {
    label: 'Purchase Orders',
    icon: <ShoppingCartOutlined />,
    indexName: SearchIndexName.PurchaseOrders,
    getItemHref: (item) => routes.purchasing.purchaseOrderDetails(item.po_no),
    getItemsSearchHref: (query: string) => routes.purchasing.purchaseOrderList({ query }),
    getLabel: (item) => `${capitalize(item.status)} PO for ${item.vendor_name}`,
    getSubtext: (item) => item.po_no,
    isEnabledForRole: (activeRole) => isPurchaserRole(activeRole),
    products: [TenantProductName.DfpaSearchAndLookup],
  } as SearchIndexCategoryConf<SearchIndexPurchaseOrder>,
  {
    label: 'Sales Orders',
    icon: <ProfileOutlined />,
    indexName: SearchIndexName.SalesOrders,
    getItemHref: (item) => routes.orders.orderDetails(item.order_no),
    getItemsSearchHref: (query: string) => routes.orders.orderList({ query }),
    getLabel: (item) => `${item.status} ${item.order_type} for ${item.customer_name}`,
    getSubtext: (item) => item.order_no,
    getFilters: () => ({ order_type: ['Order'] }),
    products: [TenantProductName.DfpaSearchAndLookup, TenantProductName.SalesSearchAndLookup],
  } as SearchIndexCategoryConf<SearchIndexSalesOrder>,
  {
    label: 'Quotes',
    icon: <FileTextOutlined />,
    indexName: SearchIndexName.SalesOrders,
    getItemHref: (item) => routes.orders.quoteDetails(item.order_no),
    getItemsSearchHref: (query: string) => routes.orders.quoteList({ query }),
    getLabel: (item) => `${item.status} ${item.order_type} for ${item.customer_name}`,
    getSubtext: (item) => item.order_no,
    getFilters: () => ({ order_type: ['Quote'] }),
    products: [TenantProductName.SalesSearchAndLookup],
  } as SearchIndexCategoryConf<SearchIndexSalesOrder>,
];

export function useGlobalSearch(searchStr: string, maxResultsPerCategory = 3): MenuCategory[] {
  const { activeTenant, activeErpRole, activeUser } = useGlobalApp();

  const categoriesToSearch = searchIndexCategoryConfs.filter(
    (cat) =>
      (cat.products ? cat.products.some((product) => shouldShowProduct(activeTenant, product)) : true) &&
      (cat.isEnabledForRole ? cat.isEnabledForRole(activeErpRole) : true),
  );

  const { data: searchIndexResults } = usePromise(
    () =>
      searchStr
        ? multiSearchIndex(
            categoriesToSearch.map(
              (catConf) =>
                ({
                  indexName: catConf.indexName,
                  query: searchStr,
                  hitsPerPage: maxResultsPerCategory,
                  filters: catConf.getFilters ? catConf.getFilters() : undefined,
                } as SearchRequest),
            ),
          )
        : Promise.resolve([]),
    [searchStr],
  );

  const searchIndexCategories: MenuCategory[] = searchIndexResults
    ? searchIndexResults.map((result, resultIdx) => {
        const catConf = categoriesToSearch[resultIdx];
        const hasMultiCompany = new Set(result.hits.map((hit: { [key: string]: any }) => hit.company_id)).size > 1;
        const menuCategory: MenuCategory = {
          label: catConf.label,
          options: result.hits.map((hit: Obj) => ({
            label: catConf.getLabel(hit),
            labelHighlights: getLabelHighlights(catConf.getLabel(hit), searchStr),
            href: catConf.getItemHref(hit),
            ...(hasMultiCompany && catConf.getSubLabel ? { subLabel: catConf.getSubLabel(hit) } : null),
            ...(catConf.getSubtext ? { subtext: catConf.getSubtext(hit) } : null),
            ...(catConf.icon ? { icon: catConf.icon } : null),
          })),
        };

        // show the 'Show all X matching Items' special option if there are more matches
        if (result.nbHits > maxResultsPerCategory) {
          menuCategory.options.push({
            label: `Show all ${formatNumber(result.nbHits)} matching ${catConf.label.toLowerCase()}`,
            href: catConf.getItemsSearchHref(searchStr),
            ...(catConf.icon ? { icon: catConf.icon } : null),
          });
        }
        return menuCategory;
      })
    : [];

  const navFilteredOptions: MenuOption[] = fuzzyFilter(
    getNavigationOptions(activeErpRole, activeTenant, activeUser),
    searchStr,
    {
      fields: ['label'],
    },
  )
    .slice(0, maxResultsPerCategory)
    .map(({ item, highlights }) => ({
      ...item,
      labelHighlights: highlights.label,
    }));

  const navCategory: MenuCategory = {
    label: 'Navigation',
    options: navFilteredOptions,
  };

  // filter out empty categories
  const searchResults = [...searchIndexCategories, navCategory].filter((cat) => cat.options.length > 0);

  return searchResults;
}
