import React from 'react';

import {
  DeploymentUnitOutlined,
  BranchesOutlined,
  NodeExpandOutlined,
  SettingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { message, Typography } from 'antd';
import { theme } from 'theme';

import {
  TransferType,
  QuoteLineItemP21WithInfo,
  getTransferType,
  getItemCost,
  getItemNetWeight,
} from 'pages/orders/quotes/quoteUtils';
import { Disposition, LineItemActionsP21 } from 'pages/orders/quotes/types';

import { ActionButton } from 'components/Button/ActionButton';
import { MiniTableSkeleton } from 'components/MiniTable';
import { CollapsePanels } from 'components/recipes/sidePane/CollapsePanels';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { LocationAvailabilityPanelContent } from 'components/recipes/sidePane/LocationAvailabilityPanelContent';
import { LocationAvailabilityProperties } from 'components/recipes/sidePane/LocationAvailabilityProperties';
import { SalesInvoiceLinesPanelContent } from 'components/recipes/sidePane/SalesInvoiceLinesPanelContent';
import { SalesOrderLinesPanelContext } from 'components/recipes/sidePane/SalesOrderLinesPanelContext';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { panelTitleByType } from 'components/recipes/sidePane/types';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { getGlobalAppState } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD, splitIfIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { getSearchIndexItemByItemId } from 'utils/search/search';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { OrderType, SidePanePanelType } from 'utils/track';

import { OrderEditHashStateP21 } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';
import { SearchIndexItemPartial } from 'types/search-collections';

import { AccessoriesPanelContent } from './AccessoriesPanelContent';
import { CustomerPartIdPropertiesP21 } from './CustomerPartIdProperties';
import { ItemAssemblyComponentsTabContent } from './ItemAssemblyComponentsTabContent';
import { ItemCommissionCostModal } from './ItemCommissionCostModal';
import { ItemRequiredDatePropertiesP21 } from './ItemRequiredDateProperties';
import { LotPropertiesP21 } from './LotProperties';
import { NotesPanelContent } from './NotesPanelContent';

export function LineItemDetailsSidePaneP21({
  lineItem,
  lineItemIdx,
  lineItemActions,
  orderState,
  orderType,
}: {
  lineItem: QuoteLineItemP21WithInfo;
  lineItemActions: LineItemActionsP21;
  lineItemIdx: number;
  orderState: OrderEditHashStateP21;
  orderType: OrderType;
}) {
  const { foreignId: itemId } = lineItem;
  const { data: searchIndexItem } = usePromise(() => getSearchIndexItemByItemId(itemId), [lineItem.foreignId], {
    cacheKey: `search:${JSON.stringify([SearchIndexName.Items, itemId])}`,
  });

  // show loading shell
  if (!searchIndexItem) {
    return <SidePane title={<>Item #{itemId}</>} description={lineItem.name} content={<MiniTableSkeleton />} />;
  }

  if (searchIndexItem.extended_desc !== lineItem.description && !lineItem.extendedDescription) {
    lineItem.extendedDescription = {
      originalDescription: searchIndexItem.extended_desc ?? '',
      extendedText: (lineItem.description ?? '').replace(searchIndexItem.extended_desc ?? '', ''),
    };
  }

  const itemDescription = `${searchIndexItem.extended_desc ?? ''} ${
    lineItem.extendedDescription?.extendedText ?? ''
  }`.trim();

  const hasEditedDescription = lineItem.extendedDescription && lineItem.extendedDescription.extendedText?.length >= 1;

  return (
    <SidePane
      title={
        <>
          Item{' '}
          <a href={routes.sales.itemDetails(itemId)} target="_blank" rel="noreferrer">
            #{itemId} {searchIndexItem.is_assembly ? <DeploymentUnitOutlined /> : null}
          </a>
        </>
      }
      description={searchIndexItem.item_desc}
      extendedDescription={
        <Typography>
          {itemDescription}{' '}
          {hasEditedDescription ? (
            <small
              className={css`
                color: ${theme.colors.neutral[400]};
              `}
            >
              (edited)
            </small>
          ) : null}
        </Typography>
      }
      navTabs={[
        {
          header: 'Details',
          content: (
            <ItemDetailsTabContent
              orderState={orderState}
              lineItem={lineItem}
              lineItemIdx={lineItemIdx}
              algoliaItem={searchIndexItem}
              orderType={orderType}
              lineItemActions={lineItemActions}
            />
          ),
        },
        searchIndexItem.is_assembly
          ? {
              header: 'Components',
              content: <ItemAssemblyComponentsTabContent itemId={searchIndexItem.item_id} />,
            }
          : null,
      ].filter(truthy)}
    />
  );
}

function ItemDetailsTabContent({
  orderState,
  lineItem,
  lineItemIdx,
  algoliaItem,
  orderType,
  lineItemActions,
}: {
  orderState: OrderEditHashStateP21;
  lineItem: QuoteLineItemP21WithInfo;
  lineItemIdx: number;
  algoliaItem: SearchIndexItemPartial;
  orderType: OrderType;
  lineItemActions: LineItemActionsP21;
}) {
  const { sourceLocation, shipLocation } = lineItem;
  const { item_id: itemId, inv_mast_uid: itemUid } = algoliaItem;
  const companyId = splitIfIdNameStr(orderState.company)?.foreignId || ``;
  const customerId = splitIfIdNameStr(orderState.customer)?.foreignId || ``;
  const salesLocation = splitIfIdNameStr(orderState.location);
  const headerSourceLocation = splitIfIdNameStr(orderState.sourceLocation);
  const shipToLocation = splitIfIdNameStr(orderState.shipTo);
  const locationId = sourceLocation?.foreignId || headerSourceLocation?.foreignId || salesLocation?.foreignId;
  const headerRequiredDate = orderState.requiredDate;
  const { activeTenant, activeUser } = getGlobalAppState();
  const shouldDisableNotesExport = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersDisableNotesExportForP21,
  );
  const shouldChangeRequiredDateForLineItem = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersChangeRequiredDateForLineItem,
  );

  return (
    <>
      {locationId ? <LocationAvailabilityProperties algoliaItem={algoliaItem} locationId={locationId} /> : null}
      <PriceProperties lineItem={lineItem} lineItemIdx={lineItemIdx} lineItemActions={lineItemActions} />
      <TransferProperties
        lineItem={lineItem}
        salesLocation={salesLocation}
        sourceLocation={sourceLocation}
        shipLocation={shipLocation}
        shipToLocation={shipToLocation}
      />
      {algoliaItem.lots_assignable ? (
        <LotPropertiesP21
          lineItem={lineItem}
          lineItemIdx={lineItemIdx}
          lineItemActions={lineItemActions}
          locationId={locationId}
          orderType={orderType}
        />
      ) : null}
      {algoliaItem.customer_ids_with_cpn?.includes(customerId) ? (
        <CustomerPartIdPropertiesP21
          lineItem={lineItem}
          lineItemIdx={lineItemIdx}
          lineItemActions={lineItemActions}
          companyId={companyId}
          customerId={customerId}
        />
      ) : null}
      {shouldChangeRequiredDateForLineItem ? (
        <ItemRequiredDatePropertiesP21
          lineItem={lineItem}
          lineItemIdx={lineItemIdx}
          lineItemActions={lineItemActions}
          headerRequiredDate={headerRequiredDate}
        />
      ) : null}
      <CollapsePanels
        arrowPosition="right"
        panels={[
          {
            title: panelTitleByType[SidePanePanelType.LocationAvailability],
            content: <LocationAvailabilityPanelContent itemUid={itemUid} />,
          },
          !shouldDisableNotesExport
            ? {
                title: panelTitleByType[SidePanePanelType.Notes],
                content: (
                  <NotesPanelContent
                    lineItem={lineItem}
                    onLineNotesChange={(lineNotes) => {
                      lineItemActions.setLineItem(lineItemIdx, { ...lineItem, notes: lineNotes });
                      message.success(`Line Notes saved`);
                    }}
                  />
                ),
              }
            : null,
          salesLocation
            ? {
                title: panelTitleByType[SidePanePanelType.AccessoryItems],
                content: (
                  <AccessoriesPanelContent
                    lineItem={lineItem}
                    location={salesLocation}
                    orderType={orderType}
                    onLineItemsAdd={lineItemActions.addLineItems}
                  />
                ),
              }
            : null,

          {
            title: panelTitleByType[SidePanePanelType.OrdersOfItemToCustomer],
            content: <SalesOrderLinesPanelContext itemId={itemId} companyId={companyId} customerId={customerId} />,
          },
          {
            title: panelTitleByType[SidePanePanelType.SalesOfItemToCustomer],
            content: <SalesInvoiceLinesPanelContent itemId={itemId} companyId={companyId} customerId={customerId} />,
          },
          {
            title: panelTitleByType[SidePanePanelType.SalesOfItemAllCustomers],
            content: <SalesInvoiceLinesPanelContent itemId={itemId} companyId={companyId} />,
          },
        ]}
      />
    </>
  );
}
function PriceProperties({
  lineItem,
  lineItemActions,
  lineItemIdx,
}: {
  lineItem: QuoteLineItemP21WithInfo;
  lineItemActions: LineItemActionsP21;
  lineItemIdx: number;
}) {
  const { priceInfo } = lineItem;
  const { activeTenant, activeUser } = getGlobalAppState();
  const quoteOrderLineItemConfig = getTenantConfiguration(activeTenant).quoteOrderEntryFlow.lineItemConfig;
  const shouldShowCostAndGM = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesShowCostAndGrossMargin,
  );
  const shouldChangeCommissionCost = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersChangeCommissionCost,
  );
  const shouldShowOtherCost = shouldShowCostAndGM && quoteOrderLineItemConfig.otherCost.visible;
  const shouldShowNetWeight = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesShowNetWeight);

  if (!priceInfo) {
    return null;
  }

  const itemPriceInfo = priceInfo.current;
  const systemCalculated = itemPriceInfo?.systemCalculatedPrices[0];
  const systemCalculatedUnitPrice = systemCalculated?.price;
  const precision = itemPriceInfo?.priceDecimalPrecision || 2;
  const priceLibrary = priceInfo.library;
  const unitOfMeasure = itemPriceInfo?.unitOfMeasure;
  const formattedCost = unitOfMeasure
    ? formatUSD((getItemCost(lineItem, activeTenant) || 0) * unitOfMeasure.size, true, precision)
    : formatUSD(getItemCost(lineItem, activeTenant), true, precision);
  const formattedOtherCost = unitOfMeasure
    ? formatUSD((systemCalculated?.otherCost || 0) * unitOfMeasure.size, true, precision)
    : formatUSD(systemCalculated?.otherCost || 0, true, precision);
  const formattedWeight = formatNumber(getItemNetWeight(lineItem), precision);

  const props: Array<LabelValueProperty | null> = [
    systemCalculatedUnitPrice
      ? {
          label: 'P21 Price',
          value: (
            <>
              {formatUSD(systemCalculatedUnitPrice.amount, true, precision)}
              {unitOfMeasure && `/${unitOfMeasure.symbol}`}
              {priceLibrary && (
                <InfoTooltip
                  placement="bottom"
                  title={
                    <>
                      <b>Price Library</b>
                      <div>
                        {priceLibrary.id}: {priceLibrary.description}{' '}
                      </div>
                    </>
                  }
                  useInfoIcon
                />
              )}
            </>
          ),
        }
      : null,
    systemCalculated?.lineNo && systemCalculated.name ? { label: 'Contract', value: systemCalculated.name } : null,
    priceInfo.contract
      ? {
          label: 'Contract Info',
          value: `${priceInfo.contract.messages.join('. ')}.`,
        }
      : null,
    shouldShowCostAndGM
      ? {
          label: 'Cost',
          value: (
            <>
              {`${formattedCost}${unitOfMeasure ? `/${unitOfMeasure.symbol}` : ''}`}

              {shouldChangeCommissionCost && (
                <>
                  {` | `}
                  <ActionButton
                    label="Edit"
                    onClick={async () => {
                      const commissionCost = await showAsyncModal(ItemCommissionCostModal, {
                        lineItem,
                        formattedCost,
                        unitOfMeasure,
                      });
                      if (commissionCost && commissionCost > 0) {
                        lineItemActions.updateLineItem(lineItemIdx, { commissionCost });
                      }
                    }}
                  />
                </>
              )}
            </>
          ),
        }
      : null,
    shouldShowOtherCost
      ? {
          label: quoteOrderLineItemConfig.otherCost.displayName,
          value: `${formattedOtherCost}${unitOfMeasure ? `/${unitOfMeasure.symbol}` : ''}`,
        }
      : null,
    shouldShowNetWeight
      ? {
          label: 'Net Weight',
          value: `${formattedWeight} lbs.`,
        }
      : null,
  ];

  return <LabelValueProperties properties={props} />;
}

export function TransferProperties({
  lineItem,
  salesLocation,
  shipLocation,
  sourceLocation,
  shipToLocation,
}: {
  lineItem?: QuoteLineItemP21WithInfo;
  salesLocation?: IdNameObj;
  shipLocation?: IdNameObj;
  sourceLocation?: IdNameObj;
  shipToLocation?: IdNameObj;
}) {
  const supplierId = lineItem?.supplier?.foreignId;
  const supplierName = lineItem?.supplier?.name;
  const poCost = lineItem?.poCost;

  const transferType = getTransferType(salesLocation, shipLocation, sourceLocation);
  if (transferType === TransferType.SourceLocation && !sourceLocation && supplierId === undefined) return null;
  if (transferType === TransferType.ShipLocation && !shipLocation && supplierId === undefined) return null;

  const props: Array<LabelValueProperty | null> = [
    supplierId === undefined &&
    (transferType === TransferType.SourceLocation ||
      transferType === TransferType.ShipLocation ||
      transferType === TransferType.SourceAndShipLocation)
      ? {
          label: 'Transfer',
          value: (
            <>
              {`from ${sourceLocation?.foreignId}: ${sourceLocation?.name} to ${shipLocation?.foreignId}: ${shipLocation?.name}`}
              &nbsp;
              <BranchesOutlined />
            </>
          ),
        }
      : null,

    sourceLocation &&
    transferType === TransferType.LineLocation &&
    sourceLocation?.foreignId === shipLocation?.foreignId &&
    sourceLocation?.foreignId !== salesLocation?.foreignId
      ? {
          label: 'Line location',
          value: (
            <>
              {`${sourceLocation?.foreignId}: ${sourceLocation?.name}`}
              &nbsp;
              <BranchesOutlined />
            </>
          ),
        }
      : null,

    supplierId !== undefined && lineItem?.disposition === Disposition.DirectShip
      ? {
          label: 'Direct Ship',
          value: (
            <>
              {`from ${supplierId}: ${supplierName} to ${shipToLocation?.foreignId}: ${shipToLocation?.name}`}
              &nbsp;
              <NodeExpandOutlined />
            </>
          ),
        }
      : null,

    supplierId !== undefined && lineItem?.disposition === Disposition.Special
      ? {
          label: 'Special Order',
          value: (
            <>
              {`from ${supplierId}: ${supplierName} to ${shipLocation?.foreignId}: ${shipLocation?.name}`}
              &nbsp;
              <SettingOutlined />
            </>
          ),
        }
      : null,

    lineItem?.disposition === Disposition.BackOrder
      ? {
          label: 'Backorder',
          value: (
            <>
              {`${lineItem.quantity} ${lineItem.unitOfMeasure}`}
              &nbsp;
              <ReloadOutlined />
            </>
          ),
        }
      : null,

    poCost
      ? {
          label: 'PO Cost',
          value: `$${poCost}`,
        }
      : null,
  ];

  return <LabelValueProperties properties={props} />;
}
