import { EndpointSchema } from '@recurrency/core-api-schema/dist/utils/apiSchema';
import { AxiosError } from 'axios';

import { coreApiFetch, legacyApiFetch } from 'utils/api';

import { usePromise, UsePromiseResponse } from './usePromise';

/**
 * NULLIFY_API_CALL is a hack since hooks always have to be called in order,
 * but sometimes we want to conditionally call apis,
 * in which case we pass NULLIFY_API_CALL as first arg.
 * e.g load quote only when we have an id
 */
export const NULLIFY_API_CALL = 'NULLIFY_API_CALL';

export function useLegacyApi<DataT = any>(
  apiPath: string,
  queryParams?: Obj<string | number | boolean | undefined>,
): UsePromiseResponse<DataT, AxiosError> {
  const result = usePromise(
    apiPath === NULLIFY_API_CALL
      ? () => Promise.resolve(null)
      : () => legacyApiFetch(apiPath, { method: 'GET', data: queryParams }).then((resp) => resp.data),
    [],
    { cacheKey: queryParams ? `${apiPath}|${JSON.stringify(queryParams)}` : apiPath },
  );

  // @ts-expect-error legacy api isn't strongly typed, so we don't know the correct return type
  return result;
}

export function useCoreApi<EndpointSchemaT extends EndpointSchema>(
  schema: EndpointSchemaT,
  options?: {
    queryParams?: InstanceType<EndpointSchemaT['queryParams']>;
    pathParams?: Omit<InstanceType<EndpointSchemaT['pathParams']>, 'tenantId' | 'erpType'>;
    bodyParams?: EndpointSchemaT['requestBody'] extends object
      ? InstanceType<EndpointSchemaT['requestBody']>
      : undefined;
  } | null,
): UsePromiseResponse<InstanceType<EndpointSchemaT['responseBody']>, AxiosError> {
  const result = usePromise<InstanceType<EndpointSchemaT['responseBody']>, AxiosError>(
    () => (options !== null ? coreApiFetch(schema, options).then((resp) => resp.data) : Promise.resolve(null as Any)),
    [],
    { cacheKey: schema ? `${schema.endpoint}|${JSON.stringify(options)}` : undefined },
  );

  return result;
}

/**
 * throw if SWR response has error (will be caught by nearest error boundary)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throwIfApiError<DataT = any>(
  response: UsePromiseResponse<DataT, AxiosError>,
): UsePromiseResponse<DataT, AxiosError> {
  if (response.error) {
    throw response.error;
  } else {
    return response;
  }
}
