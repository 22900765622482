import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ContactDTO } from '@recurrency/core-api-schema/dist/contacts/getContacts';
import { Grid } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { Button } from 'components/Button';
import { Portal, PortalId } from 'components/Portal';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { ContactFormData, ContactModal } from './NewContactModal';

export interface NewContactButtonProps {
  onSubmit?: (c: ContactDTO) => void;
  size?: SizeType;
  type?: ButtonType;
  disabled?: boolean;
  collapsible?: boolean;
  initialValues?: Partial<ContactFormData>;
}

export function NewContactButton({
  onSubmit,
  size,
  type,
  disabled,
  collapsible,
  initialValues,
}: NewContactButtonProps) {
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;
  const { activeTenant, activeUser } = useGlobalApp();
  const createContactEnabled = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateContact);

  return (
    <div
      className={css`
        position: relative;
      `}
    >
      <Button
        size={size}
        type={type || 'default'}
        onClick={() => {
          setContactModalOpen(true);
          track(TrackEvent.Nav_CtaClick, {
            ctaName: CtaName.NewContact,
            ctaType: CtaType.Button,
          });
        }}
        icon={<PlusOutlined />}
        disabled={disabled || !createContactEnabled}
      >
        {(isDesktopView || !collapsible) && `New Contact`}
      </Button>
      {isContactModalOpen && (
        <Portal portalId={PortalId.Modal}>
          <ContactModal onClose={() => setContactModalOpen(false)} onSubmit={onSubmit} initialValues={initialValues} />
        </Portal>
      )}
    </div>
  );
}
