import React from 'react';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  GetSimulatedInventoryDashboardQueryFilters,
  SimulatedInventoryDashboardGroupBy,
  SimulatedInventoryDashboardRecordDTO,
} from '@recurrency/core-api-schema/dist/inventory/getSimulatedInventoryDashboardHistory';
import moment from 'moment';
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { breakpoints } from 'theme/breakpoints';
import { colors } from 'theme/colors';
import { fontWeights } from 'theme/typography';

import { Card, CardHeader } from 'components/Card';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { CenteredError, SmallLoader } from 'components/Loaders';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { PropertyLabel } from 'components/PropertyListItem';

import { useCoreApi } from 'hooks/useApi';

import { formatNumber, formatPercent, formatUSDAbbreviated, getChangeColor } from 'utils/formatting';
import { objKeys } from 'utils/object';
import { useHashState } from 'utils/routes';
import { asKeyOf } from 'utils/tables';

import { InventoryDashboardHashState } from 'types/hash-state';

import { DashboardUnitSelect } from '../PurchasingDashboardPage/shared/DashboardUnitSelect';
import { InventoryDashboardFilters } from '../PurchasingDashboardPage/shared/InventoryDashboardFilters';

const chartColors = objKeys(colors.chart).map((key) => colors.chart[key]);

export function InventorySimulationDashboard() {
  const [hashState, updateHashState] = useHashState<InventoryDashboardHashState>();
  const { displayUnit } = hashState;

  if (!hashState || !hashState.displayUnit) {
    requestAnimationFrame(() => {
      updateHashState({ displayUnit: 'value' });
    });
  }

  const filters: GetSimulatedInventoryDashboardQueryFilters = {
    companyIds: hashState.filters?.companyIds,
    locationIds: hashState.filters?.locationIds,
    itemIds: hashState.filters?.itemIds,
    itemGroupIds: hashState.filters?.itemGroupIds,
    supplierIds: hashState.filters?.supplierIds,
    purchaseClasses: hashState.filters?.purchaseClasses,
    demandPatterns: hashState.filters?.demandPattern,
    demandPredictabilities: hashState.filters?.demandPredictability,
  };

  const {
    data: simulatedInventoryData,
    error: simulatedInventoryDataError,
    isLoading: simulatedInventoryDataIsLoading,
  } = useCoreApi(schemas.inventory.getSimulatedInventoryDashboardHistory, {
    queryParams: { filters },
  });

  const inventoryRecords = sanitizeChartData(simulatedInventoryData?.items);
  const lastSimulationDate =
    inventoryRecords.length > 0 ? inventoryRecords[inventoryRecords.length - 1].simulatedDate : undefined;

  if (simulatedInventoryDataError) {
    return <CenteredError error={simulatedInventoryDataError} />;
  }

  return (
    <Container>
      <PageHeader title="Recurrency Simulated Impact" />

      <FlexSpace
        gap={8}
        className={css`
          margin-bottom: 12px;
        `}
      >
        <div>Measured in</div>
        <DashboardUnitSelect
          state={hashState.displayUnit}
          onChange={(value) => updateHashState({ displayUnit: value })}
        />
      </FlexSpace>
      <FilterBarBox dividerLine>
        <InventoryDashboardFilters
          label="Filter"
          filterState={hashState.filters}
          onFiltersChange={(newFilters) => updateHashState({ ...hashState, filters: newFilters })}
          showDemandPatternFilter
          showDemandPredictabilityFilter
        />
      </FilterBarBox>
      <div
        className={css`
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr 1fr;

          ${breakpoints.tablet} {
            grid-template-columns: 1fr;
          }
        `}
      >
        <ActualVsSimulatedLineChartCard
          title={displayUnit === 'value' ? 'Inventory Value' : 'Inventory Quantity'}
          actualDataKey={displayUnit === 'value' ? 'actualInventoryValue' : 'actualInventoryQty'}
          simulatedDataKey={displayUnit === 'value' ? 'simulatedInventoryValue' : 'simulatedInventoryQty'}
          valueType={displayUnit === 'value' ? 'currency' : 'number'}
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
          decreaseIsBetter
        />
        <ActualVsSimulatedLineChartCard
          title={displayUnit === 'value' ? 'Backorder Value' : 'Backorder Quantity'}
          actualDataKey={displayUnit === 'value' ? 'actualBackorderValue' : 'actualBackorderQty'}
          simulatedDataKey={displayUnit === 'value' ? 'simulatedBackorderValue' : 'simulatedBackorderQty'}
          valueType={displayUnit === 'value' ? 'currency' : 'number'}
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
          decreaseIsBetter
        />
        <ActualVsSimulatedLineChartCard
          title={displayUnit === 'value' ? 'Purchase Value' : 'Purchase Quantity'}
          actualDataKey={displayUnit === 'value' ? 'actualPurchaseValue' : 'actualPurchaseQty'}
          simulatedDataKey={displayUnit === 'value' ? 'simulatedPurchaseValue' : 'simulatedPurchaseQty'}
          valueType={displayUnit === 'value' ? 'currency' : 'number'}
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
          decreaseIsBetter
        />
        <ActualVsSimulatedLineChartCard
          title={displayUnit === 'value' ? 'Overstock Value' : 'Overstock Quantity'}
          actualDataKey={displayUnit === 'value' ? 'actualOverstockValue' : 'actualOverstockQty'}
          simulatedDataKey={displayUnit === 'value' ? 'simulatedOverstockValue' : 'simulatedOverstockQty'}
          valueType={displayUnit === 'value' ? 'currency' : 'number'}
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
          decreaseIsBetter
        />
        <ActualVsSimulatedLineChartCard
          title="Gross Margin ROI"
          actualDataKey="actualGrossMarginRoi"
          simulatedDataKey="simulatedGrossMarginRoi"
          valueType="number"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
        <ActualVsSimulatedLineChartCard
          title="Annualized Turns"
          actualDataKey="actualAnnualizedTurns"
          simulatedDataKey="simulatedAnnualizedTurns"
          valueType="number"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
        <ActualVsSimulatedLineChartCard
          title="Fill Rate"
          actualDataKey="actualFillRate"
          simulatedDataKey="simulatedFillRate"
          valueType="percent"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
        <ActualVsSimulatedLineChartCard
          title="In Stock Rate"
          actualDataKey="actualInStockRate"
          simulatedDataKey="simulatedInStockRate"
          valueType="percent"
          isLoading={simulatedInventoryDataIsLoading}
          records={inventoryRecords}
        />
        {lastSimulationDate && (
          <BreakdownPieChartCard
            title="Demand Pattern Breakdown"
            simulationDate={lastSimulationDate}
            actualDataKey={displayUnit === 'value' ? 'actualInventoryValue' : 'actualInventoryQty'}
            simulatedDataKey={displayUnit === 'value' ? 'simulatedInventoryValue' : 'simulatedInventoryQty'}
            valueType={displayUnit === 'value' ? 'currency' : 'number'}
            groupBy={SimulatedInventoryDashboardGroupBy.DemandPattern}
            filters={filters}
          />
        )}
        {lastSimulationDate && (
          <BreakdownPieChartCard
            title="Demand Predictability Breakdown"
            simulationDate={lastSimulationDate}
            actualDataKey={displayUnit === 'value' ? 'actualInventoryValue' : 'actualInventoryQty'}
            simulatedDataKey={displayUnit === 'value' ? 'simulatedInventoryValue' : 'simulatedInventoryQty'}
            valueType={displayUnit === 'value' ? 'currency' : 'number'}
            groupBy={SimulatedInventoryDashboardGroupBy.DemandPredictability}
            filters={filters}
          />
        )}
      </div>
    </Container>
  );
}

/**
 * sanitize data so first month matches exactly for simulated vs actual.
 * Minor difference in calculation causes fill rate and in_stock rate charts to be slightly off.
 */
function sanitizeChartData(
  records: SimulatedInventoryDashboardRecordDTO[] | undefined,
): SimulatedInventoryDashboardRecordDTO[] {
  if (!records || records.length === 0) return [];

  // first record is first month, ensure simulated values match actual values
  const firstMonthRecord = records[0];
  for (const key of objKeys(firstMonthRecord)) {
    if (key.startsWith('simulated')) {
      const actualKey = key.replace('simulated', 'actual') as typeof key;
      if (firstMonthRecord[actualKey]) {
        // @ts-expect-error - we know this is a valid key
        firstMonthRecord[key] = firstMonthRecord[actualKey];
      }
    }
  }
  return records;
}

function getValueFormatter(valueType: 'currency' | 'number' | 'percent') {
  const valueFormatter: (value: Any) => string =
    valueType === 'currency'
      ? (val) => formatUSDAbbreviated(val, 0)
      : valueType === 'percent'
      ? (val) => formatPercent(val, 0)
      : (val) => formatNumber(val, val > 10 ? 0 : 2, undefined, { notation: 'compact', useGrouping: true });
  return valueFormatter;
}

function ActualVsSimulatedLineChartCard({
  title,
  actualDataKey,
  simulatedDataKey,
  valueType,
  isLoading,
  records,
  decreaseIsBetter,
}: {
  title: string;
  actualDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  simulatedDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  valueType: 'currency' | 'number' | 'percent';
  isLoading: boolean;
  records: SimulatedInventoryDashboardRecordDTO[];
  decreaseIsBetter?: boolean;
}) {
  const chartHeight = 250;
  const valueFormatter = getValueFormatter(valueType);
  const dateFormatter = (date: Any) => (date ? moment(date).format('MMM YY') : '-');

  return (
    <Card>
      <CardHeader title={title} />
      {isLoading || records.length === 0 ? (
        <div
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: ${chartHeight}px;
          `}
        >
          {isLoading ? <SmallLoader /> : <NoDataMessage description="No records found, try another filter." />}
        </div>
      ) : (
        <FlexSpace
          gap={8}
          className={css`
            padding: 10px;
          `}
        >
          <ActualVsSimulatedDeltaKPI
            records={records}
            actualDataKey={actualDataKey}
            simulatedDataKey={simulatedDataKey}
            valueFormatter={valueFormatter}
            decreaseIsBetter={decreaseIsBetter}
          />
          <ResponsiveContainer width="99.9%" height={chartHeight - 20}>
            <LineChart data={records}>
              <XAxis
                type="category"
                dataKey={asKeyOf<SimulatedInventoryDashboardRecordDTO>('simulatedDate')}
                tickFormatter={dateFormatter}
                tick={{ fill: colors.primary[600], stroke: colors.primary[600], strokeWidth: 0.2 }}
              />
              <YAxis
                type="number"
                domain={valueType === 'percent' ? [0, 1] : undefined}
                tickFormatter={valueFormatter}
                tick={{ fill: colors.primary[600], stroke: colors.primary[600], strokeWidth: 0.2 }}
              />
              <Line
                type="linear"
                dataKey={actualDataKey}
                stroke={colors.chart.sunsetPurple}
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
              <Line
                type="linear"
                dataKey={simulatedDataKey}
                stroke={colors.chart.lagoonBlue}
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
              <Tooltip cursor={false} formatter={valueFormatter} labelFormatter={dateFormatter} />
              <CartesianGrid strokeDasharray="3 3" />
            </LineChart>
          </ResponsiveContainer>
        </FlexSpace>
      )}
    </Card>
  );
}

function ActualVsSimulatedDeltaKPI({
  records,
  actualDataKey,
  simulatedDataKey,
  valueFormatter,
  decreaseIsBetter,
}: {
  records: SimulatedInventoryDashboardRecordDTO[];
  actualDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  simulatedDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  valueFormatter: (value: Any) => string;
  decreaseIsBetter?: boolean;
}) {
  const lastRecord = records[records.length - 1];
  const actualValue = lastRecord[actualDataKey] as number;
  const simulatedValue = lastRecord[simulatedDataKey] as number;
  const delta = simulatedValue - actualValue;
  const changeColor = delta ? getChangeColor(delta * (decreaseIsBetter ? -1 : 1)) : colors.neutral[500];

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-left: 16px;
        width: 200px;
        align-items: center;
      `}
    >
      <div
        className={css`
          color: ${changeColor};
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <div
          className={css`
            font-size: 32px;
            font-weight: bold;
            line-height: 36px;
          `}
        >
          {delta < 0 ? <ArrowDownOutlined /> : delta > 0 ? <ArrowUpOutlined /> : null} {valueFormatter(Math.abs(delta))}
        </div>
        <div
          className={css`
            white-space: nowrap;
          `}
        >
          After 12 months
        </div>
      </div>
      <div
        className={css`
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <LabelValueKPI
          label="Actual"
          value={actualValue}
          valueColor={colors.chart.sunsetPurple}
          valueFormatter={valueFormatter}
        />
        <div>→</div>
        <LabelValueKPI
          label="Simulated"
          value={simulatedValue}
          valueColor={colors.chart.lagoonBlue}
          valueFormatter={valueFormatter}
        />
      </div>
    </div>
  );
}

function LabelValueKPI({
  label,
  value,
  valueColor,
  valueFormatter,
}: {
  label: string;
  value: number;
  valueColor: string;
  valueFormatter: (value: Any) => string;
}) {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <PropertyLabel label={label} />
      <div
        className={css`
          color: ${valueColor};
          font-weight: ${fontWeights.semibold};
        `}
      >
        {valueFormatter(value)}
      </div>
    </div>
  );
}

function BreakdownPieChartCard({
  title,
  simulationDate,
  actualDataKey,
  simulatedDataKey,
  filters,
  groupBy,
  valueType,
}: {
  title: string;
  simulationDate: string;
  actualDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  simulatedDataKey: keyof SimulatedInventoryDashboardRecordDTO;
  filters: GetSimulatedInventoryDashboardQueryFilters;
  groupBy: SimulatedInventoryDashboardGroupBy;
  valueType: 'currency' | 'number' | 'percent';
}) {
  const chartHeight = 250;
  const valueFormatter = getValueFormatter(valueType);

  const {
    data: breakdownData,
    error,
    isLoading,
  } = useCoreApi(schemas.inventory.getSimulatedInventoryDashboardHistory, {
    queryParams: {
      filters: {
        ...filters,
        simulationDate,
      },
      groupBy,
    },
  });

  // sort by name, so pie chart slices are in consistent order
  const records = (breakdownData?.items || []).sort((a, b) => a.groupName!.localeCompare(b.groupName!));

  return (
    <Card>
      <CardHeader title={title} />
      {isLoading || error ? (
        <div
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: ${chartHeight}px;
          `}
        >
          {error ? <CenteredError error={error} /> : <SmallLoader />}
        </div>
      ) : (
        <FlexSpace
          gap={8}
          fullWidth
          className={css`
            padding: 10px;
          `}
        >
          <BreakdownPieChartWithLabel
            label="Actual"
            dataKey={actualDataKey}
            records={records}
            valueFormatter={valueFormatter}
            chartHeight={chartHeight}
          />
          <div>→</div>
          <BreakdownPieChartWithLabel
            label="Simulated"
            dataKey={simulatedDataKey}
            records={records}
            valueFormatter={valueFormatter}
            chartHeight={chartHeight}
          />
          <ResponsiveContainer width={120} height={chartHeight - 20}>
            <PieChart>
              {/* render invisible pie chart so we can render legent with same colors */}
              <Pie
                data={records}
                nameKey="groupName"
                dataKey={actualDataKey}
                cx="50%"
                cy="50%"
                outerRadius={0}
                isAnimationActive={false}
              >
                {records.map((entry, idx) => (
                  <Cell key={idx} fill={chartColors[idx % chartColors.length]} />
                ))}
              </Pie>
              <Legend layout="vertical" align="left" verticalAlign="middle" />
            </PieChart>
          </ResponsiveContainer>
        </FlexSpace>
      )}
    </Card>
  );
}

function BreakdownPieChartWithLabel({
  label,
  dataKey,
  records,
  valueFormatter,
  chartHeight,
}: {
  label: string;
  dataKey: keyof SimulatedInventoryDashboardRecordDTO;
  records: SimulatedInventoryDashboardRecordDTO[];
  valueFormatter: (value: Any) => string;
  chartHeight: number;
}) {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
      `}
    >
      <PropertyLabel label={label} />
      <ResponsiveContainer width="99.9%" height={chartHeight - 20}>
        <PieChart>
          <Pie
            data={records}
            nameKey="groupName"
            dataKey={dataKey}
            cx="50%"
            cy="50%"
            outerRadius={100}
            // clockwise starting from top
            startAngle={90}
            endAngle={-270}
            isAnimationActive={false}
          >
            {records.map((entry, idx) => (
              <Cell key={idx} fill={chartColors[idx % chartColors.length]} />
            ))}
          </Pie>
          <Tooltip formatter={valueFormatter} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
