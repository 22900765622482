import React from 'react';

import { AppstoreOutlined } from '@ant-design/icons';

import { UseAsyncMultiSelectProps } from 'components/AsyncSelect/types';

import { AsyncMultiSelect } from '../../AsyncSelect/AsyncMultiSelect';

export type ProductGroupMultiSelectProps = {
  selectProps: UseAsyncMultiSelectProps;
  selectedValues: string[];
  onSelectedValuesChange: (newItemGroupIds: string[]) => void;
};

export function ProductGroupMultiSelect({
  selectedValues,
  onSelectedValuesChange,
  selectProps,
}: ProductGroupMultiSelectProps) {
  return (
    <AsyncMultiSelect
      label="Product Group"
      icon={<AppstoreOutlined />}
      selectedValues={selectedValues}
      onSelectedValuesChange={onSelectedValuesChange}
      queryPlaceholder="Search product groups"
      selectProps={selectProps}
    />
  );
}
