import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { EnvironmentOutlined, SkinOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { UsageQuantitySource } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { DemandUsageLineRecordDTO } from '@recurrency/core-api-schema/dist/ml/getDemandUsageLines';
import { ColumnType } from 'antd/lib/table';

import { AsyncMultiSelect, filterMultiSelectOptions } from 'components/AsyncSelect/AsyncMultiSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useItemsSelectProps, useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ResultCount } from 'components/ResultCount';
import { DEFAULT_PAGE_SIZE } from 'components/Table';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatIdNameObj } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableDateColumn, sortableNumberColumn } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

export const DemandUsageLinesTable = ({
  itemUid,
  locationId,
  dateFilter,
  setDateFilter,
}: {
  itemUid: string;
  locationId: string;
  dateFilter: DateFilter;
  setDateFilter: (dateFilter: DateFilter) => void;
}) => {
  const locationsSelectProps = useLocationsSelectProps({});
  const itemsSelectProps = useItemsSelectProps();
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [filteredItemCodes, setFilteredItemCodes] = useState<string[]>([]);

  const usageSourceSetting = getTenantSetting(TenantSettingKey.CalculationUseImportedUsage)
    ? 'IMPORTED'
    : getTenantSetting(TenantSettingKey.CalculationUsageQuantitySource);

  const tableProps = useCoreApiTableProps({
    schema: schemas.ml.getDemandUsageLines,
    queryParams: {
      filters: {
        itemIds: [itemUid],
        locationIds: [locationId],
        sourceItemCodes: filteredItemCodes,
        sourceLocationIds: filteredLocationIds,
        startDate: formatMomentDateToSqlDate(dateFilter.from),
        endDate: formatMomentDateToSqlDate(dateFilter.to),
      },
    },
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { isLoading, setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredItemCodes, filteredLocationIds, dateFilter, setPage]);

  // specific to how usage ids are formatted, as they are either usageId|linenumber or usageId
  const formatUsageId = (id: string) => (id.includes('|') ? id.slice(0, id.lastIndexOf('|')) : id);

  const tableColumns: (ColumnType<DemandUsageLineRecordDTO> | null)[] = [
    {
      title:
        {
          IMPORTED: <InfoTooltip title="Usage directly imported from ERP">Imported Usage ID</InfoTooltip>,
          [UsageQuantitySource.QuantityInvoiced]: 'Invoice No',
          [UsageQuantitySource.QuantityOrdered]: 'Order No',
        }[usageSourceSetting as UsageQuantitySource] || 'ID',
      dataIndex: 'id',
      render: (_, record: DemandUsageLineRecordDTO) =>
        usageSourceSetting === UsageQuantitySource.QuantityOrdered ? (
          <Link to={routes.orders.orderDetails(formatUsageId(record.id))}>{formatUsageId(record.id)}</Link>
        ) : (
          formatUsageId(record.id)
        ),
    },
    {
      title: 'Item',
      dataIndex: 'sourceItemName',
      render: (_, record: DemandUsageLineRecordDTO) => (
        <div>
          <Link to={routes.purchasing.itemDetails(record.sourceItemCode)}>{record.sourceItemCode}</Link>
          {`: ${record.sourceItemName}`}
        </div>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'sourceLocationId',
      render: (_, record: DemandUsageLineRecordDTO) =>
        formatIdNameObj({ foreignId: record.sourceLocationId, name: record.sourceLocationName }),
    },
    sortableDateColumn({
      title: 'Usage Date',
      dataIndex: 'usageDate',
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableNumberColumn({
      title: 'Usage',
      dataIndex: 'usageQty',
      sorter: true,
    }),
    {
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    },
  ];

  return (
    <>
      <FilterBarBox>
        <AsyncMultiSelect
          selectProps={filterMultiSelectOptions(convertToMultiSelectProps(itemsSelectProps))}
          label="Item"
          queryPlaceholder="Search items"
          selectedValues={filteredItemCodes}
          onSelectedValuesChange={(values) => setFilteredItemCodes(values)}
          icon={<SkinOutlined />}
        />
        <AsyncMultiSelect
          selectProps={filterMultiSelectOptions(convertToMultiSelectProps(locationsSelectProps))}
          label="Location"
          queryPlaceholder="Search locations"
          selectedValues={filteredLocationIds}
          onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
          icon={<EnvironmentOutlined />}
        />
        <FlexSpacer />
        <DateRangePicker
          value={[dateFilter.from, dateFilter.to]}
          onChange={(values) => setDateFilter(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={tableColumns.filter(filterCostAndGM)} loading={isLoading} />
    </>
  );
};
