import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig } = config.quoteOrderEntryFlow;
  const { formConfig: itemFormConfig } = config.itemEntryFlow;
  quoteOrderFormConfig.class1.required = true;
  itemFormConfig.locationForQuoteEntry = {
    selectMode: 'list',
    listOptions: {
      defaultLocation: true,
      quoteEntryLocation: true,
    },
  };
  itemFormConfig.locationForInventory = {
    selectMode: 'list',
    listOptions: {
      defaultLocation: true,
      quoteEntryLocation: false,
    },
  };
  return config;
}
