import React, { useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { ItemLotDTO, ItemLotListDTO } from '@recurrency/core-api-schema/dist/items/getItemLots';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelect, AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { FilterBarBox } from 'components/FilterBarBox';

import { filterCostAndGM } from 'utils/filterCostAndGM';
import { splitIdNameStr } from 'utils/formatting';
import {
  sortableIdColumn,
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortableNumberColumn,
  sortableStringColumn,
} from 'utils/tables';

export const AvailableItemLotsTable = ({ itemId }: { itemId: string }) => {
  const locationsSelectProps = useLocationsSelectProps({});
  const [filteredLocationId, setFilteredLocationId] = useState<string | undefined>();

  const tableProps = useCoreApiTableProps({
    schema: schemas.items.getItemLots,
    pathParams: { itemId },
    queryParams: {
      locationId: filteredLocationId,
    },
  });

  const shouldShowBrand = tableProps.items?.some((lot) => lot.brand);
  const shouldShowInDate = tableProps.items?.some((lot) => lot.admissionDate);

  const tableColumns: (ColumnType<ItemLotListDTO> | null)[] = [
    sortableIdColumn({
      title: 'Lot Number',
      dataIndex: 'lotId',
      sorter: true,
    }),
    shouldShowInDate
      ? sortableDateColumn({
          title: 'In Date',
          dataIndex: 'admissionDate',
          sorter: true,
        })
      : null,
    sortableDateColumn({
      title: 'Creation Date',
      dataIndex: 'creationDate',
      sorter: true,
    }),
    sortableIdColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record: ItemLotDTO) => (record.locationId ? `${record.locationId}: ${record.locationName}` : '-'),
      sorter: true,
    }),
    shouldShowBrand
      ? sortableStringColumn({
          title: 'Brand',
          dataIndex: 'brand',
          sorter: true,
        })
      : null,
    sortableDollarWithCentsColumn({
      title: 'Cost',
      dataIndex: 'unitCost',
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Quantity Available',
      dataIndex: 'quantityAvailable',
      sorter: true,
    }),
  ];

  return (
    <>
      <FilterBarBox>
        <AsyncSelect
          className={tableFilterSelectClassName}
          selectProps={locationsSelectProps}
          entityPlural="locations"
          variant={AsyncSelectVariant.Filter}
          onSelect={(_, option) => setFilteredLocationId(splitIdNameStr(option.value).foreignId)}
          onClear={() => setFilteredLocationId(undefined)}
          allowClear
          size="small"
        />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={tableColumns.filter(filterCostAndGM)} />
    </>
  );
};
