import React from 'react';

import { Dropdown as AntdDropdown } from 'antd';
import { DropDownProps as AntdDropdownProps } from 'antd/lib/dropdown';

export interface DropdownProps extends AntdDropdownProps {
  children: React.ReactNode;
}

export const Dropdown = ({ children, ...otherProps }: DropdownProps) => (
  <AntdDropdown {...otherProps} trigger={['click']}>
    {children}
  </AntdDropdown>
);
