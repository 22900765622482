import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SortDirection } from '@recurrency/core-api-schema/dist/common/enums';
import {
  GetSalesHistoryReportSortByField,
  GetSalesInvoiceLinesReportGroupBy,
  SalesInvoiceLinesReportItemDTO,
} from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { SavedViewDTO } from '@recurrency/core-api-schema/dist/savedViews/getSavedView';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { FilterBarBox } from 'components/FilterBarBox';
import { CenteredError } from 'components/Loaders';
import { RadioGroup } from 'components/Radio';
import { Sparkline } from 'components/Sparkline';

import { useCoreApi } from 'hooks/useApi';

import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatNumber, formatUSD, splitIfIdNameStr } from 'utils/formatting';
import { objMapValues } from 'utils/object';
import { routes } from 'utils/routes';
import {
  sortableChangeDollarColumn,
  sortableChangeNumberColumn,
  sortableDollarColumn,
  sortableNumberColumn,
  sortableStringColumn,
} from 'utils/tables';

import { ForeignIdNameStr } from 'types/hash-state';

import { LinkButton } from '../../Button/LinkButton';
import { DEFAULT_PAGE_SIZE, Table, TableProps } from '../../Table/Table';
import {
  Filter,
  ReportingUnitType,
  getLastTwoYearLabels,
  getMonthlyFilledSparklineSeries,
  nameByGroupByField,
} from './salesReportUtils';

interface SalesReportTableProps extends Omit<TableProps, 'columns' | 'data'> {
  groupBy: GetSalesInvoiceLinesReportGroupBy;
  report?: SavedViewDTO;
  filter?: Filter;
  pageSize?: number;
  allowSort?: boolean;
  allowPagination?: boolean;
  showViewAllButton?: boolean;
  ignoreRoleSalesRepIds?: boolean;
}

export const SalesReportTable = (props: SalesReportTableProps) => {
  const {
    groupBy,
    report,
    filter,
    allowSort = true,
    allowPagination = true,
    showViewAllButton,
    ignoreRoleSalesRepIds,
    ...otherProps
  } = props;
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(report?.data?.sortBy || GetSalesHistoryReportSortByField.SalesYtd);
  const [sortDir, setSortDir] = useState(report?.data?.sortDir || SortDirection.Desc);
  const [unitType, setUnitType] = useState(ReportingUnitType.Amount);
  const showValueMetricSwitcher =
    groupBy === GetSalesInvoiceLinesReportGroupBy.Item || groupBy === GetSalesInvoiceLinesReportGroupBy.ItemGroup;
  const pageSize = otherProps.pageSize ?? DEFAULT_PAGE_SIZE;
  const yearToDate = report?.data.yearToDate ?? true;
  const { curYearLabel, lastYearLabel } = getLastTwoYearLabels(yearToDate);

  const { isLoading, data, error } = useCoreApi(
    schemas.reports.getSalesInvoiceLinesReport,
    groupBy
      ? {
          queryParams: {
            ...report?.data,
            groupBy,
            sortBy,
            sortDir,
            limit: pageSize,
            filter: report?.data?.filter
              ? objMapValues(report.data.filter as Obj<string[]>, (value: ForeignIdNameStr[]) =>
                  value?.map((v) => splitIfIdNameStr(v)?.foreignId),
                )
              : filter || undefined,
            offset: ((page ?? 1) - 1) * pageSize,
            yearToDate,
            ignoreRoleSalesRepIds,
          },
        }
      : null,
  );

  const tableColumns: (ColumnType<SalesInvoiceLinesReportItemDTO> | null)[] = [
    groupBy
      ? sortableStringColumn({
          title: `${nameByGroupByField[groupBy]} ID`,
          dataIndex: 'foreignId',
          sorter: allowSort,
          render: (foreignId) =>
            groupBy === 'customer' ? (
              <Link to={routes.sales.customerDetails(foreignId)}>{foreignId}</Link>
            ) : groupBy === 'item' ? (
              <Link to={routes.sales.itemDetails(foreignId)}>{foreignId}</Link>
            ) : (
              foreignId
            ),
        })
      : null,
    groupBy
      ? sortableStringColumn({
          title: nameByGroupByField[groupBy],
          dataIndex: 'name',
          sorter: allowSort,
        })
      : null,
    ...(unitType === ReportingUnitType.Units
      ? [
          sortableNumberColumn({
            title: `Units ${curYearLabel}`,
            dataIndex: 'unitsYtd',
            sorter: allowSort,
          }),
          sortableNumberColumn({
            title: `Units ${lastYearLabel}`,
            dataIndex: 'unitsLYtd',
            sorter: allowSort,
          }),
          sortableChangeNumberColumn({
            title: 'Units Δ',
            dataIndex: 'unitsDelta',
            sorter: allowSort,
          }),
          {
            title: `Units (Jan ${new Date().getFullYear() - 1} - Present)`,
            dataIndex: 'unitsMonthly',
            width: '200px',
            render: (unitsMonthly: Obj<number>) => (
              <Sparkline
                height={50}
                series={getMonthlyFilledSparklineSeries(
                  unitsMonthly,
                  new Date(new Date().getFullYear() - 1, 0, 1),
                  new Date(),
                  formatNumber,
                )}
              />
            ),
          },
        ]
      : [
          sortableDollarColumn({
            title: `Sales ${curYearLabel}`,
            dataIndex: 'salesYtd',
            sorter: allowSort,
            defaultSortOrder: 'descend',
          }),
          sortableDollarColumn({
            title: `Sales ${lastYearLabel}`,
            dataIndex: 'salesLYtd',
            sorter: allowSort,
          }),
          sortableChangeDollarColumn({
            title: 'Sales Δ',
            dataIndex: 'salesDelta',
            sorter: allowSort,
          }),
          {
            title: `Sales (Jan ${new Date().getFullYear() - 1} - Present)`,
            dataIndex: 'salesMonthly',
            width: '200px',
            render: (salesMonthly: Obj<number>) => (
              <Sparkline
                height={50}
                series={getMonthlyFilledSparklineSeries(
                  salesMonthly,
                  new Date(new Date().getFullYear() - 1, 0, 1),
                  new Date(),
                  formatUSD,
                )}
              />
            ),
          },
          sortableDollarColumn({
            title: `GM ${curYearLabel}`,
            dataIndex: 'gmYtd',
            sorter: allowSort,
          }),
          sortableDollarColumn({
            title: `GM ${lastYearLabel}`,
            dataIndex: 'gmLYtd',
            sorter: allowSort,
          }),
          sortableChangeDollarColumn({
            title: 'GM Δ',
            dataIndex: 'gmDelta',
            sorter: allowSort,
          }),
        ]),
  ];

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <>
      {showValueMetricSwitcher ? (
        <FilterBarBox>
          <RadioGroup
            onChange={(ev) => {
              setUnitType(ev.target.value);
            }}
            value={unitType}
          >
            <Radio.Button value={ReportingUnitType.Amount}>Dollars</Radio.Button>
            <Radio.Button value={ReportingUnitType.Units}>Units</Radio.Button>
          </RadioGroup>
        </FilterBarBox>
      ) : null}
      <Table
        columns={tableColumns.filter(filterCostAndGM)}
        data={data?.items || []}
        loading={isLoading}
        size="small"
        key="id"
        rowKey="foreignId"
        verticalAlign="center"
        onChange={(_pagination, _filters, sorter, { action }) => {
          // !Array.isArray is there to typeguard into one sorter field
          // stats table only uses single sorting, so this is okay
          if (action === 'sort' && !Array.isArray(sorter) && sorter.field) {
            setPage(1);
            setSortBy(sorter.field?.toString() as GetSalesHistoryReportSortByField);
            setSortDir(sorter.order === 'ascend' ? SortDirection.Asc : SortDirection.Desc);
          }
        }}
        pagination={
          !isLoading &&
          allowPagination &&
          (data?.totalCount ?? 0) > pageSize && {
            onChange: (page) => setPage(page),
            pageSize,
            simple: true,
            current: page,
            total: data?.totalCount,
          }
        }
        {...otherProps}
      />

      {showViewAllButton && data && (
        <div
          className={css`
            margin-top: 16px;
            justify-content: center;
            display: flex;
          `}
        >
          <LinkButton
            route={report?.id ? routes.reporting.report(report.id) : routes.reporting.explorer({ groupBy, yearToDate })}
          >
            View Report
          </LinkButton>
        </div>
      )}
    </>
  );
};
