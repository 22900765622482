import React from 'react';

import { css, cx } from '@emotion/css';
import { Alert as AntdAlert } from 'antd';
import { AlertProps as AntdAlertProps } from 'antd/lib/alert';
import { theme } from 'theme';

export interface AlertProps extends AntdAlertProps {
  noRadius?: boolean;
}

export const Alert = ({ className, noRadius, ...props }: AlertProps): JSX.Element => (
  <AntdAlert
    className={cx(
      'Alert',
      css`
        ${!noRadius && 'border-radius: 8px'};
        white-space: pre-line;
        ${props.type === 'info' && `background: ${theme.colors.primary[200]};`}
      `,
      className,
    )}
    {...props}
  />
);
