import React, { useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { ReplenishmentPathDTO } from '@recurrency/core-api-schema/dist/itemLocations/getItemLocations';
import { Popover } from 'antd';
import { theme } from 'theme';

import { ReplenishmentPathTimeline } from 'components/recipes/planning/ReplenishmentPathTimeline';

import { coreApiFetch } from 'utils/api';
import { splitIdNameStr } from 'utils/formatting';

export type ReplenishmentPathPopoverProps = {
  children: React.ReactNode;
  itemId: string;
  location: string;
  supplier?: string;
};

export function ReplenishmentPathPopover({ children, location, supplier, itemId }: ReplenishmentPathPopoverProps) {
  const locationId = splitIdNameStr(location).foreignId;
  const [data, setData] = useState<ReplenishmentPathDTO>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  async function fetchReplenishmentPathData() {
    try {
      setIsLoading(true);
      const res = await coreApiFetch(schemas.itemLocations.getItemLocationReplenishmentPath, {
        queryParams: {
          itemId,
          locationId,
        },
      });
      setData(res.data);
      setIsFetched(true);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOpenPopover() {
    if (!isLoading && !isFetched) await fetchReplenishmentPathData();
  }

  return (
    <Popover
      title="Replenishment Path"
      trigger="hover"
      color={theme.colors.neutral[75]}
      overlayInnerStyle={{ borderRadius: '8px' }}
      placement="bottom"
      onVisibleChange={(isVisible) => {
        if (isVisible) handleOpenPopover();
      }}
      content={() => (
        <ReplenishmentPathTimeline
          isLoading={isLoading}
          location={location}
          supplier={supplier}
          replenishmentPathData={data}
        />
      )}
      arrowPointAtCenter
    >
      <span>
        {children} <QuestionCircleOutlined />
      </span>
    </Popover>
  );
}
