import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { Button } from 'components/Button';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { shouldShowProduct } from 'utils/roleAndTenant';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { TaskFormData, TaskModal } from './TaskModal';

export interface NewTaskButtonProps {
  onSubmit?: () => void;
  size?: SizeType;
  initialValues?: Partial<TaskFormData>;
}

export function NewTaskButton({ onSubmit, size, initialValues }: NewTaskButtonProps) {
  const { activeTenant } = useGlobalApp();

  return shouldShowProduct(activeTenant, TenantProductName.SalesCrm) ? (
    <div
      className={css`
        position: relative;
      `}
    >
      <Button
        type="primary"
        size={size}
        onClick={async () => {
          track(TrackEvent.Nav_CtaClick, {
            ctaName: CtaName.NewTask,
            ctaType: CtaType.Button,
          });

          const task = await showAsyncModal(TaskModal, { initialValues });
          if (task) {
            onSubmit?.();
          }
        }}
        icon={<PlusOutlined />}
      >
        New Task
      </Button>
    </div>
  ) : null;
}
