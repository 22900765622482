import React from 'react';

import { css } from '@emotion/css';
import { colors } from 'theme/colors';

import { FlexSpace } from 'components/FlexSpace';
import { Typography } from 'components/Typography';

import { truthy } from 'utils/boolean';

import { BorderedPropertyItem } from './BorderedPropertyItem';

export type EquationLine = NoteLine | CalculationLine;

export interface CalculationLine {
  type: 'calculation';
  calculation: Array<CalculationProperty | CalculationOperator>;
}

export interface NoteLine {
  type: 'note';
  text: React.ReactChild;
  maxWidth: string;
}

export interface CalculationProperty {
  type: 'property';
  label: string;
  value: React.ReactChild;
  description?: React.ReactChild;
}

export interface CalculationOperator {
  type: 'operator';
  operator: string;
}

// helper ast functions

export function calcLine(
  ...calculation: Array<
    CalculationProperty | CalculationOperator | null | Array<CalculationProperty | CalculationOperator | null>
  >
): CalculationLine {
  return { type: 'calculation', calculation: calculation.flat().filter(truthy) };
}

export function calcProp(label: string, value: React.ReactChild, description?: React.ReactChild): CalculationProperty {
  return { type: 'property', label, value, description };
}

export function calcOp(operator: string): CalculationOperator {
  return { type: 'operator', operator };
}

export function noteLine(text: React.ReactChild, maxWidth = '350px'): NoteLine {
  return { type: 'note', text, maxWidth };
}

export function Equation({ lines }: { lines: Array<EquationLine | null | Array<EquationLine | null>> }) {
  return (
    <FlexSpace direction="column">
      {lines
        .flat()
        .filter(truthy)
        .map((line, idx) =>
          line.type === 'calculation' ? (
            <FlexSpace
              key={idx}
              alignItems="start"
              className={css`
                flex-wrap: wrap;
                max-width: 800px;
              `}
            >
              {line.calculation.map((calcNode, idx) =>
                calcNode.type === 'property' ? (
                  <BorderedPropertyItem
                    key={idx}
                    label={calcNode.label}
                    value={calcNode.value}
                    description={
                      calcNode.description ? (
                        <Typography type="small" style={{ color: colors.primary[500] }}>
                          {calcNode.description}
                        </Typography>
                      ) : undefined
                    }
                  />
                ) : calcNode.type === 'operator' ? (
                  <Typography key={idx} type="large" style={{ marginTop: '14px' }}>
                    {calcNode.operator}
                  </Typography>
                ) : null,
              )}
            </FlexSpace>
          ) : (
            <div
              className={css`
                max-width: ${line.maxWidth};
              `}
            >
              {line.text}
            </div>
          ),
        )}
    </FlexSpace>
  );
}
