import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { GetReplenishmentRulesLocationDTO } from '@recurrency/core-api-schema/dist/replenishmentRules/getReplenishmentRules';
import { DataNode } from 'antd/lib/tree';

import { Card, CardSection } from 'components/Card';
import { Tree } from 'components/Tree';

import { showAsyncModal } from 'utils/asyncModal';
import { splitIdNameStr } from 'utils/formatting';

import { EditReplenishmentPathNodeModal } from './modals/EditReplenishmentPathNodeModal';

export type ReplenishmentPathsTreeProps = {
  searchQuery?: string;
  showCardWrapper?: boolean;
  replenishmentPathTree?: GetReplenishmentRulesLocationDTO[];
  setReplenishmentPathTree: (newReplenishmentPathTree: GetReplenishmentRulesLocationDTO[]) => void;
};

export function ReplenishmentPathsTree({
  searchQuery,
  showCardWrapper,
  replenishmentPathTree = [],
  setReplenishmentPathTree,
}: ReplenishmentPathsTreeProps) {
  const treeData = sortTreeData(convertReplenishmentPathToTree(replenishmentPathTree));

  function setTreeData(newTreeData: DataNode[]) {
    setReplenishmentPathTree(convertTreeToReplenishmentPath(newTreeData));
  }

  function convertReplenishmentPathToTree(nodes: GetReplenishmentRulesLocationDTO[]): DataNode[] {
    return nodes.map((node) => ({
      key: `${node.locationId}: ${node.locationName}`,
      title: (
        <span
          onClick={() => openEditReplenishmentPathNodeModal(node)}
          className={css`
            padding-right: 20px;
            display: inline;
            position: relative;
            .open-edit-path-modal-button {
              display: none;
              position: absolute;
              right: 0px;
              bottom: 2px;
            }

            &:hover {
              .open-edit-path-modal-button {
                display: inline;
              }
            }
          `}
        >
          {node.locationId}: {node.locationName} <EditOutlined className="open-edit-path-modal-button" />
        </span>
      ),
      children: node.children && node.children.length > 0 ? convertReplenishmentPathToTree(node.children) : [],
    }));
  }

  function convertTreeToReplenishmentPath(treeNodes: DataNode[]): GetReplenishmentRulesLocationDTO[] {
    return treeNodes.map((node) => ({
      locationId: splitIdNameStr(String(node.key)).foreignId,
      locationName: splitIdNameStr(String(node.key)).name,
      children: node.children ? convertTreeToReplenishmentPath(node.children) : [],
    }));
  }

  function sortTreeData(treeDataToSort: DataNode[]) {
    return treeDataToSort.sort((a, b) => {
      // This is to allow sorting of the tree nodes by the locationId, and then by the locationName all in the same string
      if (typeof a.key === 'string' && typeof b.key === 'string') {
        return a.key.localeCompare(b.key, 'en', { numeric: true });
      }
      if (a.key > b.key) return 1;
      if (a.key < b.key) return -1;
      return 0;
    });
  }

  function openEditReplenishmentPathNodeModal(node: GetReplenishmentRulesLocationDTO) {
    showAsyncModal(EditReplenishmentPathNodeModal, {
      currentNode: node,
      replenishmentPathTree,
      setReplenishmentPathTree,
    });
  }

  const tree = (
    <Tree
      searchQuery={searchQuery}
      defaultExpandAll
      className={css`
        .ant-tree-node-content-wrapper::before {
          content: '⋮⋮';
          display: inline-block;
          margin-right: 5px;
          cursor: move;
        }

        .ant-tree-treenode-disabled {
          display: none;
        }
      `}
      treeData={treeData}
      setTreeData={setTreeData}
    />
  );

  return showCardWrapper ? (
    <Card>
      <CardSection>{tree}</CardSection>
    </Card>
  ) : (
    tree
  );
}
