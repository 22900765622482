import React from 'react';

import { css } from '@emotion/css';

import { truthy } from 'utils/boolean';

export interface LabelValueProperty {
  label: string;
  value: React.ReactChild;
}

export function LabelValueProperties({ properties }: { properties: Array<LabelValueProperty | null> }) {
  if (!properties?.length) {
    return null;
  }

  return (
    <div>
      {properties.filter(truthy).map((prop, idx) => (
        <div
          key={idx}
          className={css`
            display: flex;
            align-items: center;
            white-space: pre-wrap;
          `}
        >
          {prop.label}: {prop.value}
        </div>
      ))}
    </div>
  );
}
