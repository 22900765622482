import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig } = config.quoteOrderEntryFlow;
  quoteOrderFormConfig.ocrCode = {
    enableExport: true,
    defaultValueFieldFromAlgoliaCustomer: 'dept_dimension',
  };
  quoteOrderFormConfig.ocrCode2 = {
    enableExport: true,
    defaultValueFieldFromAlgoliaCustomer: 'market_dimension',
  };
  return config;
}
