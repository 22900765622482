import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig } = config.quoteOrderEntryFlow;
  const { formConfig: itemFormConfig } = config.itemEntryFlow;
  quoteOrderFormConfig.class1.fieldType = 'checkbox';
  itemFormConfig.locationForQuoteEntry = {
    selectMode: 'list',
    listOptions: {
      defaultLocation: false,
      quoteEntryLocation: true,
    },
  };
  itemFormConfig.locationForInventory = {
    selectMode: 'all',
    excludeName: 'TEST',
  };
  return config;
}
