import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { AuditLog } from './AuditLog';

interface SupplierLocationSettingsAuditLogProps {
  supplierId: string;
  locationId: string;
}

export const SupplierLocationSettingsAuditLog = ({ supplierId, locationId }: SupplierLocationSettingsAuditLogProps) => (
  <AuditLog
    endpoint={schemas.auditLog.getSupplierLocationSettingsAuditLog}
    queryParams={{
      filter: {
        supplierId,
        locationId,
      },
    }}
  />
);
