import { useState } from 'react';

export function useModal() {
  const [isVisible, setIsVisible] = useState(false);

  function onOpen() {
    setIsVisible(true);
  }

  function onClose() {
    setIsVisible(false);
  }

  return {
    isVisible,
    onOpen,
    onClose,
  };
}
