import React from 'react';

import { LineItemActionsP21 } from 'pages/orders/quotes/types';

import { ActionButton } from 'components/Button/ActionButton';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';

import { showAsyncModal } from 'utils/asyncModal';

import { QuoteLineItemP21 } from 'types/hash-state';

import { ItemCustomerPartIdModal } from './ItemCustomerPartIdModal';

export function CustomerPartIdPropertiesP21({
  lineItem,
  lineItemActions,
  lineItemIdx,
  companyId,
  customerId,
}: {
  lineItem: QuoteLineItemP21;
  lineItemActions: LineItemActionsP21;
  lineItemIdx: number;
  companyId: string;
  customerId: string;
}) {
  const props: LabelValueProperty[] = [
    {
      label: 'Customer Part No',
      value: (
        <>
          {lineItem.customerPartId || `None Selected`}
          {` | `}
          <ActionButton
            label="Edit"
            onClick={async () => {
              const selectedCustomerPartId = await showAsyncModal(ItemCustomerPartIdModal, {
                lineItem,
                companyId,
                customerId,
              });
              if (selectedCustomerPartId) {
                lineItemActions.updateLineItem(lineItemIdx, { customerPartId: selectedCustomerPartId });
              }
            }}
          />
        </>
      ),
    },
  ];

  return <LabelValueProperties properties={props} />;
}
