import React, { useState } from 'react';

import { css } from '@emotion/css';
import { TenantTableSyncStatusDTO } from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';
import { BarChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Bar } from 'recharts';
import { theme } from 'theme';

import { FilterBarBox } from 'components/FilterBarBox';
import { Input } from 'components/Input';
import { Table } from 'components/Table';

import { sortableStringColumn, sortableNumberColumn } from 'utils/tables';

import { EditableDateCell } from './EditableDateCell';

// Define the styles using @emotion/css
const changeTrackingStyles = css`
  font-weight: bold;
  color: red;
`;

export interface TenantTableSyncStatusTableProps {
  tableStatusList?: TenantTableSyncStatusDTO[];
  tenantId: string;
  onDataChanged: () => void;
}

export interface TenantTableSyncStatusChartProps {
  tableStatusList?: TenantTableSyncStatusDTO[];
}

export function TenantSyncStatusTable({ tableStatusList, tenantId, onDataChanged }: TenantTableSyncStatusTableProps) {
  const tableStatusColumns: ColumnType<TenantTableSyncStatusDTO>[] = [
    sortableStringColumn({
      title: 'Source Table',
      dataIndex: 'sourceTableName',
    }),
    sortableStringColumn({
      title: 'Target Table',
      dataIndex: 'targetTableName',
    }),
    sortableStringColumn({
      title: 'Synced to (Tenant TZ)',
      dataIndex: 'dateLastModified',
      render: (value, record) => (
        <EditableDateCell
          dateType="Modified"
          tenantId={tenantId}
          tableName={record.targetTableName}
          dbType={record.dbType}
          initialDate={value}
          onDataChanged={onDataChanged}
        />
      ),
    }),
    sortableStringColumn({
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (value, record) => (
        <EditableDateCell
          dateType="Updated"
          tenantId={tenantId}
          tableName={record.targetTableName}
          dbType={record.dbType}
          initialDate={value}
          onDataChanged={onDataChanged}
        />
      ),
    }),
    sortableStringColumn({
      title: 'Created At',
      dataIndex: 'createdAt',
    }),
    sortableNumberColumn({
      title: 'Diff',
      dataIndex: 'countDiff',
      render: (_, record) => {
        if (
          record.sourceTableCount === null ||
          record.sourceTableCount === undefined ||
          record.targetTableCount === null ||
          record.targetTableCount === undefined
        ) {
          return '-';
        }
        return (record.sourceTableCount - record.targetTableCount).toLocaleString();
      },
    }),
    sortableNumberColumn({
      title: 'Diff %',
      dataIndex: 'diffPercentage',
      render: (value) => {
        if (value === null || value === undefined) {
          return '-';
        }
        //  * = more than 1% in difference
        value = Math.abs(value);
        value = Math.round(value * 100) / 100; // 2 decimal places
        if (value > 1) return `*${value}`;
        return value;
      },
    }),
    sortableNumberColumn({
      title: 'Src Table Count',
      dataIndex: 'sourceTableCount',
    }),
    sortableNumberColumn({
      title: 'Dest Table Count',
      dataIndex: 'targetTableCount',
    }),
    sortableStringColumn({
      title: 'Sync Strategy',
      dataIndex: 'syncStrategy',
      render: (value) => {
        if (value === null || value === undefined) {
          return '-';
        }
        if (value === 'sync_change_tracking') {
          return <div className={changeTrackingStyles}>Change Tracking</div>;
        }
        return value;
      },
    }),
    sortableStringColumn({
      title: 'Last Comparator Run',
      dataIndex: 'diffCreatedAt',
      render: (value) => {
        if (value === null || value === undefined) {
          return '-';
        }
        return value;
      },
    }),
    sortableStringColumn({
      title: 'Type',
      dataIndex: 'dbType',
      render: (value) => (
        <div
          title={value === 'postgres' ? 'Tenant DB -> Postgres' : value}
          className={css`
            cursor: help;
          `}
        >
          {value}
        </div>
      ),
    }),
  ];

  const [query, setQuery] = useState('');
  let filteredList: { sourceTableName: string; dbType: string }[] = [];
  if (tableStatusList) {
    filteredList = fuzzyFilter(tableStatusList || [], query, {
      fields: ['sourceTableName', 'dbType'],
    })
      .map(({ item }) => item)
      .sort((a, b) => (b.countDiff ?? 0) - (a.countDiff ?? 0));
  }

  let isChangeTrackingEnabled = false;
  if (tableStatusList) {
    // Check if at least one instance of 'sync_change_tracking' exists in syncStrategy
    isChangeTrackingEnabled = tableStatusList.some((item) => item.syncStrategy === 'sync_change_tracking');
  }

  return (
    <>
      {isChangeTrackingEnabled && <p className={changeTrackingStyles}> Change Tracking Enabled!</p>}
      <FilterBarBox>
        <Input
          placeholder="Filter Table or Type"
          title="search"
          onChange={(ev) => setQuery(ev.target.value)}
          style={{ maxWidth: '300px' }}
        />
      </FilterBarBox>
      <p>* = need attention, more than 1% in difference or one day in delay</p>
      <Table
        columns={tableStatusColumns}
        data={filteredList}
        rowKey={(record) => `${record.sourceTableName}-${record.targetTableName}-${record.dbType}`}
        isLoading={!tableStatusList}
      />
    </>
  );
}

export function TenantSyncDiffChart({ tableStatusList }: TenantTableSyncStatusChartProps) {
  const data = [];
  const temp_list = tableStatusList || [];
  // due to the nature of the sync, there's an uneven distribution of tables with sync diff percentage,
  // here I pick below 0, 0-10 and > 10 percentage point as arbitrary yet good grouping thresholds
  const max_relevant_percentage = 10;
  // calc tables with sync diff below 0 percent
  data.push({
    range: `[,${0})`,
    tables_in_diff_percent_range: temp_list.filter((x) => (x.diffPercentage ?? 0) < 0).length,
  });
  // calc tables with sync diff between 0 and 10 percent
  let i = 1;
  for (; i <= max_relevant_percentage; i++) {
    const upper = i;
    const lower = i - 1;
    const segment_count = temp_list.filter(
      (x) => (x.diffPercentage ?? 0) >= lower && (x.diffPercentage ?? 0) < upper,
    ).length;
    data.push({ range: `[${lower},${upper})`, tables_in_diff_percent_range: segment_count });
  }
  // cal tables with sync diff above 10 percent
  data.push({
    range: `[${i - 1},)`,
    tables_in_diff_percent_range: temp_list.filter((x) => (x.diffPercentage ?? 0) >= i - 1).length,
  });

  return (
    <BarChart width={800} height={250} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="range" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="tables_in_diff_percent_range" fill={theme.colors.chart.lagoonBlue} />
    </BarChart>
  );
}
