import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import {
  GetSalesInvoiceLinesReportGroupBy,
  SalesInvoiceLinesReportItemDTO,
} from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';

import { NewOrderButton } from 'components/recipes/NewOrderButton';
import { NewQuoteButton } from 'components/recipes/NewQuoteButton';
import { SalesReportTable } from 'components/recipes/salesReport/SalesReportTable';
import { FilterByField } from 'components/recipes/salesReport/salesReportUtils';
import { SelectionCountContext } from 'components/recipes/SelectionCountContext';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { optArrFromVal } from 'utils/array';
import { joinIdNameObj } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';

import { IdNameObj } from 'types/legacy-api';

export const TopItemsTable = ({ customerId, customerName }: { customerId: string; customerName: string }) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const [selectedItemIdNameObjs, setSelectedItemIdNameObjs] = useState<IdNameObj[]>([]);
  const selectedItemIds = useMemo(() => selectedItemIdNameObjs.map((item) => item.foreignId), [selectedItemIdNameObjs]);

  const handleRowSelect = (item: SalesInvoiceLinesReportItemDTO) => {
    if (selectedItemIds.includes(item.foreignId)) {
      setSelectedItemIdNameObjs(selectedItemIdNameObjs.filter((i) => i.foreignId !== item.foreignId));
    } else {
      setSelectedItemIdNameObjs([{ foreignId: item.foreignId, name: item.name }, ...selectedItemIdNameObjs]);
    }
  };

  const handleAllSelect = (selected: boolean, _: any, items: SalesInvoiceLinesReportItemDTO[]) => {
    const itemsToChange = items.map((item) => ({ foreignId: item.foreignId, name: item.name }));
    if (selected) {
      setSelectedItemIdNameObjs([...itemsToChange, ...selectedItemIdNameObjs]);
    } else {
      const itemIdsToRemove = itemsToChange.map((item: IdNameObj) => item.foreignId);
      setSelectedItemIdNameObjs(selectedItemIdNameObjs.filter((i) => !itemIdsToRemove.includes(i.foreignId)));
    }
  };

  return (
    <div>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          gap: 16px;
          align-items: center;
          margin-bottom: 12px;
        `}
      >
        <SelectionCountContext selection={selectedItemIdNameObjs} onClear={() => setSelectedItemIdNameObjs([])} />
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <NewQuoteButton
            hashState={{
              customer: joinIdNameObj({ foreignId: customerId, name: customerName }),
              items: selectedItemIdNameObjs,
            }}
            props={{
              disabled: selectedItemIds.length === 0,
              onClick: () => {
                setSelectedItemIdNameObjs([]);
              },
            }}
          />
          <NewOrderButton
            hashState={{
              customer: joinIdNameObj({ foreignId: customerId, name: customerName }),
              items: selectedItemIdNameObjs,
            }}
            props={{
              disabled: selectedItemIds.length === 0,
              onClick: () => {
                setSelectedItemIdNameObjs([]);
              },
            }}
          />
        </div>
      </div>
      <SalesReportTable
        groupBy={GetSalesInvoiceLinesReportGroupBy.Item}
        filter={{ [FilterByField.Customer]: optArrFromVal(customerId) }}
        rowSelection={
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote)
            ? {
                selectedRowKeys: selectedItemIds,
                onSelect: handleRowSelect,
                onSelectAll: handleAllSelect,
              }
            : undefined
        }
        ignoreRoleSalesRepIds
      />
    </div>
  );
};
