import React from 'react';

import { Button } from 'components/Button';

import { Modal } from '../Modal/Modal';

export interface AutoSaveModalProps {
  visible: boolean;
  onYes: () => void;
  onNo: () => void;
}

export const AutoSaveModal = ({ visible, onNo, onYes }: AutoSaveModalProps) => (
  <Modal
    visible={visible}
    onCancel={onNo}
    title="You have unfinished work"
    footer={
      <>
        <Button onClick={onNo}>No</Button>
        <Button onClick={onYes} type="primary">
          Yes
        </Button>
      </>
    }
  >
    You have unfinished work on this page. Would you like to continue from where you left off?
  </Modal>
);
