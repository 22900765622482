export type UnitConverterFn = (value: number, targetSize?: number, defaultSize?: number) => number;

export const priceUnitConverter: UnitConverterFn = (value, targetSize, valueSize) =>
  (value * (targetSize || 1)) / (valueSize || 1);

export const qtyUnitConverter: UnitConverterFn = (value, targetSize, valueSize) =>
  (value * (valueSize || 1)) / (targetSize || 1);

/**
 * Safely capture and convert an unknow data type to a number or undefiend
 * @param value an unknow value type
 * @param defaultValue an unknow value type
 * @returns
 */
export function floatOrDefault(value: Maybe<string | number | undefined>, defaultValue: number): number {
  if (!value) return defaultValue;
  const parsedValue = parseFloat(String(value));
  if (isNaN(parsedValue)) return defaultValue;
  return parsedValue;
}
