import React, { useEffect, useMemo, useState } from 'react';

import { TagOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { fuzzyFilter } from 'fuzzbunny';
import { useDebounce } from 'use-debounce';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { usePurchaseClassSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { Modal } from 'components/Modal';
import { ProductGroupMultiSelect } from 'components/recipes/select/ProductGroupMultiSelect';
import { PurchaseStatusMultiSelect } from 'components/recipes/select/PurchaseStatusMultiSelect';
import { SelectionCountContext } from 'components/recipes/SelectionCountContext';
import { SearchInput } from 'components/SearchInput';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { track, TrackEvent } from 'utils/track';

import { PurchasingLineInput, SupplierLocationWTarget } from 'types/hash-state';

import { GenericAutoTrimFillTableRow, GenericTargetLineDTO } from '../types';
import {
  calculateFieldQtyTotal,
  getFieldTotal,
  getLinesForSupplierLocation,
  getLineStatusCounts,
  getMergedPurchaseLines,
  getPOSummaries,
  TargetFieldSumMap,
} from '../utils';
import { PurchaseAutoTargetFillTrimEmptyView } from './autoTrimFill/PurchaseAutoFillTrimEmptyView';
import { PurchaseAutoFillTrimStatus } from './autoTrimFill/PurchaseAutoFillTrimStatus';
import { PurchaseAutoTargetFillTrimTable } from './autoTrimFill/PurchaseAutoTargetFillTrimTable';
import {
  calculateDaysOfSupply,
  getAutoFillLines,
  getAutoFillTrimTrackProps,
  getAutoTrimLines,
} from './autoTrimFill/utils';
import { getUniqueItemGroupOptions } from './utils';

export function PurchaseAutoTargetFillTrimModal({
  supplierLocations,
  targetLines,
  purchasingLinesById,
  isHubAndSpoke,
  targetValue,
  currentValue,
  targetType,
  onClose,
}: {
  supplierLocations: SupplierLocationWTarget[];
  targetLines: GenericTargetLineDTO[];
  purchasingLinesById: Obj<PurchasingLineInput>;
  isHubAndSpoke: boolean;
  targetValue: number;
  currentValue: number;
  targetType: SupplierTargetTypes;
  onClose: (purchasingLinesById?: Obj<PurchasingLineInput>) => void;
}) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [selectedLineKeys, setSelectedLineKeys] = useState<string[]>([]);
  const [lines, setLines] = useState<GenericAutoTrimFillTableRow[]>([]);
  const [itemGroupIdsFilter, setItemGroupIdsFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [abcClassFilter, setAbcClassFilter] = useState<string[]>([]);
  const purchaseClassSelectProps = usePurchaseClassSelectProps();

  const filteredLines = useMemo(
    () =>
      fuzzyFilter(lines, debouncedSearchQuery, {
        fields: ['itemId', 'itemName'],
      })
        .map((i) => i.item)
        .filter(
          (i) =>
            (itemGroupIdsFilter.length > 0 ? !!i.itemGroupId && itemGroupIdsFilter.includes(i.itemGroupId) : true) &&
            (statusFilter.length > 0 ? !!i.status && statusFilter.includes(i.status) : true) &&
            (abcClassFilter.length > 0 ? !!i.purchaseClass && abcClassFilter.includes(i.purchaseClass) : true),
        ),
    [lines, debouncedSearchQuery, abcClassFilter, itemGroupIdsFilter, statusFilter],
  );
  const selectedLines = useMemo(
    () => lines.filter((line) => selectedLineKeys.includes(line.key)),
    [lines, selectedLineKeys],
  );
  const allLines = getMergedPurchaseLines(
    getLinesForSupplierLocation(targetLines, supplierLocations),
    purchasingLinesById,
  );
  // lines in the PO not are not changed by the suggestion
  // used for totaling the PO summary
  const unchangedLines = useMemo(
    () => allLines.filter((line) => line.userQtyToOrder > 0 && !selectedLines.find((l) => l.key === line.key)),
    [allLines, selectedLines],
  );
  const poSummaries = useMemo(
    () =>
      getPOSummaries([...selectedLines, ...unchangedLines], supplierLocations).filter((po) =>
        isHubAndSpoke ? po.purchasingLocation.foreignId === po.requirementLocation?.foreignId : true,
      ),
    [isHubAndSpoke, selectedLines, supplierLocations, unchangedLines],
  );
  const lineStatusCounts = useMemo(
    () => getLineStatusCounts([...selectedLines, ...unchangedLines]),
    [selectedLines, unchangedLines],
  );
  const recommendedValue = useMemo(() => {
    const linesTotalValue = selectedLines.reduce(
      (total: number, line) =>
        // total values for currentQtyToOrder is already part of currentValue
        calculateFieldQtyTotal(line, TargetFieldSumMap[targetType], line.userQtyToOrder - line.currentQtyToOrder) +
        total,
      0,
    );
    return linesTotalValue + currentValue;
  }, [selectedLines, currentValue, targetType]);
  const hideWarning = useMemo(() => {
    // hide the warning message when
    //  - there are no recommendation
    //  - po modified by deselecting a row
    //  - po modified by changing qty to order
    const qtyChanged = lines.find((line) => line.userQtyToOrder !== line.recommendedQtyToOrder);
    return lines.length === 0 || selectedLineKeys.length !== lines.length || qtyChanged != null;
  }, [lines, selectedLineKeys]);
  const recommendedPOCost = useMemo(
    () => getFieldTotal([...lines, ...unchangedLines], TargetFieldSumMap[SupplierTargetTypes.Dollars]),
    [lines, unchangedLines],
  );
  const currentPOCost = useMemo(
    () => getFieldTotal(allLines, TargetFieldSumMap[SupplierTargetTypes.Dollars]),
    [allLines],
  );

  // only call this once when loading the modal. this is the initial pre selected rows
  useEffect(
    () => {
      const recommendedLines =
        currentValue > targetValue
          ? getAutoTrimLines(allLines, currentValue, targetValue, targetType)
          : getAutoFillLines(allLines, currentValue, targetValue, targetType);
      setLines(recommendedLines);
      setSelectedLineKeys(recommendedLines.map((line) => line.key));

      // Fire off track event for matching qty lines
      track(TrackEvent.Purchasing_AutoFillTrimModal_Open, getAutoFillTrimTrackProps(recommendedLines || []));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onRowSelectionChange = (newSelectedLineKeys: string[]) => {
    setSelectedLineKeys(newSelectedLineKeys);
  };
  const onOrderQtyChange = (value: number, line: GenericAutoTrimFillTableRow) => {
    line.userQtyToOrder = value;
    line.projectedDaysOfSupply = calculateDaysOfSupply(line);
    setLines([...lines]);
  };
  const handlePurchasingLinesChange = () => {
    const newPurchasingLinesById = { ...purchasingLinesById };

    for (const lineKey of selectedLineKeys) {
      const selectedLine = lines.find((line) => line.key === lineKey);
      if (selectedLine != null) {
        if (newPurchasingLinesById[lineKey] == null) {
          // case for new items to be added to the lines
          newPurchasingLinesById[lineKey] = {};
        }
        newPurchasingLinesById[lineKey].qtyToOrder = selectedLine.userQtyToOrder;
      }
    }
    onClose(newPurchasingLinesById);
  };
  const location = isHubAndSpoke
    ? poSummaries[0].purchasingLocation
    : { foreignId: supplierLocations[0].locationId, name: supplierLocations[0].locationName };
  const isAutoTrim = targetValue < currentValue;

  return (
    <Modal
      title={
        <ConditionalWrapper
          condition={isAutoTrim}
          wrapper={(children) => (
            <InfoTooltip title="Backorders won't be reduced to below backorder quantity.">{children}</InfoTooltip>
          )}
        >
          <>
            {isAutoTrim ? 'Auto Trim' : 'Auto Fill'} for Supplier #{supplierLocations[0].supplierId}:{' '}
            {supplierLocations[0].supplierName} at{' '}
            {supplierLocations.length === 1 || isHubAndSpoke
              ? `Location #${location.foreignId}: ${location.name}`
              : `${supplierLocations.length} Locations`}
          </>
        </ConditionalWrapper>
      }
      visible
      width="95vw"
      bodyStyle={{ overflowY: 'scroll', height: '85vh' }}
      centered
      okText="Apply"
      onCancel={() => onClose()}
      onOk={handlePurchasingLinesChange}
      wrapClassName="purchase-auto-target-fill-trim-modal"
    >
      <PurchaseAutoFillTrimStatus
        targetValue={targetValue}
        recommendedValue={recommendedValue}
        currentValue={currentValue}
        hideWarning={hideWarning}
        currentPOCost={currentPOCost}
        recommendedPOCost={recommendedPOCost}
      />
      <FilterBarBox
        dividerLine
        className={css`
          margin-top: 20px;
        `}
      >
        <ProductGroupMultiSelect
          selectProps={{
            options: getUniqueItemGroupOptions(targetLines),
          }}
          selectedValues={itemGroupIdsFilter}
          onSelectedValuesChange={setItemGroupIdsFilter}
        />
        <PurchaseStatusMultiSelect selectedValues={statusFilter} onSelectedValuesChange={setStatusFilter} />
        <AsyncMultiSelect
          selectProps={convertToMultiSelectProps(purchaseClassSelectProps)}
          label="ABC Class"
          queryPlaceholder="Search ABC classes"
          selectedValues={abcClassFilter}
          onSelectedValuesChange={setAbcClassFilter}
          icon={<TagOutlined />}
        />
        <FlexSpacer />
        <SelectionCountContext selection={selectedLineKeys} onClear={() => setSelectedLineKeys([])} />
        <SearchInput
          query={searchQuery}
          onQueryChange={(value) => setSearchQuery(value)}
          placeholder="Search Item ID / Description"
        />
      </FilterBarBox>

      <PurchaseAutoTargetFillTrimTable
        emptyView={
          lines.length > 0 ? null : (
            <PurchaseAutoTargetFillTrimEmptyView currentValue={currentValue} targetValue={targetValue} />
          )
        }
        filteredLines={filteredLines}
        poSummaries={poSummaries}
        lineStatusCounts={lineStatusCounts}
        locations={supplierLocations}
        selectedLineKeys={selectedLineKeys}
        onRowSelectionChange={onRowSelectionChange}
        onOrderQtyChange={onOrderQtyChange}
        isHubAndSpoke={isHubAndSpoke}
      />
    </Modal>
  );
}
