import React, { useRef } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { SelectProps } from 'antd/lib/select';

import { UseAsyncSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';

import { SmallLoader } from '../Loaders';
import { Select, SelectOption } from '../Select';

export const tableFilterSelectClassName = css`
  width: 300px;
  display: block;
`;

export const tableFilterSelectEqualWidthClassName = css`
  ${tableFilterSelectClassName}
  flex: 1;
`;

export enum AsyncSelectVariant {
  Search = 'search',
  Filter = 'filter',
  Select = 'select',
}

export interface AsyncSelectProps extends SelectProps<any> {
  selectProps: UseAsyncSelectProps | (() => UseAsyncSelectProps);
  entityPlural: string;
  variant?: AsyncSelectVariant;
}

export function AsyncSelect({
  selectProps,
  entityPlural,
  placeholder,
  onSelect,
  variant = AsyncSelectVariant.Search,
  ...otherProps
}: AsyncSelectProps) {
  // selectProps could be passed as a hook function, resolve to obj
  let selectPropsObj = typeof selectProps === 'function' ? selectProps() : selectProps;

  const prevOptionsRef = useRef<SelectOption[]>();
  if (selectPropsObj.isLoading && prevOptionsRef.current) {
    selectPropsObj = { ...selectPropsObj, options: prevOptionsRef.current, isLoading: false };
  } else if (!selectPropsObj.isLoading) {
    prevOptionsRef.current = selectPropsObj.options;
  }

  return (
    <Select
      loading={selectPropsObj.isLoading}
      options={selectPropsObj.options}
      placeholder={
        placeholder ||
        (variant === AsyncSelectVariant.Search ? (
          `Search ${entityPlural}`
        ) : variant === AsyncSelectVariant.Select ? (
          `Select ${entityPlural}`
        ) : (
          <>
            <FilterOutlined
              className={css`
                margin-right: 4px;
              `}
            />
            Filter by {entityPlural}
          </>
        ))
      }
      notFoundContent={selectPropsObj.isLoading ? <SmallLoader /> : `No ${entityPlural} found`}
      showSearch
      filterOption={false}
      onSearch={selectPropsObj.setSearchQuery}
      onSelect={(value, option) => {
        // clear search query after user makes a selection
        selectPropsObj.setSearchQuery('');
        selectPropsObj.onSelect?.(value);
        onSelect?.(value, option);
      }}
      // use dropdownLabel as the label for dropdown if it exists
      optionLabelProp={selectPropsObj.options?.[0]?.dropdownLabel ? 'dropdownLabel' : undefined}
      {...otherProps}
    />
  );
}
