import React from 'react';

import { IdNameObj } from '@recurrency/core-api-schema/dist/purchaseOrders/getPurchaseOrderPdfDetails';

import { FilterBarBox } from 'components/FilterBarBox';
import { SplitPage } from 'components/SplitPage';
import { Table } from 'components/Table';

import { formatNumber, formatUSD, joinIdNameObj } from 'utils/formatting';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';
import { asKeyOf } from 'utils/tables';

import { TransferTargetSummary } from 'types/hash-state';

export function TransferTargetLinesFinalize({ transferSummaries }: { transferSummaries: TransferTargetSummary[] }) {
  const tableColumns: PersistedColumn<TransferTargetSummary>[] = [
    {
      title: 'Source Location',
      dataIndex: asKeyOf<TransferTargetSummary>('sourceLocation'),
      settingKey: 'sourceLocation',
      required: true,
      render: (sourceLocation: IdNameObj) => joinIdNameObj(sourceLocation),
    },
    {
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferTargetSummary>('destinationLocation'),
      settingKey: 'destinationLocation',
      required: true,
      render: (destinationLocation: IdNameObj) => joinIdNameObj(destinationLocation),
    },
    {
      title: 'Transfer Days',
      dataIndex: asKeyOf<TransferTargetSummary>('transferDays'),
      settingKey: 'transferDays',
      optional: true,
    },
    {
      title: 'Total Lines',
      dataIndex: asKeyOf<TransferTargetSummary>('totalLines'),
      settingKey: 'totalLines',
    },
    {
      title: 'Current ($)',
      dataIndex: asKeyOf<TransferTargetSummary>('totalCost'),
      align: 'right' as const,
      settingKey: 'totalCost',
      render: (currentValue: number) => formatUSD(currentValue),
    },
    {
      title: 'Current Weight',
      dataIndex: asKeyOf<TransferTargetSummary>('totalWeight'),
      settingKey: 'totalWeight',
      optional: true,
      render: (currentWeight: number) => formatNumber(currentWeight),
    },
    {
      title: 'Current Volume',
      dataIndex: asKeyOf<TransferTargetSummary>('totalVolume'),
      settingKey: 'totalVolume',
      optional: true,
    },
  ];

  return (
    <SplitPage
      left={
        <div>
          <FilterBarBox />
          <Table columns={tableColumns} data={transferSummaries} verticalAlign="middle" />
        </div>
      }
      // TODO @brunno-recurrency - Implement the right side transfer overview panel
      right={null}
    />
  );
}
