// NOTE: All fields from legacy api come as snake_case.
// We camelCase them via camelCaseKeys helper in utils/api.ts
// #region api responses

/** GET /v3/customers/{customerId}/restrictions */
export interface CustomerRestrictionsResponse {
  customerId: string;
  customerName: string;
  poNoRequired: 'Y' | 'N';
  creditLimit: number;
  creditLimitUsed: number;
  creditStatus: {
    creditStatusId: string;
    creditStatusDesc: string;
    orderEntryAction: CustomerRestrictionsOrderEntryAction;
    validationAction: CustomerRestrictionsValidationAction;
  };
  stateSalesTaxExemptionNumber?: string;
  customFields?: {
    defaultPoNumber: string | null;
    customerJobNumRequired: 'Y' | 'N';
  };
}

/** GET /v3/items/{invMastUid}/transfer-lines, GET /v3/orders/{orderNo}/transfer-lines */
export type TransferLinesResponse = ListResponse<TransferLine>;

/** GET /v3/orders/{orderNo}/shipments */
export type OrderShipmentsResponse = ListResponse<OrderShipment>;

export interface PriceOverrideReason {
  description: string;
  code: string;
}

/** GET /v3/salesrep */
export interface SalesRepLocationResponse {
  taker: string;
  locationId: number;
  locationName: string;
}

/** GET /v3/orders/{orderNo} */
export interface Order {
  orderNo: string;
  customerId: string;
  orderDate: string;
  ship2Name: string;
  ship2Add1: string;
  ship2Add2: null;
  ship2City: string;
  ship2State: string;
  ship2Zip: string;
  ship2Country: string;
  packingBasis: string;
  orderTypePriority: string;
  requestedDate: string;
  poNo: string;
  completed: 'Y' | 'N';
  projectedOrder: 'Y' | 'N';
  purchaseOrder?: {
    poNo: string;
  };
  carrierId: string;
  addressId: string;
  contactId: string;
  taker: string;
  approved: 'Y' | 'N';
  cancelFlag: 'Y' | 'N';
  tenantId: string;
  customer: OrderCustomer;
  orderAddress: OrderAddress;
  contact?: OrderContact;
  lines: OrderLine[];
  location: OrderLocation;
  quote: boolean;
  willCall: 'Y' | 'N';
  freightType?: string;
}

/** GET /v3/quotes/{foreignId} */
export interface Quote {
  orderNo: string;
  customerId: string;
  orderDate: string;
  ship2Name: string;
  ship2Add1: string;
  ship2Add2: null;
  ship2City: string;
  ship2State: string;
  ship2Zip: string;
  ship2Country: string;
  packingBasis: string;
  orderTypePriority: string;
  requestedDate: string;
  poNo: string;
  completed: 'Y' | 'N';
  projectedOrder: 'Y' | 'N';
  purchaseOrder?: {
    poNo: string;
  };
  carrierId: string;
  addressId: string;
  contactId: string;
  taker: string;
  approved: 'Y' | 'N';
  cancelFlag: 'Y' | 'N';
  tenantId: string;
  customer: OrderCustomer;
  orderAddress: OrderAddress;
  contact: OrderContact;
  lines: OrderLine[];
  location: OrderLocation;
  quote: true;
  willCall: 'Y' | 'N';
  freightType?: string;
}

export interface LineItemStats {
  mean: number;
  min: number;
  max: number;
  median: number;
  xys: Array<{ x: number; y: number }>;
  gmLow: number;
  gmHigh: number;
}

export interface PurchaseOrder {
  poNo: string;
  orderDate: string;
  complete: boolean;
  open: boolean;
  vendorId: string;
  vendorName: string;
  supplierId: string;
  supplierName: string;
  carrierId: string;
  carrierName: string;
  locationId: string;
  locationName: string;
  ship2Name: string;
  ship2Add1: string;
  ship2Add2: string;
  ship2City: string;
  ship2State: string;
  ship2Zip: string;
  ship2Country: string;
  requestedBy: string; // aka buyer
  requestingContact: {
    firstName: string;
    lastName: string;
  };
  cancelFlag: string;
  tenantId: string;
  contactsId: string;
  contactsName: string;
  purchaseValue: number;
  linesCount: number;
  lines: PurchaseOrderLine[];
}

export interface PurchaseOrderLine {
  cancelFlag: string;
  closedFlag: string;
  complete: boolean;
  dateDue: string;
  deleteFlag: string;
  extendedDesc: string;
  invMastUid: string;
  itemDesc: string;
  itemId: string;
  lineComplete: string;
  lineNo: string;
  poLineUid: string;
  poNo: string;
  pricingUnit: string;
  pricingUnitSize: number;
  qtyOrdered: number;
  qtyReceived: number;
  qtyToVouch: null;
  status: 'open' | 'complete';
  tenantId: string;
  totalAmount: number;
  unitOfMeasure: string;
  unitPrice: number;
  unitQuantity: number;
  unitSize: number;
}

// #endregion

// #region helpers
export interface IdNameObj {
  /** Id in tenant's source ERP DB */
  foreignId: string;
  /** Human readable name of referred object */
  name: string;
}

export interface ListResponse<RecordT> {
  items: RecordT[];
  totalCount: number;
}
// #endregion

export interface ShipToAddress {
  id: string;
  name: string;
  physAddress1: string;
  physAddress2: string;
  physCity: string;
  physState: string;
  physPostalCode: string;
  centralPhoneNumber: string;
  centralFaxNumber: string;
  lastMaintainedBy: string;
  emailAddress: string;
  url: string | null;
}

export interface LineItemPriceInfoUOM {
  default: boolean;
  unitOfMeasure: string;
  unitSize: number;
}

/* --- V4 Endpoints and Types --- */

export interface LineItemRequest {
  inv_mast_uid: string | undefined;
  quantity: number;
  unit_of_measure?: string;
}

export enum UnitOfMeasureTags {
  Sales = 'sales',
  Purchasing = 'purchasing',
  Base = 'base',
}

export interface Quantity {
  amount: number;
  unitOfMeasure: ItemUnitOfMeasure;
}

export type Currency = Quantity;

export interface CustomerPriceContract {
  price: {
    unitOfMeasure: ItemUnitOfMeasure;
    min: Currency | null;
    max: Currency | null;
  };
  messages: string[] | null;
}
export interface ItemUnitOfMeasure {
  symbol: string;
  name: string;
  size: number;
  tags: UnitOfMeasureTags[];
}

export interface MinMaxPricePolicy {
  minUnitPrice?: Quantity;
  maxUnitPrice?: Quantity;
  unitOfMeasure: ItemUnitOfMeasure;
}

export interface ItemAvailability {
  locationId: string;
  qtyOnHand: Quantity;
  qtyAllocated: Quantity;
  qtyBackordered: Quantity;
  qtyInProcess: Quantity;
  qtyInTransit: Quantity;
  movingAverageCost: Currency;
  standardCost: Currency;
}

export interface ItemPriceInfo {
  unitOfMeasure: ItemUnitOfMeasure;
  priceDecimalPrecision: number;
  quantityDecimalPrecision: number;
  systemCalculatedPrices: {
    price: Currency | null;
    name: string | null;
    lineNo: string | null;
    qtyLimit: number | null;
    otherCost?: number | null;
  }[];
}

export interface ItemPriceInfoExtra extends ItemPriceInfo {
  availability: InventoryInfo[];
  cost: ItemCostInfo | null;
}

export interface ItemCostInfo {
  locationId: string;
  invMastUid: string;
  unitCost: Currency | null;
  movingAverageUnitCost: Currency | null;
  standardCost: Currency | null;
  commissionCost: Currency | null;
  unitOfMeasure?: ItemUnitOfMeasure;
}

export type ContractPricePolicy = MinMaxPricePolicy;

export interface ItemContract {
  name: string;
  policies: ContractPricePolicy[];
  messages: string[];
}

export interface InventoryInfo {
  locationId: string;
  qtyInProcess: Quantity;
  qtyInTransit: Quantity;
  qtyAvailable: Quantity;
  dateLastUpdatedUtc: Date;
}

export interface ItemPriceResponseV4 {
  availability: ItemAvailability[];
  cost: ItemCostInfo;
  unitOfMeasure: ItemUnitOfMeasure;
  unitsOfMeasure: ItemUnitOfMeasure[];
  priceDecimalPrecision: number;
  quantityDecimalPrecision: number;
  systemCalculatedPrices: {
    price: Currency | null;
    name: string | null;
    lineNo: string | null;
    qtyLimit: number | null;
  }[];
  library: {
    id: string;
    description: string;
  } | null;
}

export type ItemPriceResults = { results: ItemPriceResponseV4; error?: undefined } | { error: string };

export interface ItemCostResponseV4 {
  unitCost: Quantity;
  unitOfMeasure: ItemUnitOfMeasure;
}

export interface QuoteLineItemP21PriceInfo {
  baseUnit?: ItemUnitOfMeasure;
  defaultUnit?: ItemUnitOfMeasure;
  unitOfMeasureOptions?: ItemUnitOfMeasure[];
  current?: ItemPriceInfo;
  contract?: ItemContract | null;
  cost?: ItemCostInfo;
  library?: {
    id: string;
    description: string;
  } | null;
  error?: undefined;
}

export interface OrderContact {
  firstName: string;
  lastName: string;
  emailAddress: string;
  addressId: string;
}

export interface OrderCustomer {
  customerName: string;
  creditStatus: string;
  billToContactId: null;
  salesrepId: string;
}

export interface OrderLine {
  oeLineUid: string;
  parentOeLineUid: string;
  unitPrice: number;
  unitSize: number;
  qtyOrdered: number;
  qtyInvoiced: number;
  qtyStaged: number;
  qtyAllocated: number;
  qtyOnPickTickets: number;
  qtyCanceled: number;
  unitOfMeasure: string;
  invMastUid: string;
  itemId: string;
  extendedDesc: string;
  complete: string;
  manualPriceOveride: string;
  orderNo: string;
  tenantId: string;
  salesCost: number;
  commissionCost: number;
  shipLocId: string;
  sourceLocId: string;
  productGroupId: string;
  pricingUnitSize: number;
  pricingUnit: string;
  disposition: null;
  lotBill: string;
  extendedPrice: number;
  cancelFlag: string;
  deleteFlag: string;
  item: OrderItem;
  qtyPicked: number;
  unitCost: number;
  qtyOpen: number;
  totalAmount: number;
  totalGrossMargin: number;
}

export interface OrderItem {
  defaultSellingUnit: string;
  itemDesc: string;
  itemId: string;
}

export interface OrderLocation {
  locationName: string;
  locationId: string;
}

export interface OrderAddress {
  centralFaxNumber: string | null;
  centralPhoneNumber: string | null;
  corpAddressId: string | null;
  emailAddress: string | null;
  name: string | null;
  physAddress1: string;
  physAddress2: string | undefined;
  physAddress3: string | undefined;
  physCity: string;
  physCountry: string;
  physPostalCode: string;
  physState: string;
  url: string | null;
}

export interface QuoteLine {
  oeLineUid: string;
  parentOeLineUid: string;
  unitPrice: number;
  unitSize: number;
  qtyOrdered: number;
  qtyInvoiced: number;
  qtyStaged: number;
  qtyAllocated: number;
  qtyOnPickTickets: number;
  qtyCanceled: number;
  unitOfMeasure: string;
  invMastUid: string;
  itemId: string;
  extendedDesc: string;
  complete: string;
  manualPriceOveride: string;
  orderNo: string;
  tenantId: string;
  salesCost: number;
  commissionCost: number;
  shipLocId: string;
  sourceLocId: string;
  productGroupId: string;
  pricingUnitSize: number;
  pricingUnit: string;
  disposition: null;
  lotBill: string;
  extendedPrice: number;
  cancelFlag: string;
  deleteFlag: string;
  item: OrderItem;
  qtyPicked: number;
  unitCost: number;
  qtyOpen: number;
  totalAmount: number;
  totalGrossMargin: number;
}

export interface TransferLine {
  transferNo: number;
  qtyToTransfer: number;
  qtyTransferred: number;
  qtyReceived: number;
  unitOfMeasure: string;
  unitSize: number;
  unitQuantity: number;
  invMastUid: string;
  lineNo: number;
  transferHdr: TransferHdr;
  item: TransferLineItem;
}

export interface TransferLineItem {
  itemId: string;
  itemDesc: string;
}

export interface TransferHdr {
  fromLocationId: string;
  toLocationId: string;
  receivedDate: null;
  shippedFlag: string;
  completeFlag: string;
  transferDate: string;
  carrierId: string;
  carrier: TransferHdrCarrier;
  fromLocation: LocationWithName;
  toLocation: LocationWithName;
}

export interface TransferHdrCarrier {
  name: string;
}

export interface LocationWithName {
  locationName: string;
}

export interface OrderShipment {
  carrierName: string | null;
  invoiceNo: string | null;
  location: LocationWithName;
  locationId: string;
  pickTicketNo: number;
  printDate: string;
  shipDate: string | null;
  shipmentType: string;
  trackingNo: string | null;
}

/** based on https://pxxi-chm.netlify.app/oe/disposition */
export const lineItemDispositionMap: Obj<string> = {
  B: 'Backorder',
  C: 'Cancel',
  D: 'Direct Ship',
  F: 'Future',
  H: 'Hold',
  M: 'Make',
  P: 'Production Order',
  S: 'Special Order',
  T: 'Transfer',
};

export enum CustomerRestrictionsOrderEntryAction {
  Error = 'E',
  Warning = 'W',
  None = 'N',
}

export enum CustomerRestrictionsValidationAction {
  NoChange = 'N',
  PlaceOnHold = 'H',
  CashOnDelivery = 'C',
  Validate = 'V',
}

export const customerRestrictionsValidationMessageMap = {
  [CustomerRestrictionsValidationAction.NoChange]: '',
  [CustomerRestrictionsValidationAction.PlaceOnHold]: 'Order will be placed on hold.',
  [CustomerRestrictionsValidationAction.CashOnDelivery]: '',
  [CustomerRestrictionsValidationAction.Validate]: '',
};
