import React from 'react';

import { css, cx } from '@emotion/css';
import { Descriptions as AntdDescriptions, DescriptionsProps } from 'antd';

export const Descriptions = ({ className, ...props }: DescriptionsProps) => (
  <AntdDescriptions
    className={cx(
      css`
        .ant-descriptions-view {
          border-radius: ${props.bordered ? '8px' : 'none'};
        }
      `,
      className,
    )}
    {...props}
  />
);
