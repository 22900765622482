import React from 'react';

import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { SidePane } from 'components/recipes/sidePane/SidePane';

import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

import { POSummary } from '../../types';
import { LineStatusCountsPanelContent } from './LineStatusCountsPanelContent';
import { POSummariesPanelContent } from './POSummariesPanelContent';
import { SupplierNotesPanelContent } from './SupplierNotesPanelContent';

export function POSummarySidePaneSidePane({
  lineStatusCounts,
  poSummaries,
  uniqueSuppliers,
}: {
  lineStatusCounts: Record<PurchaseTargetLineStatus, number>;
  poSummaries: POSummary[];
  uniqueSuppliers: string[];
}) {
  return (
    <SidePane
      title="Overview"
      settingKey={ViewSettingKey.PurchaseTargetPOSummarySidePane}
      panels={[
        {
          settingKey: 'poSummary',
          title: poSummaries.length === 1 ? 'PO Summary' : `${poSummaries.length} PO Summaries`,
          content: <POSummariesPanelContent targetSummaries={poSummaries} />,
        },
        {
          settingKey: 'inventoryStatus',
          title: 'Inventory Status',
          content: <LineStatusCountsPanelContent lineStatusCounts={lineStatusCounts} />,
        },
        {
          settingKey: 'supplierNotes',
          title: 'Supplier Notes',
          content: <SupplierNotesPanelContent uniqueSuppliers={uniqueSuppliers} />,
        },
      ]}
    />
  );
}
