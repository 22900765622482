import type { HTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

import { cx, css } from '@emotion/css';
import { theme } from 'theme';

import { SkeletonBar } from 'components/SkeletonBar';

export interface PropertyListItemProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  value: React.ReactNode;
  id?: string;
  icon?: React.ReactNode;
  isLoading?: boolean;
  iconAlign?: 'start' | 'center';
}

export const PropertyListItem = forwardRef<HTMLDivElement, PropertyListItemProps>(
  ({ label, value, id, icon: PropertyIcon, iconAlign = 'center', className, isLoading, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        css`
          display: flex;
          flex-direction: column;
        `,
        className,
      )}
      {...props}
    >
      {label && <PropertyLabel label={label} />}
      <div
        className={css`
          ${theme.typography.pMedium};
          color: ${theme.colors.neutral[900]};
          ${PropertyIcon ? 'display:flex;' : ''}
          gap: 6px;
          align-items: ${iconAlign === 'start' ? 'flex-start' : 'center'};
        `}
      >
        {PropertyIcon && (
          <div
            className={css`
              font-size: 16px;
              font-weight: bold;
              color: ${theme.colors.neutral[400]};
            `}
            color={theme.colors.neutral[400]}
          >
            {PropertyIcon}
          </div>
        )}
        {!isLoading ? (
          <div>
            {value}
            {id && (
              <span
                className={css`
                  color: ${theme.colors.neutral[500]};
                `}
              >
                {' #'}
                {id}
              </span>
            )}
          </div>
        ) : (
          <SkeletonBar size="large" />
        )}
      </div>
    </div>
  ),
);

export function PropertyLabel({ label, className }: { label: string; className?: string }) {
  return (
    <div
      className={cx(
        css`
          ${theme.typography.captionSpaced};
          color: ${theme.colors.neutral[500]};
          margin-bottom: 2px;
          text-transform: uppercase;
        `,
        className,
      )}
    >
      {label}
    </div>
  );
}
