import React, { useRef, useEffect } from 'react';

import { colors } from 'theme/colors';

export interface StatusCounts {
  total: number;
  pending: number;
  running: number;
  success: number;
  failed: number;
}

export function StepStatusProgressBar({
  statusCounts,
  width = 150,
  boxSize = 10,
}: {
  statusCounts: StatusCounts;
  width?: number;
  boxSize?: number;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const dpr = window.devicePixelRatio || 1;
    canvas.width = width * dpr;
    canvas.height = boxSize * dpr;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${boxSize}px`;
    ctx.scale(dpr, dpr);

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const totalBoxes = statusCounts.total;
      const boxWidth = width / totalBoxes;
      const boxHeight = boxSize;

      let currentX = 0;
      let drawnBoxes = 0;

      const drawBox = (x: number, y: number, color: string) => {
        ctx.fillStyle = color;
        // -0.25 is to show a little gap between step boxes
        ctx.fillRect(x, y, boxWidth - 0.25, boxHeight);
      };

      const drawBoxesForStatus = (count: number, color: string) => {
        for (let i = 0; i < count && drawnBoxes < totalBoxes; i++) {
          drawBox(currentX, 0, color);
          currentX += boxWidth;
          drawnBoxes++;
        }
      };

      drawBoxesForStatus(statusCounts.success, colors.success[500]);
      drawBoxesForStatus(statusCounts.failed, colors.danger[500]);
      drawBoxesForStatus(statusCounts.running, colors.info[500]);
      drawBoxesForStatus(statusCounts.pending, colors.neutral[200]);

      // Fill any remaining boxes with a neutral color
      while (drawnBoxes < totalBoxes) {
        drawBox(currentX, 0, '#e0e0e0');
        currentX += boxWidth;
        drawnBoxes++;
      }
    };

    draw();
  }, [boxSize, width, statusCounts]);

  return <canvas ref={canvasRef} />;
}
