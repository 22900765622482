import { CreatePurchaseOrderBody } from '@recurrency/core-api-schema/dist/purchaseOrders/createPurchaseOrder';
import { PurchaseOrderDetailsDTO } from '@recurrency/core-api-schema/dist/v2/purchaseOrders/index';

import { calcDiscountPercent } from 'pages/purchasing/utils';

import { roundTo2Decimals } from 'utils/formatting';
import { PurchaseOrderEntryFlowFormConfig } from 'utils/tenantConf/default';

import { PurchaseOrderEditHashState, PurchaseOrderLineItem } from 'types/hash-state';

export function getFilledLineItems(lineItems?: PurchaseOrderLineItem[]): PurchaseOrderLineItem[] {
  return (lineItems || []).filter((lineItem) => !!lineItem.item?.foreignId);
}

export function getNumLineItems(lineItems?: PurchaseOrderLineItem[]): number {
  return getFilledLineItems(lineItems).length;
}

export function getLineItemTrackProps(lineItems: PurchaseOrderLineItem[]) {
  return {
    numLineItems: getNumLineItems(lineItems),
    totalCost: getTotalPrice(lineItems),
  };
}

export function getTotalPrice(lineItems: PurchaseOrderLineItem[]): number {
  return roundTo2Decimals(
    lineItems.reduce((total: number, item) => (item.unitQuantity || 0) * (item.unitPrice || 0) + total, 0),
  );
}

export function validateQuantity(lineItem: PurchaseOrderLineItem): string | null {
  if (lineItem.item) {
    const { unitQuantity: quantity } = lineItem;
    if (typeof quantity === 'number' && Number.isFinite(quantity)) {
      if (quantity < 0) {
        return 'Quantity must be greater than 0';
      }
    } else {
      return 'Quantity is required';
    }
  }
  return null;
}

export function validatePricePerUOM(lineItem: PurchaseOrderLineItem): string | null {
  if (lineItem.item) {
    const { unitPrice } = lineItem;
    if (typeof unitPrice === 'number' && Number.isFinite(unitPrice)) {
      if (unitPrice < 0) {
        return 'Price must be greater or equal to 0';
      }
    }
    // P21 automatically fills in price if missing, so not a required field
  }
  return null;
}

export function validateLineItems(lineItems: PurchaseOrderLineItem[]): string[] {
  const errors: string[] = [];

  if (getNumLineItems(lineItems) === 0) {
    return ['At least one line item is required.'];
  }

  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i];
    const lineNum = i + 1;

    if (validateQuantity(lineItem) || validatePricePerUOM(lineItem)) {
      errors.push(`Line ${lineNum}: Please fill required fields.`);
    }
  }

  return errors;
}

export function createPurchaseOrderBodyFromHashState(
  docState: PurchaseOrderEditHashState,
  formConfig: PurchaseOrderEntryFlowFormConfig,
): CreatePurchaseOrderBody {
  // when function is called, data is already validated.
  // adding type defaults to appease typescript
  return {
    companyId: docState.company?.foreignId,
    locationId: docState.location?.foreignId || '',
    vendorId: docState.vendor?.foreignId,
    supplierId: docState.supplier?.foreignId || '',
    buyerId: docState.buyer?.foreignId,
    carrierId: docState.carrier?.foreignId,
    approved: docState.approved || false,
    dueDate: docState.dueDate,
    email: formConfig.email.defaultValue,
    lineItems: getFilledLineItems(docState.lineItems).map((lineItem) => ({
      itemId: lineItem.item?.foreignId || '',
      unitOfMeasure: lineItem.unitOfMeasure,
      unitOfMeasureId: lineItem.unitOfMeasureId,
      unitQuantity: lineItem.unitQuantity || 1,
      unitPrice:
        formConfig.poCostDiscount.active && lineItem.originalUnitPrice
          ? lineItem.originalUnitPrice
          : lineItem.unitPrice || 0,
      discountPercent:
        formConfig.poCostDiscount.active && lineItem.originalUnitPrice
          ? calcDiscountPercent(lineItem.originalUnitPrice, lineItem.unitPrice || 0)
          : undefined,
    })),
  };
}

export function purchaseOrderDetailToHashState(po: PurchaseOrderDetailsDTO): PurchaseOrderEditHashState {
  return {
    // PO details endpoint doesn't send company :(
    location:
      po.locationId && po.locationName
        ? {
            foreignId: po.locationId,
            name: po.locationName,
          }
        : undefined,
    vendor:
      po.vendorId && po.vendorName
        ? {
            foreignId: po.vendorId,
            name: po.vendorName,
          }
        : undefined,
    supplier:
      po.supplierId && po.supplierName
        ? {
            foreignId: po.supplierId,
            name: po.supplierName,
          }
        : undefined,
    carrier:
      po.carrierId && po.carrierName
        ? {
            foreignId: po.carrierId,
            name: po.carrierName,
          }
        : undefined,
    buyer:
      po.buyer && po.buyer.id
        ? {
            foreignId: po.buyer.id,
            name: po.buyer.name || ``,
          }
        : undefined,
    lineItems: po.lines?.map((line) => ({
      item: {
        foreignId: line.itemCode,
        name: line.itemName,
      },
      unitOfMeasure: line.unitOfMeasure,
      unitOfMeasureId: line.unitOfMeasureId,
      unitQuantity: line.quantityOrdered,
      unitPrice: line.unitPrice,
    })),
  };
}
