import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { NewPurchaseOrderButton } from 'components/recipes/NewPurchaseOrderButton';
import { PurchaseOrderLinesTable } from 'components/recipes/PurchaseOrderLinesTable';
import { PurchaseOrdersTable } from 'components/recipes/PurchaseOrdersTable';

import { useCoreApi } from 'hooks/useApi';

import { IdPathParams, useHashState, usePathParams } from 'utils/routes';

import { DetailsPageWithCompanyIdHashState } from 'types/hash-state';

export const VendorDetailsPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const [hashState] = useHashState<DetailsPageWithCompanyIdHashState>();

  const companyId = hashState?.companyId;
  const { data, isLoading, error } = useCoreApi(schemas.vendors.getVendor, {
    pathParams: { vendorId: id },
    queryParams: { companyId },
  });

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !data) {
    return <CenteredLoader />;
  }

  if (Object.keys(data).length === 0) {
    return <NoDataMessage />;
  }

  const { vendorId, vendorName } = data;

  return (
    <Container>
      <PageHeader
        title={vendorName}
        copyable
        entity={{ kind: 'Vendor', id: vendorId }}
        headerActions={<NewPurchaseOrderButton />}
      />
      <NavTabs
        tabs={[
          { header: 'Purchase Orders', content: <PurchaseOrdersTable vendorId={vendorId} companyId={companyId} /> },
          {
            header: 'Purchase Order Lines',
            content: <PurchaseOrderLinesTable vendorId={vendorId} companyId={companyId} />,
          },
        ]}
      />
    </Container>
  );
};
