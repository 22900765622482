import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig } = config.quoteOrderEntryFlow;
  quoteOrderFormConfig.terms.enableExport = true;
  quoteOrderFormConfig.contact.required = true;
  quoteOrderFormConfig.quoteValidUntilDays.defaultValue = 60;
  return config;
}
