import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { InvoiceDTO, InvoiceStatus } from '@recurrency/core-api-schema/dist/invoices/commonParams';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelect, AsyncSelectVariant } from 'components/AsyncSelect';
import { CustomerSelectOption, useCustomersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { PageHeader } from 'components/PageHeader';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';

import { showAsyncModal } from 'utils/asyncModal';
import { formatUSD, splitIfIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';

import { PayInvoicesModal } from './PayInvoicesModal';

export function OpenInvoicesPage() {
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerSelectOption>();

  // Don't use until multiple invoices can be paid at once
  // const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
  // const [selectedRows, setSelectedRows] = useState<InvoiceDTO[]>([]);

  const customersSelectProps = useCustomersSelectProps({});

  const columns: ColumnType<InvoiceDTO>[] = [
    {
      title: 'Customer',
      dataIndex: 'customerId',
      render: (_: any, invoice: InvoiceDTO) => (
        <Link to={routes.sales.customerDetails(invoice.customerId)}>{invoice.customerId}</Link>
      ),
    },
    {
      title: 'Order',
      render: (_: any, invoice: InvoiceDTO) =>
        invoice.orderId ? <Link to={routes.orders.orderDetails(invoice.orderId)}>{invoice.orderId}</Link> : '-',
    },
    {
      title: 'Invoice',
      dataIndex: 'invoiceId',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      render: (date) => date && new Date(date).toLocaleDateString(),
    },
    {
      title: 'Terms',
      dataIndex: 'terms',
    },
    {
      title: 'Remaining Amount',
      dataIndex: 'balanceDue',
      render: (balanceDue: number) => formatUSD(balanceDue, true),
    },
    {
      title: 'Payment Due Date',
      dataIndex: 'netDueDate',
      render: (date) => {
        const daysUntilDue = date && Math.floor((new Date(date).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
        const overdue = daysUntilDue && daysUntilDue < 0;
        return (
          <div
            className={css`
              display: flex;
              gap: 8px;
            `}
          >
            <StatusBadge
              label={overdue ? `${-daysUntilDue} Days Overdue` : `Due in ${daysUntilDue} days`}
              status={overdue ? BadgeStatus.Review : daysUntilDue <= 3 ? BadgeStatus.Incomplete : BadgeStatus.Open}
            />
          </div>
        );
      },
    },
    {
      title: 'Payment Due Date',
      dataIndex: 'netDueDate',
      render: (date) => date && new Date(date).toLocaleDateString(),
    },
    {
      title: 'Action',
      render: (_: any, invoice: InvoiceDTO) => (
        <AsyncButton
          size="small"
          onClick={async () => {
            await showAsyncModal(PayInvoicesModal, {
              customerId: invoice.customerId,
              companyId: invoice.companyId,
              invoices: [
                { id: invoice.invoiceId, amountRemaining: invoice.balanceDue, orderNo: invoice.orderId || '' },
              ],
            });
          }}
        >
          Make Payment
        </AsyncButton>
      ),
    },
  ];

  return (
    <Container>
      <PageHeader
        title="Open Invoices"
        // TODO don't use header action until multiple invoices can be paid at once
        // headerActions={
        //   <AsyncButton
        //     type="primary"
        //     disabled={!selectedInvoiceIds.length || selectedRows.some((row) => row.customerId !== selectedRows[0].customerId)}
        //     onClick={async () => {
        //       await showAsyncModal(PayInvoicesModal, {
        //         customerId: selectedRows[0].customerId,
        //         invoices: selectedRows.map((row) => ({ id: row.invoiceId, amountRemaining: row.balanceDue })),
        //       })
        //     }}
        //   >
        //     Make Payment
        //   </AsyncButton>
        // }
      />
      <FilterBarBox dividerLine>
        <AsyncSelect
          className={css`
            width: 350px;
          `}
          size="small"
          variant={AsyncSelectVariant.Filter}
          selectProps={customersSelectProps}
          entityPlural="customers"
          onSelect={(_, option) => setSelectedCustomer(option as CustomerSelectOption)}
          onClear={() => setSelectedCustomer(undefined)}
          allowClear
        />
      </FilterBarBox>
      <AsyncTable
        tableProps={useCoreApiTableProps({
          schema: schemas.invoices.getUnpaidInvoicesByCustomer,
          queryParams: {
            filter: {
              companyId: splitIfIdNameStr(selectedCustomer?.company)?.foreignId,
              customerId: splitIfIdNameStr(selectedCustomer?.value)?.foreignId,
              status: InvoiceStatus.Open,
            },
          },
        })}
        columns={columns}
        rowKey="invoiceId"
        // TODO don't use row selection until multiple invoices can be paid at once
        // rowSelection={{
        //   selectedRowKeys: selectedInvoiceIds,
        //   onChange: (newSelectedRowKeys: React.Key[], newSelectedRows: InvoiceDTO[]) => {
        //     setSelectedInvoiceIds(newSelectedRowKeys as string[]);
        //     setSelectedRows(newSelectedRows);
        //   },
        // }}
      />
    </Container>
  );
}
