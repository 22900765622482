import React, { useState } from 'react';

import { DownOutlined, MinusCircleOutlined, PlusOutlined, ShopOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ErpRole, RecurrencyRole } from '@recurrency/core-api-schema/dist/common/enums';
import {
  PostCreateTenantUserBodyParams,
  TenantUserErpRolePayload,
  TenantUserRecurrencyRolePayload,
} from '@recurrency/core-api-schema/dist/tenants/postCreateTenantUser';
import { PutUpdateTenantUserBodyParams } from '@recurrency/core-api-schema/dist/tenants/putUpdateTenantUser';
import { TenantListResultDTO } from '@recurrency/core-api-schema/dist/tenants/tenantListResultDTO';
import { UserWithTenantsDTO } from '@recurrency/core-api-schema/dist/users/common';
import { notification, Menu } from 'antd';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Dropdown } from 'components/Dropdown';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { Select } from 'components/Select';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';

const getDefaultErpRole = () => ({
  name: Object.values(ErpRole)[0],
  foreignId: '',
});
const getDefaultRecurrencyRole = () => ({
  name: Object.values(RecurrencyRole)[0],
});

export interface EditUserTenant {
  id: string;
  name: string;
}

interface EditUserDetailsModalProps {
  onClose: (reloadPageAfter: boolean) => void;
  currentOrSelectedTenant: EditUserTenant;
  currentErpRoles?: TenantUserErpRolePayload[];
  currentRecurrencyRoles?: TenantUserRecurrencyRolePayload[];
  /** if true, prevents user from changing the tenant in the modal */
  isTenantLocked: boolean;
  userData: UserWithTenantsDTO;
  tenantData?: TenantListResultDTO;
}

export const EditUserDetailsModal = ({
  onClose,
  currentOrSelectedTenant,
  currentErpRoles,
  currentRecurrencyRoles,
  isTenantLocked,
  userData,
  tenantData,
}: EditUserDetailsModalProps) => {
  const [tenant, setTenant] = useState(currentOrSelectedTenant);

  const [tenantQuery, setTenantQuery] = useState('');
  const [erpRoles, setErpRoles] = useState(currentErpRoles ?? [getDefaultErpRole()]);
  const [recurrencyRoles, setRecurrencyRoles] = useState<TenantUserRecurrencyRolePayload[]>(
    currentRecurrencyRoles ?? [],
  );
  const [companySearchQuery, setCompanySearchQuery] = useState('');

  const currentAllowedCompanyIds =
    userData.tenants?.find((t) => t.id === tenant.id)?.tenantUser.allowedCompanyIds || [];
  const [allowedCompanyIds, setAllowedCompanyIds] = useState<string[]>(currentAllowedCompanyIds);

  const tenantErpType = (tenantData?.items || []).find((t) => t.id === tenant.id)?.erpType || '';
  const { data: companyData, isLoading } = useCoreApi(schemas.companies.getCompanies, {
    pathParams: { tenantId: tenant.id, erpType: tenantErpType },
    queryParams: { allCompanies: true },
  });

  const companySelectProps = {
    isLoading,
    options: (companyData?.items || []).map((record) => ({
      label: `${record.companyId}: ${record.companyName}`,
      value: record.companyId,
    })),
    searchQuery: companySearchQuery,
    setSearchQuery: setCompanySearchQuery,
  };

  const onSubmit = async () => {
    try {
      if (!tenant.id) {
        throw new Error('No tenant ID found');
      }

      if (userData.tenants?.some((t: { id: string }) => t.id === tenant.id)) {
        const request: PutUpdateTenantUserBodyParams = {
          isActive: true,
          roles: erpRoles,
          recurrencyRoles,
          allowedCompanyIds,
        };
        await coreApiFetch(schemas.tenants.putUpdateTenantUser, {
          bodyParams: request,
          pathParams: { tenantId: tenant.id, userId: userData.id },
        });
        notification.success({ message: 'User tenant updated.' });
      } else {
        const request: PostCreateTenantUserBodyParams = {
          userId: userData.id,
          isActive: true,
          roles: erpRoles,
          recurrencyRoles,
        };
        await coreApiFetch(schemas.tenants.postCreateTenantUser, {
          bodyParams: request,
          pathParams: { tenantId: tenant.id },
        });
        notification.success({ message: 'User tenant added.' });
      }

      onClose(true);
    } catch (err) {
      captureAndShowError(err, `Error while updating user`);
    }
  };

  const updateErpRoles = ({ index, name, foreignId }: { index: number; name?: ErpRole; foreignId?: string }) => {
    const existingRoles = [...erpRoles];
    if (name) {
      existingRoles[index].name = name;
    }
    if (foreignId || foreignId === '') {
      existingRoles[index].foreignId = foreignId;
    }
    setErpRoles(existingRoles);
  };

  const updateRecurrencyRoles = ({ index, name }: { index: number; name?: RecurrencyRole }) => {
    const existingRoles = [...recurrencyRoles];
    if (name) {
      existingRoles[index].name = name;
    }
    setRecurrencyRoles(existingRoles);
  };

  const addErpRole = () => {
    const existingRoles = [...erpRoles];
    existingRoles.push(getDefaultErpRole());
    setErpRoles(existingRoles);
  };

  const addRecurrencyRole = () => {
    const existingRoles = [...recurrencyRoles];
    existingRoles.push(getDefaultRecurrencyRole());
    setRecurrencyRoles(existingRoles);
  };

  const removeErpRole = (index: number) => {
    if (erpRoles.length > 1) {
      const existingRoles = [...erpRoles];
      existingRoles.splice(index, 1);
      setErpRoles(existingRoles);
    } else {
      notification.error({ message: 'User must have at least one erp role per tenant' });
    }
  };

  const removeRecurrencyRole = (index: number) => {
    const existingRoles = [...recurrencyRoles];
    existingRoles.splice(index, 1);
    setRecurrencyRoles(existingRoles);
  };

  const SectionSpacing = () => (
    <div
      className={css`
        margin-top: 20px;
      `}
    />
  );

  return (
    <Modal
      visible
      title="Manage Tenant/User Connection"
      onCancel={() => onClose(false)}
      footer={
        <div>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <AsyncButton type="primary" onClick={onSubmit} disabled={!tenant.id}>
            Save Changes
          </AsyncButton>
        </div>
      }
      centered
    >
      <div>
        <div>Add user to</div>
        {isTenantLocked ? (
          <div>
            <Input disabled value={tenant.name} />
          </div>
        ) : (
          <Select
            style={{ display: 'block' }}
            placeholder="Search tenants"
            onSelect={(value, option) => {
              setTenantQuery('');
              setTenant({
                name: value,
                id: option.id,
              });
            }}
            showSearch
            searchValue={tenantQuery}
            onSearch={(value) => {
              setTenantQuery(value ? value.toLowerCase() : '');
            }}
            options={
              tenantData
                ? tenantData.items
                    .map((record) => ({
                      id: record.id,
                      value: record.name,
                    }))
                    .filter((record: { value: string }) => record.value.toLowerCase().includes(tenantQuery))
                : []
            }
            defaultValue={tenant.name}
          />
        )}
        <SectionSpacing />
        <div>ERP role(s)</div>
        {erpRoles.map((roleObj, index) => (
          <div
            className={css`
              margin: 4px;
            `}
            key={roleObj.name}
          >
            <Button danger onClick={() => removeErpRole(index)}>
              <MinusCircleOutlined />
            </Button>
            <Dropdown
              overlay={
                // @ts-expect-error (converted from ts-ignore)
                <Menu onClick={({ key: name }) => updateErpRoles({ index, name })}>
                  {Object.values(ErpRole).map((roleString: string) => (
                    <Menu.Item key={roleString}>{roleString}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button>
                {roleObj.name} <DownOutlined />
              </Button>
            </Dropdown>
            <Input
              style={{ width: 'auto' }}
              onChange={(s) => updateErpRoles({ index, foreignId: s.target.value })}
              placeholder="Foreign ID"
              defaultValue={roleObj.foreignId}
            />
          </div>
        ))}
        <Button onClick={addErpRole}>
          <PlusOutlined /> Add ERP Role
        </Button>
        <SectionSpacing />
        <div>Recurrency role(s)</div>
        {recurrencyRoles.map((roleObj, index) => (
          <div
            className={css`
              margin: 4px;
            `}
            key={roleObj.name}
          >
            <Button danger onClick={() => removeRecurrencyRole(index)}>
              <MinusCircleOutlined />
            </Button>
            <Dropdown
              overlay={
                // @ts-expect-error (converted from ts-ignore)
                <Menu onClick={({ key: name }) => updateRecurrencyRoles({ index, name })}>
                  {Object.values(RecurrencyRole).map((roleString: string) => (
                    <Menu.Item key={roleString}>{roleString}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button>
                {roleObj.name} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        ))}
        <Button onClick={addRecurrencyRole}>
          <PlusOutlined /> Add Recurrency Role
        </Button>
        <SectionSpacing />
        <div>Allowed Company IDs</div>
        <div
          className={css`
            margin: 4px;
          `}
        >
          <AsyncMultiSelect
            mode="multiple"
            queryPlaceholder="Search companies"
            selectedValues={
              allowedCompanyIds.length > 0 ? allowedCompanyIds : companySelectProps.options.map((o) => o.value)
            }
            onSelectedValuesChange={(values) => setAllowedCompanyIds(values)}
            selectProps={companySelectProps}
            disableValuesTooltip
            triggerButton={
              <Button size="small">
                <ShopOutlined />
                {allowedCompanyIds.length > 0 ? allowedCompanyIds.length : companySelectProps.options.length} Companies
                Selected
              </Button>
            }
          />
        </div>
      </div>
    </Modal>
  );
};
