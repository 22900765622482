import { TenantSettings } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { CompanyDTO } from '@recurrency/core-api-schema/dist/companies/getCompanies';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { TenantUserErpRoleDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';
import { UserDTO } from '@recurrency/core-api-schema/dist/users/common';

import { TypesenseSearchClient } from 'utils/search/types';
import { StateStore, useStateStore } from 'utils/stateStore';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

export interface ExtendedUser extends UserDTO {
  fullName: string;
  isRecurrencyAdmin: boolean;
  isRecurrencyInternalUser: boolean;
}

export interface ExtendedRole extends TenantUserErpRoleDTO {
  // multiple foreignIds are used for the manager role who see data across multiple rep codes
  foreignIds: string[];
}

export interface GlobalAppState {
  sessionId: string;
  activeUser: ExtendedUser;
  activeTenant: TenantDTO;
  activeErpRole: ExtendedRole;
  userAllowedCompanies: CompanyDTO[];
  /**
   * companies allowed for the user, (or a partial selection of allowed companies).
   * if null, all companies are allowed
   * otherwise it will always be an non-empty array.
   */
  activeCompanyIds: string[] | null;
  /** Settings for the currently active tenant. Partial because no tenant will have all settings for all ERPs, just a subset */
  activeTenantSettings: Partial<TenantSettings> | undefined;
  /** table columns and sidepane panels visible for active tenant user */
  userViewVisibleKeys: Record<ViewSettingKey, string[]> | undefined;
  accessToken: string;
  tenants: TenantDTO[];
  roles: ExtendedRole[];
  typesenseSearchClient: TypesenseSearchClient;
  logout: () => void;
  loginWithRedirect: () => void;
}

/**
 * singleton global app state store.
 * the store values are assigned by Auth0AppStateLoader
 */
export const globalAppStore = new StateStore<GlobalAppState>({} as GlobalAppState);

export function getGlobalAppState() {
  // Non-react getter
  return globalAppStore.state;
}

export function useGlobalApp() {
  // React hook getter
  return useStateStore(globalAppStore);
}
