import { Table as AntdTable } from 'antd';
import { mediaQueries } from 'theme/breakpoints';
import { colors } from 'theme/colors';

import { styled } from 'utils/styledComponent';

export const TableWrapper = styled('div')`
  width: 100%;
  overflow-x: auto;
  overflow-y; auto;

  ${mediaQueries.sm} {
    width: auto;
  }
`;

export const Table = styled(AntdTable)`
  .ant-table.ant-table-small {
    font-size: 10px;
    ${mediaQueries.sm} {
      font-size: 14px;
    }

    .ant-table-title {
      padding: 0;
    }

    .ant-table-thead .ant-table-column-sorters,
    .ant-table-cell {
      padding: 2px 2px;
      ${mediaQueries.sm} {
        padding: 8px 8px;
      }

      &:first-child {
        padding-left: 8px;
      }
      &:last-child {
        padding-right: 8px;
      }
    }
  }

  tr.ant-table-row {
    &.focusable-row {
      cursor: pointer;

      &.focused-row > td {
        background-color: ${colors.primary[200]};
      }
    }
  }

  .ant-pagination {
    justify-content: center;
    .ant-pagination-item,
    .ant-select-selector,
    .ant-pagination-item-link {
      border-radius: 8px;
    }
  }
`;
