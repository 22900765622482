import React, { useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Form, Select } from 'antd';

import { CenteredError, CenteredLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { SelectOption } from 'components/Select';

import { useCoreApi } from 'hooks/useApi';

import { QuoteLineItemP21 } from 'types/hash-state';

interface SelectLineItemCustomerPartIdModalProps {
  lineItem: QuoteLineItemP21;
  companyId: string;
  customerId: string;
  onClose: (selectedCustomerPartId?: string | undefined) => void;
}

export function ItemCustomerPartIdModal({
  lineItem,
  companyId,
  customerId,
  onClose,
}: SelectLineItemCustomerPartIdModalProps): JSX.Element {
  const [selectedCustomerPartId, setSelectedCustomerPartId] = useState<string | undefined>(lineItem.customerPartId);
  const {
    data: customerPartIdsData,
    isLoading: customerPartIdsIsLoading,
    error: customerPartIdsError,
  } = useCoreApi(schemas.items.getItemCustomerPartIds, {
    pathParams: { itemId: lineItem.foreignId },
    queryParams: { companyId, customerId },
  });
  const customerPartIdsSelectProps: SelectOption[] = (customerPartIdsData?.items || []).map((item) => ({
    value: item.customerPartId,
    label: item.customerPartId,
  }));

  if (!selectedCustomerPartId && customerPartIdsSelectProps && customerPartIdsSelectProps.length > 0) {
    setSelectedCustomerPartId(customerPartIdsSelectProps[0].value);
  }

  if (customerPartIdsError) {
    return <CenteredError error={customerPartIdsError} />;
  }
  if (!customerPartIdsData || customerPartIdsIsLoading) {
    return <CenteredLoader />;
  }
  return (
    <Modal
      visible
      title={`Customer Part No. for Item #${lineItem.foreignId}`}
      width={550}
      onCancel={() => onClose()}
      onOk={() => onClose(selectedCustomerPartId)}
      closable={false}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 14px;
        `}
      />
      {selectedCustomerPartId ? (
        <>
          <div>Customer Part No:</div>
          <Form.Item
            name="customerPartId"
            className={css`
              margin: 0;
            `}
          >
            <Select
              options={customerPartIdsSelectProps}
              defaultValue={selectedCustomerPartId}
              onChange={(value) => setSelectedCustomerPartId(value)}
            />
          </Form.Item>
        </>
      ) : (
        <div
          className={css`
            margin-bottom: 20px;
          `}
        >
          No available customer part numbers for Item #{lineItem.foreignId} Customer #{customerId}
        </div>
      )}
    </Modal>
  );
}
