import React from 'react';

import { useHistory } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { breakpoints } from 'theme/breakpoints';
import { colors } from 'theme/colors';

import { Button } from 'components/Button';
import { PageHeading, Typography } from 'components/Typography';

export type PageHeaderProps = {
  title: React.ReactNode;
  entity?: {
    kind: string;
    id?: string;
    badge?: React.ReactNode;
  };
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  copyable?: boolean;
  headerActions?: React.ReactNode;
  onBack?: () => void;
};

export function PageHeader({ title, subtitle, description, copyable, entity, headerActions, onBack }: PageHeaderProps) {
  const history = useHistory();
  if (entity && !onBack) onBack = () => history.goBack();

  const backButton = onBack && (
    <Button
      size="small"
      onClick={onBack}
      className={css`
        border: none;
        padding: 0;
      `}
    >
      <ArrowLeftOutlined />
    </Button>
  );

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        margin: 24px 0;

        ${breakpoints.mobile} {
          flex-direction: column;
        }
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}
      >
        {!entity && backButton}
        <div
          className={css`
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          {entity && (
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 8px;
              `}
            >
              {backButton}
              <Typography style={{ color: colors.neutral[700] }}>
                {entity.kind}
                {entity.id && (
                  <>
                    {' #'}
                    <Typography copyable={copyable} style={{ display: 'inline-block' }}>
                      {entity.id}
                    </Typography>
                  </>
                )}
              </Typography>
              {entity.badge}
            </div>
          )}
          <PageHeading copyable={copyable}>{title}</PageHeading>
          {subtitle && (
            <Typography type="small" style={{ color: colors.neutral[400] }}>
              {subtitle}
            </Typography>
          )}
          {!!description && <>{description}</>}
        </div>
      </div>
      <div
        className={css`
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;
        `}
      >
        {headerActions}
      </div>
    </div>
  );
}
