import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { UserWithTenantsDTO } from '@recurrency/core-api-schema/dist/users/common';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';

import { Button } from 'components/Button';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { Input } from 'components/Input';
import { Table } from 'components/Table';

import { truthy } from 'utils/boolean';
import { routes } from 'utils/routes';
import { sortableNumberColumn, sortableStringColumn } from 'utils/tables';

export interface UserTenantsTableProps {
  user: UserWithTenantsDTO;
  onAddToTenantClick: () => void;
  onEditClick: (tenant: TenantDTO) => void;
  onRemoveClick: (tennantId: string) => void;
}

export function UserTenantsTable({ user, onAddToTenantClick, onEditClick, onRemoveClick }: UserTenantsTableProps) {
  const [searchQuery, setSearchQuery] = useState('');
  let tableRowsData: TenantDTO[] = Object.values(user?.tenants || []);
  tableRowsData = fuzzyFilter(tableRowsData, searchQuery, { fields: ['name', 'id'] }).map(({ item }) => item);

  const columns: (ColumnType<TenantDTO> | null)[] = [
    sortableNumberColumn({
      title: 'Tenant ID',
      dataIndex: 'id',
      render: (id: string) => <Link to={routes.internal.tenantDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'name',
    }),
    {
      title: 'ERP Role(s)',
      dataIndex: ['tenantUser', 'roles'],
      render: (erpRoles: TenantDTO['tenantUser']['roles']) =>
        erpRoles.map((role) => `${role.name || '(no name)'}:${role.foreignId || '(no foreignId)'}`).join(', '),
    },
    {
      title: 'Recurrency Role(s)',
      dataIndex: ['tenantUser', 'recurrencyRoles'],
      render: (recurrencyRoles: TenantDTO['tenantUser']['recurrencyRoles']) =>
        recurrencyRoles.map((role) => `${role.name || '(no name)'}`).join(', '),
    },
    {
      title: 'Allowed Companies',
      dataIndex: ['tenantUser', 'allowedCompanyIds'],
      render: (allowedCompanyIds: TenantDTO['tenantUser']['allowedCompanyIds']) =>
        (allowedCompanyIds || []).length > 0 ? (allowedCompanyIds || []).join(', ') : 'All companies',
    },
    {
      align: 'right',
      render: (record: TenantDTO) => (
        <div
          className={css`
            display: flex;
            gap: 8px 8px;
          `}
        >
          <Button onClick={() => onEditClick(record)}>
            <EditOutlined /> Edit
          </Button>
          <Button danger onClick={() => onRemoveClick(record.id)}>
            <MinusCircleOutlined /> Disconnect
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <FilterBarBox>
        <Input
          placeholder="Search Tenants"
          onChange={(ev) => setSearchQuery(ev.target.value)}
          style={{ maxWidth: '300px' }}
        />
        <FlexSpacer />
        <Button type="primary" onClick={onAddToTenantClick}>
          <PlusOutlined /> Add to Tenant
        </Button>
      </FilterBarBox>
      <Table columns={columns.filter(truthy)} data={tableRowsData} rowKey="id" />
    </>
  );
}
