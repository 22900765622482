import { ReactNode, useRef } from 'react';

import ReactDOM from 'react-dom';

import { zIndices } from 'theme/zIndices';

export enum PortalId {
  Tooltip = 'portal_tooltip',
  Modal = 'portal_modal',
}

export interface PortalProps {
  portalId: PortalId;
  children: ReactNode;
}

export const Portal = ({ children, portalId }: PortalProps) => {
  const containerRef = useRef<HTMLElement | null>(null);
  // use existing portal container from dom if it exists
  if (!containerRef.current) {
    containerRef.current = document.getElementById(portalId);
  }

  // create portal container element if it doesn't exist
  if (!containerRef.current) {
    containerRef.current = document.createElement('div');
    containerRef.current.id = portalId;
    containerRef.current.style.zIndex = String(zIndices.portal);
    document.body.appendChild(containerRef.current);
  }

  return ReactDOM.createPortal(children, containerRef.current);
};
