import React from 'react';

import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';

import { truthy } from 'utils/boolean';
import { formatUSD, pluralize } from 'utils/formatting';

import { OrderLineItemSAPB1 } from 'types/hash-state';

export function PriceAndCostProperties({ lineItem }: { lineItem: OrderLineItemSAPB1 }) {
  const props: LabelValueProperty[] = [
    {
      label: `Price per ${lineItem.unitOfMeasure}`,
      value: formatUSD(lineItem.unitPrice, true),
    },
    {
      label: `Cost per ${lineItem.unitOfMeasure}`,
      value: formatUSD(lineItem.unitCost, true),
    },
    Number.isFinite(lineItem.lotsAvgUnitCost)
      ? {
          label: `${pluralize(lineItem.lotsSelected?.length || 0, 'Lot', 'Lots')} Cost per ${lineItem.unitOfMeasure}`,
          value: formatUSD(lineItem.lotsAvgUnitCost, true),
        }
      : null,
  ].filter(truthy);
  return <LabelValueProperties properties={props} />;
}
