import React from 'react';

import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { upperNavModules, lowerNavModules, filterNav } from '../navModulesData';
import { NavModule } from '../types';
import * as Styled from './SecondaryNavigation.style';

export interface SecondaryNavigationProps {
  isDesktopView: boolean;
  onModuleSelect: (navModule: NavModule) => void;
  curModuleSlug: string | undefined;
  isHamburgerOpen: boolean;
}

export const SecondaryNavigation = ({
  isDesktopView,
  onModuleSelect,
  curModuleSlug: selectedModuleSlug,
  isHamburgerOpen,
}: SecondaryNavigationProps) => {
  const { activeTenant, activeUser, activeErpRole } = useGlobalApp();

  const upperNavModulesToRender = filterNav(upperNavModules, activeTenant, activeUser, activeErpRole);
  const lowerNavModulesToRender = filterNav(lowerNavModules, activeTenant, activeUser, activeErpRole);

  const renderMenuItem = (navModule: NavModule) => {
    const { slug: moduleSlug, label, selectedIcon, unselectedIcon } = navModule;
    const isSelected = selectedModuleSlug === moduleSlug;

    const itemContent = (
      <Styled.MenuItem
        className={`fs-nav-module-${moduleSlug}`}
        selected={isSelected}
        href={navModule.routeFn?.() || navModule.subModules[0]?.routeFn() || ''}
        onClick={(ev) => {
          // allow ctrl/cmd+click to open in new tab
          if (!ev.ctrlKey && !ev.metaKey) {
            ev.preventDefault();
            onModuleSelect(navModule);
          }
        }}
      >
        <Styled.MenuItemChild>
          <Styled.Icon isDesktopView={isDesktopView}>{isSelected ? selectedIcon : unselectedIcon}</Styled.Icon>
          {!isDesktopView && <Styled.HamburgerTitle>{label}</Styled.HamburgerTitle>}
        </Styled.MenuItemChild>
      </Styled.MenuItem>
    );

    return (
      <React.Fragment key={moduleSlug}>
        {isDesktopView ? (
          <Tooltip slow title={label} placement="right">
            {itemContent}
          </Tooltip>
        ) : (
          itemContent
        )}
      </React.Fragment>
    );
  };

  if (isDesktopView || isHamburgerOpen) {
    return (
      <Styled.Container isDesktopView={isDesktopView} data-test-id="secondaryNavContainer">
        <Styled.Content>
          <Styled.MenuContainer>{upperNavModulesToRender.map(renderMenuItem)}</Styled.MenuContainer>
          <Styled.MenuContainer>{lowerNavModulesToRender.map(renderMenuItem)}</Styled.MenuContainer>
        </Styled.Content>
      </Styled.Container>
    );
  }

  return null;
};
