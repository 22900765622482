import { CSSProperties } from 'react';

import { AsyncJobName, AsyncJobStepName } from '@recurrency/core-api-schema/dist/asyncJobs/common';

import { ISODateStr } from 'types/hash-state';

export function calcDurationMsFromDates(startedAt: ISODateStr | undefined, finishedAt: ISODateStr | undefined) {
  if (!startedAt || !finishedAt) return 0;
  const start = new Date(startedAt);
  const end = new Date(finishedAt);
  return end.getTime() - start.getTime();
}

export function formatJobName(name: AsyncJobName) {
  return name === AsyncJobName.UpdateItemLocationMinMaxes ? 'Export Min/Maxes' : name;
}

export function formatJobStepName(name: AsyncJobStepName) {
  return name === AsyncJobStepName.ExportERPMinMaxes ? 'Export Min/Maxes batch' : name;
}

export const iconStyle: CSSProperties = {
  fontSize: '22px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};
