import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { VesselLineDTO } from '@recurrency/core-api-schema/dist/vesselLines/getVesselLines';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelect, AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { FilterBarBox } from 'components/FilterBarBox';

import { truthy } from 'utils/boolean';
import {
  getSearatesTrackingUrl,
  joinTruthy,
  roundTo1Decimal,
  roundTo2Decimals,
  splitIdNameStr,
} from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableIdColumn, sortableDateColumn, sortableNumberColumn, sortableStringColumn } from 'utils/tables';

export const VesselLinesTable = ({ itemUid, locationId }: { itemUid?: string; locationId?: string }) => {
  const tableColumns: ColumnType<VesselLineDTO>[] = [
    sortableIdColumn({
      title: 'PO No',
      dataIndex: 'purchaseOrderId',
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
      sorter: true,
    }),
    !locationId
      ? sortableIdColumn({
          title: 'Location',
          dataIndex: 'locationId',
          render: (_, record: VesselLineDTO) =>
            record.locationId ? `${record.locationId}: ${record.locationName}` : '-',
          sorter: true,
        })
      : null,
    sortableIdColumn({
      title: 'Vessel Receipt No',
      dataIndex: 'vesselReceiptId',
      sorter: true,
    }),
    sortableStringColumn({
      title: 'Vessel Name',
      dataIndex: 'vesselName',
      sorter: true,
    }),
    sortableStringColumn({
      title: 'Container Name',
      dataIndex: 'containerName',
      render: (containerName: string) => (
        <a href={getSearatesTrackingUrl(containerName)} target="_blank" rel="noreferrer">
          {containerName}
        </a>
      ),
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Qty in Container',
      dataIndex: 'quantityInContainer',
      render: (quantityInContainer: number) => roundTo1Decimal(quantityInContainer),
      sorter: true,
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Unit Size',
      dataIndex: 'unitSize',
      render: (unitSize: number) => roundTo2Decimals(unitSize),
      sorter: true,
    }),
    sortableStringColumn({
      title: 'Port of Loading, Country',
      dataIndex: 'loadingPort',
      render: (_, record: VesselLineDTO) =>
        record.loadingPort || record.loadingCountry
          ? joinTruthy([record.loadingPort, record.loadingCountry], ',')
          : '-',
      sorter: false,
    }),
    sortableDateColumn({
      title: 'Departure Date',
      dataIndex: 'departureDate',
      sorter: true,
    }),
    sortableStringColumn({
      title: 'Port of Discharge, Country',
      dataIndex: 'dischargePort',
      render: (_, record: VesselLineDTO) =>
        record.dischargePort || record.dischargeCountry
          ? joinTruthy([record.dischargePort, record.dischargePort], ',')
          : '-',
      sorter: false,
    }),
    sortableDateColumn({
      title: 'Est Date of Arrival',
      dataIndex: 'arrivalDate',
      sorter: true,
      defaultSortOrder: 'descend',
    }),
  ].filter(truthy);

  const locationsSelectProps = useLocationsSelectProps({});

  const [filteredLocationId, setFilteredLocationId] = useState<string | undefined>(locationId);
  const tableProps = useCoreApiTableProps({
    schema: schemas.vesselLines.getVesselLines,
    queryParams: {
      filter: {
        itemUid,
        locationId: filteredLocationId,
      },
    },
  });

  const { setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredLocationId, setPage]);

  return (
    <>
      <FilterBarBox>
        {locationId && (
          <AsyncSelect
            className={tableFilterSelectClassName}
            selectProps={locationsSelectProps}
            entityPlural="locations"
            variant={AsyncSelectVariant.Filter}
            onSelect={(_, option) => setFilteredLocationId(splitIdNameStr(option.value).foreignId)}
            onClear={() => setFilteredLocationId(undefined)}
            allowClear
            size="small"
          />
        )}
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={tableColumns} />
    </>
  );
};
