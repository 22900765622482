import React from 'react';

import { useLocation } from 'react-router';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

import { Alert } from 'components/Alert';
import { Container } from 'components/Container';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowFreeProduct } from 'utils/roleAndTenant';
import { parsePath, routePaths } from 'utils/routes';

import { UpgradeButtons } from './UpgradeButtons';

export const routePathMessages = {
  [routePaths.demandPlanning.dashboard]:
    'Upgrade to the full version of Recurrency to act on inventory insights with intelligent forecasts, smart lead times, and recommended mins and maxes.',
  [routePaths.demandPlanning.reporting]:
    'Upgrade to the full version of Recurrency to act on inventory insights with intelligent forecasts, smart lead times, and recommended mins and maxes.',
  [routePaths.sales.dashboard]:
    'Upgrade to the full version of Recurrency to act on sales insights with intelligent upsell recommendations, automatic reorder suggestions, and rapid entry workflows.',
  [routePaths.reporting.explorer]:
    'Upgrade to the full version of Recurrency to put reporting insights into action with sales workflows and management tools.',
  [routePaths.reporting.reports]:
    'Upgrade to the full version of Recurrency to put reporting insights into action with sales workflows and management tools.',
  [routePaths.reporting.report]:
    'Upgrade to the full version of Recurrency to put reporting insights into action with sales workflows and management tools.',
};

const DEFAULT_MESSAGE =
  'Upgrade to the full version of Recurrency to revolutionize your demand planning, purchasing, and sales processes today.';

export const UpgradeBanner = () => {
  const location = useLocation();
  const { activeTenant } = useGlobalApp();

  const path = location.pathname;
  const parsedPath = parsePath(path);

  const isInFreeTrial = shouldShowFreeProduct(activeTenant);

  const message = routePathMessages[parsedPath?.routePath] || DEFAULT_MESSAGE;

  if (isInFreeTrial) {
    return (
      <Container>
        <Alert
          className={css`
            margin-top: 24px;
          `}
          icon={<ExclamationCircleOutlined />}
          message={message}
          action={<UpgradeButtons simple />}
          type="info"
          banner
          closable
        />
      </Container>
    );
  }
  return null;
};
