import React, { useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Checkbox, Menu, notification } from 'antd';

import { Modal } from 'components/Modal';
import { Portal, PortalId } from 'components/Portal';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { joinAnd } from 'utils/formatting';

export interface EmailPDFMenuItemProps {
  selectedQuoteId: string;
}

export function EmailDraftQuoteOrderPDFMenuItem({ selectedQuoteId }: EmailPDFMenuItemProps) {
  const { activeUser, activeTenant } = useGlobalApp();
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [hideLineItemPrices, setHideLineItemPrices] = useState(false);

  async function handleEmailPDF() {
    notification.info({
      message: `Sending email...`,
    });
    setEmailModalOpen(false);
    try {
      // TODO: add tracking
      await coreApiFetch(schemas.salesQuoteDrafts.postSalesQuoteDraftPdfsMulti, {
        bodyParams: {
          salesQuoteDraftIds: [selectedQuoteId],
          showLineItemPrices: !hideLineItemPrices,
        },
      });
      notification.success({
        message: `Draft Quote Successfully Sent`,
        description: 'Email sent, check your inbox shortly',
      });
    } catch (err) {
      captureAndShowError(err, `Error while sending email. Our engineering team has been notified.`);
    } finally {
      setHideLineItemPrices(false);
    }
  }

  return (
    <>
      <Tooltip
        placement="topRight"
        title={`
          PDFs will be sent to ${joinAnd([
            activeUser.email,
            ...(activeTenant.tenantUser.settings?.quote?.copyEmailsTo ?? []),
          ])}. This will not send an email to the customer.`}
      >
        <div
          className={css`
            position: relative;
          `}
        >
          <Menu.Item
            onClick={() => {
              setEmailModalOpen(true);
            }}
          >
            Email PDF
          </Menu.Item>
        </div>
      </Tooltip>

      <Portal portalId={PortalId.Modal}>
        <Modal
          title="Email PDF"
          okText="Send Email"
          okType="primary"
          visible={isEmailModalOpen}
          onOk={() => {
            handleEmailPDF();
          }}
          onCancel={() => {
            setHideLineItemPrices(false);
            setEmailModalOpen(false);
          }}
          mask
        >
          The PDF of this Draft Quote will be sent to{' '}
          {joinAnd([activeUser.email, ...(activeTenant.tenantUser.settings?.quote?.copyEmailsTo ?? [])])}. This will not
          send an email to the customer.
          <br />
          <br />
          <Checkbox checked={hideLineItemPrices} onChange={(e) => setHideLineItemPrices(e.target.checked)} /> Hide line
          item prices on PDF
        </Modal>
      </Portal>
    </>
  );
}
