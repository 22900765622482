import React from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { colors } from 'theme/colors';

import { MultiSelectModal } from 'components/AsyncSelect/MultiSelectModal';
import { UseAsyncMultiSelectProps } from 'components/AsyncSelect/types';
import { Button } from 'components/Button';

import { showAsyncModal } from 'utils/asyncModal';

export interface ChooseViewSettingButtonProps {
  options: UseAsyncMultiSelectProps;
  selectedValues: string[];
  onSelectedValuesChange: (columns: string[]) => void;
  triggerButton?: React.ReactElement;
  descriptor: string;
  defaultValues?: string[];
}

export function ChooseViewSettingButton({
  options,
  selectedValues,
  onSelectedValuesChange,
  triggerButton,
  descriptor,
  defaultValues,
}: ChooseViewSettingButtonProps) {
  const description = `Configure ${descriptor.toLowerCase()} to show what you want to see and the order that you want to see it. Saving this will maintain this view whenever you use Recurrency.`;
  const chooseButton = triggerButton ?? (
    <Button
      icon={<SettingOutlined />}
      size="small"
      className={css`
        &.ant-btn {
          border-color: ${colors.neutral[300]};

          &:focus,
          &:hover {
            border-color: ${colors.primary[500]};
            color: inherit;
          }
        }
      `}
    >
      {descriptor}
    </Button>
  );
  const ModalButton = React.cloneElement(chooseButton, {
    onClick: async () => {
      const newValues = await showAsyncModal(MultiSelectModal, {
        options,
        selectedValues,
        orderable: true,
        title: `Configure ${descriptor}`,
        defaultValues,
        description,
        queryPlaceholder: `Search for ${descriptor.toLowerCase()}`,
      });
      if (newValues) {
        onSelectedValuesChange(newValues);
      }
    },
  });

  return ModalButton;
}
