import React from 'react';

import { Link } from 'react-router-dom';

import { DashboardOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TransferOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TransferOrderDTO } from '@recurrency/core-api-schema/dist/transferOrders/common';
import { ColumnType } from 'antd/lib/table';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { ActionButton } from 'components/Button/ActionButton';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { ButtonLink } from 'components/Links';
import { PageHeader } from 'components/PageHeader';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { ResultCount } from 'components/ResultCount';
import { SearchInput } from 'components/SearchInput';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { truthy } from 'utils/boolean';
import { formatDateShorthand } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { asKeyOf, sortableDollarWithCentsColumn } from 'utils/tables';

import { TransferOrdersHashState } from 'types/hash-state';

export const TransferStatusBadge = {
  [TransferOrderStatus.Open]: BadgeStatus.Open,
  [TransferOrderStatus.Shipped]: BadgeStatus.Shipped,
  [TransferOrderStatus.Completed]: BadgeStatus.Completed,
};

const statusOptions = [
  { label: 'All', value: '' },
  { label: 'Open', value: 'open' },
  { label: 'Shipped', value: 'shipped' },
  { label: 'Completed', value: 'completed' },
];

export const TransferOrderListPage = () => {
  const locationsSelectProps = useLocationsSelectProps({});
  const [hashState, updateHashState] = useHashState<TransferOrdersHashState>();
  const { sourceLocationIds = [], destinationLocationIds = [] } = hashState;
  const tableProps = useCoreApiTableProps({
    schema: schemas.transferOrders.getTransferOrders,
    queryParams: {
      filter: {
        status: hashState.status ? [hashState.status] : [],
        sourceLocationIds,
        destinationLocationIds,
        search: hashState.searchQuery,
      },
    },
  });

  const columns: (ColumnType<TransferOrderDTO> | null)[] = [
    {
      title: 'TO No',
      dataIndex: asKeyOf<TransferOrderDTO>('transferOrderNumber'),
      render: (id: string) => <Link to={routes.purchasing.transferOrderDetails(id)}>{id}</Link>,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<TransferOrderDTO>('status'),
      render: (status: TransferOrderStatus) => <StatusBadge status={TransferStatusBadge[status]} />,
      sorter: true,
    },
    {
      title: 'Source Location',
      dataIndex: asKeyOf<TransferOrderDTO>('sourceLocationName'),
      render: (_, record) => (
        <span>
          {record.sourceLocationId}: {record.sourceLocationName}
        </span>
      ),
      sorter: true,
    },
    {
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferOrderDTO>('destinationLocationName'),
      render: (_, record) => (
        <span>
          {record.destinationLocationId}: {record.destinationLocationName}
        </span>
      ),
      sorter: true,
    },
    {
      title: 'Transfer Date',
      dataIndex: asKeyOf<TransferOrderDTO>('transferDate'),
      render: (transferDate: string) => formatDateShorthand(transferDate),
      sorter: true,
    },
    sortableDollarWithCentsColumn({
      title: 'Total Cost',
      dataIndex: asKeyOf<TransferOrderDTO>('totalCost'),
      sorter: true,
    }),
    {
      title: 'Line Count',
      dataIndex: asKeyOf<TransferOrderDTO>('lineCount'),
    },
    {
      title: '',
      dataIndex: asKeyOf<TransferOrderDTO>('transferOrderNumber'),
      render: (id: string) => ButtonLink(routes.purchasing.transferOrderDetails(id)),
    },
  ];

  return (
    <Container>
      <PageHeader title={<InfoTooltip title="">Transfer Orders</InfoTooltip>} />
      <FilterBarBox dividerLine>
        <FlexSpace direction="column" gap={16} fullWidth>
          <FlexSpace wrap fullWidth>
            <span>Filter</span>
            <AsyncMultiSelect
              label="Status"
              mode="single"
              icon={<DashboardOutlined />}
              selectProps={{ options: statusOptions }}
              selectedValues={[hashState.status || statusOptions[0].value]}
              onSelectedValuesChange={(values: string[]) => updateHashState({ status: values[0] || '' })}
            />
            <AsyncMultiSelect
              label="Source Location"
              mode="multiple"
              queryPlaceholder="Search locations"
              icon={<EnvironmentOutlined />}
              selectProps={convertToMultiSelectProps(locationsSelectProps)}
              selectedValues={sourceLocationIds}
              onSelectedValuesChange={(values: string[]) => updateHashState({ sourceLocationIds: values })}
            />
            <AsyncMultiSelect
              label="Destination Location"
              mode="multiple"
              queryPlaceholder="Search locations"
              icon={<EnvironmentOutlined />}
              selectProps={convertToMultiSelectProps(locationsSelectProps)}
              selectedValues={destinationLocationIds}
              onSelectedValuesChange={(values: string[]) => updateHashState({ destinationLocationIds: values })}
            />
            {hashState.status || hashState.destinationLocationIds || hashState.sourceLocationIds ? (
              <ActionButton
                onClick={() =>
                  updateHashState({
                    status: undefined,
                    sourceLocationIds: undefined,
                    destinationLocationIds: undefined,
                  })
                }
                label="Clear All"
              />
            ) : null}
            <FlexSpacer />
            <SearchInput
              placeholder="Search Transfer Orders"
              query={hashState.searchQuery}
              onQueryChange={(query) => updateHashState({ searchQuery: query !== '' ? query : undefined })}
            />
            <ResultCount count={tableProps.totalCount} />
          </FlexSpace>
        </FlexSpace>
      </FilterBarBox>
      <AsyncTable<TransferOrderDTO> tableProps={tableProps} columns={columns.filter(truthy)} />
    </Container>
  );
};
