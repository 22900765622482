import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig, lineItemConfig: quoteOrderLineItemConfig } = config.quoteOrderEntryFlow;
  const { formConfig: itemFormConfig } = config.itemEntryFlow;
  quoteOrderFormConfig.deliveryInstructions.required = true;
  quoteOrderFormConfig.emailAddress.required = true;
  quoteOrderFormConfig.customerPONumber.required = true;
  quoteOrderFormConfig.sourceLocation.required = true;
  quoteOrderFormConfig.carrier.required = true;
  quoteOrderFormConfig.contact.required = true;
  quoteOrderLineItemConfig.otherCost = {
    visible: true,
    displayName: 'Rebate Cost',
  };
  quoteOrderLineItemConfig.otherCostBasedGM = {
    visible: true,
    displayName: 'Rebate Margin',
  };
  itemFormConfig.supplier.defaultValueForAssemblyByCompanyId = {
    HTA001: {
      foreignId: '100024',
      name: 'HeiTek Automation',
    },
    BT01: {
      foreignId: '111901',
      name: 'Betatron Electronics, Inc',
    },
  };
  itemFormConfig.locationForQuoteEntry = {
    selectMode: 'list',
    listOptions: {
      defaultLocation: false,
      quoteEntryLocation: true,
    },
  };
  return config;
}
