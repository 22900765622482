import React from 'react';

import { css } from '@emotion/css';
import { Table } from 'antd';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { theme } from 'theme';

import { Container } from 'components/Container';

import { formatUSD } from 'utils/formatting';

interface DonutChartProps {
  total: number;
  paid: number;
}

export const DonutChart: React.FC<DonutChartProps> = ({ total, paid }) => {
  const unpaid = total - paid;
  const data = [
    { name: 'Paid: ', value: paid },
    { name: 'Unpaid: ', value: unpaid },
  ];
  const COLORS = [theme.colors.primary[700], theme.colors.primary[100]];
  const percentage = ((paid / total) * 100).toFixed(2);
  const columns = [
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      key: 'value',
      render: (value: number) => <strong>{formatUSD(value, true, 2)}</strong>,
    },
  ];

  const renderTooltip = (event: any) => {
    if (event.payload.length > 0) {
      return (
        <div style={{ backgroundColor: 'white', padding: '8px', border: '1px solid #ccc' }}>
          {event.payload[0].name} {formatUSD(event.payload[0].value, true, 2)}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Container
        className={css`
          width: 300px;
          height: 200px;
        `}
      >
        <PieChart width={300} height={200}>
          <Pie data={data} cx="50%" cy="50%" innerRadius={50} outerRadius={80} paddingAngle={3} dataKey="value">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <text
            x="50%"
            y="45%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: '16px', fontWeight: 'bold' }}
          >
            {percentage}%
          </text>
          <text x="50%" y="55%" textAnchor="middle" dominantBaseline="central" style={{ fontSize: '14px' }}>
            Complete
          </text>
          <Tooltip content={renderTooltip} />
        </PieChart>
      </Container>
      <Table
        dataSource={data}
        columns={columns}
        showHeader={false}
        bordered={false}
        rowKey="name"
        pagination={false}
        size="small"
        style={{ width: '50%', alignSelf: 'center' }}
      />
    </div>
  );
};
