import { theme } from 'theme';

import { Table } from 'components/Table';
import { Typography } from 'components/Typography';

import { styled } from 'utils/styledComponent';

export const Container = styled('div')`
  width: 100%;
  margin: 16px 0;
`;

export const SidePaneContainer = styled('div')`
  margin-top: 16px;
`;

export const ItemTable = styled(Table)`
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: 8px;

  .ant-table.ant-table-small .ant-table-cell {
    padding: 8px 4px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-table {
    td.ant-table-cell {
      vertical-align: top;
      .ant-select,
      .ant-input {
        width: 100%;
      }

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
      }
    }

    tr {
      td.ant-table-cell {
        &:first-child {
          width: 22px;
        }
      }
    }
  }
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 16px;
`;

export const HeaderLeft = styled('div')`
  display: flex;
  align-items: center;

  .ant-radio-group {
    margin-left: 8px;
  }
`;

export const HeaderActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  .ant-btn {
    margin-left: 8px;
  }
`;

export const TableAction = styled('div')`
  cursor: pointer;

  &:hover {
    svg {
      color: ${theme.colors.primary[600]};
    }
  }
`;

export const TableCell = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  min-height: 30px;
`;

export const TableCellDetail = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 2px;
`;

export const TextSpacer = styled('div')`
  height: 6px;
`;

export const UseThisValue = styled(Typography)`
  cursor: pointer;
  &:hover {
    background: ${theme.colors.neutral[200]};
  }
`;

export const Footer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
`;

export const FooterLeft = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const FooterContent = styled('div')`
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    margin-bottom: 8px;
  }
`;

export const AddButtonContent = styled('div')`
  text-align: left;
`;
