import React from 'react';

import { css } from '@emotion/css';
import { GetItemNotesFilter } from '@recurrency/core-api-schema/dist/notes/getItemNotes';
import { GetSupplierNotesFilter } from '@recurrency/core-api-schema/dist/notes/getSupplierNotes';
import { EndpointSchema } from '@recurrency/core-api-schema/dist/utils/apiSchema';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { SmallLoader } from 'components/Loaders';

import { useCoreApi } from 'hooks/useApi';

import { ViewAllModal } from '../sidePane/ViewAllModal';
import { ViewNotesModalContent } from './ViewNotesModalContent';

type NoteQueryParams = GetItemNotesFilter | GetSupplierNotesFilter;
export function ViewNotesModal({
  endpoint,
  filter,
  title,
  onClose,
  mandatoryText,
}: {
  endpoint: EndpointSchema;
  filter: NoteQueryParams;
  title: React.ReactNode;
  onClose: () => void;
  mandatoryText: string;
}) {
  const { data, isLoading } = useCoreApi(endpoint, {
    queryParams: {
      filter,
    },
  });
  const isDesktopView: boolean = useBreakpoint()?.lg ?? false;

  const responsiveModalWidth = isDesktopView ? '65vw' : '95vw';
  return (
    <ViewAllModal
      onClose={onClose}
      title={title}
      content={isLoading ? <SmallLoader /> : <ViewNotesModalContent notes={data.items} mandatoryText={mandatoryText} />}
      width="responsiveModalWidth"
      extraStyles={css`
        margin: 0 auto;
        .ant-modal-content {
          width: ${responsiveModalWidth};
        }
      `}
    />
  );
}
