import React from 'react';

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean | undefined;
  wrapper: (children: React.ReactElement) => React.ReactElement;
  children: React.ReactElement;
}) => (condition ? wrapper(children) : children);
