import { SparklinePoint, SparklineSeries } from './Sparkline';

export function getMonthlyFilledSeries<RowT>({
  data,
  dateField,
  valueField,
  numPrevYears,
  curMonthOffset = 0,
  formatFn,
  title,
  color,
}: {
  data: RowT[];
  dateField: keyof RowT;
  valueField: keyof RowT;
  numPrevYears: number;
  /** how many months +/- curMonth to fill the series */
  curMonthOffset?: number;
  formatFn: (value: number) => string;
  title?: string;
  color?: string;
}): SparklineSeries[] {
  const valueByDate: Obj<number> = Object.fromEntries(
    // extract YYYY-MM-DD date part from timestamp iso string
    data.map((row) => [(row[dateField] as unknown as string).slice(0, 10), row[valueField] as unknown as number]),
  );
  const curDate = new Date(Date.now()); // easier to mock/freeze Date value if passing in Date.now()
  // add/subtract months to get to the start of the month. setMonth will correctly offset year if setMonth is negative
  if (curMonthOffset) curDate.setMonth(curDate.getMonth() + curMonthOffset);

  const curYear = curDate.getFullYear();
  const startYear = curYear - numPrevYears;
  const curMonth = curDate.getMonth() + 1; // js months are 0-indexed
  const series: SparklineSeries[] = [];

  for (let year = startYear; year <= curYear; ++year) {
    const points: SparklinePoint[] = [];
    for (let month = 1; month <= 12; ++month) {
      if (year === curYear && month > curMonth) break; // up to current month
      // fill in missing months with 0 so all lines have same start/end spans
      const date = `${year}-${String(month).padStart(2, '0')}-01`;
      const value = valueByDate[date] || 0;
      points.push({ date, value });
    }
    series.push({
      data: points,
      formatFn,
      title,
      color,
      separation: true,
      showGradient: true,
    });
  }

  return series;
}
