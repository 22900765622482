import React from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useSearchIndexTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { LinkButton } from 'components/Button/LinkButton';
import { ButtonLink } from 'components/Links';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatDate, formatUSD } from 'utils/formatting';
import { isAdmin } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';

import { SearchIndexSalesOrder } from 'types/search-collections';

export const OpenOrdersTable = () => {
  const { activeErpRole } = useGlobalApp();

  const columns: ColumnType<SearchIndexSalesOrder>[] = [
    {
      title: 'Order No',
      dataIndex: 'order_no',
      render: (orderNo: string) => <Link to={routes.orders.orderDetails(orderNo)}>{orderNo} </Link>,
    },
    {
      title: `Order Date`,
      dataIndex: 'order_date',
      render: (date: string) => formatDate(date),
    },
    {
      title: 'Customer ID',
      dataIndex: 'customer_id',
      render: (customerId: string) => <Link to={routes.sales.customerDetails(customerId)}>{customerId}</Link>,
    },
    { title: 'Customer', dataIndex: 'customer_name' },
    {
      title: 'Open Amount',
      dataIndex: 'total_open_value',
      render: (amt: number) => formatUSD(amt, true),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_value',
      render: (amt: number) => formatUSD(amt, true),
    },
    {
      title: 'GM',
      dataIndex: 'gross_margin',
      render: (amt: number) => formatUSD(amt, true),
    },
    {
      title: 'Line Count',
      dataIndex: 'item_count',
    },
    {
      dataIndex: 'order_no',
      render: (orderNo: string) => ButtonLink(routes.orders.orderDetails(orderNo)),
    },
  ].filter(filterCostAndGM);

  const facetValueFilters = {
    order_type: ['order'],
    status: ['Open'],
    ...(!isAdmin(activeErpRole.foreignId, activeErpRole.name) && { salesrep_id: activeErpRole?.foreignIds }),
  };
  const sortBy: { field: string; order: 'desc' } = {
    field: 'order_date',
    order: 'desc',
  };

  return (
    <>
      <AsyncTable
        tableProps={useSearchIndexTableProps<SearchIndexSalesOrder>({
          indexName: SearchIndexName.SalesOrders,
          options: {
            filters: facetValueFilters,
            hitsPerPage: 15,
            sortBy,
          },
        })}
        columns={columns}
        rowKey="orderNo"
        pagination={false}
      />
      <div
        className={css`
          margin-top: 16px;
          justify-content: center;
          display: flex;
        `}
      >
        <LinkButton
          route={routes.orders.orderList({
            where: facetValueFilters,
            sortBy,
          })}
        >
          View All
        </LinkButton>
      </div>
    </>
  );
};
