import React from 'react';

import { EnvironmentOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

import { linkableAddress, linkableEmail, linkablePhoneNumber } from 'components/Links';
import { PropertyListItemProps } from 'components/PropertyListItem';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { dashesIfEmpty, EMPTY_VALUE_DASHES } from 'utils/formatting';

import { SearchIndexShipToPartial } from 'types/search-collections';

import { DetailPagePropertyListItem } from './DetailPagePropertyList';

export function phoneNumberPropertyListItem(item: PropertyListItemProps): PropertyListItemProps {
  const phoneNumber = item.value as string;
  return {
    ...item,
    icon: phoneNumber ? linkablePhoneNumber(phoneNumber, <PhoneOutlined />) : <PhoneOutlined />,
    value: dashesIfEmpty(phoneNumber),
  };
}

export function emailPropertyListItem(item: PropertyListItemProps): PropertyListItemProps {
  const email = item.value as string;
  return {
    ...item,
    icon: email ? linkableEmail(email, <MailOutlined />) : <MailOutlined />,
    value: dashesIfEmpty(email),
  };
}

export function addressPropertyListItem(item: PropertyListItemProps): PropertyListItemProps {
  const address = item.value as string;
  return {
    ...item,
    value: address ? (
      <>
        {linkableAddress(
          address.replaceAll('\n', ', '),
          <div>
            <EnvironmentOutlined /> Map
          </div>,
        )}
        <div style={{ whiteSpace: 'pre-line' }}>{address}</div>
      </>
    ) : (
      EMPTY_VALUE_DASHES
    ),
  };
}

export function lineItemPagePropertyListItem(
  item: PropertyListItemProps,
  compact?: boolean,
): DetailPagePropertyListItem {
  const content = item.value as string;
  return {
    ...item,
    value: content ? (
      <>
        <div style={{ whiteSpace: 'pre-line' }}>{content}</div>
      </>
    ) : (
      EMPTY_VALUE_DASHES
    ),
    colspan: compact ? 0.75 : 1,
  };
}

export function lineItemPageShipToPropertyListItem(
  item: PropertyListItemProps,
  compact?: boolean,
): DetailPagePropertyListItem {
  const algoliaShipTo = item.value as SearchIndexShipToPartial;
  return {
    ...item,
    value: algoliaShipTo ? (
      <>
        <div
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          {algoliaShipTo?.ship_to_name}
          {algoliaShipTo?.ship_to_address && (
            <InfoTooltip
              placement="bottom"
              title={
                <>
                  <div>{algoliaShipTo.ship_to_address}</div>
                  <div>{algoliaShipTo.ship_to_phone}</div>
                </>
              }
              useInfoIcon
            />
          )}
        </div>
      </>
    ) : (
      EMPTY_VALUE_DASHES
    ),
    colspan: compact ? 0.75 : 1,
  };
}
