import React from 'react';

import { ToolFilled } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { theme } from 'theme';

import { Alert } from 'components/Alert';
import { Card } from 'components/Card';
import { RecurrencyWordmark } from 'components/Icons';

export function MaintenancePage() {
  return (
    <div
      className={cx(
        'AppContainer',
        css`
          min-height: 100vh;
          width: 100%;
          display: flex;
          background: ${theme.colors.neutral[200]};
          align-items: center;
          justify-content: center;
        `,
      )}
    >
      <Card
        className={css`
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          overflow: hidden;
          flex-direction: column;
          width: min(90%, 350px);
        `}
      >
        <div
          className={css`
            background: ${theme.colors.primary[700]};
            width: 100%;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <RecurrencyWordmark
            className={css`
              height: 27px;
            `}
          />
        </div>
        <Alert
          icon={<ToolFilled />}
          message="Recurrency is temporarily unavailable due to scheduled maintenance."
          banner
        />
        <div
          className={css`
            padding: 16px;
          `}
        >
          Though we try to limit maintenance to off-hours, we apologize for any disruptions this may cause. Service will
          be back online as quickly as possible. For status updates, go to{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://status.recurrency.com">
            status.recurrency.com
          </a>
          .
        </div>
      </Card>
    </div>
  );
}
