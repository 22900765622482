import React from 'react';

import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { ButtonProps, notification } from 'antd';
import zipcelx, { ZipCelXConfig, ZipCelXSheet, ZipCelXDataSet } from 'zipcelx';

import { Button } from 'components/Button';
import { Tooltip } from 'components/Tooltip';

import { captureAndShowError } from 'utils/error';
import { formatDate, formatNumber } from 'utils/formatting';
import { track, TrackEvent } from 'utils/track';

export type GetDownloadDataFn = () => Promise<ZipCelXSheet>;

export interface ExportButtonProps extends ButtonProps {
  getDownloadData: GetDownloadDataFn;
  recordType?: string;
  downloadRecordLimit?: number;
}

export async function notifyAndDownload(getDownloadData: GetDownloadDataFn, recordType?: string) {
  const key = 'downloadNotification';
  notification.info({
    key,
    message: 'Preparing Download',
    description: (
      <span>
        Loading records for download. <LoadingOutlined />
      </span>
    ),
    duration: 0,
  });

  try {
    const sheet = await getDownloadData();
    if (sheet.data && sheet.data.length > 0) {
      const config: ZipCelXConfig = {
        filename: `Recurrency_${recordType ? `${recordType}_` : ''}${formatDate(new Date().toLocaleDateString())}`,
        sheet,
      };
      zipcelx(config);

      const recordCount = sheet.data.length - 1; // ignore header row
      notification.success({
        key,
        message: 'Download Complete',
        description: `Downloaded ${formatNumber(recordCount)} records successfully.`,
      });
      track(TrackEvent.Components_ExportButton_Export, { recordCount, recordType });
    } else {
      throw new Error('Failed to load records to download');
    }
  } catch (err) {
    captureAndShowError(err, `Error while download data`, { notificationKey: key });
  }
}

export function DownloadButton({ getDownloadData, recordType, downloadRecordLimit, ...otherProps }: ExportButtonProps) {
  return (
    <Tooltip
      title={`Download as Excel file${downloadRecordLimit ? ` (up to ${formatNumber(downloadRecordLimit)} rows)` : ''}`}
    >
      <Button
        onClick={() => notifyAndDownload(getDownloadData, recordType)}
        icon={<DownloadOutlined />}
        {...otherProps}
      >
        Download
      </Button>
    </Tooltip>
  );
}

// Helper util functions for converting array to ZipCelXSheet
export interface DownloadXSheetColumn<RecordT> {
  title: string;
  type: 'string' | 'number';
  value: (record: RecordT) => string | number;
}

export function recordsToXSheet<RecordT>(records: RecordT[], columns: DownloadXSheetColumn<RecordT>[]): ZipCelXSheet {
  const data: ZipCelXDataSet = [
    columns.map((col) => ({ value: col.title, type: 'string' })), // header row
    ...records.map((record) => columns.map((col) => ({ value: col.value(record), type: col.type }))),
  ];
  return { data };
}
