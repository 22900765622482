import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import { DEFAULT_PAGE_SIZE } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { optArrFromVal } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { formatDateShorthand, formatErpOrderStatus, formatUSD } from 'utils/formatting';

import { SalesOrderLinesTable } from '../SalesOrderLinesTable';
import { QtyByMonthSparkline } from './QtyByMonthSparkline';
import { ViewAllModal } from './ViewAllModal';

export function SalesOrderLinesPanelContext({
  itemId,
  companyId,
  customerId,
  locationIds,
  orderStatus,
}: {
  itemId: string;
  companyId?: string;
  customerId?: string;
  locationIds?: string[];
  orderStatus?: ErpOrderStatus;
}) {
  const { data, isLoading, error } = useCoreApi(schemas.salesOrderLines.getSalesOrderLinesV2, {
    queryParams: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      filter: {
        itemId,
        companyIds: optArrFromVal(companyId),
        customerId,
        locationIds,
        status: orderStatus,
      },
    },
  });

  return (
    <>
      {orderStatus === undefined ? (
        <QtyByMonthSparkline
          data={data?.aggs}
          dateField="orderDate"
          quantityField="quantityOrdered"
          seriesTitle="Qty Ordered"
        />
      ) : null}
      <MiniTable
        numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
        data={data?.items}
        columns={[
          { render: (record) => formatDateShorthand(record.orderDate), width: '90px' },
          { render: (record) => formatErpOrderStatus(record.status), width: '100px' },
          {
            render: (record) => formatUSD(record.unitPrice, true),
            align: `right`,
          },
          { render: (record) => `${record.quantityOrdered} ${record.unitOfMeasure || ``}`, align: `right` },
        ]}
        isLoading={isLoading}
        error={error}
        onMoreButtonClick={() => {
          showAsyncModal(ViewAllModal, {
            title: `Order Lines of Item #${itemId}${customerId ? ` to Customer #${customerId}` : ''}`,
            content: (
              <SalesOrderLinesTable
                itemId={itemId}
                companyId={companyId}
                customerId={customerId}
                locationIds={locationIds}
                orderStatus={orderStatus}
              />
            ),
          });
        }}
      />
    </>
  );
}
