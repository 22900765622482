import React from 'react';

import { css } from '@emotion/css';
import { ReplenishmentPathDTO } from '@recurrency/core-api-schema/dist/itemLocations/getItemLocations';
import { Col, Row, Skeleton, Timeline } from 'antd';
import { colors } from 'theme/colors';

import { ConditionalWrapper } from 'components/ConditionalWrapper';

import { pluralize, splitIdNameStr } from 'utils/formatting';

export type ReplenishmentPathTimelineProps = {
  location?: string;
  supplier?: string;
  isLoading?: boolean;
  replenishmentPathData?: ReplenishmentPathDTO;
};

export function ReplenishmentPathTimeline({
  isLoading,
  replenishmentPathData,
  location,
  supplier,
}: ReplenishmentPathTimelineProps) {
  const locationId = location ? splitIdNameStr(location).foreignId : null;
  const replenishmentPath = replenishmentPathData?.replenishmentPath;
  const hubLeadTime = replenishmentPathData?.hubLeadTime;
  if (isLoading)
    return (
      <>
        <Row
          gutter={[16, 16]}
          className={css`
            width: 350px;
          `}
        >
          <Col span={10}>
            <Skeleton active />
          </Col>
          <Col span={14}>
            <Skeleton active />
          </Col>
        </Row>
      </>
    );

  if (!replenishmentPath) return null;
  // Reverse the replenishment path so that the timeline starts from the supplier and ends at requirement location
  const reversedReplenishmentPath = replenishmentPath.slice(0).reverse();

  const renderTimelineContent = (primaryLabel: string, secondaryLabel: string) => (
    <>
      {primaryLabel}
      <div
        className={css`
          font-size: 11px;
        `}
      >
        {secondaryLabel}
      </div>
    </>
  );

  return (
    <Timeline
      mode="left"
      className={css`
        width: 350px;
        margin-top: 10px;

        .ant-timeline-item-label {
          width: calc(30% - 12px) !important;
          margin-top: 30px !important;
        }

        .ant-timeline-item-tail,
        .ant-timeline-item-head.ant-timeline-item-head-blue {
          left: 30% !important;
        }

        .ant-timeline-item-content {
          left: calc(30% - 4px) !important;
          width: calc(70% - 14px) !important;
        }

        .ant-timeline-item-tail {
          border-left-color: ${colors.neutral[400]};
        }

        .ant-timeline-item-last {
          padding-bottom: 0;
        }
        .ant-timeline-item-last > .ant-timeline-item-content {
          min-height: 0;
        }
      `}
    >
      {supplier && (
        <Timeline.Item
          key="supplier"
          label={
            <span
              className={css`
                font-size: 12px;
                line-height: 10px;
              `}
            >
              {hubLeadTime !== undefined ? (
                <>
                  {hubLeadTime} days
                  <br />
                  (purchase)
                </>
              ) : (
                ''
              )}
            </span>
          }
        >
          {renderTimelineContent(supplier, 'Primary Supplier')}
        </Timeline.Item>
      )}
      {reversedReplenishmentPath.map((rpItem, i) => {
        const nextNode = i + 1 < reversedReplenishmentPath.length ? reversedReplenishmentPath[i + 1] : null;
        const currentLocationId = rpItem.locationId;
        const transferDaysText =
          i !== reversedReplenishmentPath.length - 1 && nextNode ? (
            <>
              {nextNode.transferDays ?? 0} {pluralize(nextNode.transferDays ?? 0, 'day')}
              <br />
              (transfer)
            </>
          ) : (
            ''
          );

        return (
          <Timeline.Item
            key={currentLocationId}
            label={
              <span
                className={css`
                  font-size: 12px;
                  line-height: 10px;
                `}
              >
                {transferDaysText}
              </span>
            }
          >
            <ConditionalWrapper condition={currentLocationId === locationId} wrapper={(c) => <b>{c}</b>}>
              {renderTimelineContent(
                `${rpItem.locationId}: ${rpItem.locationName}`,
                i === reversedReplenishmentPath.length - 1
                  ? 'Requirement Location'
                  : i === 0
                  ? 'Purchase Location'
                  : '',
              )}
            </ConditionalWrapper>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
}
