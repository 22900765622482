import React, { useState } from 'react';

import { CreditCardOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { ChargeDTO } from '@recurrency/core-api-schema/dist/payments/charge';
import { notification } from 'antd';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { DetailPagePropertyList } from 'components/recipes/detailPage/DetailPagePropertyList';

import { coreApiFetch } from 'utils/api';
import { formatUSD } from 'utils/formatting';

interface RefundModalProps {
  onClose: () => void;
  charge: ChargeDTO;
}

export function RefundModal({ onClose, charge }: RefundModalProps) {
  const [refundAmount, setRefundAmount] = useState<number | undefined>(undefined);
  const refundableAmount = (charge.amountCaptured - charge.amountRefunded) / 100;

  return (
    <Modal
      title="New Refund"
      visible
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>,
        <AsyncButton
          key="refund"
          onClick={async () => {
            if (!refundAmount) return;
            try {
              const response = await coreApiFetch(schemas.payments.createRefund, {
                bodyParams: {
                  // Stripe amount in cents
                  amount: Math.round(refundAmount * 100),
                  orderId: charge.metadata.order_id,
                  customerId: charge.metadata.customer_id,
                  invoiceId: charge.metadata.invoice_id,
                  chargeId: charge.id,
                  // refundApplicationFee: true,  // TODO(PE-2064): review this once we enable surcharges
                  reverseTransfer: true,
                  reason: 'requested_by_customer',
                },
              });
              if (response.data.status === 'succeeded') {
                notification.success({
                  message: 'Refund Requested',
                  description: 'To confirm the refunded amount please reload the page. This may take 1-2 business days',
                  duration: 30,
                });
              } else {
                notification.error({ message: 'Refund Failed', description: `${response.data.status}`, duration: 30 });
              }
            } catch (e) {
              notification.error({ message: 'Refund Failed', description: `${e}`, duration: 30 });
            }
            onClose();
          }}
          type="primary"
          disabled={!refundAmount || refundAmount > refundableAmount}
        >
          Request Refund
        </AsyncButton>,
      ]}
      onCancel={() => onClose()}
      centered
      width={500}
    >
      <DetailPagePropertyList
        rows={[
          [{ label: 'Customer', value: charge.metadata.customer_id }],
          [{ label: 'Order', value: `#${charge.metadata.order_id}` }],
          [{ label: 'Card Holder', value: charge.billingDetails?.name }],
          [
            {
              label: 'Payment Method',
              value: `${charge.paymentMethodDetails.card.brand.toLocaleUpperCase()} ending in ${
                charge.paymentMethodDetails.card.last4
              }`,
              icon: <CreditCardOutlined />,
              colspan: 10,
            },
          ],
          [{ label: 'Amount Refundable', value: formatUSD(refundableAmount, true), colspan: 10 }],
          [{ label: 'Original Charge Amount', value: formatUSD(charge.amountCaptured / 100, true), colspan: 10 }],
        ]}
        flex
      />
      <PropertyListItem
        label="Amount to Refund"
        value={
          <Input
            type="number"
            prefix="$"
            value={refundAmount}
            required
            onChange={(ev) => setRefundAmount(ev.target.valueAsNumber)}
            min={0}
          />
        }
      />
    </Modal>
  );
}
