import React, { useEffect } from 'react';

import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Spin, Table } from 'antd';
import { fontWeights } from 'theme/typography';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';

import { TenantDataSyncConfigsModal } from './components/TenantDataSyncConfigsModal';
import { useTentantDataSyncConfigsTable } from './TenantDataSyncConfigsTable.hooks';

export interface TenantDataSyncConfigsTableProps {
  tenantId: string;
}

export function TenantDataSyncConfigsTable({ tenantId }: TenantDataSyncConfigsTableProps) {
  const { activeUser } = useGlobalApp();
  const { loading, tableData, fetchTenantDataSyncConfigs, form, handleSubmit } =
    useTentantDataSyncConfigsTable(tenantId);

  async function handleShowModal() {
    await showAsyncModal(TenantDataSyncConfigsModal, { activeUser, form, handleSubmit });
  }

  useEffect(() => {
    fetchTenantDataSyncConfigs();
  }, [fetchTenantDataSyncConfigs]);

  return (
    <Container>
      <Typography style={{ ...styles.headerTypography }}>Data Sync Configs for Tenant Integration</Typography>
      <Spin spinning={loading}>
        {!loading && tableData.length === 0 ? (
          <Typography type="subtitle" style={{ marginBottom: '24px' }}>
            No data sync configs found. Please create tenant integration first then try again. Contact Jack for more
            help.
          </Typography>
        ) : (
          <>
            <div>
              Those fields are used to configure Airflow data sync tasks from tenant db to PG to SF. <br />
              After being saved, there will be about a <strong>5-minute delay</strong> before the values can take
              effect.
              <ul>
                <li>
                  For <code>broker-sync</code>, values need to be uploaded to S3 by the next run of{' '}
                  <code>airflow-tenants-sync</code>.
                </li>
                <li>
                  For <code>post-exporter-sync</code>, values need to be evicted from the cache. Check{' '}
                  <code>pg_broker_sync.py</code> for exact duration.
                </li>
              </ul>
            </div>
            <Button
              icon={<EditOutlined />}
              onClick={handleShowModal}
              disabled={loading}
              type="primary"
              style={styles.editButton}
            >
              Edit
            </Button>
            <Table dataSource={tableData} columns={columns} pagination={false} />
          </>
        )}
      </Spin>
    </Container>
  );
}

const columns = [
  {
    title: 'Configuration Name',
    dataIndex: 'label',
    key: 'label',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (text: string) => <pre>{text}</pre>,
  },
];

const styles = {
  headerTypography: {
    fontSize: 18,
    alignContent: 'center',
    marginTop: 50,
    marginBottom: 20,
    fontWeight: fontWeights.bold,
  },
  editButton: {
    marginBottom: 20,
  },
};
