import React from 'react';

import { css } from '@emotion/css';
import { theme } from 'theme';

import { Tooltip } from 'components/Tooltip';

import { formatUSD, truncate } from 'utils/formatting';

import { Tag, TagProps } from './Tag';

export interface Props {
  // Name of the filter
  filterName: string;
  // List of all applied filters
  filterValues: string[] | number[];
  filterType: 'or' | 'and' | 'currency';
}

export const FilterTag = (props: Omit<Props & TagProps, 'label' | 'closable'>) => {
  const { filterValues, filterName, filterType, onClose, ...otherProps } = props;
  const tooltipTitle =
    filterType === 'currency'
      ? `${filterName} between ${formatUSD(filterValues[0])} and ${formatUSD(filterValues[1])}`
      : filterValues.join('\n');
  const title =
    filterType === 'currency' ? (
      filterName
    ) : (
      <>
        <span
          className={css`
            color: ${theme.colors.primary[500]};
            font-weight: 500;
          `}
        >
          {`[${filterName}]`}
        </span>
        <span
          className={css`
            font-weight: 500;
          `}
        >
          {
            // TODO: Ideally we can show a proper amount of values as smart as possible rather than the first one regardless of length
            typeof filterValues[0] === 'string' ? truncate(filterValues[0], 20) : filterValues[0]
          }
          {filterValues.length > 1 ? `, +${filterValues.length - 1}` : ''}
        </span>
      </>
    );
  return (
    <Tooltip title={tooltipTitle} overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}>
      <div>
        <Tag label={title} size="small" closable={!!onClose} onClose={onClose} {...otherProps} />
      </div>
    </Tooltip>
  );
};
