import { Children, isValidElement, ReactNode } from 'react';

export function reactNodeToText(node: ReactNode): string {
  if (typeof node === 'string') {
    return node;
  }
  if (typeof node === 'number') {
    return node.toString();
  }
  if (node && isValidElement(node) && node.props.children) {
    return Children.map(node.props.children, reactNodeToText)?.join('') ?? '';
  }

  return '';
}
