import React, { useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { notification } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Portal, PortalId } from 'components/Portal';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { joinAnd, pluralize } from 'utils/formatting';

import { IdNameObj } from 'types/legacy-api';

export interface EmailPDFButtonProps {
  selectedQuotes: { id: string; customer: IdNameObj }[];
}

export function EmailQuoteOrderPDFButton({ selectedQuotes }: EmailPDFButtonProps) {
  const { activeUser, activeTenant } = useGlobalApp();
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [hideLineItemPrices, setHideLineItemPrices] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const customersSelected = selectedQuotes.map((q) => q.customer);
  const disabled =
    customersSelected.length === 0 || !customersSelected.every((c) => c.foreignId === customersSelected[0].foreignId);

  async function handleEmailPDFs() {
    notification.info({
      message: `Sending email...`,
    });
    setIsButtonLoading(true);
    setEmailModalOpen(false);
    try {
      // TODO: add tracking
      await coreApiFetch(schemas.salesQuoteDrafts.postSalesQuoteDraftPdfsMulti, {
        bodyParams: {
          salesQuoteDraftIds: selectedQuotes.map((q) => q.id),
          showLineItemPrices: !hideLineItemPrices,
        },
      });
      notification.success({
        message: `${pluralize(selectedQuotes.length, 'Draft Quote', 'Draft Quotes', true)} Successfully Sent`,
        description: 'Email sent, check your inbox shortly',
      });
    } catch (err) {
      captureAndShowError(err, `Error while sending email. Our engineering team has been notified.`);
    } finally {
      setHideLineItemPrices(false);
      setIsButtonLoading(false);
    }
  }

  // TODO: consolidate email Button and Menu Item versions to reduce duplicated code
  return (
    <>
      <Tooltip
        placement="topRight"
        title={
          disabled
            ? `To email PDFs, select one or more draft quotes for the same customer.`
            : `PDFs will be sent to ${joinAnd([
                activeUser.email,
                ...(activeTenant.tenantUser.settings?.quote?.copyEmailsTo ?? []),
              ])}. This will not send an email to the customer.`
        }
      >
        <div
          className={css`
            position: relative;
          `}
        >
          <Button
            type="primary"
            onClick={() => {
              setEmailModalOpen(true);
            }}
            disabled={disabled}
            loading={isButtonLoading}
          >
            Email {pluralize(selectedQuotes.length, 'PDF')}
          </Button>
          {disabled && (
            <div
              className={css`
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                cursor: not-allowed;
              `}
            />
          )}
        </div>
      </Tooltip>

      <Portal portalId={PortalId.Modal}>
        <Modal
          title={pluralize(selectedQuotes.length, 'Email PDF')}
          okText="Send Email"
          okType="primary"
          visible={isEmailModalOpen}
          onOk={() => {
            handleEmailPDFs();
          }}
          onCancel={() => {
            setHideLineItemPrices(false);
            setEmailModalOpen(false);
          }}
          mask
        >
          The {pluralize(selectedQuotes.length, 'PDF')} of{' '}
          {pluralize(selectedQuotes.length, 'this Draft Quote', 'these Draft Quotes')} will be sent to{' '}
          {joinAnd([activeUser.email, ...(activeTenant.tenantUser.settings?.quote?.copyEmailsTo ?? [])])}. This will not
          send an email to the customer.
          <br />
          <br />
          <Checkbox checked={hideLineItemPrices} onChange={(e) => setHideLineItemPrices(e.target.checked)} /> Hide line
          item prices on {pluralize(selectedQuotes.length, 'PDF')}
        </Modal>
      </Portal>
    </>
  );
}
