import React from 'react';

import { Tooltip as AntDesignTooltip } from 'antd';
import { TooltipPlacement, TooltipProps as AntdTooltipProps } from 'antd/lib/tooltip';

export interface TooltipProps {
  title: string | React.ReactNode;
  placement?: TooltipPlacement;
  children: React.ReactChild;
  slow?: boolean;
  overlayInnerStyle?: React.CSSProperties;
}

export const Tooltip = ({
  children,
  title = '',
  placement = 'top',
  slow,
  overlayInnerStyle,
  ...otherProps
}: TooltipProps & AntdTooltipProps): JSX.Element => (
  <AntDesignTooltip
    mouseEnterDelay={slow ? 0.15 : 0.1}
    title={title}
    placement={placement}
    overlayInnerStyle={overlayInnerStyle}
    {...otherProps}
  >
    {children}
  </AntDesignTooltip>
);
