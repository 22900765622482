import * as Sentry from '@sentry/react';

import { env } from 'utils/env';

import { HttpError } from './error';
import { SessionStorageKey, getSessionStorageItem, hasSessionStorageItem } from './sessionStorage';

// let dialogShownTimeout: any;

const setTag = (event: Sentry.Event, key: string, value: string) => {
  if (!event.tags) {
    event.tags = {};
  }
  event.tags[key] = value;
};

export const registerSentry = () => {
  // initialize error tracking in production
  if ((env.APP_ENV === 'production' || env.APP_ENV === 'staging') && window.location.hostname !== 'localhost') {
    Sentry.init({
      dsn: 'https://c0b499e1dbf54228bed9b33ee32b207b@o422067.ingest.sentry.io/5343700',
      environment: env.APP_ENV,
      normalizeDepth: 10,
      beforeSend(event, hint) {
        if (env.APP_VERSION) {
          setTag(event, 'version', env.APP_VERSION);
        }

        // if fullstory has loaded, track the fullstory session url with the error
        if (window.FS?.getCurrentSessionURL) {
          setTag(event, 'fs_url', window.FS.getCurrentSessionURL(/* withTimestamp */ true));
        }

        if (hasSessionStorageItem(SessionStorageKey.SessionId)) {
          setTag(event, 'session_id', getSessionStorageItem(SessionStorageKey.SessionId) ?? '');
        }

        if (hint?.originalException instanceof HttpError) {
          setTag(event, 'request_id', hint?.originalException.requestId ?? '');
        }

        return event;
      },
      ignoreErrors: [
        // Fired by antd components and is safe to ignore
        // see https://github.com/ant-design/ant-design/issues/26621
        /^ResizeObserver loop (limit exceeded|completed with undelivered notifications)/,
        // Fired when user's browser disconnects, safe to ignore
        /^NetworkError: Internet disconnected/,
      ],
    });
  }
};
