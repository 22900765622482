import React from 'react';

import { ActionButton } from 'components/Button/ActionButton';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { showAsyncModal } from 'utils/asyncModal';
import { pluralize } from 'utils/formatting';

import { OrderLineItemSAPB1 } from 'types/hash-state';

import { LineItemActionsSAPB1 } from '../../types';
import { ItemLotsModal } from './ItemLotsModal';

export function LotProperties({
  lineItem,
  lineItemActions,
  lineItemIdx,
  locationId,
}: {
  lineItem: OrderLineItemSAPB1;
  lineItemActions: LineItemActionsSAPB1;
  lineItemIdx: number;
  locationId?: string;
}) {
  const quantityAllocated = lineItem.lotsSelected?.reduce((acc, lot) => acc + lot.quantity, 0) || 0;
  const quantityToAllocate = lineItem.quantity || 0;

  const props: LabelValueProperty[] = [
    {
      label: pluralize(lineItem.lotsSelected?.length || 0, 'Lot', 'Lots'),
      value: (
        <>
          {lineItem.lotsSelected
            ? lineItem.lotsSelected.length === 1
              ? lineItem.lotsSelected[0].lotId
              : `${lineItem.lotsSelected.length} Selected`
            : `None Selected`}
          {quantityToAllocate > quantityAllocated ? (
            <InfoTooltip
              title={`${pluralize(quantityToAllocate - quantityAllocated, 'unit', 'units', true)}  unallocated`}
              useInfoIcon
            />
          ) : null}
          {` | `}
          <ActionButton
            label="Edit"
            onClick={async () => {
              const selectedLots = await showAsyncModal(ItemLotsModal, { lineItem, locationId });
              if (selectedLots) {
                lineItemActions.updateLineItem(lineItemIdx, { lotsSelected: selectedLots });
              }
            }}
          />
        </>
      ),
    },
  ];

  return <LabelValueProperties properties={props} />;
}
