import React, { useEffect } from 'react';

import { DeleteOutlined, DownOutlined, CopyOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Menu } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import * as Styled from 'pages/orders/quotes/QuoteEditFlowP21/QuoteLineItems/QuoteLineItems.style';

import { AsyncSelect } from 'components/AsyncSelect';
import { LabelContainer, LabelTitle } from 'components/AsyncSelect/AsyncSelect.style';
import { useItemsWithDescSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { NULLIFY_API_CALL, useCoreApi, useLegacyApi } from 'hooks/useApi';

import { formatUSD } from 'utils/formatting';
import { encodeLegacyApiParam } from 'utils/routes';
import { typedColumn } from 'utils/tables';

import { ItemCostInfo } from 'types/legacy-api';

import { AssemblyComponent, AssemblyComponentWithInfo, LineComponentItemActions } from './AssemblyTypes';

type UpdateLineComponentItemFn = (lineItem: Partial<AssemblyComponent>) => void;

function validateQuantity(lineComponentItem: AssemblyComponentWithInfo): string | null {
  if (lineComponentItem.itemId && !lineComponentItem.isLoading) {
    const { quantity } = lineComponentItem;
    if (typeof quantity === 'number' && Number.isFinite(quantity)) {
      if (quantity < 0) {
        return 'Quantity must be greater than 0';
      }
    } else {
      return 'Quantity is required';
    }
  }
  return null;
}

function renderDetails(details: React.ReactChild, color: string, tooltip?: string) {
  const typography = (
    <Typography
      style={{
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        padding: '4px 0 4px 0',
        color,
      }}
    >
      {details}
    </Typography>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{typography}</Tooltip>;
  }

  return typography;
}

function renderError(error: string | null, tooltip?: string) {
  return error && renderDetails(error, theme.colors.danger[500], tooltip);
}

export function getLineComponentItemColumns({
  selectedLineItemRowIdx,
  lineComponentItems,
  lineComponentItemActions,
  locationId,
  companyId,
  tenantId,
}: {
  selectedLineItemRowIdx: number | undefined;
  lineComponentItems: AssemblyComponentWithInfo[];
  lineComponentItemActions: LineComponentItemActions;
  locationId: string | undefined;
  companyId: string;
  tenantId: string;
}) {
  const lineComponentItemColumns: ColumnType<AssemblyComponentWithInfo>[] = [
    typedColumn({
      title: '#',
      render: (_, lineItem, index) => (
        <td>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: ${selectedLineItemRowIdx === index ? theme.colors.primary[600] : `transparent`};
            `}
          />
          <span>{index + 1}</span>
        </td>
      ),
    }),
    typedColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      width: '15%',
      render: (_, lineComponentItem, index) => (
        <ColumnSelectItemId
          lineComponentItem={lineComponentItem}
          updateLineComponentItem={(_lineComponentItem) =>
            lineComponentItemActions.updateLineItem(index, _lineComponentItem)
          }
        />
      ),
    }),
    typedColumn({
      title: 'Item Name',
      dataIndex: 'itemName',
      render: (_, item) => <ColumnItemDescription lineComponentItem={item} />,
    }),
    typedColumn({
      title: 'Quantity Per Assembly',
      dataIndex: 'quantity',
      width: '15%',
      render: (_, lineComponentItem, index) => (
        <ColumnItemQuantity
          lineComponentItem={lineComponentItem}
          updateLineComponentItem={(_lineComponentItem) =>
            lineComponentItemActions.updateLineItem(index, _lineComponentItem)
          }
        />
      ),
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      width: '8%',
      render: (_, lineComponentItem, index) => (
        <ColumnItemUOM
          lineComponentItem={lineComponentItem}
          updateLineComponentItem={(_lineComponentItem) =>
            lineComponentItemActions.updateLineItem(index, _lineComponentItem)
          }
        />
      ),
    }),

    typedColumn({
      title: 'Unit Cost',
      width: '15%',
      render: (_, lineComponentItem) => (
        <ColumnItemCost
          lineComponentItem={lineComponentItem}
          locationId={locationId}
          companyId={companyId}
          tenantId={tenantId}
        />
      ),
    }),

    typedColumn({
      align: 'center',
      title: '',
      width: '60px',
      render: (_, lineComponentItem, lineItemIdx) => (
        <ColumnActions
          lineItemIdx={lineItemIdx}
          numLineItems={lineComponentItems.length}
          lineComponentItemActions={lineComponentItemActions}
        />
      ),
    }),
  ];

  return lineComponentItemColumns;
}

function ColumnSelectItemId({
  lineComponentItem,
  updateLineComponentItem,
}: {
  lineComponentItem: AssemblyComponentWithInfo;
  updateLineComponentItem: UpdateLineComponentItemFn;
}) {
  return (
    <Styled.TableCell>
      {
        <AsyncSelect
          selectProps={useItemsWithDescSelectProps({})}
          entityPlural="items"
          value={lineComponentItem.itemId}
          onSelect={(selectedId: string, option: any) =>
            // reset fields when selecting new line item
            updateLineComponentItem({
              itemName: option.name,
              itemId: selectedId,
              componentItemUid: option.invMastUid,
              isAssembly: option.isAssembly,
              quantity: 1,
              unitOfMeasure: undefined,
            })
          }
          dropdownMatchSelectWidth={550}
        />
      }
    </Styled.TableCell>
  );
}

function ColumnItemDescription({ lineComponentItem }: { lineComponentItem: AssemblyComponentWithInfo }) {
  return (
    <Styled.TableCell>
      <Styled.TextSpacer />
      <Typography>{lineComponentItem.itemName}</Typography>
      <Styled.TextSpacer />
    </Styled.TableCell>
  );
}

function ColumnItemQuantity({
  lineComponentItem,
  updateLineComponentItem,
}: {
  lineComponentItem: AssemblyComponentWithInfo;
  updateLineComponentItem: UpdateLineComponentItemFn;
}) {
  const updateQuantity = (quantity: number | string) => {
    if (typeof quantity === 'string') {
      quantity = (quantity !== '' ? Number(quantity) : '') as number;
    }

    updateLineComponentItem({ quantity });
  };

  const error = validateQuantity(lineComponentItem);

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={lineComponentItem.quantity}
        onChange={(ev) => updateQuantity(ev.target.value)}
        disabled={!lineComponentItem.itemId}
        min={1}
      />
      <Styled.TableCellDetail>{renderError(error)}</Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnItemUOM({
  lineComponentItem,
  updateLineComponentItem,
}: {
  lineComponentItem: AssemblyComponentWithInfo;
  updateLineComponentItem: UpdateLineComponentItemFn;
}) {
  const { data: unitOfMeasuresData, isLoading: unitOfMeasuresIsLoading } = useCoreApi(
    schemas.items.getItemUnitOfMeasuresV2,
    lineComponentItem.itemId
      ? {
          pathParams: { itemId: lineComponentItem.itemId },
        }
      : null,
  );

  useEffect(() => {
    if (unitOfMeasuresData && lineComponentItem.itemId && !lineComponentItem.unitOfMeasure) {
      // We need to determine if this is the Sale or Purchase Default
      const defaultUom = unitOfMeasuresData.items.find((uom) => uom.isSaleDefault);
      if (defaultUom) {
        updateLineComponentItem({ unitOfMeasure: defaultUom.unitOfMeasure });
      }
    }
  }, [lineComponentItem, unitOfMeasuresData, updateLineComponentItem]);

  return (
    <Styled.TableCell>
      <Select
        value={lineComponentItem.unitOfMeasure}
        onChange={(value) => updateLineComponentItem({ unitOfMeasure: value })}
        options={(unitOfMeasuresData?.items || []).map((uom, idx) => ({
          value: uom.unitOfMeasure,
          label: (
            <LabelContainer key={idx}>
              <LabelTitle>{uom.unitOfMeasure}</LabelTitle>
            </LabelContainer>
          ),
        }))}
        disabled={!lineComponentItem.itemId}
        isLoading={lineComponentItem.isLoading || unitOfMeasuresIsLoading}
      />
    </Styled.TableCell>
  );
}

function ColumnItemCost({
  // display only
  lineComponentItem,
  locationId,
  tenantId,
  companyId,
}: {
  lineComponentItem: AssemblyComponentWithInfo;
  locationId: string | undefined;
  tenantId: string;
  companyId: string;
}) {
  const { data: costData } = useLegacyApi<ItemCostInfo>(
    lineComponentItem.componentItemUid
      ? `/v4/items/${encodeLegacyApiParam(lineComponentItem.componentItemUid)}/cost`
      : NULLIFY_API_CALL,
    {
      company_id: companyId,
      tenant_id: tenantId,
      location_id: locationId,
    },
  );
  const unitCost = costData?.unitCost?.amount;
  return <Styled.TableCell>{formatUSD(unitCost, true)}</Styled.TableCell>;
}

function ColumnActions({
  lineItemIdx,
  numLineItems,
  lineComponentItemActions: actions,
}: {
  lineItemIdx: number;
  numLineItems: number;
  lineComponentItemActions: LineComponentItemActions;
}) {
  return (
    <Styled.TableCell>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {lineItemIdx > 0 && (
              <Menu.Item
                icon={<ArrowUpOutlined />}
                onClick={() => {
                  actions.moveLineItemUp(lineItemIdx);
                }}
              >
                Move Up
              </Menu.Item>
            )}
            {lineItemIdx + 1 < numLineItems && (
              <Menu.Item
                icon={<ArrowDownOutlined />}
                onClick={() => {
                  actions.moveLineItemDown(lineItemIdx);
                }}
              >
                Move Down
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item
              icon={<CopyOutlined />}
              onClick={() => {
                actions.duplicateLineItem(lineItemIdx);
              }}
            >
              Duplicate
            </Menu.Item>
            <Menu.Item
              className={css`
                color: ${theme.colors.danger[500]};
              `}
              icon={<DeleteOutlined />}
              onClick={() => {
                actions.deleteLineItem(lineItemIdx);
              }}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Button icon={<DownOutlined />} style={{ width: '50px' }} />
      </Dropdown>
    </Styled.TableCell>
  );
}
