import { PurchaseTargetLineStatus, TransferTargetReviewMethods } from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseTargetLineDTO } from '@recurrency/core-api-schema/dist/purchasing/getPurchaseTargetLines';

import { MultiSelectOption } from 'components/AsyncSelect/types';

import { arrUnique } from 'utils/array';
import { truthy } from 'utils/boolean';
import { formatIdNameObj, pluralize } from 'utils/formatting';

import { GenericTargetLineDTO, GenericTargetLineRow } from '../types';

export const allStatuses = Object.values(PurchaseTargetLineStatus);
export const nonStockStatuses = allStatuses.filter((s) => s !== PurchaseTargetLineStatus.Nonstock);

export function focusedRowClassName(record: GenericTargetLineRow, focusedLine?: GenericTargetLineRow) {
  return [
    'focusable-row',
    record.key === focusedLine?.key ? 'focused-row' : '',
    record.userQtyToOrder > 0 ? 'purchasing-row' : '',
  ]
    .filter(truthy)
    .join(' ');
}

export function getUniqueItemGroupOptions(targetLines: GenericTargetLineDTO[]) {
  const options: MultiSelectOption[] = arrUnique(
    targetLines.filter((tl) => !!tl.itemGroupId),
    'itemGroupId',
  ).map((tl) => ({
    value: tl.itemGroupId!,
    label: formatIdNameObj({ foreignId: tl.itemGroupId!, name: tl.itemGroupName ?? '' }),
  }));

  return options;
}

export function getTransferKey(record: PurchaseTargetLineDTO): string {
  return `${record.supplierId}|${record.locationId}|${record.itemId}`;
}

export const daysOfWeekDef = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
];

export function reviewScheduleToReadable(schedule: string, method: TransferTargetReviewMethods, detailed?: boolean) {
  if (schedule === '') return '-';
  const daysOfWeekAbr = daysOfWeekDef.map((day) => day.label);
  const [days, cron, weeks] = schedule.split('-');
  if (method === TransferTargetReviewMethods.Cycle) {
    return `Every ${days} days`;
  }
  const daysOfWeek = cron.split(' ')[4]?.split(',');
  if (!daysOfWeek) return '-';
  if (detailed) {
    const daysOfWeekNames = daysOfWeek.map((day) => daysOfWeekAbr[parseInt(day, 10)]).join(', ');
    return `${daysOfWeekNames} every ${pluralize(parseInt(weeks, 10), 'week', 'weeks', weeks !== '1')}`;
  }
  if (weeks === '1') {
    return `${pluralize(daysOfWeek.length, 'day', 'days', true)} per week`;
  }
  return `Every ${weeks} weeks`;
}
