import { ItemAvailabilityDTO } from '@recurrency/core-api-schema/dist/items/getItemAvailability';
import { WorkflowState, RecommendedQuoteLineDTO } from '@recurrency/core-api-schema/dist/ml/getRecommendedQuotes';

import { truthy } from 'utils/boolean';
import { captureError } from 'utils/error';
import { formatNumber, formatUSD } from 'utils/formatting';
import { qtyUnitConverter } from 'utils/units';

export const workflowStateName: Record<WorkflowState, string> = {
  [WorkflowState.NeedsReview]: 'Needs Review',
  [WorkflowState.Open]: 'Open',
  [WorkflowState.Won]: 'Won',
  [WorkflowState.Lost]: 'Lost',
  [WorkflowState.Rejected]: 'Removed',
};

export function getRecLastOrderText(lineItem: RecommendedQuoteLineDTO, shouldShowGM: boolean): string {
  return [
    `Order ${formatNumber(lineItem.lastQty / lineItem.lastUnitSize)} ${lineItem.unitOfMeasure} at ${formatUSD(
      lineItem.lastUnitPrice,
      true,
    )}/${lineItem.unitOfMeasure || 'unit'} for a total of ${formatUSD(lineItem.lastExtPrice)}.`,
    shouldShowGM &&
      `Gross margin of ${formatUSD(lineItem.lastGrossMargin)} (${Math.round(
        (lineItem.lastGrossMargin / lineItem.lastExtPrice) * 100,
      )}%).`,
  ]
    .filter(truthy)
    .join(' ');
}

export function getRecReasonText(lineItem: RecommendedQuoteLineDTO): string {
  const { mlRationale } = lineItem;
  let reasonText = '';
  if (mlRationale.isSeasonal) {
    reasonText = `Seasonally ordered in ${mlRationale.seasonQ} for ${mlRationale.distinctSeasonYears} years.`;
  } else if (mlRationale.isFrequent) {
    reasonText = `Ordered every ${mlRationale.medianFreqDays} days on average.`;
  }
  // recommendations are either seasonal or frequent, will never be empty string
  return reasonText;
}

export function getAvailabilityText(
  locationAvailabilities: ItemAvailabilityDTO[],
  locationId: string,
  itemUid: string,
): string {
  const locAvail = locationAvailabilities.find((locAv) => locAv.locationId === locationId);
  if (!locAvail) {
    // we should never get into this state, unless the the data is incorrect
    captureError(new Error(`Item availability for itemUid: ${itemUid}, location: ${locationId} not found`));
    return `Item not stocked`;
  }

  const unitOfMeasure = locAvail.unitOfMeasure || '';
  const locQtyAvailable = locAvail ? qtyUnitConverter(locAvail.quantityAvailable, locAvail.unitSize) : 0;
  if (locQtyAvailable > 0) {
    return `${formatNumber(locQtyAvailable)} ${unitOfMeasure} available in ${locAvail.locationName}.`;
  }

  const totalQtyAvailable = qtyUnitConverter(
    locationAvailabilities.reduce((qtySum, locAv) => qtySum + locAv.quantityAvailable, 0),
    locAvail.unitSize,
  );
  if (totalQtyAvailable) {
    return `Out of stock in ${locAvail.locationName}, ${formatNumber(
      totalQtyAvailable,
    )} ${unitOfMeasure} in other locations.`;
  }

  return `Out of stock in all locations.`;
}
