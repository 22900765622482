import React from 'react';

import { css } from '@emotion/css';

import { Sparkline } from 'components/Sparkline';
import { getMonthlyFilledSeries } from 'components/Sparkline/utils';

export function QtyByMonthSparkline<AggItemT>({
  data,
  dateField,
  quantityField,
  seriesTitle,
}: {
  data: AggItemT[] | undefined;
  dateField: keyof AggItemT;
  quantityField: keyof AggItemT;
  seriesTitle?: string;
}) {
  return data && data.length > 0 ? (
    <Sparkline
      className={css`
        margin-bottom: 12px;
      `}
      height={32}
      series={getMonthlyFilledSeries({
        data,
        dateField,
        valueField: quantityField,
        numPrevYears: 2,
        title: seriesTitle,
        formatFn: (val) => String(val),
      })}
    />
  ) : null;
}
