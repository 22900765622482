import React, { useRef } from 'react';

import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { SelectProps } from 'antd/lib/select';
import { theme } from 'theme';

import { UseAsyncSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { NewItemModal } from 'components/recipes/NewItemModal';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { searchIndexItemByIdCache } from 'utils/search/search';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { OrderType, track, TrackEvent } from 'utils/track';

import { ForeignIdNameStr } from 'types/hash-state';

import { SmallLoader } from '../Loaders';
import { Select, SelectOption } from '../Select';

export const tableFilterSelectClassName = css`
  width: 300px;
  display: block;
`;

export const tableFilterSelectEqualWidthClassName = css`
  ${tableFilterSelectClassName}
  flex: 1;
`;

export enum AsyncSelectVariant {
  Search = 'search',
  Filter = 'filter',
}

interface AsyncSelectProps extends SelectProps<any> {
  selectProps: UseAsyncSelectProps | (() => UseAsyncSelectProps);
  entityPlural: string;
  variant?: 'search' | 'filter';
  company?: ForeignIdNameStr;
  orderType?: OrderType;
  location?: ForeignIdNameStr;
}

const newItemOptionValue = 'newItem';
const newAssemblyOptionValue = 'newAssembly';

export function AsyncItemSelect({
  selectProps,
  entityPlural,
  placeholder,
  onSelect,
  variant = AsyncSelectVariant.Search,
  company,
  orderType = undefined,
  location,
  ...otherProps
}: AsyncSelectProps) {
  // selectProps could be passed as a hook function, resolve to obj
  let selectPropsObj = typeof selectProps === 'function' ? selectProps() : selectProps;

  const prevOptionsRef = useRef<SelectOption[]>();
  if (selectPropsObj.isLoading && prevOptionsRef.current) {
    selectPropsObj = { ...selectPropsObj, options: prevOptionsRef.current, isLoading: false };
  } else if (!selectPropsObj.isLoading) {
    prevOptionsRef.current = selectPropsObj.options;
  }

  const { activeUser, activeTenant } = useGlobalApp();
  const newItemOption: SelectOption = {
    value: newItemOptionValue,
    label: (
      <>
        <div
          className={css`
            margin-top: 6px;
            margin-bottom: 16px;
            border-top: 1px solid ${theme.colors.primary[300]};
          `}
        />
        <div
          className={css`
            display: flex;
            gap: 4px;
            color: ${theme.colors.link[500]};
            align-items: center;
          `}
        >
          <PlusOutlined />
          <Typography style={{ color: theme.colors.link[500] }} type="subtitle">
            New Item
          </Typography>
        </div>
      </>
    ),
  };
  const newAssemblyOption: SelectOption = {
    value: newAssemblyOptionValue,
    label: (
      <>
        <div
          className={css`
            display: flex;
            gap: 4px;
            color: ${theme.colors.link[500]};
            align-items: center;
          `}
        >
          <PlusOutlined />
          <Typography style={{ color: theme.colors.link[500] }} type="subtitle">
            New Assembly
          </Typography>
        </div>
      </>
    ),
  };

  return (
    <>
      <Select
        loading={selectPropsObj.isLoading}
        options={
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateItem)
            ? shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateAssemblyItem)
              ? [...selectPropsObj.options, newItemOption, newAssemblyOption]
              : [...selectPropsObj.options, newItemOption]
            : [...selectPropsObj.options]
        }
        placeholder={
          placeholder ||
          (variant === AsyncSelectVariant.Search ? (
            `Search ${entityPlural}`
          ) : (
            <>
              <FilterOutlined
                className={css`
                  margin-right: 4px;
                `}
              />
              Filter by {entityPlural}...
            </>
          ))
        }
        notFoundContent={selectPropsObj.isLoading ? <SmallLoader /> : `No ${entityPlural} found`}
        showSearch
        filterOption={false}
        onSearch={selectPropsObj.setSearchQuery}
        onSelect={async (value, option) => {
          if (value === newItemOptionValue || value === newAssemblyOptionValue) {
            const modalData = await showAsyncModal(NewItemModal, {
              initialValues: {
                itemId: selectPropsObj.searchQuery,
                company,
                location,
              },
              orderType,
              assemblyItem: value === newAssemblyOptionValue,
            });
            if (modalData?.newData) {
              const itemData = modalData.newData;
              const submitNotification = createSubmissionNotification({
                entityName: 'Item',
                expectedWaitSeconds: 30,
                erpType: activeTenant.erpType,
              });
              try {
                const response = await coreApiFetch(schemas.items.createItem, { bodyParams: itemData });

                track(TrackEvent.Items_CreateItem_Submit, {
                  itemId: response.data.itemId,
                  itemUid: response.data.itemUid,
                });

                // remove the created Item ID from the algolia cache because new algolia results are required.
                searchIndexItemByIdCache.delete(response.data.itemId);
                submitNotification.success();

                onSelect?.(response.data.itemId, {
                  label: response.data.itemName,
                  value: response.data.itemId,
                  name: response.data.itemName,
                  description: response.data.itemDescription,
                });
              } catch (err) {
                submitNotification.error(err);
              }
              return;
            }
            if (modalData?.existingData) {
              const itemData = modalData.existingData;
              onSelect?.(itemData.item_id, {
                label: itemData.item_desc,
                value: itemData.item_id,
                name: itemData.item_desc,
                description: itemData.extended_desc,
              });
              return;
            }
          }

          selectPropsObj.setSearchQuery('');
          selectPropsObj.onSelect?.(value);
          onSelect?.(value, option);
        }}
        {...otherProps}
      />
    </>
  );
}
