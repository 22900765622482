import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DocumentsTable } from 'components/recipes/DocumentsTable';

export const QuoteDocumentsTable = ({ salesQuoteId }: { salesQuoteId: string }) => (
  <DocumentsTable
    tableProps={useCoreApiTableProps({
      schema: schemas.salesQuotes.getSalesQuoteDocuments,
      pathParams: { salesQuoteId },
    })}
  />
);
