import React from 'react';

import { css } from '@emotion/css';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { Form, Radio, Typography } from 'antd';

import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';

import { EMPTY_VALUE_DASHES } from 'utils/formatting';

export interface ItemSetupMinMaxRadioGroupProps<T> {
  record?: SearchPlanningDTO;
  value: T;
  options: { recommended: T; custom: T };
  onChange: (newVal: T) => void;
  customMin?: number;
  customMax?: number;
  setCustomMin: (newVal: number) => void;
  setCustomMax: (newVal: number) => void;
}

export function ItemSetupMinMaxRadioGroup<T>({
  record,
  value,
  options,
  customMin,
  customMax,
  setCustomMin,
  setCustomMax,
  onChange,
}: ItemSetupMinMaxRadioGroupProps<T>) {
  const isCustomMinMax = value === options.custom;

  return (
    <RadioGroup
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
      className={css`
        margin-top: 10px;
      `}
    >
      <FlexSpace direction="column" gap={20} fullWidth>
        <Radio value={options.recommended}>
          <FlexSpace gap={10} direction="column" fullWidth>
            <b>Use Recommended Min/Max</b>
            <Typography.Paragraph>
              Set up this item using Recurrency’s recommended Min/Max. This will update the replenishment method to be
              Min/Max and update the minimum and maximum values to be Recurrency’s recommended.
            </Typography.Paragraph>
          </FlexSpace>

          {record && (
            <FlexSpace gap={1} direction="column" fullWidth>
              <div className={optionWrapperCss}>
                <div className={valuesWrapperCss}>
                  <PropertyListItem label="Min" value={record.recommended_min ?? EMPTY_VALUE_DASHES} />
                </div>

                <div className={valuesWrapperCss}>
                  <PropertyListItem label="Max" value={record.recommended_max ?? EMPTY_VALUE_DASHES} />
                </div>
              </div>
            </FlexSpace>
          )}
        </Radio>

        <Radio value={options.custom}>
          <FlexSpace gap={10} direction="column" fullWidth>
            <b>Use Custom Min/Max</b>
            <Typography.Paragraph>
              Set up this item using a custom Min/Max. This will update the replenishment method to be Min/Max and
              update the minimum and maximum values to be a specified custom value. Recurrency will continue to generate
              recommendations that can be reviewed in the Planning page.
            </Typography.Paragraph>
          </FlexSpace>

          <FlexSpace gap={1} direction="column" fullWidth>
            <div className={optionWrapperCss}>
              <div className={valuesWrapperCss}>
                <PropertyListItem
                  label="Min"
                  value={
                    <Form.Item
                      className={noMarginCss}
                      name="custom_min"
                      rules={[
                        {
                          required: isCustomMinMax,
                          message: 'Target value is required.',
                        },
                      ]}
                    >
                      <Input
                        value={customMin}
                        onChange={(ev) => setCustomMin(Number(ev.target.value))}
                        defaultValue={record?.recommended_min}
                        type="number"
                        min={0}
                        disabled={!isCustomMinMax}
                      />
                    </Form.Item>
                  }
                />
              </div>

              <div className={valuesWrapperCss}>
                <PropertyListItem
                  label="Max"
                  value={
                    <Form.Item
                      className={noMarginCss}
                      name="custom_max"
                      rules={[
                        {
                          required: isCustomMinMax,
                          message: 'Target type is required.',
                        },
                      ]}
                    >
                      <Input
                        value={customMax}
                        onChange={(ev) => setCustomMax(Number(ev.target.value))}
                        defaultValue={record?.recommended_max}
                        type="number"
                        min={0}
                        disabled={!isCustomMinMax}
                      />
                    </Form.Item>
                  }
                />
              </div>
            </div>
          </FlexSpace>
        </Radio>
      </FlexSpace>
    </RadioGroup>
  );
}

const optionWrapperCss = css`
  display: flex;
  gap: 20px;
  & > * {
    flex: 1;
  }
  width: 75%;
`;

const valuesWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 36%;
`;

const noMarginCss = css`
  margin: 0;
`;
