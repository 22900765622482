import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { objRemoveNilValues } from 'utils/object';

import { AuditLog } from './AuditLog';

interface ItemLocationSettingsAuditLogProps {
  itemUid: string;
  locationId: string;
  field?: string;
}

export const ItemLocationSettingsAuditLog = ({ itemUid, locationId, field }: ItemLocationSettingsAuditLogProps) => (
  <AuditLog
    endpoint={schemas.auditLog.getItemLocationSettingsAuditLog}
    queryParams={{
      filter: objRemoveNilValues({
        itemUid,
        locationId,
        field: field ?? undefined,
      }),
    }}
  />
);
