import React, { useMemo } from 'react';

import { Checkbox } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { Table } from 'components/Table';

import { formatNumber, formatUSD } from 'utils/formatting';
import { sortDirections, typedColumn } from 'utils/tables';

import { PurchasingTOFinalizeInput } from '../../../../../types/hash-state';
import { TransferSummary } from '../../types';
import { getTransferSummaryKey } from '../../utils';
import { CarrierCell } from './PurchasingFinalizeStepCarrierCell';

export function PurchasingFinalizeTransfersSection({
  transferSummaries,
  toFinalizeInputByKey,
  onTOFinalizeInputChange,
}: {
  transferSummaries: TransferSummary[];
  toFinalizeInputByKey: Obj<PurchasingTOFinalizeInput>;
  onTOFinalizeInputChange: (newTOFinalizeInputByKey: Obj<PurchasingTOFinalizeInput>) => void;
}) {
  const numTotalTOs = transferSummaries.length;
  const numApprovedTOs = useMemo(
    () =>
      transferSummaries.filter(
        (summary) =>
          toFinalizeInputByKey[
            getTransferSummaryKey(summary.sourceLocation.foreignId, summary.destinationLocation.foreignId)
          ]?.approved,
      ).length,
    [toFinalizeInputByKey, transferSummaries],
  );

  const updateTOHeader = (record: TransferSummary, update: Partial<PurchasingTOFinalizeInput>) => {
    const newTOFinalizeInputByKey = { ...toFinalizeInputByKey };
    const toKey = getTransferSummaryKey(record.sourceLocation.foreignId, record.destinationLocation.foreignId);
    newTOFinalizeInputByKey[toKey] = { ...newTOFinalizeInputByKey[toKey], ...update };
    onTOFinalizeInputChange(newTOFinalizeInputByKey);
  };

  const handleBulkApproveChange = (isChecked: boolean) => {
    const newTOFinalizeInputByKey = { ...toFinalizeInputByKey };

    // Update the 'approved' state for each TO
    transferSummaries.forEach((toSummary) => {
      const toKey = getTransferSummaryKey(toSummary.sourceLocation.foreignId, toSummary.destinationLocation.foreignId);
      const toFinalizeInput = newTOFinalizeInputByKey[toKey] || {};
      toFinalizeInput.approved = isChecked;
      newTOFinalizeInputByKey[toKey] = toFinalizeInput;
    });

    onTOFinalizeInputChange(newTOFinalizeInputByKey);
  };

  const tableColumns: ColumnType<TransferSummary>[] = [
    typedColumn({
      title: 'Source Location',
      dataIndex: 'sourceLocation',
      width: `300px`,
      sortDirections,
      render: (fromLocation) => (
        <div>
          <div>{fromLocation.foreignId}</div>
          <div>{fromLocation.name}</div>
        </div>
      ),
    }),
    typedColumn({
      title: 'Destination Location',
      dataIndex: 'destinationLocation',
      width: `300px`,
      sortDirections,
      render: (purchasingLocation) => (
        <div>
          <div>{purchasingLocation.foreignId}</div>
          <div>{purchasingLocation.name}</div>
        </div>
      ),
    }),
    typedColumn({
      title: 'Carrier',
      dataIndex: 'carrier',
      width: `250px`,
      sortDirections,
      render: (carrier, record) => (
        <CarrierCell value={carrier} onChange={(newCarrier) => updateTOHeader(record, { carrier: newCarrier })} />
      ),
    }),
    typedColumn({
      title: () => (
        <div>
          Approved
          <Checkbox
            style={{ marginLeft: 8 }}
            checked={numApprovedTOs > 0 && numApprovedTOs === numTotalTOs}
            indeterminate={numApprovedTOs > 0 && numApprovedTOs !== numTotalTOs}
            onChange={(e) => handleBulkApproveChange(e.target.checked)}
          />
        </div>
      ),
      dataIndex: 'approved',
      sortDirections,
      align: 'right',
      render: (approved, record) => (
        <Checkbox checked={approved} onChange={() => updateTOHeader(record, { approved: !approved })} />
      ),
    }),
    typedColumn({
      title: 'Transfer Days',
      dataIndex: 'transferDays',
      sortDirections,
      align: 'right',
      render: (transferDays) => (Number.isFinite(transferDays) ? formatNumber(transferDays) : '-'),
    }),
    typedColumn({
      title: 'Total Lines',
      dataIndex: 'totalLines',
      sortDirections,
      align: 'right',
      render: (totalLines) => formatNumber(totalLines),
    }),
    typedColumn({
      title: 'Total Cost',
      dataIndex: 'totalCost',
      sortDirections,
      align: 'right',
      render: (totalCost) => formatUSD(totalCost, true),
    }),
  ];

  return (
    <div>
      <Table columns={tableColumns} data={transferSummaries} verticalAlign="middle" />
    </div>
  );
}
