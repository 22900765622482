import React from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import moment, { Moment } from 'moment';

import { MultiSelectOption } from 'components/AsyncSelect/types';
import { Button } from 'components/Button';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { Modal } from 'components/Modal';
import { ItemLocationSettingsAuditLog } from 'components/recipes/AuditLog/ItemLocationSettingsAuditLog';
import { Select } from 'components/Select';
import { Tabs } from 'components/Tabs';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatDate, splitIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';

import { ISODateStr } from 'types/hash-state';

import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import { MultiModalSelection } from './utils';

export function IncludeExcludeModal({
  selection,
  exclude,
  onClose,
}: {
  selection: MultiModalSelection;
  exclude: boolean;
  onClose: (confirmData?: { excludedUntil?: ISODateStr }) => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const shouldAllowExcludedUntil = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeaturePlanningExcludedUntil,
  );
  const [excludedUntil, setExcludedUntil] = React.useState<ISODateStr | undefined>(undefined);
  const [shouldShowCustomDatePicker, setShouldShowCustomDatePicker] = React.useState(false);

  const isSingleRecord = selection.type === 'list' && selection.items.length === 1;
  const title = `${exclude ? 'Exclude' : 'Include'} ${
    isSingleRecord
      ? `Item #${selection.items[0].item_id} at Location ${selection.items[0].location}`
      : `${selection.count} Items`
  }`;

  const getIsoDateFromToday = (amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2) =>
    moment().startOf('day').add(amount, unit).toISOString();

  const FOREVER_VALUE = '';
  const CUSTOM_VALUE = 'custom';
  const excludedUntilOptions: MultiSelectOption[] = [
    { value: FOREVER_VALUE, label: 'Forever' },
    { value: getIsoDateFromToday(1, 'day'), label: '1 day' },
    { value: getIsoDateFromToday(1, 'week'), label: '1 week' },
    { value: getIsoDateFromToday(1, 'month'), label: '1 month' },
    { value: getIsoDateFromToday(6, 'months'), label: '6 months' },
    { value: CUSTOM_VALUE, label: 'Custom' },
  ];

  function onSelectExcludedUntilOption(value: string) {
    if (value === CUSTOM_VALUE) {
      setShouldShowCustomDatePicker(true);
      if (!excludedUntil) setExcludedUntil(moment().add(1, 'day').toISOString());
    } else {
      setShouldShowCustomDatePicker(false);
      setExcludedUntil(value || undefined);
    }
  }

  const isFutureDate = (date: Moment) =>
    // Can not select days before tomorrow
    date && date < moment().add(1, 'day').startOf('day');

  return (
    <Modal
      visible
      title={title}
      onCancel={() => onClose()}
      width={900}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            onClick={() => {
              onClose({ excludedUntil });
            }}
            type="primary"
          >
            {exclude ? 'Exclude' : 'Include'}
          </Button>
        </>
      }
    >
      <>
        <span>
          Status will be set as{' '}
          {exclude
            ? `Excluded, and will not be set as Needs Review ${
                excludedUntil ? `until ${formatDate(excludedUntil)}` : `in the future`
              }.`
            : 'Needs Review or OK in the future.'}
        </span>
        {shouldAllowExcludedUntil && exclude && (
          <FlexSpace
            direction="row"
            gap={8}
            alignItems="center"
            className={css`
              margin-top: 10px;
            `}
          >
            <span>Exclude for </span>
            <Select
              className={css`
                width: 140px;
              `}
              size="small"
              value={shouldShowCustomDatePicker ? CUSTOM_VALUE : excludedUntil || FOREVER_VALUE}
              onSelect={onSelectExcludedUntilOption}
              options={excludedUntilOptions}
              placeholder="Select duration"
            />
            {shouldShowCustomDatePicker && (
              <IsoDatePicker
                className={css`
                  height: 32px;
                `}
                disabledDates={isFutureDate}
                onChange={setExcludedUntil}
                picker="date"
                value={excludedUntil}
                allowClear={false}
                showTime={false}
                size="small"
              />
            )}
          </FlexSpace>
        )}
        <DividerLine />
        <MultiUpdateReplenishmentSettingsTable
          selection={selection}
          bulkUpdate={{
            type: 'includeExclude',
            exclude,
          }}
        />
        {isSingleRecord && (
          <>
            <DividerLine marginTop={42} />
            <Tabs
              tabs={[
                {
                  header: 'Audit Log',
                  infoTooltip: `Excluded updates for Item #${selection.items[0].item_id} at Location #${selection.items[0].location}`,
                  content: (
                    <ItemLocationSettingsAuditLog
                      itemUid={selection.items[0].item_uid}
                      locationId={splitIdNameStr(selection.items[0].location).foreignId}
                      field="excluded"
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </>
    </Modal>
  );
}
