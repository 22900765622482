import React, { useState } from 'react';

import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';

import { useHashState } from 'utils/routes';

import { PurchaseTargetLinesHashState, PurchasingLineInput, SupplierLocationWTarget } from 'types/hash-state';

import { GenericTargetLineDTO } from '../types';
import { getLinesForSupplierLocation } from '../utils';
import { PurchaseTargetLinesTable } from './PurchaseTargetLinesTable';

export function PurchaseTargetLinesModal({
  supplierLocations,
  targetLines,
  purchasingLinesById: initialPurchasingLinesById,
  isHubAndSpoke,
  onClose,
}: {
  supplierLocations: SupplierLocationWTarget[];
  targetLines: GenericTargetLineDTO[];
  purchasingLinesById: Obj<PurchasingLineInput>;
  isHubAndSpoke: boolean;
  onClose: (purchasingLinesById?: Obj<PurchasingLineInput>) => void;
}) {
  const [purchasingLinesById, setPurchasingLinesById] = useState(initialPurchasingLinesById);
  const [hashState] = useHashState<PurchaseTargetLinesHashState>();
  const locationTargetLines = getLinesForSupplierLocation(targetLines, supplierLocations);
  const purchaseGroups = hashState.purchaseGroups?.filter((pg) => pg.groupId === locationTargetLines[0].groupId);

  return (
    <ViewAllModal
      title={
        isHubAndSpoke && purchaseGroups?.length === 1
          ? `Lines for ${purchaseGroups[0].groupName}`
          : `Lines for Supplier #${supplierLocations[0].supplierId}: ${supplierLocations[0].supplierName} at ${
              supplierLocations.length === 1
                ? `Location #${supplierLocations[0].locationId}: ${supplierLocations[0].locationName}`
                : `${supplierLocations.length} Locations`
            }`
      }
      wrapClassName="purchase-target-lines-modal"
      content={
        <PurchaseTargetLinesTable
          linesModalMode
          supplierLocations={supplierLocations}
          targetLines={locationTargetLines}
          purchasingLinesById={purchasingLinesById}
          onPurchasingLinesChange={setPurchasingLinesById}
          isHubAndSpoke={isHubAndSpoke}
          purchaseGroups={isHubAndSpoke ? purchaseGroups : undefined}
        />
      }
      onClose={() => onClose(purchasingLinesById)}
    />
  );
}
