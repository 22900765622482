import React from 'react';

import { css } from '@emotion/css';
import { colors } from 'theme/colors';

export const FixedFooter = ({ children }: { children: React.ReactNode }) => (
  <>
    <div
      // placeholder spacer div to account for space taken by fixed footer
      className={css`
        margin-top: 52px;
      `}
    />
    <div
      className={css`
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        padding: 16px 24px;
        border-top: 1px solid ${colors.neutral[200]};
        background: white;
      `}
    >
      {children}
    </div>
  </>
);
