import React, { useCallback, useEffect, useState } from 'react';

import {
  PercentageOutlined,
  DeleteOutlined,
  DownOutlined,
  CopyOutlined,
  ArrowUpOutlined,
  EnvironmentOutlined,
  ArrowDownOutlined,
  NodeExpandOutlined,
  SettingOutlined,
  BranchesOutlined,
  RetweetOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Menu, message, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import { Disposition } from 'pages/orders/quotes/types';

import { AsyncItemSelect } from 'components/AsyncSelect/AsyncItemSelect';
import { LabelContainer, LabelSubtitle, LabelTitle } from 'components/AsyncSelect/AsyncSelect.style';
import { useItemsWithDescSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';
import { getGlobalAppState, useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { joinIfIdNameObj, roundToPrecision } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { typedColumn } from 'utils/tables';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { OrderType, QuoteEditLineItemActionsClickType, track, TrackEvent } from 'utils/track';
import { priceUnitConverter } from 'utils/units';

import { QuoteLineItemP21 } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import {
  calcGM,
  calcPrice,
  extendedPriceWithIvaTax,
  getDuplicateLineItemsQty,
  getOtherCost,
  getUnitCost,
  getUnitOfMeasure,
  QuoteLineItemP21WithInfo,
  TransferType,
  validateGrossMargin,
  validatePrice,
  validateQuantity,
  warnPrice,
  warnQuantity,
} from '../../quoteUtils';
import { LineItemActionsP21 } from '../../types';
import { BackorderModal } from './BackorderModal';
import { DirectShipModal } from './DirectShipModal';
import * as Styled from './QuoteLineItems.style';
import { SourceAndShipModal } from './SourceAndShipModal';
import { SubstituteModal } from './SubstituteModal';

type UpdateLineItemFn = (item: Partial<QuoteLineItemP21>, jobNumber?: string) => void;

export const renderMessage = (
  details: JSX.Element | string | null | undefined,
  color: string,
  tooltip?: JSX.Element | string | null | undefined,
) => {
  const typography = (
    <Typography
      style={{
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        padding: '4px 0 4px 0',
        color,
      }}
    >
      {details}
    </Typography>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        {typography}
      </Tooltip>
    );
  }

  return typography;
};

export const renderError = (error: string | null | undefined, tooltip?: JSX.Element | string | null | undefined) =>
  error && renderMessage(error, theme.colors.danger[600], tooltip);

const renderWarning = (warning: string | null | undefined, tooltip?: JSX.Element | string | null | undefined) =>
  warning && renderMessage(warning, theme.colors.warning[600], tooltip);

const renderInfo = (info: string | null | undefined, tooltip?: JSX.Element | string | null | undefined) =>
  info && renderMessage(info, theme.colors.neutral[700], tooltip);

// 'bottom' to avoid covering input fields
const tooltipPlacement = 'bottom';

export function getLineItemColumns({
  selectedRowIdx,
  lineItems,
  company,
  salesLocation,
  sourceLocation,
  shipToLocation,
  orderType,
  numLineItems,
  actions,
}: {
  selectedRowIdx: number | undefined;
  lineItems: QuoteLineItemP21WithInfo[];
  company: IdNameObj;
  salesLocation: IdNameObj;
  sourceLocation?: IdNameObj;
  shipToLocation: IdNameObj;
  orderType: OrderType;
  numLineItems: number;
  actions: LineItemActionsP21;
}) {
  const companyId = company.foreignId;
  const { activeTenant, activeUser } = getGlobalAppState();
  const shouldDisablePriceAndGMChange = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersDisablePriceAndGMChange,
  );

  const lineItemColumns: ColumnType<QuoteLineItemP21WithInfo>[] = [
    typedColumn({
      title: '#',
      align: 'right',
      render: (_, item, index) => (
        <>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: ${selectedRowIdx === index ? theme.colors.primary[600] : `transparent`};
            `}
          />
          <span>{index + 1}</span>
        </>
      ),
    }),
    typedColumn({
      title: 'Item ID',
      dataIndex: 'foreignId',
      width: '15%',
      render: (_, item, index) => (
        <ColumnSelectItemId
          item={item}
          updateLineItem={(...args) => actions.updateLineItem(index, ...args)}
          company={company}
          location={sourceLocation || salesLocation}
          orderType={orderType}
        />
      ),
    }),
    typedColumn({
      title: 'Item Description',
      dataIndex: 'description',
      width: '35%',
      render: (_, item, index) => (
        <ColumnItemDescription item={item} updateLineItem={(...args) => actions.updateLineItem(index, ...args)} />
      ),
    }),
    typedColumn({
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '15%',
      render: (_, item, index) => (
        <ColumnItemQuantity
          item={item}
          items={lineItems.slice(0, index + 1)}
          location={sourceLocation || salesLocation}
          updateLineItem={(...args) => actions.updateLineItem(index, ...args)}
        />
      ),
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      width: '8%',
      render: (_, item, index) => (
        <ColumnItemUOM item={item} updateLineItem={(...args) => actions.updateLineItem(index, ...args)} />
      ),
    }),
    typedColumn({
      title: 'Price per UOM',
      dataIndex: 'price',
      width: '15%',
      render: (_, item, index) => (
        <ColumnItemPrice
          item={item}
          updateLineItem={(...args) => actions.updateLineItem(index, ...args)}
          shouldDisallowChange={shouldDisablePriceAndGMChange}
        />
      ),
    }),
    typedColumn({
      title: 'GM',
      width: '15%',
      render: (_, item, index) => (
        <ColumnItemGM
          item={item}
          updateLineItem={(...args) => actions.updateLineItem(index, ...args)}
          shouldDisallowChange={shouldDisablePriceAndGMChange}
        />
      ),
    }),
    typedColumn({
      align: 'center',
      title: 'Actions',
      width: '60px',
      render: (_, lineItem, lineItemIdx) => (
        <ColumnActions
          companyId={companyId}
          lineItem={lineItem}
          lineItems={lineItems}
          lineItemIdx={lineItemIdx}
          orderType={orderType}
          salesLocation={salesLocation}
          sourceLocation={sourceLocation}
          shipToLocation={shipToLocation}
          numLineItems={numLineItems}
          actions={actions}
        />
      ),
    }),
  ];

  return lineItemColumns.filter(filterCostAndGM);
}

function ColumnSelectItemId({
  item,
  updateLineItem,
  company,
  orderType,
  location,
}: {
  item: QuoteLineItemP21WithInfo;
  updateLineItem: UpdateLineItemFn;
  company?: IdNameObj;
  orderType: OrderType;
  location?: IdNameObj;
}) {
  const { foreignId } = item;
  const error = item.errors.foreignId;

  return (
    <Styled.TableCell>
      <AsyncItemSelect
        selectProps={useItemsWithDescSelectProps({ location })}
        entityPlural="items"
        value={foreignId}
        onSelect={(selectedId: string, option: any) =>
          // reset fields when selecting new line item so defaults are re-set
          updateLineItem({
            name: option.name,
            description: option.description,
            foreignId: selectedId,
            price: undefined,
            quantity: undefined,
            unitOfMeasure: undefined,
            lotsAssignable: option.lotsAssignable,
            lots: undefined,
            customerPartIdAssignable: option.customerPartIdAssignable,
          })
        }
        company={joinIfIdNameObj(company)}
        dropdownMatchSelectWidth={950}
        orderType={orderType}
        location={joinIfIdNameObj(location)}
        optionLabelProp="dropdownLabel"
      />
      <Styled.TableCellDetail>
        {[renderError(error)].map((x, idx) => x && <div key={idx}>{x}</div>)}
      </Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnItemDescription({
  item,
  updateLineItem,
}: {
  item: QuoteLineItemP21WithInfo;
  updateLineItem: UpdateLineItemFn;
}) {
  const charLimit = 255;
  const originalDescription = item.extendedDescription?.originalDescription ?? item.description ?? '';
  const extendedText = item?.extendedDescription?.extendedText ?? '';
  const extendedTextMaxLength = charLimit - 1 - originalDescription.length;
  const exceededMaxLengthError = extendedText.length >= extendedTextMaxLength ? 'Max length reached' : '';
  return (
    <Styled.TableCell>
      <Styled.TextSpacer />
      <Typography>{item.name}</Typography>
      <Styled.TextSpacer />
      <Styled.TableCellDetail>
        <Input
          placeholder="Extend item description"
          value={extendedText}
          onChange={(ev) => {
            const newExtendedText = ev.target.value;
            // Set max char limit to 255
            const newDescription = `${originalDescription} ${newExtendedText}`.trim().substring(0, charLimit - 1);
            updateLineItem({
              description: newDescription,
              extendedDescription: {
                originalDescription,
                extendedText: newExtendedText.substring(0, extendedTextMaxLength),
              },
            });
          }}
          disabled={!item.foreignId}
          hidden={!item.foreignId}
        />
        <Styled.TableCellDetail>
          {[renderError(exceededMaxLengthError)].map((x, idx) => x && <div key={idx}>{x}</div>)}
        </Styled.TableCellDetail>
      </Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnItemQuantity({
  item,
  items,
  location,
  updateLineItem,
}: {
  item: QuoteLineItemP21WithInfo;
  items: QuoteLineItemP21WithInfo[];
  location: IdNameObj;
  updateLineItem: UpdateLineItemFn;
}) {
  const { activeTenant, activeUser } = useGlobalApp();

  const updateQuantity = (quantity: number | string) => {
    if (typeof quantity === 'string') {
      quantity = (quantity !== '' ? Number(quantity) : '') as number;
    }

    updateLineItem({ quantity });
  };

  // Now filters out items that have Disposition set
  const qtyAllocatedForDisposition = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersQtyAllocatedForDispositionDirectOrSpecial,
  );
  const duplicateLineItemQtyAllocated = getDuplicateLineItemsQty(
    items,
    item,
    location.foreignId,
    qtyAllocatedForDisposition,
  );

  const { quantity } = item;
  const [error, errorTooltip] = validateQuantity(item, activeTenant.erpType, location, duplicateLineItemQtyAllocated);
  const [warning, warningTooltip] = warnQuantity(
    item,
    location,
    duplicateLineItemQtyAllocated,
    qtyAllocatedForDisposition,
  );

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={quantity}
        onChange={(ev) => updateQuantity(ev.target.value)}
        disabled={!item.foreignId}
        min={1}
        validate={() => !error}
      />
      <Styled.TableCellDetail>
        {[renderError(error, errorTooltip), renderWarning(warning, warningTooltip)].map(
          (x, idx) => x && <div key={idx}>{x}</div>,
        )}
      </Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnItemUOM({ item, updateLineItem }: { item: QuoteLineItemP21WithInfo; updateLineItem: UpdateLineItemFn }) {
  const updateUOM = (unitOfMeasure: string) => {
    const prevUOM = getUnitOfMeasure(item.unitOfMeasure, item);
    const nextUOM = getUnitOfMeasure(unitOfMeasure, item);
    const price = item.price && nextUOM ? priceUnitConverter(item.price, nextUOM?.size, prevUOM?.size) : item.price;
    const commissionCost =
      item.commissionCost && nextUOM
        ? priceUnitConverter(item.commissionCost, nextUOM?.size, prevUOM?.size)
        : item.commissionCost;
    updateLineItem({ price, unitOfMeasure, commissionCost });
  };

  const { unitOfMeasure } = item;
  return (
    <Styled.TableCell>
      <Select
        value={unitOfMeasure}
        onChange={(value) => updateUOM(value)}
        options={(item.priceInfo?.unitOfMeasureOptions || []).map((u, i) => ({
          value: u.symbol,
          label: (
            <LabelContainer key={i}>
              <LabelTitle>{u.symbol}</LabelTitle>
              <LabelSubtitle>
                {u.size} {u.name}
              </LabelSubtitle>
            </LabelContainer>
          ),
        }))}
        disabled={!item.foreignId}
        isLoading={item.isLoading}
      />
    </Styled.TableCell>
  );
}

function ColumnItemPrice({
  item,
  updateLineItem,
  shouldDisallowChange = false,
}: {
  item: QuoteLineItemP21WithInfo;
  updateLineItem: UpdateLineItemFn;
  shouldDisallowChange: boolean;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const systemCalculated = item.priceInfo?.current?.systemCalculatedPrices[0];
  const systemCalculatedUnitPrice = systemCalculated?.price;
  const systemCalculatedUnitPriceAmount = systemCalculatedUnitPrice?.amount;
  const precision = item.priceInfo?.current?.priceDecimalPrecision || 2;

  const updatePrice = useCallback(
    (_price: number | string, fromUserInput?: boolean) => {
      // we need to allow user to backspace to empty and enter new value,
      // hence allow setting _price to empty string.
      // this _price quantity won't be set to recommended value
      if (typeof _price === 'string') {
        _price = (_price !== '' ? Number(_price) : '') as number;
      } else {
        _price = roundToPrecision(_price, precision);
      }

      if (_price !== item.price) {
        const manualPriceOverride = fromUserInput ? _price !== systemCalculatedUnitPriceAmount : undefined;
        updateLineItem({ price: _price, manualPriceOverride });
      }
    },
    [item.price, precision, updateLineItem, systemCalculatedUnitPriceAmount],
  );

  useEffect(() => {
    if (!item || !item.priceInfo || !item.algoliaItem) {
      return;
    }

    // override price with sytem calculated price if user hasn't manually set price
    if (
      (item.price === undefined || !item.manualPriceOverride) &&
      typeof systemCalculatedUnitPriceAmount === 'number'
    ) {
      updatePrice(systemCalculatedUnitPriceAmount);
    }
  }, [item, updatePrice, systemCalculatedUnitPriceAmount]);

  const manualPriceOverrideInfo = item.manualPriceOverride ? 'Price Override' : null;
  const minMaxIsWarning = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersWarnMinMaxApproval);
  // NOTE: Right now when min/max is just warning the only customer that wants that doesn't want to render the exact number
  // And vice versa
  // It may make sense to mae them seperate feature flags in the future
  const showMinMaxValues = !minMaxIsWarning;

  const error = validatePrice(item, !minMaxIsWarning, showMinMaxValues);
  const warning = warnPrice(item, minMaxIsWarning, showMinMaxValues);

  // Show extended Price W/IVA tax, controlled by TenantFeatureFlag.SalesShowIvaTax
  // Only shown under the lines and not in subtotal, because the use case is for CSRs to see it under line item. P21 doesn't show this in totals either
  // IVA, short for 'Impuesto sobre el Valor Añadido', which means Value Added Tax (VAT) in English
  const showExtendedPriceWithIvaTax = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesShowIvaTax,
  );
  const ivaTaxPriceInfo = extendedPriceWithIvaTax(item, showExtendedPriceWithIvaTax);

  return (
    <Styled.TableCell>
      <Input
        data-testid="line-item-price-input"
        type="number"
        value={item.price}
        onChange={(ev) => updatePrice(ev.target.value, /* fromUserInput */ true)}
        disabled={!item.foreignId || shouldDisallowChange}
        isLoading={item.isLoading}
        prefix="$"
        validate={() => !error}
      />
      <Styled.TableCellDetail>
        {[
          renderInfo(manualPriceOverrideInfo),
          renderInfo(ivaTaxPriceInfo),
          renderError(error),
          renderWarning(warning),
        ].map((x, idx) => x && <div key={idx}>{x}</div>)}
      </Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnItemGM({
  item,
  updateLineItem,
  shouldDisallowChange = false,
}: {
  item: QuoteLineItemP21WithInfo;
  updateLineItem: UpdateLineItemFn;
  shouldDisallowChange: boolean;
}) {
  const { activeTenant } = useGlobalApp();
  const quoteOrderLineItemConfig = getTenantConfiguration(activeTenant).quoteOrderEntryFlow.lineItemConfig;
  const [price, setPrice] = useState<number | undefined>(item.price);
  const [gm, setGM] = useState<number | null>(null);
  const [uom, setUOM] = useState<string | undefined>(item.unitOfMeasure);
  const [itemId, setItemId] = useState<string | undefined>(item.foreignId);

  const cost = getUnitCost(item, activeTenant);
  const otherCost = getOtherCost(item);
  const precision = item.priceInfo?.current?.priceDecimalPrecision || 2;

  const otherCostBasedGMInfo =
    quoteOrderLineItemConfig.otherCostBasedGM.visible && item.price && otherCost
      ? `${calcGM(item.price, otherCost)}% (${quoteOrderLineItemConfig.otherCostBasedGM.displayName})`
      : null;

  const updatePrice = useCallback(
    (_price: number | string) => {
      // we need to allow user to backspace to empty and enter new value,
      // hence allow setting _price to empty string.
      // this _price quantity won't be set to recommended value
      if (typeof _price === 'string') {
        _price = (_price !== '' ? Number(_price) : '') as number;
      }

      if (_price !== price) {
        setPrice(price);
        setGM(null);
      }
    },
    [price],
  );

  const updateGM = useCallback(
    (gm: number | string, fromUserInput?: boolean) => {
      // we need to allow user to backspace to empty and enter new value,
      // hence allow setting price to empty string.
      // this price quantity won't be set to recommended value
      if (typeof gm === 'string') {
        gm = (gm !== '' ? Number(gm) : '') as number;
      }

      if (cost) {
        const price = calcPrice(gm || 0, cost, precision);
        updateLineItem({ price, manualPriceOverride: fromUserInput });
        setGM(gm);
        setPrice(price);
      }
    },
    [cost, updateLineItem, precision],
  );

  useEffect(() => {
    if (!item || !item.algoliaItem) return;
    if (item.price === undefined || roundToPrecision(item.price, precision) !== price) {
      if (item.foreignId === itemId && item.unitOfMeasure !== uom) {
        if (gm) {
          updateGM(gm);
        }
      } else {
        updatePrice(item.price === undefined ? '' : item.price);
      }
    }
    setItemId(item.foreignId);
    setUOM(item.unitOfMeasure);
  }, [gm, item, itemId, price, uom, updateGM, updatePrice, precision]);

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={gm !== null ? gm : item.price && cost ? calcGM(item.price, cost) : 0}
        onChange={(ev) => {
          updateGM(ev.currentTarget.value, /* fromUserInput */ true);
        }}
        suffix={<PercentageOutlined />}
        disabled={!item.foreignId || !cost || shouldDisallowChange}
        isLoading={item.isLoading}
        validate={validateGrossMargin}
      />
      <Styled.TableCellDetail>
        {[renderWarning(otherCostBasedGMInfo, null)].map((x, idx) => x && <div key={idx}>{x}</div>)}
      </Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnActions({
  companyId,
  orderType,
  lineItem,
  lineItems,
  lineItemIdx,
  salesLocation,
  sourceLocation,
  shipToLocation,
  numLineItems,
  actions,
}: {
  companyId: string;
  orderType: OrderType;
  lineItem: QuoteLineItemP21WithInfo;
  lineItems?: QuoteLineItemP21WithInfo[];
  lineItemIdx: number;
  salesLocation: IdNameObj;
  sourceLocation?: IdNameObj;
  shipToLocation: IdNameObj;
  numLineItems: number;
  actions: LineItemActionsP21;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const [isSourceLocationModalSelected, setIsSourceLocationModalSelected] = useState(false);
  const [isShipLocationModalSelected, setIsShipLocationModalSelected] = useState(false);
  const [isDirectShipModalSelected, setIsDirectShipModalSelected] = useState(false);
  const [isSpecialOrderModalSelected, setIsSpecialOrderModalSelected] = useState(false);
  const [isSubstituteModalSelected, setIsSubstituteModalSelected] = useState(false);
  const { data: substitutesData } = useCoreApi(schemas.items.getItemSubstitutes, {
    pathParams: { itemId: lineItem.foreignId },
  });

  const trackDropdownClick = (dropdownClickType: QuoteEditLineItemActionsClickType) =>
    track(TrackEvent.Quotes_EditQuote_LineItem_DropDownClick, {
      dropdownClickType,
      orderType,
    });

  return (
    <Styled.TableCell>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeSourceLocation) && (
              <>
                {orderType === OrderType.Order && (
                  <>
                    <Menu.Item
                      icon={<BranchesOutlined />}
                      onClick={() => {
                        trackDropdownClick(QuoteEditLineItemActionsClickType.Transfer);
                        setIsSourceLocationModalSelected(true);
                      }}
                    >
                      Source Location
                    </Menu.Item>
                    <Menu.Item
                      icon={<EnvironmentOutlined />}
                      onClick={() => {
                        trackDropdownClick(QuoteEditLineItemActionsClickType.ShipLocation);
                        setIsShipLocationModalSelected(true);
                      }}
                    >
                      Ship Location
                    </Menu.Item>
                  </>
                )}
                {orderType === OrderType.Order && (
                  <>
                    <Menu.Item
                      icon={<NodeExpandOutlined />}
                      onClick={() => {
                        trackDropdownClick(QuoteEditLineItemActionsClickType.DirectShip);
                        setIsDirectShipModalSelected(true);
                      }}
                    >
                      Direct Ship
                    </Menu.Item>
                  </>
                )}
                <Menu.Item
                  icon={<SettingOutlined />}
                  onClick={() => {
                    trackDropdownClick(QuoteEditLineItemActionsClickType.SpecialOrder);
                    setIsSpecialOrderModalSelected(true);
                  }}
                >
                  Special Order
                </Menu.Item>
                {orderType === OrderType.Order && (
                  <>
                    <Menu.Item
                      icon={<ReloadOutlined />}
                      onClick={async () => {
                        trackDropdownClick(QuoteEditLineItemActionsClickType.Backorder);
                        const isBackOrdered = await showAsyncModal(BackorderModal, {});
                        if (isBackOrdered) {
                          actions.updateLineItem(lineItemIdx, {
                            transferCarrier: undefined,
                            disposition: Disposition.BackOrder,
                            // If the line item is Disposition.BackOrder, it shouldn't be Disposition.Transfer
                            // clear shipLocation && sourceLocation will make sure this
                            shipLocation: undefined,
                            sourceLocation: undefined,
                            supplier: undefined,
                            poCost: undefined,
                          });
                        } else if (lineItem.disposition === Disposition.BackOrder) {
                          actions.updateLineItem(lineItemIdx, {
                            disposition: undefined,
                            supplier: undefined,
                            poCost: undefined,
                          });
                        }
                      }}
                    >
                      Backorder
                    </Menu.Item>
                  </>
                )}
                <Menu.Divider />
              </>
            )}
            <Menu.Item
              icon={<RetweetOutlined />}
              disabled={!substitutesData?.totalCount}
              onClick={() => {
                trackDropdownClick(QuoteEditLineItemActionsClickType.Substitute);
                setIsSubstituteModalSelected(true);
              }}
            >
              Substitute
            </Menu.Item>
            {
              // Only show divider if there is more than one item
              numLineItems > 1 && <Menu.Divider />
            }
            {lineItemIdx > 0 && (
              <Menu.Item
                icon={<ArrowUpOutlined />}
                onClick={() => {
                  trackDropdownClick(QuoteEditLineItemActionsClickType.MoveUp);
                  actions.moveLineItemUp(lineItemIdx);
                }}
              >
                Move Up
              </Menu.Item>
            )}
            {lineItemIdx + 1 < numLineItems && (
              <Menu.Item
                icon={<ArrowDownOutlined />}
                onClick={() => {
                  trackDropdownClick(QuoteEditLineItemActionsClickType.MoveDown);
                  actions.moveLineItemDown(lineItemIdx);
                }}
              >
                Move Down
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item
              icon={<CopyOutlined />}
              onClick={() => {
                trackDropdownClick(QuoteEditLineItemActionsClickType.Duplicate);
                actions.duplicateLineItem(lineItemIdx);
              }}
            >
              Duplicate
            </Menu.Item>
            <Menu.Item
              className={css`
                color: ${theme.colors.danger[500]};
              `}
              icon={<DeleteOutlined />}
              onClick={() => {
                trackDropdownClick(QuoteEditLineItemActionsClickType.Delete);
                actions.deleteLineItem(lineItemIdx);
              }}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Button data-testid="line-item-actions">
          <DownOutlined />
        </Button>
      </Dropdown>
      {isSourceLocationModalSelected && lineItem.algoliaItem !== undefined && (
        <SourceAndShipModal
          lineItem={lineItem}
          salesLocation={salesLocation}
          selectedModal={TransferType.SourceLocation}
          onOk={(sourceTransferInfo) => {
            if (sourceTransferInfo) {
              actions.updateLineItem(lineItemIdx, {
                disposition: undefined,
                sourceLocation: sourceTransferInfo.fromLocation,
                shipLocation: sourceTransferInfo.toLocation,
                supplier: undefined,
                transferCarrier: sourceTransferInfo.carrier,
              });
            }

            setIsSourceLocationModalSelected(false);
          }}
          onCancel={() => setIsSourceLocationModalSelected(false)}
        />
      )}
      {isShipLocationModalSelected && lineItem.algoliaItem !== undefined && (
        <SourceAndShipModal
          lineItem={lineItem}
          salesLocation={salesLocation}
          selectedModal={TransferType.ShipLocation}
          onOk={(shipTransferInfo) => {
            if (shipTransferInfo) {
              actions.updateLineItem(lineItemIdx, {
                disposition: undefined,
                sourceLocation: shipTransferInfo.fromLocation,
                shipLocation: shipTransferInfo.toLocation,
                supplier: undefined,
                transferCarrier: shipTransferInfo.carrier,
              });
            }
            setIsShipLocationModalSelected(false);
          }}
          onCancel={() => setIsShipLocationModalSelected(false)}
        />
      )}
      {isDirectShipModalSelected && lineItem.algoliaItem !== undefined && (
        <DirectShipModal
          companyId={companyId}
          item={lineItem}
          items={lineItems}
          location={sourceLocation || salesLocation}
          orderDisposition="D"
          orderType={orderType}
          shipToLocation={shipToLocation}
          onOk={(supplier, poCost) => {
            if (!supplier) return;
            // Setting shipLocation === sourceLocation indicates that there is NOT a transfer
            actions.updateLineItem(lineItemIdx, {
              transferCarrier: undefined,
              disposition: Disposition.DirectShip,
              shipLocation: sourceLocation || salesLocation,
              sourceLocation: sourceLocation || salesLocation,
              supplier,
              poCost,
            });
            setIsDirectShipModalSelected(false);
          }}
          onCancel={() => {
            if (lineItem.disposition === Disposition.DirectShip) {
              actions.updateLineItem(lineItemIdx, {
                disposition: undefined,
                supplier: undefined,
                poCost: undefined,
              });
            }
            setIsDirectShipModalSelected(false);
          }}
        />
      )}
      {isSpecialOrderModalSelected && lineItem.algoliaItem !== undefined && (
        <DirectShipModal
          companyId={companyId}
          item={lineItem}
          items={lineItems}
          location={sourceLocation || salesLocation}
          orderDisposition="S"
          orderType={orderType}
          shipToLocation={sourceLocation || salesLocation}
          onOk={(supplier, poCost) => {
            if (!supplier) return;
            actions.updateLineItem(lineItemIdx, {
              transferCarrier: undefined,
              disposition: Disposition.Special,
              shipLocation: sourceLocation || salesLocation,
              sourceLocation: sourceLocation || salesLocation,
              supplier,
              poCost,
            });
            setIsSpecialOrderModalSelected(false);
          }}
          onCancel={() => {
            if (lineItem.disposition === Disposition.Special) {
              actions.updateLineItem(lineItemIdx, {
                disposition: undefined,
                supplier: undefined,
                poCost: undefined,
              });
            }
            setIsSpecialOrderModalSelected(false);
          }}
        />
      )}

      {isSubstituteModalSelected && lineItem.algoliaItem !== undefined && (
        <SubstituteModal
          lineItem={lineItem}
          location={sourceLocation || salesLocation}
          onOk={(item) => {
            track(TrackEvent.Quotes_EditQuote_SubstituteItem, {
              orderType,
            });
            actions.setLineItem(lineItemIdx, item);
            message.success(`Item #${item.foreignId} substituted for #${lineItem.foreignId}`);
            setIsSubstituteModalSelected(false);
          }}
          onCancel={() => setIsSubstituteModalSelected(false)}
        />
      )}
    </Styled.TableCell>
  );
}
