import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import createAuth0Client, { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { schemas } from '@recurrency/core-api-schema';
import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { setUser as sentrySetUser, setTag as sentrySetTag } from '@sentry/react';
import Typesense from 'typesense';
import { v4 as uuidv4 } from 'uuid';

import { CenteredError, CenteredLoader } from 'components/Loaders';

import { ExtendedUser, globalAppStore } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { env } from 'utils/env';
import { captureError } from 'utils/error';
import {
  loadActiveCompanyIds,
  loadActiveTenantSettings,
  loadActiveTenantUserViewSettings,
} from 'utils/globalAppLoaders';
import { getLocalStorageItem, hasLocalStorageItem, LocalStorageKey } from 'utils/localStorage';
import { getActiveErpRole, getActiveTenant } from 'utils/roleAndTenant';
import { setDefaultTenantSlugForMakePath } from 'utils/routes';
import { getSessionStorageItem, SessionStorageKey, setSessionStorageItem } from 'utils/sessionStorage';
import { identify } from 'utils/track';

interface Auth0RedirectAppState {
  pathname: string;
  hash: string;
  query: string;
}

interface Auth0AppStateLoaderProps extends Auth0ClientOptions {
  children: JSX.Element;
}

export const Auth0AppStateLoader = ({ children, ...auth0ClientOptions }: Auth0AppStateLoaderProps) => {
  const auth0ClientRef = useRef<Auth0Client>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const history = useHistory();

  useEffect(() => {
    // closure function since useEffect functions cannot be async
    const initAuth0 = async () => {
      // get or create sessionId
      let sessionId = getSessionStorageItem(SessionStorageKey.SessionId);
      if (!sessionId) {
        sessionId = uuidv4();
        setSessionStorageItem(SessionStorageKey.SessionId, sessionId);
      }

      auth0ClientRef.current = await createAuth0Client(auth0ClientOptions);
      globalAppStore.update({
        sessionId,
        logout: () => auth0ClientRef.current!.logout({ returnTo: window.location.origin }),
        loginWithRedirect: () =>
          auth0ClientRef.current!.loginWithRedirect({
            appState: {
              pathname: window.location.pathname,
              query: window.location.search,
              hash: window.location.hash,
            } as Auth0RedirectAppState,
          }),
      });

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        try {
          const { appState }: { appState?: Auth0RedirectAppState } =
            await auth0ClientRef.current.handleRedirectCallback();

          if (appState) {
            // restore url
            history.replace(appState.pathname + appState.query + appState.hash);
          }
        } catch (err) {
          // authentication failed, don't render App and redirect to login page
          captureError(err);
          auth0ClientRef.current.loginWithRedirect();
          return;
        }
      }

      let accessToken: string | undefined;

      // E2E login uses auth0 REST api to get access_token and inject it into localStorage
      if (hasLocalStorageItem(LocalStorageKey.E2EAuthToken)) {
        accessToken = getLocalStorageItem(LocalStorageKey.E2EAuthToken)!;
      } else {
        const isAuthenticated = await auth0ClientRef.current.isAuthenticated();
        if (isAuthenticated) {
          accessToken = await auth0ClientRef.current.getTokenSilently();
        } else {
          // if unauthenticated, don't render App and redirect to login page
          globalAppStore.state.loginWithRedirect();
          return;
        }
      }

      try {
        globalAppStore.update({ accessToken });
        const { data } = await coreApiFetch(schemas.users.getMe);
        const { user, tenants, isAdmin } = data;

        if (!tenants || tenants.length === 0) {
          throw new Error(`No tenants assigned to ${user.email}`);
        }

        const activeTenant = getActiveTenant(tenants);
        const { roles: loadedRoles } = activeTenant.tenantUser;
        // manager role can have comma separated ids of sales reps
        const roles = loadedRoles.map((role) => ({ ...role, foreignIds: role.foreignId.split(',') }));
        const activeErpRole = getActiveErpRole(roles);
        const activeUser: ExtendedUser = {
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
          isRecurrencyAdmin: isAdmin,
          isRecurrencyInternalUser: user.email.endsWith('@recurrency.com'),
        };

        setDefaultTenantSlugForMakePath(activeTenant.slug);

        globalAppStore.update({
          activeUser,
          activeTenant,
          activeErpRole,
          roles,
          tenants: tenants.sort((a, b) => a.name.localeCompare(b.name)),
          typesenseSearchClient: new Typesense.SearchClient({
            apiKey: activeTenant.typesense.searchApiKey,
            nodes: [{ host: env.TYPESENSE_HOST, port: 443, protocol: 'https' }],
            cacheSearchResultsForSeconds: 0,
            connectionTimeoutSeconds: 10,
          }),
        });

        const activeCompanyIdsPromise = loadActiveCompanyIds(activeTenant);
        const activeTenantSettingsPromise = loadActiveTenantSettings();

        // Load user view settings asynchronously, do not block app load
        // Any specific settings needed before first paint should be loaded separately
        loadActiveTenantUserViewSettings();

        // activeCompanyIds and activeTenantSettings are needed for pages to render with correct data.
        await Promise.all([activeCompanyIdsPromise, activeTenantSettingsPromise]);

        sentrySetUser(activeUser);
        sentrySetTag('user.role', activeErpRole.name);
        sentrySetTag('user.email', activeUser.email);
        sentrySetTag('tenant.id', activeTenant.id);
        sentrySetTag('tenant.slug', activeTenant.slug);

        identify({
          id: activeUser.id,
          name: activeUser.fullName,
          email: activeUser.email,
          role: activeErpRole.name,
          sessionId,
          tenantId: activeTenant.id,
          tenantName: activeTenant.name,
          tenantSlug: activeTenant.slug,
          tenantCreatedAt: activeTenant.createdAt,
          userCreatedAt: activeUser.createdAt,
          tenantEnabledProducts: Object.entries(activeTenant.products)
            .filter(([, product]) => product.productEnabled)
            .map(([key]) => key as TenantProductName),
        });
      } catch (err) {
        captureError(err);
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  // don't render app if auth info is loading, or has an error
  return isLoading ? <CenteredLoader /> : error ? <CenteredError error={error} /> : children;
};
