import { UnitCostSource } from '@recurrency/core-api-schema/dist/common/enums';

export const UnitCostSettingLabel: Record<UnitCostSource, string> = {
  [UnitCostSource.Fifo]: 'Fifo Cost',
  [UnitCostSource.MovingAverage]: 'Moving Avg Cost',
  [UnitCostSource.Standard]: 'Standard Cost',
  [UnitCostSource.LastPurchase]: 'Last Purchase Cost',
  [UnitCostSource.Supplier]: 'Supplier Cost',
};

export const unitCostSettingLabelMap = (settingValue?: UnitCostSource) =>
  UnitCostSettingLabel[settingValue ?? UnitCostSource.MovingAverage];
