import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { LockOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { message } from 'antd';
import { theme } from 'theme';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { captureAndShowError } from 'utils/error';
import { parsePath } from 'utils/routes';
import { zendeskAnonymousRequest } from 'utils/zendesk';

import { Button } from '../components/Button';
import { Container } from '../components/Container';
import { FlexSpace } from '../components/FlexSpace';
import { FlexSpacer } from '../components/FlexSpacer';
import { Typography } from '../components/Typography';

enum Upsell {
  Dfpa = 'Demand Planning and Purchasing',
  Sales = 'Sales',
}

const UPSELL_MODULES: Record<string, Upsell> = {
  sales: Upsell.Sales,
  orders: Upsell.Dfpa,
  'demand-planning': Upsell.Dfpa,
  purchasing: Upsell.Dfpa,
  payments: Upsell.Sales,
  reporting: Upsell.Sales,
  tasks: Upsell.Sales,
};

const UPSELLS: Record<Upsell, { imageUrl: string; title: string; description: string }> = {
  [Upsell.Dfpa]: {
    imageUrl: 'https://assets.recurrency.com/app-images/dfpa-upsell-hero-2.jpg',
    title: 'Take control of your inventory.',
    description:
      "Upgrade to Recurrency's Demand Planning and Purchasing suite to act on inventory insights with intelligent forecasting, smart lead times, automatic purchase order generation, and much more.",
  },
  [Upsell.Sales]: {
    imageUrl: 'https://assets.recurrency.com/app-images/sales-upsell-hero.jpg',
    title: 'Sell more, faster, with stronger customer relationships.',
    description:
      "Upgrade to Recurrency's Sales product to act on sales insights with lightning-fast search and lookups, intelligent upsell recommendations, automatic reorder suggestions, rapid entry workflows, and much more.",
  },
};

export const NoAccessPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { activeTenant, activeUser } = useGlobalApp();

  const path = location.pathname;
  const parsedPath = parsePath(path);

  const upsellKey = parsedPath.moduleSlug in UPSELL_MODULES ? UPSELL_MODULES[parsedPath.moduleSlug] : undefined;
  const upsellData = upsellKey ? UPSELLS[upsellKey] : undefined;

  const onRequestUpgrade = async () => {
    try {
      await zendeskAnonymousRequest({
        requesterName: activeUser.fullName,
        subject: `Requesting access to view page for ${activeTenant.name}`,
        body: `${activeUser.fullName} (${activeUser.email}) at ${activeTenant.name} would like an upgrade to be able to access this page: ${window.location.pathname}.`,
      });
      message.success(`Access requested! Our team will be in touch.`);
    } catch (err) {
      captureAndShowError(err, `Error while requesting access, please try again later.`);
    }
  };

  return (
    <Container>
      <div
        className={css`
          max-width: 600px;
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid ${theme.colors.neutral[300]};
          margin: 24px auto;
          box-shadow: 2px 2px 4px 0px ${theme.colors.neutral[100]};
        `}
      >
        <Typography type="h1" style={{ color: theme.colors.primary[600] }}>
          <img
            className={css`
              width: 100%;
            `}
            alt=""
            src={upsellData?.imageUrl || 'https://assets.recurrency.com/app-images/generic-upsell-hero.jpg'}
          />
        </Typography>
        <div
          className={css`
            padding: 24px;
            border-top: 1px solid ${theme.colors.neutral[300]};
          `}
        >
          {upsellData && (
            <>
              <Typography type="small">
                <span
                  className={css`
                    color: ${theme.colors.neutral[500]};
                  `}
                >
                  <LockOutlined />
                  &nbsp;&nbsp;
                  {upsellKey && `This page is restricted to users with access to ${upsellKey}`}.
                </span>
              </Typography>
              <br />
            </>
          )}
          <Typography type="large">{upsellData?.title || 'Access Denied'}</Typography>
          <br />
          <Typography type="subtitle">
            {upsellData?.description || 'You cannot access this page with your current Recurrency plan.'}
          </Typography>
          <br />
          <FlexSpace>
            <FlexSpacer />
            <Button onClick={history.goBack}>Go Back</Button>
            <Button type="primary" onClick={onRequestUpgrade}>
              Request Access
            </Button>
          </FlexSpace>
        </div>
      </div>
    </Container>
  );
};
