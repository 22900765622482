import { env } from 'utils/env';

import { captureError } from './error';
import { TrackEvent, EventProps, track } from './track';

export enum PerfMarkName {
  /** when init() function in src/index.tsx is called */
  AppInit = 'appInit',

  /** when navigation shell renders */
  NavShellRender = 'navShellRender',
}

/**
 * Creates a performance mark using perfomance api
 * NOTE: the marks are also viewable in performance profiler in chrome devtools
 */
export function perfMark(name: PerfMarkName) {
  window.performance.mark(name);
}

export function trackPageLoadMetrics() {
  try {
    const trackProps: EventProps[TrackEvent.Nav_PageLoad] = {
      windowUserAgent: window.navigator.userAgent,
      windowInnerWidth: window.innerWidth,
      windowInnerHeight: window.innerHeight,
      appVersion: env.APP_VERSION,
    };

    const navEntries = window.performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
    const markEntries = window.performance.getEntriesByType('mark') as PerformanceMark[];
    const resourceEntries = window.performance.getEntriesByType('resource') as PerformanceResourceTiming[];
    const navTiming = navEntries[0];
    const appInitMark = markEntries.find((entry) => entry.name === PerfMarkName.AppInit);
    const navShellRenderMark = markEntries.find((entry) => entry.name === PerfMarkName.NavShellRender);
    const appJsLoad = resourceEntries.find(
      (entry) => entry.initiatorType === 'script' && entry.name.endsWith('/app.js'),
    );
    const appCssLoad = resourceEntries.find(
      (entry) => entry.initiatorType === 'link' && entry.name.endsWith('/app.css'),
    );

    // we Math.round metrics since we care about whole milliseconds. It's also faster to query
    // safari is the only modern browser that doesn't have 'navigation' entry. It's the new IE.
    if (navTiming) {
      trackProps.timeMsToIndexHtmlLoad = Math.round(navTiming.responseEnd);
      trackProps.timeMsToDomContentLoad = Math.round(navTiming.domContentLoadedEventEnd);
    }
    if (appInitMark) {
      trackProps.timeMsToAppInit = Math.round(appInitMark.startTime);
    }
    if (navShellRenderMark) {
      trackProps.timeMsToNavShellRender = Math.round(navShellRenderMark.startTime);
    }
    if (appJsLoad) {
      trackProps.timeMsToAppJsLoad = Math.round(appJsLoad.responseEnd);
      trackProps.sizeKbCompressedAppJs = Math.round(appJsLoad.encodedBodySize / 1000);
    }
    if (appCssLoad) {
      trackProps.timeMsToAppCssLoad = Math.round(appCssLoad.responseEnd);
      trackProps.sizeKbCompressedAppCss = Math.round(appCssLoad.encodedBodySize / 1000);
    }

    track(TrackEvent.Nav_PageLoad, trackProps);
  } catch (err) {
    // gracefully fail if tracking fails, error shouldn't bubble to error boundary
    captureError(err);
  }
}
