import React from 'react';

import { css } from '@emotion/css';
import { colors } from 'theme/colors';

import { reactNodeToText } from 'utils/react';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { track, TrackEvent } from 'utils/track';

import { ChooseViewSettingButton } from './ChooseViewSettingButton';

interface ColumnChooserSectionProps {
  tableKey: ViewSettingKey;
  columns: PersistedColumn<any>[];
  visibleColumnKeys: string[];
  setVisibleColumnKeys: (keys: string[]) => void;
}

export function ColumnChooserSection({
  tableKey,
  columns,
  visibleColumnKeys,
  setVisibleColumnKeys,
}: ColumnChooserSectionProps) {
  const defaultColumnKeys = columns.filter((column) => !column.optional).map((column) => column.settingKey);

  return (
    <div
      className={css`
        display: flex;
        padding-bottom: 8px;
        justify-content: flex-end;
      `}
    >
      <ChooseViewSettingButton
        options={{
          options: columns.map((col) => ({
            label: (
              <>
                {reactNodeToText(col.title)}
                {col.required ? (
                  <div
                    className={css`
                      color: ${colors.neutral[400]};
                      font-style: italic;
                    `}
                  >
                    Required
                  </div>
                ) : (
                  <></>
                )}
              </>
            ),
            value: col.settingKey,
            searchLabel: reactNodeToText(col.title),
            disabled: col.required,
          })),
        }}
        selectedValues={visibleColumnKeys}
        onSelectedValuesChange={(newValues: string[]) => {
          track(TrackEvent.Customizable_Columns_Update, {
            page: tableKey,
            columns: newValues,
          });
          setVisibleColumnKeys(newValues);
        }}
        descriptor="Columns"
        defaultValues={defaultColumnKeys}
      />
    </div>
  );
}
