import React, { useState } from 'react';

import { css } from '@emotion/css';

import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';

import { QuoteLineItemP21 } from 'types/hash-state';
import { ItemUnitOfMeasure } from 'types/legacy-api';

interface SelectLineItemCommissionCostModalProps {
  lineItem: QuoteLineItemP21;
  formattedCost: string;
  unitOfMeasure?: ItemUnitOfMeasure;
  onClose: (commissionCost?: number | undefined) => void;
}

export function ItemCommissionCostModal({
  lineItem,
  formattedCost,
  unitOfMeasure,
  onClose,
}: SelectLineItemCommissionCostModalProps): JSX.Element {
  const [selectedCommissionCost, setSelectedCommissionCost] = useState<number | undefined>(lineItem.commissionCost);
  return (
    <Modal
      visible
      title={`Cost for Item #${lineItem.foreignId}`}
      width={600}
      onCancel={() => onClose()}
      onOk={() => onClose(selectedCommissionCost)}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 14px;
        `}
      />
      <PropertyListItem
        label={`Default Cost ${unitOfMeasure ? `(per ${unitOfMeasure.name})` : ''}`}
        value={formattedCost}
      />
      <br />
      <PropertyListItem
        label={`Current Cost ${unitOfMeasure ? `(per ${unitOfMeasure.name})` : ''}`}
        value={
          <Input
            type="number"
            min={0}
            onChange={(ev) => setSelectedCommissionCost(Number(ev.target.value))}
            prefix="$"
          />
        }
      />
    </Modal>
  );
}
