import React, { useCallback, useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantDataSyncConfigs } from '@recurrency/core-api-schema/dist/tenantDataSyncConfigs/getTenantDataSyncConfigs';
import { Form } from 'antd';

import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { createSubmissionNotification } from 'utils/submissionNotification';

export interface TableData {
  label: string;
  key: string;
  value: any;
}

export function useTentantDataSyncConfigsTable(tenantId: string) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const { activeUser } = useGlobalApp();
  const [form] = Form.useForm<TenantDataSyncConfigs>();

  // This function fetches the tenant data sync configurations from the server.
  // If the call is successful, it transforms the raw data into a format suitable for the table and sets the state.
  // It also sets the form fields with the fetched data.
  const fetchTenantDataSyncConfigs = useCallback(async () => {
    setLoading(true);
    try {
      const { data: submittedData } = await coreApiFetch(schemas.tenantDataSyncConfigs.getTenantDataSyncConfigs, {
        pathParams: { tenantId },
      });
      const data = transformRawDataToTableFormat(submittedData);
      setTableData(data);
      form.setFieldsValue({ ...submittedData, updatedBy: activeUser.email });
    } catch (error) {
      captureAndShowError(error, 'Error fetching Tenant Data Sync Configs', {
        renderDescription: () => (
          <Typography>
            <p>Please try again.</p>
          </Typography>
        ),
        duration: 0,
      });
    } finally {
      setLoading(false);
    }
  }, [activeUser.email, form, tenantId]);

  const handleSubmit = useCallback(
    async (values: TenantDataSyncConfigs) => {
      const submitNotification = createSubmissionNotification({
        entityName: 'Data Sync Configs',
        submittingMessage: 'Save Configs to Postgres',
        expectedWaitSeconds: 1,
        erpType: IntegratedErps.P21,
      });
      try {
        await coreApiFetch(schemas.tenantDataSyncConfigs.updateTenantDataSyncConfigs, {
          pathParams: { tenantId },
          bodyParams: values,
        });
        await fetchTenantDataSyncConfigs();
        submitNotification.success();
      } catch (e) {
        submitNotification.error(e);
      }
    },
    [tenantId, fetchTenantDataSyncConfigs],
  );

  return {
    form,
    loading,
    tableData,
    fetchTenantDataSyncConfigs,
    handleSubmit,
  };
}

// This function transforms the raw data fetched from the server into a format suitable for the table.
// It maps each key to an object containing the key, the label, and the formatted value.
export function transformRawDataToTableFormat(rawData: TenantDataSyncConfigs): TableData[] {
  // If there is no data, return an empty array
  if (!rawData) return [];

  // Otherwise, return an array of objects, where each object is a row in the table.
  return Object.keys(rawData)
    .filter((key) => key !== 'tenant_id')
    .map((key) => {
      const value = rawData[key as keyof TenantDataSyncConfigs];
      return {
        label: key,
        key,
        value: formatValue(key, value),
      };
    });
}

export function formatValue(key: string, value: unknown): string {
  // If the value is an array, join the values with a new line
  if (key === 'filtersExclude' || key === 'filtersInclude') {
    return Array.isArray(value) && value.length ? value.join(' \r\n') : 'EMPTY';
  }
  if (key === 'taskChangeTracking') {
    return value ? 'true' : 'false';
  }
  if (value === null || value === undefined) {
    return 'N/A';
  }
  return String(value) || 'EMPTY';
}
