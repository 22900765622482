import React, { useState } from 'react';

import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';

import { css } from '@emotion/css';
import { RecurrencyRole, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';

import { EditDetailsPage } from 'pages/account/EditDetailsPage';
import { ExportJobDetailsPage } from 'pages/admin/ExportJobsPage/ExportJobDetailsPage';
import { ExportJobsPage } from 'pages/admin/ExportJobsPage/ExportJobsPage';
import { LegacySalesSettingsPage } from 'pages/admin/LegacySalesSettingsPage';
import { LocationSettingsPage } from 'pages/admin/LocationSettingsPage';
import { recurrencyRolesToShowSettingsPage, SettingsPage } from 'pages/admin/SettingsPage';
import { UsersPage } from 'pages/admin/UsersPage';
import { TenantDetailsPage } from 'pages/internal/tenants/TenantDetailsPage';
import { TenantEditPage } from 'pages/internal/tenants/TenantEditPage';
import { TenantListPage } from 'pages/internal/tenants/TenantListPage';
import { TenantNewPage } from 'pages/internal/tenants/TenantNewPage';
import { UserDetailsPage } from 'pages/internal/users/UserDetailsPage';
import { UserEditPage } from 'pages/internal/users/UserEditPage';
import { UserListPage } from 'pages/internal/users/UserListPage';
import { UserNewPage } from 'pages/internal/users/UserNewPage';
import { ItemDetailsPage } from 'pages/items/ItemDetailsPage';
import { NoAccessPage } from 'pages/NoAccessPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { OpportunitiesPage } from 'pages/orders/OpportunitiesPage';
import { OrderDetailsPage } from 'pages/orders/orders/OrderDetailsPage';
import { OrderListPage } from 'pages/orders/orders/OrderListPage';
import { OrderNewPage } from 'pages/orders/orders/OrderNewPage';
import { DraftQuoteListPage } from 'pages/orders/quotes/DraftQuoteListPage';
import { QuoteDetailsPage } from 'pages/orders/quotes/QuoteDetailsPage';
import { QuoteEditPage } from 'pages/orders/quotes/QuoteEditPage';
import { QuoteListPage } from 'pages/orders/quotes/QuoteListPage';
import { QuoteNewPage } from 'pages/orders/quotes/QuoteNewPage';
import { DisputeListPage } from 'pages/payments/disputes';
import { OpenInvoicesPage } from 'pages/payments/OpenInvoicesPage';
import { OrderPayPage } from 'pages/payments/OrderPayPage';
import { PaymentsListPage } from 'pages/payments/PaymentsListPage';
import { PayoutListPage } from 'pages/payments/payouts';
import { PayoutDetailsPage } from 'pages/payments/payouts/PayoutDetailsPage';
import { OnboardingPage } from 'pages/planning/OnboardingPage/OnboardingPage';
import { ReplenishmentPathsPage } from 'pages/planning/ReplenishmentPathsPage/ReplenishmentPathsPage';
import { ForecastingPage } from 'pages/purchasing/ForecastingPage/ForecastingPage';
import { InventorySimulationDashboard } from 'pages/purchasing/InventorySimulationDashboard/InventorySimulationDashboard';
import { PlanningPage } from 'pages/purchasing/PlanningPage/PlanningPage';
import { PurchaseGroupsPage } from 'pages/purchasing/PurchaseGroupsPage/PurchaseGroupsPage';
import { PurchaseOrderDetailsPage } from 'pages/purchasing/purchaseOrders/PurchaseOrderDetailsPage';
import { PurchaseOrderListPage } from 'pages/purchasing/purchaseOrders/PurchaseOrderListPage';
import { PurchaseOrderNewPage } from 'pages/purchasing/purchaseOrders/PurchaseOrderNewPage';
import { PurchaseStatusPage } from 'pages/purchasing/PurchaseStatsPage/PurchaseStatusPage';
import { PurchaseTargetsPage } from 'pages/purchasing/PurchaseTargetsPage';
import { PurchaseTargetLinesPage } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage';
import { InventoryMissionControlPage } from 'pages/purchasing/PurchasingDashboardPage';
import {
  CurrentInventoryExplorerPage,
  HistoricalInventoryExplorerPage,
} from 'pages/purchasing/PurchasingDashboardPage/InventoryExplorer';
import { PurchasingItemListPage } from 'pages/purchasing/PurchasingItemListPage';
import { TransferOrderDetailsPage } from 'pages/purchasing/TransferOrders/TransferOrderDetailsPage';
import { TransferOrderListPage } from 'pages/purchasing/TransferOrders/TransferOrderListPage';
import { TransferReplenishmentLinesPage } from 'pages/purchasing/TransferReplenishmentPage/TransferReplenishmentLinesPage/TransferReplenishmentLinesPage';
import { TransferReplenishmentPage } from 'pages/purchasing/TransferReplenishmentPage/TransferReplenishmentPage';
import { VendorDetailsPage } from 'pages/purchasing/vendors/VendorDetailsPage';
import { VendorListPage } from 'pages/purchasing/vendors/VendorListPage';
import { ExplorerPage } from 'pages/reporting/ExplorerPage';
import { ReportPage } from 'pages/reporting/ReportPage';
import { SavedReportListPage } from 'pages/reporting/SavedReportListPage';
import { CustomerDetailsPage } from 'pages/sales/customers/CustomerDetailsPage';
import { CustomerListPage } from 'pages/sales/customers/CustomerListPage';
import { ProspectNewPage } from 'pages/sales/customers/ProspectNewPage/ProspectNewPage';
import { DashboardPage } from 'pages/sales/DashboardPage';
import { PricingPage } from 'pages/sales/PricingPage';
import { SalesItemListPage } from 'pages/sales/SalesItemListPage';
import { Integration } from 'pages/setup/Integration';
import { TaskDetailsPage } from 'pages/tasks/TaskDetailsPage';
import { TasksListPage } from 'pages/tasks/TasksListPage';

import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Container } from 'components/Container';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { captureError } from 'utils/error';
import { isAtLeastOneOfRecurrencyRoles, shouldShowProduct } from 'utils/roleAndTenant';
import { makePath, routePaths } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { navModules, filterNav } from './Navigation/navModulesData';

export interface ProductRouteProps extends RouteProps {
  products: TenantProductName[];
}

export interface RecurrencyRoleRouteProps extends RouteProps {
  recurrencyRoles: RecurrencyRole[];
}

const ProductRouteBanner = ({
  message,
  onClick,
  buttonText,
}: {
  message: string;
  onClick: () => void;
  buttonText: string;
}) => (
  <Container>
    <Alert
      className={css`
        margin-top: 24px;
      `}
      message={message}
      action={
        <Button onClick={onClick} size="small">
          {buttonText}
        </Button>
      }
      type="warning"
      banner
      closable
    />
  </Container>
);

const ProductRoute = (props: ProductRouteProps) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const [showInternalPreview, setShowInternalPreview] = useState(false);
  const { isRecurrencyInternalUser } = activeUser;
  const shouldShowRoute = props.products.some((product) => shouldShowProduct(activeTenant, product));

  if (shouldShowRoute || showInternalPreview) {
    return (
      <>
        {!shouldShowRoute && (
          <ProductRouteBanner
            message={`Showing internal preview of this page. ${
              activeTenant.name
            } users cannot access this page as ${props.products.join(' or ')} is not enabled.`}
            onClick={() => setShowInternalPreview(!showInternalPreview)}
            buttonText="See Regular User's View"
          />
        )}
        <Route {...props} />
      </>
    );
  }

  if (!isRecurrencyInternalUser) {
    track(TrackEvent.Nav_NoAccess, { blockedProducts: props.products });
  }

  return (
    <>
      {isRecurrencyInternalUser && (
        <ProductRouteBanner
          message={`Showing regular user's view of this page. ${
            activeTenant.name
          } users cannot access this page as ${props.products.join(' or ')} is not enabled.`}
          onClick={() => setShowInternalPreview(!showInternalPreview)}
          buttonText="Preview Page"
        />
      )}
      <Route children={<NoAccessPage />} />
    </>
  );
};

const AdminRoute = (props: RouteProps) => {
  const { activeUser } = useGlobalApp();
  const isRecurrencyAdminUser = activeUser.isRecurrencyAdmin;

  if (isRecurrencyAdminUser) {
    return <Route {...props} />;
  }
  captureError(new Error(`User ${activeUser.email} was blocked from accessing admin route ${props.path}`));
  return <Route children={<NoAccessPage />} />;
};

const RecurrencyRoleRoute = ({ recurrencyRoles, ...props }: RecurrencyRoleRouteProps) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const shouldShowRoute = isAtLeastOneOfRecurrencyRoles(activeTenant, recurrencyRoles);

  if (shouldShowRoute) {
    return <Route {...props} />;
  }
  captureError(new Error(`User ${activeUser.email} was blocked from accessing Recurrency role route ${props.path}`));
  return <Route children={<NoAccessPage />} />;
};

export function AppRoutes() {
  const { activeTenant, activeUser, activeErpRole } = useGlobalApp();

  const homeModule = filterNav(navModules, activeTenant, activeUser, activeErpRole)[0];
  const homeRoute = homeModule.routeFn?.() || homeModule.subModules[0].routeFn();

  return (
    <>
      {/* prettier-ignore */}
      <Switch>
        <Route exact path={routePaths.home} children={<Redirect to={makePath(homeRoute)} />} />

        <ProductRoute exact products={[TenantProductName.SalesMissionControl]} path={routePaths.sales.dashboard} component={DashboardPage} />

        <ProductRoute exact products={[TenantProductName.SalesReporting]} path={routePaths.reporting.reporting} component={ExplorerPage} />
        <ProductRoute exact products={[TenantProductName.SalesReporting]} path={routePaths.reporting.explorer} component={ExplorerPage} />
        <ProductRoute exact products={[TenantProductName.SalesReporting]} path={routePaths.reporting.report} component={ReportPage} />
        <ProductRoute exact products={[TenantProductName.SalesReporting]} path={routePaths.reporting.reports} component={SavedReportListPage} />

        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup]} path={routePaths.sales.pricing} component={PricingPage} />
        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup]} path={routePaths.sales.customerList} component={CustomerListPage} />
        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup]} path={routePaths.sales.customerDetails} component={CustomerDetailsPage} />
        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup]} path={routePaths.sales.itemList} component={SalesItemListPage} />
        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup]} path={routePaths.sales.itemDetails} component={ItemDetailsPage} />
        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup]} path={routePaths.orders.quoteList} component={QuoteListPage} />
        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup]} path={routePaths.orders.quoteDetails} component={QuoteDetailsPage} />

        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup, TenantProductName.DfpaSearchAndLookup]} path={routePaths.orders.orderList} component={OrderListPage} />
        <ProductRoute exact products={[TenantProductName.SalesSearchAndLookup, TenantProductName.DfpaSearchAndLookup]} path={routePaths.orders.orderDetails} component={OrderDetailsPage} />

        <ProductRoute exact products={[TenantProductName.SalesCsrFrontCounter]} path={routePaths.orders.orderNew} component={OrderNewPage} />

        <ProductRoute exact products={[TenantProductName.SalesQuoting]} path={routePaths.orders.draftQuoteList} component={DraftQuoteListPage} />
        <ProductRoute exact products={[TenantProductName.SalesQuoting]} path={routePaths.orders.quoteNew} component={QuoteNewPage} />
        <ProductRoute exact products={[TenantProductName.SalesQuoting]} path={routePaths.orders.quoteEdit} component={QuoteEditPage} />

        <ProductRoute exact products={[TenantProductName.SalesRecommendations]} path={routePaths.orders.opportunities} component={OpportunitiesPage} />

        <ProductRoute exact products={[TenantProductName.DfpaMissionControl]} path={routePaths.demandPlanning.dashboard} component={InventoryMissionControlPage} />
        <ProductRoute exact products={[TenantProductName.DfpaMissionControl]} path={routePaths.demandPlanning.reporting} component={HistoricalInventoryExplorerPage} />
        <ProductRoute exact products={[TenantProductName.DfpaMissionControl]} path={routePaths.demandPlanning.currentInventory} component={CurrentInventoryExplorerPage} />
        <ProductRoute exact products={[TenantProductName.DfpaMissionControl]} path={routePaths.demandPlanning.inventorySimulationDashboard} component={InventorySimulationDashboard} />

        <ProductRoute exact products={[TenantProductName.DfpaDemandPlanning]} path={routePaths.demandPlanning.forecasting} component={ForecastingPage} />
        <ProductRoute exact products={[TenantProductName.DfpaDemandPlanning]} path={routePaths.demandPlanning.onboarding} component={OnboardingPage} />
        <ProductRoute exact products={[TenantProductName.DfpaDemandPlanning]} path={routePaths.demandPlanning.planning} component={PlanningPage} />

        <ProductRoute exact products={[TenantProductName.DfpaSearchAndLookup]} path={routePaths.purchasing.purchaseOrderList} component={PurchaseOrderListPage} />
        <ProductRoute exact products={[TenantProductName.DfpaSearchAndLookup]} path={routePaths.purchasing.purchaseOrderDetails} component={PurchaseOrderDetailsPage} />
        <ProductRoute exact products={[TenantProductName.DfpaSearchAndLookup]} path={routePaths.purchasing.vendorList} component={VendorListPage} />
        <ProductRoute exact products={[TenantProductName.DfpaSearchAndLookup]} path={routePaths.purchasing.vendorDetails} component={VendorDetailsPage} />
        <ProductRoute exact products={[TenantProductName.DfpaSearchAndLookup]} path={routePaths.purchasing.itemList} component={PurchasingItemListPage} />
        <ProductRoute exact products={[TenantProductName.DfpaSearchAndLookup]} path={routePaths.purchasing.itemDetails} component={ItemDetailsPage} />

        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.purchaseOrderNew} component={PurchaseOrderNewPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.purchaseTargets} component={PurchaseTargetsPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.purchaseTargetLines} component={PurchaseTargetLinesPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.purchaseGroups} component={PurchaseGroupsPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.purchaseStatus} component={PurchaseStatusPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.transferReplenishment} component={TransferReplenishmentPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.transferReplenishmentLines} component={TransferReplenishmentLinesPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.transferOrderList} component={TransferOrderListPage} />
        <ProductRoute exact products={[TenantProductName.DfpaPurchasingAutomation]} path={routePaths.purchasing.transferOrderDetails} component={TransferOrderDetailsPage} />

        <ProductRoute exact products={[TenantProductName.Payments]} path={routePaths.payments.payouts} component={PayoutListPage} />
        <ProductRoute exact products={[TenantProductName.Payments]} path={routePaths.payments.disputes} component={DisputeListPage} />
        <ProductRoute exact products={[TenantProductName.Payments]} path={routePaths.payments.payoutDetails} component={PayoutDetailsPage} />
        <ProductRoute exact products={[TenantProductName.Payments]} path={routePaths.payments.payments} component={PaymentsListPage} />
        <ProductRoute exact products={[TenantProductName.Payments]} path={routePaths.payments.openInvoices} component={OpenInvoicesPage} />
        <ProductRoute exact products={[TenantProductName.Payments]} path={routePaths.payments.paymentDetails} component={OrderPayPage} />

        <ProductRoute exact products={[TenantProductName.SalesCrm]} path={routePaths.tasks.taskList} component={TasksListPage} />
        <ProductRoute exact products={[TenantProductName.SalesCrm]} path={routePaths.tasks.taskDetails} component={TaskDetailsPage} />
        <ProductRoute exact products={[TenantProductName.SalesCrm]} path={routePaths.sales.customerNew} component={ProspectNewPage} />

        <AdminRoute exact path={routePaths.internal.userList} component={UserListPage} />
        <AdminRoute exact path={routePaths.internal.userDetails} component={UserDetailsPage} />
        <AdminRoute exact path={routePaths.internal.userNew} component={UserNewPage} />
        <AdminRoute exact path={routePaths.internal.userEdit} component={UserEditPage} />
        <AdminRoute exact path={routePaths.internal.tenantList} component={TenantListPage} />
        <AdminRoute exact path={routePaths.internal.tenantDetails} component={TenantDetailsPage} />
        <AdminRoute exact path={routePaths.internal.tenantEdit} component={TenantEditPage} />
        <AdminRoute exact path={routePaths.internal.tenantNew} component={TenantNewPage} />

        <AdminRoute exact path={routePaths.settings.legacySalesSettings} component={LegacySalesSettingsPage} />
        <RecurrencyRoleRoute exact recurrencyRoles={recurrencyRolesToShowSettingsPage} path={routePaths.settings.settings} component={SettingsPage} />
        <RecurrencyRoleRoute exact recurrencyRoles={[RecurrencyRole.TenantAdmin]} path={routePaths.settings.users} component={UsersPage} />
        <RecurrencyRoleRoute exact recurrencyRoles={[RecurrencyRole.TenantAdmin]} path={routePaths.settings.replenishmentPaths} component={ReplenishmentPathsPage} />
        <RecurrencyRoleRoute exact recurrencyRoles={[RecurrencyRole.TenantAdmin]} path={routePaths.settings.locationSettings} component={LocationSettingsPage} />
        <Route exact path={routePaths.settings.exportJobs} component={ExportJobsPage} />
        <Route exact path={routePaths.settings.exportJobDetails} component={ExportJobDetailsPage} />

        <Route exact path={routePaths.account.accountEdit} component={EditDetailsPage} />
        <Route exact path={routePaths.setup.integration} component={Integration} />

        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}
