import React from 'react';

import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { MiniTableSkeleton } from 'components/MiniTable';
import { CollapsePanels } from 'components/recipes/sidePane/CollapsePanels';
import { LocationAvailabilityPanelContent } from 'components/recipes/sidePane/LocationAvailabilityPanelContent';
import { LocationAvailabilityProperties } from 'components/recipes/sidePane/LocationAvailabilityProperties';
import { SalesInvoiceLinesPanelContent } from 'components/recipes/sidePane/SalesInvoiceLinesPanelContent';
import { SalesOrderLinesPanelContext } from 'components/recipes/sidePane/SalesOrderLinesPanelContext';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { panelTitleByType } from 'components/recipes/sidePane/types';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { splitIfIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { getSearchIndexItemByItemId } from 'utils/search/search';
import { SidePanePanelType } from 'utils/track';

import { OrderEditHashStateSAPB1, OrderLineItemSAPB1 } from 'types/hash-state';
import { SearchIndexItemPartial } from 'types/search-collections';

import { LineItemActionsSAPB1 } from '../../types';
import { LotProperties } from './LotProperties';
import { PriceAndCostProperties } from './PriceAndCostProperties';

export function LineItemDetailsSidePaneSAPB1({
  orderState,
  lineItem,
  lineItemActions,
  lineItemIdx,
}: {
  orderState: OrderEditHashStateSAPB1;
  lineItem: OrderLineItemSAPB1;
  lineItemActions: LineItemActionsSAPB1;
  lineItemIdx: number;
}) {
  const { data: searchIndexItem } = usePromise(
    () => getSearchIndexItemByItemId(lineItem.foreignId),
    [lineItem.foreignId],
    {
      cacheKey: `search:${JSON.stringify([SearchIndexName.Items, lineItem.foreignId])}`,
    },
  );

  // show loading shell
  if (!searchIndexItem) {
    return (
      <SidePane title={<>Item #{lineItem.foreignId}</>} description={lineItem.name} content={<MiniTableSkeleton />} />
    );
  }

  return (
    <SidePane
      title={
        <>
          Item{' '}
          <a href={routes.sales.itemDetails(lineItem.foreignId)} target="_blank" rel="noreferrer">
            #{lineItem.foreignId}
          </a>
        </>
      }
      description={searchIndexItem.item_desc}
      extendedDescription={searchIndexItem.extended_desc}
      navTabs={[
        {
          header: 'Details',
          content: (
            <ItemDetailsTabContent
              lineItem={lineItem}
              lineItemIdx={lineItemIdx}
              lineItemActions={lineItemActions}
              algoliaItem={searchIndexItem}
              orderState={orderState}
            />
          ),
        },
      ]}
    />
  );
}

export function ItemDetailsTabContent({
  lineItem,
  lineItemActions,
  lineItemIdx,
  algoliaItem,
  orderState,
}: {
  lineItem: OrderLineItemSAPB1;
  lineItemActions: LineItemActionsSAPB1;
  lineItemIdx: number;
  algoliaItem: SearchIndexItemPartial;
  orderState: OrderEditHashStateSAPB1;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const { item_id: itemId } = algoliaItem;
  const customerId = splitIfIdNameStr(orderState.customer)?.foreignId || '';
  const locationId = splitIfIdNameStr(orderState.location)?.foreignId;
  const shouldShowCostAndGM = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesShowCostAndGrossMargin,
  );

  return (
    <>
      {locationId ? <LocationAvailabilityProperties algoliaItem={algoliaItem} locationId={locationId} /> : null}
      {lineItem.unitOfMeasure && shouldShowCostAndGM ? <PriceAndCostProperties lineItem={lineItem} /> : null}
      {algoliaItem.lots_assignable ? (
        <LotProperties
          lineItem={lineItem}
          lineItemIdx={lineItemIdx}
          lineItemActions={lineItemActions}
          locationId={locationId}
        />
      ) : null}
      <CollapsePanels
        arrowPosition="right"
        panels={[
          {
            title: panelTitleByType[SidePanePanelType.LocationAvailability],
            content: <LocationAvailabilityPanelContent itemUid={itemId} />,
          },
          {
            title: panelTitleByType[SidePanePanelType.OrdersOfItemToCustomer],
            content: <SalesOrderLinesPanelContext itemId={itemId} customerId={customerId} />,
          },
          {
            title: panelTitleByType[SidePanePanelType.SalesOfItemToCustomer],
            content: <SalesInvoiceLinesPanelContent itemId={itemId} customerId={customerId} />,
          },
          {
            title: panelTitleByType[SidePanePanelType.SalesOfItemAllCustomers],
            content: <SalesInvoiceLinesPanelContent itemId={itemId} />,
          },
        ]}
      />
    </>
  );
}
