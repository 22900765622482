import React from 'react';

import { Link } from 'react-router-dom';

import { DeploymentUnitOutlined } from '@ant-design/icons';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { SalesQuoteLineDTO } from '@recurrency/core-api-schema/dist/salesQuoteLines/getSalesQuoteLines';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { Table } from 'components/Table';

import { routes } from 'utils/routes';
import { sortableStringColumn, sortableNumberColumn, sortableDollarWithCentsColumn } from 'utils/tables';

import { ErpOrderStatusBadge } from './ErpOrderStatusBadge';

export const QuoteAssemblyLinesTable = ({
  quoteLines,
  quoteLineUid,
  onClick,
}: {
  quoteLines: SalesQuoteLineDTO[];
  quoteLineUid: string;
  onClick: (itemId: string, quoteLineUid: string) => void;
}) => {
  const columns: ColumnType<SalesQuoteLineDTO>[] = [
    sortableStringColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      width: '150px',
      render: (itemId: string, record: SalesQuoteLineDTO) => (
        <>
          <Link to={routes.sales.itemDetails(itemId)}>{itemId}</Link>&nbsp;
          {record.assemblyFlag && (
            <Button size="small" onClick={() => onClick(record.itemId, record.quoteLineUid || '0')}>
              <DeploymentUnitOutlined />
            </Button>
          )}
        </>
      ),
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemName',
    }),
    sortableStringColumn({
      title: 'Status',
      dataIndex: 'status',
      render: (status: ErpOrderStatus) => <ErpOrderStatusBadge erpOrderStatus={status} />,
    }),
    sortableNumberColumn({
      title: 'Qty per Assembly',
      dataIndex: 'quantityPerAssembly',
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    sortableNumberColumn({
      title: 'Qty Ordered',
      dataIndex: 'quantityOrdered',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
    }),
  ];

  return (
    <Table
      columns={columns}
      style={{ height: '70vh' }}
      pageSize={10}
      data={quoteLines.filter((line) => line.parentQuoteLineUid && line.parentQuoteLineUid === quoteLineUid)}
    />
  );
};
