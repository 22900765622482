import React from 'react';

import { css } from '@emotion/css';
import { Col, Row } from 'antd';

import { PropertyListItem, PropertyListItemProps } from 'components/PropertyListItem';

export interface DetailPagePropertyListItem extends PropertyListItemProps {
  colspan?: number;
}

export const DetailPagePropertyList = ({ rows, flex }: { rows: DetailPagePropertyListItem[][]; flex?: boolean }) => (
  <>
    {rows.map((row, index) => (
      <Row
        gutter={[24, 16]}
        className={css`
          margin-bottom: 16px;
        `}
        key={index}
      >
        {row.map((property) => (
          <Col
            flex={flex ? 'auto' : undefined}
            lg={8 * (property.colspan ?? 1)}
            md={12 * (property.colspan ?? 1)}
            sm={12 * (property.colspan ?? 1)}
            xs={24 * (property.colspan ?? 1)}
            key={property.label}
          >
            <PropertyListItem iconAlign="start" {...property} />
          </Col>
        ))}
      </Row>
    ))}
  </>
);
