import React from 'react';

import { css } from '@emotion/css';

import { Modal } from 'components/Modal';

export function BackorderModal({ onClose }: { onClose: (ok?: boolean) => void }) {
  return (
    <Modal visible title="Backorder" onCancel={() => onClose(false)} onOk={() => onClose(true)} width={600}>
      <div
        className={css`
          margin-bottom: 20px;
        `}
      >
        Line item's full quantity will be backordered. No quantity will be allocated for this line item.
      </div>
    </Modal>
  );
}
