import { ErpOrderStatus, IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';
import moment from 'moment';
import { theme } from 'theme';

import { getAmountWithCentsTo2Decimals } from 'pages/payments/common/paymentUtils';

import { IdNameObj } from 'types/legacy-api';

const ZERO_MARGIN = 0.03;

/** Used to get the color of a change in some value */
export const getChangeColor = (number: string | number) =>
  (typeof number === 'string' ? parseFloat(number) > 0 : number > 0)
    ? theme.colors.success[500]
    : theme.colors.danger[500];

function parseNumber(value: Maybe<number | string>) {
  const num = Number(value);
  if (value === '' || value === null || value === undefined || Number.isNaN(num)) {
    return NaN;
  }

  return num;
}

/**
 * format number with given decimal point
 * @param value string or number type value that can be represented as a number
 * @param decimal count of decimal point with 4 as a default value
 * @param decimal count of decimal point with 4 as a default value
 */

export const formatNumber = (
  value: Maybe<number | string>,
  maxFractionDigits = 2,
  minFractionDigits = 0,
  options?: Intl.NumberFormatOptions,
) => {
  let num = parseNumber(value);
  if (Number.isNaN(num)) {
    return '-';
  }

  // this avoids the -0 bug which shows for very small numbers
  if (maxFractionDigits === 2) {
    num = roundTo2Decimals(num) || 0;
  }

  let formatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  };
  if (options) formatterOptions = { ...formatterOptions, ...options };

  const formatter = new Intl.NumberFormat('en-US', formatterOptions);

  return options?.notation === 'compact' ? formatter.format(num).toLocaleString() : formatter.format(num);
};

/**
 * Truncate string to a number of characters (including ellipsis)
 * @param input string to be truncated
 * @param length (minimum of 4) number of characters truncated string should be
 */

export const truncate = (input: string, length = 15) => {
  if (input.length > length - 3) {
    return `${input.substring(0, length - 3)}...`;
  }
  return input;
};

export const roundTo2Decimals = (value: number): number => Math.round(value * 100) / 100;
export const roundTo1Decimal = (value: number): number => Math.round(value * 10) / 10;

const precisionCache: Record<number, number> = {};
export const roundToPrecision = (value: number, precision: number, method = Math.round) => {
  let power = precisionCache[value];
  if (!power) {
    power = 10 ** precision;
    precisionCache[value] = power;
  }
  return method(value * power) / power;
};

/**
 * Takes a USD currency value and returns an abbreviated string ("compact notation")
 *
 * 3600 => "$3.6K"
 * 74757 => "$74.8K"
 * -1234567890 => "-$1.23B"
 */
export const formatUSDAbbreviated = (value: number, maximumFractionDigits?: number) => {
  if (Number.isNaN(value)) {
    return '-';
  }

  // handle -0 case
  if (value === 0) value = 0;

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumSignificantDigits: 3,
    minimumSignificantDigits: 1,
  };
  if (maximumFractionDigits !== undefined) {
    options.maximumFractionDigits = maximumFractionDigits;
    options.minimumFractionDigits = 0;
  }

  // TODO: only thing that uses this now assumes USD and en-US, probably want to be more agnostic, I assume we'll want to do other currencies at some point, as well....
  const formatter = new Intl.NumberFormat('en-US', options);

  return formatter.format(Number(value));
};

export const formatUSD = (value: Maybe<number | string>, showCents = false, precision?: number) => {
  if (value === null || value === undefined || Number.isNaN(Number(value))) {
    return '-';
  }

  // handle -0 case
  if (value === 0) value = 0;

  const isValueNearZero = value !== 0 && Number(value) > -1 * ZERO_MARGIN && Number(value) < ZERO_MARGIN;

  const numDigits = typeof precision === 'number' ? precision : showCents ? (isValueNearZero ? 4 : 2) : 0;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: numDigits,
    minimumFractionDigits: numDigits,
  });

  const formatted = formatter.format(Number(value)).toLocaleString();
  return formatted;
};

export const formatCents = (value: Maybe<number | string>) => {
  if (value === null || value === undefined || Number.isNaN(Number(value))) {
    return '-';
  }
  return formatUSD(getAmountWithCentsTo2Decimals(value), true, 2);
};

/**
 * Default formatDate behavior keeps datetime intact and strip timezone info.
 * Most dates come from ERP without timezone. They are displayed as-is.
 * @param withTimestamp - show date with timestamp in h:mma format
 * @param inLocalTime we assume the date is in UTC, and convert to user's browser-local time.
 */
export const formatDate = (date: string | undefined, withTimestamp = false, inLocalTime = false) => {
  if (!date || !moment(date).isValid()) {
    return '-';
  }

  const momentDate = inLocalTime ? moment(date) : moment.utc(date);
  return momentDate.format(withTimestamp ? 'MM/DD/YYYY h:mma' : 'MM/DD/YYYY');
};

export const formatDateShorthand = (date: string | undefined | null) => {
  if (!date || !moment(date).isValid()) {
    return '-';
  }

  return moment(date).format('MM/DD/YY');
};

export const formatHumanDate = (dateString: string) => {
  const date = moment(dateString);
  if (date.isValid()) {
    const today = moment().startOf('day');
    const startDay = date.startOf('day');
    if (startDay.isSame(today)) {
      return 'today';
    }
    const hourDiff = moment.duration(date.diff(moment())).asHours();
    if (hourDiff > 0 && hourDiff < 24) {
      return 'tomorrow';
    }
    // we calculate this ourselves because the moment fromNow() function rounds up if above .5 and down
    //  if below .5, and we want it to use ceiling if in the future and floor if in the past
    const dayDiff = moment.duration(startDay.diff(today)).asDays();
    if (dayDiff > 0) {
      return `In ${pluralize(Math.ceil(dayDiff), 'day', 'days', true)}`;
    }
    if (dayDiff > -2) {
      return 'yesterday';
    }
    return `${pluralize(Math.floor(-dayDiff), 'day', 'days', true)} ago`;
  }
  return '-';
};

export function formatDuration(durationMs: number) {
  if (!durationMs) return '-';

  if (Math.abs(durationMs) < 1000) {
    return `${durationMs / 1000}s`;
  }

  let seconds = Math.floor(durationMs / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  seconds %= 60;

  return [
    hours > 0 ? `${hours.toString().padStart(2, '0')}h` : '',
    minutes > 0 ? `${minutes.toString().padStart(2, '0')}m` : '',
    seconds > 0 ? `${seconds.toString().padStart(2, '0')}s` : '',
  ]
    .filter((s) => s !== '')
    .join(' ');
}

export const formatPercent = (percent: Maybe<number | string>, numDecimals = 2) => {
  const numPercent = Number(percent);
  if (percent === null || percent === undefined || Number.isNaN(numPercent)) {
    return '-';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: numDecimals,
  });

  const formatted = formatter.format(Number(numPercent)).toLocaleString();
  return formatted;
};

export const EMPTY_VALUE_DASHES = `---`;

export const dashesIfEmpty = (value: Maybe<string>) =>
  value === '' || value === undefined || value === null ? EMPTY_VALUE_DASHES : value;

export interface Address {
  name?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  zip?: string | number;
  state?: string;
  phone?: string;
}

// TODO: fix formatAddress to also use Address interface
export const formatAddress = (
  address1: Maybe<string>,
  city: Maybe<string>,
  state: Maybe<string>,
  zip: Maybe<string | number>,
) => {
  if (!address1 || !city || !state || !zip) {
    return '-';
  }

  return `${address1}, ${city}, ${state} ${zip}`;
};

export const formatAddressMultiLine = ({ name, address1, address2, address3, city, state, zip, phone }: Address) =>
  [
    ...[name, address1, address2, address3].map((line) => (line ? `${line}\n` : ``)),
    city ? `${city}, ` : ``,
    `${state || ``} ${zip || ``}`,
    phone ? `\n${phone}` : ``,
  ].join('');

export const joinTruthy = (values: Array<string | number | boolean | undefined | null>, joinStr: string): string =>
  values.filter((v) => v === 0 || !!v).join(joinStr);

export const formatPartialAddress = ({
  address1,
  address2,
  city,
  state,
  zip,
}: {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string | number;
}) => joinTruthy([address1, address2, city, joinTruthy([state, zip], ' ')], ', ');

export const joinAnd = (strs: string[]) => {
  if (strs.length <= 2) {
    return strs.join(' and ');
  }
  return [strs.slice(0, -1).join(', '), strs[strs.length - 1]].join(', and ');
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
export const capitalizeFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const pluralize = (count: number, singular: string, plural?: string, inclusive = false) =>
  (inclusive ? `${formatNumber(count)} ` : '') + (count === 1 ? singular : plural || `${singular}s`);

/** prefixes string with 'a' or 'an' depending on whether str is vowel or not */
export const addArticle = (str: string) => `${/^[aeiou]/.test(str) ? 'an' : 'a'} ${str.toLowerCase()}`;

export const formatId = (id: Maybe<string>): string => id || '-';

export const formatName = (firstName?: string, lastName?: string): string =>
  [firstName, lastName].filter(Boolean).join(' ');

/** SAPB1 uses shipToName (Adress) as the shipToId, and the shipToName is '', so we only show shipToId */
export const formatShipTo = (shipToId: string, shipToName: Maybe<string>): string =>
  shipToName ? joinIdNameObj({ name: shipToName, foreignId: shipToId }) : shipToId;

export function formatPhoneNumber(number: string) {
  const cleaned = `${number}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return undefined;
}

export function formatErpOrderStatus(status: ErpOrderStatus): string {
  return capitalize(status);
}

export const splitIdNameStr = (idNameStr: string): IdNameObj => {
  // using regex for split so we only split on the first colon (with spaces on both sides trimmed)
  const colonIdx = idNameStr.indexOf(':');
  if (colonIdx === -1) {
    throw new Error(`Invalid IdNameStr: '${idNameStr}', need ':' as separator`);
  }
  const foreignId = idNameStr.slice(0, colonIdx).trim();
  const name = idNameStr.slice(colonIdx + 1).trim();
  return { foreignId, name };
};

export const splitIfIdNameStr = (idNameStr: string | undefined): IdNameObj | undefined =>
  idNameStr && idNameStr.indexOf(':') >= 0 ? splitIdNameStr(idNameStr) : undefined;

export const fieldsToIdNameObj = (id: string, name: string) =>
  joinIfIdNameObj({
    foreignId: id,
    name,
  });

export const joinIdNameObj = (foreignIdNameRef: IdNameObj) =>
  `${foreignIdNameRef.foreignId}: ${(foreignIdNameRef.name || '').trim()}`;

export const joinIfIdNameObj = (foreignIdNameRef: IdNameObj | undefined): string | undefined =>
  foreignIdNameRef && foreignIdNameRef.foreignId ? joinIdNameObj(foreignIdNameRef) : undefined;

export const formatIdNameObj = (foreignIdNameRef: IdNameObj | undefined) =>
  foreignIdNameRef ? joinIdNameObj(foreignIdNameRef) : '-';

export const joinObjectId = (ids: string[], delimiter = '|') => ids.join(delimiter);

export const isStringEqual = (a: string | number, b: string | number) => `${a}` === `${b}`;

/** convert to 'Title Case' e.g 'hello world' -> 'Hello World' */
export const toTitleCase = (str: string) => str.split(' ').map(capitalize).join(' ');

/** Convert to 'dash-case' e.g 'Availability & Cost' -> 'availability-cost' */
export const toDashCase = (str: string) => str.toLowerCase().replace(/[^a-z0-9]+/g, '-');

/** Convert to 'snake_case' e.g 'Availability & Cost' -> 'availability_cost' */
export const toSnakeCase = (str: string) => str.toLowerCase().replace(/[^a-z0-9]+/g, '_');

/** e.g 'sales-dashboard' to 'Sales Dashboard' */
export const dashCaseToTitleCase = (str: string) => toTitleCase(str.replace('-', ' '));

/** e.g 'REVIEW_CYCLE_DAYS' to 'Review Cycle Days' */
export const snakeCaseToTitleCase = (str: string) => str.split('_').map(capitalizeFirst).join(' ');

/**
 * Used to convert 'packingList' -> 'Packing List'
 * e.g used for document names from event type
 */
export function camelToTitleCase(input: string) {
  const result = input.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const erpNameByType: Record<IntegratedErps, string> = {
  [IntegratedErps.P21]: 'P21',
  [IntegratedErps.SAPB1]: 'SAP B1',
  [IntegratedErps.ECLIPSE]: 'Eclipse',
  [IntegratedErps.NETSUITE]: 'NetSuite',
};

export function getErpName(erpType: IntegratedErps) {
  return erpNameByType[erpType];
}

export function getSearatesTrackingUrl(containerName: string) {
  return `https://searates.com/container/tracking/?container=${containerName}`;
}

export function convertSnakeCaseToSpaceSplit(input: string): string {
  const words = input.split('_');
  const cappedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  const result = cappedWords.join(' ');

  return result;
}
