import moment from 'moment';

/**
 * Compute the number of days until the next forecasting run on the next Sunday
 * or 1st of the month. Use UTC as DAGs are scheduled in UTC.
 */
export const getNextUsageUpdateDate = () => {
  const today = moment().utc();
  const todayDayOfWeek = today.day();

  // Calculate days until next Sunday (7 if today is Sunday)
  const daysUntilSunday = 7 - todayDayOfWeek;

  // Calculate days until the 1st of the next month
  const startOfNextMonth = moment(today).utc().add(1, 'months').startOf('month');
  const daysUntilFirstOfMonth = startOfNextMonth.diff(today, 'days');

  // Return the minimum of the two calculated days
  return Math.min(daysUntilSunday, daysUntilFirstOfMonth);
};
