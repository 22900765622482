import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { ItemTransferLineDTO } from '@recurrency/core-api-schema/dist/items/getItemTransferLines';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { formatDate, formatNumber } from 'utils/formatting';
import { typedColumn } from 'utils/tables';
import { qtyUnitConverter } from 'utils/units';

export const OpenTransferLinesTable = ({ invMastUid }: { invMastUid: string | undefined }) => {
  const columns: ColumnType<ItemTransferLineDTO>[] = [
    typedColumn({
      title: 'Transfer No',
      dataIndex: 'transferNumber',
    }),
    typedColumn({
      title: 'Transfer Date',
      dataIndex: 'transferDate',
      render: (transferDate) => formatDate(transferDate),
    }),
    typedColumn({
      title: 'From Location',
      dataIndex: 'fromLocationId',
      render: (fromLocationId, { fromLocationName }) =>
        fromLocationId ? `${fromLocationId}: ${fromLocationName}` : '-',
    }),
    typedColumn({
      title: 'To Location',
      dataIndex: 'toLocationId',
      render: (toLocationId, { toLocationName }) => (toLocationId ? `${toLocationId}: ${toLocationName}` : '-'),
    }),
    typedColumn({
      title: 'Qty to Transfer',
      dataIndex: 'quantityToTransfer',
      render: (quantityToTransfer, { unitSize }) => formatNumber(qtyUnitConverter(quantityToTransfer, unitSize)),
    }),
    typedColumn({
      title: 'Qty Transferred',
      dataIndex: 'quantityTransferred',
      render: (quantityTransferred, { unitSize }) => formatNumber(qtyUnitConverter(quantityTransferred, unitSize)),
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    }),
    typedColumn({
      title: 'Carrier',
      dataIndex: 'carrierId',
      render: (carrierId, { carrierName }) => (carrierId ? `${carrierId}: ${carrierName}` : '-'),
    }),
  ];

  return (
    <AsyncTable
      tableProps={useCoreApiTableProps({
        schema: schemas.items.getItemTransferLines,
        pathParams: {
          itemUid: invMastUid ?? '',
        },
      })}
      columns={columns}
    />
  );
};
