import React from 'react';

import { Link } from 'react-router-dom';

import { BuildOutlined, GlobalOutlined, UserOutlined } from '@ant-design/icons';
import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { NewCustomerButton } from 'components/recipes/NewCustomerButton';
import { NewProspectButton } from 'components/recipes/NewProspectButton';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { splitIfIdNameStr } from 'utils/formatting';
import { isAdmin } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { sortableStringColumn } from 'utils/tables';

import { SearchIndexCustomer } from 'types/search-collections';

export const CustomerListPage = () => {
  const { activeErpRole, activeTenant } = useGlobalApp();
  const isUserAdminRole = isAdmin(activeErpRole.foreignId, activeErpRole.name);

  const columns: (ColumnType<SearchIndexCustomer> | null)[] = [
    {
      title: 'ID',
      dataIndex: 'customer_id',
      render: (id: string, record: SearchIndexCustomer) => (
        <Link to={routes.sales.customerDetails(id, { companyId: record.company_id })}>{id}</Link>
      ),
    },
    sortableStringColumn({
      title: 'Customer',
      dataIndex: 'customer_name',
      sorter: true,
    }),
    activeTenant.erpType === IntegratedErps.P21
      ? {
          title: 'Company',
          dataIndex: 'company',
          render: (company: string) => splitIfIdNameStr(company)?.name,
        }
      : null,
    { title: 'Address', dataIndex: 'phys_address1' },
    {
      title: 'City',
      dataIndex: 'phys_city',
    },
    {
      title: 'State',
      dataIndex: 'phys_state',
    },
    {
      title: 'ZIP',
      dataIndex: 'phys_postal_code',
    },
    isUserAdminRole
      ? {
          title: 'Primary Rep',
          dataIndex: 'salesrep_fullname',
        }
      : null,
    {
      title: '',
      dataIndex: 'customer_id',
      render: (customerId: string, record: SearchIndexCustomer) =>
        ButtonLink(routes.sales.customerDetails(customerId, { companyId: record.company_id })),
    },
  ];

  const valueFacets: (ValueFacet<SearchIndexCustomer> | null)[] = [
    {
      title: 'Type',
      field: 'customer_type',
      icon: <BuildOutlined />,
    },
    isUserAdminRole
      ? {
          title: 'Sales Rep',
          queryPlaceholder: 'Search sales reps',
          field: 'salesreps',
          icon: <UserOutlined />,
        }
      : null,
    {
      title: 'City',
      queryPlaceholder: 'Search cities',
      field: 'phys_city',
      icon: <GlobalOutlined />,
    },
    {
      title: 'State',
      queryPlaceholder: 'Search states',
      field: 'phys_state',
      icon: <GlobalOutlined />,
    },
  ];

  return (
    <SearchFrame<SearchIndexCustomer>
      indexName={SearchIndexName.Customers}
      title="Customers"
      queryPlaceholder="Search customers by ID or name"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      headerActions={
        <>
          <NewProspectButton />
          <NewCustomerButton />
        </>
      }
    />
  );
};
