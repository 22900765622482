import React from 'react';

import { css } from '@emotion/css';
import { theme } from 'theme';

import { Input } from 'components/Input';

export const PositiveNumberInput = ({
  value,
  disabled,
  onChange,
  min = 0,
}: {
  value: number | undefined;
  disabled: boolean;
  onChange: (value?: number) => void;
  min?: number;
}) => {
  const hasError = () => !(value === undefined || value >= 0);
  const renderInputValueError = hasError() ? (
    <div
      className={css`
        color: ${theme.colors.danger[500]};
      `}
    >
      value must be greater or equal to {min}
    </div>
  ) : null;
  return (
    <div>
      <Input
        type="number"
        disabled={disabled}
        onChange={(ev) => {
          const currentInputValue = ev.currentTarget.value;
          const numericInputValue = currentInputValue === '' ? undefined : Number(currentInputValue);
          onChange(numericInputValue);
        }}
        value={value}
        min={min}
        validate={() => !hasError()}
      />
      {renderInputValueError}
    </div>
  );
};
