import React from 'react';

import { DocumentDTO } from '@recurrency/core-api-schema/dist/common/documentDTO';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { UseAsyncTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { camelToTitleCase, formatDate } from 'utils/formatting';
import { typedColumn } from 'utils/tables';
import { PdfDocumentType } from 'utils/track';

import { OpenPdfButton } from './OpenPdfButton';
import { SendPdfEmailButton } from './SendPdfEmailButton';

export interface PdfDocument extends DocumentDTO {
  type: PdfDocumentType;
}

export const DocumentsTable = ({ tableProps }: { tableProps: UseAsyncTableProps<DocumentDTO> }) => {
  const columns: ColumnType<PdfDocument>[] = [
    typedColumn({
      title: 'Type',
      dataIndex: 'type',
      width: '20%',
      render: (type) => camelToTitleCase(type),
    }),
    typedColumn({
      title: 'ID',
      width: '20%',
      dataIndex: 'foreignId',
    }),
    typedColumn({
      title: 'Created',
      width: '20%',
      dataIndex: 'dateCreated',
      render: (date) => formatDate(date),
    }),
    typedColumn({
      title: 'Last Modified',
      width: '20%',
      dataIndex: 'dateLastModified',
      render: (date) => formatDate(date),
    }),
    typedColumn({
      title: 'Actions',
      width: '250px',
      align: 'left',
      render: (record) => (
        <div style={{ display: 'flex', gap: 8 }}>
          <SendPdfEmailButton foreignId={record.foreignId} type={record.type} />
          <OpenPdfButton foreignId={record.foreignId} type={record.type} />
        </div>
      ),
    }),
  ];

  return <AsyncTable tableProps={tableProps} columns={columns} />;
};
