import React from 'react';

import { Link } from 'react-router-dom';

import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  FileTextOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';

import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSections, DetailPageSection } from 'components/recipes/detailPage/DetailPageSections';
import { addressPropertyListItem } from 'components/recipes/detailPage/propertyListItemUtils';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';

import { useCoreApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import {
  dashesIfEmpty,
  EMPTY_VALUE_DASHES,
  formatAddressMultiLine,
  formatDate,
  formatPhoneNumber,
  formatUSD,
} from 'utils/formatting';
import { routes, usePathParams, IdPathParams } from 'utils/routes';

import { OrderLinesAgnosticTable } from './tabs/OrderLinesAgnosticTable';

export const OrderDetailsPageAgnostic = () => {
  const { id: salesOrderNumber } = usePathParams<IdPathParams>();

  const {
    data: order,
    error: orderError,
    isLoading: isOrderLoading,
  } = useCoreApi(schemas.salesOrders.getSalesOrderDetailsV2, {
    pathParams: { salesOrderId: salesOrderNumber },
  });

  if (orderError) {
    return <CenteredError error={orderError} />;
  }

  if (isOrderLoading || !order) {
    return <CenteredLoader />;
  }

  if (Object.keys(order).length === 0) {
    return <NoDataMessage />;
  }

  const headerSections: DetailPageSection[] = [
    {
      title: 'Customer',
      rows: [
        [
          {
            icon: <UserOutlined />,
            label: 'Customer',
            value: <Link to={routes.sales.customerDetails(order.customerId)}>{order.customerName}</Link>,
            id: order.customerId,
          },
          {
            icon: <UserOutlined />,
            label: 'Contact',
            value: dashesIfEmpty(order.contact?.name),
          },
          { label: 'PO No.', value: dashesIfEmpty(order.customerPoRef), icon: <FileTextOutlined /> },
          {
            label: 'Terms',
            value: dashesIfEmpty(order.terms),
            icon: <FileTextOutlined />,
          },
        ].filter(truthy),
      ],
    },
    {
      title: 'Order Configuration',
      rows: [
        [
          {
            icon: <CalendarOutlined />,
            label: 'Order Date',
            value: formatDate(order.orderDate),
          },
          {
            icon: order.approved ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
            label: 'Approved',
            value: order.approved ? 'Yes' : 'No',
          },
          { icon: <CalendarOutlined />, label: 'Due Date', value: formatDate(order.dueDate) },
          { label: 'Taker', value: dashesIfEmpty(order.taker?.name), icon: <UserOutlined />, id: order.taker?.id },
          {
            label: 'Total Price',
            value: order.totalPrice ? formatUSD(order.totalPrice, true) : EMPTY_VALUE_DASHES,
            icon: <DollarOutlined />,
          },
          {
            label: 'Total Open Price',
            value: order.totalPrice ? formatUSD(order.totalOpenPrice, true) : EMPTY_VALUE_DASHES,
            icon: <DollarOutlined />,
          },
        ].filter(truthy),
      ],
    },
    {
      title: 'Logistics',
      rows: [
        [
          {
            label: 'Sales Location',
            value: order.locationName ? `${order.locationName}` : EMPTY_VALUE_DASHES,
            id: order.locationId,
          },
          {
            label: 'Carrier',
            value: dashesIfEmpty(order.carrier?.name),
            id: order.carrierId,
          },
        ].filter(truthy),
        [
          addressPropertyListItem({
            label: 'Bill to',
            value: formatAddressMultiLine({
              name: order.customerName,
              address1: order.billToAddress?.address1,
              address2: order.billToAddress?.address2,
              address3: order.billToAddress?.address3,
              city: order.billToAddress?.city,
              state: order.billToAddress?.state,
              zip: order.billToAddress?.zipcode,
            }),
            id: order.customerId,
          }),
          addressPropertyListItem({
            label: 'Ship To',
            value: formatAddressMultiLine({
              name: order.customerName,
              address1: order.shipToAddress?.address1,
              address2: order.shipToAddress?.address2,
              address3: order.shipToAddress?.address3,
              city: order.shipToAddress?.city,
              state: order.shipToAddress?.state,
              zip: order.shipToAddress?.zipcode,
              phone: order.shipToAddress?.centralPhoneNumber
                ? formatPhoneNumber(order.shipToAddress?.centralPhoneNumber)
                : undefined,
            }),
            id: order.shipToId,
          }),
          order.deliveryInstructions && order.deliveryInstructions.length > 0
            ? {
                label: 'Delivery Instructions',
                value: <div style={{ whiteSpace: 'pre-line' }}>{order.deliveryInstructions}</div>,
              }
            : null,
        ].filter(truthy),
      ],
    },
  ];

  return (
    <Container>
      <PageHeader
        title={salesOrderNumber}
        copyable
        entity={{ kind: 'Order', badge: <ErpOrderStatusBadge erpOrderStatus={order.status} /> }}
      />
      <DetailPageSections sections={headerSections} />
      <NavTabs
        tabs={[{ header: 'Order Lines', content: <OrderLinesAgnosticTable orderLines={order.lines || []} /> }].filter(
          truthy,
        )}
      />
    </Container>
  );
};
