import React from 'react';

import { Link } from 'react-router-dom';

import { DeploymentUnitOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { ItemComponentDTO } from '@recurrency/core-api-schema/dist/items/getItemComponents';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { routes } from 'utils/routes';
import { sortableStringColumn, sortableNumberColumn } from 'utils/tables';

export const AssemblyComponentsTable = ({ itemId }: { itemId: string }) => {
  const columns: ColumnType<ItemComponentDTO>[] = [
    sortableStringColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      width: '120px',
      render: (id: string, record: ItemComponentDTO) => (
        <Link to={routes.sales.itemDetails(id)}>
          {id} {record.isAssembly ? <DeploymentUnitOutlined /> : null}
        </Link>
      ),
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemDesc',
      render: (itemDesc, record: ItemComponentDTO) =>
        record.extendedDesc ? `${itemDesc} ${record.extendedDesc}` : itemDesc,
    }),
    sortableNumberColumn({
      title: 'Qty per Assembly',
      dataIndex: 'qtyPerAssembly',
    }),
    sortableStringColumn({
      title: 'Unit of Measure',
      dataIndex: 'unitOfMeasure',
      render: (unitOfMeasure) => unitOfMeasure.name,
    }),
    sortableNumberColumn({
      title: 'Unit Size',
      dataIndex: 'unitOfMeasure',
      render: (unitOfMeasure) => unitOfMeasure.unitSize,
    }),
  ];

  return (
    <AsyncTable
      tableProps={useCoreApiTableProps({
        schema: schemas.items.getItemComponents,
        pathParams: {
          itemId,
        },
      })}
      columns={columns}
    />
  );
};
