import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import {
  TenantSettingKey,
  TenantSettings,
  TenantSettingsFields,
} from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TenantSettingsField } from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';

import { Alert } from 'components/Alert';

import { pluralize } from 'utils/formatting';
import { objKeys } from 'utils/object';

interface PendingChange {
  key: TenantSettingKey;
  title: string;
  value: string;
  pendingValue: string;
}

export function PendingSettingsAlert({
  pendingSettings,
  settings,
}: {
  pendingSettings: Partial<TenantSettings>;
  settings: Partial<TenantSettings>;
}) {
  const pendingKeys = objKeys(pendingSettings);

  if (pendingKeys.length === 0) {
    return null;
  }

  const getValueLabel = (field: TenantSettingsField, value: Any): string => {
    if ('options' in field) {
      const option = field.options.find((o) => o.value === value);
      if (option) return option.label;
    }
    return typeof value === 'string' ? value : JSON.stringify(value);
  };

  const pendingChanges = pendingKeys
    .map(
      (key) =>
        ({
          key,
          title: TenantSettingsFields[key].title,
          value: getValueLabel(TenantSettingsFields[key], settings[key]),
          pendingValue: getValueLabel(TenantSettingsFields[key], pendingSettings[key]),
        } as PendingChange),
    )
    .filter((change) => change.value !== change.pendingValue);

  if (pendingChanges.length === 0) {
    return null;
  }

  return (
    <Alert
      className={css`
        margin-top: 24px;
      `}
      icon={<ExclamationCircleOutlined />}
      message={
        <div>
          <span>
            {pluralize(pendingChanges.length, 'pending change', 'pending changes', true)} will update automatically
            overnight as it requires significant recalculations.
          </span>
          <ul>
            {pendingChanges.map((change, idx) => (
              <li key={idx}>
                {change.title}: {change.value} → {change.pendingValue}
              </li>
            ))}
          </ul>
        </div>
      }
      type="info"
      banner
    />
  );
}
