import React from 'react';

import { Link } from 'react-router-dom';

import { DeleteOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { CompactUserDTO } from '@recurrency/core-api-schema/dist/common/compactUserDTO';
import { SavedViewDTO } from '@recurrency/core-api-schema/dist/savedViews/getSavedView';
import { Space } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { QuoteWithTotalInfo } from 'pages/orders/quotes/types';

import { Button } from 'components/Button';
import { Tooltip } from 'components/Tooltip';

import { formatName } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableStringColumn, sortableDateColumn } from 'utils/tables';

export const getSavedReportListColumns = (
  onSetPinned: (id: string, newStatus: boolean) => void,
  onDelete: (report: SavedViewDTO) => void,
) => {
  const columns: ColumnType<QuoteWithTotalInfo>[] = [
    sortableStringColumn({
      title: 'Report Name',
      dataIndex: 'name',
      render: (name: string, record: SavedViewDTO) => <Link to={routes.reporting.report(record.id)}>{name}</Link>,
    }),
    sortableStringColumn({
      title: 'Created By',
      dataIndex: 'user',
      render: (user: CompactUserDTO) => formatName(user.firstName, user.lastName),
    }),
    sortableDateColumn({
      title: 'Date Created',
      dataIndex: 'createdAt',
    }),
    sortableDateColumn({
      title: 'Date Modified',
      dataIndex: 'updatedAt',
      defaultSortOrder: 'descend',
    }),
    {
      title: 'Actions',
      dataIndex: '',
      render: (record: SavedViewDTO) => (
        <Space size="small">
          <Tooltip title={record.isPinned ? 'Unpin from Dashboard' : 'Pin to Dashboard'}>
            <Button
              type={record.isPinned ? 'primary' : 'default'}
              onClick={() => onSetPinned(record.id, !record.isPinned)}
            >
              {record.isPinned ? <PushpinFilled /> : <PushpinOutlined />}
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button danger onClick={() => onDelete(record)}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return columns;
};
