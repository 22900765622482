import React, { useState } from 'react';

import { css } from '@emotion/css';
import { GetReplenishmentRulesLocationDTO } from '@recurrency/core-api-schema/dist/replenishmentRules/getReplenishmentRules';

import { AsyncSelect } from 'components/AsyncSelect';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { Modal } from 'components/Modal';

import { truthy } from 'utils/boolean';
import { splitIdNameStr } from 'utils/formatting';

export interface EditReplenishmentPathNodeModalProps {
  currentNode: GetReplenishmentRulesLocationDTO;
  replenishmentPathTree: GetReplenishmentRulesLocationDTO[];
  onClose: () => void;
  setReplenishmentPathTree: (newReplenishmentPathTree: GetReplenishmentRulesLocationDTO[]) => void;
}

export const EditReplenishmentPathNodeModal = ({
  currentNode,
  replenishmentPathTree,
  onClose,
  setReplenishmentPathTree,
}: EditReplenishmentPathNodeModalProps) => {
  const { locationId, locationName } = currentNode;
  const initialParent = findParentNode(replenishmentPathTree, locationId);
  const initialParentLocation = initialParent ? `${initialParent.locationId}: ${initialParent.locationName}` : null;

  const [newParentLocation, setNewParentLocation] = useState(initialParentLocation);
  const locationsSelectProps = useLocationsSelectProps({ sortByValueProp: true });

  const locationsToFilterOut = [...getChildrenLocationIds(currentNode), locationId];
  const locationOptions = [
    { label: 'No Replenishment Location', value: '' },
    ...locationsSelectProps.options.filter((o) => !locationsToFilterOut.includes(splitIdNameStr(o.value).foreignId)),
  ];

  async function handleSubmit() {
    onClose();
    const newParentLocationId = newParentLocation ? splitIdNameStr(newParentLocation).foreignId : null;
    const newReeplenishmentPathTree = changeParentNode(replenishmentPathTree, newParentLocationId);
    setReplenishmentPathTree(newReeplenishmentPathTree);
  }

  function changeParentNode(
    tree: GetReplenishmentRulesLocationDTO[],
    newParentLocationId: string | null,
  ): GetReplenishmentRulesLocationDTO[] {
    let nodeToBeMoved: GetReplenishmentRulesLocationDTO = { locationId, locationName };

    const findAndRemoveNode = (node: GetReplenishmentRulesLocationDTO) => {
      if (node.locationId === locationId) {
        nodeToBeMoved = node;
        return null;
      }
      if (node.children) {
        node.children = node.children.map(findAndRemoveNode).filter(truthy);
      }
      return node;
    };

    const appendToNode = (node: GetReplenishmentRulesLocationDTO) => {
      if (node.locationId === newParentLocationId) {
        node.children = [...(node.children ?? []), nodeToBeMoved];
      } else if (node.children) {
        node.children = node.children.map(appendToNode);
      }
      return node;
    };

    const treeWithRemovedNode = tree.map(findAndRemoveNode).filter(truthy);
    return newParentLocationId ? treeWithRemovedNode.map(appendToNode) : [...treeWithRemovedNode, nodeToBeMoved];
  }

  function findParentNode(
    tree: GetReplenishmentRulesLocationDTO[],
    locationId: string,
  ): GetReplenishmentRulesLocationDTO | null {
    function findParentRecursive(
      nodes: GetReplenishmentRulesLocationDTO[],
      parent: GetReplenishmentRulesLocationDTO | null,
    ): GetReplenishmentRulesLocationDTO | null {
      for (const node of nodes) {
        if (node.locationId === locationId) {
          return parent;
        }
        if (node.children) {
          const result = findParentRecursive(node.children, node);
          if (result) {
            return result;
          }
        }
      }
      return null;
    }

    return findParentRecursive(tree, null);
  }

  function getChildrenLocationIds(node: GetReplenishmentRulesLocationDTO): string[] {
    const locationIds: string[] = [];

    function traverse(_node: GetReplenishmentRulesLocationDTO) {
      if (_node.children) {
        for (const child of _node.children) {
          locationIds.push(child.locationId);
          traverse(child); // Recursively traverse children
        }
      }
    }

    traverse(node);
    return locationIds;
  }

  return (
    <Modal
      visible
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Save
        </Button>,
      ]}
      title="Select new replenishment location"
      onCancel={onClose}
      width={900}
    >
      <Container>
        <div>
          Select a new replenishment location for {locationId}: {locationName}
        </div>
        <AsyncSelect
          className={css`
            margin-top: 20px;
            width: 100%;
          `}
          selectProps={{
            ...locationsSelectProps,
            options: locationOptions,
          }}
          entityPlural="locations"
          allowClear
          value={newParentLocation}
          onChange={setNewParentLocation}
        />
      </Container>
    </Modal>
  );
};
