import React from 'react';

import { css } from '@emotion/css';
import Progress from 'antd/lib/progress';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';

import { formatPercent, formatUSD } from 'utils/formatting';

export function PurchaseAutoFillTrimStatus({
  targetValue,
  recommendedValue,
  currentValue,
  hideWarning,
  currentPOCost,
  recommendedPOCost,
}: {
  targetValue: number;
  recommendedValue: number;
  currentValue: number;
  hideWarning: boolean;
  currentPOCost: number;
  recommendedPOCost: number;
}) {
  return (
    <FlexSpace direction="column">
      <FlexSpace>
        <FlexSpace
          className={css`
            width: 390px;
            padding: 8px 12px;
            gap: 8px;
            border-radius: 8px;
            color: ${theme.colors.primary[800]};
            border: 1px solid ${theme.colors.primary[300]};
          `}
        >
          <span
            className={css`
              white-space: nowrap;
            `}
          >
            Autocomplete <b>{formatPercent(currentValue / targetValue, 0)}</b> to{' '}
            <b
              className={css`
                color: ${theme.colors.success[500]};
              `}
            >
              {formatPercent(recommendedValue / targetValue, 0)}
            </b>
          </span>
          <Progress
            percent={(recommendedValue / targetValue) * 100}
            showInfo={false}
            success={{ percent: (currentValue / targetValue) * 100, strokeColor: theme.colors.primary[600] }}
            strokeColor={theme.colors.success[500]}
          />
        </FlexSpace>
        <FlexSpace
          className={css`
            padding: 8px 12px;
            gap: 8px;
            border-radius: 8px;
            color: ${theme.colors.primary[800]};
            border: 1px solid ${theme.colors.primary[300]};
          `}
        >
          <span
            className={css`
              white-space: nowrap;
            `}
          >
            Autocomplete <b>{formatUSD(currentPOCost, true)}</b> to{' '}
            <b
              className={css`
                color: ${theme.colors.success[500]};
              `}
            >
              {formatUSD(recommendedPOCost, true)}
            </b>
          </span>
        </FlexSpace>
      </FlexSpace>
      {!hideWarning && currentValue > targetValue && recommendedValue / targetValue > 1 ? (
        <div>Unable to recommend below target: some items are at their minimum levels.</div>
      ) : null}
    </FlexSpace>
  );
}
