import React, { useState, useCallback, useEffect, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePreviousVal } from 'hooks/usePreviousVal';

import { dashCaseToTitleCase } from 'utils/formatting';
import { perfMark, PerfMarkName, trackPageLoadMetrics } from 'utils/perfMeasure';
import { decodePercentChars, parsePath, routePaths, routes, useHashState } from 'utils/routes';
import { scrollToTop } from 'utils/scroll';
import { setSuperProps, track, TrackEvent } from 'utils/track';

import { NavTabHashState } from 'types/hash-state';

import * as Styled from './Navigation.style';
import { filterNav, navModules } from './navModulesData';
import { PrimaryNavigation } from './PrimaryNavigation';
import { SecondaryNavigation } from './SecondaryNavigation';
import { TertiaryNavigation } from './TertiaryNavigation';
import { NavModule, NavSubModule } from './types';

export interface NavigationProps {
  children: JSX.Element;
  isDesktopView: boolean;
}

// these route paths should not display navigation capabilities to other modules or pages
// only the page and basic header should be displayed (no search, no nav, no create record button)
// e.g. CSR payment popup
const popupModeRoutePaths = [routePaths.payments.paymentDetails];

export const Navigation = ({ children, isDesktopView }: NavigationProps) => {
  const history = useHistory();
  const location = useLocation();
  const [hashState] = useHashState<NavTabHashState>();
  const { tab: curNavTab } = hashState;
  const prevNavTab = usePreviousVal(curNavTab);
  const { activeTenant, activeUser, activeErpRole } = useGlobalApp();
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const curPagePath = location.pathname;
  const curPagePathParseResult = parsePath(curPagePath);
  const prevPagePath = usePreviousVal(curPagePath);
  const pageTimeSpentSRef = useRef(0);
  // eslint-disable-next-line prefer-const
  let { moduleSlug: curModuleSlug, subModuleSlug: curSubModuleSlug, routePath: curRoutePath } = curPagePathParseResult;

  const filteredNavModules = filterNav(navModules, activeTenant, activeUser, activeErpRole);
  if (!curModuleSlug) {
    curModuleSlug = filteredNavModules[0].slug;
  }
  const curNavModule = filteredNavModules.find((module) => module.slug === curModuleSlug);
  if (!curSubModuleSlug && curNavModule) {
    curSubModuleSlug = curNavModule.subModules[0].slug;
  }

  const isPopupMode = popupModeRoutePaths.includes(curRoutePath);

  useEffect(() => {
    // track page load metrics after nav shell loads
    perfMark(PerfMarkName.NavShellRender);
    trackPageLoadMetrics();

    const intervalId = setInterval(() => {
      if (!document.hidden) {
        // if user navigates away from tab, don't count
        pageTimeSpentSRef.current += 1;
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  if (curPagePath !== prevPagePath) {
    // set curPagePath and curPageRoutePath as super props so every event gets it
    const prevPageRoutePath = prevPagePath ? parsePath(prevPagePath).routePath : undefined;

    setSuperProps({ curPath: curPagePath, curRoutePath });
    track(TrackEvent.Nav_HistoryChange, {
      prevPath: prevPagePath,
      prevRoutePath: prevPageRoutePath,
      prevTimeSpentS: pageTimeSpentSRef.current,
    });

    pageTimeSpentSRef.current = 0;

    // scroll to top after navigating to a new page, so new page isn't halfway scrolled
    scrollToTop();
  } else if (curNavTab && prevNavTab && curNavTab !== prevNavTab) {
    track(TrackEvent.Nav_TabChange, {
      tab: curNavTab,
    });
  }

  // Add pathname to title so when user goes to their browser history, or long clicks back button
  // they see meaningful names (and can go back to a previously saved state)
  useEffect(() => {
    const { pathSlashParts } = curPagePathParseResult;
    if (curPagePathParseResult.tenantSlug) {
      // skip tenantSlug in tab name breadcrumbs, it's not relevant
      pathSlashParts.shift();
    }

    const titleFromPathname = pathSlashParts
      .map((part) => dashCaseToTitleCase(decodeURIComponent(decodePercentChars(part))))
      .join(' › ');
    document.title = `${titleFromPathname} | Recurrency`;
  }, [curPagePathParseResult]);

  const goToRoute = useCallback(
    (route?: string) => {
      if (route) {
        history.push(route);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsHamburgerOpen(false);
      }
    },
    [history],
  );

  const handleLogoClick = useCallback(() => {
    history.push(routes.home());
  }, [history]);

  const handleModuleSelect = useCallback(
    (navModule: NavModule) => {
      const { routeFn, subModules } = navModule;
      if (routeFn) {
        goToRoute(routeFn());
      } else if (subModules && subModules.length > 0) {
        goToRoute(subModules[0].routeFn());
      }
    },
    [goToRoute],
  );

  const handleSubModuleSelect = useCallback(
    (navSubModule: NavSubModule) => {
      if (navSubModule?.routeFn) {
        goToRoute(navSubModule?.routeFn());
      }
    },
    [goToRoute],
  );

  const toggleHamburger = useCallback(() => {
    setIsHamburgerOpen(!isHamburgerOpen);
  }, [isHamburgerOpen]);

  return (
    <Styled.Container>
      <PrimaryNavigation isDesktopView={isDesktopView} onLogoClick={handleLogoClick} isPopupMode={isPopupMode} />
      {isPopupMode ? (
        <div>{children}</div>
      ) : (
        <Styled.Splitter isDesktopView={isDesktopView}>
          <>
            <SecondaryNavigation
              isDesktopView={isDesktopView}
              curModuleSlug={curModuleSlug}
              onModuleSelect={handleModuleSelect}
              isHamburgerOpen={isHamburgerOpen}
            />
            <Styled.ContentWrapper isDesktopView={isDesktopView} hasTertiaryNav={!!curNavModule?.subModules}>
              {curNavModule?.subModules && (
                <TertiaryNavigation
                  isDesktopView={isDesktopView}
                  curNavModule={curNavModule}
                  curSubModuleSlug={curSubModuleSlug}
                  onSubModuleSelect={handleSubModuleSelect}
                  isHamburgerOpen={isHamburgerOpen}
                  toggleHamburger={toggleHamburger}
                />
              )}
              <Styled.Content isDesktopView={isDesktopView}>{children}</Styled.Content>
            </Styled.ContentWrapper>
          </>
        </Styled.Splitter>
      )}
    </Styled.Container>
  );
};
