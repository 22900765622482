import React from 'react';

import { Link } from 'react-router-dom';

import { CheckOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { SalesOrderScheduledReleaseLineDTO } from '@recurrency/core-api-schema/dist/salesOrderLines/getScheduledReleaseLines';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';

import { useCoreApi } from 'hooks/useApi';

import { showAsyncModal } from 'utils/asyncModal';
import { formatDate, formatDateShorthand, formatNumber } from 'utils/formatting';
import { routes } from 'utils/routes';
import { sortableDateColumn, sortableNumberColumn, sortableStringColumn, typedColumn } from 'utils/tables';

export interface ScheduledReleaseStockPanelRecord {
  itemId: string;
  itemUid: string;
  locationId: string;
  unitOfMeasure: string;
}

const rowStatus = (record: SalesOrderScheduledReleaseLineDTO) =>
  record.allocatedQuantity === record.scheduledQuantity
    ? 'Fully Allocated'
    : record.nonAllocatedQuantity === record.scheduledQuantity
    ? 'Unallocated'
    : ('Partially Allocated' as string);

export function ScheduledReleaseStockPanelContent({ itemInfo }: { itemInfo: ScheduledReleaseStockPanelRecord }) {
  const locationIds = itemInfo.locationId ? [itemInfo.locationId] : undefined;
  const unitOfMeasure = itemInfo.unitOfMeasure ?? '';
  const {
    data: scheduledReleaseLinesData,
    isLoading: scheduledReleaseLinesLoading,
    error: scheduledReleaseLinesError,
  } = useCoreApi(schemas.salesOrderLines.getSalesOrderScheduledReleaseLines, {
    queryParams: {
      filter: {
        itemIds: [itemInfo.itemUid],
        locationIds,
        completed: false,
      },
    },
  });

  return (
    <MiniTable
      numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
      data={scheduledReleaseLinesData?.items || []}
      columns={[
        {
          render: (record) => `${formatDateShorthand(record.releaseDate)}`,
          align: 'left',
          width: '120px',
        },
        {
          render: (record: SalesOrderScheduledReleaseLineDTO) => `${rowStatus(record)}`,
          align: 'left',
          width: '100px',
        },
        {
          render: (record) => `${formatNumber(record.nonAllocatedQuantity)} ${unitOfMeasure}`,
          align: 'right',
        },
      ]}
      isLoading={scheduledReleaseLinesLoading}
      error={scheduledReleaseLinesError}
      onMoreButtonClick={() => {
        showAsyncModal(ViewAllModal, {
          title: 'Open Scheduled Releases',
          content: <ScheduledReleaseStockTable itemInfo={itemInfo} />,
        });
      }}
    />
  );
}

function ScheduledReleaseStockTable({ itemInfo }: { itemInfo: ScheduledReleaseStockPanelRecord }) {
  const columns: ColumnType<SalesOrderScheduledReleaseLineDTO>[] = [
    sortableStringColumn({
      title: 'Order No',
      dataIndex: 'orderNumber',
      render: (orderNumber: string) => (
        <Link to={routes.orders.orderDetails(orderNumber)} target="_blank" rel="noreferrer">
          {orderNumber}
        </Link>
      ),
    }),
    typedColumn({
      title: 'Location',
      dataIndex: 'locationId',
      render: (_, record) => `${record.locationId}: ${record.locationName}`,
    }),
    sortableDateColumn({
      title: 'Release Date',
      dataIndex: 'releaseDate',
      render: (value: any) => `${formatDate(value)}`,
    }),
    typedColumn({
      title: 'Status',
      render: (_: any, record) => `${rowStatus(record)}`,
    }),
    typedColumn({
      title: 'Credit Hold',
      dataIndex: 'onCreditHold',
      render: (value: any) => (value ? <CheckOutlined /> : null),
      align: 'center' as const,
    }),
    sortableNumberColumn({
      title: 'Unallocated Qty',
      dataIndex: 'nonAllocatedQuantity',
      align: 'right' as const,
      render: (value: any) => `${formatNumber(value)} ${itemInfo.unitOfMeasure}`,
    }),
    sortableNumberColumn({
      title: 'Allocated Qty',
      dataIndex: 'allocatedQuantity',
      align: 'right' as const,
      render: (value: any) => `${formatNumber(value)} ${itemInfo.unitOfMeasure}`,
    }),
    sortableNumberColumn({
      title: 'Scheduled Qty',
      dataIndex: 'scheduledQuantity',
      align: 'right' as const,
      render: (value: any) => `${formatNumber(value)} ${itemInfo.unitOfMeasure}`,
    }),
  ];
  return (
    <AsyncTable
      tableProps={useCoreApiTableProps({
        schema: schemas.salesOrderLines.getSalesOrderScheduledReleaseLines,
        queryParams: {
          filter: {
            itemIds: [itemInfo.itemUid],
            locationIds: [itemInfo.locationId],
            completed: false,
          },
        },
      })}
      columns={columns}
    />
  );
}
