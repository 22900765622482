import React from 'react';

import { css } from '@emotion/css';

import { SmallLoader } from 'components/Loaders';

export const CardLoader = ({ height }: { height: number }) => (
  <SmallLoader
    extraCss={css`
      height: ${height}px;
      min-width: 100%;
    `}
  />
);
