import React, { useState } from 'react';

import {
  TenantFeatureFlag,
  TenantFeatureFlagDescriptions,
  TenantFeatureFlags,
} from '@recurrency/core-api-schema/dist/common/enums';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';

import { Button } from 'components/Button';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { Input } from 'components/Input';
import { Table } from 'components/Table';

import { sortableStringColumn } from 'utils/tables';

interface FeatureFlagRecord {
  featureName: string;
}

export function TenantFeatureFlagsTable({
  featureFlags,
  onFeatureFlagsChange,
}: {
  featureFlags: TenantFeatureFlags;
  onFeatureFlagsChange: (newFeatureFlags: TenantFeatureFlags) => void;
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const defaultSelected = Object.entries(featureFlags)
    .filter(([_, enabled]) => enabled)
    .map(([featureName, _]) => featureName);
  const [selectedFeatureNames, setSelectedFeatureNames] = useState<string[]>(defaultSelected);

  let tableRowsData: FeatureFlagRecord[] = Object.values(TenantFeatureFlag).map((featureName) => ({
    featureName,
  }));

  tableRowsData = fuzzyFilter(tableRowsData, searchQuery, { fields: ['featureName'] }).map(({ item }) => item);

  const columns: ColumnType<FeatureFlagRecord>[] = [
    sortableStringColumn({
      title: 'Feature',
      dataIndex: 'featureName',
    }),
    {
      title: 'Description',
      dataIndex: 'featureName',
      render: (featureFlag: TenantFeatureFlag) => <div>{TenantFeatureFlagDescriptions[featureFlag] || '-'}</div>,
    },
  ];

  const handleSetFeaturesButtonClick = () => {
    onFeatureFlagsChange(
      Object.fromEntries(selectedFeatureNames.map((featureName) => [featureName, true])) as TenantFeatureFlags,
    );
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: selectedFeatureNames,
    onSelect: (record: FeatureFlagRecord, selected: boolean) => {
      // individual row selection, so even if table is filtered, previously selected items aren't wiped
      if (selected) {
        setSelectedFeatureNames([...selectedFeatureNames, record.featureName]);
      } else {
        setSelectedFeatureNames(selectedFeatureNames.filter((featureName) => featureName !== record.featureName));
      }
    },
  };

  return (
    <>
      <FilterBarBox>
        <Input
          placeholder="Search Feature Flags"
          onChange={(ev) => setSearchQuery(ev.target.value)}
          style={{ maxWidth: '300px' }}
        />
        <FlexSpacer />
        <Button type="primary" onClick={handleSetFeaturesButtonClick}>
          Set Enabled Features
        </Button>
      </FilterBarBox>

      <Table columns={columns} data={tableRowsData} rowKey="featureName" rowSelection={rowSelection} />
    </>
  );
}
