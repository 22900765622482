/* eslint-disable */
// @ts-nocheck
// NOTE: intentionally not type checked because we don't want to change third party snippets

import { waitForSelector, insertAsyncScript } from 'utils/dom';
import type { Stripe } from './stripe-js';

export function extScriptsInit() {
  // segment.io
  if (process.env.SEGMENT_TOKEN) {
    // we build our own lightweight client that uses segment batch api directly.
    // segment cdn and analytics.min.js is blocked by adblockers and privacy tools.
    // We want cleaner tracking of events to debug user issues.
    // we use segment batch api https://segment.com/docs/connections/sources/catalog/libraries/server/http-api/#batch
    // and pass it through netlify redirect to have the requests go through our domain.

    const segment = (window.segment = window.segment || {});
    if (!segment.initialize) {
      if (segment.invoked) {
        console.error('Segment snippet included twice.');
      } else {
        segment.invoked = true;
        segment.writeKey = process.env.SEGMENT_TOKEN;
        segment.queue = [];
        segment.batchIntervalMs = 5000;
        segment.flushTimeout = null;

        segment.identify = (userId, traits) => {
          segment.userId = userId;
          const timestamp = new Date().toISOString();
          segment.queue.push({ type: 'identify', timestamp, userId, traits });
        };
        segment.track = (event, properties) => {
          const timestamp = new Date().toISOString();
          segment.queue.push({ type: 'track', timestamp, event, properties });

          if (!segment.flushTimeout) {
            segment.flushTimeout = setTimeout(segment.flushQueue, segment.batchIntervalMs);
          }
        };
        segment.flushQueue = () => {
          if (segment.queue.length === 0) return;

          const payload = {
            // set userId to all events in queue
            batch: segment.queue.map((ev) => ({ ...ev, userId: segment.userId })),
            writeKey: segment.writeKey,
            sentAt: new Date().toISOString(),
            integrations: { 'Segment.io': true },
            context: {
              library: { name: 'analytics.js', version: 'next' },
              locale: window.navigator.language,
              userAgent: window.navigator.userAgent,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              page: { url: window.location.origin + window.location.pathname },
            },
          };
          segment.queue = [];
          segment.flushTimeout = null;

          const isLocalhost = window.location.hostname === 'localhost';
          if (!isLocalhost) {
            window
              .fetch('/_segment/v1/batch', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
                keepalive: true,
              })
              .catch((err) => {}); // fail gracefully
          }
        };

        // flush queue on page unload
        window.addEventListener('beforeunload', () => {
          segment.flushQueue();
        });
        window.document.addEventListener('visibilitychange', () => {
          if (window.document.visibilityState === 'hidden') segment.flushQueue();
        });
      }
    }
  }

  // fullstory.com
  if (process.env.FULLSTORY_ORG) {
    window._fs_debug = false;
    window._fs_host = 'fullstory.com';
    window._fs_script = 'edge.fullstory.com/s/fs.js';
    window._fs_org = process.env.FULLSTORY_ORG;
    window._fs_namespace = 'FS';
    (function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
        }
        return;
      }
      g = m[e] = function (a, b, s) {
        g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
      };
      g.q = [];

      insertAsyncScript({
        src: `https://${_fs_script}`,
        crossOrigin: 'anonymous',
      });

      g.identify = function (i, v, s) {
        g(l, { uid: i }, s);
        if (v) g(l, v, s);
      };
      g.setUserVars = function (v, s) {
        g(l, v, s);
      };
      g.event = function (i, v, s) {
        g('event', { n: i, p: v }, s);
      };
      g.anonymize = function () {
        g.identify(!true);
      };
      g.shutdown = function () {
        g('rec', !1);
      };
      g.restart = function () {
        g('rec', true);
      };
      g.log = function (a, b) {
        g('log', [a, b]);
      };
      g.consent = function (a) {
        g('consent', !arguments.length || a);
      };
      g.identifyAccount = function (i, v) {
        o = 'account';
        v = v || {};
        v.acctId = i;
        g(o, v);
      };
      g.clearUserCookie = function () {};
      g.setVars = function (n, p) {
        g('setVars', [n, p]);
      };
      g._w = {};
      y = 'XMLHttpRequest';
      g._w[y] = m[y];
      y = 'fetch';
      g._w[y] = m[y];
      if (m[y])
        m[y] = function () {
          return g._w[y].apply(this, arguments);
        };
      g._v = '1.3.0';
    })(window, document, window._fs_namespace, 'script', 'user');
  }

  // cohere.so
  if (process.env.COHERE_TOKEN) {
    const Cohere = (window.Cohere = window.Cohere || []);
    if (Cohere.invoked) console.error('Tried to load Cohere twice');
    else {
      Cohere.invoked = true;
      Cohere.snippet = '0.2';
      Cohere.methods = ['init', 'identify', 'stop', 'showCode'];
      Cohere.methods.forEach(function (o) {
        Cohere[o] = function () {
          const t = Array.prototype.slice.call(arguments);
          t.unshift(o), Cohere.push(t);
        };
      });
      insertAsyncScript({
        src: 'https://static.cohere.so/main.js',
        crossOrigin: 'anonymous',
      });
    }
    window.Cohere.init(process.env.COHERE_TOKEN);
  }

  // headwayapp.co
  if (process.env.HEADWAYAPP_ACCOUNT) {
    insertAsyncScript({
      src: 'https://cdn.headwayapp.co/widget.js',
      onload: async () => {
        if (window.Headway) {
          // headwayapp can only be initialized once the selector exists in DOM, so we wait for it
          const selector = '.headwayapp-badge';
          await waitForSelector(selector);
          window.Headway?.init({
            selector,
            account: process.env.HEADWAYAPP_ACCOUNT,
            trigger: selector,
          });
        }
      },
    });
  }

  // zendesk.com chat widget
  if (process.env.ZENDESK_KEY) {
    // zeIdentify tracks userProps until zendesk script is initialized
    window.zeIdentify = (userProps) => {
      window.zeIdentify.userProps = userProps;
    };

    insertAsyncScript({
      src: `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_KEY}`,
      id: 'ze-snippet',
      onload: () => {
        window.zE(() => {
          if (window.zeIdentify.userProps) {
            window.zE.identify(window.zeIdentify.userProps);
          }
          window.zE.hide();
          window.zE('webWidget:on', 'close', window.zE.hide);
          window.zeIdentify = (userProps) => window.zE.identify(userProps);
        });
      },
    });
  }

  // chameleon.io
  if (process.env.CHAMELEON_TOKEN) {
    const chameleonNamespace = 'chmln';
    // Check if the namespace or root is already defined
    if (!window[chameleonNamespace]) {
      window[chameleonNamespace] = {};
    }

    if (!window[chameleonNamespace].root) {
      // Initialize Chameleon tracking variables
      window[chameleonNamespace].accountToken = process.env.CHAMELEON_TOKEN;
      window[chameleonNamespace].location = window.location.href.toString();
      window[chameleonNamespace].now = new Date();
      window[chameleonNamespace].fastUrl = 'https://fast.chameleon.io/';

      // Methods to initialize
      const chameleonMethods = [
        'identify',
        'alias',
        'track',
        'clear',
        'set',
        'show',
        'on',
        'off',
        'custom',
        'help',
        '_data',
      ];

      // Create stubs for each Chameleon method
      for (var i = 0; i < chameleonMethods.length; i++) {
        (function (method) {
          const methodQueue = (window[chameleonNamespace][method + '_a'] = []);
          window[chameleonNamespace][method] = function () {
            methodQueue.push(arguments);
          };
        })(chameleonMethods[i]);
      }
    }

    insertAsyncScript({
      src: window[chameleonNamespace].fastUrl + 'messo/' + window[chameleonNamespace].accountToken + '/messo.min.js',
    });
  }
}

// m.stripe.com requests are made for every page load and it is a flakey domain that fails often
// we reduce blast radius of stripe tracking failures by loading stripe script only when needed
export function initStripeClient(): Promise<Stripe> {
  return new Promise((resolve) => {
    // initialize once as singleton
    if (window._stripe) {
      resolve(window._stripe);
    } else {
      insertAsyncScript({
        src: 'https://js.stripe.com/v3/',
        id: 'stripe-snippet',
        onload: () => {
          const stripe = (window._stripe = window.Stripe(process.env.STRIPE_CLIENT_KEY));
          resolve(stripe);
        },
      });
    }
  });
}
