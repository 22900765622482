import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TenantIntegrationStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { colors } from 'theme/colors';
import { ZipCelXSheet } from 'zipcelx';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { Input } from 'components/Input';
import { ButtonLink } from 'components/Links';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { RadioGroup } from 'components/Radio';
import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from 'components/recipes/DownloadButton';
import { Select } from 'components/Select';
import { Table } from 'components/Table';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';

import { formatDate, capitalize } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { sortableBooleanColumn, sortableNumberColumn, sortableStringColumn } from 'utils/tables';

const MAX_RECORDS = 1000;

export interface TenantsListHashState {
  query?: string;
  integrationFilter?: TenantIntegrationStatus;
  isActiveFilter: 'active' | 'inactive' | undefined;
}

export const TenantListPage = () => {
  const {
    data: tenantData,
    error,
    isLoading,
  } = useCoreApi(schemas.tenants.getAllTenants, {
    queryParams: { limit: MAX_RECORDS },
  });

  const [hashState, updateHashState] = useHashState<TenantsListHashState>();

  const [filteredTenants, setFilteredTenants] = useState<TenantDTO[]>(tenantData?.items ?? []);

  useEffect(() => {
    const current = { ...(tenantData ?? { items: [] }) };
    current.items = fuzzyFilter(current.items, hashState.query ?? '', {
      fields: ['id', 'name', 'slug', 'id', 'databaseSchema', 'erpType'],
    }).map(({ item }) => item);
    if (hashState.integrationFilter) {
      current.items = current.items.filter((item) => item.integrations[0]?.status === hashState.integrationFilter);
    }

    if (hashState.isActiveFilter) {
      const filterBoolean = hashState.isActiveFilter === 'active';
      current.items = current.items.filter((item) => item.isActive === filterBoolean);
    }
    setFilteredTenants(current.items);
  }, [hashState, tenantData]);

  const columns: ColumnType<TenantDTO>[] = [
    sortableNumberColumn({
      title: 'Tenant ID',
      dataIndex: 'id',
      render: (id: string) => <Link to={routes.internal.tenantDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'name',
    }),
    sortableStringColumn({
      title: 'Slug',
      dataIndex: 'slug',
    }),
    sortableStringColumn({
      title: 'DB Schema',
      dataIndex: 'databaseSchema',
    }),
    sortableStringColumn({
      title: 'Erp Type',
      dataIndex: 'erpType',
    }),
    sortableBooleanColumn({
      title: 'Active',
      dataIndex: 'isActive',
      filtered: true,
    }),
    sortableStringColumn({
      title: 'Integration Status',
      dataIndex: ['integrations', 0, 'status'],
    }),
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (date: string) => formatDate(date),
      sorter: (a: TenantDTO, b: TenantDTO) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      render: ({ id }: { id: string }) => ButtonLink(routes.internal.tenantDetails(id)),
    },
  ];

  type IntegrationOptions = keyof typeof TenantIntegrationStatus;
  const activeFilterOptions = Object.keys(TenantIntegrationStatus).map((integrationStatus) => ({
    name: integrationStatus,
    value: TenantIntegrationStatus[integrationStatus as IntegrationOptions],
  }));

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !tenantData) {
    return <CenteredLoader />;
  }

  return (
    <Container>
      <PageHeader
        title="Tenants"
        subtitle={
          <InfoTooltip
            useInfoIcon
            tooltipOverlayStyle={{ maxWidth: 'none' }}
            tooltipOverlayInnerStyle={{ backgroundColor: '#fff' }}
            title={
              <div>
                <BarChart
                  height={200}
                  width={500}
                  layout="vertical"
                  data={Object.values(TenantIntegrationStatus).map((status) => ({
                    status: capitalize(status),
                    statusCount: tenantData.items.filter((tenant) => tenant.integrations?.[0]?.status === status)
                      .length,
                  }))}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis type="category" dataKey="status" width={100} />
                  <Tooltip />
                  <Bar dataKey="statusCount" name="Tenants" fill={colors.chart.lagoonBlue} />
                </BarChart>
              </div>
            }
          >
            {
              tenantData.items.filter((tenant) =>
                [TenantIntegrationStatus.Active, TenantIntegrationStatus.Paused].includes(
                  tenant.integrations?.[0]?.status,
                ),
              ).length
            }{' '}
            integrated tenants (Active + Paused) out of {tenantData.items.length} total tenants
          </InfoTooltip>
        }
      />
      <FilterBarBox>
        <Input
          placeholder="Search Tenants"
          onChange={(ev) => updateHashState({ query: ev.target.value })}
          value={hashState.query}
          style={{ maxWidth: '300px' }}
          allowClear
        />
        <Select
          options={activeFilterOptions}
          onChange={(val) => updateHashState({ integrationFilter: val })}
          style={{ minWidth: '200px' }}
          placeholder="Integration Status"
          allowClear
        />
        <RadioGroup
          value={hashState.isActiveFilter}
          onChange={({ target: { value } }) => updateHashState({ isActiveFilter: value })}
          size="middle"
        >
          <Radio.Button value={undefined}>All</Radio.Button>
          <Radio.Button value="active">Active</Radio.Button>
          <Radio.Button value="inactive">Inactive</Radio.Button>
        </RadioGroup>
        <FlexSpacer />
        <DownloadButton recordType="Tenants" getDownloadData={() => getDownloadData(filteredTenants)} />
        <Button type="primary">
          <Link to={routes.internal.tenantNew()}>
            <PlusOutlined /> New Tenant
          </Link>
        </Button>
      </FilterBarBox>
      <Table columns={columns} data={filteredTenants} rowKey="id" isLoading={isLoading} />
    </Container>
  );
};

async function getDownloadData(tenants: TenantDTO[]): Promise<ZipCelXSheet> {
  // sort the tenants by the erp type and then database schema, placing null values at the top
  const sortedTenants = tenants.sort((a: TenantDTO, b: TenantDTO) => {
    if (!a.erpType && !b.erpType) return a.databaseSchema.localeCompare(b.databaseSchema);
    if (!a.erpType) {
      return 1;
    }
    if (!b.erpType) {
      return -1;
    }
    return a.erpType.localeCompare(b.erpType) || a.databaseSchema.localeCompare(b.databaseSchema);
  });
  const exportColumns: Array<DownloadXSheetColumn<TenantDTO>> = [
    { title: 'Tenant ID', type: 'string', value: (record) => record.id },
    { title: 'Name', type: 'string', value: (record) => record.name },
    { title: 'Slug', type: 'string', value: (record) => record.slug },
    { title: 'DB Schema', type: 'string', value: (record) => record.databaseSchema },
    { title: 'Erp Type', type: 'string', value: (record) => record.erpType },
    { title: 'Active', type: 'string', value: (record) => (record.isActive ? 'Yes' : 'No') },
    {
      title: 'Integration Status',
      type: 'string',
      value: (record) => (record.integrations.length > 0 ? record.integrations[0].status : ''),
    },
    {
      title: 'Created At',
      type: 'string',
      value: (record) => new Date(record.createdAt).toLocaleDateString(),
    },
  ];

  return recordsToXSheet(sortedTenants, exportColumns);
}
