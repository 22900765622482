import React, { Fragment, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import moment from 'moment';
import { theme } from 'theme';

import { Portal, PortalId } from 'components/Portal';
import { Typography } from 'components/Typography';

import { useContainerDimensions } from 'hooks/useContainerDimensions';

export type SparklinePoint = {
  date: string;
  value: number;
  // Indicates the line to this point should be dotted
  dotted?: boolean;
  color?: string;
  title?: string;
  formatFn?: (val: number) => string;
};

export type SparklineSeries = {
  data: SparklinePoint[];
  // These are all defaults that will be overridden by any point specific values
  color?: string;
  // Creates a separation line between this and the previous series
  separation?: boolean;
  title?: string;
  showGradient?: boolean;
  formatFn: (val: number) => string;
};

export type SparklineProps = {
  // Series must be in chronological order
  series: SparklineSeries[];
  width?: number;
  height?: number;
  className?: string;
  toolipSubtitle?: string;
};

export const Sparkline = ({ series, height, width, className, toolipSubtitle }: SparklineProps) => {
  const containerElRef = useRef(null);
  const { height: containerHeight, width: containerWidth } = useContainerDimensions(containerElRef) ?? {
    height: 1,
    width: 1,
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [point, setPoint] = useState<SparklinePoint>();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  // have to use uniquely named gradients otherwise safari will show as black when a <linearGradient> definition is removed
  const linearGradientIdRef = useRef<string>(`sparklineGradient-${Math.random().toString().slice(2)}`);
  const offset = 4;
  const defaultColor = theme.colors.chart.sunsetPurple;
  const adjustedWidth = (width || containerWidth) - 2 * offset;
  const adjustedHeight = (height || containerHeight) - 2 * offset;

  series.forEach((value) => value.data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
  const combinedPoints = series
    .map((value) =>
      value.data.map((point) => {
        point.color = point.color || value.color;
        point.title = point.title || value.title;
        point.formatFn = point.formatFn || value.formatFn;
        return point;
      }),
    )
    .flat(1);

  const maxX = new Date(combinedPoints[combinedPoints.length - 1].date).getTime();
  const minX = new Date(combinedPoints[0].date).getTime();
  const maxY = Math.max(...combinedPoints.map((d) => d.value));
  const minY = Math.min(...combinedPoints.map((d) => d.value), 0);

  const xDataToChartPlane = (date: string) =>
    maxX === minX ? adjustedWidth / 2 : ((new Date(date).getTime() - minX) / (maxX - minX)) * adjustedWidth + offset;
  const yDataToChartPlane = (val: number) =>
    maxY === minY ? adjustedHeight / 2 : adjustedHeight - ((val - minY) / (maxY - minY)) * adjustedHeight + offset;
  return (
    <div
      className={cx(
        css`
          cursor: crosshair;
        `,
        className,
      )}
      ref={containerElRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseMove={(ev) => {
        setMouse({ x: ev.nativeEvent.pageX, y: ev.nativeEvent.pageY });
        // Find the closest point on the x axis of the sparkline to the mouse
        // TODO: This can be done in much better than linear time, this was just quick to implement
        const closest = combinedPoints.reduce((prev, current) =>
          Math.abs(xDataToChartPlane(prev.date) - ev.nativeEvent.offsetX) <
          Math.abs(xDataToChartPlane(current.date) - ev.nativeEvent.offsetX)
            ? prev
            : current,
        );
        setPoint(closest);
      }}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <svg
        width={width || '100%'}
        height={height || '100%'}
        style={{
          display: 'block',
          strokeWidth: '2px',
        }}
      >
        {/* date x-axis */}
        <line
          x1={xDataToChartPlane(combinedPoints[0].date)}
          x2={xDataToChartPlane(combinedPoints[combinedPoints.length - 1].date)}
          y1={yDataToChartPlane(0)}
          y2={yDataToChartPlane(0)}
          stroke={theme.colors.neutral[400]}
          strokeOpacity={0.5}
          strokeWidth="1"
          strokeLinecap="round"
        />
        {/* year separators */}
        {series.map((value, idx) => (
          <Fragment key={idx}>
            {value.separation ? (
              <line
                x1={xDataToChartPlane(value.data[0].date)}
                x2={xDataToChartPlane(value.data[0].date)}
                y1={0}
                y2={adjustedHeight}
                stroke={theme.colors.neutral[400]}
                strokeDasharray="6"
                strokeOpacity={0.5}
                strokeWidth="1"
                strokeLinecap="round"
              />
            ) : null}
          </Fragment>
        ))}
        {/* line series */}
        {combinedPoints.slice(0, -1).map((_, i) => (
          <line
            x1={xDataToChartPlane(combinedPoints[i].date)}
            x2={xDataToChartPlane(combinedPoints[i + 1].date)}
            y1={yDataToChartPlane(combinedPoints[i].value)}
            y2={yDataToChartPlane(combinedPoints[i + 1].value)}
            stroke={combinedPoints[i + 1].color ?? defaultColor}
            strokeLinecap="round"
            strokeWidth="2"
            strokeDasharray={combinedPoints[i + 1].dotted ? '3 3' : undefined}
            key={i}
          />
        ))}
        {
          // gradients - combine any series with the same color and showing gradients in order to create a cohesive gradient
          series
            .reduce((prev, curr) => {
              if (prev.length === 0) {
                return [curr];
              }
              if (
                prev[prev.length - 1].color === curr.color &&
                prev[prev.length - 1].showGradient &&
                curr.showGradient
              ) {
                prev[prev.length - 1] = {
                  data: [...prev[prev.length - 1].data, ...curr.data],
                  showGradient: true,
                  color: curr.color,
                  formatFn: () => '',
                };
                return prev;
              }
              return [...prev, curr];
            }, [] as SparklineSeries[])
            .map((value, idx) => (
              <Fragment key={idx}>
                {value.showGradient ? (
                  <>
                    <linearGradient id={linearGradientIdRef.current + idx} gradientTransform="rotate(90)">
                      <stop offset="0" stopColor={value.color ?? defaultColor} stopOpacity={0.35} />
                      <stop offset="1" stopColor={value.color ?? defaultColor} stopOpacity={0.05} />
                    </linearGradient>
                    <polygon
                      points={`${
                        idx > 0
                          ? `${xDataToChartPlane(
                              series[idx - 1].data[series[idx - 1].data.length - 1].date,
                            )} ${yDataToChartPlane(0)} ${xDataToChartPlane(
                              series[idx - 1].data[series[idx - 1].data.length - 1].date,
                            )} ${yDataToChartPlane(series[idx - 1].data[series[idx - 1].data.length - 1].value)}`
                          : ''
                      }${value.data.reduce(
                        (prev, curr) => `${prev} ${xDataToChartPlane(curr.date)} ${yDataToChartPlane(curr.value)}`,
                        '',
                      )} ${xDataToChartPlane(value.data[value.data.length - 1].date)} ${yDataToChartPlane(
                        0,
                      )} ${xDataToChartPlane(value.data[0].date)} ${yDataToChartPlane(0)}`}
                      stroke={value.color ?? defaultColor}
                      strokeWidth="0"
                      fill={`url(#${linearGradientIdRef.current + idx})`}
                    />
                  </>
                ) : null}
              </Fragment>
            ))
        }
        {
          // tooltip dots
          showTooltip && point && (
            <circle
              cx={xDataToChartPlane(point.date)}
              cy={yDataToChartPlane(point.value)}
              r="3"
              fill={point.color ?? defaultColor}
            />
          )
        }
      </svg>
      {showTooltip && point && (
        // Tooltip that follows mouse when the user is hovering over the sparkline
        <Portal portalId={PortalId.Tooltip}>
          <div
            className="dataToolTip"
            style={{
              position: 'absolute',
              borderRadius: '8px',
              left: window.innerWidth - mouse.x > 105 ? `${mouse.x + 20}px` : undefined,
              right: window.innerWidth - mouse.x <= 105 ? `${window.innerWidth - mouse.x + 20}px` : undefined,
              top: `${mouse.y + 20}px`,
              backgroundColor: 'rgba(255, 255, 255, .90)',
              fontWeight: 500,
              border: `2px solid ${point.color ?? defaultColor}`,
            }}
          >
            <div
              className={css`
                padding: 5px;
              `}
            >
              <Typography style={{ fontWeight: 'bold' }}>{point.title}</Typography>
              <Typography>{moment(point.date).format('MMM YYYY')}</Typography>
              <Typography>{point.formatFn?.(point.value)}</Typography>
              {toolipSubtitle && <Typography>{toolipSubtitle}</Typography>}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};
