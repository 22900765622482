import React from 'react';

import { css } from '@emotion/css';

import { pluralize } from 'utils/formatting';

import { ActionButton } from './Button/ActionButton';
import { FlexSpace } from './FlexSpace';

export function SelectionCountAndClear({
  numSelected,
  onClear,
  entitySingle,
  entityPlural,
}: {
  numSelected: number;
  onClear: () => void;
  entitySingle: string;
  entityPlural?: string;
}) {
  if (numSelected === 0) {
    return null;
  }

  return (
    <FlexSpace
      gap={16}
      className={css`
        justify-content: center;
        margin-bottom: 16px;
      `}
    >
      <span>
        <b>{numSelected}</b> {pluralize(numSelected, entitySingle, entityPlural)} selected
      </span>
      <ActionButton label="Clear" onClick={() => onClear()} />
    </FlexSpace>
  );
}
