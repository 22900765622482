import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { getGlobalAppState, GlobalAppState } from 'hooks/useGlobalApp';

import { isAdmin } from 'utils/roleAndTenant';

import { GetAllRecordsRequest, SearchRequest } from './types';

const accessFiltersByIndex: Record<SearchIndexName, (appState: GlobalAppState) => Record<string, string[]> | null> = {
  [SearchIndexName.Items]: (appState) => {
    const allowedCompanyIds = getActiveCompanyIds(appState);
    const accessFilters: Record<string, string[]> = {};

    if (allowedCompanyIds.length > 0) {
      accessFilters.company_ids = allowedCompanyIds;
    }
    return accessFilters;
  },
  [SearchIndexName.Customers]: (appState) => {
    const allowedSalesRepIds = getRoleSalesRepIds(appState);
    const allowedCompanyIds = getActiveCompanyIds(appState);
    const accessFilters: Record<string, string[]> = {};

    if (allowedCompanyIds.length > 0) {
      accessFilters.company_id = allowedCompanyIds;
    }
    if (allowedSalesRepIds.length > 0) {
      accessFilters.salesrep_ids = allowedSalesRepIds;
    }
    return accessFilters;
  },
  [SearchIndexName.ShipTos]: companyIdFilter,
  [SearchIndexName.SalesOrders]: (appState) => {
    const allowedSalesRepIds = getRoleSalesRepIds(appState);
    const allowedCompanyIds = getActiveCompanyIds(appState);
    const accessFilters: Record<string, string[]> = {};

    if (allowedCompanyIds.length > 0) {
      accessFilters.company_id = allowedCompanyIds;
    }
    if (allowedSalesRepIds.length > 0) {
      accessFilters.salesrep_id = allowedSalesRepIds;
    }
    return accessFilters;
  },
  [SearchIndexName.PurchaseOrders]: companyIdFilter,
  [SearchIndexName.TransferOrders]: companyIdFilter,
  [SearchIndexName.Vendors]: companyIdFilter,
  [SearchIndexName.Forecasts]: companyIdFilter,
  [SearchIndexName.Planning]: companyIdFilter,
  [SearchIndexName.InventoryStatus]: companyIdFilter,
};

function companyIdFilter(appState: GlobalAppState): { company_id: string[] } | null {
  const allowedCompanyIds = getActiveCompanyIds(appState);
  if (allowedCompanyIds.length > 0) {
    return { company_id: allowedCompanyIds };
  }
  return null;
}

function getActiveCompanyIds(appState: GlobalAppState): string[] {
  return appState.activeCompanyIds || appState.userAllowedCompanies.map((c) => c.companyId);
}

function getRoleSalesRepIds(appState: GlobalAppState): string[] {
  const activeRole = appState.activeErpRole;
  if (!isAdmin(activeRole.foreignId, activeRole.name)) {
    return activeRole.foreignIds;
  }
  return [];
}

/**
 * Injects filters for restricting search results based on user's access
 * NOTE: This mutates the search request being passed in.
 */
export function addAccessFilters<RequestT extends SearchRequest | GetAllRecordsRequest>(
  searchRequest: RequestT,
): RequestT {
  const appState = getGlobalAppState();
  const accessFilters = accessFiltersByIndex[searchRequest.indexName]?.(appState);

  if (accessFilters) {
    // create a copy of searchRequest so we don't mutate the original
    searchRequest = { ...searchRequest };
    searchRequest.filters = { ...searchRequest.filters };

    for (const [filterKey, accessFilterValues] of Object.entries(accessFilters)) {
      // if there are filter values, then restrict them to the ones that are allowed
      const reqFilterValues = searchRequest.filters[filterKey] || [];
      const allowedFilterValues = reqFilterValues.filter((val) => accessFilterValues.includes(val));
      searchRequest.filters[filterKey] = allowedFilterValues.length > 0 ? allowedFilterValues : accessFilterValues;
    }
  }

  return searchRequest;
}
