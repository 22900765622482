import React from 'react';

import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { captureError } from 'utils/error';

import { BadgeStatus, StatusBadge } from './StatusBadge';

export const ErpOrderBadgeStatusLookup: Record<ErpOrderStatus, BadgeStatus> = {
  [ErpOrderStatus.Open]: BadgeStatus.Open,
  [ErpOrderStatus.Completed]: BadgeStatus.Completed,
  [ErpOrderStatus.Canceled]: BadgeStatus.Canceled,
};

export const ErpOrderStatusBadge = ({ erpOrderStatus }: { erpOrderStatus: ErpOrderStatus }) => {
  if (ErpOrderBadgeStatusLookup[erpOrderStatus] === undefined) {
    captureError(new Error(`Attempted to render invalid ErpOrderStatus: ${erpOrderStatus}`));
    return <></>;
  }
  return <StatusBadge status={ErpOrderBadgeStatusLookup[erpOrderStatus]} />;
};
