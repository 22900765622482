import React, { ReactNode } from 'react';

import { css, cx } from '@emotion/css';

export const FlexSpace = ({
  className,
  children,
  gap = 8,
  direction = 'row',
  alignItems = direction === 'row' ? 'center' : 'start',
  wrap,
  fullWidth,
  justify,
}: {
  className?: string;
  children: ReactNode;
  gap?: number;
  wrap?: boolean;
  fullWidth?: boolean;
  direction?: React.CSSProperties['flexDirection'];
  alignItems?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
}) => (
  <div
    className={cx(
      css`
        display: flex;
        flex-direction: ${direction};
        gap: ${gap}px;
        align-items: ${alignItems};
        justify-content: ${justify || 'flex-start'};
        ${wrap ? 'flex-wrap: wrap;' : ''}
        ${fullWidth ? 'width: 100%;' : ''}
      `,
      className,
    )}
  >
    {children}
  </div>
);
