import React from 'react';

import type { TabsProps } from 'antd/lib/tabs';

import { toDashCase } from 'utils/formatting';
import { useHashState } from 'utils/routes';

import { NavTabHashState } from 'types/hash-state';

import { Tabs } from './Tabs';

export interface TabContent {
  header: string;
  content: JSX.Element;
  infoTooltip?: string;
}

interface Props extends TabsProps {
  tabs: TabContent[];
}

export function NavTabs({ tabs }: Props) {
  const [hashState, updateHashState] = useHashState<NavTabHashState>();

  // initial tab is the first tab, if not present in url query params
  let activeTab = hashState?.tab || '';

  // If the hash state tab is not in list of tabs, instead use the first tab
  if (!tabs.map((tab) => toDashCase(tab.header)).includes(activeTab)) {
    activeTab = '';
  }

  if (!activeTab && tabs.length > 0) {
    activeTab = toDashCase(tabs[0].header);
    // requestAnimationFrame prevents 'Cannot update during an existing state transition' error
    requestAnimationFrame(() => {
      updateHashState({ tab: activeTab });
    });
  }

  return (
    <Tabs
      tabs={tabs}
      activeTab={activeTab}
      onChange={(tabKey) => {
        updateHashState({ tab: tabKey });
      }}
    />
  );
}
