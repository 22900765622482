import React, { useEffect, useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ShipToResponseDTO } from '@recurrency/core-api-schema/dist/shipTos/createShipTo';
import { Form } from 'antd';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  useLocationsSelectProps,
  useFreightsSelectProps,
  useSalesRepsSelectProps,
  useBranchesSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { responsiveFormLayout } from 'components/FormItems';
import { AddressFormField, AddressFormItem } from 'components/FormItems/AddressFormItem';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { optArrFromVal } from 'utils/array';
import { splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent } from 'utils/track';

import { ForeignIdNameStr } from 'types/hash-state';

export interface ShipToFormData {
  companyId: string;
  salesRep: ForeignIdNameStr;
  customerId: string;

  addressName: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode: string;
  email?: string;
  phone: string;

  branch: ForeignIdNameStr;
  location: ForeignIdNameStr;
  freight?: ForeignIdNameStr;
}

export interface ShipToModalProps {
  initialValues?: Partial<ShipToFormData>;
  onClose: () => void;
  onSubmit?: (shipTo: ShipToResponseDTO) => void;
}

export const ShipToModal = ({ initialValues, onClose, onSubmit }: ShipToModalProps) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const [shipToForm] = Form.useForm();

  const [shipToState, setShipToState] = useState<Partial<ShipToFormData>>(() => ({
    ...initialValues,
  }));

  const defaultQuoteFieldData = activeTenant.defaultData.quote;
  const { companyId, customerId } = shipToState;
  const salesRepsSelectProps = useSalesRepsSelectProps({ companyId });
  const branchSelectProps = useBranchesSelectProps({ companyId });
  const locationSelectProps = useLocationsSelectProps({ companyIds: optArrFromVal(companyId) });
  const freightsSelectProps = useFreightsSelectProps({ companyId });
  const addressDetailsRequired = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesExportShipToUsingTransactionApi,
  );
  // use shipToState as the source of state
  useEffect(() => {
    shipToForm.setFieldsValue(shipToState);
  }, [shipToForm, shipToState]);

  // location default value
  useEffect(() => {
    if (!shipToState.location)
      shipToState.location = locationSelectProps.options.find(
        (l) => splitIdNameStr(l.value).foreignId === defaultQuoteFieldData.location?.foreignId,
      )?.value;
    shipToForm.setFieldsValue(shipToState);
  }, [
    shipToForm,
    shipToState,
    locationSelectProps.isLoading,
    locationSelectProps.options,
    defaultQuoteFieldData.location?.foreignId,
  ]);

  // freight default value
  useEffect(() => {
    if (
      !shipToState.freight &&
      !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableFreightExport)
    )
      shipToState.freight = freightsSelectProps.options.find(
        (f) => splitIdNameStr(f.value).foreignId === defaultQuoteFieldData.freightType?.foreignId,
      )?.value;
    shipToForm.setFieldsValue(shipToState);
  }, [
    shipToForm,
    shipToState,
    freightsSelectProps.isLoading,
    freightsSelectProps.options,
    defaultQuoteFieldData.freightType?.foreignId,
    activeTenant,
    activeUser,
  ]);

  async function handleSubmit(data: ShipToFormData) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Ship To',
      expectedWaitSeconds: 45,
      erpType: activeTenant.erpType,
    });
    try {
      onClose();
      const response = await coreApiFetch(schemas.shipTos.createShipTo, {
        bodyParams: {
          companyId: companyId || '',
          salesrepId: splitIdNameStr(data.salesRep).foreignId,
          customerId: customerId || '',
          address: {
            name: data.addressName,
            phone: data.phone,
            mailing: {
              address1: data.address1,
              address2: data.address2,
              city: data.city,
              state: data.state,
              country: data.country,
              zipcode: data.zipcode,
            },
            physical: {
              address1: data.address1,
              address2: data.address2,
              city: data.city,
              state: data.state,
              country: data.country,
              zipcode: data.zipcode,
            },
          },
          shipToLocation: {
            locationId: splitIdNameStr(data.location).foreignId,
            branchId: splitIdNameStr(data.branch).foreignId,
            freightCodeId: splitIfIdNameStr(data.freight)?.foreignId,
          },
        },
      });

      track(TrackEvent.ShipTos_CreateShipTo_Submit, { shipToId: response.data.shipToId || '' });
      submitNotification.success();
      onSubmit?.(response.data);
    } catch (err) {
      submitNotification.error(err);
    }
  }

  return (
    <Modal
      visible
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" form="shipToForm" htmlType="submit">
          Submit
        </Button>,
      ]}
      title="New Ship To"
      onCancel={() => onClose()}
      centered
      width={1200}
    >
      <Container>
        <Form.Provider>
          <Form
            name="shipToForm"
            form={shipToForm}
            onFinish={handleSubmit}
            onError={console.error}
            onFinishFailed={console.error}
            onValuesChange={(changedValues) => setShipToState({ ...shipToState, ...changedValues })}
            {...responsiveFormLayout}
          >
            <Form.Item
              label="Assigned Sales Rep"
              name="salesRep"
              rules={[{ required: true, message: 'Please choose a sales rep' }]}
            >
              <AsyncSelect selectProps={salesRepsSelectProps} entityPlural="sales reps" />
            </Form.Item>

            <Form.Item
              label="Default Branch"
              name="branch"
              rules={[{ required: true, message: 'Please choose a branch' }]}
            >
              <AsyncSelect selectProps={branchSelectProps} entityPlural="branches" />
            </Form.Item>

            <Form.Item
              label="Preferred Location"
              name="location"
              rules={[{ required: true, message: 'Please choose a location' }]}
            >
              <AsyncSelect selectProps={locationSelectProps} disabled={!companyId} entityPlural="locations" />
            </Form.Item>

            {!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableFreightExport) && (
              <Form.Item
                label="Freight Type"
                name="freight"
                rules={[{ required: true, message: 'Please choose a freight type' }]}
              >
                <AsyncSelect selectProps={freightsSelectProps} disabled={!companyId} entityPlural="freights" />
              </Form.Item>
            )}

            <Form.Item
              label="Address Name"
              name="addressName"
              rules={[
                {
                  required: true,
                  message: 'Address name is required when making a shipTo',
                },
                { max: 255, message: 'Address name cannot be more than 255 characters' },
              ]}
            >
              <Input />
            </Form.Item>

            <AddressFormItem
              // address1, city, state, zipcode are required for txn-api
              requiredFields={
                addressDetailsRequired
                  ? [AddressFormField.address1, AddressFormField.city, AddressFormField.state, AddressFormField.zipcode]
                  : [AddressFormField.zipcode]
              }
            />

            <Form.Item
              label="Central Phone"
              name="phone"
              rules={[{ required: true, max: 20, message: 'Phone number cannot be more than 20 characters' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: 'email', message: 'Must be an email' },
                { max: 255, message: 'Email cannot be more than 255 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Container>
    </Modal>
  );
};
