import React from 'react';

import { SelectOption } from 'components/Select';
import { Select } from 'components/Select/Select.style';

export type DashboardUnit = 'value' | 'volume';

export function DashboardUnitSelect({
  state,
  onChange,
}: {
  state: DashboardUnit;
  onChange: (value: DashboardUnit) => void;
}) {
  const unitSelectOptions: SelectOption[] = [
    { label: 'Dollars', value: 'value' },
    { label: 'Units', value: 'volume' },
  ];

  return (
    <Select options={unitSelectOptions} size="small" style={{ width: '140px' }} value={state} onChange={onChange} />
  );
}
