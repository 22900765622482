import React from 'react';

import { MiniTable } from 'components/MiniTable';

import { truthy } from 'utils/boolean';

import { LabelValueProperty } from './LabelValueProperties';

export function LabelValueMiniTable({ properties }: { properties: Array<LabelValueProperty | null> }) {
  if (!properties?.length) {
    return null;
  }

  return (
    <MiniTable
      data={properties.filter(truthy)}
      columns={[
        { render: (record) => record.label },
        {
          render: (record) => record.value,
          align: `right`,
        },
      ]}
    />
  );
}
