/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';

import { css } from '@emotion/css';
import { Empty, Typography } from 'antd';
import moment from 'moment';
import {
  Area,
  AreaChart,
  CartesianGrid,
  LineType,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  YAxisProps,
  ReferenceLineProps,
} from 'recharts';
import { theme } from 'theme';

import { formatNumber } from 'utils/formatting';

export interface InventoryDashboardAreaChartDataRecord {
  date: string; // MMM YY formatted date
  value: number;
  units?: string;
  type?: 'historical' | 'current';
}
export interface InventoryDashboardAreaChartProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: InventoryDashboardAreaChartDataRecord[];
  containerHeights?: { height?: number; minHeight?: number; maxHeight?: number };
  areaType?: LineType;
  areaKey?: string;
  themeChartColor: keyof typeof theme.colors.chart;
  yAxisProps: YAxisProps;
  tooltip?: TooltipProps;
  size?: 'small' | 'large';
  onClick?: () => void;
}

export const InventoryDashboardAreaChart = ({
  data,
  containerHeights,
  areaType,
  areaKey,
  themeChartColor,
  yAxisProps,
  tooltip,
  size = 'large',
  onClick,
}: InventoryDashboardAreaChartProps) => {
  const fillColorName = `fill_${themeChartColor}`;

  const xAxisProps = {
    dataKey: 'date',
  };

  const referenceLineYears = [0, 1, 2].map((offset) => moment().startOf('year').subtract(offset, 'year'));
  const startOfYearReferenceLineProps: ReferenceLineProps[] = referenceLineYears.map((momentObj) => ({
    x: momentObj.format('MMM YY'),
    stroke: theme.colors.neutral[400],
  }));

  const xInterval =
    size === 'large'
      ? data.length < 7
        ? undefined
        : data.length === 12
        ? 2
        : 3
      : data.length < 7
      ? undefined
      : data.length === 12
      ? 3
      : 4;

  return (
    <ResponsiveContainer
      width="100%"
      height={containerHeights?.height}
      minHeight={containerHeights?.minHeight}
      maxHeight={containerHeights?.maxHeight}
      className={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      {!data || !data.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <AreaChart data={data} margin={{ left: -1 }} baseValue="dataMin" onClick={onClick}>
          <defs>
            <linearGradient id={fillColorName} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.colors.chart[themeChartColor]} stopOpacity={0.2} />
              <stop offset="95%" stopColor={theme.colors.chart[themeChartColor]} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            {...xAxisProps}
            tick={{ fill: theme.colors.primary[600], stroke: theme.colors.primary[600], strokeWidth: 0.2 }}
            interval={xInterval}
          />
          <YAxis
            {...yAxisProps}
            domain={
              yAxisProps.unit && yAxisProps.unit === '%'
                ? [0, 100]
                : [(dataMin) => (dataMin / 3) * 2, (dataMax) => dataMax * 1.25]
            }
            interval="preserveEnd"
            tick={{ fill: theme.colors.primary[600], stroke: theme.colors.primary[600], strokeWidth: 0.2 }}
          />
          <Tooltip
            formatter={tooltip?.formatter ? tooltip?.formatter : (value) => formatNumber(Number(value))}
            content={(e: TooltipProps) => {
              // We render a custom tooltip to remove the connection point between historical and forecasted data
              const payload = e.payload?.filter((payloadx) => payloadx.name !== 'connection');
              if (e.active && payload && payload.length) {
                return (
                  <div
                    className={css`
                      background-color: rgba(255, 255, 255, 0.9);
                      font-weight: 500;
                      border-radius: 8px;
                      border: 2px solid ${payload[0].color};
                    `}
                  >
                    <div
                      className={css`
                        padding: 6px;
                      `}
                    >
                      <Typography>{moment(payload[0].payload.date, 'MMM YY').format('MMMM YYYY')}</Typography>
                      <Typography>
                        {e.formatter
                          ? e.formatter(payload[0].value as number, '', payload[0], 0)
                          : formatNumber(payload[0].value as number)}
                      </Typography>
                    </div>
                  </div>
                );
              }

              return null;
            }}
          />
          <Area
            type={areaType ?? 'linear'}
            dataKey={areaKey ?? 'value'}
            stroke={theme.colors.chart[themeChartColor]}
            strokeWidth={2}
            cursor="pointer"
            dot={false}
            fill={`url(#${fillColorName})`}
          />
          <CartesianGrid strokeDasharray="3 3" />
          {startOfYearReferenceLineProps?.map((refLineProps, index) => (
            <ReferenceLine key={index} {...refLineProps} />
          ))}
        </AreaChart>
      )}
    </ResponsiveContainer>
  );
};
