/**
 * We use max-width because our designs are desktop first, then tablet, then mobile
 */
export const breakpoints = {
  mobile: `@media (max-width: 480px)`,
  tablet: `@media (max-width: 1024px)`,
  smallDesktop: `@media (max-width: 1280px)`,
};

/**
 * @deprecated
 * used by olded styled-component based components
 * Use breakpoints instead
 */
export const mediaQueries = {
  xs: '@media (min-width: 0px)',
  sm: '@media (min-width: 576px)',
  md: '@media (min-width: 768px)',
  lg: '@media (min-width: 992px)',
  xl: '@media (min-width: 1200px)',
  xxl: '@media (min-width: 1600px)',
};

export type ThemeBreakpoints = typeof breakpoints;

export const breakpointsEntries = Object.entries(breakpoints);
