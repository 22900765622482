import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { Checkbox } from 'antd';
import { fuzzyFilter } from 'fuzzbunny';
import { colors } from 'theme/colors';

import { Counter } from 'components/Counter';
import { MiniTableSkeleton } from 'components/MiniTable';
import { PropertyLabel } from 'components/PropertyListItem';
import { SearchInput } from 'components/SearchInput';
import { SortableList } from 'components/SortableList/SortableList';
import { Typography } from 'components/Typography';

import { truthy } from 'utils/boolean';
import { MAX_VALUES_PER_FACET } from 'utils/search/types';

import { highlightedLabel, MultiSelectOption, normalizeOptions, UseAsyncMultiSelectProps } from './types';

const MAX_MULTISELECT_OPTIONS = MAX_VALUES_PER_FACET;

export function SelectableItemList({
  selectProps,
  mode = 'multiple',
  selectedValues,
  queryPlaceholder,
  popoverClassName,
  orderable,
  onSelectedValuesChange,
}: {
  selectProps: UseAsyncMultiSelectProps | (() => UseAsyncMultiSelectProps);
  selectedValues: string[];
  mode?: 'multiple' | 'single';
  queryPlaceholder?: string;
  popoverClassName?: string;
  orderable?: boolean;
  onSelectedValuesChange: (values: string[]) => void;
}) {
  // use internal search state when selectProps doesn't have setSearchQuery
  const [searchQuery, setSearchQuery] = useState('');

  // selectProps could be passed as a hook function, resolve to obj
  const selectPropsObj = typeof selectProps === 'function' ? selectProps() : selectProps;
  let filteredOptions = selectPropsObj.options;
  const showSearchInput = !!queryPlaceholder;

  // Limit max options shown in list so browser doesn't freeze making rendering a huge list
  const currentRenderLimit =
    // Check for frontend limit and cut off and show a message if needed
    filteredOptions.length >= MAX_MULTISELECT_OPTIONS
      ? MAX_MULTISELECT_OPTIONS
      : // Check if API limit was exceeded and show a message if needed
      selectPropsObj.totalCount && selectPropsObj.totalCount > filteredOptions.length
      ? filteredOptions.length
      : // If no limit was exceeded, show all options and no message
        undefined;
  filteredOptions = currentRenderLimit ? filteredOptions.slice(0, currentRenderLimit) : filteredOptions;

  // filter with local search if selectProps doesn't have setSearchQuery based filtering
  if (showSearchInput && !selectPropsObj.setSearchQuery && searchQuery) {
    filteredOptions = fuzzyFilter(normalizeOptions(filteredOptions), searchQuery, {
      fields: ['searchLabel', 'value'],
    }).map((option) => ({
      label: option.highlights.label ? highlightedLabel(option.highlights.label) : option.item.label,
      value: option.item.value,
      disabled: option.item.disabled,
    }));
  }

  const sortedOptions = useMemo(
    () =>
      // Only show selected values in sorted order, but only if they are in the filteredOptions given from the search
      selectedValues
        .map((value) => {
          const option = filteredOptions.find((o) => o.value === value);
          // If the option is not in filteredOptions, don't show it, since it is not in the search
          if (!option) {
            return undefined;
          }
          return {
            label: option?.label ?? value,
            value,
            disabled: option?.disabled,
          } as { label: React.ReactNode | string; value: string; disabled?: boolean };
        })
        .filter(truthy),
    [selectedValues, filteredOptions],
  );

  const renderItem = (option: MultiSelectOption) => (
    <div
      className={css`
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 4px 0;
        cursor: pointer;

        &:hover {
          background-color: ${colors.neutral[50]};
        }
      `}
      onClick={() =>
        option.disabled
          ? null
          : mode === 'multiple'
          ? onSelectedValuesChange(
              selectedValues.includes(option.value)
                ? selectedValues.filter((v) => v !== option.value)
                : [...selectedValues, option.value],
            )
          : onSelectedValuesChange([option.value])
      }
    >
      {mode === 'multiple' ? (
        <Checkbox disabled={option.disabled} checked={selectedValues.includes(option.value)} />
      ) : null}
      {option.label}
      {typeof option.count === 'number' ? (
        <Counter
          className={css`
            margin-left: auto;
          `}
          value={option.count}
          variant="neutral"
        />
      ) : null}
    </div>
  );

  const NoOptionsLabel = () => (
    <div
      className={css`
        color: ${colors.neutral[400]};
      `}
    >
      None
    </div>
  );

  const hiddenOptions = filteredOptions.filter((option) => !selectedValues.includes(option.value));

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-width: 240px;
        ${popoverClassName}
      `}
    >
      {showSearchInput ? (
        <SearchInput
          autoFocus
          query={selectPropsObj.searchQuery ?? searchQuery}
          placeholder={queryPlaceholder}
          onQueryChange={selectPropsObj.setSearchQuery ?? setSearchQuery}
          className={css`
            width: 100%;
          `}
        />
      ) : null}
      <div
        className={css`
          max-height: 400px;
          overflow-y: auto;
        `}
      >
        {orderable ? (
          <>
            <PropertyLabel label="Visible" />
            {sortedOptions.length > 0 ? (
              <SortableList
                items={sortedOptions}
                render={(item) => renderItem({ label: item.label, value: item.value, disabled: item.disabled })}
                itemKey={(item) => item.value}
                onChange={(items) => onSelectedValuesChange(items.map((item) => item.value))}
              />
            ) : (
              <NoOptionsLabel />
            )}
            <PropertyLabel
              label="Hidden"
              className={css`
                margin-top: 8px;
              `}
            />
            {hiddenOptions.length > 0 ? hiddenOptions.map((option) => renderItem(option)) : <NoOptionsLabel />}
          </>
        ) : (
          <>{filteredOptions.map((option) => renderItem(option))}</>
        )}
      </div>

      {selectPropsObj.isLoading ? (
        <MiniTableSkeleton />
      ) : filteredOptions.length === 0 ? (
        <Typography type="small">
          No results found{selectPropsObj.searchQuery ? ', try another search' : '.'}
        </Typography>
      ) : null}
      {showSearchInput && currentRenderLimit ? (
        <Typography type="small">Showing top {currentRenderLimit} results, search for more.</Typography>
      ) : null}
    </div>
  );
}
