import React, { useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ContactDTO } from '@recurrency/core-api-schema/dist/contacts/getContacts';
import { ShipToResponseDTO } from '@recurrency/core-api-schema/dist/shipTos/createShipTo';
import { Checkbox, Form, FormInstance } from 'antd';
import { RuleObject } from 'antd/lib/form';
import moment from 'moment';
import { theme } from 'theme';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  CustomerSelectOption,
  ShipToSelectOption,
  useCarriersSelectProps,
  useCompaniesSelectProps,
  useContactsSelectProps,
  useCustomersSelectProps,
  useFreightsSelectProps,
  useFreightChargesSelectProps,
  useLocationsSelectProps,
  useShipTosSelectProps,
  usePackingBasesSelectProps,
  useOrderPrioritiesSelectProps,
  useShippingRoutesSelectProps,
  useOrderClassesSelectProps,
  useTermsSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { NewContactButton } from 'components/recipes/NewContactButton';
import { NewShipToButton } from 'components/recipes/NewShipToButton';
import { TextArea } from 'components/TextArea';

import { NULLIFY_API_CALL, useCoreApi, useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePreviousVal } from 'hooks/usePreviousVal';

import { optArrFromVal } from 'utils/array';
import { truthy } from 'utils/boolean';
import { captureError } from 'utils/error';
import {
  getErpName,
  joinIdNameObj,
  joinIfIdNameObj,
  splitIdNameStr,
  splitIfIdNameStr,
  toTitleCase,
} from 'utils/formatting';
import { objKeys, objPickKeys } from 'utils/object';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { encodeLegacyApiParam, UpdateFormHashStateOptions } from 'utils/routes';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { OrderType } from 'utils/track';
import { validateDateInFuture, validatePONumber } from 'utils/validation';

import { QuoteEditHashStateP21 } from 'types/hash-state';
import {
  CustomerRestrictionsResponse,
  CustomerRestrictionsOrderEntryAction,
  customerRestrictionsValidationMessageMap,
} from 'types/legacy-api';

import { getStateUpdateWithCustomerTerms } from '../../quoteUtils';
import * as Styled from './QuoteHeader.style';

const addButtonRowCss = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: stretch;
  align-items: flex-start;
`;

export const QuoteHeader = ({
  form,
  quoteState,
  onQuoteStateChange,
  orderType,
}: {
  form: FormInstance<QuoteEditHashStateP21>;
  quoteState: QuoteEditHashStateP21;
  onQuoteStateChange: (update: Partial<QuoteEditHashStateP21>, options?: UpdateFormHashStateOptions) => void;
  orderType: OrderType;
}) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const defaultQuoteFieldData = activeTenant.defaultData.quote;
  const quoteFormConfig = getTenantConfiguration(activeTenant).quoteOrderEntryFlow.formConfig;

  useEffect(() => {
    // explicit pick, so undefined values are explicitly set, and override internal form state
    const fieldsValue = objPickKeys(
      quoteState,
      'company',
      'customer',
      'shipTo',
      'contact',
      'location',
      'sourceLocation',
      'freightType',
      'freightCharge',
      'carrier',
      'shippingRouteId',
      'willCall',
      'packingBasis',
      'deliveryInstructions',
      'shipToEmailAddress',
      'requiredDate',
      'validUntilDate',
      'customerJobNo',
      'poNo',
      'headerNote',
      'qteForOrder',
      'frontCounter',
      'terms',
      'class1',
    );
    // use quoteState as the source of state
    form.setFieldsValue(fieldsValue);
  }, [form, quoteState]);

  const companyId = quoteState.company ? splitIdNameStr(quoteState.company).foreignId : '';
  const { foreignId: customerId, name: customerName } = quoteState.customer
    ? splitIdNameStr(quoteState.customer)
    : { foreignId: '', name: '' };
  const shipToId = quoteState.shipTo ? splitIdNameStr(quoteState.shipTo).foreignId : '';
  const companiesSelectProps = useCompaniesSelectProps();
  const customersSelectProps = useCustomersSelectProps({ companyId });
  const freightsSelectProps = useFreightsSelectProps({ companyId });
  const freightChargesSelectProps = useFreightChargesSelectProps();
  const contactsSelectProps = useContactsSelectProps({ companyId, customerId });
  const shipTosSelectProps = useShipTosSelectProps({ companyId, customerId });
  const locationsSelectProps = useLocationsSelectProps({ companyIds: optArrFromVal(companyId) });
  const carriersSelectProps = useCarriersSelectProps();
  const shippingRouteSelectProps = useShippingRoutesSelectProps();
  const packingBasisSelectProps = usePackingBasesSelectProps();
  const orderPrioritiesSelectProps = useOrderPrioritiesSelectProps();
  const orderClass1SelectProps = useOrderClassesSelectProps({ classNumber: 1 });
  const termsSelectProps = useTermsSelectProps();

  const shouldChangeHeaderSourceLocation = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersChangeSourceLocationForHeader,
  );

  const { data: orderClassesData, isLoading: orderClassesIsLoading } = useCoreApi(schemas.orderClasses.getOrderClasses);
  const class1 = orderClassesData?.items.filter((oc) => oc.classNumber === 1)[0];

  const smartFieldDefaults = {
    company: {
      default: joinIfIdNameObj(defaultQuoteFieldData.company),
      selectProps: companiesSelectProps,
    },
    shipTo: {
      default: undefined,
      selectProps: shipTosSelectProps,
    },
    carrier: {
      default: shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableCarrierExport)
        ? undefined
        : joinIfIdNameObj(defaultQuoteFieldData.carrier),
      selectProps: carriersSelectProps,
    },
    orderTypePriority: {
      default: undefined,
      selectProps: orderPrioritiesSelectProps,
    },
  };

  if (
    !(
      shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeFreightType) ||
      shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableFreightExport)
    )
  ) {
    Object.assign(smartFieldDefaults, {
      freightType: {
        selectProps: freightsSelectProps,
        default:
          joinIfIdNameObj(defaultQuoteFieldData.company) === quoteState.company
            ? joinIfIdNameObj(defaultQuoteFieldData.freightType)
            : undefined,
      },
    });
  }

  // make smart default selection for field values
  // either the settings specified default, or the only item in options
  for (const field of objKeys(smartFieldDefaults)) {
    const smartDefault = smartFieldDefaults[field];
    if (!quoteState[field]) {
      const defaultValue =
        smartDefault.default ||
        (smartDefault.selectProps.options.length === 1 &&
          !smartDefault.selectProps.isLoading &&
          !smartDefault.selectProps.searchQuery &&
          smartDefault.selectProps.options[0].value);
      if (defaultValue) {
        requestAnimationFrame(() => {
          onQuoteStateChange({ [field]: defaultValue }, { defaultValue: true });
          if (field === 'shipTo') {
            onSelectShipTo(smartDefault.selectProps.options[0]);
          }
        });
      }
    }
  }

  const today = moment().startOf('day');
  const tomorrow = moment().startOf('day').add(1, 'day');
  // add date field defaults
  if (!quoteState.requiredDate) {
    requestAnimationFrame(() => {
      // required date is default to tomorrow to reduce the chance of ts comparison
      // error caused by validating order date <= required date at server side
      onQuoteStateChange({ requiredDate: tomorrow.toISOString() }, { defaultValue: true });
    });
  }
  if (orderType === OrderType.Quote && !quoteState.validUntilDate) {
    requestAnimationFrame(() => {
      onQuoteStateChange(
        {
          validUntilDate: today.add(quoteFormConfig.quoteValidUntilDays.defaultValue, 'd').toISOString(),
        },
        { defaultValue: true },
      );
    });
  }
  // apply customer restrictions only apply to orders, not quotes
  const { data: customerRestrictionsData } = useLegacyApi<CustomerRestrictionsResponse>(
    customerId && orderType === OrderType.Order
      ? `/v3/customers/${encodeLegacyApiParam(customerId)}/restrictions`
      : NULLIFY_API_CALL,
  );

  // set stateSalesTaxExemptionNumber from customerRestrictionsData if provided
  useEffect(() => {
    if (customerRestrictionsData?.stateSalesTaxExemptionNumber !== quoteState.stateSalesTaxExemptionNumber) {
      onQuoteStateChange(
        { stateSalesTaxExemptionNumber: customerRestrictionsData?.stateSalesTaxExemptionNumber },
        { defaultValue: true },
      );
    }
  }, [customerRestrictionsData, onQuoteStateChange, quoteState.stateSalesTaxExemptionNumber]);

  // re-validate customer field after customerRestrictionsData is loaded
  useEffect(() => {
    if (customerId && customerRestrictionsData) {
      form.validateFields(['customer']);
    }
  }, [form, customerRestrictionsData, customerId]);

  // for GAL, certain customers  have user defined fields for default PO number,
  // automatically apply the default poNo
  useEffect(() => {
    if (
      customerRestrictionsData?.customFields?.defaultPoNumber &&
      quoteState.poNo !== customerRestrictionsData?.customFields?.defaultPoNumber
    ) {
      onQuoteStateChange({ poNo: customerRestrictionsData?.customFields?.defaultPoNumber }, { defaultValue: true });
    }
  }, [customerRestrictionsData, onQuoteStateChange, quoteState.poNo]);

  const quickButtons = useMemo(
    () => [
      { name: 'in a week', value: moment().add(7, 'd') },
      { name: 'in a month', value: moment().add(1, 'M') },
    ],
    [],
  );

  const prevCustomerId = usePreviousVal(customerId);

  // set terms = customer's default terms_desc if provided
  useEffect(() => {
    if (customerId !== prevCustomerId) {
      getStateUpdateWithCustomerTerms(customerId, companyId)
        .then((stateUpdate) => stateUpdate && onQuoteStateChange(stateUpdate))
        .catch((err) => captureError(err));
    }
  }, [companyId, onQuoteStateChange, customerId, prevCustomerId]);

  const onSelectCompany = () => {
    // clear form when compnay changes if there was a company
    onQuoteStateChange({
      customer: undefined,
      contact: undefined,
      shipTo: undefined,
      freightType: undefined,
      location: undefined,
      sourceLocation: undefined,
      carrier: undefined,
      willCall: undefined,
      packingBasis: undefined,
      deliveryInstructions: undefined,
      shipToEmailAddress: undefined,
      requiredDate: undefined,
      validUntilDate: undefined,
      shippingRouteId: undefined,
      poNo: undefined,
      customerJobNo: undefined,
      headerNote: undefined,
      stateSalesTaxExemptionNumber: undefined,
      qteForOrder: undefined,
      frontCounter: undefined,
      freightCharge: undefined,
      items: quoteState.items?.map((item) => ({
        foreignId: item.foreignId,
        name: item.name,
        quantity: item.quantity,
        requiredDate: undefined,
      })),
      terms: undefined,
    });
  };

  const onSelectCustomer = (option?: CustomerSelectOption) => {
    // clear form when customer changes if there was a customer
    // also set terms = customer's default terms_desc
    onQuoteStateChange({
      contact: undefined,
      shipTo: undefined,
      freightType: undefined,
      location: undefined,
      sourceLocation: undefined,
      carrier: undefined,
      willCall: undefined,
      packingBasis: undefined,
      deliveryInstructions: undefined,
      shipToEmailAddress: undefined,
      requiredDate: undefined,
      validUntilDate: undefined,
      shippingRouteId: undefined,
      poNo: undefined,
      customerJobNo: undefined,
      headerNote: undefined,
      stateSalesTaxExemptionNumber: undefined,
      qteForOrder: undefined,
      frontCounter: undefined,
      freightCharge: undefined,
      items: quoteState.items?.map((item) => ({ foreignId: item.foreignId, name: item.name, requiredDate: undefined })),
      terms: option?.terms_desc,
    });
  };

  const onSelectShipTo = (option: ShipToSelectOption) => {
    if (!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableFreightExport)) {
      if (
        option.freightType &&
        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeFreightType)
      ) {
        onQuoteStateChange({ freightType: joinIfIdNameObj(option.freightType) });
      } else {
        onQuoteStateChange({ freightType: joinIfIdNameObj(defaultQuoteFieldData.freightType) });
      }
    }
    // At GAL, CSRs should not auto-populate location from ship-to, rather use taker location instead
    // If the feature flag is off, the location will use the ship to default location
    // If the feature flag is on, the location will use the taker location
    const shouldUseShipToLocation =
      !quoteState.location ||
      !shouldShowFeatureFlag(
        activeTenant,
        activeUser,
        TenantFeatureFlag.OrdersDefaultSalesLocationFromTakerDefaultLocation,
      );

    if (option.location && shouldUseShipToLocation) {
      onQuoteStateChange({ location: joinIfIdNameObj(option.location) });
      if (shouldChangeHeaderSourceLocation) {
        onQuoteStateChange({ sourceLocation: joinIfIdNameObj(option.location) });
      }
    }
    if (
      !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisablePackingBasisExport) &&
      option.packingBasis
    ) {
      onQuoteStateChange({ packingBasis: option.packingBasis });
    }
    if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeDeliveryInstructions)) {
      if (option.deliveryInstructions) {
        onQuoteStateChange({ deliveryInstructions: option.deliveryInstructions });
      }
    }
    if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeShipToEmailAddress)) {
      if (option.shipToEmailAddress) {
        onQuoteStateChange({ shipToEmailAddress: option.shipToEmailAddress });
      }
    }
    if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeFreightCharge)) {
      if (option.freightCharge) {
        onQuoteStateChange({ freightCharge: joinIfIdNameObj(option.freightCharge) });
      }
    }
    // If CarrierExport and OrdersChangeCarrier are both allowed, the carrier will use the ship to default carrier
    if (!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableCarrierExport)) {
      if (option.carrier && shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeCarrier)) {
        onQuoteStateChange({ carrier: joinIfIdNameObj(option.carrier) });
      }
    }
    if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeShippingRoute)) {
      if (option.shippingRouteUid) {
        onQuoteStateChange({
          shippingRouteId: shippingRouteSelectProps.options.filter((o) => o.data === option.shippingRouteUid)[0]?.value,
        });
      } else {
        onQuoteStateChange({
          shippingRouteId: undefined,
        });
      }
    }
    onQuoteStateChange({ customer: joinIfIdNameObj(option.customer) });
  };

  const creditLimit = customerRestrictionsData?.creditLimit ?? 1;

  return (
    <Styled.HeaderContainer>
      <Form
        form={form}
        onFinishFailed={console.error}
        onValuesChange={(changedValues) => onQuoteStateChange(changedValues)}
        {...responsiveFormLayout}
      >
        <Form.Item
          name="company"
          label="Company"
          rules={[{ required: true, message: 'Please add a company.' }]}
          hidden={companiesSelectProps.options.length <= 1}
        >
          <AsyncSelect selectProps={companiesSelectProps} entityPlural="companies" onSelect={onSelectCompany} />
        </Form.Item>
        {companiesSelectProps.options.length > 1 && <br />}
        <Form.Item
          name="customer"
          label="Customer"
          rules={[
            { required: true, message: 'Please add a customer.' },
            customerId
              ? {
                  validator: async (_rule: RuleObject) => {
                    if (customerRestrictionsData?.customerId !== customerId) return;
                    const { creditStatus } = customerRestrictionsData;

                    if (creditStatus.orderEntryAction === CustomerRestrictionsOrderEntryAction.Error) {
                      throw new Error(`Credit status: ${creditStatus.creditStatusDesc}.`);
                    }
                  },
                }
              : null,
          ].filter(truthy)}
          extra={
            <div
              className={css`
                color: ${theme.colors.neutral[500]};
                margin-top: -2px;
              `}
            >
              {customerRestrictionsData?.creditStatus?.orderEntryAction ===
              CustomerRestrictionsOrderEntryAction.Warning ? (
                <>
                  Credit status: {customerRestrictionsData.creditStatus.creditStatusDesc}.{' '}
                  {customerRestrictionsValidationMessageMap[customerRestrictionsData.creditStatus.validationAction]}
                  <br />
                </>
              ) : null}
              {orderType === OrderType.Order &&
              creditLimit > 0 &&
              (customerRestrictionsData?.creditLimitUsed ?? 0) > creditLimit ? (
                <>
                  Customer is over credit limit. This order will be placed on hold after it is submitted to{' '}
                  {getErpName(activeTenant.erpType)}.
                  <br />
                </>
              ) : null}
            </div>
          }
        >
          <AsyncSelect
            selectProps={customersSelectProps}
            entityPlural="customers"
            disabled={!companyId}
            onSelect={(_, option) => onSelectCustomer(option as CustomerSelectOption)}
            onClear={() => onSelectCustomer()}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Ship To" required rules={[{ required: true, message: 'Please add a ship to.' }]}>
          <div className={addButtonRowCss}>
            <Form.Item
              name="shipTo"
              className={css`
                flex-grow: 1;
              `}
              rules={[{ required: true, message: 'Please add a ship to.' }]}
            >
              <AsyncSelect
                selectProps={shipTosSelectProps}
                entityPlural="ship tos"
                disabled={!companyId}
                // defaulting to the freight type associated with the ship to as it's location agnostic, just based on the ship to itself
                onSelect={(_, option) => onSelectShipTo(option as ShipToSelectOption)}
              />
            </Form.Item>
            <NewShipToButton
              initialValues={{
                companyId,
                customerId,
              }}
              onSubmit={(shipTo: ShipToResponseDTO) => {
                shipTosSelectProps.reload?.();
                onQuoteStateChange({
                  shipTo: joinIdNameObj({ foreignId: shipTo.shipToId || '', name: shipTo.shipToName }),
                });
              }}
              disabled={!customerId || !companyId}
              collapsible
            />
          </div>
        </Form.Item>

        <Form.Item label="Contact" required={quoteFormConfig.contact.required}>
          <div className={addButtonRowCss}>
            <Form.Item
              name="contact"
              className={css`
                flex-grow: 1;
              `}
              rules={[{ required: quoteFormConfig.contact.required, message: 'Please add a contact.' }]}
            >
              <AsyncSelect
                selectProps={contactsSelectProps}
                entityPlural="contacts"
                disabled={!customerId}
                placeholder={!customerId ? 'Please select a customer first' : ''}
                allowClear
              />
            </Form.Item>
            <NewContactButton
              initialValues={{
                customer: {
                  foreignId: customerId || '',
                  name: customerName || '',
                  companyId: companyId || '',
                },
              }}
              onSubmit={(c: ContactDTO) => {
                onQuoteStateChange({
                  contact: joinIdNameObj({ foreignId: c.contactId || '', name: c.contactName }),
                });
                contactsSelectProps.reload?.();
              }}
              disabled={!customerId || !companyId}
              collapsible
            />
          </div>
        </Form.Item>

        <Form.Item name="terms" label="Terms">
          <AsyncSelect
            /** If the value of quoteFormConfig.terms.enableExport is set to true,
             * the 'terms' field should be both editable and exportable.
             * Otherwise, the 'terms' field must not be editable or exported to P21,
             * and instead the Q/O terms will be automatically generated based on the
             * customer's terms, which are displayed here.
             * */
            selectProps={termsSelectProps}
            entityPlural="terms"
            disabled={!customerId || !quoteFormConfig.terms.enableExport}
            placeholder={!customerId ? 'Please select a customer first' : ''}
            onSelect={(value) => (quoteFormConfig.terms.enableExport ? onQuoteStateChange({ terms: value }) : null)}
          />
        </Form.Item>
        <br />
        <Form.Item
          name="requiredDate"
          label="Required Date"
          rules={[
            { required: true, message: 'Please add a required date.' },
            { validator: validateDateInFuture, message: "Required date can't be in the past" },
          ]}
        >
          <IsoDatePicker quickButtons={quickButtons} />
        </Form.Item>
        {orderType === OrderType.Quote && (
          <Form.Item
            name="validUntilDate"
            label="Valid Until Date"
            rules={[
              { required: true, message: 'Please add a valid until date.' },
              { validator: validateDateInFuture, message: "Valid until date can't be in the past" },
            ]}
          >
            <IsoDatePicker quickButtons={quickButtons} />
          </Form.Item>
        )}
        <br />
        <Form.Item
          name="location"
          label={shouldChangeHeaderSourceLocation ? `Sales Location` : `Location`}
          rules={[{ required: true, message: `Please add a sales location.` }]}
        >
          <AsyncSelect
            selectProps={locationsSelectProps}
            entityPlural="locations"
            onSelect={(value) =>
              shouldChangeHeaderSourceLocation ? onQuoteStateChange({ sourceLocation: value }) : null
            }
          />
        </Form.Item>
        {shouldChangeHeaderSourceLocation && (
          <Form.Item
            name="sourceLocation"
            label="Source Location"
            rules={[{ required: quoteFormConfig.sourceLocation.required, message: 'Please add a source location.' }]}
          >
            <AsyncSelect selectProps={locationsSelectProps} entityPlural="source locations" />
          </Form.Item>
        )}
        {!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableFreightExport) && (
          <Form.Item
            name="freightType"
            label="Freight Type"
            rules={[
              {
                required: true,
                message: 'Please add a freight code.',
              },
            ]}
          >
            {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeFreightType) ? (
              <AsyncSelect
                selectProps={freightsSelectProps}
                entityPlural="freight codes"
                disabled={!shipToId}
                placeholder={!customerId && !shipToId ? 'Please select a ship to first' : ''}
              />
            ) : (
              <AsyncSelect selectProps={freightsSelectProps} entityPlural="freight codes" />
            )}
          </Form.Item>
        )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeFreightCharge) && (
          <Form.Item name="freightCharge" label="Freight Charge">
            <AsyncSelect
              selectProps={freightChargesSelectProps}
              entityPlural="freight charges"
              disabled={!shipToId}
              placeholder={!shipToId ? 'Please select a ship to first' : ''}
              allowClear
            />
          </Form.Item>
        )}
        {!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableCarrierExport) &&
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeCarrier) && (
            <Form.Item
              name="carrier"
              label="Carrier"
              rules={[{ required: quoteFormConfig.carrier.required, message: 'Please add a carrier.' }]}
            >
              <AsyncSelect
                selectProps={carriersSelectProps}
                entityPlural="carriers"
                disabled={!shipToId}
                placeholder={!shipToId ? 'Please select a ship to first' : ''}
                allowClear
              />
            </Form.Item>
          )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeShippingRoute) && (
          <Form.Item
            name="shippingRouteId"
            label="Route"
            rules={[
              quoteFormConfig.shippingRouteId.required && splitIfIdNameStr(quoteState.carrier)?.foreignId === '10009'
                ? {
                    required: true,
                    message: 'Please select a route. Route must be selected if carrier is Larson Truck.',
                  }
                : null,
            ].filter(truthy)}
          >
            <AsyncSelect selectProps={shippingRouteSelectProps} entityPlural="routes" allowClear />
          </Form.Item>
        )}
        {quoteFormConfig.isQuoteForOrder.visible && orderType === OrderType.Quote && (
          <>
            <Form.Item name="qteForOrder" label="Quote For Order">
              <Checkbox
                checked={quoteState.qteForOrder === 'Y'}
                onChange={(e) =>
                  onQuoteStateChange({
                    qteForOrder: e.target.checked ? 'Y' : 'N',
                  })
                }
              />
            </Form.Item>
          </>
        )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersFrontCounterEnabled) &&
          orderType === OrderType.Order && (
            <>
              <Form.Item name="frontCounter" label="Front Counter">
                <Checkbox
                  checked={quoteState.frontCounter}
                  onChange={() => onQuoteStateChange({ frontCounter: !quoteState.frontCounter })}
                />
              </Form.Item>
            </>
          )}
        {!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersWillCallDisabled) &&
          orderType === OrderType.Order && (
            <>
              <Form.Item name="willCall" label="Will Call">
                <Checkbox
                  checked={quoteState.willCall}
                  onChange={() => onQuoteStateChange({ willCall: !quoteState.willCall })}
                  data-testid="willCall"
                />
              </Form.Item>
            </>
          )}
        {quoteFormConfig.class1.fieldType === 'checkbox' && !orderClassesIsLoading && class1 && (
          <>
            <Form.Item name="class1" label={toTitleCase(class1.classDescription.toLocaleLowerCase())}>
              <Checkbox
                checked={quoteState.class1 === `${class1.classId}: ${class1.classDescription}`}
                onChange={(e) =>
                  onQuoteStateChange({
                    class1: e.target.checked ? `${class1.classId}: ${class1.classDescription}` : undefined,
                  })
                }
              />
            </Form.Item>
          </>
        )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChooseClass1) &&
          quoteFormConfig.class1.fieldType === 'asyncSelect' && (
            <>
              <Form.Item
                name="class1"
                label="Order Class 1"
                rules={[{ required: quoteFormConfig.class1.required, message: 'Please add a class 1.' }]}
              >
                <AsyncSelect selectProps={orderClass1SelectProps} entityPlural="order class 1" allowClear />
              </Form.Item>
            </>
          )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangePackingBasis) && (
          <>
            <Form.Item name="packingBasis" label="Packing Basis">
              <AsyncSelect
                selectProps={packingBasisSelectProps}
                entityPlural="a packing basis"
                disabled={!shipToId}
                placeholder={!shipToId ? 'Please select a ship to first' : ''}
                allowClear
              />
            </Form.Item>
          </>
        )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeDeliveryInstructions) && (
          <>
            <Form.Item
              name="deliveryInstructions"
              label="Delivery Instructions"
              rules={[
                {
                  required: quoteFormConfig.deliveryInstructions.required,
                  message: 'Please add delivery instructions',
                },
              ]}
            >
              <TextArea
                rows={4}
                disabled={!shipToId}
                placeholder={!shipToId ? 'Enter delivery instructions here to be shown in order entry and on PDFs' : ''}
                allowClear
              />
            </Form.Item>
          </>
        )}
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeShipToEmailAddress) && (
          <>
            <Form.Item
              name="shipToEmailAddress"
              label="Email Address"
              rules={[
                { required: quoteFormConfig.emailAddress.required, message: 'Please add a ship to email address.' },
              ]}
            >
              <TextArea
                // this field can contain multiple emails thus multiline textarea string is applied
                autoSize={{ minRows: 2, maxRows: 8 }}
                disabled={!shipToId}
                placeholder={!shipToId ? 'Enter email address here for shipping notification' : undefined}
                allowClear
              />
            </Form.Item>
          </>
        )}
        {orderPrioritiesSelectProps.options.length > 0 && (
          <>
            <Form.Item name="orderTypePriority" label="Order Priority">
              <AsyncSelect
                selectProps={orderPrioritiesSelectProps}
                entityPlural="an order priority"
                placeholder="Please select an order priority"
                allowClear
              />
            </Form.Item>
          </>
        )}
        <br />
        <Form.Item
          name="poNo"
          label="PO No"
          rules={[
            {
              required: customerRestrictionsData?.poNoRequired === 'Y' || quoteFormConfig.customerPONumber.required,
              message: 'Please add a customer PO number.',
            },
            { validator: validatePONumber },
          ]}
        >
          <Input disabled={!!customerRestrictionsData?.customFields?.defaultPoNumber} />
        </Form.Item>
        {quoteFormConfig.customerJobNo.visible && (
          <>
            <Form.Item
              name="customerJobNo"
              label="Customer Job No"
              rules={[
                orderType === OrderType.Order
                  ? {
                      required: customerRestrictionsData?.customFields?.customerJobNumRequired === 'Y',
                      message: 'Customer Job number is required.',
                    }
                  : {
                      required: false,
                      message: 'Please add a customer job number.',
                    },
              ]}
            >
              <Input disabled={false} />
            </Form.Item>
            <br />
          </>
        )}
        {!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersDisableNotesExportForP21) && (
          <Form.Item name="headerNote" label="Header Note">
            <TextArea rows={3} placeholder="Enter a note to be shown in order entry and on PDFs" />
          </Form.Item>
        )}
      </Form>
    </Styled.HeaderContainer>
  );
};
