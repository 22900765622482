import { Modal as AntdModal } from 'antd';

import { styled } from 'utils/styledComponent';

export const Modal = styled(AntdModal)`
  .ant-modal-header {
    border-radius: 8px;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-body {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .ant-modal-footer {
    .ant-btn {
      border-radius: 8px;
    }
  }
`;
