import React, { useEffect, useState } from 'react';

import { EnvironmentOutlined, ExclamationCircleOutlined, SkinOutlined, WarningOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { UsageLineType, UsageQuantitySource } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { DemandUsageLineRecordDTO } from '@recurrency/core-api-schema/dist/ml/getDemandUsageLines';
import { DemandUsageLinesOverrideUpdate } from '@recurrency/core-api-schema/dist/ml/updateDemandUsageLineOverrides';
import { Checkbox, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { AsyncMultiSelect, filterMultiSelectOptions } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useItemsSelectProps, useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { AsyncButton } from 'components/Button/AsyncButton';
import { DateRangePicker } from 'components/DatePicker';
import { DividerLine } from 'components/DividerLine';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { Input } from 'components/Input';
import { ResultCount } from 'components/ResultCount';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { coreApiFetch } from 'utils/api';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatIdNameObj } from 'utils/formatting';
import { routes } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { asKeyOf, sortableDateColumn, sortableNumberColumn } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

import { ExternalLink } from '../Links';
import { InputOverride, Override } from './planning/InputOverride';

export enum UsageSourceSettings {
  Imported = 'IMPORTED',
}

const usageTypeOptions: MultiSelectOption[] = [
  { value: '', label: 'All Lines' },
  { value: UsageLineType.RecommendedOutlier, label: 'Recommended Outliers' },
  { value: UsageLineType.Overriden, label: 'Overridden Usage' },
];

export interface UsageLineOverride {
  itemId: string;
  usageId: string;
  locationId: string;
  usageDate: string;
  usageQty: number;
  usageQtyBase: number;
  exceptional: boolean;
}

export const DemandUsageLinesTable = ({
  itemUid,
  locationId,
  dateFilter,
  usageLineOverrideInputs,
  onChangeUsageLine,
  onClearUsageLines,
  setDateFilter,
}: {
  itemUid: string;
  locationId: string;
  dateFilter: DateFilter;
  usageLineOverrideInputs: UsageLineOverride[];
  onChangeUsageLine: (usageLineOverride: UsageLineOverride) => void;
  onClearUsageLines: () => void;
  setDateFilter: (dateFilter: DateFilter) => void;
}) => {
  const locationsSelectProps = useLocationsSelectProps({});
  const itemsSelectProps = useItemsSelectProps();
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [filteredItemCodes, setFilteredItemCodes] = useState<string[]>([]);
  const [isClearOverridesPressed, setIsClearOverridesPressed] = useState<boolean>(false);
  const [filteredLineType, setFilteredLineType] = useState<UsageLineType>();
  const [overrideNote, setOverrideNote] = useState<string>('');

  const usageSourceSetting = getTenantSetting(TenantSettingKey.CalculationUseImportedUsage)
    ? UsageSourceSettings.Imported
    : getTenantSetting(TenantSettingKey.CalculationUsageQuantitySource);

  const isMonthlyUsageLines = usageSourceSetting === UsageSourceSettings.Imported;

  const tableProps = useCoreApiTableProps({
    schema: schemas.ml.getDemandUsageLines,
    queryParams: {
      filters: {
        itemIds: [itemUid],
        locationIds: [locationId],
        sourceItemCodes: filteredItemCodes,
        sourceLocationIds: filteredLocationIds,
        usageLineType: filteredLineType,
        startDate: formatMomentDateToSqlDate(dateFilter.from),
        endDate: formatMomentDateToSqlDate(dateFilter.to),
      },
    },
    pageSize: 10, // Setting a smaller page size to make the bottom Save/Clear override buttons more visible
  });

  useEffect(() => {
    /*
     * This will make sure that - if Clear Overrides button was pressed - all previously overriden usage lines from
     * the current table page are cleared: usageQty is set to usageQtyBase and exceptional is set to false.
     */
    if (isClearOverridesPressed) {
      tableProps.items.forEach((record) => {
        const overrideInput = usageLineOverrideInputs.find((input) => input.usageId === record.id);
        if (!overrideInput && record.usageQtyBase !== record.usageQty) {
          onChangeUsageLine({
            usageId: record.id,
            itemId: record.itemId,
            locationId: record.sourceLocationId,
            usageDate: record.usageDate,
            usageQty: record.usageQtyBase,
            usageQtyBase: record.usageQtyBase,
            exceptional: false,
          });
        }
      });
    }
  }, [isClearOverridesPressed, onChangeUsageLine, tableProps.items, usageLineOverrideInputs]);

  const { isLoading, setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredItemCodes, filteredLocationIds, dateFilter, filteredLineType, setPage]);

  // specific to how usage ids are formatted, as they are either usageId|linenumber or usageId
  const formatUsageId = (id: string) => (id.includes('|') ? id.slice(0, id.lastIndexOf('|')) : id);

  const tableColumns: (ColumnType<DemandUsageLineRecordDTO> | null)[] = [
    usageSourceSetting === UsageSourceSettings.Imported
      ? {
          title: <InfoTooltip title="Usage directly imported from ERP">Imported Usage ID</InfoTooltip>,
          dataIndex: asKeyOf<DemandUsageLineRecordDTO>('id'),
          render: (_, record: DemandUsageLineRecordDTO) => formatUsageId(record.id),
        }
      : null,
    usageSourceSetting === UsageQuantitySource.QuantityInvoiced
      ? {
          title: 'Invoice No',
          dataIndex: asKeyOf<DemandUsageLineRecordDTO>('id'),
          render: (_, record: DemandUsageLineRecordDTO) => formatUsageId(record.id),
        }
      : null,
    usageSourceSetting === UsageQuantitySource.QuantityOrdered ||
    usageSourceSetting === UsageQuantitySource.QuantityInvoiced
      ? {
          title: 'Order No',
          dataIndex: asKeyOf<DemandUsageLineRecordDTO>('id'),
          render: (_, record: DemandUsageLineRecordDTO) =>
            record.salesOrderNo ? (
              <ExternalLink to={routes.orders.orderDetails(record.salesOrderNo)}>{record.salesOrderNo}</ExternalLink>
            ) : (
              formatUsageId(record.id)
            ),
        }
      : null,
    {
      title: 'Item',
      dataIndex: asKeyOf<DemandUsageLineRecordDTO>('sourceItemName'),
      render: (_, record: DemandUsageLineRecordDTO) => (
        <div>
          <ExternalLink to={routes.purchasing.itemDetails(record.sourceItemCode)}>{record.sourceItemCode}</ExternalLink>
          {`: ${record.sourceItemName}`}
        </div>
      ),
    },
    {
      title: 'Location',
      dataIndex: asKeyOf<DemandUsageLineRecordDTO>('sourceLocationId'),
      render: (_, record: DemandUsageLineRecordDTO) =>
        formatIdNameObj({ foreignId: record.sourceLocationId, name: record.sourceLocationName }),
    },
    !isMonthlyUsageLines
      ? {
          title: 'Customer',
          dataIndex: asKeyOf<DemandUsageLineRecordDTO>('customerId'),
          render: (_, record: DemandUsageLineRecordDTO) =>
            formatIdNameObj(
              record.customerId && record.customerName
                ? { foreignId: record.customerId, name: record.customerName }
                : undefined,
            ),
        }
      : null,
    sortableDateColumn({
      title: 'Usage Date',
      dataIndex: asKeyOf<DemandUsageLineRecordDTO>('usageDate'),
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableNumberColumn({
      title: 'Usage',
      dataIndex: asKeyOf<DemandUsageLineRecordDTO>('usageQty'),
      width: 150,
      sorter: true,
      render: (_, record: DemandUsageLineRecordDTO) => {
        const override: Override = {
          updatedBy: record.overrideUpdatedByUserName,
          updatedAt: record.overrideUpdatedAt,
          quantity: record.usageQty,
          baseQuantity: record.usageQtyBase,
          uom: record.unitOfMeasure,
          note: record.overrideNote,
        };

        const overrideInput = usageLineOverrideInputs.find((input) => input.usageId === record.id);

        return (
          <FlexSpace gap={8} direction="row">
            {!record.overrideUpdatedAt && record.isOutlier && usageSourceSetting === UsageSourceSettings.Imported && (
              <Tooltip
                className={css`
                  margin-left: 5px;
                `}
                title="Recurrency has determined this month of usage to be an outlier. Considering overriding the value if there was exceptional usage during that month."
              >
                <WarningOutlined />
              </Tooltip>
            )}
            <InputOverride
              override={override}
              value={
                overrideInput?.usageQty ?? (record.overrideUpdatedByUserId ? record.usageQty : record.usageQtyBase)
              }
              disabled={!isMonthlyUsageLines && (overrideInput ? overrideInput?.usageQty === 0 : record.usageQty === 0)}
              onValueChange={(qty) =>
                onChangeUsageLine({
                  usageId: record.id,
                  itemId: record.itemId,
                  locationId: record.sourceLocationId,
                  usageDate: record.usageDate,
                  usageQty: qty,
                  usageQtyBase: record.usageQtyBase,
                  exceptional: qty === 0,
                })
              }
            />
          </FlexSpace>
        );
      },
    }),
    {
      title: 'UOM',
      dataIndex: asKeyOf<DemandUsageLineRecordDTO>('unitOfMeasure'),
    },
    !isMonthlyUsageLines
      ? {
          title: (
            <InfoTooltip title="Mark a usage line as exceptional to remove its usage from the history. It will not be used in calculating forecasts in the future.">
              Exceptional
            </InfoTooltip>
          ),
          render: (record: DemandUsageLineRecordDTO) => {
            const overrideInput = usageLineOverrideInputs.find((input) => input.usageId === record.id);
            return (
              <>
                <Checkbox
                  checked={overrideInput ? overrideInput?.usageQty === 0 : record.usageQty === 0}
                  onChange={(ev) =>
                    onChangeUsageLine({
                      usageId: record.id,
                      itemId: record.itemId,
                      locationId: record.sourceLocationId,
                      usageDate: record.usageDate,
                      usageQty: ev.target.checked ? 0 : record.usageQty || record.usageQtyBase,
                      usageQtyBase: record.usageQtyBase,
                      exceptional: ev.target.checked,
                    })
                  }
                />
                {record.isOutlier && (
                  <Tooltip
                    className={css`
                      margin-left: 5px;
                    `}
                    title="Recurrency has determined this usage line to be an outlier. Considering marking this item as exceptional to remove its usage from forecasting."
                  >
                    <ExclamationCircleOutlined />
                  </Tooltip>
                )}
              </>
            );
          },
        }
      : null,
  ];

  const isOverridesChanged = usageLineOverrideInputs.some(
    (o) => o.usageQty !== tableProps.items.find((r) => r.id === o.usageId)?.usageQty,
  );

  async function handleUpdateUsageLineOverrides(overrides: UsageLineOverride[]) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Usage Override',
      expectedWaitSeconds: 10,
    });

    const updates: DemandUsageLinesOverrideUpdate[] = overrides.map((o) => ({
      itemId: o.itemId,
      locationId: o.locationId,
      quantity: o.usageQty === o.usageQtyBase ? null : o.usageQty,
      usageId: o.usageId,
      note: overrideNote,
    }));

    try {
      // If Clear Overrides button is pressed, we'll send an API request to clear all overrides that are saved in the backend
      if (isClearOverridesPressed) {
        await coreApiFetch(schemas.ml.updateDemandUsageLineOverrides, {
          bodyParams: {
            updates: [{ itemId: itemUid, locationId, quantity: null }],
          },
        });
      }

      if (updates.length > 0) {
        await coreApiFetch(schemas.ml.updateDemandUsageLineOverrides, {
          bodyParams: {
            updates,
          },
        });
      }

      submitNotification.success({
        successMessage: 'Usage overrides applied',
        description: () =>
          'Override updates to Planning & Forecasts will apply overnight as it requires significant recalculations.',
        duration: 10,
      });
      tableProps.reload();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  function handleClearUsageLines() {
    onClearUsageLines();
    setIsClearOverridesPressed(true);
  }

  return (
    <>
      <FilterBarBox>
        <AsyncMultiSelect
          selectProps={filterMultiSelectOptions(convertToMultiSelectProps(itemsSelectProps))}
          label="Item"
          queryPlaceholder="Search items"
          selectedValues={filteredItemCodes}
          onSelectedValuesChange={(values) => setFilteredItemCodes(values)}
          icon={<SkinOutlined />}
        />
        <AsyncMultiSelect
          selectProps={filterMultiSelectOptions(convertToMultiSelectProps(locationsSelectProps))}
          label="Location"
          queryPlaceholder="Search locations"
          selectedValues={filteredLocationIds}
          onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
          icon={<EnvironmentOutlined />}
        />
        <AsyncMultiSelect
          selectProps={{ options: usageTypeOptions }}
          mode="single"
          label="Exceptions"
          selectedValues={[filteredLineType || usageTypeOptions[0].value]}
          onSelectedValuesChange={(values) => setFilteredLineType((values[0] || undefined) as UsageLineType)}
          disableValuesTooltip
          icon={<ExclamationCircleOutlined />}
        />
        <FlexSpacer />
        <DateRangePicker
          value={[dateFilter.from, dateFilter.to]}
          onChange={(values) => setDateFilter(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>

      <AsyncTable tableProps={tableProps} columns={tableColumns.filter(filterCostAndGM)} loading={isLoading} />
      <DividerLine marginTop={5} marginBottom={15} color={colors.neutral[200]} />
      <FlexSpace justify="space-between">
        <Input
          value={overrideNote}
          disabled={!isOverridesChanged}
          onChange={(ev) => setOverrideNote(ev.target.value)}
          placeholder="Optional override note"
          size="small"
          className={css`
            max-width: 250px;
          `}
        />
        <FlexSpace justify="flex-end">
          <AsyncButton size="small" onClick={handleClearUsageLines}>
            Clear Overrides
          </AsyncButton>
          <AsyncButton
            disabled={!isOverridesChanged && !isClearOverridesPressed}
            type="primary"
            size="small"
            onClick={() => handleUpdateUsageLineOverrides(usageLineOverrideInputs)}
          >
            Save Usage Lines
          </AsyncButton>
        </FlexSpace>
      </FlexSpace>
    </>
  );
};
