import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { RightOutlined, LeftOutlined, SwapOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { QuoteStatus, TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ItemLotDTO } from '@recurrency/core-api-schema/dist/items/getItemLots';
import { SalesQuoteDraft } from '@recurrency/core-api-schema/dist/salesQuoteDrafts/common';
import { Form, message, notification, Space, Steps } from 'antd';
import produce, { applyPatches, enablePatches, Patch } from 'immer';
import { useDebounce } from 'use-debounce/lib';

import {
  hashStateFromQuote,
  quoteRequestFromHashState,
  LineItemsInfoCache,
  orderRequestFromHashState,
  getLineItemTrackProps,
  QuoteLineItemP21WithInfo,
  validatePrice,
  validateQuantity,
  validateName,
  getCachedItemPriceInfoMulti,
  getItemContract,
  calcPrice,
  minMaxLimit,
  getDuplicateLineItemsQty,
  convertOrderHashStateToQuote,
  convertQuoteHashStateToOrder,
  getLotInfoCacheKey,
  getCustomerPartIdInfoCacheKey,
  getCachedItemAvailability,
} from 'pages/orders/quotes/quoteUtils';
import { CustomModals, getTenantCustomizations } from 'pages/orders/quotes/tenantCustomUtils';
import { LineItemActionsP21, QuoteStatusType } from 'pages/orders/quotes/types';

import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { FixedFooter } from 'components/FixedFooter';
import { NotificationLink } from 'components/Links';
import { CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { AutoSaveModal } from 'components/recipes/AutoSaveModal';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { Tooltip } from 'components/Tooltip';
import { TrialBanner } from 'components/TrialBanner';

import { throwIfApiError, useLegacyApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { captureAndShowError, captureError } from 'utils/error';
import { capitalize, getErpName, joinIdNameObj, joinIfIdNameObj, splitIfIdNameStr } from 'utils/formatting';
import { LocalStorageKey } from 'utils/localStorage';
import { isEqual, isObjEmpty } from 'utils/object';
import { isAdmin as getIsAdmin, shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { encodeHashState, IdPathParams, routes, useFormHashState, usePathParams } from 'utils/routes';
import { scrollToTop } from 'utils/scroll';
import { getSearchIndexItemByItemId } from 'utils/search/search';
import { StatefulPromise } from 'utils/statefulPromise';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { isTenantGALarson, isTenantSafeware } from 'utils/tenants';
import { OrderType, track, TrackEvent } from 'utils/track';

import { LotSelection, QuoteEditHashStateP21, QuoteEditStep, QuoteLineItemP21 } from 'types/hash-state';
import { LineItemRequest, SalesRepLocationResponse } from 'types/legacy-api';
import { SearchIndexItemPartial } from 'types/search-collections';

import { PriceOverrideModal } from './PriceOverrideReasonModal';
import * as Styled from './QuoteEditFlowP21.style';
import { QuoteHeader } from './QuoteHeader';
import { QuoteLineItems } from './QuoteLineItems';
import { SummaryLine } from './QuoteLineItemSummaryStats';
import { QuoteReview } from './QuoteReview';

enablePatches();

export const QuoteEditFlowP21 = ({ orderType }: { orderType: OrderType }) => {
  const history = useHistory();
  const [lotInfoCache, setLotsInfoCache] = useState<{
    [key: string]: StatefulPromise<{ lotsAvailable?: ItemLotDTO[] }>;
  }>({});
  const [customerPartIdInfoCache, setCustomerPartIdInfoCache] = useState<{
    [key: string]: StatefulPromise<{ customerPartIdAvailable?: string[] }>;
  }>({});
  const { activeTenant, activeUser, activeErpRole } = useGlobalApp();
  const quoteOrderFormConfig = getTenantConfiguration(activeTenant).quoteOrderEntryFlow.formConfig;
  const { foreignId, name } = activeErpRole;
  const isAdmin = getIsAdmin(foreignId, name);

  const { id: quoteId } = usePathParams<IdPathParams>();

  const {
    data: quoteData,
    setData: quoteSetData,
    isLoading: quoteDataIsLoading,
  } = usePromise(async () => {
    if (quoteId) {
      const quoteResponse = await coreApiFetch(schemas.salesQuoteDrafts.getSalesQuoteDraft, {
        pathParams: { salesQuoteDraftId: quoteId },
      });
      // copy quoteData into hash state, but don't override existing state so user can refresh page to get back to same state
      // we do this inside a usePromise so quoteData and quoteState are in sync when quote loads
      updateQuoteState({ ...hashStateFromQuote(quoteResponse.data), ...quoteState });
      return quoteResponse.data;
    }
    return null;
  }, [quoteId]);

  const {
    formState: quoteState,
    updateFormState: updateQuoteState,
    savedFormState,
    clearSavedFormState,
  } = useFormHashState<QuoteEditHashStateP21>(
    orderType === OrderType.Quote ? LocalStorageKey.Form_Quote_AutoSave : LocalStorageKey.Form_Order_AutoSave,
    !(quoteId || quoteData?.id),
  );
  const prevLineItems = useRef<QuoteLineItemP21WithInfo[]>([]);
  // useDebounce rate-limits the calls to multiline when initializing a quote with a lot of line items
  // as the line items regularly update themselves which would cause another call to the endpoint on each update otherwise.
  const [debouncedQuoteState] = useDebounce(quoteState, 75);
  const { step: currentStep = QuoteEditStep.Header } = quoteState;

  const [headerForm] = Form.useForm<QuoteEditHashStateP21>();
  const [additionalSummaryLines, setAdditionalSummaryLines] = useState<SummaryLine[]>([]);
  const [validationErrorMsg, setValidationErrorMsg] = useState('');
  const [showAutoSaveModal, setShowAutoSaveModal] = useState(
    // Only show modal when there is saved state and the current quote is not a draft
    !isObjEmpty(savedFormState) && !(quoteId || quoteData?.id),
  );

  const customModals = {
    [CustomModals.PriceOverride]: { modal: PriceOverrideModal, props: { quoteState, onOk: updateQuoteState } },
  };

  const companyId = splitIfIdNameStr(quoteState.company)?.foreignId;
  const customerId = splitIfIdNameStr(quoteState.customer)?.foreignId;
  const salesLocationId = splitIfIdNameStr(quoteState.location)?.foreignId;
  const shipToId = splitIfIdNameStr(quoteState.shipTo)?.foreignId;
  const lineItems = useMemo(() => quoteState.items || [], [quoteState.items]);

  useEffect(() => {
    track(TrackEvent.Quotes_EditQuote_StepChange, { step: QuoteEditStep[currentStep], orderType });
  }, [currentStep, orderType]);

  const { data: salesRepLocationData } = throwIfApiError(useLegacyApi<SalesRepLocationResponse>('/v3/salesrep'));

  // set default location and taker based on salesRepLocationData
  useEffect(() => {
    if (salesRepLocationData && !quoteDataIsLoading) {
      const quoteStateUpdate: QuoteEditHashStateP21 = {};
      if (!quoteState.taker) {
        quoteStateUpdate.taker = salesRepLocationData.taker;
      }

      if (!quoteState.location) {
        // Setting default based on user location
        if (salesRepLocationData.locationId && salesRepLocationData.locationId !== -1) {
          quoteStateUpdate.location = joinIdNameObj({
            foreignId: String(salesRepLocationData.locationId),
            name: salesRepLocationData.locationName,
          });
        } else if (quoteState.company === joinIfIdNameObj(activeTenant.defaultData.quote.company)) {
          // If they don't have an associated location, then use the default provided
          // certain tenants will have their users as ADMIN, so they need a default location per tenant
          quoteStateUpdate.location = joinIfIdNameObj(activeTenant.defaultData.quote?.location);
        }
      }

      updateQuoteState(quoteStateUpdate, { defaultValue: true });
    }
  }, [
    quoteState.location,
    isAdmin,
    salesRepLocationData,
    quoteDataIsLoading,
    updateQuoteState,
    quoteState.taker,
    activeTenant.defaultData.quote?.location,
    quoteState.company,
    activeTenant.defaultData.quote.company,
  ]);

  // when line items change, load corresponding price info stats
  // this is a bit of a hack, since it's similar to usePromise interface
  // but since you can't have variable number of hooks, we need to store state in an object
  const [lineItemsInfoCache, setLineItemsInfoCache] = useState<LineItemsInfoCache>({});

  useEffect(() => {
    // helper function to convert itemId to invMastUid and then fetch price info

    // NOTE: currently no reason to load item info without a customer set
    // Same goes for companyId
    if (!customerId || !companyId || !salesLocationId || !shipToId) {
      return;
    }

    if (lineItems) {
      Promise.all(
        lineItems
          .map((item, index) => ({
            item,
            cacheKey: getLineItemCacheKey(item, index),
          }))
          .filter(({ item }) => !!item.foreignId)
          .map(async ({ item, cacheKey }) => {
            const infoParams = {
              companyId,
              customerId,
              salesLocationId,
              shipToId,
            };
            if (
              item.foreignId &&
              (lineItemsInfoCache[cacheKey] === undefined || !isEqual(lineItemsInfoCache[cacheKey].params, infoParams))
            ) {
              setLineItemsInfoCache(
                produce((infoCache) => {
                  infoCache[cacheKey] = { params: infoParams, isLoading: true };
                }),
              );
            }
            let algoliaItem: SearchIndexItemPartial | undefined;
            try {
              algoliaItem = await getSearchIndexItemByItemId(item.foreignId);
            } catch (err) {
              // TODO: some sort of error state for a nonexistant algolia result
              // Doing it this way avoids completely nonfunctional state
            }
            return {
              algoliaItem,
              cacheKey,
              item,
            };
          }),
      ).then(async (linesWAlgoliaInfo) => {
        const lineRequests: Array<LineItemRequest> = linesWAlgoliaInfo.map(({ algoliaItem, item }) => ({
          inv_mast_uid: algoliaItem?.inv_mast_uid,
          quantity: item.quantity || 1,
          ...(item.unitOfMeasure
            ? {
                unit_of_measure: item.unitOfMeasure,
              }
            : {}),
        }));

        if (lineRequests.length === 0) {
          return;
        }

        try {
          const [linesWPriceInfo, linesWAvailabilityInfo] = await Promise.all([
            getCachedItemPriceInfoMulti(companyId, customerId, salesLocationId, shipToId, lineRequests),
            Promise.all(lineRequests.map((req) => getCachedItemAvailability(req.inv_mast_uid!))),
          ]);

          setLineItemsInfoCache(
            produce((infoCache) => {
              linesWPriceInfo.forEach((priceInfo, index) => {
                const { algoliaItem, cacheKey } = linesWAlgoliaInfo[index];
                const availabilityInfo = linesWAvailabilityInfo[index];

                // TODO: Handle single line errors
                if (priceInfo.error) {
                  infoCache[cacheKey] = { ...infoCache[cacheKey], isLoading: false };
                } else {
                  if (isTenantGALarson(activeTenant.id) && priceInfo.current?.systemCalculatedPrices[0]?.price) {
                    // FIXME: Hardcoding in easiest place for right now
                    // POC for min/max pricing with GAL
                    const priceMin = priceInfo.current.systemCalculatedPrices[0].price.amount * 0.9;

                    const cost = priceInfo.cost?.movingAverageUnitCost?.amount || priceInfo.cost?.unitCost?.amount || 0;
                    const HARD_CODED_MIN_GM = 10;
                    const gmMin = calcPrice(HARD_CODED_MIN_GM, cost, priceInfo.current.priceDecimalPrecision);

                    const minAmount = gmMin > 0 ? Math.max(gmMin, priceMin) : priceMin;

                    priceInfo = {
                      ...priceInfo,
                      contract: {
                        messages: [],
                        name: '',
                        policies: [
                          {
                            minUnitPrice: {
                              unitOfMeasure: priceInfo.current.unitOfMeasure,
                              amount: minAmount,
                            },
                            unitOfMeasure: priceInfo.current.unitOfMeasure,
                          },
                        ],
                      },
                    };
                  }

                  infoCache[cacheKey] = {
                    params: infoCache[cacheKey].params,
                    isLoading: false,
                    algoliaItem,
                    priceInfo,
                    availabilityInfo,
                  };
                }
              });
            }),
          );
        } catch (err) {
          captureError(err);
          for (const { cacheKey } of linesWAlgoliaInfo) {
            setLineItemsInfoCache(
              produce((infoCache) => {
                infoCache[cacheKey] = { ...infoCache[cacheKey], isLoading: false };
              }),
            );
          }
        }

        // TODO: The API should also get a "multiline" endpoint, but for now we'll do a call for each item to get results
        // Min/max prices for Safeware
        if (isTenantSafeware(activeTenant.id)) {
          const contractsReq = [];
          for (const line of lineRequests) {
            contractsReq.push(
              getItemContract(line.inv_mast_uid, {
                companyId,
                customerId,
                salesLocationId,
                shipToId,
              }),
            );
          }

          const contracts = await Promise.all(contractsReq);
          setLineItemsInfoCache(
            produce((infoCache) => {
              contracts.forEach((contract, index) => {
                const { cacheKey } = linesWAlgoliaInfo[index];
                infoCache[cacheKey] = {
                  ...infoCache[cacheKey],
                  priceInfo: {
                    ...infoCache[cacheKey].priceInfo,
                    contract: contract.error ? null : contract.items,
                  },
                };
              });
            }),
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuoteState, customerId, salesLocationId, shipToId]);

  const getLineItemCacheKey = (line: QuoteLineItemP21, index: number) => `${line.foreignId || ''}|${index}`;
  const qtyAbove: Record<string, [priceIndex: number, qty: number]> = {};

  const lineItemsWithInfo: QuoteLineItemP21WithInfo[] = lineItems.map((item, index) => {
    const lineItemId = getLineItemCacheKey(item, index);
    const itemInfo = lineItemsInfoCache[lineItemId];
    const minMaxIsWarning = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersWarnMinMaxApproval);
    // NOTE: Right now when min/max is just warning the only customer that wants that doesn't want to render the exact number
    // And vice versa
    // It may make sense to mae them seperate feature flags in the future
    const showMinMaxValues = !minMaxIsWarning;
    const itemWithInfo: QuoteLineItemP21WithInfo = {
      ...item,
      priceInfo: itemInfo?.priceInfo && {
        // React state objects are read-only but going to edit a nested value
        ...itemInfo.priceInfo,
        current: itemInfo.priceInfo.current && {
          ...itemInfo.priceInfo.current,
          systemCalculatedPrices: itemInfo.priceInfo.current.systemCalculatedPrices.map((s) => ({ ...s })),
        },
      },
      algoliaItem: itemInfo?.algoliaItem,
      availabilityInfo: itemInfo?.availabilityInfo,
      isLoading: !!itemInfo?.isLoading,
      errors: {},
    };
    if (!itemWithInfo.isLoading && itemWithInfo.foreignId) {
      const qtyAllocatedForDisposition = shouldShowFeatureFlag(
        activeTenant,
        activeUser,
        TenantFeatureFlag.OrdersQtyAllocatedForDispositionDirectOrSpecial,
      );
      const duplicateLineItemQtyAllocated = getDuplicateLineItemsQty(
        lineItems,
        item,
        quoteState.location || '',
        qtyAllocatedForDisposition,
      );
      itemWithInfo.errors = {
        foreignId:
          validateName(itemWithInfo) ||
          (isTenantGALarson(activeTenant.id) && itemWithInfo.algoliaItem?.item_id === 'SUBTOTAL'
            ? 'Subtotal can only be used in P21.'
            : null),
        quantity: validateQuantity(
          itemWithInfo,
          activeTenant.erpType,
          splitIfIdNameStr(quoteState.location),
          duplicateLineItemQtyAllocated,
        )[0],
        // TODO: When approval flow is verified to work, allow users to send below minimum values to ERP (approval defaults to false)
        price: validatePrice(itemWithInfo, !minMaxIsWarning, showMinMaxValues),
      };

      const current = itemWithInfo?.priceInfo?.current;
      if (current?.systemCalculatedPrices && itemInfo.algoliaItem) {
        const { inv_mast_uid } = itemInfo.algoliaItem;

        let [priceIndex, qty] = qtyAbove[inv_mast_uid] ?? [0, 0];
        const systemCalculated = current.systemCalculatedPrices[priceIndex];
        if (systemCalculated?.qtyLimit) {
          if (qty >= systemCalculated.qtyLimit) {
            priceIndex += 1;
            qty = 0;
          } else {
            systemCalculated.qtyLimit -= qty;
          }
        }
        current.systemCalculatedPrices = current.systemCalculatedPrices.slice(priceIndex);
        qtyAbove[inv_mast_uid] = [priceIndex, qty + (item.quantity || 0)];
      }
    }
    return itemWithInfo;
  });

  const handleQuoteSaveDraft = async () => {
    if (!(await isCurrentStepValid(true))) {
      return;
    }

    const existingQuoteId = quoteId || quoteData?.id;
    const quoteRequest = quoteRequestFromHashState(
      quoteState,
      activeTenant,
      activeUser,
      defaultApproved(),
      quoteOrderFormConfig,
    );
    let updatedQuote: SalesQuoteDraft | undefined;

    try {
      if (existingQuoteId) {
        if (quoteData?.status === QuoteStatus.Draft) {
          updatedQuote = (
            await coreApiFetch(schemas.salesQuoteDrafts.putUpdateSalesQuoteDraft, {
              pathParams: { salesQuoteDraftId: existingQuoteId },
              bodyParams: { ...quoteRequest, status: QuoteStatus.Draft },
            })
          ).data;
          quoteSetData(updatedQuote);
        } else {
          notification.error({ message: 'Cannot convert an already-submitted quote to a draft.' });
          return;
        }
      } else {
        updatedQuote = (
          await coreApiFetch(schemas.salesQuoteDrafts.postSalesQuoteDraft, {
            bodyParams: { ...quoteRequest, status: QuoteStatus.Draft },
          })
        ).data;
        quoteSetData(updatedQuote);
      }

      clearSavedFormState();
      track(TrackEvent.Quotes_EditQuote_SaveDraft, {
        ...getLineItemTrackProps(lineItemsWithInfo, activeTenant),
        quoteId: updatedQuote?.id,
        step: QuoteEditStep[currentStep],
      });

      notification.success({
        message: 'Quote draft saved.',
        key: routes.orders.draftQuoteList(),
        description: <NotificationLink to={routes.orders.draftQuoteList()}>View Draft Quotes</NotificationLink>,
      });
    } catch (err) {
      captureAndShowError(err, 'Error while saving quote');
    }
  };

  const defaultApproved = useCallback(() => {
    if (shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersWarnMinMaxApproval)) {
      return lineItemsWithInfo.every((line) => !minMaxLimit(line));
    }
    return quoteOrderFormConfig.approved.defaultValue;
  }, [activeTenant, activeUser, lineItemsWithInfo, quoteOrderFormConfig.approved.defaultValue]);
  const defaultApproval = defaultApproved();

  const handleQuoteSubmit = async () => {
    if (!(await isCurrentStepValid())) {
      return;
    }

    const existingQuoteId = quoteId || quoteData?.id;
    const approved = defaultApproved();

    const quoteRequest = quoteRequestFromHashState(
      quoteState,
      activeTenant,
      activeUser,
      approved,
      quoteOrderFormConfig,
    );
    let updatedSalesQuoteId: string;

    const submitNotification = createSubmissionNotification({
      entityName: capitalize(orderType),
      erpType: activeTenant.erpType,
      expectedWaitSeconds: 60,
    });

    try {
      track(TrackEvent.Quotes_EditQuote_Submit, getLineItemTrackProps(lineItemsWithInfo, activeTenant));
      updatedSalesQuoteId = (
        await coreApiFetch(schemas.salesQuotes.postCreateSalesQuote, {
          bodyParams: {
            ...quoteRequest,
            salesQuoteDraftId: existingQuoteId,
            status: QuoteStatusType.Submitted,
            sendPdfEmailAfterSubmit: true,
          },
        })
      ).data.salesQuoteId!;

      submitNotification.success({
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.quoteDetails(updatedSalesQuoteId)}>
            View {entityName} #{updatedSalesQuoteId}
          </NotificationLink>
        ),
      });
      history.push(routes.orders.quoteDetails(updatedSalesQuoteId));
      clearSavedFormState();
    } catch (err) {
      submitNotification.error(err, {
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.quoteNewP21(quoteState)}>
            Return to {entityName}
          </NotificationLink>
        ),
      });
    }
  };

  const handleOrderSubmit = async () => {
    if (!(await isCurrentStepValid())) {
      return;
    }

    const approved = defaultApproved();
    // TODO: modal that quote will not be automatically approved if approved === false
    const orderRequest = orderRequestFromHashState(
      quoteState,
      activeTenant,
      activeUser,
      approved,
      quoteOrderFormConfig,
    );

    const submitNotification = createSubmissionNotification({
      entityName: capitalize(orderType),
      erpType: activeTenant.erpType,
      expectedWaitSeconds: 60,
    });
    try {
      track(TrackEvent.Orders_EditOrder_Submit, getLineItemTrackProps(lineItemsWithInfo, activeTenant));
      const createdOrder = (
        await coreApiFetch(schemas.orders.postCreate, {
          bodyParams: orderRequest,
        })
      ).data;

      const createdOrderId = createdOrder.foreignId!;
      submitNotification.success({
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.orderDetails(createdOrderId)}>
            View {entityName} #{createdOrderId}
          </NotificationLink>
        ),
      });
      history.push(routes.orders.orderDetails(createdOrderId));
      clearSavedFormState();
    } catch (err) {
      submitNotification.error(err, {
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.orderNewP21(quoteState)}>
            Return to {entityName}
          </NotificationLink>
        ),
      });
    }
  };

  const canGoToStep = useCallback(
    async (step: QuoteEditStep, draft?: boolean): Promise<boolean> => {
      if (step > QuoteEditStep.Header) {
        try {
          // antd Form makes you jumps through async try/catch hoop just to know whether a form is valid or not
          await headerForm.validateFields();
        } catch (err) {
          setValidationErrorMsg('Please ensure that all required header fields are filled.');
          return false;
        }
      }
      if (step > QuoteEditStep.LineItems) {
        if (lineItems.filter((item) => item.foreignId).length === 0) {
          setValidationErrorMsg('There must be at least one line item.');
          return false;
        }
        if (lineItemsWithInfo.some((lineItem) => lineItem.isLoading)) {
          setValidationErrorMsg('Lines must finish loading before proceeding');
          return false;
        }
        if (!draft && lineItemsWithInfo.some((lineItem) => Object.values(lineItem.errors).some((error) => error))) {
          setValidationErrorMsg('Please ensure that all line items are filled correctly.');
          return false;
        }
      }
      setValidationErrorMsg('');
      return true;
    },
    [headerForm, lineItems, lineItemsWithInfo],
  );

  const isCurrentStepValid = useCallback(
    async (draft?: boolean): Promise<boolean> => canGoToStep(currentStep + 1, draft),
    [canGoToStep, currentStep],
  );

  // Unified standard line item actions across all parts of the flow
  const lineItemActions = useMemo((): LineItemActionsP21 => {
    const lineChanges: Patch[] = [];

    const onLineItemsChange = (items: QuoteLineItemP21[], jobNumber?: string) => {
      // NOTE/FIXME: ideally we grab the items here, extract the jobNumber,
      // and feed in items selection to QuoteLineItems, but that's a non-trivial refactor
      const quoteStateUpdate: QuoteEditHashStateP21 = { items };
      if (jobNumber && !quoteState.jobNumber) {
        quoteStateUpdate.jobNumber = jobNumber;
      }
      updateQuoteState(quoteStateUpdate);
    };

    return {
      updateLineItem: (index: number, update: Partial<QuoteLineItemP21>, jobNumber?: string) => {
        if (!quoteState.items) {
          return;
        }

        const newLineItems = produce(
          applyPatches(quoteState.items, lineChanges),
          (lineItems) => {
            if (lineItems[index]?.foreignId && update.foreignId && typeof lineItems[index].price !== 'undefined') {
              delete lineItems[index].price;
            }
            lineItems[index] = { ...lineItems[index], ...update };
          },
          (patches) => lineChanges.push(...patches),
        );
        onLineItemsChange(newLineItems, jobNumber);
      },

      setLineItem: (index: number, update: QuoteLineItemP21) => {
        if (!quoteState.items) {
          return;
        }

        const newLineItems = produce(
          applyPatches(quoteState.items, lineChanges),
          (lineItems) => {
            lineItems[index] = update;
          },
          (patches) => lineChanges.push(...patches),
        );
        onLineItemsChange(newLineItems);
      },

      addEmptyLineItem: () => {
        lineItemActions.updateLineItem(lineItemsWithInfo.length, {});
      },

      addLineItem: (item: QuoteLineItemP21) => {
        if (!quoteState.items) {
          onLineItemsChange([item]);
        } else {
          produce(
            applyPatches(quoteState.items, lineChanges),
            // if the last item is blank item (created view add new line), then replace with new line
            // otherwise add to line items array
            (lineItems) => {
              if (lineItems.length > 0 && !lineItems[lineItems.length - 1].foreignId) {
                lineItemActions.updateLineItem(lineItemsWithInfo.length - 1, item);
              } else {
                lineItemActions.updateLineItem(lineItemsWithInfo.length, item);
              }
            },
            (patches) => lineChanges.push(...patches),
          );
        }
      },

      // TODO: add more logic about where to place the lines (eg. replace empty lines)
      addLineItems: (items: QuoteLineItemP21[]) => {
        if (!quoteState.items) {
          onLineItemsChange(items);
        } else {
          const newLineItems = produce(
            applyPatches(quoteState.items, lineChanges),
            (lineItems) => [...lineItems, ...items],
            (patches) => lineChanges.push(...patches),
          );
          onLineItemsChange(newLineItems);
        }
      },

      deleteLineItem: (index: number) => {
        onLineItemsChange(quoteState.items!.filter((_, idx) => idx !== index));
      },

      duplicateLineItem: (index: number) => {
        const newLineItems = quoteState.items ? [...quoteState.items] : [];
        const item = lineItemsWithInfo[index];
        const systemCalculated = item.priceInfo?.current?.systemCalculatedPrices[0];
        const systemCalculatedUnitPrice = systemCalculated?.price;
        const price =
          systemCalculatedUnitPrice && item.price && item.price === systemCalculatedUnitPrice.amount
            ? undefined
            : item.price;

        // insert duplicate item after its current index, but don't copy transferInfo
        newLineItems.splice(index + 1, 0, {
          ...newLineItems[index],
          transferCarrier: undefined,
          supplier: undefined,
          disposition: undefined,
          shipLocation: undefined,
          sourceLocation: undefined,
          price,
        });
        onLineItemsChange(newLineItems);
        onLineItemsChange(newLineItems);
      },

      moveLineItemUp: (index: number) => {
        const newLineItems = quoteState.items ? [...quoteState.items] : [];
        if (index > 0) {
          const lineItem = newLineItems[index];
          newLineItems[index] = newLineItems[index - 1];
          newLineItems[index - 1] = lineItem;
          onLineItemsChange(newLineItems);
        }
      },
      moveLineItemDown: (index: number) => {
        const newLineItems = quoteState.items ? [...quoteState.items] : [];
        if (index + 1 < newLineItems.length) {
          const lineItem = newLineItems[index];
          newLineItems[index] = newLineItems[index + 1];
          newLineItems[index + 1] = lineItem;
          onLineItemsChange(newLineItems);
        }
      },
    };
  }, [lineItemsWithInfo, quoteState.items, quoteState.jobNumber, updateQuoteState]);

  useEffect(() => {
    // Clear error message immediately when user has fixed the errors
    if (validationErrorMsg !== '') {
      isCurrentStepValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteState, validationErrorMsg]);

  // get available lots for line items that track lots
  useEffect(() => {
    for (const lineItem of quoteState.items || []) {
      if (
        lineItem.foreignId &&
        lineItem.lotsAssignable &&
        !lotInfoCache[getLotInfoCacheKey(quoteState, lineItem.foreignId)]
      ) {
        // We extend limit to 200 now to satisfy tenant's needs
        // TODO: apply true pagination
        const lotsPromise = coreApiFetch(schemas.items.getItemLots, {
          pathParams: { itemId: lineItem.foreignId },
          queryParams: { locationId: splitIfIdNameStr(quoteState.location)?.foreignId, limit: 200 },
        });
        lotInfoCache[getLotInfoCacheKey(quoteState, lineItem.foreignId)] = new StatefulPromise(
          lotsPromise.then((res) => ({ lotsAvailable: res.data.items })),
        );
        setLotsInfoCache({ ...lotInfoCache });
        lotsPromise.catch((err) => captureError(err)).finally(() => setLotsInfoCache({ ...lotInfoCache }));
      }
    }
  }, [quoteState, quoteState.location, lotInfoCache]);

  // fill in line item lots as single useEffect call instead of multiple useEffect calls spread between components
  useEffect(() => {
    const newLineItems: QuoteLineItemP21[] = [...(quoteState.items || [{} as QuoteLineItemP21])];
    if (newLineItems.length > 0) {
      for (let idx = 0; idx < newLineItems.length; ++idx) {
        const lineItem = newLineItems[idx];
        if (lineItem.foreignId) {
          const newLineItem = { ...lineItem };
          if (lineItem.quantity === undefined) {
            newLineItem.quantity = 1;
          }
          // lots
          const unitQtyHasChanged =
            prevLineItems.current[idx]?.quantity && prevLineItems.current[idx].quantity !== newLineItem.quantity;
          if (unitQtyHasChanged) {
            newLineItem.lots = undefined;
          }
          const lotsInfo = lotInfoCache[getLotInfoCacheKey(quoteState, lineItem.foreignId)];
          const lotsAvailable = lotsInfo?.value?.lotsAvailable;
          const shouldSetDefaultLots =
            orderType === OrderType.Order && lotsAvailable && newLineItem.quantity && newLineItem.lots === undefined;
          const defaultLotSelection = shouldSetDefaultLots
            ? getDefaultLotSelection(lotsAvailable, newLineItem.quantity!)
            : undefined;
          if (defaultLotSelection) {
            newLineItem.lots = defaultLotSelection;
          }
          // customer part id
          const customerPartIdInfo =
            customerPartIdInfoCache[getCustomerPartIdInfoCacheKey(quoteState, lineItem.foreignId)];
          const customerPartIdAvailable = customerPartIdInfo?.value?.customerPartIdAvailable;
          const shouldSetDefaultCustomerPartId =
            customerPartIdAvailable && customerPartIdAvailable.length > 0 && newLineItem.customerPartId === undefined;
          const defaultCustomerPartIdSelection = shouldSetDefaultCustomerPartId
            ? customerPartIdAvailable[0]
            : undefined;
          if (defaultCustomerPartIdSelection) {
            newLineItem.customerPartId = defaultCustomerPartIdSelection;
          }
          newLineItems[idx] = newLineItem;
        }
      }
    }
    // only update if new line items have changed
    if (JSON.stringify(newLineItems) !== JSON.stringify(quoteState.items) && quoteState.items !== undefined) {
      updateQuoteState({ items: newLineItems });
    }
  }, [quoteState, lotInfoCache, customerPartIdInfoCache, updateQuoteState, orderType]);

  // get available customer_part_id for line items that track customer_part_id
  useEffect(() => {
    for (const lineItem of quoteState.items || []) {
      if (
        lineItem.foreignId &&
        lineItem.customerPartIdAssignable &&
        !customerPartIdInfoCache[getCustomerPartIdInfoCacheKey(quoteState, lineItem.foreignId)]
      ) {
        const customerPartIdsPromise = coreApiFetch(schemas.items.getItemCustomerPartIds, {
          pathParams: { itemId: lineItem.foreignId },
          queryParams: { companyId: companyId || '', customerId: customerId || '' },
        });
        customerPartIdInfoCache[getCustomerPartIdInfoCacheKey(quoteState, lineItem.foreignId)] = new StatefulPromise(
          customerPartIdsPromise.then((res) => ({
            customerPartIdAvailable: res.data.items.map((item) => item.customerPartId),
          })),
        );
        setCustomerPartIdInfoCache({ ...customerPartIdInfoCache });
        customerPartIdsPromise
          .catch((err) => captureError(err))
          .finally(() => setCustomerPartIdInfoCache({ ...customerPartIdInfoCache }));
      }
    }
  }, [quoteState, customerPartIdInfoCache, companyId, customerId]);

  const handleTenantCustomizations = async (step: QuoteEditStep) => {
    const { customItems, messages, modals, quoteStateUpdate, summaryLines } = getTenantCustomizations({
      orderType,
      step,
      activeTenant,
      lineItemsWithInfo,
      quoteState,
    });

    customItems.forEach((item) => {
      const index = lineItemsWithInfo.findIndex((line) => line.foreignId === item.foreignId);
      if (index === -1) {
        lineItemActions.addLineItem(item);
      } else {
        lineItemActions.updateLineItem(index, item);
      }
    });

    messages.forEach((text) => message.warning(text));

    modals.forEach((modal) => showAsyncModal(customModals[modal].modal, customModals[modal].props));

    setAdditionalSummaryLines(summaryLines);

    updateQuoteState(quoteStateUpdate);
  };

  const goToStep = async (step: QuoteEditStep) => {
    // don't go to next step, until every previous state is valid
    if (step >= currentStep && !(await canGoToStep(step))) {
      return;
    }
    updateQuoteState({ items: lineItems.filter((item) => item.foreignId), step });

    handleTenantCustomizations(step);

    scrollToTop();
  };

  // track previous state to compare if unit quantity changed (use case: default lot selection)
  useEffect(() => {
    prevLineItems.current = lineItemsWithInfo;
  }, [quoteState, lineItemsWithInfo]);

  if (quoteDataIsLoading) {
    return <CenteredLoader />;
  }

  return (
    <Container>
      <PageHeader
        title={
          <Styled.VerticalCenter>
            <div>
              {quoteId ? 'Edit' : 'New'} {capitalize(orderType)}
            </div>
            {quoteData?.status === QuoteStatus.Draft && (
              <StatusBadge
                status={BadgeStatus.Draft}
                className={css`
                  margin-left: 8px;
                  display: flex;
                  align-items: center;
                `}
              />
            )}
          </Styled.VerticalCenter>
        }
        headerActions={
          <Button
            icon={<SwapOutlined />}
            onClick={() => {
              history.push(
                orderType === OrderType.Quote
                  ? routes.orders.orderNewP21(convertQuoteHashStateToOrder(quoteState))
                  : routes.orders.quoteNewP21(convertOrderHashStateToQuote(quoteState)),
              );
            }}
          >
            Edit as {orderType === OrderType.Quote ? `Order` : `Quote`}
          </Button>
        }
      />
      <Form.Provider>
        <AutoSaveModal
          visible={showAutoSaveModal}
          onNo={() => {
            clearSavedFormState();
            setShowAutoSaveModal(false);
          }}
          onYes={() => {
            history.replace(encodeHashState({ ...savedFormState, step: 0 }));
            setShowAutoSaveModal(false);
            clearSavedFormState();
          }}
        />
        <Steps current={currentStep} style={{ marginTop: 24 }} onChange={(newStep) => goToStep(newStep)}>
          {['Header', 'Line Items', 'Review'].map((step) => (
            <Steps.Step key={step} title={step} />
          ))}
        </Steps>
        {validationErrorMsg && (
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 16px;
            `}
          >
            <Alert message={validationErrorMsg} banner type="error" />
          </div>
        )}
        {currentStep === QuoteEditStep.Header && (
          <Styled.Content>
            <QuoteHeader
              form={headerForm}
              quoteState={quoteState}
              orderType={orderType}
              onQuoteStateChange={updateQuoteState}
            />
          </Styled.Content>
        )}
        {currentStep === QuoteEditStep.LineItems && (
          <QuoteLineItems
            quoteState={quoteState}
            lineItemActions={lineItemActions}
            lineItemsWithInfo={lineItemsWithInfo}
            orderType={orderType}
            additionalSummaryLines={additionalSummaryLines}
          />
        )}
        {currentStep === QuoteEditStep.Review && (
          <>
            <QuoteReview
              defaultApproval={defaultApproval}
              quoteState={quoteState}
              orderType={orderType}
              pricePrecision={lineItemsWithInfo[0]?.priceInfo?.current?.priceDecimalPrecision || 2}
              lineItemsWithInfo={lineItemsWithInfo}
              additionalSummaryLines={additionalSummaryLines}
            />
          </>
        )}
        <FixedFooter>
          <TrialBanner />
          <Space>
            <Button onClick={() => goToStep(currentStep - 1)} disabled={currentStep === 0}>
              <LeftOutlined />
              Previous
            </Button>
            {orderType === OrderType.Quote && (
              <Tooltip
                title={`This will save the quote in Recurrency, but not export to ${getErpName(activeTenant.erpType)}.`}
              >
                <AsyncButton onClick={handleQuoteSaveDraft}>Save Draft</AsyncButton>
              </Tooltip>
            )}
            {currentStep === QuoteEditStep.Review ? (
              <Tooltip
                title={`This ${orderType} will be sent to ${getErpName(
                  activeTenant.erpType,
                )} and will no longer be editable in Recurrency.`}
              >
                <AsyncButton
                  type="primary"
                  onClick={orderType === OrderType.Quote ? handleQuoteSubmit : handleOrderSubmit}
                  // If somehow a user is able to get into the edit quote page when it's disabled, block them from sending to ERP
                  disabled={
                    orderType === OrderType.Quote
                      ? !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) ||
                        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuoteDraftOnly)
                      : !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) ||
                        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrderViewOnly)
                  }
                >
                  Send to {getErpName(activeTenant.erpType)}
                </AsyncButton>
              </Tooltip>
            ) : (
              <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
                Next
                <RightOutlined />
              </Button>
            )}
          </Space>
        </FixedFooter>
      </Form.Provider>
    </Container>
  );
};

function getDefaultLotSelection(lotsAvailable: ItemLotDTO[], lineItemQuantity: number): LotSelection {
  // use top-down approach to select lots; the top-down approach relies on the lotsAvailable ordering
  // e.g. if FIFO is desired, this util assumes that lotsAvailable is already sorted by lot creation date
  let totalRunningQuantitySelected = 0;
  const lotsSelected: LotSelection = [];

  for (const lot of lotsAvailable) {
    if (totalRunningQuantitySelected >= lineItemQuantity) break;
    const quantityToAdd = Math.min(lot.quantityAvailable, lineItemQuantity - totalRunningQuantitySelected);
    lotsSelected.push({
      lotId: lot.lotId,
      quantity: quantityToAdd,
      lotCostPerUOM: lot.unitCost,
    });
    totalRunningQuantitySelected += quantityToAdd;
  }

  return lotsSelected;
}
