export function calculatePercentDifference(current?: number, previous?: number) {
  // If both are 0 we view the difference as 0
  if (current === 0 && previous === 0) {
    return 0;
  }

  // If either values are null or 0, we just return undefined
  if (!current || !previous) {
    return undefined;
  }

  return (current - previous) / previous;
}
