import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { Container } from 'components/Container';
import { CenteredError } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { UsersTable } from 'components/recipes/UsersTable';

import { useCoreApi } from 'hooks/useApi';

// Ideally we should have better pagination and true search/filter instead of frontend search and pagination
const MAX_RECORDS = 100000;

export const UserListPage = () => {
  const {
    data: userData,
    error,
    isLoading,
  } = useCoreApi(schemas.users.getUsers, {
    queryParams: { limit: MAX_RECORDS },
  });

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Container>
      <PageHeader title="Users" />
      <UsersTable users={userData?.items ?? []} isLoading={isLoading} />
    </Container>
  );
};
