import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import { DEFAULT_PAGE_SIZE } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { optArrFromVal } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { formatDateShorthand, formatUSD } from 'utils/formatting';

import { SalesInvoiceLinesTable } from '../SalesInvoiceLinesTable';
import { QtyByMonthSparkline } from './QtyByMonthSparkline';
import { ViewAllModal } from './ViewAllModal';

export function SalesInvoiceLinesPanelContent({
  itemId,
  companyId,
  customerId,
  locationIds,
}: {
  itemId: string;
  companyId?: string;
  customerId?: string;
  locationIds?: string[];
}) {
  const { data, isLoading, error } = useCoreApi(schemas.salesInvoiceLines.getSalesInvoiceLinesV2, {
    queryParams: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      filter: { itemId, companyIds: optArrFromVal(companyId), customerId, locationIds },
    },
  });

  return (
    <>
      <QtyByMonthSparkline
        data={data?.aggs}
        dateField="invoiceDate"
        quantityField="quantityShipped"
        seriesTitle="Qty Shipped"
      />
      <MiniTable
        numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
        data={data?.items}
        columns={[
          { render: (record) => formatDateShorthand(record.invoiceDate), width: '90px' },
          { render: () => ``, width: '100px' }, // dummy row for alignment with order lines history panel
          { render: (record) => formatUSD(record.unitPrice, true), align: `right` },
          { render: (record) => `${record.quantityShipped} ${record.unitOfMeasure || ``}`, align: `right` },
        ]}
        isLoading={isLoading}
        error={error}
        onMoreButtonClick={() => {
          showAsyncModal(ViewAllModal, {
            title: `Invoice Lines of Item #${itemId}${customerId ? ` to Customer #${customerId}` : ''}`,
            content: (
              <SalesInvoiceLinesTable
                itemId={itemId}
                companyId={companyId}
                customerId={customerId}
                locationIds={locationIds}
              />
            ),
          });
        }}
      />
    </>
  );
}
