import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { SalesQuoteLineDTO } from '@recurrency/core-api-schema/dist/salesQuoteLines/getSalesQuoteLines';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { QuoteAssemblyLinesTable } from 'components/recipes/QuoteAssemblyLinesTable';
import { Typography } from 'components/Typography';

export function QuoteAssemblyLinesModal({
  itemId,
  quoteLineUid,
  quoteLines,
  onClose,
}: {
  itemId: string;
  quoteLineUid: string;
  quoteLines: SalesQuoteLineDTO[];
  onClose: () => void;
}) {
  // Use state to store an array of objects, each containing the previous values of the current itemID and currentQuoteLineUid
  const [previousItemIdAndQuoteLineUid, setPreviousItemIdAndQuoteLineUid] = useState<
    { itemId: string; quoteLineUid: string }[]
  >([]);
  const [currentItemId, setCurrentItemId] = useState(itemId);
  const [currentQuoteLineUid, setCurrentQuoteLineUid] = useState(quoteLineUid);

  const handleClick = (itemId: string, quoteLineUid: string) => {
    setPreviousItemIdAndQuoteLineUid([
      ...previousItemIdAndQuoteLineUid,
      { itemId: currentItemId, quoteLineUid: currentQuoteLineUid },
    ]);
    setCurrentItemId(itemId);
    setCurrentQuoteLineUid(quoteLineUid);
  };

  const handleBackClick = () => {
    const itemIdAndQuoteLineUid = previousItemIdAndQuoteLineUid.pop();
    setCurrentItemId(itemIdAndQuoteLineUid?.itemId || '0');
    setCurrentQuoteLineUid(itemIdAndQuoteLineUid?.quoteLineUid || '0');
  };

  return (
    <Modal
      destroyOnClose
      visible
      title={
        <>
          <Button
            size="small"
            type="link"
            onClick={currentItemId !== itemId && currentQuoteLineUid ? handleBackClick : onClose}
            style={{ display: 'inline-block' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <Typography style={{ display: 'inline-block', color: theme.colors.neutral[700] }}>ASSEMBLY: #</Typography>
          <Typography copyable style={{ display: 'inline-block', color: theme.colors.neutral[700] }}>
            {currentItemId}
          </Typography>
        </>
      }
      width={1200}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="small">
          Close
        </Button>
      }
    >
      <QuoteAssemblyLinesTable quoteLines={quoteLines} quoteLineUid={currentQuoteLineUid} onClick={handleClick} />
    </Modal>
  );
}
