import React, { ReactNode } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Popover } from 'antd';
import { theme } from 'theme';

export const EquationPopover = ({
  children,
  title,
  visible,
  onVisibleChange,
}: {
  children: ReactNode;
  title: ReactNode;
  visible?: boolean;
  onVisibleChange?: (isVisible: boolean) => void;
}) => (
  <Popover
    title={title}
    trigger="hover"
    color={theme.colors.neutral[75]}
    overlayInnerStyle={{ borderRadius: '8px' }}
    placement="bottom"
    content={children}
    arrowPointAtCenter
    visible={visible}
    onVisibleChange={onVisibleChange}
  >
    <span
      className={css`
        margin-left: 8px;
      `}
    >
      <QuestionCircleOutlined />
    </span>
  </Popover>
);
