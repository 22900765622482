import React from 'react';

import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { SelectProps } from 'antd/lib/select';

import { AsyncSelect } from 'components/AsyncSelect';
import { useSearchIndexSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { SelectOption } from 'components/Select';

import { addArticle } from 'utils/formatting';

interface AlgoliaSelectProps<HitT> extends SelectProps<any> {
  label: string;
  name: string;
  rules: FormItemProps['rules'];
  indexName: SearchIndexName;
  mapHitFn: (hit: HitT) => SelectOption;
  placeholder?: string;
  salesRepRefined?: boolean;
  postValueChange?: (value: SelectOption['value'], options: any) => void;
  initialValue?: string;
  setFieldsValue?: (value: any) => void;
}

export function AlgoliaSelectFormItem<HitT>({
  label,
  name,
  rules,
  indexName,
  mapHitFn,
  placeholder,
  salesRepRefined = false,
  postValueChange,
  initialValue,
  ...otherProps
}: AlgoliaSelectProps<HitT>) {
  const selectProps = useSearchIndexSelectProps<HitT>({ indexName, mapHitFn, salesRepRefined });

  return (
    <Form.Item label={label} name={name} rules={rules}>
      <AsyncSelect
        selectProps={selectProps}
        entityPlural={label}
        onSelect={(value: string, option: FIXME) => {
          postValueChange?.(value, option);
        }}
        placeholder={placeholder || `Search for ${addArticle(label)}`}
        defaultValue={initialValue}
        {...otherProps}
      />
    </Form.Item>
  );
}
