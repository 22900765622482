import { TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantProductDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';

export const productDependencies: Partial<Record<TenantProductName, TenantProductName[]>> = {
  [TenantProductName.GlobalSearch]: [TenantProductName.DfpaSearchAndLookup, TenantProductName.SalesSearchAndLookup],
  [TenantProductName.DfpaPurchasingAutomation]: [TenantProductName.DfpaDemandPlanning],
};

export enum ProductsPreset {
  FreeReporting = 'Free Reporting',
  FreeReportingActiveUpsell = 'Free Reporting - Active Upsell',
  FullDPPATrialActiveUpsell = 'Full DPPA Trial - Active Upsell',
  FullProductDPPAOnly = 'Full DPPA Product - Paid/MOAT',
  FullProductDPOnly = 'Full DP Product - Paid/MOAT',
}

export const PRODUCT_FULLY_DISABLED: TenantProductDTO = {
  purchased: false,
  productEnabled: false,
  backendEnabled: false,
};

const PRODUCT_FULLY_ENABLED: TenantProductDTO = {
  purchased: true,
  productEnabled: true,
  backendEnabled: true,
};

// Used for trial tenants
const PRODUCT_ENABLED_NOT_PURCHASED: TenantProductDTO = {
  purchased: false,
  productEnabled: true,
  backendEnabled: true,
};

// Used for trial tenants so data is available internally to demo but not to end users
const PRODUCT_BACKEND_ENABLED: TenantProductDTO = {
  purchased: false,
  productEnabled: false,
  backendEnabled: true,
};

export const productsPresetTemplates: Record<ProductsPreset, Partial<Record<TenantProductName, TenantProductDTO>>> = {
  [ProductsPreset.FreeReporting]: {
    [TenantProductName.DfpaMissionControl]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.SalesReporting]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.SalesMissionControl]: PRODUCT_ENABLED_NOT_PURCHASED,
  },
  [ProductsPreset.FreeReportingActiveUpsell]: {
    [TenantProductName.EnterpriseBasic]: PRODUCT_BACKEND_ENABLED,
    [TenantProductName.DfpaMissionControl]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.DfpaSearchAndLookup]: PRODUCT_BACKEND_ENABLED,
    [TenantProductName.DfpaDemandPlanning]: PRODUCT_BACKEND_ENABLED,
    [TenantProductName.DfpaPurchasingAutomation]: PRODUCT_BACKEND_ENABLED,
    [TenantProductName.SalesReporting]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.SalesMissionControl]: PRODUCT_ENABLED_NOT_PURCHASED,
  },
  [ProductsPreset.FullDPPATrialActiveUpsell]: {
    [TenantProductName.EnterpriseBasic]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.GlobalSearch]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.DfpaMissionControl]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.DfpaSearchAndLookup]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.DfpaDemandPlanning]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.DfpaPurchasingAutomation]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.SalesReporting]: PRODUCT_ENABLED_NOT_PURCHASED,
    [TenantProductName.SalesMissionControl]: PRODUCT_ENABLED_NOT_PURCHASED,
  },
  [ProductsPreset.FullProductDPPAOnly]: {
    [TenantProductName.EnterpriseBasic]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.GlobalSearch]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaMissionControl]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaSearchAndLookup]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaDemandPlanning]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaPurchasingAutomation]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesReporting]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesMissionControl]: PRODUCT_FULLY_ENABLED,
  },
  [ProductsPreset.FullProductDPOnly]: {
    [TenantProductName.EnterpriseBasic]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.GlobalSearch]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaMissionControl]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaSearchAndLookup]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.DfpaDemandPlanning]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesReporting]: PRODUCT_FULLY_ENABLED,
    [TenantProductName.SalesMissionControl]: PRODUCT_FULLY_ENABLED,
  },
};
