import { AsyncJobStatus } from '@recurrency/core-api-schema/dist/asyncJobs/common';
import {
  CustomerType,
  PurchaseTargetLineStatus,
  PurchaseTargetStatus,
  SortDirection,
  SpecialOrderFilterOptions,
  SupplierTargetTypes,
  TargetGroupType,
  TaskFilter,
  TaskStatus,
} from '@recurrency/core-api-schema/dist/common/enums';
import {
  InventoryDashboardGroupBy,
  InventoryDashboardTimeFilters,
} from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import { MetadataKey } from '@recurrency/core-api-schema/dist/payments/getPaymentIntents';
import { PurchaseGroupWithTargetDTO } from '@recurrency/core-api-schema/dist/purchaseGroups/getPurchaseGroups';
import { AllocatedOrderLinePayload } from '@recurrency/core-api-schema/dist/purchaseOrders/createPurchaseOrder';
import {
  GetSalesHistoryReportSortByField,
  GetSalesInvoiceLinesReportGroupBy,
} from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { TransferTargetLineDTO, TransferTargetDTO } from '@recurrency/core-api-schema/dist/transferOrders/common';

import { Disposition } from 'pages/orders/quotes/types';
import { DocSummaryType, TransferSummaryLine } from 'pages/purchasing/PurchaseTargetsPage/types';

import { FilterByField, ReportingUnitType } from 'components/recipes/salesReport/salesReportUtils';

import { IdNameObj } from './legacy-api';

export interface NavTabHashState {
  tab?: string;
}

export interface CompanyIdHashState {
  companyId?: string;
}

export type DetailsPageWithCompanyIdHashState = NavTabHashState & CompanyIdHashState;

export interface PricingHashState {
  customer?: string;
  company?: string;
  item?: string;
  itemInvMastUid?: string;
  tab?: string;
}

export enum QuoteEditStep {
  Header,
  LineItems,
  Review,
}

export interface OrderLineTransferInfo {
  toLocation?: IdNameObj;
  fromLocation?: IdNameObj;
  carrier?: IdNameObj;
}

export interface LineNote {
  topic: string;
  note: string;
  areas: string[];
  mandatory?: boolean;
}

export type LotSelection = Array<{
  lotId: string;
  quantity: number;
  lotCostPerUOM?: number;
}>;

export interface QuoteLineItemP21 {
  foreignId: string;
  name: string;
  unitOfMeasure?: string;
  price?: number;
  quantity?: number;
  description?: string;
  extendedDescription?: { originalDescription: string; extendedText: string };
  shipLocation?: IdNameObj;
  sourceLocation?: IdNameObj;
  manualPriceOverride?: boolean;
  /* NOTE: transfer info is only valid for orders, but hash shate is shared between orders and quotes */
  transferCarrier?: IdNameObj;
  supplier?: IdNameObj;
  disposition?: Disposition;
  notes?: LineNote[];
  lotsAssignable?: boolean;
  lots?: LotSelection;
  poCost?: number;
  customerPartIdAssignable?: boolean;
  customerPartId?: string;
  commissionCost?: number;
  requiredDate?: ISODateStr;
}

export type ForeignIdNameStr = string;
export type ISODateStr = string;

export interface QuoteEditHashStateP21 {
  // NOTE: ONLY PUT FIELDS THAT ARE FILLED IN BY USER, NOT API DERIVED STATE
  company?: ForeignIdNameStr;
  customer?: ForeignIdNameStr;
  shipTo?: ForeignIdNameStr;
  orderTypePriority?: string;
  packingBasis?: string;
  deliveryInstructions?: string;
  shipToEmailAddress?: string;
  contact?: ForeignIdNameStr;
  location?: ForeignIdNameStr;
  sourceLocation?: ForeignIdNameStr;
  freightType?: ForeignIdNameStr;
  freightCharge?: ForeignIdNameStr;
  carrier?: ForeignIdNameStr;
  shippingRouteId?: string;
  requiredDate?: ISODateStr;
  validUntilDate?: ISODateStr;
  poNo?: string;
  headerNote?: string;
  step?: QuoteEditStep;
  taker?: string;
  items?: QuoteLineItemP21[];
  jobNumber?: string;
  customerJobNo?: string;
  willCall?: boolean;
  frontCounter?: boolean;
  priceOverrideReason?: string;
  priceOverrideComments?: string;
  class1?: ForeignIdNameStr;
  description?: string;
  qteForOrder?: string;
  // NOTE: HACKY TECH DEBT! DON'T ADD MORE FIELDS TO THIS LIST!!!!!
  // Works for new quotes and orders, but won't work for Q/O created from other parts of app e.g. Copy as Q/O, Opportunities e.t.c
  // this is a /customer/:customerId/restrictions api derived field used for GALarson's CO tax custom logic
  // TODO: state needs to be lifted out into a quoteContext object that can hydrate this info when customerId changes
  stateSalesTaxExemptionNumber?: string;
  // this an algolia derived field used for GALarson's CO tax custom logic
  // TODO: state needs to be lifted out into a quoteContext object when shipTo changes.
  shipToState?: string;
  terms?: string;
}

export type OrderEditHashStateP21 = QuoteEditHashStateP21;

export interface OrderEditHashStateSAPB1 {
  step?: QuoteEditStep;
  customer?: ForeignIdNameStr;
  customerPORef?: string;
  contact?: ForeignIdNameStr;
  shipTo?: ForeignIdNameStr;
  location?: ForeignIdNameStr;
  carrier?: ForeignIdNameStr;
  shippingRouteId?: string;
  customerNote?: string;
  dueDate?: string;
  items?: OrderLineItemSAPB1[];
  ocrCode?: string;
  ocrCode2?: string;
}

export interface OrderLineItemSAPB1 {
  foreignId: string;
  name: string;
  quantity?: number;
  // selling uom
  unitOfMeasure?: string;
  unitOfMeasureId?: string;
  unitPrice?: number;
  /* aka selling unit cost */
  unitCost?: number;
  /**
   * Defined if lots are selected.
   * If lotsAverageUnitCost is finite, then GM% and suggested price use lotsAverageUnitCost as unitCost.
   * note: lotsAvgUnitCost is adjusted to selling uom unitSize.
   */
  lotsAvgUnitCost?: number;
  priceSource?: string;
  // pricing uom
  pricingUnitOfMeasureId?: string;
  lotsSelected?: LotSelection;
}

// SAPB1 quote and order share same document model
export type QuoteEditHashStateSAPB1 = OrderEditHashStateSAPB1;

// order entry hash states with P21/SAPB1 shared types and naming
export type ErpAgnosticOrderHashState = OrderEditHashStateP21 | OrderEditHashStateSAPB1;
export type ErpAgnosticLineItem = QuoteLineItemP21 | OrderLineItemSAPB1;

export enum PurchaseOrderEditStep {
  Header,
  LineItems,
}

export interface PurchaseOrderEditHashState {
  step?: PurchaseOrderEditStep;
  company?: IdNameObj;
  vendor?: IdNameObj;
  supplier?: IdNameObj;
  location?: IdNameObj;
  carrier?: IdNameObj;
  buyer?: IdNameObj;
  dueDate?: ISODateStr;
  approved?: boolean;
  lineItems?: PurchaseOrderLineItem[];
}

export interface PurchaseOrderLineItem {
  item?: IdNameObj;
  unitQuantity?: number;
  unitOfMeasure?: string;
  unitOfMeasureId?: string;
  unitPrice?: number;
  originalUnitPrice?: number;
}

export interface DraftQuotesListHashState {
  page?: number;
}

export type SearchFrameSortByOrder = 'asc' | 'desc';
export interface SearchFrameHashState {
  /** query string from search bar */
  query?: string;
  /** current page number */
  page?: number;
  /** other filters */
  extFilter?: Obj<string[]>;
  /** value filters */
  where?: Obj<string[]>;
  /** numeric filters */
  whereNum?: Obj<{ min: number; max: number }>;
  sortBy?: { field: string; order: SearchFrameSortByOrder };
  secondarySortBy?: { field: string; order: SearchFrameSortByOrder };
}

export interface TaskNewHashState {
  title?: string;
}

export interface ProspectHashState extends Obj {
  // type: Customer or Prospect
  type?: CustomerType;
  // Step of the flow
  step?: ProspectStep;
  // Associated company
  company?: string;
  // Customer Step
  salesRep?: string;
  assignedSalesRep?: string;
  customerName?: string;
  customerPhone?: string;
  customerFax?: string;
  customerEmail?: string;
  'physical-address1'?: string;
  'physical-address2'?: string;
  'physical-state'?: string;
  'physical-city'?: string;
  'physical-zipcode'?: string;
  addressesSame?: boolean;
  'mailing-address1'?: string;
  'mailing-address2'?: string;
  'mailing-state'?: string;
  'mailing-city'?: string;
  'mailing-zipcode'?: string;
  // Shipto
  makeShipTo?: boolean;
  shipToDefaultBranch?: string;
  shipToPreferredLocation?: string;
  shipToDefaultFreight?: string;
  // CustomerIvaTax
  companyPerson?: string;
  ivaExemptionNumber?: string;
  taxRegime?: string;
  // Contact Step
  contactFirstName?: string;
  contactLastName?: string;
  contactTitle?: string;
  contactPhone?: string;
  contactFax?: string;
  contactEmail?: string;
}

export enum ProspectStep {
  Customer,
  Contact,
  Review,
}

export interface ReportingHashState {
  query?: string;
  filter?: {
    // @TODO change to use proper value. Currently this is saying that {itemId: "123: itemName"}
    [k in FilterByField]?: ForeignIdNameStr[];
  };
  groupBy?: GetSalesInvoiceLinesReportGroupBy;
  sortBy?: GetSalesHistoryReportSortByField;
  sortDir?: SortDirection;
  unitType?: ReportingUnitType;
  page?: number;
  limit?: number;
  numPrevYearsForSalesMonthly?: number;
  yearToDate?: boolean;
}

export const ALL_FILTER = 'all';
export interface TaskListHashState {
  filter: TaskFilter | typeof ALL_FILTER;
  status: TaskStatus;
  assigneeUserIdSelected: string | undefined;
  searchQuery: string | undefined;
}

export interface PurchaseTargetsHashState {
  targetGroupType?: TargetGroupType;
  targetTypes?: SupplierTargetTypes[];
  supplierIds?: string[];
  locationIds?: string[];
  buyerIds?: string[];
  status?: PurchaseTargetStatus;
  selectedSupplierLocations?: SupplierLocationWTarget[];
  specialOrder?: SpecialOrderFilterOptions[];
}

export interface TransferTargetsHashState {
  sourceLocationIds?: string[];
  destinationLocationIds?: string[];
  status?: PurchaseTargetStatus;
  selectedTransferTargets?: TransferTargetDTO[];
}

export interface TransferOrdersHashState {
  status?: string;
  sourceLocationIds?: string[];
  destinationLocationIds?: string[];
  searchQuery?: string;
}

export enum PurchasingStep {
  AllLines,
  PurchaseOrders,
  Finalize,
}

export enum TransferStep {
  AllLines,
  Finalize,
}

export enum BulkForecastOverridesStep {
  Upload,
  Review,
}

export interface SupplierLocationWTarget {
  supplierId: string;
  supplierName: string;
  // aka requirementLocationId / requirementLocationName
  locationId: string;
  locationName: string;
  purchasingLocationId?: string;
  purchasingLocationName?: string;
  vendorId: string;
  vendorName: string;
  targetType: SupplierTargetTypes;
  targetValue: number;
  groupName?: string;
  groupId?: string;
}

export interface PurchasingLineTransfer {
  transferFromLocationId: string;
  transferFromLocationName: string;
  qtyToTransfer: number;
}

export interface PurchasingLineInput {
  qtyToOrder?: number;
  unitCost?: number;
  transfers?: PurchasingLineTransfer[];
  requiredDate?: ISODateStr;
  qtyForSpecialOrders?: number;
  specialOrderLinks?: AllocatedOrderLinePayload[];
}

export interface PurchasingTOFinalizeInput {
  carrier?: IdNameObj;
  approved?: boolean;
}

export interface PurchasingPOFinalizeInput extends PurchasingTOFinalizeInput {
  buyer?: IdNameObj;
  dueDate?: ISODateStr;
  carrierClearedByUser?: boolean;
}

export type PurchaseGroupHashState = Omit<
  PurchaseGroupWithTargetDTO,
  | 'supplierId'
  | 'supplierName'
  | 'vendorId'
  | 'vendorName'
  | 'status'
  | 'createdByUserId'
  | 'lastUpdatedByUserId'
  | 'createdAt'
  | 'updatedAt'
  | 'orderCost'
  | 'numLines'
  | 'currentValue'
  | 'purchaseLocationTargetValue'
  | 'purchaseLocationTargetType'
  | 'goalPercent'
  | 'reviewCycleDays'
  | 'hasSpecialOrder'
>;

export interface PurchaseTargetLinesHashState {
  supplierLocations: SupplierLocationWTarget[];
  purchasingLinesById?: Obj<PurchasingLineInput>;
  statuses?: PurchaseTargetLineStatus[];
  /** PO header fields such as buyer, carrier, approved by `${supplierId}|{purchasingLocationId}` */
  poFinalizeInputByKey?: Obj<PurchasingPOFinalizeInput>;
  toFinalizeInputByKey?: Obj<PurchasingTOFinalizeInput>;
  query?: string;
  /** @deprecated - TODO @thomas: remove this prop after Feb 13 2024 */
  company?: IdNameObj;
  companyIds?: string[];
  step?: PurchasingStep;
  groupBuy?: boolean;
  groupIds?: string[];
  purchaseGroups?: PurchaseGroupHashState[];
}

export interface TransferTargetLinesHashState {
  step?: TransferStep;
  statuses?: PurchaseTargetLineStatus[];
  selectedTransferTargets?: TransferTargetDTO[];
  transferLineUpdates?: TransferLineUpdates[];
  searchQuery?: string;
  transferInputByID?: Obj<TransferInput>;
}

export interface PaymentsPayoutsHashState {
  startDate?: ISODateStr;
  endDate?: ISODateStr;
}

export interface PaymentsTransactionsHashState {
  createDate: ISODateStr;
  amount: number;
  startDate?: ISODateStr;
  endDate?: ISODateStr;
  payoutId?: string;
}
export interface PaymentListHashState {
  startDate?: ISODateStr;
  endDate?: ISODateStr;
  searchQuery?: string;
  searchKey?: MetadataKey;
}

export interface InventoryDashboardHashStateFilters {
  timeSpan?: InventoryDashboardTimeFilters;
  companyIds?: string[];
  locationIds?: string[];
  itemIds?: string[];
  itemGroupIds?: string[];
  supplierIds?: string[];
  buyerIds?: string[];
  stockable?: string[];
  purchaseClasses?: string[];
  status?: string[];
  demandPattern?: string[];
  demandPredictability?: string[];
}

export type DisplayUnit = 'value' | 'volume';
export interface InventoryDashboardHashState {
  filters?: InventoryDashboardHashStateFilters;
  displayUnit: DisplayUnit;
  useRecommendedMax?: boolean;
}

export enum InventoryExplorerCompareFromOptions {
  LastMonth = 'lastMonth',
  LastThreeMonths = 'lastThreeMonths',
  LastSixMonths = 'lastSixMonths',
}

export enum InventoryExplorerCompareToOptions {
  PriorMonth = 'priorMonth',
  PriorThreeMonths = 'priorThreeMonths',
  PriorSixMonths = 'priorSixMonths',
  PriorYear = 'priorYear',
}

export interface InventoryDashboardDetailsHashState extends InventoryDashboardHashState {
  chartSource: string;
  groupBy?: InventoryDashboardGroupBy;
  compareFrom: InventoryExplorerCompareFromOptions;
  compareTo: InventoryExplorerCompareToOptions;
  sortBy?: [string, SortDirection][];
  columnGroups?: string[];
  changeValue: 'percent' | 'value';
  page: number;
}

export interface InventoryDashboardStatusHashState extends InventoryDashboardHashState {
  sortBy?: [string, SortDirection][];
  page: number;
  zoomEnabled?: boolean;
  columns?: string[];
}

export interface UserManagementHashState {
  /** query string from search bar */
  query?: string;
}

export interface SettingsPageHashState {
  setting?: string;
}

export interface ExportJobsPageHashState {
  status?: AsyncJobStatus;
}

// Transfer Targets

export type FullTransferTargetLine = TransferTargetLineDTO & {
  originalTransferQty?: number;
};

export type UpdatedTransferLine = FullTransferTargetLine & {
  qtyToTransfer?: number;
  selectedUom?: string;
};

export type TransferLineUpdates = {
  itemId: string;
  sourceLocationId: string;
  destinationLocationId: string;
  qtyToTransfer?: number;
  selectedUom?: string;
};

export interface TransferInput {
  approved?: boolean;
  carrier?: IdNameObj;
}

export interface TransferTargetSummary extends Partial<TransferTargetDTO> {
  kind: DocSummaryType.TransferOrder;
  sourceLocation: IdNameObj;
  destinationLocation: IdNameObj;
  totalLines: number;
  lines: TransferSummaryLine[]; // TODO: Check if this is the correct type. i.e. if we have all the info we need to fill this kind of object
  totalWeight: number;
  totalCost: number;
  approved?: boolean;
  carrier?: IdNameObj;
}

export interface ForecastAccuracyHashState {
  view?: string;
  breakdown?: string;
  items?: string[];
  locations?: string[];
  classes?: string[];
  groups?: string[];
  month?: string;
}
