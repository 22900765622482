import React from 'react';

import { PdfDocumentDTO } from '@recurrency/core-api-schema/dist/v2/common';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { UseAsyncTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { camelToTitleCase, formatDate } from 'utils/formatting';
import { asKeyOf, typedColumn } from 'utils/tables';
import { PdfDocumentType } from 'utils/track';

import { OpenPdfButton } from './OpenPdfButton';
import { SendPdfEmailButton } from './SendPdfEmailButton';

export interface PdfDocument extends PdfDocumentDTO {
  type: PdfDocumentType;
}

export const DocumentsTableAgnostic = ({ tableProps }: { tableProps: UseAsyncTableProps<PdfDocumentDTO> }) => {
  const columns: ColumnType<PdfDocument>[] = [
    typedColumn({
      title: 'Type',
      dataIndex: asKeyOf<PdfDocumentDTO>('type'),
      width: '20%',
      render: (type) => camelToTitleCase(type ?? ''),
    }),
    typedColumn({
      title: 'ID',
      width: '20%',
      // Users are accustomed to seeing the number value not the UID value
      dataIndex: asKeyOf<PdfDocumentDTO>('documentNumber'),
    }),
    typedColumn({
      title: 'Created',
      width: '20%',
      dataIndex: asKeyOf<PdfDocumentDTO>('tenantCreatedAt'),
      render: (date) => formatDate(date),
    }),
    typedColumn({
      title: 'Last Modified',
      width: '20%',
      dataIndex: asKeyOf<PdfDocumentDTO>('tenantUpdatedAt'),
      render: (date) => formatDate(date),
    }),
    typedColumn({
      title: 'Actions',
      width: '250px',
      align: 'left',
      render: (record) => {
        const actionId = record.type === PdfDocumentType.PurchaseOrder ? record.documentNumber : record.documentId;
        if (!actionId) return null;
        return (
          <div style={{ display: 'flex', gap: 8 }}>
            <SendPdfEmailButton foreignId={actionId} type={record.type} />
            <OpenPdfButton foreignId={actionId} type={record.type} />
          </div>
        );
      },
    }),
  ];

  return <AsyncTable tableProps={tableProps} columns={columns} />;
};
