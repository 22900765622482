import { Space } from 'antd';
import { theme } from 'theme';

import { Table } from 'components/Table';

import { styled } from 'utils/styledComponent';

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const Header = styled('div')`
  padding-bottom: 16px;
`;

export const HeaderRow = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const HeaderTitle = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Footer = styled('div')`
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  justify-content: flex-end;
`;

export const FooterLeft = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const FooterContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    margin-bottom: 8px;
  }
`;

export const ItemTable = styled(Table)`
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: 8px;

  .ant-table.ant-table-small .ant-table-cell {
    padding: 8px 4px;
  }

  .ant-table {
    td.ant-table-cell {
      vertical-align: top;
      .ant-select,
      .ant-input {
        width: 100%;
      }

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
      }
    }
  }
`;

export const Box = styled('div')`
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: 8px;
  padding: 12px;
`;

export const ButtonGroup = styled(Space)`
  width: 100%;
  position: sticky;
  display: flex;
  top: 20px;
  .ant-space-item {
    width: 100%;
  }
`;
