import React from 'react';

import { InputFormItem } from 'components/FormItems';

import { ProspectHashState } from 'types/hash-state';

export const ProspectContactStep = ({ prospectState }: { prospectState: ProspectHashState }) => (
  <>
    <InputFormItem
      label="First Name"
      name="contactFirstName"
      rules={[
        {
          required: !!(
            prospectState.contactFirstName ||
            prospectState.contactLastName ||
            prospectState.contactTitle ||
            prospectState.contactPhone ||
            prospectState.contactFax ||
            prospectState.contactEmail
          ),
          message: 'First name is required when making a contact',
        },
        { max: 15, message: 'First name cannot be more than 15 characters' },
      ]}
    />
    <InputFormItem
      label="Last Name"
      name="contactLastName"
      rules={[{ max: 24, message: 'Last name cannot be more than 24 characters' }]}
    />
    <InputFormItem
      label="Title"
      name="contactTitle"
      rules={[{ max: 40, message: 'Title cannot be more than 40 characters' }]}
    />
    <InputFormItem
      label="Direct Phone"
      name="contactPhone"
      rules={[{ max: 20, message: 'Phone number cannot be more than 20 characters' }]}
    />
    <InputFormItem
      label="Direct Fax"
      name="contactFax"
      rules={[{ max: 20, message: 'Fax number cannot be more than 20 characters' }]}
    />
    <InputFormItem
      label="Direct Email"
      name="contactEmail"
      rules={[
        { type: 'email', message: 'Must be an email' },
        { max: 255, message: 'Email cannot be more than 255 characters' },
      ]}
    />
  </>
);
