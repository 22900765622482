import React, { useEffect, useState } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PurchaseGroupWithTargetDTO } from '@recurrency/core-api-schema/dist/purchaseGroups/getPurchaseGroups';
import { Form } from 'antd';

import { AsyncSelect } from 'components/AsyncSelect';
import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useLocationsSelectProps, useSuppliersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { responsiveFormLayout2 } from 'components/FormItems';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { Select } from 'components/Select';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { createSubmissionNotification } from 'utils/submissionNotification';

export interface SavePurchaseGroupModalProps {
  onClose: () => void;
  onSubmit?: () => void;
  isVisible?: boolean;
  reloadPurchaseGroups: () => void;
  groupId?: string;
  initialFormValues?: Partial<SavePurchaseGroupFormBody>;
  purchaseGroups: PurchaseGroupWithTargetDTO[];
}

export interface SavePurchaseGroupFormBody {
  groupId?: string;
  groupName: string;
  supplierId: string;
  locationIds: string[];
  purchaseLocationId: string;
  companyId?: string;
}

export const SavePurchaseGroupModal = ({
  onClose,
  reloadPurchaseGroups,
  initialFormValues,
  purchaseGroups,
}: SavePurchaseGroupModalProps) => {
  const { activeUser, activeCompanyIds, userAllowedCompanies } = useGlobalApp();
  const { id: userId } = activeUser;

  const [form] = Form.useForm<SavePurchaseGroupFormBody>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(initialFormValues?.companyId || '');

  // Get company options to show (show all if active company IDs is null)
  const companyOptions = userAllowedCompanies
    .filter((c) => (!activeCompanyIds ? true : activeCompanyIds.some((id) => id === c.companyId)))
    .map((c) => ({ label: `${c.companyId}: ${c.companyName}`, value: c.companyId }));

  const haveMultipleCompanyOptions = companyOptions.length > 1;
  // Hide company input if there aren't company choices or if there's one passed in
  const hideCompanyInput = !haveMultipleCompanyOptions || !!initialFormValues?.companyId;
  // Hide certain inputs if no company has been selected
  const hideCompanySpecificInputs = haveMultipleCompanyOptions && selectedCompanyId === '';

  const suppliersSelectProps = useSuppliersSelectProps(selectedCompanyId === '' ? undefined : selectedCompanyId);
  const locationCompanyIdFilter = getLocationCompanyIdFilter(activeCompanyIds, selectedCompanyId);
  const locationsSelectProps = useLocationsSelectProps({
    sortByValueProp: true,
    companyIds: locationCompanyIdFilter,
  });

  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>(initialFormValues?.locationIds || []);
  const convertedLocationsSelectProps = convertToMultiSelectProps(locationsSelectProps, { valueKey: 'foreignId' });
  const selectedPurchaseTargets = selectedLocationIds
    .map((id) => convertedLocationsSelectProps.options.find((o) => o.value === id))
    .filter((o) => typeof o !== 'undefined') as MultiSelectOption[];

  useEffect(() => {
    if (initialFormValues?.groupId) {
      form.setFieldsValue(initialFormValues);
    } else form.resetFields();
  }, [form, initialFormValues]);

  // Reset purchase location, locations and supplier if company is changed
  const onCompanyChange = (companyId: string) => {
    setSelectedCompanyId(companyId);
    setSelectedLocationIds([]);
    form.resetFields(['supplierId', 'purchaseLocationId']);
  };

  function checkPurchaseLocationId(selectedLocations: string[]) {
    const selectedPurchaseLocationId = form.getFieldValue('purchaseLocationId');
    if (!selectedLocations.includes(selectedPurchaseLocationId)) {
      form.setFieldsValue({ purchaseLocationId: undefined });
    }
  }

  function validateGroupName() {
    return new Promise((resolve, reject) => {
      const formGroupName = form.getFieldValue('groupName') ?? '';
      const groupNames = purchaseGroups
        .filter((g) => g.groupId !== initialFormValues?.groupId)
        .map((g) => g.groupName.toLowerCase());

      if (groupNames.includes(formGroupName.toLowerCase()))
        reject(new Error('A purchase group with this name already exists'));
      resolve('');
    });
  }

  async function handleSubmit(formBody: SavePurchaseGroupFormBody) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Purchase Group',
      expectedWaitSeconds: 10,
      submittingMessage: 'Saving Purchase Group',
    });

    const bodyParams = {
      groupName: formBody.groupName,
      purchaseLocationId: formBody.purchaseLocationId,
      supplierId: formBody.supplierId.split(':')[0],
      locationIds: formBody.locationIds.filter((id) => id !== formBody.purchaseLocationId),
      lastUpdatedByUserId: userId,
      createdByUserId: userId,
    };

    try {
      onClose();
      const groupId = (initialFormValues as SavePurchaseGroupFormBody)?.groupId;
      if (groupId) {
        const updateBodyParams = { ...bodyParams, groupId };
        await coreApiFetch(schemas.purchaseGroups.updatePurchaseGroup, {
          bodyParams: updateBodyParams,
        });
      } else {
        await coreApiFetch(schemas.purchaseGroups.createPurchaseGroup, { bodyParams });
      }
      submitNotification.success({ successMessage: 'Purchase Group saved', duration: 10 });
      reloadPurchaseGroups();
      setSelectedLocationIds([]);
      form.resetFields();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  return (
    <Modal
      className={css`
        margin-top: -150px; // Added this to avoid locations select options to collapse to top of screen.
      `}
      visible
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" form="savePurchaseGroupForm" htmlType="submit">
          Save
        </Button>,
      ]}
      title={`${initialFormValues?.groupId ? 'Edit' : 'New'} Purchase Group`}
      onCancel={onClose}
      width={900}
    >
      <Container>
        <Form
          name="savePurchaseGroupForm"
          form={form}
          onFinish={(values) => {
            handleSubmit(values);
          }}
          onError={console.error}
          onFinishFailed={console.error}
          {...responsiveFormLayout2}
          labelCol={{
            sm: {
              span: 5,
              offset: 1,
            },
            md: {
              span: 5,
              offset: 1,
            },
          }}
        >
          {!hideCompanyInput && (
            <Form.Item
              label="Company"
              name="companyId"
              rules={[{ required: true, message: 'Please specify a company' }]}
            >
              <Select showSearch options={companyOptions} value={selectedCompanyId} onSelect={onCompanyChange} />
            </Form.Item>
          )}

          <Form.Item
            label="Group Name"
            name="groupName"
            rules={[{ required: true, message: 'Please add a group name.' }, { validator: validateGroupName }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Supplier"
            name="supplierId"
            rules={[{ required: true, message: 'Please choose a supplier' }]}
            initialValue={initialFormValues?.supplierId}
            hidden={hideCompanySpecificInputs}
          >
            <AsyncSelect selectProps={suppliersSelectProps} entityPlural="suppliers" />
          </Form.Item>

          <Form.Item
            label="Locations"
            name="locationIds"
            rules={[{ required: true, message: 'You must choose at least 1 location' }]}
            hidden={hideCompanySpecificInputs}
          >
            <AsyncMultiSelect
              containerClassName={css`
                width: 100%;
                height: 40px !important;
              `}
              popoverClassName={css`
                max-height: 300px;
              `}
              selectProps={convertedLocationsSelectProps}
              label="Locations"
              queryPlaceholder="Search locations"
              selectedValues={selectedLocationIds}
              onSelectedValuesChange={(selectedValues) => {
                checkPurchaseLocationId(selectedValues);
                setSelectedLocationIds(selectedValues);
                form.setFieldsValue({ locationIds: selectedValues });
              }}
              icon={<EnvironmentOutlined />}
            />
          </Form.Item>

          <Form.Item
            label="Purchase Location"
            name="purchaseLocationId"
            rules={[{ required: true, message: 'Please choose a purchase location' }]}
            hidden={hideCompanySpecificInputs}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toString().toLowerCase().includes(input.toLowerCase())
              }
              options={selectedPurchaseTargets}
              notFoundContent={
                selectedPurchaseTargets.length === 0 ? 'Please select at least one location first' : 'No results found'
              }
            />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

/**
 * Used to get the company ID filter for passing to getLocation
 * if there is a selected company ID, use that
 * if there are no active company IDs, then don't filter for company
 * otherwise, use all active company IDs
 */
function getLocationCompanyIdFilter(activeCompanyIds: string[] | null, selectedCompanyId: string) {
  if (selectedCompanyId !== '') {
    return [selectedCompanyId];
  }
  if (!activeCompanyIds || activeCompanyIds.length === 0) {
    return undefined;
  }
  return activeCompanyIds;
}
