import React from 'react';

import { css } from '@emotion/css';
import { Popover, Space } from 'antd';

import { UpgradeButtons } from './UpgradeButtons';

export const UpgradePopover = ({ title, description }: { title: string; description: string }) => (
  <Popover
    placement="bottomLeft"
    title={`Upgrade to ${title}`}
    content={
      <Space
        className={css`
          max-width: 300px;
        `}
        direction="vertical"
      >
        <div>{description}</div>
        <UpgradeButtons />
      </Space>
    }
  >
    <div
      className={css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: not-allowed;
      `}
    />
  </Popover>
);
