import React from 'react';

import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { css } from '@emotion/css';

import { getChangeColor } from 'utils/formatting';

export interface ChangeDeltaWithArrowProps {
  value?: number;
  valueFormatter?: (value?: number) => string;
}

export function ChangeDeltaWithArrow({ value, valueFormatter }: ChangeDeltaWithArrowProps) {
  const valueStr = valueFormatter ? valueFormatter(value) : String(value);
  const arrowCss = css`
    font-size: 18px;
    color: transparent;
  `;

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: end;
      `}
    >
      {valueStr}
      {!value ? (
        <CaretDownFilled className={arrowCss} />
      ) : value > 0 ? (
        <CaretUpFilled className={arrowCss} style={{ color: getChangeColor(value) }} />
      ) : (
        <CaretDownFilled className={arrowCss} style={{ color: getChangeColor(value) }} />
      )}
    </div>
  );
}
