import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { Divider, notification } from 'antd';
import * as queryString from 'query-string';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { Tabs } from 'components/Tabs';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatUSD } from 'utils/formatting';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { PaymentInfo } from './tabs/PaymentInfoModal';
import { PaymentMethodsTab } from './tabs/PaymentMethodsTab';
import './styles/PaymentDetailsPage.css';
import { PaymentsTab } from './tabs/PaymentTransactionsTab';

export const PaymentBreakdownLine = ({ label, value }: { label: string; value: string }) => (
  <div
    className={css`
      display: flex;
      justify-content: space-between;
      max-width: 550px;
      margin-bottom: 4px;
    `}
  >
    <Typography type="subtitle">{label}:</Typography>
    <Typography style={{ fontWeight: 600 }} type="subtitle">
      {value}
    </Typography>
  </div>
);

const PAYMENT_TAB_KEY = '1';
const PAYMENT_TRANSACTION_KEY = '2';
export const OrderPayPage = () => {
  const { activeTenant } = useGlobalApp();
  const [transactionFee, setTransactionFee] = useState<number>();

  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>({
    orderNo: '',
    invoiceNo: '',
    customerId: '',
    customerName: '',
    contactId: '',
    contactName: '',
    companyId: '',
    companyName: '',
    shipToId: '',
    shipToName: '',
    requestedDate: '',
    rmaExpirationDate: '',
    salesSubTotal: '',
    taxTotal: '',
    cfTotalDue: '',
    totalFreight: '',
    totalOtherCharge: '',
    downpayment: '',
    downpaymentPaid: '',
  });

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const decodedQueryParams = {} as PaymentInfo;
    // eslint-disable-next-line guard-for-in
    for (const key in queryParams) {
      // convert snake_case to camelCase
      const camelCaseKey = key.replace(/_\w/g, (match) => match[1].toUpperCase());
      decodedQueryParams[camelCaseKey as keyof PaymentInfo] = decodeURIComponent(queryParams[key] as string);
    }

    if (!decodedQueryParams.orderNo || decodedQueryParams.orderNo.trim() === '') {
      // disable Tabs if orderNo is missing
      setActiveTab('');
      // show a notification for missing orderNo then close the window:
      notification.error({
        message: 'Missing Order Number!',
        description: 'This window will close in a few seconds. Reopen this window should fix this issue.',
        duration: 10,
        onClose: () => {
          window.close();
        },
        onClick: () => {
          window.close();
        },
      });
    } else {
      setPaymentInfo(decodedQueryParams);
      setActiveTab(PAYMENT_TAB_KEY);
    }
  }, []);

  const [activeTab, setActiveTab] = useState(PAYMENT_TAB_KEY);

  return (
    <Container
      className={css`
        width: 100%;
        padding: 0 48 0 48;
        margin: 0px;
      `}
    >
      <div
        className={css`
          margin-bottom: 28px;
          display: flex;
          gap: 6px;
        `}
      >
        <Typography type="large" style={{ fontWeight: 'bold' }}>
          Payment
        </Typography>
        <Typography type="large" style={{ fontWeight: 'normal' }}>
          for
        </Typography>
        <Typography type="large" style={{ fontWeight: 'bold' }}>
          Order #{paymentInfo.orderNo}
        </Typography>
      </div>
      <DetailPageSections
        sections={[
          {
            title: 'Order Information',
            rows: [
              [
                { label: `Customer: `, value: `${paymentInfo.customerName} `, id: paymentInfo.customerId },
                paymentInfo.contactId && {
                  label: `Contact: `,
                  value: `${paymentInfo.contactName} `,
                  id: paymentInfo.contactId,
                },
                { label: `Ship To: `, value: `${paymentInfo.shipToName} `, id: paymentInfo.shipToId },
              ].filter(truthy),
            ],
          },
          {
            title: 'Payment Breakdown',
            children: (
              <>
                <PaymentBreakdownLine label="Order Subtotal" value={formatUSD(paymentInfo.salesSubTotal, true, 2)} />
                <PaymentBreakdownLine label="Tax" value={formatUSD(paymentInfo.taxTotal, true, 2)} />
                <PaymentBreakdownLine label="Freight" value={formatUSD(paymentInfo.totalFreight, true, 2)} />
                {paymentInfo.totalOtherCharge && Number(paymentInfo.totalOtherCharge) > 0 && (
                  <PaymentBreakdownLine label="Other Charge" value={formatUSD(paymentInfo.totalOtherCharge, true, 2)} />
                )}
                {transactionFee != null && (
                  <PaymentBreakdownLine label="Transaction Fee" value={formatUSD(transactionFee, true, 2)} />
                )}
                <PaymentBreakdownLine
                  label="Grand Total"
                  value={formatUSD(
                    Number(paymentInfo.cfTotalDue) + (transactionFee != null ? transactionFee : 0),
                    true,
                    2,
                  )}
                />
              </>
            ),
          },
        ]}
      />
      <Tabs
        activeTab={activeTab}
        onChange={(tab) => {
          setActiveTab(tab);
        }}
        tabs={
          activeTab && isTenantErpTypeP21(activeTenant.erpType)
            ? [
                {
                  header: 'Payment Methods',
                  tabKey: PAYMENT_TAB_KEY,
                  content: (
                    <PaymentMethodsTab
                      paymentInfo={paymentInfo}
                      setFee={setTransactionFee}
                      transactionFee={transactionFee}
                      isDirectCharge={false}
                    />
                  ),
                },
                {
                  header: 'Payments',
                  tabKey: PAYMENT_TRANSACTION_KEY,
                  content: <PaymentsTab orderNo={paymentInfo.orderNo} totalDue={paymentInfo.cfTotalDue} />,
                },
              ].filter(truthy)
            : []
        }
      />
      <Divider
        className={css`
          position: fixed;
          bottom: 36px;
        `}
      />
      <Button
        onClick={() => window.close()}
        type="default"
        className={css`
          position: fixed;
          bottom: 10px;
          right: 10px;
        `}
      >
        Done
      </Button>
    </Container>
  );
};
