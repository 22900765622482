const passwordSegment = () => Math.random().toString(36).substr(2, 8);
const shuffle = (v: string) =>
  v
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');
export const generateTempPassword = () => {
  const symLength = Math.ceil(Math.random() * 10);
  const symString = '!#$<>^&*()-+?';
  let symStr = '';
  for (let i = 0; i < symLength; i++) {
    symStr += symString.charAt(Math.floor(Math.random() * symString.length));
  }
  return shuffle(symStr + passwordSegment() + passwordSegment());
};
