import React, { useState } from 'react';

import { BulbOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Button } from 'antd';

import { Tooltip } from 'components/Tooltip';

import { InventiveDrawer } from './InventiveDrawer';

export const InventiveButton = () => {
  // Not using showAsyncModal for now because we need to keep the iframe mounted to preserve sidebar state
  // Will switch back to showAsyncModal when Inventive implements state propagation
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <>
      <div
        className={css`
          position: fixed;
          bottom: 16px;
          right: 16px;
        `}
      >
        <Tooltip title="Recurrency Copilot" placement="left">
          <Button
            className={css`
              // Antd high elevation shadow
              box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
                0 9px 28px 8px rgba(0, 0, 0, 0.05);
            `}
            shape="circle"
            type="primary"
            size="large"
            icon={<BulbOutlined />}
            onClick={() => setShowDrawer(true)}
          />
        </Tooltip>
      </div>
      <InventiveDrawer onClose={() => setShowDrawer(false)} visible={showDrawer} />
    </>
  );
};
