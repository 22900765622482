import { theme } from 'theme';
import { colors } from 'theme/colors';
import { fontWeights } from 'theme/typography';

import { styled } from 'utils/styledComponent';

export const LabelContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  line-height: 1.3em;
`;

export const LabelTitle = styled('div')`
  display: flex;
  color: rgba(0, 0, 0, 0.85);
`;

export const LabelSubtitle = styled('div')`
  color: ${theme.colors.primary[600]};
`;

export const LabelHighlightPart = styled('span')`
  color: ${colors.primary[600]};
  font-weight: ${fontWeights.semibold};
`;
