import React from 'react';

import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useLegacyApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { formatDate } from 'utils/formatting';
import { encodeLegacyApiParam } from 'utils/routes';
import { typedColumn } from 'utils/tables';

import { OrderShipment } from 'types/legacy-api';

export const OrderShipmentsTable = ({ orderNo }: { orderNo: string | undefined }) => {
  const columns: ColumnType<OrderShipment>[] = [
    typedColumn({
      title: 'Pick Ticket No',
      dataIndex: 'pickTicketNo',
    }),
    typedColumn({
      title: 'Location',
      render: (orderShipment) => `${orderShipment.locationId}: ${orderShipment.location.locationName}`,
    }),
    typedColumn({
      title: 'Shipment Type',
      dataIndex: 'shipmentType',
    }),
    typedColumn({
      title: 'Carrier',
      dataIndex: 'carrierName',
    }),
    typedColumn({
      title: 'Tracking No',
      dataIndex: 'trackingNo',
    }),
    typedColumn({
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      render: (invoiceNo) => invoiceNo || '-',
    }),
    typedColumn({
      title: 'Print Date',
      dataIndex: 'printDate',
      render: (printDate) => formatDate(printDate),
    }),
    typedColumn({
      title: 'Ship Date',
      dataIndex: 'shipDate',
      render: (shipDate) => (shipDate ? formatDate(shipDate) : '-'),
    }),
  ];

  return (
    <AsyncTable
      tableProps={useLegacyApiTableProps({
        apiPath: `/v3/orders/${encodeLegacyApiParam(orderNo || '')}/shipments`,
      })}
      columns={columns}
    />
  );
};
