import React from 'react';

import { useHistory } from 'react-router-dom';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { UpdateTenantBodyParams } from '@recurrency/core-api-schema/dist/tenants/putUpdateTenant';
import { Form, notification } from 'antd';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { InputFormItem, defaultFormLayout, defaultFormTailLayout, CheckboxFormItem } from 'components/FormItems';
import { CenteredLoader, CenteredError } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { routes, IdPathParams, usePathParams } from 'utils/routes';

export const TenantEditPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const {
    data: tenant,
    isLoading,
    error,
  } = useCoreApi(schemas.tenants.getTenantById, {
    pathParams: { tenantId: id },
  });

  const history = useHistory();
  const [form] = Form.useForm();

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !tenant) {
    return <CenteredLoader />;
  }

  const onSubmit = async (data: UpdateTenantBodyParams) => {
    try {
      await coreApiFetch(schemas.tenants.putUpdateTenant, {
        bodyParams: data,
        pathParams: { tenantId: tenant.id },
      });
      notification.success({ message: 'Tenant updated.' });
      history.push(routes.internal.tenantDetails(tenant.id));
    } catch (err) {
      captureAndShowError(err, `Error while updating tenant`);
    }
  };

  return (
    <Form.Provider>
      <Container>
        <PageHeader title="Edit Tenant" onBack={() => history.goBack()} />
        <div
          className={css`
            .ant-form-item {
              margin-bottom: 24px;
            }
            .ant-col {
              max-width: 40vw;
            }
          `}
        >
          <Form {...defaultFormLayout} form={form} onFinish={onSubmit} onError={console.error} initialValues={tenant}>
            <InputFormItem label="Name" name="name" rules={[{ required: true, message: 'Please add a name.' }]} />
            <InputFormItem
              label="Company Name"
              name="companyName"
              rules={[{ required: true, message: 'Please add a company name.' }]}
            />
            <InputFormItem label="Logo URL" name="logoUrl" />
            <CheckboxFormItem label="Active?" name="isActive" />
            <Form.Item {...defaultFormTailLayout}>
              <Button id="submitTenantButton" type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    </Form.Provider>
  );
};
