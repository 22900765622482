import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { RecommendedItemsRecordDTO } from '@recurrency/core-api-schema/dist/ml/getRecommendedItemsToCustomer';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { NewQuoteButton } from 'components/recipes/NewQuoteButton';
import { SelectionCountContext } from 'components/recipes/SelectionCountContext';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { joinIdNameObj } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { sortablePercentColumn, sortableStringColumn } from 'utils/tables';

import { IdNameObj } from 'types/legacy-api';

export const RecommendedItemsTable = ({
  customerId,
  customerName,
  companyId,
  companyName,
}: {
  customerId: string;
  customerName: string;
  companyId?: string;
  companyName?: string;
}) => {
  const { activeTenant, activeUser } = useGlobalApp();

  const columns: ColumnType<RecommendedItemsRecordDTO>[] = [
    sortableStringColumn({
      title: 'Item ID',
      dataIndex: 'itemId',
      render: (itemId: string) => <Link to={routes.sales.itemDetails(itemId)}>{itemId}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemName',
    }),
    sortableStringColumn({
      title: 'Product Group ID',
      dataIndex: 'itemGroupId',
    }),
    sortableStringColumn({
      title: 'Product Group',
      dataIndex: 'itemGroupName',
    }),
    sortablePercentColumn({
      title: 'Match %',
      dataIndex: 'matchRank',
      defaultSortOrder: 'descend',
    }),
  ];

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [selectedItemIdNameObjs, setSelectedItemIdNameObjs] = useState<IdNameObj[]>([]);

  return (
    <div>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          gap: 16px;
          align-items: center;
          margin-bottom: 12px;
        `}
      >
        <SelectionCountContext
          selection={selectedItemIdNameObjs}
          onClear={() => {
            setSelectedItemIds([]);
            setSelectedItemIdNameObjs([]);
          }}
        />
        <NewQuoteButton
          hashState={{
            customer: joinIdNameObj({ foreignId: customerId, name: customerName }),
            company: joinIdNameObj({ foreignId: companyId ?? '', name: companyName ?? '' }),
            items: selectedItemIdNameObjs,
          }}
          props={{ disabled: selectedItemIds.length === 0 }}
        />
      </div>
      <AsyncTable
        tableProps={useCoreApiTableProps({
          schema: schemas.ml.getRecommendedItemsToCustomer,
          pathParams: { customerId },
        })}
        columns={columns}
        rowKey="itemUid"
        rowSelection={
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote)
            ? {
                selectedRowKeys: selectedItemIds,
                onChange: (selectedRowKeys: React.Key[], selectedRows: RecommendedItemsRecordDTO[]) => {
                  // we can assume rowKeys are string[] instead of Array<string | number>
                  setSelectedItemIds(selectedRowKeys as string[]);
                  setSelectedItemIdNameObjs(selectedRows.map((row) => ({ foreignId: row.itemId, name: row.itemName })));
                },
              }
            : undefined
        }
      />
    </div>
  );
};
