import React from 'react';

import { LineItemActionsP21 } from 'pages/orders/quotes/types';

import { ActionButton } from 'components/Button/ActionButton';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { showAsyncModal } from 'utils/asyncModal';
import { pluralize } from 'utils/formatting';
import { OrderType } from 'utils/track';

import { QuoteLineItemP21 } from 'types/hash-state';

import { ItemLotsModal } from './ItemLotsModal';

export function LotPropertiesP21({
  lineItem,
  lineItemActions,
  lineItemIdx,
  locationId,
  orderType,
}: {
  lineItem: QuoteLineItemP21;
  lineItemActions: LineItemActionsP21;
  lineItemIdx: number;
  locationId?: string;
  orderType: OrderType;
}) {
  const quantityAllocated = lineItem.lots?.reduce((acc, lot) => acc + lot.quantity, 0) || 0;
  const quantityToAllocate = lineItem.quantity || 0;
  const nonZeroLots = lineItem.lots?.filter((lot) => lot.quantity > 0);

  const props: LabelValueProperty[] = [
    {
      label: pluralize(nonZeroLots?.length || 0, 'Lot', 'Lots'),
      value: (
        <>
          {nonZeroLots
            ? nonZeroLots.length === 1
              ? nonZeroLots[0].lotId
              : `${nonZeroLots.length} Selected`
            : `None Selected`}
          {quantityToAllocate > quantityAllocated ? (
            <InfoTooltip
              title={`${pluralize(quantityToAllocate - quantityAllocated, 'unit', 'units', true)}  unallocated`}
              useInfoIcon
            />
          ) : null}
          {` | `}
          <ActionButton
            label={orderType === OrderType.Order ? 'Edit' : 'View Only'}
            onClick={async () => {
              const selectedLots = await showAsyncModal(ItemLotsModal, { lineItem, locationId, orderType });
              if (selectedLots) {
                lineItemActions.updateLineItem(lineItemIdx, { lots: selectedLots });
              }
            }}
          />
        </>
      ),
    },
  ];

  return <LabelValueProperties properties={props} />;
}
