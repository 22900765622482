import React from 'react';

import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { OrderType } from 'utils/track';

import { QuoteEditFlowP21 } from '../quotes/QuoteEditFlowP21';
import { OrderEditFlowSAPB1 } from './OrderEditFlowSAPB1';

export const OrderNewPage = () => {
  const { activeTenant } = useGlobalApp();

  if (activeTenant.erpType === IntegratedErps.SAPB1) {
    return <OrderEditFlowSAPB1 orderType={OrderType.Order} />;
  }
  return <QuoteEditFlowP21 orderType={OrderType.Order} />;
};
