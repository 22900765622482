import type { HTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

import { cx, css } from '@emotion/css';

export interface GridProps extends HTMLAttributes<HTMLDivElement> {
  fillStrategy?: 'auto-fit' | 'auto-fill';
  colMin?: string;
  colMax?: string;
  gap?: number;
}

export const Grid = forwardRef<HTMLDivElement, GridProps>(
  ({ fillStrategy = 'auto-fit', colMin = '200px', colMax = '1fr', gap = 20, children, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        css`
          display: grid;
          grid-template-columns: repeat(${fillStrategy}, minmax(${colMin}, ${colMax}));
          gap: ${gap}px;
        `,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  ),
);
