import React, { ReactNode } from 'react';

import { css, cx } from '@emotion/css';

import { DividerLine } from './DividerLine';

export const FilterBarBox = ({
  children,
  dividerLine,
  className,
}: {
  children?: ReactNode;
  dividerLine?: boolean;
  className?: string;
}) => (
  <>
    <div
      className={cx(
        css`
          display: flex;
          gap: 8px;
          margin-bottom: 20px;
          flex-wrap: wrap;
          align-items: center;
        `,
        className,
      )}
    >
      {children}
    </div>
    {dividerLine && <DividerLine marginTop={0} />}
  </>
);
