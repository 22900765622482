import React from 'react';

import { Link } from 'react-router-dom';

import { CalendarOutlined, DollarOutlined, UserOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';

import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { addressPropertyListItem } from 'components/recipes/detailPage/propertyListItemUtils';
import { ErpOrderStatusBadge } from 'components/recipes/ErpOrderStatusBadge';
import { NewPurchaseOrderButton } from 'components/recipes/NewPurchaseOrderButton';

import { useCoreApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import { dashesIfEmpty, formatAddressMultiLine, formatDate, formatUSD } from 'utils/formatting';
import { routes, usePathParams, IdPathParams } from 'utils/routes';

import { purchaseOrderDetailToHashState } from '../PurchaseOrderNewPage/utils';
import { PurchaseOrderDocumentsTable } from './tabs/PurchaseOrderDocumentsTable';
import { PurchaseOrderLinesTable } from './tabs/PurchaseOrderLinesTable';

export const PurchaseOrderDetailsPage = () => {
  const { id: purchaseOrderNumber } = usePathParams<IdPathParams>();
  const {
    data: purchaseOrder,
    error,
    isLoading,
  } = useCoreApi(schemas.purchaseOrders.getPurchaseOrderDetailsV2, {
    pathParams: { purchaseOrderId: purchaseOrderNumber },
  });

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !purchaseOrder) {
    return <CenteredLoader />;
  }

  if (Object.keys(purchaseOrder).length === 0) {
    return <NoDataMessage />;
  }

  const headerSections: DetailPageSection[] = [
    {
      title: 'Vendor',
      rows: [
        [
          purchaseOrder.vendorId
            ? {
                icon: <UserOutlined />,
                label: 'Vendor',
                value: (
                  <Link to={routes.purchasing.vendorDetails(purchaseOrder.vendorId)}>{purchaseOrder.vendorName}</Link>
                ),
                id: purchaseOrder.vendorId,
              }
            : undefined,
          purchaseOrder.supplierId
            ? {
                icon: <UserOutlined />,
                label: 'Supplier',
                value: dashesIfEmpty(purchaseOrder.supplierName),
                id: purchaseOrder.supplierId,
              }
            : undefined,
        ].filter(truthy),
      ],
    },
    {
      title: 'Purchase Order Configuration',
      rows: [
        [
          {
            icon: <CalendarOutlined />,
            label: 'Purchase Order Date',
            value: formatDate(purchaseOrder.orderDate),
          },
          {
            icon: <UserOutlined />,
            label: 'Buyer',
            value: dashesIfEmpty(purchaseOrder.buyer?.name),
            id: purchaseOrder.buyer?.id,
          },
          {
            icon: <DollarOutlined />,
            label: 'Total Amount',
            value: formatUSD(purchaseOrder.totalAmount, true),
          },
        ],
      ],
    },
    {
      title: 'Logistics',
      rows: [
        [
          {
            label: 'Purchase Location',
            value: purchaseOrder.locationName,
            id: purchaseOrder.locationId,
          },
          purchaseOrder.shipToAddress
            ? addressPropertyListItem({
                label: 'Ship To',
                value: formatAddressMultiLine({
                  name: purchaseOrder.shipToAddress?.addressName,
                  address1: purchaseOrder.shipToAddress?.address1,
                  address2: purchaseOrder.shipToAddress?.address2,
                  address3: purchaseOrder.shipToAddress?.address3,
                  city: purchaseOrder.shipToAddress?.city,
                  state: purchaseOrder.shipToAddress?.state,
                  zip: purchaseOrder.shipToAddress?.zipcode,
                  phone: purchaseOrder.shipToAddress?.centralPhoneNumber,
                }),
              })
            : null,
          {
            label: 'Carrier',
            value: dashesIfEmpty(purchaseOrder.carrierName),
            id: purchaseOrder.carrierId,
          },
        ].filter(truthy),
      ],
    },
  ];

  return (
    <Container>
      <PageHeader
        title={purchaseOrderNumber}
        copyable
        entity={{
          kind: 'Purchase Order',
          badge: <ErpOrderStatusBadge erpOrderStatus={purchaseOrder.status} />,
        }}
        headerActions={
          <NewPurchaseOrderButton label="Copy as New PO" hashState={purchaseOrderDetailToHashState(purchaseOrder)} />
        }
      />
      <DetailPageSections sections={headerSections} />
      <NavTabs
        tabs={[
          { header: 'Purchase Order Lines', content: <PurchaseOrderLinesTable lines={purchaseOrder.lines ?? []} /> },
          {
            header: 'Documents',
            content: <PurchaseOrderDocumentsTable purchaseOrderNumber={purchaseOrder.purchaseOrderNumber} />,
          },
        ]}
      />
    </Container>
  );
};
