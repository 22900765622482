export enum PromiseQueueState {
  Queued,
  Pending,
  Fulfilled,
  Rejected,
}

export interface QueuedResult<RequestT> {
  state: PromiseQueueState.Queued;
  request: RequestT;
  response: undefined;
  error: undefined;
}

export interface PendingResult<RequestT> {
  state: PromiseQueueState.Pending;
  request: RequestT;
  response: undefined;
  error: undefined;
}

export interface FulfilledResult<RequestT, ResponseT> {
  state: PromiseQueueState.Fulfilled;
  request: RequestT;
  response: ResponseT;
  error: undefined;
}

export interface RejectedResult<RequestT> {
  state: PromiseQueueState.Rejected;
  request: RequestT;
  response: undefined;
  error: Error;
}

export type PromiseQueueStateResult<RequestT, ResponseT> =
  | QueuedResult<RequestT>
  | PendingResult<RequestT>
  | FulfilledResult<RequestT, ResponseT>
  | RejectedResult<RequestT>;

export class ConcurrentPromiseQueue<RequestT, ResponseT> {
  requests: RequestT[];
  maxConcurrency: number;
  promiseFn: (request: RequestT) => Promise<ResponseT>;
  onStateChange?: (state: PromiseQueueStateResult<RequestT, ResponseT>[]) => void;
  queue: PromiseQueueStateResult<RequestT, ResponseT>[];
  taskIntervalMs: number;

  constructor({
    requests,
    promiseFn,
    onStateChange,
    maxConcurrency,
    taskIntervalMs = 0,
  }: {
    /** task inputs  */
    requests: RequestT[];
    /** async function to transform request to response */
    promiseFn: (request: RequestT) => Promise<ResponseT>;
    /** callback to watch progress of queue */
    onStateChange?: (state: PromiseQueueStateResult<RequestT, ResponseT>[]) => void;
    /** how many maximum tasks should be running at any time concurrently */
    maxConcurrency?: number;
    /** how long to wait before adding new task ? - used to prevent thundering herd and deadlocks */
    taskIntervalMs?: number;
  }) {
    this.requests = requests;
    this.maxConcurrency = maxConcurrency ?? requests.length;
    this.promiseFn = promiseFn;
    this.onStateChange = onStateChange;
    this.taskIntervalMs = taskIntervalMs;

    this.queue = requests.map((request) => ({
      state: PromiseQueueState.Queued,
      request,
      response: undefined,
      error: undefined,
    }));
  }

  async processQueue(): Promise<PromiseQueueStateResult<RequestT, ResponseT>[]> {
    const pendingPromises: Promise<void>[] = [];

    do {
      // enqueue new tasks if maxConcurrency is not reached
      while (pendingPromises.length < this.maxConcurrency) {
        const task = this.queue.find((t) => t.state === PromiseQueueState.Queued);
        if (task) {
          task.state = PromiseQueueState.Pending;
          const promise = this.promiseFn(task.request)
            .then((result) => {
              task.state = PromiseQueueState.Fulfilled;
              task.response = result;
            })
            .catch((error) => {
              task.state = PromiseQueueState.Rejected;
              task.error = error;
            })
            .finally(() => {
              this.onStateChange?.(this.queue);
              pendingPromises.splice(pendingPromises.indexOf(promise), 1);
            });

          pendingPromises.push(promise);

          if (this.taskIntervalMs) {
            await delay(this.taskIntervalMs);
          }
        } else {
          break; // no more tasks to add
        }
      }

      // wait for any of the pending promises to finish
      // fun fact - Promise.race([]) will wait indefinitely, hence need to check length
      if (pendingPromises.length > 0) {
        await Promise.race(pendingPromises);
      }
    } while (pendingPromises.length > 0);

    return this.queue;
  }
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
