import React, { useEffect, useMemo, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import { colors } from 'theme/colors';

import { SkeletonInput } from 'components/Input/SkeletonInput';

export interface InputProps extends AntdInputProps {
  isLoading?: boolean;
  validate?: (value?: string) => boolean;
  autoFocus?: boolean;
  hasWarning?: boolean;
  hasError?: boolean;
}

export const Input = (props: InputProps): JSX.Element => {
  const {
    isLoading = false,
    validate = () => true,
    disabled,
    value,
    autoFocus = false,
    hasWarning,
    className,
    ...otherProps
  } = props;

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      // @ts-expect-error (converted from ts-ignore)
      setTimeout(() => inputRef.current.focus(), 1);
    }
  }, [autoFocus, inputRef]);

  const hasError: boolean = useMemo(() => {
    if (disabled) return false;

    return !validate(value as string | undefined);
  }, [disabled, validate, value]);

  if (isLoading) return <SkeletonInput active sizetype={otherProps?.size ?? 'middle'} />;

  return (
    <AntdInput
      className={cx(
        'Input',
        css`
          border-radius: 8px;
          border-color: ${colors.neutral[300]};
          height: ${props.size === 'large' ? '50px' : props.size === 'small' ? '30px' : '40px'};
          ${hasError && `border-color: ${colors.danger[200]};`}
          ${hasWarning && `border-color: ${colors.warning[200]};`}
        `,
        className,
      )}
      ref={inputRef}
      value={value}
      disabled={disabled}
      // remove focus from input so user doesn't change input quantities or prices when trying to scroll the page
      onWheel={(e) => e.currentTarget.blur()}
      {...otherProps}
    />
  );
};
