import React from 'react';

import { SalesInvoiceLinesReportItemDTO } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';

import { SkeletonBar } from 'components/SkeletonBar';
import { Sparkline } from 'components/Sparkline';

import { formatUSD } from 'utils/formatting';

import { getMonthlyFilledSparklineSeries } from './salesReportUtils';

export const SalesReportSparkline = ({
  stats,
  isLoading,
  yearsOfSparkline,
}: {
  stats: SalesInvoiceLinesReportItemDTO | undefined;
  isLoading: boolean;
  yearsOfSparkline: number;
}) =>
  !isLoading ? (
    <Sparkline
      height={80}
      series={getMonthlyFilledSparklineSeries(
        stats?.salesMonthly ?? {},
        new Date(new Date().getFullYear() - yearsOfSparkline, 0, 1),
        new Date(),
        formatUSD,
      )}
    />
  ) : (
    <SkeletonBar size="large" />
  );
