import React from 'react';

import { useHistory } from 'react-router-dom';

import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { UserUpdatePartialPayload } from '@recurrency/core-api-schema/dist/users/patchUpdatePartialUser';
import { Form, notification } from 'antd';
import { theme } from 'theme';

import { AsyncSelect } from 'components/AsyncSelect';
import { useActiveUserTenantsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { InputFormItem, defaultFormTailLayout, SelectFormItem, responsiveFormLayout } from 'components/FormItems';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';
import { globalAppStore, useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { getTimezoneOptions } from 'utils/timezones';

const tzOptions = getTimezoneOptions();

interface EditUserDetailsForm {
  firstName?: string;
  lastName?: string;
  email?: string;
  timezone?: string;
  tenantId?: string;
  quoteCopyEmailsTo?: string[];
  orderCopyEmailsTo?: string[];
  invoiceCopyEmailsTo?: string[];
}
export function updatePartialRequestFromFormState(state: EditUserDetailsForm): UserUpdatePartialPayload {
  return {
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    timezone: state.timezone,
    tenantUserSettings: {
      // tenant id will always exist with form validation
      tenantId: state.tenantId!,
      settings: {
        quote: {
          copyEmailsTo: state.quoteCopyEmailsTo,
        },
        order: {
          copyEmailsTo: state.orderCopyEmailsTo,
        },
        invoice: {
          copyEmailsTo: state.invoiceCopyEmailsTo,
        },
      },
    },
  };
}

export const EditDetailsPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();
  const {
    data: user,
    isLoading,
    error,
  } = useCoreApi(schemas.users.getUserById, {
    pathParams: { id: activeUser.id },
    queryParams: { includeAuth: true },
  });
  const activeUserTenantsSelectProps = useActiveUserTenantsSelectProps();
  const [form] = Form.useForm<EditUserDetailsForm>();
  const history = useHistory();

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !user) {
    return <CenteredLoader />;
  }

  const onSubmit = async (data: EditUserDetailsForm) => {
    const request = updatePartialRequestFromFormState(data);
    try {
      const { data: updatedUser } = await coreApiFetch(schemas.users.updatePartialUser, {
        pathParams: { id: user.id },
        bodyParams: request,
      });
      await globalAppStore.update({
        activeUser: { ...activeUser, fullName: `${updatedUser.firstName} ${updatedUser.lastName}` },
        activeTenant: {
          ...activeTenant,
          // Active tenant should always be in the users tenants
          tenantUser:
            updatedUser.tenants?.filter((tenant) => tenant.id === activeTenant.id)[0].tenantUser ??
            activeTenant.tenantUser,
        },
        tenants: updatedUser.tenants,
      });
      notification.success({ message: 'Account Details updated.' });
    } catch (err) {
      captureAndShowError(err, `Error while updating user`);
    }
  };

  return (
    <Container>
      <PageHeader title="Edit Account Settings" onBack={() => history.goBack()} />
      <Form.Provider>
        <Form
          {...responsiveFormLayout}
          form={form}
          onFinish={onSubmit}
          onError={console.error}
          initialValues={{
            ...user,
            tenantId: activeTenant.id,
            quoteCopyEmailsTo: activeTenant.tenantUser.settings?.quote?.copyEmailsTo ?? [],
            orderCopyEmailsTo: activeTenant.tenantUser.settings?.order?.copyEmailsTo ?? [],
            invoiceCopyEmailsTo: activeTenant.tenantUser.settings?.invoice?.copyEmailsTo ?? [],
          }}
        >
          <InputFormItem
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please add a first name.' }]}
          />
          <InputFormItem
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please add a last name.' }]}
          />
          <Form.Item label="Email" style={{ marginBottom: '0px' }}>
            <Form.Item
              style={{
                display: 'inline-block',
                width: 'calc(90% - 12px)',
                marginBottom: '0px',
              }}
            >
              <InputFormItem name="email" rules={[{ required: true, message: 'Please add an email.' }]} />
            </Form.Item>
            <Form.Item
              style={{
                display: 'inline-block',
                width: 'calc(10% + 1px)',
                marginLeft: '10px',
              }}
            >
              <Tooltip
                slow
                title={user?.emailVerified ? 'Email is verified' : 'Email is not verified'}
                placement="right"
              >
                {user?.emailVerified ? (
                  <CheckCircleOutlined style={{ fontSize: 24, color: theme.colors.success[500] }} />
                ) : (
                  <WarningOutlined style={{ fontSize: 24, color: theme.colors.warning[500], paddingTop: '2px' }} />
                )}
              </Tooltip>
            </Form.Item>
          </Form.Item>
          <SelectFormItem
            isLoading={false}
            initialValue=""
            label="Timezone"
            name="timezone"
            options={tzOptions}
            setFieldsValue={form.setFieldsValue}
          />
          {user?.tenants && user?.tenants?.length > 1 && (
            <Typography type="large" style={{ paddingBottom: '20px' }}>
              Tenant Specific Settings
            </Typography>
          )}
          <Form.Item
            name="tenantId"
            label="Tenant"
            rules={[{ required: true, message: 'Please choose a tenant.' }]}
            hidden={user?.tenants && user?.tenants?.length <= 1}
          >
            <AsyncSelect
              selectProps={activeUserTenantsSelectProps}
              entityPlural="tenants"
              onSelect={(tenantId) => {
                form.setFieldsValue({
                  quoteCopyEmailsTo:
                    user.tenants?.filter((tenant) => tenant.id === tenantId)[0].tenantUser.settings?.quote
                      ?.copyEmailsTo ?? [],
                  orderCopyEmailsTo:
                    user.tenants?.filter((tenant) => tenant.id === tenantId)[0].tenantUser.settings?.order
                      ?.copyEmailsTo ?? [],
                  invoiceCopyEmailsTo:
                    user.tenants?.filter((tenant) => tenant.id === tenantId)[0].tenantUser.settings?.invoice
                      ?.copyEmailsTo ?? [],
                });
              }}
            />
          </Form.Item>
          <SelectFormItem
            label="Quote CC emails"
            name="quoteCopyEmailsTo"
            placeholder="Add emails separated by spaces or commas"
            rules={[
              {
                type: 'array',
                message: 'Must be a list',
                defaultField: { type: 'email', message: 'There can only be valid emails' },
              },
            ]}
            mode="tags"
            notFoundContent=""
            tokenSeparators={[',', ' ']}
          />
          <SelectFormItem
            label="Order CC emails"
            name="orderCopyEmailsTo"
            placeholder="Add emails separated by spaces or commas"
            rules={[
              {
                type: 'array',
                message: 'Must be a list',
                defaultField: { type: 'email', message: 'There can only be valid emails' },
              },
            ]}
            mode="tags"
            notFoundContent=""
            tokenSeparators={[',', ' ']}
          />
          <Form.Item {...defaultFormTailLayout}>
            <Button id="saveUserSettingsButton" type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Form.Provider>
    </Container>
  );
};
