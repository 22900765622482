import React from 'react';

import { css } from '@emotion/css';
import { SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { fontWeights } from 'theme/typography';

import { LabelValueMiniTable } from 'components/recipes/sidePane/LabelValueMiniTable';
import { TargetProgressBar } from 'components/TargetProgressBar';

import { capitalize, formatNumber, formatUSD, joinIdNameObj } from 'utils/formatting';

import { POSummary } from '../../types';

export function POSummariesPanelContent({ targetSummaries }: { targetSummaries: POSummary[] }) {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 12px;
      `}
    >
      {targetSummaries.map((targetSummary, idx) => (
        <div key={idx}>
          <div
            className={css`
              font-weight: ${fontWeights.bold};
            `}
          >
            {joinIdNameObj(targetSummary.supplier)}
            <br />
            {joinIdNameObj(targetSummary.requirementLocation || targetSummary.purchasingLocation)}
          </div>
          {targetSummary.targetType !== SupplierTargetTypes.NotSet ? (
            <TargetProgressBar percent={targetSummary.currentValue / targetSummary.targetValue} />
          ) : null}
          <LabelValueMiniTable
            properties={[
              targetSummary.targetType !== SupplierTargetTypes.NotSet
                ? {
                    label: `Current ${capitalize(targetSummary.targetType)}`,
                    value: formatTargetValue(targetSummary.targetType, targetSummary.currentValue),
                  }
                : null,
              targetSummary.targetType !== SupplierTargetTypes.NotSet
                ? {
                    label: `Target ${capitalize(targetSummary.targetType)}`,
                    value: formatTargetValue(targetSummary.targetType, targetSummary.targetValue),
                  }
                : null,
              {
                label: 'Total Cost of PO',
                value: formatUSD(targetSummary.totalPOCost, true),
              },
              { label: 'Total Lines', value: targetSummary.totalLines },
            ]}
          />
        </div>
      ))}
    </div>
  );
}

export const formatTargetValue = (targetType: SupplierTargetTypes, value: number) =>
  targetType === SupplierTargetTypes.Dollars ? formatUSD(value, true) : formatNumber(value);
