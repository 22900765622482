import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig } = config.quoteOrderEntryFlow;
  const { formConfig: itemFormConfig } = config.itemEntryFlow;
  quoteOrderFormConfig.customerJobNo.visible = true;
  quoteOrderFormConfig.shippingRouteId.required = true;
  itemFormConfig.itemGroup.defaultValue = {
    foreignId: 'TEMPHOLD',
    name: 'Temporary Item Hold',
  };
  itemFormConfig.itemDescription.validate = true;
  return config;
}
