import React from 'react';

import { css } from '@emotion/css';

import { styled } from 'utils/styledComponent';

import { PRIMARY_NAVIGATION_HEIGHT, SECONDARY_NAVIGATION_WIDTH, TERTIARY_NAVIGATION_HEIGHT } from './navConstants';

export const Container = styled('div')`
  width: 100vw;
`;

export const Splitter = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <div
    className={css`
      height: 100%;
      display: flex;
      flex-direction: row;
      padding-top: ${isDesktopView ? `${PRIMARY_NAVIGATION_HEIGHT}px` : 0};
    `}
  >
    {children}
  </div>
);

export const ContentWrapper = ({
  isDesktopView,
  hasTertiaryNav,
  children,
}: {
  isDesktopView: boolean;
  hasTertiaryNav: boolean;
  children: React.ReactNode;
}) => (
  <div
    className={css`
      padding-top: ${isDesktopView && hasTertiaryNav ? `${TERTIARY_NAVIGATION_HEIGHT}px` : 0};
      width: 100%;
    `}
  >
    {children}
  </div>
);
export const Content = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <div
    className={css`
      flex-grow: 1;
      flex-shrink: 1;
      margin-left: ${isDesktopView ? `${SECONDARY_NAVIGATION_WIDTH}px` : '0'};
    `}
  >
    {children}
  </div>
);
