import React from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { colors } from 'theme/colors';
import { fontWeights } from 'theme/typography';

import { Select } from 'components/Select';

import { styled } from 'utils/styledComponent';

export const ResultContainer = ({
  isDesktopView,
  to,
  children,
}: {
  isDesktopView: boolean;
  to: string;
  children: React.ReactNode;
}) => (
  <Link
    to={to}
    className={css`
      display: flex;
      justify-content: space-between;
      flex-direction: ${isDesktopView ? 'row' : 'column'};
    `}
  >
    {children}
  </Link>
);

export const ResultIcon = styled('div')`
  color: rgba(0, 0, 0, 0.45);
  margin-right: 8px;
`;

export const ResultTitleContainer = styled('span')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ResultTitle = styled('div')`
  display: flex;
  color: rgba(0, 0, 0, 0.85);
`;

export const ResultTitleLine1 = styled('div')`
  display: flex;
  color: rgba(0, 0, 0, 0.85);
`;

export const ResultTitleLine2 = styled('div')`
  display: flex;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.55);
  margin-top: -8px;
`;

export const ResultSubtitle = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <span
    className={css`
      color: rgba(0, 0, 0, 0.45);
      margin-left: ${isDesktopView ? 0 : 24}px;
    `}
  >
    {children}
  </span>
);

export const SearchBox = styled(Select)`
  width: 100%;
  .ant-select-selection-item {
    color: ${colors.neutral[500]};
  }
`;

export const UnhighlightedLabel = styled('span')`
  color: rgba(0, 0, 0, 0.85);
  white-space: pre;
`;

export const HighlightedLabel = styled(UnhighlightedLabel)`
  color: ${colors.primary[600]};
  font-weight: ${fontWeights.semibold};
`;
