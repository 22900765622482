import React from 'react';

import { css, cx } from '@emotion/css';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { theme } from 'theme';
import { colors } from 'theme/colors';

export type ButtonProps = {
  spinnerPosition?: 'left' | 'absolute';
} & AntdButtonProps;

type Heights = {
  small: string;
  middle: string;
  large: string;
};

const heights: Heights = {
  small: '32px',
  middle: '40px',
  large: '50px',
};

const getHeights = (size: SizeType = 'middle') => heights[size];

const getColors = (props: ButtonProps) => {
  if (props.type === 'primary' && !props.disabled) {
    return `
      background-color: ${theme.colors.primary[600]};
      border-color: ${theme.colors.primary[600]};
      :hover {
        background: ${theme.colors.primary[700]};
      }
    `;
  }

  return `border-color: ${props.type === 'link' ? 'none' : theme.colors.neutral[200]}`;
};

export const Button = ({ className, spinnerPosition, ...props }: ButtonProps) => (
  <AntdButton
    className={cx(
      css`
        &.ant-btn {
          border-radius: 8px;
          height: ${getHeights(props.size)};
          ${spinnerPosition === 'absolute' ? getHeights(props.size) : ''}
          ${getColors(props)};
          :focus {
            outline: 1px;
          }

          .ant-btn-loading-icon {
            ${spinnerPosition === 'absolute' ? `position: absolute` : ''}
          }
          .anticon {
            ${spinnerPosition === 'absolute'
              ? css`
                  margin-left: 0;
                `
              : ''}
            ${spinnerPosition === 'absolute' && props.loading
              ? css`
                  color: ${colors.neutral[300]};
                `
              : ''}
          }
        }
      `,
      className,
    )}
    {...props}
  />
);
