import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { UserDTO } from '@recurrency/core-api-schema/dist/users/common';
import { notification } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { fuzzyFilter } from 'fuzzbunny';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { Input } from 'components/Input';
import { ButtonLink } from 'components/Links';
import { Table } from 'components/Table';

import { coreApiFetch } from 'utils/api';
import { formatDate, formatHumanDate } from 'utils/formatting';
import { routes } from 'utils/routes';
import { typedColumn } from 'utils/tables';

const columns: ColumnType<UserDTO>[] = [
  typedColumn({
    title: 'User ID',
    dataIndex: 'id',
    render: (id: string) => <Link to={routes.internal.userDetails(id)}>{id}</Link>,
  }),
  typedColumn({
    title: 'First Name',
    dataIndex: 'firstName',
  }),
  typedColumn({
    title: 'Last Name',
    dataIndex: 'lastName',
  }),
  typedColumn({
    title: 'Email',
    dataIndex: 'email',
  }),
  typedColumn({
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (date) => formatDate(date),
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    sortDirections: ['descend', 'ascend'],
  }),
  typedColumn({
    title: 'Updated At',
    dataIndex: 'updatedAt',
    render: (date) => formatDate(date),
    sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
    sortDirections: ['descend', 'ascend'],
  }),
  typedColumn({
    title: 'Last Welcomed',
    render: (_, user) =>
      'emailEvents' in user &&
      user.emailEvents &&
      user.emailEvents.length > 0 &&
      formatHumanDate(user.emailEvents[user.emailEvents.length - 1].createdAt),
  }),
  {
    render: ({ id }) => ButtonLink(routes.internal.userDetails(id)),
  },
];

export const UsersTable = ({ users, isLoading }: { users: UserDTO[]; isLoading: boolean }) => {
  const [query, setQuery] = useState('');
  const [invitedBy, setInvitedBy] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  let filteredUsers: { firstName: string; lastName: string; email: string }[] = [];
  if (users) {
    filteredUsers = fuzzyFilter(users, query, {
      fields: ['firstName', 'lastName', 'email', 'id'],
    }).map(({ item }) => item);
  }

  const sendWelcomeEmails = async () => {
    // Send welcome emails to each selected user

    await Promise.allSettled(
      selectedRowKeys.map((key) =>
        coreApiFetch(schemas.users.sendWelcomeEmail, {
          bodyParams: {
            id: key,
            invitedBy,
          },
        }),
      ),
    ).then((results) => {
      // Only keep the failed emails selected
      const mask = results.map((result) => result.status === 'rejected');
      setSelectedRowKeys(selectedRowKeys.filter((_, i) => mask[i]));
      // Show message based on result
      if (selectedRowKeys.filter((_, i) => mask[i]).length === 0) {
        notification.success({ message: 'Welcome emails sent!' });
      } else {
        notification.error({
          message: `${
            selectedRowKeys.filter((_, i) => mask[i]).length
          } welcome emails failed. All currently selected users did not receive emails.`,
        });
      }
    });
  };

  return (
    <>
      <FilterBarBox>
        <Input
          placeholder="Search Users"
          title="search"
          onChange={(ev) => setQuery(ev.target.value)}
          style={{ maxWidth: '300px' }}
        />
        <FlexSpacer />
        <AsyncButton type="primary" onClick={sendWelcomeEmails} disabled={selectedRowKeys.length === 0}>
          Send Welcome Email ({selectedRowKeys.length} users selected)
        </AsyncButton>
        <Input
          placeholder="Welcome Email Invited By"
          title="invitedBy"
          onChange={(ev) => setInvitedBy(ev.target.value)}
          style={{ maxWidth: '200px' }}
          disabled={selectedRowKeys.length === 0}
        />
        <Button type="primary">
          <Link to={routes.internal.userNew()}>
            <PlusOutlined /> New User
          </Link>
        </Button>
      </FilterBarBox>
      <Table columns={columns} data={filteredUsers} rowKey="id" isLoading={isLoading} rowSelection={rowSelection} />
    </>
  );
};
