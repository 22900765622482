import React from 'react';

import { css } from '@emotion/css';
import { colors } from 'theme/colors';

export function DividerLine({ marginTop = 20, marginBottom = 20 }: { marginTop?: number; marginBottom?: number }) {
  return (
    <div
      className={css`
        margin: ${marginTop}px 0 ${marginBottom}px 0;
        border-top: 1px solid ${colors.neutral[300]};
      `}
    />
  );
}
