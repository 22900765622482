import React, { useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import { Alert, Checkbox, notification } from 'antd';
import { theme } from 'theme';

import { AsyncButton } from 'components/Button/AsyncButton';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';

import './styles/NewPaymentMethodStyle.css';
import { PaymentInfo, PaymentMethodOption, customerPaymentMethodToOption } from './PaymentInfoModal';

const checkboxStyles = css`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
`;

const labelStyles = css`
  font-size: '14px';
`;

const hrStyles = css`
  border: none;
  border-top: 2px dashed #ccc;
  color: #aaa;
  background-color: #eee;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;

export interface CheckoutFormProps {
  paymentInfo: PaymentInfo;
  onCancel: () => void;
  onSuccess: (paymentMethodOption: PaymentMethodOption) => void;
}

export function CheckoutForm({ paymentInfo, onCancel, onSuccess }: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [showAddress, setShowAddress] = useState(false);

  const [message, setMessage] = useState('');
  const [saveForFuture, setSaveForFuture] = useState(true);

  const { data: customerDetails, isLoading } = useCoreApi(schemas.customers.getCustomerDetails, {
    pathParams: { customerId: paymentInfo.customerId },
  });

  // TODO: pre-fill the payment method info via onSuccess
  const handleSavePayment = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      notification.error({
        message: 'Page has not yet loaded, try again in a second.',
      });
      return;
    }

    elements.submit(); // submit the payment method form
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // creating the payment method.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message || 'An unexpected error occurred.');
      } else {
        setMessage('An unexpected error occurred.');
      }
      notification.error({
        message: error.message || 'An unexpected error occurred.',
        duration: 30,
      });
    } else if (paymentMethod) {
      const response = await coreApiFetch(schemas.payments.getPaymentMethodById, {
        pathParams: { paymentMethodId: paymentMethod.id },
      });

      const newlyCreatedPaymentMethod = customerPaymentMethodToOption(response.data, saveForFuture);
      notification.success({
        message: `${newlyCreatedPaymentMethod.value} saved successfully`,
        description: saveForFuture ? `Also saved for future use.` : `For this order only.`,
      });
      onSuccess(newlyCreatedPaymentMethod);
    }
  };

  return (
    <form id="payment-form">
      {message && (
        <Alert
          message={message}
          closable
          banner
          type="error"
          className={css`
            margin-bottom: 8px;
            border: 1px solid ${theme.colors.danger[500]};
            border-radius: 4px;
          `}
        />
      )}
      <Checkbox checked={showAddress} onChange={(e) => setShowAddress(e.target.checked)} className={checkboxStyles}>
        <span className={labelStyles}>Card Billing Address</span>
      </Checkbox>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {showAddress && customerDetails && (
            <>
              <AddressElement
                options={{
                  mode: 'billing',
                  allowedCountries: ['US'],
                  blockPoBox: true,
                  defaultValues: {
                    name: customerDetails?.billToContact?.contactName || '',
                    address: {
                      line1: customerDetails?.billToAddress?.address1 || '',
                      city: customerDetails?.billToAddress?.city || '',
                      state: customerDetails?.billToAddress?.state || '',
                      postal_code: customerDetails?.billToAddress?.postalCode || '',
                      country: 'US',
                    },
                  },
                }}
              />
              <hr className={hrStyles} />
            </>
          )}
        </>
      )}
      <PaymentElement id="card-element" />
      <Checkbox
        checked={saveForFuture}
        onChange={(e) => setSaveForFuture(e.target.checked)}
        className={checkboxStyles}
      />
      Save for future payments
      <br />
      <AsyncButton
        onClick={handleSavePayment}
        type="primary"
        id="submit-auth"
        disabled={!stripe || !elements}
        className={css`
          display: block;
          width: 100%;
        `}
      >
        Save
      </AsyncButton>
      <br />
      <AsyncButton
        onClick={onCancel}
        disabled={!stripe || !elements}
        className={css`
          display: block;
          width: 100%;
          margin-top: 12px;
        `}
        id="cancel-auth"
      >
        Cancel
      </AsyncButton>
    </form>
  );
}
