import { defaultConfig, TenantConfig } from './default';

export function getConfig(): TenantConfig {
  const config = { ...defaultConfig };
  const { formConfig: quoteOrderFormConfig } = config.quoteOrderEntryFlow;
  const { formConfig: itemFormConfig } = config.itemEntryFlow;
  quoteOrderFormConfig.isQuoteForOrder.visible = true;
  itemFormConfig.class2.defaultValue = {
    foreignId: 'SPCLORDR',
    name: 'Special Order',
  };
  itemFormConfig.supplierPartNo.required = true;
  return config;
}
