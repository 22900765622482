import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { TenantIntegrationStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantDefaultDataModulesPayload } from '@recurrency/core-api-schema/dist/tenants/putUpdateTenantDefaultData';
import { notification } from 'antd';

import { NotFoundPage } from 'pages/NotFoundPage';

import { Container } from 'components/Container';
import { PageHeader } from 'components/PageHeader';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';

import { FormDefaultSettings } from './FormDefaultSettings';

export const LegacySalesSettingsPage = () => {
  const { activeTenant } = useGlobalApp();
  const onDefaultDataChange = async (newDefaultData: TenantDefaultDataModulesPayload) => {
    const request = { defaultData: newDefaultData };
    try {
      await coreApiFetch(schemas.tenants.putUpdateTenantDefaultData, {
        bodyParams: request,
        pathParams: { tenantId: activeTenant.id },
      });
      notification.success({ message: 'Default data updated successfully, refresh page to see them in action.' });
    } catch (err) {
      captureAndShowError(err, 'Error while updating default data');
    }
  };

  const hasPendingIntegration = activeTenant.integrations.some((i) => i.status === TenantIntegrationStatus.Created);
  if (hasPendingIntegration) return <NotFoundPage />;

  return (
    <Container>
      <PageHeader title="Settings" />
      <FormDefaultSettings defaultData={activeTenant.defaultData} onDefaultDataChange={onDefaultDataChange} />
    </Container>
  );
};
