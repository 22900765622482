import React from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { breakpoints } from 'theme/breakpoints';

import { SplitPage } from 'components/SplitPage';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { LocalStorageKey, useLocalStorage } from 'utils/localStorage';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';

export interface TableSplitPageProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const TableSplitPage = ({ left, right }: TableSplitPageProps): JSX.Element => {
  const parentRef = React.createRef<HTMLDivElement>();
  const [isSidePaneExpandedLocalStorage] = useLocalStorage(LocalStorageKey.SidePane_Expanded, false);
  const [isSidePaneExpanded, setIsSidePaneExpanded] = React.useState(isSidePaneExpandedLocalStorage);

  const { activeTenant, activeUser } = useGlobalApp();
  const shouldShowStickyHeader = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeatureStickyTableHeaders,
  );

  // Listen for changes to the side pane and update isSidePaneExpanded
  React.useLayoutEffect(() => {
    if (shouldShowStickyHeader) {
      const observer = new MutationObserver(() => {
        setIsSidePaneExpanded(!!parentRef.current?.querySelector('.SidePane-expanded'));
      });

      observer.observe(parentRef.current as Node, {
        subtree: true,
        childList: true,
      });
      return () => observer.disconnect();
    }
    return () => {};
  }, [parentRef, shouldShowStickyHeader]);

  const sidePaneWidth = isSidePaneExpanded ? 400 : 60;

  // Fallback to old SplitPage if stickyHeader prop is not enabled
  if (!shouldShowStickyHeader) return <SplitPage left={left} right={right} />;

  return (
    <div
      ref={parentRef}
      className={css`
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      `}
    >
      <div
        className={css`
          width: calc(100% - ${sidePaneWidth + 20}px);
          ${breakpoints.tablet} {
            width: 100%;
          }
        `}
      >
        {left}
      </div>
      <div
        className={css`
          width: ${sidePaneWidth}px;

          ${breakpoints.tablet} {
            ${isSidePaneExpanded ? 'width: 100%;' : ''}
            .SidePane-expanded {
              width: 100%;
            }
          }
        `}
      >
        {right}
      </div>
    </div>
  );
};
