import React from 'react';

import { css, cx } from '@emotion/css';
import { theme } from 'theme';

export type TextType = 'h1' | 'h2' | 'h3' | 'large' | 'middle' | 'subtitle' | 'small' | 'extrasmall';
export type Align = 'left' | 'center' | 'right';

export interface StyledTypographyProps {
  type?: TextType;
  copyable?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const textStyles = {
  h1: `
    font-weight: 500;
    font-size: 56px;
    line-height: 65px;
  `,
  h2: `
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
  `,
  h3: `
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  `,
  large: `
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  `,
  middle: `
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  `,
  subtitle: `
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  `,
  small: `
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  `,
  extrasmall: `
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
  `,
};

export const StyledTypography = ({
  className,
  type = 'middle',
  copyable,
  ...rest
}: StyledTypographyProps & { className?: string }) => (
  <p
    className={cx(
      css`
        ${textStyles[type]}
        color: ${theme.colors.neutral[900]};
        cursor: ${copyable ? 'pointer' : 'inherit'};
        margin-bottom: 0;
        &:hover {
          background: ${copyable ? theme.colors.neutral[200] : 'transparent'};
        }
      `,
      className,
    )}
    {...rest}
  />
);
