import React from 'react';

import { css } from '@emotion/css';
import { Progress } from 'antd';
import { colors } from 'theme/colors';

import { formatPercent } from 'utils/formatting';

export function TargetProgressBar({ percent }: { percent: number }) {
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        gap: 8px;
      `}
    >
      <Progress type="line" percent={percent * 100} showInfo={false} strokeColor={colors.primary[600]} />
      <span>{formatPercent(percent, 0)}</span>
    </div>
  );
}
