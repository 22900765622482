import React from 'react';

import { CompassOutlined } from '@ant-design/icons';
import { CustomerType, TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { TenantUserErpRoleDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';
import { filterNav, upperNavModules } from 'App/frames/Navigation/navModulesData';

import { ExtendedUser } from 'hooks/useGlobalApp';

import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { MenuOption } from './types';

interface NavCategoryMenuOption extends MenuOption {
  featureFlag?: TenantFeatureFlag;
  onSelect?: () => void;
}
interface NavCategoryConf {
  label: string;
  options: NavCategoryMenuOption[];
}

export function getNavigationOptions(
  activeErpRole: Maybe<TenantUserErpRoleDTO>,
  activeTenant: TenantDTO,
  activeUser: ExtendedUser,
): MenuOption[] {
  const navSubModulesBySlug: Obj<NavCategoryConf> = Object.fromEntries(
    filterNav(upperNavModules, activeTenant, activeUser, activeErpRole).map((navModule) => [
      navModule.slug,
      {
        label: navModule.label,
        options: navModule.subModules.map((subModule) => ({
          label: subModule.label,
          href: subModule.routeFn(),
        })),
      },
    ]),
  );

  if (navSubModulesBySlug.purchasing) {
    navSubModulesBySlug.purchasing.options.push({
      label: 'New Purchase Order',
      href: routes.purchasing.purchaseOrderNew(),
      featureFlag: TenantFeatureFlag.PurchasingCreatePurchaseOrder,
      onSelect: () =>
        track(TrackEvent.Nav_CtaClick, {
          ctaName: CtaName.NewPurchaseOrder,
          ctaType: CtaType.GlobalSearch,
        }),
    });
  }
  if (navSubModulesBySlug.sales) {
    navSubModulesBySlug.sales.options.push(
      {
        label: 'New Prospect',
        href: routes.sales.customerNew(),
        featureFlag: TenantFeatureFlag.SalesCreateProspect,
        onSelect: () =>
          track(TrackEvent.Nav_CtaClick, {
            ctaName: CtaName.NewProspect,
            ctaType: CtaType.GlobalSearch,
          }),
      },
      {
        label: 'New Customer',
        href: routes.sales.customerNew({ type: CustomerType.Customer }),
        featureFlag: TenantFeatureFlag.SalesCreateCustomer,
        onSelect: () =>
          track(TrackEvent.Nav_CtaClick, {
            ctaName: CtaName.NewCustomer,
            ctaType: CtaType.GlobalSearch,
          }),
      },
    );
  }
  if (navSubModulesBySlug.orders) {
    navSubModulesBySlug.orders.options.push(
      {
        label: 'New Order',
        href: routes.orders.orderNew(),
        featureFlag: TenantFeatureFlag.OrdersCreateOrder,
        onSelect: () =>
          track(TrackEvent.Nav_CtaClick, {
            ctaName: CtaName.NewOrder,
            ctaType: CtaType.GlobalSearch,
          }),
      },
      {
        label: 'New Quote',
        href: routes.orders.quoteNew(),
        featureFlag: TenantFeatureFlag.OrdersCreateQuote,
        onSelect: () =>
          track(TrackEvent.Nav_CtaClick, {
            ctaName: CtaName.NewQuote,
            ctaType: CtaType.GlobalSearch,
          }),
      },
    );
  }

  return Object.values(navSubModulesBySlug)
    .map((conf) =>
      // add label as subtext and give all options compass icon
      conf.options
        .filter((option) =>
          option.featureFlag ? shouldShowFeatureFlag(activeTenant, activeUser, option.featureFlag) : true,
        )
        .map((option) => ({
          subtext: conf.label,
          icon: React.createElement(CompassOutlined),
          ...option,
        })),
    )
    .flat();
}
