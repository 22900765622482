import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  GetInventoryDashboardQueryParams,
  InventoryDashboardGroupBy,
} from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import { Empty } from 'antd';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  Tooltip as RechartsTooltip,
  Label,
  Bar,
  TooltipProps,
} from 'recharts';
import { theme } from 'theme';

import { SmallLoader } from 'components/Loaders';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

import { dashesIfEmpty, formatNumber, formatUSD } from 'utils/formatting';

import { DisplayUnit } from 'types/hash-state';

import { IconReferencDot } from './IconReferenceDot';

const viewValue = (displayUnit: DisplayUnit) => displayUnit === 'value';

const InteractiveChartLoader = ({ height }: { height: number }) => (
  <div
    className={css`
      height: ${height}px;
    `}
  >
    <SmallLoader
      extraCss={css`
        height: ${height - 24}px;
      `}
    />
  </div>
);

export const noneIfEmpty = (value: Maybe<string>) =>
  value === '' || value === undefined || value === null ? 'None' : value;

function CustomizedAxisTick({
  onClick,
  ...props
}: {
  onClick: (value: string) => void;
  x: number;
  y: number;
  payload: any;
}) {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill={theme.colors.primary[500]}
        fontWeight={500}
        cursor="pointer"
        onClick={() => onClick(noneIfEmpty(payload.value))}
      >
        {dashesIfEmpty(payload.value)}
      </text>
    </g>
  );
}

export interface InteractiveBarChartProps<FilterEnumT> {
  displayUnit: DisplayUnit;
  groupBy: InventoryDashboardGroupBy;
  queryParams: GetInventoryDashboardQueryParams;
  label?: string;
  filterKey: keyof FilterEnumT;
  onUpdateState: (filterName: keyof FilterEnumT, value: string, reset?: boolean) => void;
  onClearState: (filterName: keyof FilterEnumT) => void;
}

export function InteractiveBarChart<FilterEnumT>({
  displayUnit,
  groupBy,
  queryParams,
  label,
  filterKey,
  onUpdateState,
  onClearState,
}: InteractiveBarChartProps<FilterEnumT>) {
  const { isLoading, data, error } = useCoreApi(schemas.inventory.getInventoryDashboardStatusBreakdown, {
    queryParams: { ...queryParams, limit: 20, groupBy },
  });

  const CustomTooltipContent = (e: TooltipProps) => {
    const payload = e.payload?.filter((payloadx) => payloadx.name !== 'connection');
    if (e.active && payload && payload.length) {
      return (
        <div
          className={css`
            background-color: rgba(255, 255, 255, 0.9);
            font-weight: 500;
            border-radius: 8px;
            border: 2px solid ${theme.colors.neutral[500]};
          `}
        >
          <div
            className={css`
              padding: 6px;
            `}
          >
            <Typography type="subtitle" style={{ color: theme.colors.neutral[800], textAlign: 'center' }}>
              ID: {dashesIfEmpty(payload[0].payload?.id)}
            </Typography>
            {payload.map((row, index) => (
              <div
                className={css`
                  display: block;
                `}
                key={`line-row-${index}`}
              >
                <div
                  className={css`
                    display: inline-flex;
                    align-items: center;
                  `}
                >
                  <IconReferencDot color={row.color as string} radius={4} />
                  <div
                    className={css`
                      display: block;
                      margin-left: 2px;
                    `}
                  >
                    {viewValue(displayUnit) ? formatUSD(String(row.value)) : formatNumber(String(row.value))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };
  return (
    <div
      className={css`
        border: 1px solid ${theme.colors.neutral[300]};
        border-radius: 8px;
        padding: 8px;
        min-width: 300px;
        width: 100%;
        box-shadow: 2px 2px 4px 0px ${theme.colors.neutral[100]};
        overflow: hidden;
      `}
    >
      <ResponsiveContainer minWidth={200} height={300}>
        {!isLoading && !error && data ? (
          <BarChart
            width={200}
            height={200}
            data={data.items}
            barGap={2}
            barCategoryGap="5%"
            margin={{
              bottom: 18,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="id"
              tick={(props) => <CustomizedAxisTick onClick={(value) => onUpdateState(filterKey, value)} {...props} />}
            >
              <Label value={label} offset={4} position="bottom" />
            </XAxis>
            <RechartsTooltip content={<CustomTooltipContent />} cursor={false} />
            <Bar
              dataKey={`normalstock.${viewValue(displayUnit) ? 'value' : 'volume'}`}
              name="Normal"
              minPointSize={data.items.length > 1 ? 0 : 5}
              background={false}
              isAnimationActive={false}
              stackId={data.items.length > 1 ? 'a' : undefined}
              cursor={data.items.length > 1 ? 'zoom-in' : 'zoom-out'}
              fill={theme.colors.chart.oceanTeal}
              onClick={
                data.items.length > 1
                  ? (props) => onUpdateState(filterKey, noneIfEmpty(props.payload.id))
                  : () => onClearState(filterKey)
              }
            />
            <Bar
              dataKey={`overstock.${viewValue(displayUnit) ? 'value' : 'volume'}`}
              name="Overstock"
              minPointSize={data.items.length > 1 ? 0 : 5}
              background={false}
              isAnimationActive={false}
              stackId={data.items.length > 1 ? 'a' : undefined}
              cursor={data.items.length > 1 ? 'zoom-in' : 'zoom-out'}
              fill={theme.colors.chart.sunriseYellow}
              onClick={
                data.items.length > 1
                  ? (props) => onUpdateState(filterKey, noneIfEmpty(props.payload.id))
                  : () => onClearState(filterKey)
              }
            />
            <Bar
              dataKey={`deadstock.${viewValue(displayUnit) ? 'value' : 'volume'}`}
              name="Dead Stock"
              minPointSize={data.items.length > 1 ? 1 : 5}
              background={false}
              isAnimationActive={false}
              stackId={data.items.length > 1 ? 'a' : undefined}
              cursor={data.items.length > 1 ? 'zoom-in' : 'zoom-out'}
              fill={theme.colors.danger[500]}
              onClick={
                data.items.length > 1
                  ? (props) => onUpdateState(filterKey, noneIfEmpty(props.payload.id))
                  : () => onClearState(filterKey)
              }
            />
          </BarChart>
        ) : error || (!data && !isLoading) ? (
          <div
            className={css`
              width: 100%;
              min-width: 260px;
              height: 100%;
              min-height: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Empty />
          </div>
        ) : (
          <InteractiveChartLoader height={200} />
        )}
      </ResponsiveContainer>
    </div>
  );
}
