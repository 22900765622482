import React from 'react';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { IntegratedErps, SortDirection } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import {
  GetInventoryReportingQueryParams,
  InventoryDashboardGroupBy,
} from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import {
  InventoryDashboardRecordDTO,
  InventoryDashboardReportRecordDTO,
} from '@recurrency/core-api-schema/dist/inventory/inventoryDashboardResultDTO';
import { Col, Divider, Empty, Radio, Row, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { ColumnsType, SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';
import {
  Line,
  LineChart,
  ReferenceLine,
  ReferenceLineProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme';
import { fontWeights } from 'theme/typography';
import { ZipCelXSheet } from 'zipcelx';

import { useCompaniesSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Card, CardHeader, CardSection } from 'components/Card';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { CenteredError, SmallLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { RadioGroup } from 'components/Radio';
import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from 'components/recipes/DownloadButton';
import { Select, SelectOption } from 'components/Select';
import { Table } from 'components/Table';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch, fetchAllRecordsInBatches } from 'utils/api';
import { formatNumber, formatPercent, formatUSD, formatUSDAbbreviated } from 'utils/formatting';
import { objKeys } from 'utils/object';
import { routes, useHashState } from 'utils/routes';
import { sortDirections } from 'utils/tables';
import { isTenantErpTypeNetSuite } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';

import {
  InventoryDashboardDetailsHashState,
  InventoryDashboardHashStateFilters,
  InventoryExplorerCompareFromOptions,
  InventoryExplorerCompareToOptions,
} from 'types/hash-state';

import { DrillDownChartConfig } from '../DrillDownChartConfig';
import { DashboardUnitSelect } from '../shared/DashboardUnitSelect';
import { IconReferencDot } from '../shared/IconReferenceDot';
import { InventoryDashboardFilters } from '../shared/InventoryDashboardFilters';

const pageSize = 10;
type CompareRecordKey = keyof InventoryDashboardRecordDTO;

const groupByOptions: Record<InventoryDashboardGroupBy, string> = {
  [InventoryDashboardGroupBy.Location]: 'Location',
  [InventoryDashboardGroupBy.Stockable]: 'Stockable',
  [InventoryDashboardGroupBy.PurchaseClass]: 'ABC Class',
  [InventoryDashboardGroupBy.Supplier]: 'Supplier',
  [InventoryDashboardGroupBy.ItemGroup]: 'Product Group',
  [InventoryDashboardGroupBy.Item]: 'Item',
  [InventoryDashboardGroupBy.DemandPattern]: 'Demand Pattern',
  [InventoryDashboardGroupBy.DemandPredictability]: 'Demand Predictability',
};

const compareFromDates = (hashStateData: InventoryDashboardDetailsHashState): string[] => {
  const option: InventoryExplorerCompareFromOptions = hashStateData.compareFrom;
  const startOfMonth = moment().startOf('month');
  return option === InventoryExplorerCompareFromOptions.LastMonth
    ? [moment(startOfMonth).subtract(1, 'month').toISOString()]
    : option === InventoryExplorerCompareFromOptions.LastThreeMonths
    ? [1, 2, 3].map((lookback) => moment(startOfMonth).subtract(lookback, 'month').toISOString())
    : [1, 2, 3, 4, 5, 6].map((lookback) => moment(startOfMonth).subtract(lookback, 'month').toISOString());
};

const compareToDates = (hashStateData: InventoryDashboardDetailsHashState): string[] => {
  const fromDates = compareFromDates(hashStateData)
    .map((a) => moment(a))
    .sort((a, b) => b.valueOf() - a.valueOf());

  const offset = hashStateData.compareTo === InventoryExplorerCompareToOptions.PriorYear ? 12 : fromDates.length;
  const singleOffsetType = hashStateData.compareTo === InventoryExplorerCompareToOptions.PriorYear ? 'year' : 'month';
  const dates =
    fromDates.length === 1
      ? [moment(fromDates[0]).subtract(1, singleOffsetType).toISOString()]
      : new Array(fromDates.length)
          .fill(1)
          .map((v, i) => i)
          .map((lookback) =>
            moment(fromDates[0])
              .subtract(offset + lookback, 'months')
              .toISOString(),
          );
  return dates;
};

const tooltipDates = (dates: string[]) =>
  dates.length === 1
    ? moment(dates[0]).format('MMMM YYYY')
    : `${moment(dates[dates.length - 1]).format('MMMM YYYY')} - ${moment(dates[0]).format('MMMM YYYY')}`;

const compareColumnName = (
  toOrFrom: 'compareFrom' | 'compareTo',
  hashStateData: InventoryDashboardDetailsHashState,
) => {
  const fromDates = compareFromDates(hashStateData);
  const toDates = compareToDates(hashStateData);
  const label = {
    [InventoryExplorerCompareFromOptions.LastMonth]: 'Last Month',
    [InventoryExplorerCompareFromOptions.LastThreeMonths]: 'Last 3 Months',
    [InventoryExplorerCompareFromOptions.LastSixMonths]: 'Last 6 Months',
    [InventoryExplorerCompareToOptions.PriorMonth]: 'Prior Month',
    [InventoryExplorerCompareToOptions.PriorThreeMonths]: 'Prior 3 Months',
    [InventoryExplorerCompareToOptions.PriorSixMonths]: 'Prior 6 Months',
    [InventoryExplorerCompareToOptions.PriorYear]: 'Prior Year',
  }[toOrFrom === 'compareFrom' ? hashStateData.compareFrom : hashStateData.compareTo];

  return (
    <div
      className={css`
        padding-right: 4px;
      `}
    >
      {toOrFrom === 'compareFrom' ? (
        <InfoTooltip title={tooltipDates(fromDates)}>{label}</InfoTooltip>
      ) : (
        <InfoTooltip title={tooltipDates(toDates)}>{label}</InfoTooltip>
      )}
    </div>
  );
};

export const HistoricalInventoryExplorerPage = () => {
  const history = useHistory();
  const { activeTenant } = useGlobalApp();
  const { md: isDesktop } = useBreakpoint();

  const [hashState, updateHashState] = useHashState<InventoryDashboardDetailsHashState>();

  if (!hashState || !hashState.chartSource) {
    requestAnimationFrame(async () => {
      updateHashState(DrillDownChartConfig.inventory.hashStateConfig);
    });
  }

  const onHashStateFilterChange = (filters: InventoryDashboardHashStateFilters) => {
    updateHashState({ filters, page: 1 });
  };

  const activeKey = (valueKey: CompareRecordKey, volumeKey: CompareRecordKey) =>
    hashState.displayUnit === 'volume' ? volumeKey : valueKey;
  interface ValueVolumeConfiguration {
    key: CompareRecordKey;
    formatter: (value: Maybe<string | number>) => string;
    tickFormatter: (value: Maybe<string | number>) => string;
    textSymbol?: '$' | '%' | '';
  }

  interface ValueVolumeConfigurationSet {
    value: ValueVolumeConfiguration;
    volume: ValueVolumeConfiguration;
  }

  type ConfigTypes = keyof typeof DrillDownChartConfig;
  const dateToCategory = (date: string) => moment(date).format('MMM YY');
  const valueVolumeMap: Record<ConfigTypes, ValueVolumeConfigurationSet> = {
    inventory: {
      value: {
        key: 'inventoryValue',
        formatter: formatUSD,
        tickFormatter: (value) => formatUSDAbbreviated(value as number, 0),
        textSymbol: '$',
      },
      volume: {
        key: 'inventoryVolume',
        formatter: formatNumber,
        tickFormatter: (value) => formatNumber(value, 2, undefined, { notation: 'compact', useGrouping: true }),
        textSymbol: '',
      },
    },
    sales: {
      value: {
        key: 'sales',
        formatter: formatUSD,
        tickFormatter: (value) => formatUSDAbbreviated(value as number, 0),
        textSymbol: '$',
      },
      volume: {
        key: 'salesVolume',
        formatter: formatNumber,
        tickFormatter: (value) => formatNumber(value, 2, undefined, { notation: 'compact', useGrouping: true }),
        textSymbol: '',
      },
    },
    backorders: {
      value: {
        key: 'backorderValue',
        formatter: formatUSD,
        tickFormatter: (value) => formatUSDAbbreviated(value as number, 0),
        textSymbol: '$',
      },
      volume: {
        key: 'backorderVolume',
        formatter: formatNumber,
        tickFormatter: (value) => formatNumber(value, 2, undefined, { notation: 'compact', useGrouping: true }),
        textSymbol: '',
      },
    },
    cost: {
      value: {
        key: 'cost',
        formatter: formatUSD,
        tickFormatter: (value) => formatUSDAbbreviated(value as number, 0),
        textSymbol: '$',
      },
      volume: {
        key: 'cost',
        formatter: formatUSD,
        tickFormatter: (value) => formatUSDAbbreviated(value as number, 0),
        textSymbol: '$',
      },
    },
    grossMarginROI: {
      value: {
        key: 'grossMarginROI',
        formatter: formatNumber,
        tickFormatter: (value) => formatNumber(value as number, 2),
        textSymbol: '',
      },
      volume: {
        key: 'grossMarginROI',
        formatter: formatNumber,
        tickFormatter: (value) => formatNumber(value as number, 2),
        textSymbol: '',
      },
    },
    fillRate: {
      value: {
        key: 'fillRate',
        formatter: (value) => formatPercent((value as number) / 100),
        tickFormatter: (value) => formatPercent((value as number) / 100),
        textSymbol: '',
      },
      volume: {
        key: 'fillRate',
        formatter: (value) => formatPercent((value as number) / 100),
        tickFormatter: (value) => formatPercent((value as number) / 100),
        textSymbol: '',
      },
    },
    turns: {
      value: {
        key: 'turns',
        formatter: formatNumber,
        tickFormatter: (value) => formatNumber(value as number, 2),
        textSymbol: '',
      },
      volume: {
        key: 'turns',
        formatter: formatNumber,
        tickFormatter: (value) => formatNumber(value as number, 2),
        textSymbol: '',
      },
    },
  };

  const darkLineColors = Object.keys(theme.colors.chart)
    .filter((key) => !key.toLowerCase().includes('light'))
    .map((key: string) => theme.colors.chart[key as keyof typeof theme.colors.chart]);
  const lightLineColors = Object.keys(theme.colors.chart)
    .filter((key) => key.toLowerCase().includes('light'))
    .map((key: string) => theme.colors.chart[key as keyof typeof theme.colors.chart]);
  const lineColors = darkLineColors.concat(lightLineColors);
  const indexColor = (indexNum: number) => lineColors[indexNum % lineColors.length];

  const referenceLineYears = [0, 1, 2].map((offset) => moment().startOf('year').subtract(offset, 'year'));
  const startOfYearReferenceLineProps: ReferenceLineProps[] = referenceLineYears.map((momentObj) => ({
    x: momentObj.format('MMM YY'),
    stroke: theme.colors.neutral[400],
    strokeDasharray: '3 3',
  }));

  const groupBy = hashState.groupBy ?? InventoryDashboardGroupBy.Location;

  const apiQueryParams: GetInventoryReportingQueryParams = {
    filters: {
      companyIds: hashState.filters?.companyIds,
      locationIds: hashState.filters?.locationIds,
      itemIds: hashState.filters?.itemIds,
      itemGroupIds: hashState.filters?.itemGroupIds,
      supplierIds: hashState.filters?.supplierIds,
      buyerIds: hashState.filters?.buyerIds,
      purchaseClasses: hashState.filters?.purchaseClasses,
      stockable: hashState.filters?.stockable?.length === 1 ? hashState.filters.stockable[0] === 'true' : undefined,
      demandPattern: hashState.filters?.demandPattern,
      demandPredictability: hashState.filters?.demandPredictability,
    },
    groupBy: hashState.groupBy,
    compareFrom: compareFromDates(hashState),
    compareTo: compareToDates(hashState),
    sortBy: hashState.sortBy,
    limit: pageSize,
    offset: ((hashState.page ?? 1) - 1) * pageSize,
  };

  const { data, error, isLoading } = useCoreApi(schemas.inventory.getInventoryDashboardReporting, {
    queryParams: apiQueryParams,
  });

  const chartData = (records: InventoryDashboardReportRecordDTO[]) => {
    if (records.length < 1) return [];

    const response: Record<string, any> = {};
    records.forEach((record) => {
      const recordHistory = record.history ?? [];
      return recordHistory.forEach((row) => {
        const formattedDate = dateToCategory(row.date);
        const curr = response[formattedDate] ?? { date: formattedDate };
        const { key } = valueVolumeMap[hashState.chartSource][hashState.displayUnit];
        response[formattedDate] = {
          ...curr,
          [getChartKey(record)]: row[key],
        };
      });
    });
    const sortedData = Object.values(response).sort(
      (a, b) => moment(a.date, 'MMM YY').valueOf() - moment(b.date, 'MMM YY').valueOf(),
    );

    return sortedData;
  };

  const getChartKey = (record: InventoryDashboardReportRecordDTO) =>
    `${record.companyId}|${record.groupId}|${record.groupName}`;

  const groupByNameColumn = groupByOptions[groupBy];

  const chartSourceOptions: SelectOption[] = Object.values(DrillDownChartConfig)
    .filter((configObj) => {
      if (configObj.name !== 'backorders') return true;
      return activeTenant.erpType === 'p21';
    })
    .map((configObj) => ({
      label: configObj.title,
      value: configObj.hashStateConfig.chartSource,
    }));

  const groupBySelectOptions: SelectOption[] = objKeys(groupByOptions).map((groupByKey) => ({
    label: groupByOptions[groupByKey],
    value: groupByKey,
  }));

  const itemHistory = data?.items[0]?.history ?? [];
  const historicalMoments = itemHistory.map((row) => moment(row.date)).sort((a, b) => b.valueOf() - a.valueOf());
  const selectDates: SelectOption[] = historicalMoments.map((row) => ({
    label: moment(row).format('MMM YY'),
    value: moment(row).format('YYYY-MM-DD'),
  }));

  const carats = {
    up: (
      <CaretUpFilled
        className={css`
          color: ${theme.colors.success[500]};
        `}
      />
    ),
    down: (
      <CaretDownFilled
        className={css`
          color: ${theme.colors.danger[500]};
        `}
      />
    ),
  };

  const compareFromSelectOptions = [
    {
      label: 'Last Month',
      value: InventoryExplorerCompareFromOptions.LastMonth,
    },
    {
      label: 'Last 3 Months',
      value: InventoryExplorerCompareFromOptions.LastThreeMonths,
    },
    {
      label: 'Last 6 Months',
      value: InventoryExplorerCompareFromOptions.LastSixMonths,
    },
  ];

  const compareToSelectOptions = () => [
    {
      label:
        hashState.compareFrom === InventoryExplorerCompareFromOptions.LastMonth
          ? 'Prior Month'
          : hashState.compareFrom === InventoryExplorerCompareFromOptions.LastThreeMonths
          ? 'Prior 3 Months'
          : 'Prior 6 Months',
      value:
        hashState.compareFrom === InventoryExplorerCompareFromOptions.LastMonth
          ? InventoryExplorerCompareToOptions.PriorMonth
          : hashState.compareFrom === InventoryExplorerCompareFromOptions.LastThreeMonths
          ? InventoryExplorerCompareToOptions.PriorThreeMonths
          : InventoryExplorerCompareToOptions.PriorSixMonths,
    },
    {
      label: 'Prior Year',
      value: InventoryExplorerCompareToOptions.PriorYear,
    },
  ];

  const fullDirection = {
    [SortDirection.Asc]: 'ascend' as SortOrder,
    [SortDirection.Desc]: 'descend' as SortOrder,
  };
  const isSorted = (sortKey: string): SortOrder | undefined => {
    if (!hashState.chartSource) return undefined;
    const defaultSort = DrillDownChartConfig[hashState.chartSource].hashStateConfig.sortBy;
    const hashSort = hashState.sortBy;

    if (hashSort && hashSort.length > 0) {
      return hashSort[0][0] === sortKey ? fullDirection[hashSort[0][1]] : undefined;
    }
    if (defaultSort && defaultSort.length > 0) {
      return defaultSort[0][0] === sortKey ? fullDirection[defaultSort[0][1]] : undefined;
    }
    return undefined;
  };

  // for some group Ids, we will want to render them differently based on the group by type
  // for example, for Items, we want the ID to be link to the Item Details page
  // we can add more options here in the future, but should always default to the groupId in plain text
  const renderGroupId = (record: InventoryDashboardReportRecordDTO) => {
    if (groupBy === InventoryDashboardGroupBy.Item) {
      return <Link to={routes.purchasing.itemDetails(record.groupId)}>{record.groupId}</Link>;
    }
    return <>{record.groupId}</>;
  };

  const idNameColumn: ColumnType<InventoryDashboardReportRecordDTO>[] = [
    {
      key: 'groupId',
      title: 'ID',
      dataIndex: 'groupId',
      align: 'left',
      width: `80px`,
      sortOrder: isSorted(`groupId`),
      sorter: (a, b, sortDirection) =>
        sortDirection === 'ascend'
          ? String(a.groupId).localeCompare(String(b.groupId))
          : String(b.groupId).localeCompare(String(a.groupId)),
      render: (_: string, record: InventoryDashboardReportRecordDTO) => (
        <div>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
            `}
          />
          {renderGroupId(record)}
        </div>
      ),
    },
    {
      key: 'groupName',
      title: groupByNameColumn,
      dataIndex: 'groupName',
      align: 'left',
      width: `320px`,
      sortOrder: isSorted(`groupName`),
      sorter: (a, b, sortDirection) =>
        sortDirection === 'ascend'
          ? String(a.groupName).localeCompare(String(b.groupName))
          : String(b.groupName).localeCompare(String(a.groupName)),
      render: (str: string, _, indexNum) => (
        <div
          className={css`
            display: inline-flex;
            align-items: center;
            min-width: 250px;
          `}
        >
          <IconReferencDot color={indexColor(indexNum)} />
          <div
            className={css`
              display: block;
              margin-left: 4px;
            `}
          >
            {str}
          </div>
        </div>
      ),
    },
  ];

  const nameColumn: ColumnType<InventoryDashboardReportRecordDTO>[] = [
    {
      key: 'groupName',
      title: groupByNameColumn,
      dataIndex: 'groupName',
      sortOrder: isSorted(`groupName`),
      sorter: (a, b) => String(a.groupName).localeCompare(String(b.groupName)),
      sortDirections,
      render: (str: string, _, indexNum: number) => (
        <div
          className={css`
            display: inline-flex;
            align-items: center;
            min-width: 100px;
          `}
        >
          <IconReferencDot color={indexColor(indexNum)} />
          <div
            className={css`
              display: block;
              margin-left: 4px;
            `}
          >
            {str}
          </div>
        </div>
      ),
    },
  ];

  const companyIdColumn: ColumnType<InventoryDashboardReportRecordDTO> = {
    key: 'companyId',
    title: 'Company ID',
    dataIndex: 'companyId',
    sortDirections,
  };

  // Table Column Groups:
  const columnGroup = (
    titleStr: string,
    valueKey: CompareRecordKey,
    volumeKey: CompareRecordKey,
    valueFormatter: (value: number) => string = formatUSD,
    volumeFormatter: (value: number) => string = formatNumber,
    isFirst = false,
  ): ColumnGroupType<InventoryDashboardReportRecordDTO>[] => [
    {
      title: titleStr,
      className: 'group-header',
      children: [
        {
          key: `compareFrom.${activeKey(valueKey, volumeKey)}`,
          title: compareColumnName('compareFrom', hashState),
          dataIndex: `compareFrom`,
          sorter: (a: InventoryDashboardRecordDTO, b: InventoryDashboardRecordDTO, sortDirection: SortOrder) =>
            sortDirection === 'ascend'
              ? Number(a[activeKey(valueKey, volumeKey)]) - Number(b[activeKey(valueKey, volumeKey)])
              : Number(b[activeKey(valueKey, volumeKey)]) - Number(a[activeKey(valueKey, volumeKey)]),
          sortOrder: isSorted(`compareFrom.${activeKey(valueKey, volumeKey)}`),
          sortDirections: isFirst ? ['ascend', 'descend', 'ascend'] : ['ascend', 'descend'],
          // sortOrder: isSorted(`compareFrom.${activeKey(valueKey, volumeKey)}`),
          className: `grouped-column-left ${titleStr.toLowerCase()}-compare-from-column`,
          align: 'right' as AlignSetting,
          render: (_: never, { compareFrom: compareFromData }: InventoryDashboardReportRecordDTO) =>
            hashState.displayUnit === 'volume'
              ? volumeFormatter(compareFromData[volumeKey] as number)
              : valueFormatter(compareFromData[valueKey] as number),
        },
        {
          key: `compareTo.${activeKey(valueKey, volumeKey)}`,
          title: compareColumnName('compareTo', hashState),
          dataIndex: 'compareTo',
          sorter: (a: InventoryDashboardRecordDTO, b: InventoryDashboardRecordDTO, sortDirection: SortOrder) =>
            sortDirection === 'ascend'
              ? Number(a[activeKey(valueKey, volumeKey)]) - Number(b[activeKey(valueKey, volumeKey)])
              : Number(b[activeKey(valueKey, volumeKey)]) - Number(a[activeKey(valueKey, volumeKey)]),
          sortOrder: isSorted(`compareTo.${activeKey(valueKey, volumeKey)}`),
          sortDirections: ['ascend', 'descend'],
          className: `${titleStr.toLowerCase()}-compare-to-column`,
          align: 'right',
          render: (compareToData: InventoryDashboardRecordDTO) =>
            hashState.displayUnit === 'volume'
              ? volumeFormatter(compareToData[volumeKey] as number)
              : valueFormatter(compareToData[valueKey] as number),
        },
        {
          key: `changeValue.${activeKey(valueKey, volumeKey)}`,
          title: '△',
          dataIndex: 'changeValue',
          sorter: (a: InventoryDashboardRecordDTO, b: InventoryDashboardRecordDTO, sortDirection: SortOrder) =>
            sortDirection === 'ascend'
              ? Number(a[activeKey(valueKey, volumeKey)]) - Number(b[activeKey(valueKey, volumeKey)])
              : Number(b[activeKey(valueKey, volumeKey)]) - Number(a[activeKey(valueKey, volumeKey)]),
          sortOrder: isSorted(`changeValue.${activeKey(valueKey, volumeKey)}`),
          sortDirections: ['ascend', 'descend'],
          align: 'right',
          render: (_: never, record: InventoryDashboardReportRecordDTO) => {
            const recordValue = record.changeValue[activeKey(valueKey, volumeKey)] as number;
            const formattedValue =
              hashState.displayUnit === 'volume' ? volumeFormatter(recordValue) : valueFormatter(recordValue);
            return (
              <span>
                {formattedValue} {recordValue > 0 ? carats.up : carats.down}
              </span>
            );
          },
        },
        {
          key: `changePercent.${activeKey(valueKey, volumeKey)}`,
          title: '△ %',
          dataIndex: 'changePercent',
          sorter: (a: InventoryDashboardRecordDTO, b: InventoryDashboardRecordDTO, sortDirection: SortOrder) =>
            sortDirection === 'ascend'
              ? Number(a[activeKey(valueKey, volumeKey)]) - Number(b[activeKey(valueKey, volumeKey)])
              : Number(b[activeKey(valueKey, volumeKey)]) - Number(a[activeKey(valueKey, volumeKey)]),
          sortOrder: isSorted(`changePercent.${activeKey(valueKey, volumeKey)}`),
          sortDirections: ['ascend', 'descend'],
          align: 'right',
          render: (_: never, record: InventoryDashboardReportRecordDTO) => {
            const recordValue = record.changePercent[activeKey(valueKey, volumeKey)] as number;
            const formattedValue = formatPercent(recordValue, 0);
            return (
              <span>
                {formattedValue} {recordValue > 0 ? carats.up : carats.down}
              </span>
            );
          },
        },
      ].filter((child) => {
        if (hashState.changeValue === 'percent') {
          return child.dataIndex !== 'changeValue';
        }
        return child.dataIndex !== 'changePercent';
      }) as ColumnsType<InventoryDashboardReportRecordDTO>,
    },
  ];

  const columnGroupIsFirst = (columnGroupName: string) => {
    const groups = hashState.columnGroups ?? [];
    return groups[0] === columnGroupName;
  };

  const inventoryGroup = columnGroup(
    'Inventory',
    'inventoryValue',
    'inventoryVolume',
    undefined,
    undefined,
    columnGroupIsFirst('inventory'),
  );
  const salesGroup = columnGroup('Sales', 'sales', 'salesVolume', undefined, undefined, columnGroupIsFirst('sales'));
  const salesCostGroup = columnGroup('COGS', 'cost', 'cost', formatUSD, formatUSD, columnGroupIsFirst('cost'));
  const backordersGroup = columnGroup(
    'Backorders',
    'backorderValue',
    'backorderVolume',
    undefined,
    undefined,
    columnGroupIsFirst('backorders'),
  );
  const turnsGroup = columnGroup('Turns', 'turns', 'turns', formatNumber, formatNumber, columnGroupIsFirst('turns'));
  const gmroiGroup = columnGroup(
    'GMROI',
    'grossMarginROI',
    'grossMarginROI',
    formatNumber,
    undefined,
    columnGroupIsFirst('grossMarginROI'),
  );
  const fillRateGroup = columnGroup(
    'Fill Rate',
    'fillRate',
    'fillRate',
    (value: number) => formatPercent(value / 100, 0),
    (value: number) => formatPercent(value / 100, 0),
    columnGroupIsFirst('fillRate'),
  );

  const columnGroupMap: Record<string, ColumnGroupType<InventoryDashboardReportRecordDTO>[]> = {
    inventory: inventoryGroup,
    sales: salesGroup,
    cost: salesCostGroup,
    backorders: backordersGroup,
    turns: turnsGroup,
    grossMarginROI: gmroiGroup,
    fillRate: fillRateGroup,
  };

  const companySelectProps = useCompaniesSelectProps();
  const columns = () => {
    const cols = (hashState.columnGroups ?? ['inventory'])
      .filter((columnGroupName) => !(columnGroupName === 'backorders' && activeTenant.erpType === 'sapb1'))
      .map((columnGroupName: string) => columnGroupMap[columnGroupName]);

    const leadingColumns = {
      [InventoryDashboardGroupBy.Location]: idNameColumn,
      [InventoryDashboardGroupBy.Stockable]: nameColumn,
      [InventoryDashboardGroupBy.PurchaseClass]: nameColumn,
      [InventoryDashboardGroupBy.Supplier]: idNameColumn,
      [InventoryDashboardGroupBy.ItemGroup]: idNameColumn,
      [InventoryDashboardGroupBy.Item]: idNameColumn,
      [InventoryDashboardGroupBy.DemandPattern]: nameColumn,
      [InventoryDashboardGroupBy.DemandPredictability]: nameColumn,
    }[hashState.groupBy ?? InventoryDashboardGroupBy.Location];
    if (companySelectProps.options.length > 1) {
      leadingColumns.push(companyIdColumn);
    }
    return leadingColumns.concat(...cols);
  };

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip
            title="Explore inventory performance metrics over time grouped how you want, filtered to the section you want. Compare between groups and view trends over the time periods of your choice"
            placement="bottom"
          >
            Inventory History Explorer
          </InfoTooltip>
        }
        entity={{ kind: 'Dashboard' }}
        onBack={() =>
          history.push(
            routes.demandPlanning.dashboard({
              filters: hashState.filters,
              displayUnit: hashState.displayUnit,
            }),
          )
        }
        headerActions={
          <DownloadButton
            recordType="Inventory History"
            getDownloadData={() => getDownloadData(apiQueryParams, hashState, activeTenant.erpType)}
          />
        }
      />
      <FlexSpace
        className={css`
          width: 100%;
          margin-bottom: 18px;
        `}
        gap={8}
        wrap
      >
        <div>View </div>
        <Select
          options={chartSourceOptions}
          value={hashState.chartSource}
          className={css`
            width: 175px;
          `}
          size="small"
          onChange={(ev) => {
            const config = DrillDownChartConfig[ev].hashStateConfig;
            updateHashState({ chartSource: ev, columnGroups: config.columnGroups, sortBy: config.sortBy, page: 1 });
          }}
        />
        <div
          className={css`
            padding-left: 8px;
          `}
        >
          {' '}
          grouped by{' '}
        </div>
        <Select
          options={groupBySelectOptions}
          size="small"
          className={css`
            width: 180px;
          `}
          value={groupBy}
          onChange={(newGroupBy) => {
            updateHashState({ groupBy: newGroupBy, page: 1 });
          }}
        />
        <div
          className={css`
            padding-left: 8px;
          `}
        >
          measured in
        </div>
        <DashboardUnitSelect
          state={hashState.displayUnit}
          onChange={(value) => updateHashState({ displayUnit: value })}
        />
      </FlexSpace>
      <InventoryDashboardFilters
        label="Filter"
        filterState={hashState.filters}
        onFiltersChange={onHashStateFilterChange}
        showBuyerFilter={getTenantSetting(TenantSettingKey.UiShowBuyerFilter)}
        showStockableFilter
        showDemandPatternFilter
        showDemandPredictabilityFilter
        showPurchaseClassFilter={!isTenantErpTypeNetSuite(activeTenant.erpType)}
        showProductGroupFilter={!isTenantErpTypeNetSuite(activeTenant.erpType)}
      />
      <Divider
        className={css`
          background-color: ${theme.colors.neutral[300]};
        `}
      />
      <Row gutter={18} wrap={isDesktop ? false : undefined}>
        <Col
          className={css`
            width: 100%;
          `}
        >
          {hashState.chartSource && (
            <Card>
              <CardHeader
                title={DrillDownChartConfig[hashState.chartSource].title}
                description={DrillDownChartConfig[hashState.chartSource].description}
                useTooltip
              />
              <CardSection>
                <ResponsiveContainer
                  width="100%"
                  height={350}
                  className={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  {!isLoading && (!data || data.totalCount < 1) ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : isLoading || !data ? (
                    <SmallLoader />
                  ) : (
                    <LineChart data={chartData(data.items)}>
                      <XAxis dataKey="date" name="month-year" type="category" interval={1} />
                      <YAxis
                        name="value"
                        type="number"
                        tickFormatter={valueVolumeMap[hashState.chartSource][hashState.displayUnit].tickFormatter}
                      />
                      {startOfYearReferenceLineProps?.map((refLineProps, index) => (
                        <ReferenceLine key={index} {...refLineProps} />
                      ))}
                      {data.items.map(getChartKey).map((groupKey, indexNum) => (
                        <Line
                          key={`group-id-${indexNum}`}
                          dataKey={groupKey}
                          stroke={indexColor(indexNum)}
                          strokeWidth={2}
                          dot={false}
                          connectNulls
                        />
                      ))}
                      {selectDates?.map((date, index) => (
                        <ReferenceLine
                          key={`ref-line-${index}`}
                          x={moment(date.value).format('MMM YY')}
                          stroke={theme.colors.neutral[400]}
                          strokeDasharray="3 3"
                          className={css`
                            opacity: 20%;
                          `}
                        />
                      ))}
                      <Tooltip
                        formatter={(value: string | number | Array<string | number>) =>
                          valueVolumeMap[hashState.chartSource][hashState.displayUnit].formatter(value as number)
                        }
                        content={(e: TooltipProps) => {
                          // We render a custom tooltip to remove the connection point between historical and forecasted data
                          const payload = e.payload?.filter((payloadx) => payloadx.name !== 'connection');
                          if (e.active && payload && payload.length) {
                            return (
                              <div
                                className={css`
                                  background-color: rgba(0, 0, 0, 0.7);
                                  border-radius: 8px;
                                  color: white;
                                `}
                              >
                                <div
                                  className={css`
                                    padding: 6px;
                                  `}
                                >
                                  <Typography
                                    className={css`
                                      font-weight: ${fontWeights.bold};
                                      text-align: center;
                                      color: white;
                                      margin-bottom: 4px;
                                    `}
                                  >
                                    {moment(payload[0].payload.date, 'MMM YY').format('MMMM YYYY')}
                                  </Typography>

                                  {/* show in table for alignment */}
                                  <table>
                                    {payload
                                      .sort((a, b) => Number(b.value) - Number(a.value))
                                      .map((row, index) => (
                                        <tr key={`line-row-${index}`}>
                                          <td>
                                            <IconReferencDot color={row.color as string} radius={4} />
                                          </td>
                                          <td
                                            className={css`
                                              padding-right: 8px;
                                            `}
                                          >
                                            {/* getChartKey format is '{companyId}|{groupId}|{groupName}' - we show group name */}
                                            {row.name.split('|')[2]}
                                          </td>
                                          <td>
                                            {valueVolumeMap[hashState.chartSource][hashState.displayUnit].formatter(
                                              row.value as number,
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                  </table>
                                </div>
                              </div>
                            );
                          }
                          return null;
                        }}
                      />
                    </LineChart>
                  )}
                </ResponsiveContainer>
              </CardSection>
            </Card>
          )}
        </Col>
      </Row>
      <div
        className={css`
          width: 100%;
        `}
      >
        <div
          className={css`
            justify-content: space-between;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin: 24px 0;
          `}
        >
          <div>
            <span>Compare: </span>
            <>
              <Select
                className={css`
                  width: 150px;
                `}
                size="small"
                options={compareFromSelectOptions}
                value={hashState.compareFrom}
                onChange={(value: InventoryExplorerCompareFromOptions) =>
                  updateHashState({
                    compareFrom: value,
                    compareTo:
                      value === InventoryExplorerCompareFromOptions.LastMonth
                        ? InventoryExplorerCompareToOptions.PriorMonth
                        : value === InventoryExplorerCompareFromOptions.LastThreeMonths
                        ? InventoryExplorerCompareToOptions.PriorThreeMonths
                        : InventoryExplorerCompareToOptions.PriorSixMonths,
                  })
                }
              />
              <span
                className={css`
                  padding: 0px 6px;
                `}
              >
                to
              </span>
              <Select
                className={css`
                  width: 150px;
                `}
                size="small"
                options={compareToSelectOptions()}
                value={hashState.compareTo}
                onChange={(value: InventoryExplorerCompareToOptions) => updateHashState({ compareTo: value })}
              />
            </>
          </div>
          <div>
            <RadioGroup
              size="middle"
              value={hashState.changeValue}
              onChange={(ev) => {
                updateHashState({ changeValue: ev.target.value });
              }}
            >
              <Radio.Button value="percent">△ %</Radio.Button>
              <Radio.Button value="value">△</Radio.Button>
            </RadioGroup>{' '}
            <span
              className={css`
                font-size: 12px;
                padding-left: 8px;
              `}
            >
              <b>{isLoading ? 0 : data?.totalCount}</b> results
            </span>
          </div>
        </div>
        <div>
          <Table
            columns={columns()}
            data={data?.items || []}
            loading={isLoading}
            size="small"
            rowKey={(record) => `${record.groupId}|${record.companyId}`}
            verticalAlign="center"
            onChange={(_pagination, _filters, sorter, { action }) => {
              const sorterDirection = {
                ascend: SortDirection.Asc,
                descend: SortDirection.Desc,
              };
              if (action === 'sort') {
                const sortArray = Array.isArray(sorter) ? sorter : [sorter];
                const sortBy = sortArray
                  .filter((sortOpt) => sortOpt.order !== undefined)
                  .map((sortOpt): [string, SortDirection] => [
                    sortOpt.columnKey as string,
                    sorterDirection[sortOpt.order ?? 'ascend'],
                  ]);
                updateHashState({
                  sortBy,
                  page: 1,
                });
              }
            }}
            pagination={
              !isLoading &&
              (data?.totalCount ?? 0) > pageSize && {
                onChange: (page) => updateHashState({ page }),
                pageSize,
                simple: true,
                current: hashState.page,
                total: data?.totalCount,
              }
            }
            className={css`
              td.grouped-column-left {
                border-left: 1px solid ${theme.colors.neutral[300]} !important;
              }
              .group-header::before {
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 1.6em;
                background-color: rgba(0, 0, 0, 0.06);
                transform: translateY(-50%);
                transition: background-color 0.3s;
                content: '';
              }
            `}
          />
        </div>
      </div>
    </Container>
  );
};

async function getDownloadData(
  apiQueryParams: GetInventoryReportingQueryParams,
  hashStateData: InventoryDashboardDetailsHashState,
  erpType: IntegratedErps,
): Promise<ZipCelXSheet> {
  const { groupBy }: InventoryDashboardDetailsHashState = hashStateData;
  const records = await fetchAllRecordsInBatches((offset, limit) =>
    coreApiFetch(schemas.inventory.getInventoryDashboardReporting, {
      queryParams: {
        ...apiQueryParams,
        offset,
        limit,
      },
    }),
  );

  const groupByNameColumn = groupByOptions[groupBy ?? 'location'];

  const exportLines = records.map((item) => ({
    groupId: item.groupId,
    groupName: item.groupName,
    compareFrom: item.compareFrom,
    compareTo: item.compareTo,
    changeValue: item.changeValue,
    changePercent: item.changePercent,
  }));

  const compareFromDateText = tooltipDates(compareFromDates(hashStateData));
  const compareToDateText = tooltipDates(compareToDates(hashStateData));

  const exportColumns: Array<DownloadXSheetColumn<typeof exportLines[number]>> = [
    { title: 'ID', type: 'string', value: (record) => record.groupId },
    { title: groupByNameColumn, type: 'string', value: (record) => record.groupName },
  ];

  const valueColumns: Array<DownloadXSheetColumn<typeof exportLines[number]>>[] = [
    'inventoryVolume',
    'inventoryValue',
    'backorderVolume',
    'backorderValue',
    'sales',
    'salesVolume',
    'cost',
    'turns',
    'grossMarginROI',
    'fillRate',
  ]
    .filter((columnKey) => !(erpType === 'sapb1' && columnKey.includes('backorder')))
    .map((columnKey) => {
      const columnTitle = {
        inventoryVolume: 'Inventory Volume',
        inventoryValue: 'Inventory Value',
        sales: 'Sales Value',
        salesVolume: 'Sales Volume',
        cost: 'COGS',
        backorderVolume: 'Backorder Volume',
        backorderValue: 'Backorder Value',
        turns: 'Turns',
        grossMarginROI: 'GMROI',
        fillRate: 'Fill Rate',
      }[columnKey];
      return [
        {
          title: `${columnTitle}: ${compareFromDateText}`,
          type: 'number',
          value: (record) => record.compareFrom[columnKey as CompareRecordKey],
        },
        {
          title: `${columnTitle}: ${compareToDateText}`,
          type: 'number',
          value: (record) => record.compareTo[columnKey as CompareRecordKey],
        },
        {
          title: `${columnTitle}: Change %`,
          type: 'number',
          value: (record) => record.changePercent[columnKey as CompareRecordKey],
        },
        {
          title: `Change Value: ${columnTitle}`,
          type: 'number',
          value: (record) => record.changeValue[columnKey as CompareRecordKey],
        },
      ];
    });

  return recordsToXSheet(exportLines, exportColumns.concat(...valueColumns));
}
