import React from 'react';

import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

import { Input, InputProps } from 'components/Input';

type FormInputProps = FormItemProps & Omit<InputProps, 'name'>;

type ComponentProps = {
  postValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
};

export type Props = ComponentProps & FormInputProps;

export const InputFormItem = ({
  label,
  name,
  rules,
  type,
  help,
  validateStatus,
  disabled,
  defaultValue,
  value,
  getValueFromEvent,
  postValueChange,
  prefix,
  suffix,
  ...otherProps
}: Props): JSX.Element => (
  <Form.Item
    name={name}
    label={label}
    rules={rules}
    help={help}
    validateStatus={validateStatus}
    getValueFromEvent={getValueFromEvent}
  >
    <Input
      type={type}
      disabled={disabled}
      defaultValue={defaultValue}
      value={value}
      onChange={(event) => {
        if (postValueChange) {
          postValueChange(event);
        }
      }}
      prefix={prefix}
      suffix={suffix}
      {...otherProps}
    />
  </Form.Item>
);
