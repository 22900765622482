/** @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping */
export const fontWeights = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const typography = {
  h1: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: fontWeights.semibold,
  },
  h2: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: fontWeights.semibold,
  },
  h3: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: fontWeights.normal,
  },
  h4: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: fontWeights.medium,
  },
  h5: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '1px',
    fontWeight: fontWeights.bold,
  },
  h6: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '1px',
    fontWeight: fontWeights.bold,
  },
  pLarge: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: fontWeights.normal,
  },
  pMedium: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: fontWeights.normal,
  },
  pSmall: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: fontWeights.normal,
  },
  pXSmall: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: fontWeights.normal,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: fontWeights.semibold,
  },
  captionSpaced: {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '1px',
    fontWeight: fontWeights.medium,
  },
  labelLarge: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: fontWeights.medium,
  },
  labelSmall: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: fontWeights.medium,
  },
};

export type ThemeTypography = typeof typography;
