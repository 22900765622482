import { GetSalesInvoiceLinesReportGroupBy } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';

import { SparklineSeries } from 'components/Sparkline';

import { getLastTwoYears } from 'utils/date';

export enum FilterByField {
  Location = 'locationIds',
  SalesRep = 'salesRepIds',
  Customer = 'customerIds',
  ShipTo = 'shipToIds',
  ItemGroup = 'itemGroupIds',
  Item = 'itemIds',
  Month = 'months',
  Company = 'companyIds',
}

export enum ReportingUnitType {
  /* aka dollars - view sales & gm dollars */
  Amount = 'amount',
  /* view sales in units */
  Units = 'units',
}

export type Filter = {
  [filter in FilterByField]?: string[];
};

export const nameByGroupByField: Record<GetSalesInvoiceLinesReportGroupBy, string> = {
  [GetSalesInvoiceLinesReportGroupBy.Location]: 'Location',
  [GetSalesInvoiceLinesReportGroupBy.SalesRep]: 'Sales Rep',
  [GetSalesInvoiceLinesReportGroupBy.Customer]: 'Customer',
  [GetSalesInvoiceLinesReportGroupBy.ShipTo]: 'Ship To',
  [GetSalesInvoiceLinesReportGroupBy.ItemGroup]: 'Product Group',
  [GetSalesInvoiceLinesReportGroupBy.Item]: 'Item',
  [GetSalesInvoiceLinesReportGroupBy.Month]: 'Month',
  [GetSalesInvoiceLinesReportGroupBy.Company]: 'Company',
};

export const filterNameByField: Record<FilterByField, string> = {
  [FilterByField.Location]: 'Location',
  [FilterByField.SalesRep]: 'Sales Rep',
  [FilterByField.Customer]: 'Customer',
  [FilterByField.ShipTo]: 'Ship To',
  [FilterByField.ItemGroup]: 'Product Group',
  [FilterByField.Item]: 'Item',
  [FilterByField.Month]: 'Month',
  [FilterByField.Company]: 'Company',
};

export const filterFieldByGroupByField: Record<GetSalesInvoiceLinesReportGroupBy, FilterByField> = {
  [GetSalesInvoiceLinesReportGroupBy.Location]: FilterByField.Location,
  [GetSalesInvoiceLinesReportGroupBy.SalesRep]: FilterByField.SalesRep,
  [GetSalesInvoiceLinesReportGroupBy.Customer]: FilterByField.Customer,
  [GetSalesInvoiceLinesReportGroupBy.ShipTo]: FilterByField.ShipTo,
  [GetSalesInvoiceLinesReportGroupBy.ItemGroup]: FilterByField.ItemGroup,
  [GetSalesInvoiceLinesReportGroupBy.Item]: FilterByField.Item,
  [GetSalesInvoiceLinesReportGroupBy.Month]: FilterByField.Month,
  [GetSalesInvoiceLinesReportGroupBy.Company]: FilterByField.Company,
};

export function getMonthlyFilledSparklineSeries(
  salesMonthly: Obj<number>,
  startDate: Date,
  endDate: Date,
  formatFn: (value: string | number) => string,
) {
  const sparkSeries: SparklineSeries[] = [
    {
      data: [],
      formatFn,
      showGradient: true,
    },
  ];

  // Iterate starting from first month for full year
  for (let month = startDate.getMonth(); month < 12; ++month) {
    const date = `${startDate.getFullYear()}-${String(month + 1).padStart(2, '0')}-01`;
    const value = salesMonthly[date] || 0;
    sparkSeries[0].data.push({
      date,
      value,
    });
  }

  let curYear = startDate.getFullYear() + 1;

  // Iterate over all but the first and last year
  while (endDate.getFullYear() - curYear > 0) {
    const curYearSparkSeries: SparklineSeries = {
      data: [],
      formatFn,
      separation: true,
      showGradient: true,
    };
    for (let month = 1; month <= 12; ++month) {
      // fill in missing months with 0 so all lines have same start/end spans
      const date = `${curYear}-${String(month).padStart(2, '0')}-01`;
      const value = salesMonthly[date] || 0;
      curYearSparkSeries.data.push({
        date,
        value,
      });
    }
    sparkSeries.push(curYearSparkSeries);
    curYear += 1;
  }

  const finalYearSparkSeries: SparklineSeries = {
    data: [],
    formatFn,
    separation: true,
    showGradient: true,
  };
  // Iterate over the last year
  for (let month = 0; month <= endDate.getMonth(); ++month) {
    const date = `${endDate.getFullYear()}-${String(month + 1).padStart(2, '0')}-01`;
    const value = salesMonthly[date] || 0;
    finalYearSparkSeries.data.push({
      date,
      value,
    });
  }
  sparkSeries.push(finalYearSparkSeries);

  return sparkSeries;
}

export function getLastTwoYearLabels(yearToDate: boolean): { curYearLabel: string; lastYearLabel: string } {
  const [lastYear, curYear] = getLastTwoYears();
  const ytdSuffix = yearToDate ? ' YTD' : '';
  return {
    curYearLabel: `${curYear}${ytdSuffix}`,
    lastYearLabel: `${lastYear}${ytdSuffix}`,
  };
}
