import React, { ReactNode } from 'react';

import { reactNodeToText } from 'utils/react';

import * as Styled from './AsyncSelect.style';

export interface MultiSelectOption {
  label: ReactNode;
  /** This is to allow for searching even when the label is a react node. */
  searchLabel?: string;
  value: string;
  count?: number;
  disabled?: boolean;
}

export interface UseAsyncMultiSelectProps {
  options: MultiSelectOption[];
  /** Total count of options when not all are returned by api */
  totalCount?: number;
  isLoading?: boolean;
  searchQuery?: string;
  setSearchQuery?: (query: string) => void;
}

export function highlightedLabel(highlightParts: string[]) {
  return (
    <div>
      {highlightParts.map((part, partIdx) =>
        partIdx % 2 ? (
          <Styled.LabelHighlightPart key={partIdx}>{part}</Styled.LabelHighlightPart>
        ) : (
          <span key={partIdx}>{part}</span>
        ),
      )}
    </div>
  );
}

export function normalizeOptions(options: MultiSelectOption[]) {
  return options.map((option) => ({
    ...option,
    searchLabel:
      option.searchLabel === undefined
        ? typeof option.label === 'string'
          ? option.label
          : reactNodeToText(option.label)
        : option.searchLabel,
  }));
}
