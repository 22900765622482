import { Layout as AntdLayout } from 'antd';
import { mediaQueries } from 'theme/breakpoints';

import { styled } from 'utils/styledComponent';

export const Layout = styled(AntdLayout)`
  margin: 0 auto;
  background-color: white;

  padding-left: 1rem;
  padding-right: 1rem;

  ${mediaQueries.sm} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  ${mediaQueries.md} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
