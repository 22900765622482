import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Drawer } from 'antd';

import { useCoreApi } from 'hooks/useApi';

import { CloseableModalProps } from 'utils/asyncModal';

import { BetaTag } from '../BetaTag';
import { FlexSpace } from '../FlexSpace';
import { CenteredError, SmallLoader } from '../Loaders';

export interface InventiveDrawerProps extends CloseableModalProps<void> {
  visible: boolean;
}

export const InventiveDrawer = ({ onClose, visible }: InventiveDrawerProps) => {
  const { data, isLoading, error } = useCoreApi(schemas.inventive.getInventiveAuthorizedUrl);

  return (
    <Drawer
      title={
        <FlexSpace gap={2}>
          <strong>Recurrency Copilot</strong> <BetaTag />
        </FlexSpace>
      }
      placement="right"
      width={800}
      onClose={() => onClose()}
      visible={visible}
      className={css`
        .ant-drawer-body {
          padding: 0;
        }
      `}
    >
      {isLoading ? (
        <SmallLoader />
      ) : error ? (
        <CenteredError error={error} />
      ) : (
        <iframe
          title="inventive"
          id="inventiveIframe"
          src={data?.url}
          width="100%"
          height="99%"
          className={css`
            border: 0;
          `}
          allow="clipboard-write"
          onLoad={() => {
            // See https://www.notion.so/madeinventive/Embedding-step-by-step-guide-Recurrency-77a9df464ddf4f91ab762c8fd66fb296
            const iframe = document.getElementById('inventiveIframe') as HTMLIFrameElement;
            window.onmessage = (event) => {
              if (data && iframe) {
                const targetOrigin = new URL(data.url).origin;
                if (event.origin !== targetOrigin) {
                  // ignore messages from other origins
                  return;
                }
                const { type } = event.data;
                if (type !== 'embed_content_ready') {
                  // ignore messages with other types
                  return;
                }
                const embedTokensMsg = {
                  type: 'embed_tokens',
                  tokens: data.tokens,
                  scopeToken: data.scopeToken,
                };
                iframe.contentWindow?.postMessage(embedTokensMsg, targetOrigin);
                window.onmessage = null;
              }
            };
          }}
        />
      )}
    </Drawer>
  );
};
