import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ShipToResponseDTO } from '@recurrency/core-api-schema/dist/shipTos/createShipTo';
import { Grid } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { Button } from 'components/Button';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { CtaName, CtaType, track, TrackEvent } from 'utils/track';

import { ShipToFormData, ShipToModal } from './NewShipToModal';

export interface NewShipToButtonProps {
  onSubmit?: (shipTo: ShipToResponseDTO) => void;
  size?: SizeType;
  type?: ButtonType;
  disabled?: boolean;
  collapsible?: boolean;
  initialValues?: Partial<ShipToFormData>;
}

export function NewShipToButton({ onSubmit, size, type, disabled, collapsible, initialValues }: NewShipToButtonProps) {
  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;
  const { activeTenant, activeUser } = useGlobalApp();
  const createShipToEnabled = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateShipTo);

  return (
    <Button
      size={size}
      type={type || 'default'}
      onClick={async () => {
        track(TrackEvent.Nav_CtaClick, {
          ctaName: CtaName.NewShipTo,
          ctaType: CtaType.Button,
        });
        await showAsyncModal(ShipToModal, {
          initialValues,
          onSubmit,
        });
      }}
      icon={<PlusOutlined />}
      disabled={disabled || !createShipToEnabled}
    >
      {(isDesktopView || !collapsible) && `New Ship To`}
    </Button>
  );
}
