import React from 'react';

import { css, cx } from '@emotion/css';
import { Skeleton } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SkeletonInputProps } from 'antd/lib/skeleton/Input';

type Heights = {
  small: string;
  middle: string;
  large: string;
};

const heights: Heights = {
  small: '30px',
  middle: '40px',
  large: '50px',
};

const getHeights = (size: SizeType = 'middle') => heights[size];

export const SkeletonInput = ({ className, sizetype, ...props }: SkeletonInputProps & { sizetype: SizeType }) => (
  <Skeleton.Input
    className={cx(
      css`
        width: 100% !important;
        height: ${getHeights(sizetype)} !important;
        border-radius: 8px;
      `,
      className,
    )}
    {...props}
  />
);
