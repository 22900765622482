import React from 'react';

import { calcLine, calcOp, calcProp, Equation, noteLine } from 'components/recipes/equation/Equation';
import { EquationPopover } from 'components/recipes/equation/EquationPopover';

import { formatNumber } from 'utils/formatting';

import { QtyToOrderAlignmentNote } from './QtyToOrderAlignmentNote';
import { QtyToOrderEquationRecord } from './types';

export const OPOQQtyToOrderEquation = ({
  record,
  title,
  resultProperty,
}: {
  record: QtyToOrderEquationRecord;
  title: string;
  resultProperty: string;
}) => {
  const { currentMin: orderPoint, currentMax: orderQuantity } = record;
  /** number of orders (in sets of order quantities) needed is equal to difference between order point and the current net stock divided by the order quantity */
  const orderCount = Math.ceil((orderPoint - record.netStock) / orderQuantity);
  return (
    <EquationPopover title={title}>
      <Equation
        lines={[
          record.qtyToOrderUnaligned > 0
            ? [
                calcLine(
                  calcProp(
                    resultProperty,
                    `${formatNumber(record.qtyToOrder)} ${record.unitOfMeasure || ''}${
                      record.alignNeeded
                        ? ` (${formatNumber(
                            (record.qtyToOrder * (record.unitSize ?? 1)) / (record.qtyToOrderAlignUnitSize ?? 1),
                          )} ${record.qtyToOrderAlignUnitOfMeasure})*`
                        : ''
                    }`,
                    record.alignNeeded ? <QtyToOrderAlignmentNote record={record} /> : undefined,
                  ),
                  calcOp('='),
                  [
                    calcProp('Number of Orders', `${formatNumber(orderCount)} ${record.unitOfMeasure || ''}`),
                    calcOp('x'),
                    calcProp('Current Order Quantity', `${formatNumber(orderQuantity)} ${record.unitOfMeasure || ''}`),
                    calcOp('+'),
                    calcProp('Net Stock', `${formatNumber(record.netStock)} ${record.unitOfMeasure || ''}`),
                  ],
                  calcOp('≥'),
                  record.isOverstock
                    ? calcProp('Current Order Quantity', `${formatNumber(orderQuantity)} ${record.unitOfMeasure || ''}`)
                    : record.stockable
                    ? calcProp('Current Order Point', `${formatNumber(orderPoint)} ${record.unitOfMeasure || ''}`)
                    : calcProp(
                        'Qty Backordered',
                        `${formatNumber(record.qtyBackordered)} ${record.unitOfMeasure || ''}`,
                      ),
                ),
              ]
            : [
                calcLine(
                  calcProp('Net Stock', `${formatNumber(record.netStock)} ${record.unitOfMeasure || ''}`),
                  calcOp('≥'),
                  record.isOverstock
                    ? calcProp('Current Order Quantity', `${formatNumber(orderQuantity)} ${record.unitOfMeasure || ''}`)
                    : calcProp('Current Order Point', `${formatNumber(orderPoint)} ${record.unitOfMeasure || ''}`),
                ),
                noteLine(
                  'No inventory needs to be purchased as the current net stock is greater than the current order point or order quantity.',
                ),
              ],
        ]}
      />
    </EquationPopover>
  );
};
