import React from 'react';

import { css } from '@emotion/css';

import { Alert } from 'components/Alert';

export function InvoiceChecksAlerts({ errors, warnings }: { errors: string[]; warnings: string[] }) {
  // don't render if no alerts to show
  if (!errors.length && !warnings.length) {
    return null;
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
      `}
    >
      {errors.map((message, idx) => (
        <Alert key={idx} message={message} type="error" showIcon />
      ))}
      {warnings.map((message, idx) => (
        <Alert key={idx} message={message} type="warning" showIcon />
      ))}
    </div>
  );
}
