import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { Affix } from 'antd';
import { VERTICAL_NAVIGATION_HEIGHT } from 'App/frames/Navigation/navConstants';
import { colors } from 'theme/colors';

export const FixedTableOfContents = ({ ids }: { ids: string[] }) => {
  const [activeId, setActiveId] = useState('');

  const elements = ids
    .map((id) => document.querySelector(`[id='${id}']`))
    .filter((element) => element !== null)
    .map((element) => element!);

  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      setActiveId(
        entries
          .filter((entry) => entry.isIntersecting)
          // If multiple elements are on screen, pick the one closest to the top
          .sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top)[0]?.target.id,
      );
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: `-${VERTICAL_NAVIGATION_HEIGHT + 20}px 0px -50% 0px`,
    });

    elements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [elements]);

  return (
    <Affix
      offsetTop={VERTICAL_NAVIGATION_HEIGHT + 20}
      className={css`
        width: 275px;
        padding: 10px;
      `}
    >
      {ids.map((id) => (
        <a
          key={id}
          href={`#${id}`}
          className={css`
            display: block;
            margin-bottom: 5px;
            transition: none;
            ${activeId === id ? `color: ${colors.primary[500]}; font-weight: bold;` : `color: ${colors.neutral[900]};`}
            :hover {
              color: ${colors.primary[500]};
            }
          `}
          onClick={(e) => {
            e.preventDefault();
            const element = document.querySelector(`[id='${id}']`);
            window.scrollTo({
              top: element ? element.getBoundingClientRect().top + window.scrollY - VERTICAL_NAVIGATION_HEIGHT : 0,
              behavior: 'smooth',
            });
          }}
        >
          {id}
        </a>
      ))}
    </Affix>
  );
};
