import { SortDirection } from '@recurrency/core-api-schema/dist/common/enums';
import { InventoryDashboardGroupBy } from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';

import {
  InventoryDashboardDetailsHashState,
  InventoryExplorerCompareFromOptions,
  InventoryExplorerCompareToOptions,
} from 'types/hash-state';

export interface DrillDownChartConfigItem {
  name: string;
  title: string;
  description: string;
  hashStateConfig: InventoryDashboardDetailsHashState;
}

export const DrillDownChartConfig: Record<string, DrillDownChartConfigItem> = {
  inventory: {
    name: 'inventory' as const,
    title: 'Inventory History' as const,
    description:
      'Inventory value using the transaction costs of items over time, averaged based on how long the inventory was held for' as const,
    hashStateConfig: {
      chartSource: 'inventory',
      displayUnit: 'value',
      changeValue: 'percent',
      page: 1,
      columnGroups: ['inventory', 'sales', 'turns'],
      sortBy: [['compareFrom.inventoryValue', SortDirection.Desc]],
      groupBy: InventoryDashboardGroupBy.Location,
      compareFrom: InventoryExplorerCompareFromOptions.LastThreeMonths,
      compareTo: InventoryExplorerCompareToOptions.PriorThreeMonths,
    },
  },
  sales: {
    name: 'sales' as const,
    title: 'Sales History' as const,
    description: 'Aggregation of total quantities invoiced over each month' as const,
    hashStateConfig: {
      chartSource: 'sales',
      displayUnit: 'value',
      changeValue: 'percent',
      page: 1,
      columnGroups: ['sales', 'inventory', 'turns'],
      sortBy: [['compareFrom.sales', SortDirection.Desc]],
      groupBy: InventoryDashboardGroupBy.Location,
      compareFrom: InventoryExplorerCompareFromOptions.LastThreeMonths,
      compareTo: InventoryExplorerCompareToOptions.PriorThreeMonths,
    },
  },
  backorders: {
    name: 'backorders' as const,
    title: 'Backorder History' as const,
    description:
      'History of backorders over time using the quantity backordered and the current moving average cost' as const,
    hashStateConfig: {
      chartSource: 'backorders',
      displayUnit: 'value',
      changeValue: 'percent',
      page: 1,
      columnGroups: ['backorders', 'inventory', 'fillRate'],
      sortBy: [['compareFrom.backorderValue', SortDirection.Desc]],
      groupBy: InventoryDashboardGroupBy.Location,
      compareFrom: InventoryExplorerCompareFromOptions.LastThreeMonths,
      compareTo: InventoryExplorerCompareToOptions.PriorThreeMonths,
    },
  },
  turns: {
    name: 'turns' as const,
    title: 'Annualized Turns' as const,
    description:
      'Total cost of goods sold divided by the average inventory over the month, annualized for each month' as const,
    hashStateConfig: {
      chartSource: 'turns',
      displayUnit: 'value',
      changeValue: 'percent',
      page: 1,
      columnGroups: ['turns', 'inventory', 'cost'],
      sortBy: [['compareFrom.turns', SortDirection.Desc]],
      groupBy: InventoryDashboardGroupBy.Location,
      compareFrom: InventoryExplorerCompareFromOptions.LastThreeMonths,
      compareTo: InventoryExplorerCompareToOptions.PriorThreeMonths,
    },
  },
  grossMarginROI: {
    name: 'grossMarginROI' as const,
    title: 'Annualized GMROI' as const,
    description:
      'Gross margin return on inventory investment over each month based on gross margin history and the inventory value during that time. Each month is annualized for the value over that window' as const,
    hashStateConfig: {
      chartSource: 'grossMarginROI',
      displayUnit: 'value',
      changeValue: 'percent',
      page: 1,
      columnGroups: ['grossMarginROI', 'sales', 'cost', 'inventory'],
      sortBy: [['compareFrom.grossMarginROI', SortDirection.Desc]],
      groupBy: InventoryDashboardGroupBy.Location,
      compareFrom: InventoryExplorerCompareFromOptions.LastThreeMonths,
      compareTo: InventoryExplorerCompareToOptions.PriorThreeMonths,
    },
  },
  cost: {
    name: 'COGS' as const,
    title: 'Cost of Goods Sold' as const,
    description: '' as const,
    hashStateConfig: {
      chartSource: 'cost',
      displayUnit: 'value',
      changeValue: 'percent',
      page: 1,
      columnGroups: ['cost', 'sales', 'grossMarginROI'],
      sortBy: [['compareFrom.cost', SortDirection.Desc]],
      groupBy: InventoryDashboardGroupBy.Location,
      compareFrom: InventoryExplorerCompareFromOptions.LastThreeMonths,
      compareTo: InventoryExplorerCompareToOptions.PriorThreeMonths,
    },
  },
  fillRate: {
    name: 'fillRate' as const,
    title: 'Fill Rate' as const,
    description:
      'Percentage of all orders placed that are shipped on time and complete. Cancelled orders are excluded.' as const,
    hashStateConfig: {
      chartSource: 'fillRate',
      displayUnit: 'value',
      changeValue: 'percent',
      page: 1,
      columnGroups: ['fillRate', 'inventory', 'backorders'],
      sortBy: [['compareFrom.fillRate', SortDirection.Desc]],
      groupBy: InventoryDashboardGroupBy.Location,
      compareFrom: InventoryExplorerCompareFromOptions.LastThreeMonths,
      compareTo: InventoryExplorerCompareToOptions.PriorThreeMonths,
    },
  },
};
