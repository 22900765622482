import { mediaQueries } from 'theme/breakpoints';

import { styled } from 'utils/styledComponent';

export const HeaderContainer = styled('div')`
  width: 100%;

  ${mediaQueries.sm} {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 4px;
  }
`;
