import React, { useState } from 'react';

import { css } from '@emotion/css';
import { NgrokIntegrationDTO } from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Form } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import JSZip from 'jszip';
import { theme } from 'theme';

import { InputFormItem, RadioFormItem } from 'components/FormItems';
import { Modal } from 'components/Modal';
import { CollapsePanels } from 'components/recipes/sidePane/CollapsePanels';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { captureAndShowError } from 'utils/error';

const enum ServerIPTypes {
  Localhost = 'localhost',
  IP = 'ip',
}
const enum ServerArchTypes {
  '32bit' = '32bit',
  '64bit' = '64bit',
}
const NgrokDownloadUrls = {
  [ServerArchTypes['32bit']]: 'https://assets.recurrency.com/ngrok/32bit/ngrok.exe',
  [ServerArchTypes['64bit']]: 'https://assets.recurrency.com/ngrok/64bit/ngrok.exe',
};

interface NgrokAgentConfiguration {
  serverIPType: ServerIPTypes;
  serverIP?: string;
  serverPort: number;
  serverArchType: ServerArchTypes;
}

function createConfig(agent: NgrokIntegrationDTO | null, serverIP: string, serverPort: number) {
  // trim off any whitespace from the Server IP.  Since it's a free form text field it would be easy to fat finger extra spaces
  serverIP = serverIP ? serverIP.trim() : serverIP;
  return `
server_addr: tunnel.us.agents.recurrency.com:443
root_cas: host
crl_noverify: true
version: "2"
authtoken: ${agent?.authToken}
tunnels:
  sqlserver: #tunnel name
    labels:
      - edge=${agent?.edgeId}
    addr: ${serverIP ? `${serverIP}:` : ''}${serverPort}`.trim();
}

const getNgrokDownloadErrorMessage = (status?: number) =>
  `Error downloading agent executable, please try again.${status ? ` Status: ${status}` : ''}`;

const generateZip = async (config: string, serverArchType: ServerArchTypes, tenantName: string) => {
  const zip = new JSZip();

  // fetch ngrok exe file based on the selected server architecture (32 vs 64bit)
  // we sometimes run into issues fetching the ngrok exe
  // there's a lot of error handling to try and point to where the problem is
  // we still allow you to download the ZIP file even if there are issues with getting the exe file
  // and internal users can manually rebuild the ZIP file with the right exe from there
  // TODO:  add Linux support.  We don't see it very often, but would be good to have our bases covered
  let ngrokExe;
  try {
    const response = await fetch(NgrokDownloadUrls[serverArchType]);
    if (!response.ok) {
      captureAndShowError(response, getNgrokDownloadErrorMessage(response.status));
    } else {
      ngrokExe = await response.blob();
    }
  } catch (err) {
    captureAndShowError(err, getNgrokDownloadErrorMessage());
  }

  // Create files based on user inputs
  // allow the zip to be created even if the exe file can't be included
  if (ngrokExe !== undefined) {
    try {
      zip.file('ngrok.exe', ngrokExe, { binary: true, compression: 'DEFLATE' });
    } catch (err) {
      captureAndShowError(err, getNgrokDownloadErrorMessage());
    }
  }
  zip.file('config.yml', config);
  zip.file(
    'install-agent.bat',
    `@echo off
ngrok service install --config=./config.yml
ngrok service start`,
  );
  zip.file(
    'uninstall-agent.bat',
    `@echo off
ngrok service uninstall`,
  );

  // Generate the ZIP file
  const zipBlob = await zip.generateAsync({ type: 'blob' });

  // Create a download link for the ZIP file
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(zipBlob);
  downloadLink.download = `recurrency_agent_${tenantName}_${serverArchType}.zip`;
  downloadLink.click();
};

export function DownloadNgrokAgentModal({
  agent,
  tenant,
  onClose,
}: {
  agent: NgrokIntegrationDTO | null;
  tenant: TenantDTO;
  onClose: () => void;
}) {
  const { activeUser } = useGlobalApp();
  const [form] = Form.useForm<NgrokAgentConfiguration>();
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ipType = [
    { value: ServerIPTypes.Localhost, label: 'Same server as the database' },
    { value: ServerIPTypes.IP, label: 'Different server from the database' },
  ];

  const serverArchTypes = [
    { value: ServerArchTypes['32bit'], label: '32bit' },
    { value: ServerArchTypes['64bit'], label: '64bit' },
  ];

  const serverIPType = form.getFieldValue('serverIPType');
  const serverIP = form.getFieldValue('serverIP');
  const serverPort = form.getFieldValue('serverPort');
  const serverArchType = form.getFieldValue('serverArch');

  const config = createConfig(agent, serverIPType === ServerIPTypes.IP ? serverIP : '', serverPort);
  return (
    <Modal
      visible
      width="600px"
      title="Configure Agent"
      onCancel={onClose}
      centered
      cancelText="Close"
      okText="Download"
      okButtonProps={{
        disabled: !serverIPType || !serverPort || !serverArchType || (serverIPType === ServerIPTypes.IP && !serverIP),
        loading: isLoading,
      }}
      onOk={async () => {
        setIsLoading(true);
        try {
          await generateZip(config, serverArchType, tenant.databaseSchema);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <Form layout="vertical" form={form}>
        <RadioFormItem
          name="serverIPType"
          label="Will you run your agent on the same server as the database?"
          values={ipType}
          rules={[
            {
              required: true,
              message: 'Server IP type is required',
            },
          ]}
          onChange={() => setRefresh(!refresh)}
          optionType="default"
        />
        <RadioFormItem
          name="serverArch"
          label="Does that machine run a 32-bit or 64-bit version of Windows Server?"
          values={serverArchTypes}
          rules={[
            {
              required: true,
              message: 'Server architecture is required',
            },
          ]}
          onChange={() => setRefresh(!refresh)}
          optionType="default"
        />
        <InputFormItem
          name="serverIP"
          label="If the agent will run on a separate machine, what is the internal IP of your database server?"
          placeholder="127.0.0.1"
          disabled={serverIPType === ServerIPTypes.Localhost}
          rules={[
            {
              required: false,
            },
          ]}
          onChange={() => setRefresh(!refresh)}
        />
        <InputFormItem
          name="serverPort"
          label="What port does your database run on? (default is 1433)"
          type="number"
          placeholder="1433"
          rules={[
            {
              required: true,
              message: 'Database port is required',
            },
          ]}
          onChange={() => setRefresh(!refresh)}
        />
      </Form>
      <div>
        {activeUser.isRecurrencyAdmin && serverPort !== undefined ? (
          <CollapsePanels
            panels={[
              {
                title: 'View Config (Internal Only)',
                content: (
                  <>
                    <Paragraph copyable={{ text: config }}>Copy Config</Paragraph>
                    <pre
                      className={css`
                        padding: 8px;
                        background: ${theme.colors.neutral[100]};
                        border: 2px solid ${theme.colors.neutral[300]};
                        border-radius: 8px;
                        width: 100%;
                        height: 100%;
                      `}
                    >
                      <code>{config}</code>
                    </pre>
                  </>
                ),
              },
            ]}
          />
        ) : null}
      </div>
    </Modal>
  );
}
