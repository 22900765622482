import React from 'react';

import { AsyncJobStatus } from '@recurrency/core-api-schema/dist/asyncJobs/common';

import { Badge } from 'components/Badge';

export function JobStatusBadge({ status }: { status: AsyncJobStatus }) {
  const color = {
    [AsyncJobStatus.Running]: 'info' as const,
    [AsyncJobStatus.Success]: 'success' as const,
    [AsyncJobStatus.Failed]: 'danger' as const,
  }[status];
  const label = {
    [AsyncJobStatus.Running]: 'Running',
    [AsyncJobStatus.Success]: 'Success',
    [AsyncJobStatus.Failed]: 'Failed',
  }[status];

  return <Badge variant={color} label={label} />;
}
