import React from 'react';

import { css, cx } from '@emotion/css';
import { DatePicker as AntDatePicker, DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';

import * as Styled from './DatePicker.style';

export type Props = DatePickerProps;
export const DatePicker = (props: Props): JSX.Element => <Styled.DatePicker {...props} />;

const { RangePicker } = AntDatePicker;

export const DateRangePicker = ({ className, ...props }: RangePickerProps): JSX.Element => (
  <RangePicker
    className={cx(
      'DateRangePicker',
      css`
        border-radius: 8px;
        white-space: pre-line;
      `,
      className,
    )}
    {...props}
  />
);
