import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { ButtonProps, notification } from 'antd';

import { AsyncButton } from 'components/Button/AsyncButton';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { joinAnd } from 'utils/formatting';
import { PdfDeliveryType, PdfDocumentType, track, TrackEvent } from 'utils/track';

// foreignId for draft quotes is just the Recurrency id for the draft
export const sendPDFEmail = async ({ foreignId, type }: { foreignId: string; type: PdfDocumentType }) => {
  track(TrackEvent.Components_PdfButtonClick, {
    documentType: type,
    deliveryType: PdfDeliveryType.Email,
  });
  try {
    if (type === PdfDocumentType.Quote) {
      await coreApiFetch(schemas.salesQuotes.getSalesQuotePdf, {
        pathParams: { salesQuoteId: foreignId },
        queryParams: { sendEmail: true },
      });
    } else if (type === PdfDocumentType.DraftQuote) {
      await coreApiFetch(schemas.salesQuoteDrafts.getSalesQuoteDraftPdf, {
        pathParams: { salesQuoteDraftId: foreignId },
        queryParams: { sendEmail: true },
      });
    } else if (type === PdfDocumentType.Order || type === PdfDocumentType.WorkOrder) {
      await coreApiFetch(schemas.salesOrders.getSalesOrderPdf, {
        pathParams: { salesOrderId: foreignId },
        queryParams: { sendEmail: true },
      });
    } else if (type === PdfDocumentType.Invoice) {
      await coreApiFetch(schemas.invoices.getInvoicePdf, {
        pathParams: { invoiceId: foreignId },
        queryParams: { sendEmail: true },
      });
    } else if (type === PdfDocumentType.PackingList) {
      await coreApiFetch(schemas.invoices.getPackingListPdf, {
        pathParams: { invoiceId: foreignId },
        queryParams: { sendEmail: true },
      });
    } else if (type === PdfDocumentType.PurchaseOrder) {
      await coreApiFetch(schemas.purchaseOrders.getPurchaseOrderPdf, {
        pathParams: { purchaseOrderId: foreignId },
        queryParams: { sendEmail: true },
      });
    }
    notification.success({
      message: 'PDF email sent, check your inbox shortly.',
    });
  } catch (err) {
    captureAndShowError(err, 'Error while sending PDF email');
  }
};

export const SendPdfEmailButton = ({
  foreignId,
  type,
  props,
  content = 'Email PDF',
}: {
  foreignId: string;
  type: PdfDocumentType;
  props?: ButtonProps;
  content?: React.ReactNode;
}) => {
  const { activeTenant, activeUser } = useGlobalApp();

  return (
    <Tooltip
      title={`Send PDF to ${joinAnd([
        activeUser.email,
        ...((type === PdfDocumentType.DraftQuote
          ? activeTenant.tenantUser.settings?.quote?.copyEmailsTo
          : type === PdfDocumentType.PackingList || type === PdfDocumentType.PurchaseOrder
          ? null
          : type === PdfDocumentType.WorkOrder // GTire only
          ? activeTenant.tenantUser.settings?.order?.copyEmailsTo
          : activeTenant.tenantUser.settings?.[type]?.copyEmailsTo) ?? []),
      ])}. This will not send an email to the customer.`}
    >
      <AsyncButton {...props} onClick={() => sendPDFEmail({ foreignId, type })}>
        {content}
      </AsyncButton>
    </Tooltip>
  );
};
