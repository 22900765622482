import React, { useEffect, useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { TaskStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TaskDTO } from '@recurrency/core-api-schema/dist/tasks/common';
import { CreateTaskBody } from '@recurrency/core-api-schema/dist/tasks/createTask';
import { Form, notification } from 'antd';
import moment, { Moment } from 'moment';

import { AsyncSelect } from 'components/AsyncSelect';
import {
  useContactsSelectProps,
  useCustomersSelectProps,
  useTenantUsersSelectProps,
} from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DatePicker } from 'components/DatePicker';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { NotificationLink } from 'components/Links';
import { Modal } from 'components/Modal';
import { TextArea } from 'components/TextArea';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { joinIdNameObj, splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

export interface TaskFormData {
  title: string;
  body: string;
  dueAt: Moment;
  reminderAt: Moment;
  customer: {
    companyId?: string;
    foreignId: string;
    name: string;
  };
  contact: {
    foreignId: string;
    name: string;
  };
  assigneeUserId: string; // uuid
  // createdBy and status should only be passed if editing
  createdBy?: string; // name
  status?: TaskStatus;
}

export interface TaskModalProps {
  initialValues?: Partial<TaskFormData>;
  // if taskId is passed, existing task is being edited
  taskId?: string;
  onClose: (task?: TaskDTO) => void;
}

/** if there are multiple companies in a tenant, we append the company id and name to the customer
 * this function is used to split the components out of the customer string
 * customerStr = [companyId]:[companyName]:[customerId]:[customerName]
 */
function splitCompanyCustomerStr(companyCustomerStr: string): {
  companyId: string;
  customerId: string;
  customerName: string;
} {
  const [companyId, customerId, customerName] = companyCustomerStr.split(':').map((str) => str.trim());
  return { companyId, customerId, customerName };
}

export const TaskModal = ({ initialValues, taskId, onClose }: TaskModalProps) => {
  const { activeUser, userAllowedCompanies } = useGlobalApp();
  const [taskForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tenantHasMultipleCompanies = userAllowedCompanies.length > 1;
  const tenantsUserSelectProps = useTenantUsersSelectProps();
  const [taskState, setTaskState] = useState<Partial<TaskFormData>>(() => ({
    dueAt: moment(),
    assigneeUserId: activeUser.id,
    createdBy: activeUser.fullName,
    ...initialValues,
  }));

  // use taskState as the source of state
  useEffect(() => {
    taskForm.setFieldsValue(taskState);
  }, [taskForm, taskState]);

  async function handleSubmit() {
    try {
      setIsSubmitting(true);
      const bodyParams: CreateTaskBody = {
        title: taskState.title!,
        dueAt: (taskState.dueAt || moment()).toISOString(),
        reminderAt: taskState.reminderAt?.toISOString(),
        metadata: {
          customer: taskState.customer,
          contact: taskState.contact,
        },
        assigneeUserId: taskState.assigneeUserId!,
        body: taskState.body || '',
        status: taskState.status || TaskStatus.New,
      };

      let upsertedTask: TaskDTO;
      if (taskId) {
        const result = await coreApiFetch(schemas.tasks.updateTask, {
          pathParams: { taskId },
          bodyParams,
        });
        upsertedTask = result.data;

        track(TrackEvent.Tasks_EditTask_Save, {
          taskId: upsertedTask.id,
          assigneeIsCreator: upsertedTask.assigneeUserId === upsertedTask.userId,
        });
        notification.success({
          message: 'Task edited.',
        });
      } else {
        const result = await coreApiFetch(schemas.tasks.createTask, {
          bodyParams,
        });
        upsertedTask = result.data;

        track(TrackEvent.Tasks_CreateTask_Save, {
          taskId: upsertedTask.id,
          assigneeIsCreator: upsertedTask.assigneeUserId === upsertedTask.userId,
        });
        notification.success({
          message: 'New task created.',
          key: routes.tasks.taskDetails(upsertedTask.id),
          description: (
            <NotificationLink to={routes.tasks.taskDetails(upsertedTask.id)}>View Task Details</NotificationLink>
          ),
        });
      }
      onClose(upsertedTask);
    } catch (err) {
      captureAndShowError(err, `Error while ${taskId ? `editing` : `creating`} task`);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Modal
      visible
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" form="taskForm" htmlType="submit" loading={isSubmitting}>
          Save
        </Button>,
      ]}
      title={taskId ? 'Edit Task' : 'New Task'}
      onCancel={() => onClose()}
      centered
      width={900}
    >
      <Container>
        <Form.Provider>
          <Form
            name="taskForm"
            form={taskForm}
            onFinish={handleSubmit}
            onError={console.error}
            onFinishFailed={console.error}
            onValuesChange={(changedValues: Partial<TaskFormData>) => {
              if (changedValues.customer) {
                // clear contact when customer changes
                changedValues.contact = undefined;
              }
              setTaskState({ ...taskState, ...changedValues });
            }}
            {...responsiveFormLayout}
          >
            <Form.Item label="Topic" name="title" rules={[{ required: true, message: 'Please add a topic.' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Due Date" name="dueAt" rules={[{ required: true, message: 'Please select a due date.' }]}>
              <DatePicker />
            </Form.Item>

            <Form.Item label="Reminder Date" name="reminderAt">
              <DatePicker />
            </Form.Item>

            <Form.Item label="Customer" name="customer" rules={[{ required: true, message: 'Please add a customer.' }]}>
              <CustomersSelect appendCompanyId={tenantHasMultipleCompanies} />
            </Form.Item>

            {taskState.customer && (
              <Form.Item label="Contact" name="contact">
                <ContactSelect customerId={taskState.customer?.foreignId} />
              </Form.Item>
            )}

            <Form.Item
              label="Assigned To"
              name="assigneeUserId"
              rules={[{ required: true, message: 'Please add an assignee.' }]}
            >
              <AsyncSelect selectProps={tenantsUserSelectProps} entityPlural="users" placeholder="Search users" />
            </Form.Item>

            <Form.Item label="Created By" name="createdBy">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Comments" name="body">
              <TextArea rows={5} />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Container>
    </Modal>
  );
};

// create wrapper selects that use customer and contact as objects instead of strings
function CustomersSelect({
  value,
  onChange,
  appendCompanyId = false,
}: {
  value?: TaskFormData['customer'];
  onChange?: (value: TaskFormData['customer']) => void;
  appendCompanyId?: boolean;
}) {
  const customersSelectProps = useCustomersSelectProps({ appendCompanyId });
  let joinedValue = value ? joinIdNameObj(value) : undefined;
  if (appendCompanyId && joinedValue && value?.companyId) {
    joinedValue = `[${value.companyId}] ${joinedValue}`;
  }
  return (
    <AsyncSelect
      value={joinedValue}
      onSelect={(_, option) => {
        let { foreignId, name } = splitIdNameStr(option.value);
        if (appendCompanyId) {
          ({ customerId: foreignId, customerName: name } = splitCompanyCustomerStr(option.value));
        }
        onChange?.({ foreignId, name, companyId: option.company_id });
      }}
      selectProps={customersSelectProps}
      entityPlural="customers"
    />
  );
}

function ContactSelect({
  value,
  customerId,
  onChange,
}: {
  value?: TaskFormData['contact'];
  customerId?: string;
  onChange?: (value: TaskFormData['contact']) => void;
}) {
  const contactsSelectProps = useContactsSelectProps({ customerId });

  return (
    <AsyncSelect
      value={value ? joinIdNameObj(value) : undefined}
      onChange={(newValue) => {
        onChange?.(splitIdNameStr(newValue));
      }}
      selectProps={contactsSelectProps}
      entityPlural="contacts"
    />
  );
}
