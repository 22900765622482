import React from 'react';

import { formatNumber } from 'utils/formatting';

import { QtyToOrderEquationRecord } from './types';

export const QtyToOrderAlignmentNote = ({ record }: { record: QtyToOrderEquationRecord }) => (
  <>
    <span>* Qty to order is converted</span>
    <br />
    <span>
      from {formatNumber(record.qtyToOrderUnaligned)} {record.unitOfMeasure || ''}
    </span>{' '}
    <span>
      to {formatNumber((record.qtyToOrder * (record.unitSize ?? 1)) / (record.qtyToOrderAlignUnitSize ?? 1))}{' '}
      {record.qtyToOrderAlignUnitOfMeasure}
    </span>{' '}
    <span>
      (1 {record.qtyToOrderAlignUnitOfMeasure} ={' '}
      {formatNumber((record.qtyToOrderAlignUnitSize ?? 1) / (record.unitSize ?? 1))} {record.unitOfMeasure || ''})
    </span>
  </>
);
