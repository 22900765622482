import React from 'react';

import { css } from '@emotion/css';

import { Counter } from 'components/Counter';
import { Tooltip } from 'components/Tooltip';

import { IdNameObj } from 'types/legacy-api';

export function PurchaseTargetGroupNameToolTip({
  groupName,
  purchaseLocationId,
  purchaseLocationName,
  spokeLocations,
}: {
  groupName: string;
  purchaseLocationId?: string;
  purchaseLocationName?: string;
  spokeLocations?: IdNameObj[];
}) {
  return (
    <div>
      <Tooltip
        overlayStyle={{ whiteSpace: 'pre-line' }}
        title={`${purchaseLocationId}: ${purchaseLocationName}
          ${spokeLocations ? `${spokeLocations.map((l) => `${l.foreignId}: ${l.name}`).join('\n')}` : ''}`}
        placement="right"
      >
        <span>
          {groupName}
          <Counter
            className={css`
              margin-left: 5px;
            `}
            value={(spokeLocations?.length || 0) + 1}
            selected={false}
            variant="neutral"
          />
        </span>
      </Tooltip>
    </div>
  );
}
