import React from 'react';

import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { theme } from 'theme';

import { formatUSD, formatUSDAbbreviated } from 'utils/formatting';

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const colorCode = [theme.colors.chart.sunsetPurple, theme.colors.chart.lagoonBlue];

export type PerfChartPoint = Obj<string | number | null>;

export const PerformanceChart = ({ years, data }: { years: string[]; data: PerfChartPoint[] }) => (
  /* use width 99% to avoid responsiveness issues per https://github.com/recharts/recharts/issues/172 */
  <ResponsiveContainer width="99%" height={250}>
    <AreaChart data={data}>
      <defs>
        <linearGradient id="perfChartColor0" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={colorCode[0]} stopOpacity={0.2} />
          <stop offset="95%" stopColor={colorCode[0]} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="perfChartColor1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={colorCode[1]} stopOpacity={0.2} />
          <stop offset="95%" stopColor={colorCode[1]} stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis type="category" dataKey="monthName" ticks={monthNames} />
      <YAxis type="number" tickFormatter={formatUSDAbbreviated} />
      <Tooltip cursor={false} formatter={(value) => formatUSD(Number(value))} />
      <Legend />
      {years.map((year, idx) => (
        <Area
          key={year}
          dataKey={year}
          stroke={colorCode[idx]}
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#perfChartColor${idx})`}
        />
      ))}
      <CartesianGrid strokeDasharray="3 3" />
    </AreaChart>
  </ResponsiveContainer>
);
