import { useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { globalAppStore } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { loadActiveTenantUserViewSettings } from 'utils/globalAppLoaders';

import { ViewSettingKey } from './types';

async function upsertViewSettings(settingKey: ViewSettingKey, visibleKeys: string[]) {
  if (visibleKeys.length > 0) {
    await coreApiFetch(schemas.userViewSettings.upsertUserViewSettings, {
      bodyParams: {
        settingKey,
        settingValue: {
          visibleKeys,
        },
      },
    });
  } else {
    // clear setting if no visible keys
    await coreApiFetch(schemas.userViewSettings.deleteUserViewSettings, {
      pathParams: {
        settingKey,
      },
    });
  }

  return loadActiveTenantUserViewSettings();
}

function getViewSettings(settingKey: ViewSettingKey): string[] | undefined {
  return globalAppStore.state.userViewVisibleKeys?.[settingKey];
}

/**
 * hook that manages side pane visibility and column settings with abstraction of useState
 */
export function useUserViewSettingsState(
  settingKey: ViewSettingKey | undefined,
  defaultVisibleKeys: string[],
): [string[], (keys: string[]) => void] {
  // eslint-disable-next-line prefer-const
  let [visibleKeys, setVisibleKeys] = useState<string[]>([]);

  if (!settingKey) {
    return [visibleKeys, setVisibleKeys];
  }

  // load visible keys from global app state if defined, else use defaultVisibleKeys
  if (!visibleKeys.length) {
    const settingsVisibleKeys = getViewSettings(settingKey);
    visibleKeys = settingsVisibleKeys && settingsVisibleKeys.length > 0 ? settingsVisibleKeys : defaultVisibleKeys;
  }

  const setAndPersistVisibleKeys = (newVisibleKeys: string[]) => {
    setVisibleKeys(newVisibleKeys);
    if (settingKey) {
      upsertViewSettings(settingKey, newVisibleKeys);
    }
  };

  return [visibleKeys, setAndPersistVisibleKeys];
}
