import React from 'react';

import { css } from '@emotion/css';

export const FlexSpacer = () => (
  <div
    className={css`
      flex: 1;
    `}
  />
);
