import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Note } from '@recurrency/core-api-schema/dist/common/notes';

import { MiniTable } from 'components/MiniTable';
import { NoteCell } from 'components/recipes/sidePane/NoteCell';
import { ViewNotesModal } from 'components/recipes/ViewNotesModal/ViewNotesModal';

import { useCoreApi } from 'hooks/useApi';

import { groupByFields } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { joinIdNameObj } from 'utils/formatting';

interface UniqueItem {
  itemId: string;
  foreignId: string;
  name: string;
}

interface ItemNotesPanelContentProps {
  uniqueItems: UniqueItem[];
}

type ItemNoteList = {
  itemId: string;
  itemName: string;
  items: Note[];
};

const MANDATORY_TOOLTIP_TEXT = 'Item note marked as mandatory';

export function ItemNotesPanelContent({ uniqueItems }: ItemNotesPanelContentProps) {
  const uniqueItemIds = useMemo(
    () =>
      uniqueItems.reduce<{ [key: string]: UniqueItem }>((acc, item) => {
        acc[item.itemId] = item;
        return acc;
      }, {}),
    [uniqueItems],
  );

  const DEFAULT_NOTE_LIMIT = 3;
  const { data, isLoading } = useCoreApi(schemas.notes.getItemNotes, {
    queryParams: {
      filter: { itemIds: Object.keys(uniqueItemIds), noteLimit: DEFAULT_NOTE_LIMIT },
    },
  });

  const groupedData = useMemo(() => {
    if (data) return groupByFields(data.items, ['itemName', 'itemId']) as ItemNoteList[];
    return [];
  }, [data]);

  const handleModal = (item: ItemNoteList) => {
    const itemDetails = uniqueItemIds[item.itemId];
    showAsyncModal(ViewNotesModal, {
      title: <>Notes for Item #{joinIdNameObj(uniqueItemIds[itemDetails.itemId])}</>,
      endpoint: schemas.notes.getItemNotes,
      filter: { itemIds: [`${item.itemId}`] },
      mandatoryText: MANDATORY_TOOLTIP_TEXT,
    });
  };
  if (isLoading) return <MiniTable columns={[]} data={[]} isLoading />;
  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-top: 10px;
        `}
      >
        {groupedData?.length ? (
          groupedData.map((item: ItemNoteList, idx: number) => {
            const itemDetails = uniqueItemIds[item.itemId];
            return (
              <NoteCell
                header={`Item #${joinIdNameObj(uniqueItemIds[itemDetails.itemId])}`}
                notes={item.items}
                key={`item_not_${idx}`}
                onViewAll={() => handleModal(item)}
                mandatoryTooltipText={MANDATORY_TOOLTIP_TEXT}
              />
            );
          })
        ) : (
          <MiniTable columns={[]} data={[]} />
        )}
      </div>
    </>
  );
}
