import React from 'react';

import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { theme } from 'theme';

import { Tooltip } from './Tooltip';

export type Props = {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  placement?: TooltipPlacement;
  useInfoIcon?: boolean;
  tooltipOverlayStyle?: React.CSSProperties;
  tooltipOverlayInnerStyle?: React.CSSProperties;
};

export const InfoTooltip = (props: Props) => {
  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;
  if (isDesktopView) {
    return (
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {props.children}
        <Tooltip
          title={props.title}
          placement={props.placement}
          overlayStyle={props.tooltipOverlayStyle}
          overlayInnerStyle={props.tooltipOverlayInnerStyle}
        >
          {props.useInfoIcon ? (
            <InfoCircleOutlined
              style={{ verticalAlign: 'middle', marginLeft: '5px' }}
              twoToneColor={theme.colors.primary[600]}
            />
          ) : (
            <QuestionCircleOutlined
              style={{ verticalAlign: 'middle', marginLeft: '5px' }}
              twoToneColor={theme.colors.primary[600]}
            />
          )}
        </Tooltip>
      </span>
    );
  }
  return (
    <Tooltip title={props.title}>
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {props.children}
        {props.useInfoIcon ? (
          <InfoCircleOutlined
            style={{ verticalAlign: 'middle', marginLeft: '5px' }}
            twoToneColor={theme.colors.primary[600]}
          />
        ) : (
          <QuestionCircleOutlined
            style={{ verticalAlign: 'middle', marginLeft: '5px' }}
            twoToneColor={theme.colors.primary[600]}
          />
        )}
      </span>
    </Tooltip>
  );
};
