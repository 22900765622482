import React from 'react';

import { useHistory } from 'react-router';

import { PlusOutlined } from '@ant-design/icons';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';

import { Button } from 'components/Button';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { track, TrackEvent, CtaName, CtaType } from 'utils/track';

export const NewProspectButton = () => {
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();

  const newProspect = () => {
    track(TrackEvent.Nav_CtaClick, {
      ctaName: CtaName.NewProspect,
      ctaType: CtaType.Button,
    });
    history.push(routes.sales.customerNew());
  };
  return shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateProspect) ? (
    <Button type="primary" onClick={newProspect} icon={<PlusOutlined />}>
      New Prospect
    </Button>
  ) : null;
};
