import React, { ReactNode } from 'react';

import { css } from '@emotion/css';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TablePaginationConfig, TableProps as AntTableProps } from 'antd/lib/table';
import { ColumnType, GetRowKey } from 'antd/lib/table/interface';
import { GetComponentProps } from 'rc-table/lib/interface';

import { ResponsiveTable } from 'components/ResponsiveTable';

export const DEFAULT_PAGE_SIZE = 20;

export interface TableProps extends AntTableProps<any> {
  id?: string;
  data: any[];
  isLoading?: boolean;
  rowKey?: string | GetRowKey<any>;
  rowSelection?: FIXME;
  size?: SizeType;
  columns: ColumnType<FIXME>[];
  summary?: () => ReactNode;
  /** Take full control of pagination to for api powered tables where data.length is not same as totalCount  */
  pagination?: false | TablePaginationConfig;
  /** For frontend only pagination where totalCount === data.length. If data.length <= pageSize, then pagination isn't shown. */
  pageSize?: number;
  onRow?: GetComponentProps<any>;
  verticalAlign?: string;
}

export const Table = (props: TableProps) => {
  const {
    id,
    columns,
    data,
    rowKey,
    isLoading,
    onRow,
    rowSelection,
    summary,
    size = 'small',
    verticalAlign = 'top',
    pagination,
    pageSize = DEFAULT_PAGE_SIZE,
    ...others
  } = props;

  let tablePagination = pagination;
  // for frontend only pagination, don't show pagination if data.length <= pageSize
  if (tablePagination === undefined) {
    tablePagination = data?.length <= pageSize ? false : { simple: true, pageSize };
  }

  return (
    <div
      className={css(`
        tbody {
          vertical-align: ${verticalAlign};
        }
      `)}
    >
      <ResponsiveTable
        id={id}
        columns={columns}
        rowSelection={rowSelection}
        size={size}
        dataSource={data}
        rowKey={rowKey}
        loading={isLoading}
        onRow={onRow}
        summary={summary}
        pagination={tablePagination}
        showSorterTooltip={false}
        {...others}
      />
    </div>
  );
};
