import React from 'react';

import { css } from '@emotion/css';
import { P21LeadTimeSource } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';
import { BorderedPropertyItem } from 'components/recipes/equation/BorderedPropertyItem';
import { Typography } from 'components/Typography';

import { formatNumber, formatPercent, formatUSD } from 'utils/formatting';

import { formatSafetyStock, safetyStockSourceNames } from '../utils';

function yearlyDemand(forecastDemand: number[], months = 12): number {
  return forecastDemand.slice(0, months).reduce((a, b) => a + b);
}

export const EOQFormulaMaxEquation = ({ record }: { record: SearchPlanningDTO }) => (
  <FlexSpace>
    <BorderedPropertyItem
      label="Forecast Over Lead Time"
      aria-disabled={record.recommended_min === record.recommended_max}
      value={`${formatNumber(record.lead_time_demand_qty ?? 0)} ${record.unit_of_measure}`}
      description={
        <Typography type="small" style={{ color: theme.colors.primary[500] }}>
          {`Lead Time Demand: ${formatNumber(record.avg_demand)} ${record.unit_of_measure} / day`}
          <br />
          {`Lead Time: ${formatNumber(record.lead_time)} days`}
          <br />
          {`Source: ${record.lead_time_source === P21LeadTimeSource.override ? 'Override' : 'Smart'}`}
        </Typography>
      }
    />
    <div
      className={css`
        align-self: stretch;
        padding-top: 16px;
      `}
    >
      <Typography type="large">+</Typography>
    </div>
    <BorderedPropertyItem
      label="Safety Stock Qty"
      aria-disabled={record.recommended_min === record.recommended_max}
      value={`${formatNumber(record.safety_stock_qty ?? 0)} ${record.unit_of_measure}`}
      description={
        <Typography type="small" style={{ color: theme.colors.primary[500] }}>
          {`Safety Stock Demand: ${formatNumber(record.avg_demand)} ${record.unit_of_measure} / day`}
          <br />
          {`Safety Stock: ${formatSafetyStock(record.safety_stock_value, record.safety_stock_type)}`}
          <br />
          {`Source: ${safetyStockSourceNames[record.safety_stock_type]}`}
        </Typography>
      }
    />
    <div
      className={css`
        align-self: stretch;
        padding-top: 16px;
      `}
    >
      <Typography type="large">+</Typography>
    </div>
    <BorderedPropertyItem
      label="Economic Order Qty"
      aria-disabled={record.recommended_min === record.recommended_max}
      value={`${formatNumber(record.eoq ?? 0)}  ${record.unit_of_measure}`}
      description={
        <Typography type="small" style={{ color: theme.colors.primary[500] }}>
          {`Yearly Forecast: ${formatNumber(yearlyDemand(record.forecast_demand))} ${record.unit_of_measure}`}
          <br />
          {`Replenishment Cost: ${formatUSD(record.order_cost, true, 2)} / order`}
          <br />
          {`Holding Cost: ${formatPercent(record.holding_cost)} / year`}
          <br />
          {`Unit Cost: ${formatUSD(record.unit_cost ?? 0, true, 2)}`}
        </Typography>
      }
    />
    <div
      className={css`
        align-self: stretch;
        padding-top: 16px;
      `}
    >
      <Typography type="large">{record.recommended_min === record.recommended_max ? '<' : '='}</Typography>
    </div>
    <BorderedPropertyItem
      label={record.recommended_min === record.recommended_max ? 'Recommended Min' : 'Recommended Max'}
      aria-disabled={record.recommended_min === record.recommended_max}
      value={`${formatNumber(record.recommended_max)} ${record.unit_of_measure}`}
    />
  </FlexSpace>
);
