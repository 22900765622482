import React from 'react';

import { SalesInvoiceLinesReportItemDTO } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';

import { DetailPageSection } from '../detailPage/DetailPageSections';
import { SalesReportSparkline } from './SalesReportSparkline';
import { SalesReportStatistics } from './SalesReportStatistics';

export const salesHistoryReportSections = ({
  stats,
  isLoading,
  yearsOfSparkline = 5,
}: {
  stats: SalesInvoiceLinesReportItemDTO | undefined;
  isLoading: boolean;
  yearsOfSparkline?: number;
}): DetailPageSection[] => [
  {
    title: `Sales`,
    children: <SalesReportStatistics stats={stats} isLoading={isLoading} />,
  },
  {
    title: `Sales ${new Date().getFullYear() - yearsOfSparkline} - Present`,
    children: <SalesReportSparkline stats={stats} isLoading={isLoading} yearsOfSparkline={yearsOfSparkline} />,
  },
];
