import React from 'react';

import { css } from '@emotion/css';

import { formatNumber } from 'utils/formatting';

export function MinMaxCell({
  label,
  value,
  unitOfMeasure,
  equationPopover,
}: {
  label: string;
  value: number | undefined;
  unitOfMeasure: string;
  equationPopover?: React.ReactNode;
}) {
  return (
    <div className={css``}>
      <div>{label}</div>
      <div>
        {formatNumber(value)} {unitOfMeasure || ''}
        {equationPopover}
      </div>
    </div>
  );
}
