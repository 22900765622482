import React from 'react';

import { css, cx } from '@emotion/css';
import { Note } from '@recurrency/core-api-schema/dist/common/notes';
import { theme } from 'theme';
import { fontWeights } from 'theme/typography';

import { LastUpdated } from '../LastUpdatedMoment';
import { WarningIconTooltip } from '../sidePane/WarningIconTooltip';

export const ViewNotesModalContent = ({ notes, mandatoryText }: { notes: Note[]; mandatoryText: string }) => (
  <ul
    className={css`
      width: 100%;
      padding-left: 0px;
      padding-top: 0px;
      list-style: none;
    `}
  >
    {notes?.map((note: Note, index: number) => (
      <li
        key={`modal_notes_${index}`}
        className={css`
          padding-bottom: 8px;
        `}
      >
        <div>
          <div
            className={css`
              font-weight: ${fontWeights.medium};
              display: inline-block;
              color: ${theme.colors.neutral[800]};
              display: flex;
              flex-direction: row;
              justify-content: start;
              align-items: center;
            `}
          >
            <span
              className={css`
                font-size: 16px;
              `}
            >
              {note.topic}
            </span>
            {note.isMandatory && (
              <span>
                <WarningIconTooltip tooltipText={mandatoryText} />
              </span>
            )}
            <span>
              <LastUpdated
                entityName="Note"
                dateString={note.tenantUpdatedAt}
                showIcon
                className={css`
                  margin-left: 10px;
                `}
              />
            </span>
          </div>
        </div>
        <div
          // Whitespace in css is used to respect whitespace characters in notes textfield
          className={cx(
            'NoteContainer',
            css`
              padding-left: 8px;
              white-space: pre-line;
            `,
          )}
        >
          {note.note}
        </div>
      </li>
    ))}
  </ul>
);
