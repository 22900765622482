import React from 'react';

import { theme } from 'theme';

import { Typography } from './Typography';

export function BetaTag() {
  return (
    <Typography
      style={{
        color: theme.colors.primary[700],
        marginRight: 0,
        marginLeft: '4px',
        fontSize: '0.6em',
        fontWeight: 'bold',
        verticalAlign: 'top',
        marginTop: '-0.6em',
      }}
    >
      BETA
    </Typography>
  );
}
