import React from 'react';

import { useHistory } from 'react-router-dom';

import { CheckOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { notification } from 'antd';

import { ActionButton } from 'components/Button/ActionButton';
import { AsyncButton } from 'components/Button/AsyncButton';
import { FlexSpace } from 'components/FlexSpace';
import { Tooltip } from 'components/Tooltip';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { pluralize } from 'utils/formatting';

export function MarkSupplierLocationsAsReviewedButton({
  isHubAndSpoke,
  supplierLocations,
}: {
  isHubAndSpoke: boolean;
  supplierLocations: Array<{ supplierId: string; locationId: string }>;
}) {
  const history = useHistory();
  const isSingleSupplierLocationMode = supplierLocations.length === 1;
  const notificationKey = 'reviewed-notif';
  const { supplierId: supplierId0, locationId: locationId0 } = supplierLocations[0];

  const handleMarkAsReviewedClick = async () => {
    try {
      const updateResults = await Promise.all(
        supplierLocations.map(({ supplierId, locationId }) =>
          coreApiFetch(schemas.purchasing.patchSupplierLocationSettings, {
            bodyParams: { supplierId, locationId, reviewed: true },
          }).then(() => ({ supplierId, locationId })),
        ),
      );

      const notificationText =
        updateResults.length === 1
          ? `Marked Supplier #${supplierId0} at Location #${locationId0} as reviewed`
          : `Marked ${pluralize(updateResults.length, 'Supplier-Location', 'Supplier-Locations', true)} as reviewed`;

      notification.success({
        key: notificationKey,
        message: (
          <FlexSpace direction="column">
            <span>{notificationText}</span>
            <ActionButton
              onClick={() => {
                history.goBack();
                notification.close(notificationKey);
              }}
              label={`Back to Purchase ${isHubAndSpoke ? 'Groups' : 'Targets'}`}
            />
          </FlexSpace>
        ),
        duration: 5,
      });
    } catch (err) {
      captureAndShowError(err, 'Failed to mark supplier locations as reviewed', {
        notificationKey,
      });
    }
  };

  return (
    <Tooltip
      title={
        isSingleSupplierLocationMode
          ? `Mark Supplier #${supplierId0} at Location #${locationId0} as reviewed as of today`
          : `Mark ${pluralize(
              supplierLocations.length,
              'Supplier-Location',
              'Supplier-Locations',
              true,
            )} reviewed as of today`
      }
    >
      <AsyncButton icon={<CheckOutlined />} onClick={handleMarkAsReviewedClick}>
        {isSingleSupplierLocationMode ? 'Mark as Reviewed' : 'Mark All as Reviewed'}
      </AsyncButton>
    </Tooltip>
  );
}
