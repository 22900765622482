import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ColumnType } from 'antd/lib/table';

import { getGlobalAppState } from 'hooks/useGlobalApp';

import { getLastTwoYears } from './date';
import { shouldShowFeatureFlag } from './roleAndTenant';

const [lastYear, thisYear] = getLastTwoYears();

export const costAndGMColumnSet = new Set<string>([
  'Unit Cost',
  'Ext. Cost',
  'Cost',
  'GM',
  'GM%',
  'GM YTD',
  'GM LYTD',
  // reporting uses dynamic columns e.g GM 2020, GM 2020 YTD
  `GM ${lastYear}`,
  `GM ${thisYear}`,
  `GM ${lastYear} YTD`,
  `GM ${thisYear} YTD`,
  '∆%',
  '∆ GM',
  'GM Δ',
  'Total GM',
  'Moving Avg Cost',
  'Ext. Margin',
  'Open GM',
  'Quote GM',
  'Fifo Cost',
  'Standard Cost',
  'Last Purchase Cost',
  'Supplier Cost',
]);

// Boolean function to determine if we show the column
export function filterCostAndGM<ColT = unknown>(col: ColumnType<ColT> | null): col is ColumnType<ColT> {
  const { activeTenant, activeUser } = getGlobalAppState();
  const shouldShowCostAndGM = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.SalesShowCostAndGrossMargin,
  );

  if (!col) return false;
  const isCostOrGMColumn = costAndGMColumnSet.has(col.title as string);
  return isCostOrGMColumn ? shouldShowCostAndGM : true;
}
