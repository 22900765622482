import React, { useEffect, useMemo, useState } from 'react';

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  StockOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PurchaseOrderDefaultsItemDTO } from '@recurrency/core-api-schema/dist/v2/purchaseOrders/getPurchaseOrderDefaults';
import Menu from 'antd/lib/menu';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { renderError } from 'pages/orders/quotes/QuoteEditFlowP21/QuoteLineItems/lineItemColumns';
import * as Styled from 'pages/orders/quotes/QuoteEditFlowP21/QuoteLineItems/QuoteLineItems.style';
import { DemandPlanningContextModal } from 'pages/purchasing/PlanningPage/modals/DemandPlanningContextModal';

import { AsyncSelect } from 'components/AsyncSelect';
import { LabelContainer, LabelSubtitle, LabelTitle } from 'components/AsyncSelect/AsyncSelect.style';
import { useItemsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { Input } from 'components/Input';
import { Select } from 'components/Select';

import { coreApiFetch } from 'utils/api';
import { optArrFromVal } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { joinIdNameObj, joinIfIdNameObj, pluralize, splitIdNameStr } from 'utils/formatting';
import { StatefulPromise } from 'utils/statefulPromise';
import { asKeyOf, typedColumn } from 'utils/tables';

import { PurchaseOrderEditHashState, PurchaseOrderLineItem } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import { validatePricePerUOM, validateQuantity } from './utils';

export interface LineItemActions {
  updateLineItem: (index: number, lineItem: Partial<PurchaseOrderLineItem>) => void;
  replaceLineItem: (index: number, lineItem: PurchaseOrderLineItem) => void;
  addEmptyLineItem: () => void;
  deleteLineItem: (index: number) => void;
  duplicateLineItem: (index: number) => void;
  moveLineItemUp: (index: number) => void;
  moveLineItemDown: (index: number) => void;
}

export interface PurchaseOrderLineItemContext {
  itemId?: string;
  itemDefaults?: PurchaseOrderDefaultsItemDTO | undefined;
}

export function getLineItemActions(
  docState: PurchaseOrderEditHashState,
  updateDocState: (update: Partial<PurchaseOrderEditHashState>) => void,
): LineItemActions {
  const updateDocStateLineItems = (lineItems: PurchaseOrderLineItem[]) => {
    updateDocState({ lineItems });
  };

  return {
    updateLineItem: (index: number, update: Partial<PurchaseOrderLineItem>) => {
      const newLineItems = [...(docState.lineItems || [])];
      newLineItems[index] = { ...newLineItems[index], ...update };
      updateDocStateLineItems(newLineItems);
    },
    replaceLineItem: (index: number, newLineItem: PurchaseOrderLineItem) => {
      const newLineItems = [...(docState.lineItems || [])];
      newLineItems[index] = newLineItem;
      updateDocStateLineItems(newLineItems);
    },
    addEmptyLineItem: () => {
      const newLineItems = [...(docState.lineItems || []), {}];
      updateDocStateLineItems(newLineItems);
    },
    deleteLineItem: (index: number) => {
      if (docState.lineItems) {
        updateDocStateLineItems(docState.lineItems.filter((_, idx) => idx !== index));
      }
    },
    duplicateLineItem: (index: number) => {
      const newLineItems = [...(docState.lineItems || [])];
      // insert duplicate item after its current index
      newLineItems.splice(index + 1, 0, { ...newLineItems[index] });
      updateDocStateLineItems(newLineItems);
    },
    moveLineItemUp: (index: number) => {
      const newLineItems = [...(docState.lineItems || [])];
      if (index > 0) {
        const lineItem = newLineItems[index];
        newLineItems[index] = newLineItems[index - 1];
        newLineItems[index - 1] = lineItem;
        updateDocStateLineItems(newLineItems);
      }
    },
    moveLineItemDown: (index: number) => {
      const newLineItems = [...(docState.lineItems || [])];
      if (index + 1 < newLineItems.length) {
        const lineItem = newLineItems[index];
        newLineItems[index] = newLineItems[index + 1];
        newLineItems[index + 1] = lineItem;
        updateDocStateLineItems(newLineItems);
      }
    },
  };
}

export function getLineItemColumns({
  selectedRowIdx,
  lineItems,
  lineItemsContext,
  lineItemActions,
  docState,
  isTenantSAPB1,
}: {
  selectedRowIdx: number | undefined;
  lineItems: PurchaseOrderLineItem[];
  lineItemsContext: PurchaseOrderLineItemContext[];
  lineItemActions: LineItemActions;
  docState: PurchaseOrderEditHashState;
  isTenantSAPB1: boolean;
}) {
  const lineItemColumns: ColumnType<PurchaseOrderLineItem>[] = [
    typedColumn({
      title: '#',
      width: '3%',
      render: (_, __, index) => (
        <>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: ${selectedRowIdx === index ? colors.primary[600] : `transparent`};
            `}
          />
          <div
            className={css`
              padding: 6px;
            `}
          >
            {index + 1}
          </div>
        </>
      ),
    }),
    typedColumn({
      title: 'Item',
      dataIndex: asKeyOf<PurchaseOrderLineItem>('item'),
      render: (_, lineItem, index) => (
        <ColumnSelectItem
          lineItem={lineItem}
          supplierId={docState.supplier?.foreignId}
          isTenantSAPB1={isTenantSAPB1}
          onChange={(item) => lineItemActions.replaceLineItem(index, { item })}
        />
      ),
    }),
    typedColumn({
      title: 'Quantity',
      dataIndex: asKeyOf<PurchaseOrderLineItem>('unitQuantity'),
      width: '15%',
      render: (_, lineItem, index) => (
        <>
          <ColumnQuantity
            lineItem={lineItem}
            onChange={(quantity) => lineItemActions.updateLineItem(index, { unitQuantity: quantity })}
          />
        </>
      ),
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: asKeyOf<PurchaseOrderLineItem>('unitOfMeasure'),
      width: '8%',
      render: (_, lineItem, index) => (
        <ColumnUOMs
          lineItem={lineItem}
          lineItemContext={lineItemsContext[index]}
          onChange={(unitOfMeasure, unitOfMeasureId) =>
            // clear unit price when UOM is changed, so new price for UOM can be applied
            lineItemActions.updateLineItem(index, { unitOfMeasure, unitOfMeasureId, unitPrice: undefined })
          }
        />
      ),
    }),
    typedColumn({
      title: 'Price per UOM',
      dataIndex: asKeyOf<PurchaseOrderLineItem>('unitPrice'),
      width: '15%',
      render: (_, lineItem, index) => (
        <ColumnPricePerUOM
          lineItem={lineItem}
          lineItemContext={lineItemsContext[index]}
          // We only update originalUnitPrice once it is applied
          // and originalUnitPrice won't change (readonly) even if unitPrice is manually changed
          onChange={(unitPrice, originalUnitPrice) =>
            originalUnitPrice
              ? lineItemActions.updateLineItem(index, { unitPrice, originalUnitPrice })
              : lineItemActions.updateLineItem(index, { unitPrice })
          }
        />
      ),
    }),
    typedColumn({
      align: 'center',
      title: 'Actions',
      width: '60px',
      render: (_, lineItem, index) => (
        <ColumnActions
          lineItemIdx={index}
          lineItemContext={lineItemsContext[index]}
          numLineItems={lineItems.length}
          lineItemActions={lineItemActions}
          docState={docState}
        />
      ),
    }),
  ];

  return lineItemColumns;
}

function ColumnSelectItem({
  lineItem,
  supplierId,
  isTenantSAPB1,
  onChange,
}: {
  lineItem: PurchaseOrderLineItem;
  supplierId?: string;
  isTenantSAPB1: boolean;
  onChange: (item: IdNameObj) => void;
}) {
  return (
    <Styled.TableCell>
      <AsyncSelect
        selectProps={useItemsSelectProps({ supplierId: isTenantSAPB1 ? undefined : supplierId })}
        entityPlural="items"
        value={joinIfIdNameObj(lineItem.item)}
        onSelect={(value: string) => onChange(splitIdNameStr(value))}
        dropdownMatchSelectWidth={550}
      />
    </Styled.TableCell>
  );
}

function ColumnQuantity({
  lineItem,
  onChange,
}: {
  lineItem: PurchaseOrderLineItem;
  onChange: (quantity: number) => void;
}) {
  // set default quantity as 1
  useEffect(() => {
    if (lineItem.item && lineItem.unitQuantity === undefined) {
      onChange(1);
    }
  }, [lineItem.item, lineItem.unitQuantity, onChange]);

  const updateQuantity = (quantity: number | string) => {
    if (typeof quantity === 'string') {
      quantity = (quantity !== '' ? Number(quantity) : '') as number;
    }

    onChange(quantity);
  };

  const error = validateQuantity(lineItem);

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={lineItem.unitQuantity}
        onChange={(ev) => updateQuantity(ev.target.value)}
        disabled={!lineItem.item}
        min={1}
      />
      <Styled.TableCellDetail>{renderError(error)}</Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnUOMs({
  lineItem,
  lineItemContext,
  onChange,
}: {
  lineItem: PurchaseOrderLineItem;
  lineItemContext: PurchaseOrderLineItemContext;
  onChange: (unitOfMeasure: string, unitOfMeasureId?: string) => void;
}) {
  const unitOfMeasures = lineItemContext.itemDefaults?.unitOfMeasures || [];

  // Update to default purchasing uom
  useEffect(() => {
    if (lineItem.item && !lineItem.unitOfMeasure && lineItemContext.itemDefaults?.unitOfMeasures) {
      const defaultUom = lineItemContext.itemDefaults.unitOfMeasures.find((uom) => uom.isPurchasingDefault);
      if (defaultUom) {
        onChange(defaultUom.unitOfMeasure, defaultUom.unitOfMeasureId);
      }
    }
  }, [lineItem, lineItemContext.itemDefaults, onChange]);

  return (
    <Styled.TableCell>
      <Select
        value={lineItem.unitOfMeasure}
        onChange={(_value, event: any) => onChange(event.data.unitOfMeasure, event.data?.unitOfMeasureId)}
        options={unitOfMeasures
          .filter((uom) => uom.unitOfMeasure !== null)
          .map((uom, idx) => ({
            value: uom.unitOfMeasure,
            label: (
              <LabelContainer key={idx}>
                <LabelTitle>{uom.unitOfMeasure}</LabelTitle>
                <LabelSubtitle>{pluralize(uom.unitSize ?? 1, 'unit', 'units', true)}</LabelSubtitle>
              </LabelContainer>
            ),
            data: uom,
          }))}
        disabled={!lineItem.item}
        isLoading={lineItem.item && !lineItemContext.itemDefaults}
      />
    </Styled.TableCell>
  );
}

function ColumnPricePerUOM({
  lineItem,
  lineItemContext,
  onChange,
}: {
  lineItem: PurchaseOrderLineItem;
  lineItemContext: PurchaseOrderLineItemContext;
  onChange: (unitPrice: number, originalUnitPrice?: number) => void;
}) {
  const error = validatePricePerUOM(lineItem);

  // set default unit price from selected uom
  useEffect(() => {
    if (lineItem.unitOfMeasure && lineItemContext.itemDefaults) {
      const uomInfo = lineItemContext.itemDefaults.unitOfMeasures.find(
        (uom) => uom.unitOfMeasure === lineItem.unitOfMeasure,
      );
      if (uomInfo && uomInfo.unitPrice && !lineItem.unitPrice) {
        onChange(uomInfo.unitPrice, uomInfo.originalUnitPrice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItem.unitOfMeasure, lineItemContext.itemDefaults]);

  const onChangePrice = (newPrice: number | string) => {
    // allow user to backspace to empty and enter new value by allowing setting _price to empty string.
    // this _price quantity won't be set to recommended value
    if (typeof newPrice === 'string') {
      newPrice = (newPrice !== '' ? Number(newPrice) : undefined) as number;
    }
    if (newPrice !== lineItem.unitPrice) {
      onChange(newPrice);
    }
  };

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={lineItem.unitPrice}
        onChange={(ev) => onChangePrice(ev.target.value)}
        disabled={!lineItem.item}
        isLoading={lineItem.item && !lineItemContext.itemDefaults}
        prefix="$"
      />
      {error && <Styled.TableCellDetail>{renderError(error)}</Styled.TableCellDetail>}
    </Styled.TableCell>
  );
}

function ColumnActions({
  lineItemIdx,
  lineItemContext,
  numLineItems,
  lineItemActions: actions,
  docState,
}: {
  lineItemIdx: number;
  lineItemContext: PurchaseOrderLineItemContext;
  numLineItems: number;
  lineItemActions: LineItemActions;
  docState: PurchaseOrderEditHashState;
}) {
  return (
    <Styled.TableCell>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {docState.location && lineItemContext.itemId && lineItemContext.itemDefaults ? (
              <>
                <Menu.Item
                  icon={<StockOutlined />}
                  onClick={() => {
                    if (lineItemContext.itemDefaults?.itemId) {
                      showAsyncModal(DemandPlanningContextModal, {
                        location: joinIdNameObj(docState.location!),
                        itemId: lineItemContext.itemId!,
                        itemUid: lineItemContext.itemDefaults.itemId,
                      });
                    }
                  }}
                >
                  Usage History
                </Menu.Item>
                <Menu.Divider />
              </>
            ) : null}
            {lineItemIdx > 0 && (
              <Menu.Item
                icon={<ArrowUpOutlined />}
                onClick={() => {
                  actions.moveLineItemUp(lineItemIdx);
                }}
              >
                Move Up
              </Menu.Item>
            )}
            {lineItemIdx + 1 < numLineItems && (
              <Menu.Item
                icon={<ArrowDownOutlined />}
                onClick={() => {
                  actions.moveLineItemDown(lineItemIdx);
                }}
              >
                Move Down
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item
              icon={<CopyOutlined />}
              onClick={() => {
                actions.duplicateLineItem(lineItemIdx);
              }}
            >
              Duplicate
            </Menu.Item>
            <Menu.Item
              className={css`
                color: ${colors.danger[500]};
              `}
              icon={<DeleteOutlined />}
              onClick={() => {
                actions.deleteLineItem(lineItemIdx);
              }}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Button icon={<DownOutlined />} style={{ width: '50px' }} />
      </Dropdown>
    </Styled.TableCell>
  );
}

const itemDefaultsCache = new Map<string, StatefulPromise<PurchaseOrderDefaultsItemDTO | undefined>>();

function getItemDefaultsCacheKey(itemCode: string, docState: PurchaseOrderEditHashState) {
  return [
    docState.company?.foreignId,
    docState.vendor?.foreignId,
    docState.supplier?.foreignId,
    docState.location?.foreignId,
    itemCode,
  ].join('|');
}

export function useFetchLineItemsContext(
  lineItems: PurchaseOrderLineItem[],
  docState: PurchaseOrderEditHashState,
): PurchaseOrderLineItemContext[] {
  const [refresh, setRefresh] = useState(0); // to refresh ui, once defaults are fetched

  return useMemo(() => {
    const lineItemsContext: PurchaseOrderLineItemContext[] = [];
    const itemIdsToFetch: Set<string> = new Set();

    for (const lineItem of lineItems) {
      if (lineItem.item) {
        const itemCode = lineItem.item.foreignId;
        const cacheKey = getItemDefaultsCacheKey(itemCode, docState);
        const itemDefaults = itemDefaultsCache.get(cacheKey)?.value;
        lineItemsContext.push({ itemId: itemCode, itemDefaults });
        if (!itemDefaultsCache.has(cacheKey)) {
          itemIdsToFetch.add(itemCode);
        }
      } else {
        lineItemsContext.push({ itemId: undefined, itemDefaults: undefined });
      }
    }

    if (itemIdsToFetch.size > 0 && docState.supplier && docState.location) {
      const respPromise = coreApiFetch(schemas.purchaseOrders.getPurchaseOrderDefaultsV2, {
        bodyParams: {
          companyIds: optArrFromVal(docState.company?.foreignId),
          locationId: docState.location.foreignId,
          vendorId: docState.vendor?.foreignId,
          supplierId: docState.supplier.foreignId,
          itemIds: Array.from(itemIdsToFetch),
        },
      });
      // refresh ui after promise finishes loading
      respPromise.finally(() => requestAnimationFrame(() => setRefresh(refresh + 1)));

      for (const itemCode of itemIdsToFetch) {
        itemDefaultsCache.set(
          getItemDefaultsCacheKey(itemCode, docState),
          new StatefulPromise(respPromise.then((res) => res.data.items.find((item) => item.itemCode === itemCode))),
        );
      }
    }

    return lineItemsContext;
  }, [docState, lineItems, refresh]);
}
