import { useEffect, useState } from 'react';

/**
 * StateStore is a simple object that holds state,
 * provides a setState function to change state,
 * and observe function for any listeners to be notified when state changes.
 */
export class StateStore<StateT> {
  public state: StateT;
  private _listeners: Array<(state: StateT) => void> = [];

  constructor(initialState: StateT) {
    this.state = initialState;
  }

  setState(newState: StateT) {
    this.state = newState;
    for (const listener of this._listeners) {
      listener(newState);
    }
    return this.state;
  }

  update(partialNewState: Partial<StateT>) {
    this.setState({ ...this.state, ...partialNewState });
    return this.state;
  }

  /**
   * adds a listener that gets called when state changes.
   * The return value of observe is a function that removes the listener */
  observe(listener: (state: StateT) => void) {
    if (!this._listeners.includes(listener)) {
      this._listeners.push(listener);
    }
    return () => {
      const idx = this._listeners.indexOf(listener);
      if (idx !== -1) {
        this._listeners.splice(idx, 1);
      }
    };
  }
}

/**
 * helper hook to observe/unobserve state changes in store
 */
export function useStateStore<StateT>(store: StateStore<StateT>) {
  // React re-renders when state is changed via setState.
  // When store state changes, it is synced to component's local state.
  // we don't need 'state' because we we're using setState as a way to trigger react to re-render.
  const [, setState] = useState<StateT>(store.state);

  useEffect(() => {
    // if store instance changes, sync state to the latest instance
    setState(store.state);
    return store.observe(setState);
  }, [store]);

  // return the latest state.
  // useEffect triggers after one animation frame, we don't want to return stale state
  return store.state;
}
