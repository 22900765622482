import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { ItemUnitOfMeasureDTO } from '@recurrency/core-api-schema/dist/v2/items/getItemUoms';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { sortableStringColumn, sortableNumberColumn, sortableBooleanColumn, asKeyOf } from 'utils/tables';

export const UOMTable = ({ itemId }: { itemId: string }) => {
  const columns: ColumnType<ItemUnitOfMeasureDTO>[] = [
    sortableStringColumn({
      title: 'UOM',
      dataIndex: asKeyOf<ItemUnitOfMeasureDTO>('unitOfMeasure'),
    }),
    sortableNumberColumn({
      title: 'Unit Size',
      dataIndex: asKeyOf<ItemUnitOfMeasureDTO>('unitSize'),
    }),
    sortableBooleanColumn({
      title: 'Sales Default',
      dataIndex: asKeyOf<ItemUnitOfMeasureDTO>('isSaleDefault'),
      defaultSortOrder: 'descend',
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    }),
    sortableBooleanColumn({
      title: 'Purchase Default',
      dataIndex: asKeyOf<ItemUnitOfMeasureDTO>('isPurchaseDefault'),
      defaultSortOrder: 'descend',
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    }),
  ];

  return (
    <AsyncTable
      tableProps={useCoreApiTableProps({
        schema: schemas.items.getItemUnitOfMeasuresV2,
        pathParams: {
          itemId,
        },
      })}
      columns={columns}
    />
  );
};
