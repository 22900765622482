/**
 * Returns a new object with {[key]:undefined} removed
 */
export function objRemoveUndefinedValues<ValT = any>(obj: Obj<ValT>): Obj<Exclude<ValT, undefined>> {
  const clone: any = { ...obj };
  for (const key of Object.keys(obj)) {
    if (clone[key] === undefined) delete clone[key];
  }
  return clone;
}

/**
 * Returns a new object with {[key]:""} removed
 */
export function objRemoveEmptyStringValues(obj: Obj): Obj {
  return objFilterValues(obj, (val, _) => val !== '');
}

/**
 * Returns a new object with {[key]:""|null|undefined} removed
 */
export function objRemoveNilValues(obj: Obj): Obj {
  return objFilterValues(obj, (val, _) => val !== '' || val === null || val === undefined);
}

/**
 * like Array.filter but for object values
 */
export function objFilterValues<ValT>(obj: Obj<ValT>, callbackFn: (val: ValT, key: string) => boolean): Obj<ValT> {
  const clone: Obj<ValT> = { ...obj };
  Object.keys(obj).forEach((key) => {
    if (!callbackFn(obj[key], key)) delete clone[key];
  });
  return clone;
}

/**
 * like Array.map but for object keys
 */
export function objMapKeys<ValT>(obj: Obj<ValT>, mapperFn: (key: string) => string): Obj<ValT> {
  const clone: Obj<ValT> = {};
  Object.keys(obj).forEach((key) => {
    clone[mapperFn(key)] = obj[key];
  });
  return clone;
}

/**
 * like Array.map but for object values
 */
export function objMapValues<ValT, MappedValT>(
  obj: Obj<ValT>,
  mapperFn: (val: ValT, key: string) => MappedValT,
): Obj<MappedValT> {
  const clone: Obj<MappedValT> = {};
  Object.keys(obj).forEach((key) => {
    clone[key] = mapperFn(obj[key], key);
  });
  return clone;
}

/**
 * type safe version of Object.keys that returns KeyT[] instead of string[]
 */
export function objKeys<ObjT extends Obj, KeyT extends keyof ObjT>(obj: ObjT): KeyT[] {
  return Object.keys(obj) as unknown as KeyT[];
}

/**
 * Returns a new object with a list of keys removed, inverse of objPickKeys
 */
export function objOmitKeys<ObjT extends Obj, KeyT extends keyof ObjT>(obj: ObjT, ...keys: KeyT[]): Omit<ObjT, KeyT> {
  const clone = { ...obj };
  for (const key of keys) {
    delete clone[key];
  }
  return clone;
}

/**
 * Returns a new object with only specific keys, inverse of objOmitKeys
 */
export function objPickKeys<ObjT extends Obj, KeyT extends keyof ObjT>(obj: ObjT, ...keys: KeyT[]): Pick<ObjT, KeyT> {
  const clone = {} as ObjT;
  for (const key of keys) {
    clone[key] = obj[key];
  }
  return clone;
}

/**
 * Safely retrives nested property of object or returns undefined
 * @example objGet(obj, ['foo', 'bar', 1]) is equivalent to obj?.foo?.bar[1]
 */
export function objGet(
  obj: Obj | any[] | undefined,
  key: string | number | Readonly<Array<string | number>> | undefined,
): any {
  if (!obj) {
    return undefined;
  }

  if (!key) {
    return obj;
  }

  let retVal: any = obj;
  if (Array.isArray(key)) {
    for (const subKey of key) {
      if (retVal !== null && typeof retVal === 'object') {
        retVal = retVal[subKey];
      } else {
        return undefined;
      }
    }
    return retVal;
  }

  return retVal[key as string];
}

/**
 * Safely sets nested property of object or array
 * @example objSet({}, ['foo', 'bar', 0], true) is equivalent to {foo: {bar: [true]}}
 */
export function objSet(obj: Obj | undefined, key: readonly string[], value: any): any {
  if (!obj) {
    obj = {};
  }

  for (let subObj = obj, i = 0; i < key.length; i++) {
    const subKey = key[i];
    if (i === key.length - 1) {
      subObj[subKey] = value;
    } else {
      if (subObj[subKey] === undefined) {
        subObj[subKey] = {};
      }
      subObj = subObj[subKey];
    }
  }

  return obj;
}

/**
 * Recursively flatten object values to a list
 * e.g {a: {b: "1", c: "2"}} ->  ["1", "2"]
 */
export function objFlattenedValues<ValT>(obj: Obj<ValT | Obj<ValT>>): ValT[] {
  return Object.values(obj)
    .map((val: any) => (typeof val === 'object' && val !== null ? objFlattenedValues(val) : val))
    .flat();
}

export function isObjEmpty(obj: Obj<any>): boolean {
  return !obj || Object.keys(obj).length === 0;
}

export function isEqual(obj1: Any, obj2: Any) {
  function areArraysEqual() {
    if (obj1.length !== obj2.length) return false;

    for (let i = 0; i < obj1.length; ++i) {
      if (!isEqual(obj1[i], obj2[i])) return false;
    }

    return true;
  }

  function areObjectsEqual() {
    const obj1Keys = Object.keys(obj1);
    if (obj1Keys.length !== Object.keys(obj2).length) return false;

    for (const key of obj1Keys) {
      if (!isEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  }

  // if strict equality matches, then it's equal
  if (obj1 === obj2) return true;

  const obj1Type = Object.prototype.toString.call(obj1);
  const obj2Type = Object.prototype.toString.call(obj2);

  if (obj1Type !== obj2Type) return false;
  if (obj1Type === '[object Array]') return areArraysEqual();
  if (obj1Type === '[object Object]') return areObjectsEqual();
  return false;
}

export function objFromArray<ValT>(arr: ValT[], key: keyof ValT): { [key: string]: ValT } {
  const obj: Record<string, ValT> = {};
  for (const val of arr) {
    obj[(val as Any)[key]] = val;
  }
  return obj;
}
