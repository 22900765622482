import React, { useMemo } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { TransferTargetLineDTO } from '@recurrency/core-api-schema/dist/transferOrders/common';

import { ItemNotesPanelContent } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/ItemNotesPanelContent';
import { LocationStockPanelContent } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/LocationStockPanelContent';
import { GenericTargetLineDTO } from 'pages/purchasing/PurchaseTargetsPage/types';

import { MiniTableSkeleton } from 'components/MiniTable';
import { InventoryUsagePanelContent } from 'components/recipes/sidePane/InventoryUsagePanelContent';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { UnitOfMeasuresPanelContent } from 'components/recipes/sidePane/UnitOfMeasuresPanelContent';

import { useCoreApi } from 'hooks/useApi';

import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

export function ItemSummarySidePane({ itemInfo }: { itemInfo: TransferTargetLineDTO }) {
  const { data: itemDetailsData, isLoading: isItemDetailsLoading } = useCoreApi(schemas.items.getItemDetailsV2, {
    pathParams: { itemId: itemInfo.itemCode },
  });

  const { data: itemSupplierData, isLoading: isItemSupplierLoading } = useCoreApi(schemas.items.getItemSuppliers, {
    pathParams: {
      itemUid: itemInfo.itemId,
    },
    queryParams: {
      filter: {
        locationId: itemInfo.destinationLocationId,
      },
      limit: 100,
      offset: 0,
    },
  });

  const primarySupplier = useMemo(
    () => itemSupplierData?.items?.find((supplier) => supplier.isPrimarySupplier),
    [itemSupplierData],
  );

  return (
    <SidePane
      title={`Item #${itemInfo.itemCode} between locations #${itemInfo.sourceLocationId} and #${itemInfo.destinationLocationId}`}
      settingKey={ViewSettingKey.TransferTargetLinesSidePane}
      description={itemInfo.itemName}
      extendedDescription={itemDetailsData?.itemDescription}
      content={
        isItemDetailsLoading || isItemSupplierLoading ? (
          <MiniTableSkeleton />
        ) : (
          <LabelValueProperties
            properties={
              [
                itemDetailsData
                  ? {
                      label: 'Item Group',
                      value: `${itemDetailsData?.itemGroupId}: ${itemDetailsData?.itemGroupName}`,
                    }
                  : null,
                primarySupplier
                  ? {
                      label: 'Primary supplier',
                      value: `${primarySupplier.supplierId}: ${primarySupplier.supplierName}`,
                    }
                  : null,
                { label: 'Replenishment Method', value: itemInfo.destinationReplenishmentMethod },
              ] as Array<LabelValueProperty | null>
            }
          />
        )
      }
      panels={[
        {
          settingKey: 'sourceUsage',
          title: 'Destination location usage',
          content: (
            <InventoryUsagePanelContent
              itemId={itemInfo.itemCode}
              itemUid={itemInfo.itemId}
              locations={[{ foreignId: itemInfo.destinationLocationId, name: itemInfo.destinationLocationName }]}
            />
          ),
        },
        {
          settingKey: 'destinationUsage',
          title: 'Source location usage',
          content: (
            <InventoryUsagePanelContent
              itemId={itemInfo.itemCode}
              itemUid={itemInfo.itemId}
              locations={[{ foreignId: itemInfo.sourceLocationId, name: itemInfo.sourceLocationName }]}
            />
          ),
        },
        {
          settingKey: 'itemNotes',
          title: 'Item Notes',
          content: (
            <ItemNotesPanelContent
              uniqueItems={[{ itemId: itemInfo.itemId, foreignId: itemInfo.itemCode, name: itemInfo.itemName }]}
            />
          ),
        },
        {
          settingKey: 'locationStock',
          title: 'Location Stock',
          content: (
            <LocationStockPanelContent
              targetLine={
                {
                  itemId: itemInfo.itemCode,
                  status: itemInfo.status,
                } as GenericTargetLineDTO
              }
            />
          ),
        },
        {
          settingKey: 'unitOfMeasures',
          title: 'Unit of Measures',
          content: <UnitOfMeasuresPanelContent itemId={itemInfo.itemCode} />,
        },
      ]}
    />
  );
}
