import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import {
  CalendarOutlined,
  CarryOutOutlined,
  CreditCardOutlined,
  DownOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  IdcardOutlined,
  ProfileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { GetSalesInvoiceLinesReportGroupBy } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { Menu } from 'antd';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { Dropdown } from 'components/Dropdown';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import {
  addressPropertyListItem,
  emailPropertyListItem,
  phoneNumberPropertyListItem,
} from 'components/recipes/detailPage/propertyListItemUtils';
import { ContactModal } from 'components/recipes/NewContactModal';
import { ShipToModal } from 'components/recipes/NewShipToModal';
import { SalesInvoiceLinesTable } from 'components/recipes/SalesInvoiceLinesTable';
import { salesHistoryReportSections } from 'components/recipes/salesReport/salesHistoryReportSections';
import { SalesReportTable } from 'components/recipes/salesReport/SalesReportTable';
import { FilterByField } from 'components/recipes/salesReport/salesReportUtils';
import { useSalesHistoryReport } from 'components/recipes/salesReport/useSalesHistoryReport';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { TaskModal } from 'components/recipes/TaskModal';
import { TasksTable } from 'components/recipes/TasksTable';
import { EllipsisTruncatedLabel } from 'components/Typography/EllpsisTruncatedLabel';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { optArrFromVal } from 'utils/array';
import { truthy } from 'utils/boolean';
import { dashesIfEmpty, formatAddressMultiLine, formatDate, formatUSD, joinIdNameObj } from 'utils/formatting';
import { shouldShowFeatureFlag, shouldShowProduct } from 'utils/roleAndTenant';
import { IdPathParams, routes, useHashState, usePathParams } from 'utils/routes';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { track, TrackEvent, CtaName, CtaType } from 'utils/track';

import { DetailsPageWithCompanyIdHashState } from 'types/hash-state';

import { ContactsTable } from './tabs/ContactsTable';
import { RecommendedItemsTable } from './tabs/RecommendedItemsTable';
import { SalesOrdersTable } from './tabs/SalesOrdersTable';
import { SalesQuotesTable } from './tabs/SalesQuotesTable';
import { SalesRepsTable } from './tabs/SalesRepsTable';
import { ShipTosTable } from './tabs/ShipTosTable';
import { TopItemsTable } from './tabs/TopItemsTable';

export const CustomerDetailsPage = () => {
  const history = useHistory();
  const { id: customerId } = usePathParams<IdPathParams>();
  const [hashState] = useHashState<DetailsPageWithCompanyIdHashState>();
  const companyId = hashState?.companyId;
  const { activeTenant, activeUser } = useGlobalApp();
  const {
    data: customer,
    error,
    isLoading,
  } = useCoreApi(schemas.customers.getCustomerDetails, {
    pathParams: { customerId },
    queryParams: { filter: { companyId } },
  });
  const {
    data: salesHistoryData,
    error: salesHistoryError,
    isLoading: salesHistoryIsLoading,
  } = useSalesHistoryReport({
    groupBy: GetSalesInvoiceLinesReportGroupBy.Customer,
    filter: {
      customerIds: optArrFromVal(customerId),
      companyIds: optArrFromVal(companyId),
    },
    ignoreRoleSalesRepIds: true,
  });
  const [newTaskModalOpen, setNewTaskModalOpen] = useState(false);
  const [newContactModalOpen, setNewContactModalOpen] = useState(false);
  const [newShipToModalOpen, setNewShipToModalOpen] = useState(false);

  if (error || salesHistoryError || !customerId) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !customer) {
    return <CenteredLoader />;
  }

  const sections: DetailPageSection[] = [
    {
      title: 'Customer Info',
      rows: [
        [
          customer.companyId
            ? {
                icon: <TeamOutlined />,
                label: 'Company',
                value: customer.companyName || '',
              }
            : null,
          phoneNumberPropertyListItem({
            label: 'Phone',
            value: customer.primaryPhone,
          }),
          {
            icon: <UserOutlined />,
            label: 'Primary Sales Rep',
            value: customer.primarySalesRepName,
            id: customer.primarySalesRepId,
          },
          {
            icon: <CreditCardOutlined />,
            label: 'Credit Status',
            value: dashesIfEmpty(customer.creditStatus),
          },
          emailPropertyListItem({
            label: 'Email',
            value: customer.primaryEmail && <EllipsisTruncatedLabel label={customer.primaryEmail} maxWidth="30ch" />,
          }),
          {
            icon: <CalendarOutlined />,
            label: 'Account Opened',
            value: formatDate(customer.dateAccountOpened),
          },
          {
            icon: <CreditCardOutlined />,
            label: customer.creditLimitUsed !== undefined ? `Credit Used / Limit` : 'Credit Limit',
            value:
              customer.creditLimitUsed !== undefined
                ? `${formatUSD(customer.creditLimitUsed)}
                 / ${
                   customer.creditLimit === 0 && isTenantErpTypeP21(activeTenant.erpType)
                     ? 'Unlimited'
                     : formatUSD(customer.creditLimit)
                 }`
                : customer.creditLimit === 0
                ? 'Unlimited'
                : formatUSD(customer.creditLimit),
          },
          phoneNumberPropertyListItem({
            label: 'Fax',
            value: customer.primaryFax,
          }),
          customer.class5Id
            ? {
                label: 'Class 5',
                value: customer.class5Name,
                id: customer.class5Id,
              }
            : null,
        ].filter(truthy),
      ],
    },
    {
      title: 'Addresses',
      rows: [
        [
          addressPropertyListItem({
            label: 'Bill To',
            value: formatAddressMultiLine({
              name: customer.customerName,
              address1: customer.billToAddress?.address1,
              address2: customer.billToAddress?.address2,
              city: customer.billToAddress?.city,
              state: customer.billToAddress?.state,
              zip: customer.billToAddress?.postalCode,
            }),
            id: customer.billToAddress?.addressId,
          }),
        ],
      ],
    },
    ...salesHistoryReportSections({
      stats: salesHistoryData?.items[0],
      isLoading: salesHistoryIsLoading,
    }),
  ];

  return (
    <Container>
      <PageHeader
        title={customer?.customerName}
        copyable
        entity={{
          kind: 'Customer',
          id: customer.customerId,
          badge: customer.customerType === 'prospect' && <StatusBadge status={BadgeStatus.Prospect} />,
        }}
        headerActions={
          <>
            <Dropdown
              overlay={
                <Menu>
                  {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) && (
                    <Menu.Item
                      onClick={() => {
                        track(TrackEvent.Nav_CtaClick, {
                          ctaName: CtaName.NewQuote,
                          ctaType: CtaType.Button,
                        });
                        history.push(
                          routes.orders.quoteNew({
                            customer: joinIdNameObj({
                              foreignId: customer.customerId,
                              name: customer.customerName,
                            }),
                            company:
                              customer.companyId && customer.companyName
                                ? joinIdNameObj({
                                    foreignId: customer.companyId,
                                    name: customer.companyName,
                                  })
                                : undefined,
                          }),
                        );
                      }}
                    >
                      <FileTextOutlined style={{ marginRight: 8 }} />
                      New Quote
                    </Menu.Item>
                  )}
                  {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) && (
                    <Menu.Item
                      onClick={() => {
                        track(TrackEvent.Nav_CtaClick, {
                          ctaName: CtaName.NewOrder,
                          ctaType: CtaType.Button,
                        });
                        history.push(
                          routes.orders.orderNew({
                            customer: joinIdNameObj({
                              foreignId: customer.customerId,
                              name: customer.customerName,
                            }),
                            company:
                              customer.companyId && customer.companyName
                                ? joinIdNameObj({
                                    foreignId: customer.companyId,
                                    name: customer.companyName,
                                  })
                                : undefined,
                          }),
                        );
                      }}
                    >
                      <ProfileOutlined style={{ marginRight: 8 }} />
                      New Order
                    </Menu.Item>
                  )}
                  <Menu.Item
                    onClick={() => {
                      track(TrackEvent.Nav_CtaClick, {
                        ctaName: CtaName.NewTask,
                        ctaType: CtaType.Button,
                      });
                      setNewTaskModalOpen(true);
                    }}
                  >
                    <CarryOutOutlined style={{ marginRight: 8 }} />
                    New Task
                  </Menu.Item>
                  <Menu.Item
                    disabled={!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateContact)}
                    onClick={() => {
                      track(TrackEvent.Nav_CtaClick, {
                        ctaName: CtaName.NewContact,
                        ctaType: CtaType.Button,
                      });
                      setNewContactModalOpen(true);
                    }}
                  >
                    <IdcardOutlined style={{ marginRight: 8 }} />
                    New Contact
                  </Menu.Item>
                  <Menu.Item
                    disabled={!shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesCreateShipTo)}
                    onClick={() => {
                      track(TrackEvent.Nav_CtaClick, {
                        ctaName: CtaName.NewShipTo,
                        ctaType: CtaType.Button,
                      });
                      setNewShipToModalOpen(true);
                    }}
                  >
                    <EnvironmentOutlined style={{ marginRight: 8 }} />
                    New Ship To
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="primary">
                New <DownOutlined />
              </Button>
            </Dropdown>
          </>
        }
      />
      <DetailPageSections sections={sections} />
      <NavTabs
        tabs={[
          {
            header: 'Sales History',
            content: <SalesInvoiceLinesTable customerId={customer.customerId} companyId={customer.companyId} />,
          },
          {
            header: 'Quotes',
            content: <SalesQuotesTable customerId={customer.customerId} companyId={customer.companyId} />,
          },
          {
            header: 'Orders',
            content: <SalesOrdersTable customerId={customer.customerId} companyId={customer.companyId} />,
          },
          {
            header: 'Top Items',
            content: <TopItemsTable customerId={customer.customerId} customerName={customer.customerName} />,
          },
          {
            header: 'Top Product Groups',
            content: (
              <SalesReportTable
                groupBy={GetSalesInvoiceLinesReportGroupBy.ItemGroup}
                filter={{ [FilterByField.Customer]: optArrFromVal(customer.customerId) }}
                ignoreRoleSalesRepIds
              />
            ),
          },
          shouldShowProduct(activeTenant, TenantProductName.SalesRecommendations) && {
            header: 'Recommended New Items',
            content: (
              <RecommendedItemsTable
                customerId={customer.customerId}
                customerName={customer.customerName}
                companyId={customer.companyId}
                companyName={customer.companyName}
              />
            ),
          },
          {
            header: 'Contacts',
            content: (
              <ContactsTable
                customerId={customer.customerId}
                customerName={customer.customerName}
                customerCompanyId={customer.companyId}
                showNewContactButton
              />
            ),
          },
          {
            header: 'Ship Tos',
            content: (
              <ShipTosTable
                customerId={customer.customerId || ''}
                companyId={customer.companyId || ''}
                showNewShipToButton
              />
            ),
          },
          shouldShowProduct(activeTenant, TenantProductName.SalesCrm) && {
            header: 'Tasks',
            content: <TasksTable customerId={customer.customerId} customerName={customer.customerName} />,
          },
          {
            header: 'Sales Reps',
            content: <SalesRepsTable customerId={customerId} companyId={customer.companyId} />,
          },
        ].filter(truthy)}
      />
      {newTaskModalOpen && (
        <TaskModal
          onClose={() => setNewTaskModalOpen(false)}
          initialValues={{
            customer: {
              companyId: customer.companyId,
              foreignId: customer.customerId,
              name: customer.customerName,
            },
          }}
        />
      )}
      {newContactModalOpen && (
        <ContactModal
          onClose={() => setNewContactModalOpen(false)}
          initialValues={{
            customer: {
              foreignId: customerId || '',
              name: customer.customerName || '',
              companyId: customer.companyId || '',
            },
          }}
        />
      )}
      {newShipToModalOpen && (
        <ShipToModal
          onClose={() => setNewShipToModalOpen(false)}
          initialValues={{
            customerId,
            companyId: customer.companyId,
          }}
        />
      )}
    </Container>
  );
};
