import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { PayoutDTO } from '@recurrency/core-api-schema/dist/payments/getPayouts';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { AsyncCursorPaginationTable } from 'components/AsyncTable';
import { useCursorPaginationTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Badge } from 'components/Badge';
import { Container } from 'components/Container';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { ButtonLink } from 'components/Links';
import { PageHeader } from 'components/PageHeader';

import { convertSnakeCaseToSpaceSplit, formatUSD } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';

import { ISODateStr, PaymentsPayoutsHashState } from 'types/hash-state';

interface DateRange {
  startDate: ISODateStr;
  endDate: ISODateStr;
}

export function PayoutListPage() {
  // Define local and hash states
  const [hashState, updateHashState] = useHashState<PaymentsPayoutsHashState>();
  const [now] = useState<Date>(new Date());

  const dateRange = useMemo((): DateRange => {
    if (hashState.startDate && hashState.endDate) {
      return {
        startDate: hashState.startDate,
        endDate: hashState.endDate,
      };
    }
    // if date range is not defined, show last 7 days by default
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    return {
      startDate: new Date(now.getTime() - oneDayInMilliseconds * 7).toISOString(),
      endDate: now.toISOString(),
    };
  }, [hashState.startDate, hashState.endDate, now]);

  const handleDateRangeChange = useCallback(
    (dates, _) => {
      updateHashState({
        startDate: dates ? dates[0]?.toISOString() : undefined,
        endDate: dates ? dates[1]?.toISOString() : undefined,
      });
    },
    [updateHashState],
  );

  // States updates
  const tableProps = useCursorPaginationTableProps({
    schema: schemas.payments.getPayouts,
    queryParams: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    },
  });

  useEffect(() => {
    tableProps.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashState]);

  const columns: ColumnType<PayoutDTO>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (_: any, record: PayoutDTO) => (
        <Link
          to={routes.payments.transactions({
            payoutId: record.id,
            createDate: record.created,
            amount: record.amount,
          })}
        >
          {record.id}
        </Link>
      ),
    },
    {
      title: 'Arrival Date',
      dataIndex: 'arrivalDate',
      render: (_: any, record: PayoutDTO) => new Date(record.arrivalDate).toLocaleString(),
    },
    {
      title: 'Created Date',
      dataIndex: 'created',
      render: (_: any, record: PayoutDTO) => new Date(record.created).toLocaleString(),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      render: (_: any, record: PayoutDTO) => `${record.currency.toLocaleUpperCase()}`,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (_: any, record: PayoutDTO) => `${formatUSD(record.amount / 100, true, 2)}`, // amount is in cents
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, record: PayoutDTO) => <Badge label={convertSnakeCaseToSpaceSplit(record.status)} />,
    },
    {
      title: '',
      dataIndex: 'action',
      render: (_: any, record: PayoutDTO) =>
        ButtonLink(
          routes.payments.transactions({
            payoutId: record.id,
            createDate: record.created,
            amount: record.amount,
          }),
        ),
    },
  ];

  return (
    <Container>
      <PageHeader title="Payouts" />
      <FilterBarBox dividerLine>
        <DateRangePicker
          onChange={handleDateRangeChange}
          format="MM/DD/YYYY"
          value={[moment(dateRange.startDate), moment(dateRange.endDate)]}
        />
      </FilterBarBox>
      <AsyncCursorPaginationTable
        columns={columns}
        rowKey={(record) => record.id}
        tableProps={{
          isLoading: tableProps.isLoading,
          items: tableProps.items,
          hasNextPage: tableProps.hasNextPage,
          goNextPage: tableProps.goNextPage,
          hasPrevPage: tableProps.hasPrevPage,
          goPrevPage: tableProps.goPrevPage,
        }}
      />
    </Container>
  );
}
