import React from 'react';

import { css } from '@emotion/css';
import { P21LeadTimeSource } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';
import { BorderedPropertyItem } from 'components/recipes/equation/BorderedPropertyItem';
import { Typography } from 'components/Typography';

import { formatNumber } from 'utils/formatting';

import { formatSafetyStock, safetyStockSourceNames } from '../utils';

export const EOQFormulaMinEquation = ({
  record,
  showOpOqVariant = false,
}: {
  record: SearchPlanningDTO;
  showOpOqVariant?: boolean;
}) => (
  <FlexSpace>
    <BorderedPropertyItem
      label="Forecast Over Lead Time"
      value={`${formatNumber(record.lead_time_demand_qty ?? 0)} ${record.unit_of_measure}`}
      description={
        <Typography type="small" style={{ color: theme.colors.primary[500] }}>
          {`Lead Time Demand: ${formatNumber(record.avg_demand)} ${record.unit_of_measure} / day`}
          <br />
          {`Lead Time: ${formatNumber(record.lead_time)} days`}
          <br />
          {`Source: ${record.lead_time_source === P21LeadTimeSource.override ? 'Override' : 'Smart'}`}
        </Typography>
      }
    />
    <div
      className={css`
        align-self: stretch;
        padding-top: 16px;
      `}
    >
      <Typography type="large">+</Typography>
    </div>
    <BorderedPropertyItem
      label="Order Cycle Demand"
      value={`${formatNumber(record.order_cycle_demand_qty ?? 0)} ${record.unit_of_measure}`}
      description={
        <Typography type="small" style={{ color: theme.colors.primary[500] }}>
          {`Order Cycle Demand: ${formatNumber(record.daily_avg_forecast_demand_over_order_cycle ?? 0)} ${
            record.unit_of_measure
          } / day`}
          <br />
          {`Order Cycle: ${formatNumber(record.order_cycle_days)} days`}
        </Typography>
      }
    />
    <div
      className={css`
        align-self: stretch;
        padding-top: 16px;
      `}
    >
      <Typography type="large">+</Typography>
    </div>
    <BorderedPropertyItem
      label="Safety Stock Qty"
      value={`${formatNumber(record.safety_stock_qty ?? 0)}  ${record.unit_of_measure}`}
      description={
        <Typography type="small" style={{ color: theme.colors.primary[500] }}>
          {`Safety Stock Demand: ${formatNumber(record.avg_demand)} ${record.unit_of_measure} / day`}
          <br />
          Safety Stock: {formatSafetyStock(record.safety_stock_value, record.safety_stock_type)}
          <br />
          Source: {safetyStockSourceNames[record.safety_stock_type]}
        </Typography>
      }
    />
    <div
      className={css`
        align-self: stretch;
        padding-top: 16px;
      `}
    >
      <Typography type="large">=</Typography>
    </div>
    <BorderedPropertyItem
      label={`Recommended ${showOpOqVariant ? 'Order Point' : 'Min'}`}
      value={`${formatNumber(record.recommended_min)} ${record.unit_of_measure}`}
    />
  </FlexSpace>
);
