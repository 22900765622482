import type { ReactNode } from 'react';

import {
  IntegratedErps,
  TenantFeatureFlag,
  TenantIntegrationStatus,
  TenantProductName,
  RecurrencyRole,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { TenantUserErpRoleDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';

import { ExtendedUser } from 'hooks/useGlobalApp';

export enum NavMode {
  /** Tenant is being set up via self serve integration portal */
  Setup = 'setup',
  /** Recurrency internal admin panel */
  Internal = 'internal',
  /** Tenant is on a free trial */
  Free = 'free',
}

export type NavSubModule = {
  slug: string;
  /** entityUrl slug represents the singular version of slug */
  entitySlug?: string;
  label: string;
  routeFn: () => string;
  featureFlag?: TenantFeatureFlag;
  products?: TenantProductName[];
  recurrencyRoles?: RecurrencyRole[];
  /** Erp type for which the sub module should be shown. Undefined is all erps */
  erpType?: IntegratedErps;
  navMode?: NavMode;
  /** takes the active user role to determine whether to show this submodule */
  isEnabledForErpRole?: (role: Maybe<TenantUserErpRoleDTO>) => boolean;
  /** Tenant integration statuses for which the sub module should be shown. Undefined is all statuses */
  tenantIntegrationStatuses?: TenantIntegrationStatus[];
  tenantSetting?: TenantSettingKey;
  customFilter?: (activeTenant: TenantDTO, activeUser: ExtendedUser) => boolean;
};

export type NavModule = {
  slug: string;
  label: string;
  routeFn?: () => string;
  selectedIcon: ReactNode;
  unselectedIcon: ReactNode;
  subModules: NavSubModule[];
};
