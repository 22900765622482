import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { Button } from 'components/Button';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { lineItemPagePropertyListItem } from 'components/recipes/detailPage/propertyListItemUtils';
import { LineItemRecommendationsSidePane } from 'components/recipes/sidePane/LineItemRecommendationsSidePane';
import { SplitPage } from 'components/SplitPage';
import { Typography } from 'components/Typography';

import { splitIfIdNameStr } from 'utils/formatting';
import { OrderType } from 'utils/track';

import { OrderEditHashStateSAPB1 } from 'types/hash-state';

import * as Styled from '../../../quotes/QuoteEditFlowP21/QuoteLineItems/QuoteLineItems.style';
import { getNumLineItems } from '../orderUtils';
import { LineItemActionsSAPB1, OrderLineItemSAPB1WithInfo } from '../types';
import { getLineItemColumns } from './getLineItemColumns';
import { LineItemDetailsSidePaneSAPB1 } from './LineItemDetailsSidePaneSAPB1';
import { OrderEditLineItemSummaryStats } from './OrderEditLineItemSummaryStats';

export function OrderEditLineItemsStep({
  docState,
  orderType,
  lineItemsWithInfo,
  lineItemActions,
}: {
  docState: OrderEditHashStateSAPB1;
  orderType: OrderType;
  lineItemsWithInfo: OrderLineItemSAPB1WithInfo[];
  lineItemActions: LineItemActionsSAPB1;
}) {
  const [selectedLineItemRowIdx, setSelectedLineItemRowIdx] = useState<number>();

  return (
    <SplitPage
      left={
        <OrderLineItemsList
          docState={docState}
          orderType={orderType}
          selectedLineItemRowIdx={selectedLineItemRowIdx}
          lineItemsWithInfo={lineItemsWithInfo}
          onLineItemRowIdxChange={(rowIdx) => {
            setSelectedLineItemRowIdx(rowIdx);
          }}
          lineItemActions={lineItemActions}
        />
      }
      right={
        <Styled.SidePaneContainer>
          {selectedLineItemRowIdx !== undefined && lineItemsWithInfo[selectedLineItemRowIdx]?.foreignId ? (
            <LineItemDetailsSidePaneSAPB1
              lineItem={lineItemsWithInfo[selectedLineItemRowIdx]}
              lineItemActions={lineItemActions}
              lineItemIdx={selectedLineItemRowIdx}
              orderState={docState}
            />
          ) : (
            <LineItemRecommendationsSidePane
              orderState={docState}
              orderType={orderType}
              onRecommendedItemAdd={(recItem) =>
                lineItemActions.addLineItem({
                  foreignId: recItem.itemCode,
                  name: recItem.itemName,
                  quantity: recItem.lastQty,
                  unitPrice: recItem.lastUnitPrice,
                  unitOfMeasure: recItem.unitOfMeasure,
                })
              }
            />
          )}
        </Styled.SidePaneContainer>
      }
    />
  );
}

export function OrderLineItemsList({
  docState,
  lineItemsWithInfo,
  selectedLineItemRowIdx,
  lineItemActions,
  onLineItemRowIdxChange,
  orderType,
}: {
  docState: OrderEditHashStateSAPB1;
  lineItemsWithInfo: OrderLineItemSAPB1WithInfo[];
  selectedLineItemRowIdx: number | undefined;
  lineItemActions: LineItemActionsSAPB1;
  orderType: OrderType;
  onLineItemRowIdxChange: (rowIdx: number | undefined) => void;
}) {
  const customer = splitIfIdNameStr(docState.customer);
  const shipTo = splitIfIdNameStr(docState.shipTo);
  const location = splitIfIdNameStr(docState.location);

  const lineItemColumns =
    customer && shipTo
      ? getLineItemColumns({
          selectedRowIdx: selectedLineItemRowIdx,
          lineItems: lineItemsWithInfo,
          lineItemActions,
          orderType,
          customer,
        })
      : [];

  const section: DetailPageSection = {
    title: 'Details',
    rows: [
      [
        lineItemPagePropertyListItem({
          label: 'Customer',
          value: customer?.name,
          id: customer?.foreignId,
        }),
        lineItemPagePropertyListItem({
          label: 'Ship To',
          value: shipTo?.foreignId || '',
        }),
        lineItemPagePropertyListItem({
          label: 'Location',
          id: location?.foreignId,
          value: location?.name,
        }),
      ],
    ],
  };
  return (
    <Styled.Container>
      <DetailPageSections sections={[section]} />
      <br />
      <Styled.Header>
        <Styled.HeaderLeft>
          <Typography>Items ({getNumLineItems(lineItemsWithInfo)})</Typography>
        </Styled.HeaderLeft>
      </Styled.Header>
      <Styled.ItemTable
        data={lineItemsWithInfo}
        columns={lineItemColumns}
        pagination={false}
        size="small"
        onRow={(_, rowIdx: number | undefined) => ({
          onClick: () => {
            onLineItemRowIdxChange(rowIdx);
          },
          onDoubleClick: () => {
            onLineItemRowIdxChange(rowIdx);
          },
        })}
      />
      <br />
      <Button block onClick={lineItemActions.addEmptyLineItem}>
        <Styled.AddButtonContent>
          <PlusOutlined /> Add Line
        </Styled.AddButtonContent>
      </Button>
      <Styled.Footer>
        <Styled.FooterContent>
          <OrderEditLineItemSummaryStats lineItems={lineItemsWithInfo} />
        </Styled.FooterContent>
      </Styled.Footer>
    </Styled.Container>
  );
}
