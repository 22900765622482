import React from 'react';

import { Container } from 'components/Container';
import { PageHeader } from 'components/PageHeader';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { InventoryMissionControl } from './InventoryMissionControl';

export const InventoryMissionControlPage = () => (
  <Container>
    <PageHeader
      title={
        <InfoTooltip title="Recurrency Inventory Mission Control is the central dashboard for monitoring purchasing performance and inventory status. All data on this page is filterable using the filters below. Use Alerts to stay on top of critical action items!">
          Inventory Mission Control
        </InfoTooltip>
      }
    />
    <InventoryMissionControl />
  </Container>
);
