import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Divider } from 'antd';

import { Modal } from 'components/Modal';

import { formatUSD, pluralize } from 'utils/formatting';

import { PaymentBreakdownLine } from './OrderPayPage';
import { PaymentMethodsTab } from './tabs/PaymentMethodsTab';

interface PaymentModalProps {
  onClose: () => void;
  customerId: string;
  companyId?: string;
  invoices: { id: string; amountRemaining: number; orderNo: string }[];
}

export function PayInvoicesModal({ onClose, invoices, customerId, companyId }: PaymentModalProps) {
  const chargeAmount = invoices.reduce((acc, invoice) => acc + invoice.amountRemaining, 0);
  const [transactionFee, setTransactionFee] = useState<number>();

  // set modal content/title/footer
  const title = `Payment for ${invoices.length} ${pluralize(invoices.length, 'Invoice')}`;
  const content = (
    <>
      {invoices.map((invoice) => (
        <>
          <PaymentBreakdownLine
            key={invoice.id}
            label={`Invoice #${invoice.id}`}
            value={formatUSD(invoice.amountRemaining, true, 2)}
          />
          {!!transactionFee && (
            <PaymentBreakdownLine label="Transaction Fee" value={formatUSD(transactionFee, true, 2)} />
          )}
          <PaymentBreakdownLine
            label="Total"
            value={formatUSD(Number(chargeAmount) + (transactionFee != null ? transactionFee : 0), true, 2)}
          />
          <Divider />
          <PaymentMethodsTab
            paymentInfo={{
              orderNo: invoice.orderNo,
              invoiceNo: invoice.id,
              customerId,
              customerName: '',
              contactId: '',
              contactName: '',
              companyId: companyId || '',
              companyName: '',
              shipToId: '',
              shipToName: '',
              requestedDate: '',
              rmaExpirationDate: '',
              salesSubTotal: '',
              taxTotal: '',
              cfTotalDue: chargeAmount.toString(),
              totalFreight: '',
              totalOtherCharge: '',
              downpayment: '',
              downpaymentPaid: '',
            }}
            setFee={setTransactionFee}
            transactionFee={transactionFee}
            isDirectCharge
            onClose={onClose}
          />
        </>
      ))}
    </>
  );

  return (
    <Modal
      title={title}
      okButtonProps={{ hidden: true }}
      visible
      closable={false}
      cancelText="Close/Hide"
      onCancel={() => onClose()}
      centered
      width={600}
      className={css`
        padding: 0;
      `}
    >
      {content}
    </Modal>
  );
}
