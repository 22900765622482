import { schemas } from '@recurrency/core-api-schema';
import { ItemLotDTO } from '@recurrency/core-api-schema/dist/items/getItemLots';
import { CreateSalesOrderSAPB1Body } from '@recurrency/core-api-schema/dist/salesOrders/createSalesOrder';
import { CreateSalesQuoteBody } from '@recurrency/core-api-schema/dist/salesQuotes/createSalesQuote';

import { coreApiFetch } from 'utils/api';
import { roundTo2Decimals, splitIfIdNameStr } from 'utils/formatting';
import { getSearchIndexItemByItemId } from 'utils/search/search';

import { OrderEditHashStateSAPB1, OrderLineItemSAPB1, QuoteEditHashStateSAPB1, LotSelection } from 'types/hash-state';

import { OrderLineItemSAPB1WithInfo } from './types';

export const PRICE_COST_PRECISION = 2;

export function getNumLineItems(lineItems: OrderLineItemSAPB1[]): number {
  return lineItems.filter((item) => !!item.foreignId).length;
}

export function getTotalPrice(lineItems: OrderLineItemSAPB1[]): number {
  return roundTo2Decimals(
    lineItems.reduce((total: number, item) => (item.quantity || 0) * (item.unitPrice || 0) + total, 0),
  );
}

export function getLineItemTrackProps(lineItems: OrderLineItemSAPB1[]) {
  return {
    numLineItems: getNumLineItems(lineItems),
    totalPrice: getTotalPrice(lineItems),
  };
}

export function validateQuantity(lineItem: OrderLineItemSAPB1WithInfo): string | null {
  if (lineItem.foreignId && !lineItem.isUomDefaultsLoading) {
    const { quantity } = lineItem;
    if (typeof quantity === 'number' && Number.isFinite(quantity)) {
      if (quantity < 0) {
        return 'Quantity must be greater than 0';
      }
    } else {
      return 'Quantity is required';
    }
  }
  return null;
}

export function validatePricePerUOM(lineItem: OrderLineItemSAPB1WithInfo): string | null {
  if (lineItem.foreignId && !lineItem.isUomDefaultsLoading) {
    const { unitPrice } = lineItem;
    if (typeof unitPrice === 'number' && Number.isFinite(unitPrice)) {
      if (unitPrice < 0) {
        return 'Price must be greater or equal to 0';
      }
    } else {
      return 'Price is required';
    }
  }
  return null;
}

export function validatePricingPrice(
  lineItem: OrderLineItemSAPB1WithInfo,
  pricingPrice: number | undefined,
): string | null {
  if (lineItem.foreignId && !lineItem.isUomDefaultsLoading) {
    if (typeof pricingPrice === 'number' && Number.isFinite(pricingPrice)) {
      if (pricingPrice < 0) {
        return 'Price must be greater or equal to 0';
      }
    } else {
      return 'Price is required';
    }
  }
  return null;
}

export function validateLineItems(lineItems: OrderLineItemSAPB1WithInfo[]): string[] {
  const errors: string[] = [];

  if (getNumLineItems(lineItems) === 0) {
    return ['At least one line item is required.'];
  }

  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i];
    const lineNum = i + 1;

    if (lineItem.isUomDefaultsLoading) {
      errors.push(`Line ${lineNum}: Must finish loading before proceeding.`);
    }

    if (validateQuantity(lineItem) || validatePricePerUOM(lineItem)) {
      errors.push(`Line ${lineNum}: Please fill required fields.`);
    }
  }

  return errors;
}

export function createOrderSAPB1BodyFromHashState(docState: OrderEditHashStateSAPB1): CreateSalesOrderSAPB1Body {
  const locationId = splitIfIdNameStr(docState.location)?.foreignId;
  return {
    sendPdfEmailAfterSubmit: true,
    customerId: splitIfIdNameStr(docState.customer)?.foreignId,
    customerPORef: docState.customerPORef,
    contactId: splitIfIdNameStr(docState.contact)?.foreignId,
    shipToId: splitIfIdNameStr(docState.shipTo)?.foreignId,
    carrierId: splitIfIdNameStr(docState.carrier)?.foreignId,
    shippingRouteId: docState.shippingRouteId,
    customerNote: docState.customerNote,
    dueDate: docState.dueDate,
    items: (docState.items || [])
      .filter((item) => !!item.foreignId)
      .map((item) => ({
        itemId: item.foreignId,
        // fallback to undefined if unitQuantity or unitPrice is empty string
        // this means the user is editing the value
        unitQuantity: item.quantity || undefined,
        unitPrice: item.unitPrice || undefined,
        unitOfMeasure: item.unitOfMeasure,
        locationId,
        priceSource: item.priceSource,
        batches:
          item.lotsSelected?.map((lot) => ({
            batchNumber: lot.lotId,
            unitQuantity: lot.quantity,
          })) || undefined,
        ocrCode: docState.ocrCode,
        ocrCode2: docState.ocrCode2,
      })),
  };
}

export function createQuoteSAPB1BodyFromHashState(docState: QuoteEditHashStateSAPB1): CreateSalesQuoteBody {
  // quote request body is same as order request body
  return createOrderSAPB1BodyFromHashState(docState);
}

export function getPriceInfoCacheKey(docState: OrderEditHashStateSAPB1, item: OrderLineItemSAPB1): string {
  return `${docState.customer}|${item.foreignId}`;
}

export function getLotInfoCacheKey(docState: OrderEditHashStateSAPB1, itemId: string) {
  return `${docState.customer}|${docState.location}|${itemId}`;
}

/** lotsAvgUnitCost overrides uomUnitCost (note: lotsAvgUnitCost is in selling unit size */
export const getLotOrSellingUnitCost = (lotsAvgUnitCost: number | undefined, sellingUnitCost: number) =>
  lotsAvgUnitCost || sellingUnitCost;

export function getDefaultLotSelection(lotsAvailable: ItemLotDTO[], lineItemQuantity: number): LotSelection {
  // use top-down approach to select lots; the top-down approach relies on the lotsAvailable ordering
  // e.g. if FIFO is desired, this util assumes that lotsAvailable is already sorted by lot creation date
  let totalRunningQuantitySelected = 0;
  const lotsSelected: LotSelection = [];

  for (const lot of lotsAvailable) {
    if (totalRunningQuantitySelected >= lineItemQuantity) break;
    const quantityToAdd = Math.min(lot.quantityAvailable, lineItemQuantity - totalRunningQuantitySelected);
    lotsSelected.push({
      lotId: lot.lotId,
      quantity: quantityToAdd,
      lotCostPerUOM: lot.unitCost,
    });
    totalRunningQuantitySelected += quantityToAdd;
  }

  return lotsSelected;
}

/**
 * 1.queries search items index to check if item.lots_assignable = true
 * 2 calls /items/:itemId/lots api to get lots available for item
 */
export async function fetchItemAvailableLots(itemId: string, locationId?: string): Promise<ItemLotDTO[] | null> {
  const algoliaItem = await getSearchIndexItemByItemId(itemId);
  if (algoliaItem.lots_assignable) {
    const itemLotsRes = await coreApiFetch(schemas.items.getItemLots, {
      pathParams: { itemId },
      queryParams: { locationId },
    });
    return itemLotsRes.data.items;
  }

  return null;
}
