import React, { useCallback, useEffect, useState } from 'react';

import {
  DeleteOutlined,
  DownOutlined,
  CopyOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  PercentageOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SalesOrderPriceDefaultsUomDTO } from '@recurrency/core-api-schema/dist/salesOrders/getSalesOrderPriceDefaults';
import { Menu } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import { calcGM, calcPrice, validateGrossMargin } from 'pages/orders/quotes/quoteUtils';

import { AsyncSelect } from 'components/AsyncSelect';
import { LabelContainer, LabelSubtitle, LabelTitle } from 'components/AsyncSelect/AsyncSelect.style';
import { useItemsWithDescSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Dropdown } from 'components/Dropdown';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatHumanDate, pluralize, roundTo2Decimals } from 'utils/formatting';
import { typedColumn } from 'utils/tables';
import { OrderType, QuoteEditLineItemActionsClickType, track, TrackEvent } from 'utils/track';

import { OrderLineItemSAPB1 } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import * as Styled from '../../../quotes/QuoteEditFlowP21/QuoteLineItems/QuoteLineItems.style';
import { PRICE_COST_PRECISION, validateQuantity, validatePricingPrice, getLotOrSellingUnitCost } from '../orderUtils';
import { OrderLineItemSAPB1WithInfo, LineItemActionsSAPB1 } from '../types';

type UpdateLineItemFn = (lineItem: Partial<OrderLineItemSAPB1>) => void;
type ReplaceLineItemFn = (lineItem: OrderLineItemSAPB1) => void;

function renderDetails(details: React.ReactChild, color: string, tooltip?: string, onClick?: () => void | undefined) {
  const typography = (
    <Typography
      style={{
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        padding: '4px 0 4px 0',
        color,
      }}
      onClick={onClick}
    >
      {details}
    </Typography>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{typography}</Tooltip>;
  }

  return typography;
}

function renderError(error: string | null, tooltip?: string) {
  return error && renderDetails(error, theme.colors.danger[500], tooltip);
}

export function getLineItemColumns({
  selectedRowIdx,
  lineItems,
  lineItemActions,
  orderType,
  customer,
}: {
  selectedRowIdx: number | undefined;
  lineItems: OrderLineItemSAPB1WithInfo[];
  lineItemActions: LineItemActionsSAPB1;
  orderType: OrderType;
  customer: IdNameObj;
}) {
  const lineItemColumns: Array<
    ColumnType<OrderLineItemSAPB1WithInfo> | { title: string; children: ColumnType<OrderLineItemSAPB1WithInfo>[] }
  > = [
    typedColumn({
      title: '#',
      render: (_, __, index) => (
        <>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: ${selectedRowIdx === index ? theme.colors.primary[600] : `transparent`};
            `}
          />
          <span>{index + 1}</span>
        </>
      ),
    }),
    typedColumn({
      title: 'Item ID',
      dataIndex: 'foreignId',
      width: '15%',
      render: (_, lineItem, index) => (
        <ColumnSelectItemId
          lineItem={lineItem}
          replaceLineItem={(_lineItem) => lineItemActions.replaceLineItem(index, _lineItem)}
        />
      ),
    }),
    typedColumn({
      title: 'Item Name',
      dataIndex: 'name',
      render: (_, lineItem) => <ColumnItemDescription lineItem={lineItem} />,
    }),
    {
      title: 'Quantity',
      children: [
        typedColumn({
          title: 'Quantity',
          dataIndex: 'quantity',
          width: '15%',
          render: (_, lineItem, index) => (
            <>
              <ColumnItemQuantity
                lineItem={lineItem}
                updateLineItem={(_lineItem) => lineItemActions.updateLineItem(index, _lineItem)}
              />
            </>
          ),
        }),
        typedColumn({
          title: 'UOM',
          dataIndex: 'unitOfMeasure',
          width: '8%',
          render: (_, lineItem, index) => (
            <ColumnItemSellingUOM
              lineItem={lineItem}
              updateLineItem={(_lineItem) => lineItemActions.updateLineItem(index, _lineItem)}
            />
          ),
        }),
      ],
    },
    {
      title: 'Price',
      children: [
        typedColumn({
          title: 'Price per UOM',
          width: '15%',
          render: (_, lineItem, index) => (
            <ColumnItemPricingPrice
              lineItem={lineItem}
              updateLineItem={(_lineItem) => lineItemActions.updateLineItem(index, _lineItem)}
              customer={customer}
            />
          ),
        }),
        typedColumn({
          title: 'UOM',
          dataIndex: 'pricingUnitOfMeasureId',
          width: '8%',
          render: (_, lineItem, index) => (
            <ColumnItemPricingUOM
              lineItem={lineItem}
              updateLineItem={(_lineItem) => lineItemActions.updateLineItem(index, _lineItem)}
            />
          ),
        }),
      ],
    },
    typedColumn({
      title: 'GM',
      align: 'center',
      width: '8%',
      render: (_, lineItem, index) => (
        <ColumnItemGM
          lineItem={lineItem}
          updateLineItem={(...args) => lineItemActions.updateLineItem(index, ...args)}
        />
      ),
    }),
    typedColumn({
      align: 'center',
      title: 'Actions',
      width: '60px',
      render: (_, __, index) => (
        <ColumnActions
          lineItemIdx={index}
          orderType={orderType}
          numLineItems={lineItems.length}
          lineItemActions={lineItemActions}
        />
      ),
    }),
  ];

  return lineItemColumns.filter(filterCostAndGM);
}

function ColumnSelectItemId({
  lineItem,
  replaceLineItem,
}: {
  lineItem: OrderLineItemSAPB1WithInfo;
  replaceLineItem: ReplaceLineItemFn;
}) {
  return (
    <Styled.TableCell>
      <AsyncSelect
        selectProps={useItemsWithDescSelectProps({})}
        entityPlural="items"
        value={lineItem.foreignId}
        onSelect={(selectedId: string, option: any) => replaceLineItem({ foreignId: selectedId, name: option.name })}
        dropdownMatchSelectWidth={550}
      />
    </Styled.TableCell>
  );
}

function ColumnItemDescription({ lineItem }: { lineItem: OrderLineItemSAPB1WithInfo }) {
  return (
    <Styled.TableCell>
      <Styled.TextSpacer />
      <Typography>{lineItem.name}</Typography>
      <Styled.TextSpacer />
    </Styled.TableCell>
  );
}

function ColumnItemQuantity({
  lineItem,
  updateLineItem,
}: {
  lineItem: OrderLineItemSAPB1WithInfo;
  updateLineItem: UpdateLineItemFn;
}) {
  const updateQuantity = (quantity: number | string) => {
    if (typeof quantity === 'string') {
      quantity = (quantity !== '' ? Number(quantity) : '') as number;
    }

    updateLineItem({ quantity });
  };

  const error = validateQuantity(lineItem);

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={lineItem.quantity}
        onChange={(ev) => updateQuantity(ev.target.value)}
        disabled={!lineItem.foreignId}
        min={1}
      />
      <Styled.TableCellDetail>{renderError(error)}</Styled.TableCellDetail>
    </Styled.TableCell>
  );
}

function ColumnItemSellingUOM({
  lineItem,
  updateLineItem,
}: {
  lineItem: OrderLineItemSAPB1WithInfo;
  updateLineItem: UpdateLineItemFn;
}) {
  // update price and cost for the selected UOM
  const updateUOM = (uom: SalesOrderPriceDefaultsUomDTO) => {
    updateLineItem({
      unitOfMeasure: uom.unitOfMeasure,
      unitOfMeasureId: uom.unitOfMeasureId,
      unitPrice: uom.unitPrice && roundTo2Decimals(uom.unitPrice),
      unitCost: uom.unitCost && roundTo2Decimals(uom.unitCost),
    });
  };

  return (
    <Styled.TableCell>
      <Select
        value={lineItem.unitOfMeasure}
        onChange={(_, event: any) => updateUOM(event.data)}
        options={
          lineItem.uomDefaults
            ? Object.values(lineItem.uomDefaults).map((uom, idx) => ({
                value: uom.unitOfMeasure,
                label: (
                  <LabelContainer key={idx}>
                    <LabelTitle>{uom.unitOfMeasure}</LabelTitle>
                    <LabelSubtitle>{pluralize(uom.unitSize, 'unit', 'units', true)}</LabelSubtitle>
                  </LabelContainer>
                ),
                data: uom,
              }))
            : []
        }
        disabled={!lineItem.foreignId}
        isLoading={lineItem.isUomDefaultsLoading}
      />
    </Styled.TableCell>
  );
}

function ColumnItemPricingUOM({
  lineItem,
  updateLineItem,
}: {
  lineItem: OrderLineItemSAPB1WithInfo;
  updateLineItem: UpdateLineItemFn;
}) {
  const updateUOM = (uom: SalesOrderPriceDefaultsUomDTO) => {
    updateLineItem({
      pricingUnitOfMeasureId: uom.unitOfMeasureId,
    });
  };

  return (
    <Styled.TableCell>
      <Select
        value={lineItem.pricingUnitOfMeasureId}
        onChange={(_, event: any) => updateUOM(event.data)}
        options={
          lineItem.uomDefaults
            ? Object.values(lineItem.uomDefaults).map((uom, idx) => ({
                value: uom.unitOfMeasureId!,
                label: (
                  <LabelContainer key={idx}>
                    <LabelTitle>{uom.unitOfMeasure}</LabelTitle>
                    <LabelSubtitle>{pluralize(uom.unitSize, 'unit', 'units', true)}</LabelSubtitle>
                  </LabelContainer>
                ),
                data: uom,
              }))
            : []
        }
        disabled={!lineItem.foreignId}
        isLoading={lineItem.isUomDefaultsLoading}
      />
    </Styled.TableCell>
  );
}

function ColumnItemPricingPrice({
  lineItem,
  updateLineItem,
  customer,
}: {
  lineItem: OrderLineItemSAPB1WithInfo;
  updateLineItem: UpdateLineItemFn;
  customer: IdNameObj;
}) {
  // track pricingPrice in useState so that we can update the unitPrice (sellingPrice) when the user backspaces
  const [pricingPrice, setPricingPrice] = useState<number>();
  const { foreignId: itemId, unitPrice: sellingPrice, unitOfMeasureId, pricingUnitOfMeasureId, uomDefaults } = lineItem;
  const sellingUnitSize = unitOfMeasureId && uomDefaults && uomDefaults[unitOfMeasureId]?.unitSize;
  const pricingUnitSize = pricingUnitOfMeasureId && uomDefaults && uomDefaults[pricingUnitOfMeasureId]?.unitSize;
  const error = validatePricingPrice(lineItem, pricingPrice);

  // convert from sellingPrice to pricingPrice
  useEffect(() => {
    if (sellingPrice && sellingUnitSize && pricingUnitSize) {
      setPricingPrice(roundTo2Decimals((sellingPrice / sellingUnitSize) * pricingUnitSize));
    }
  }, [sellingPrice, sellingUnitSize, pricingUnitSize]);

  const updatePricingPrice = useCallback(
    (newPricingPrice: number | string) => {
      // allow user to backspace to empty and enter new value
      if (typeof newPricingPrice === 'string') {
        newPricingPrice = (newPricingPrice !== '' ? Number(newPricingPrice) : '') as number;
      }

      // set pricing price in local state, and update selling price (aka unitPrice) in hash state
      // from api view point, it only cares about selling uom and selling price
      if (newPricingPrice !== pricingPrice && sellingUnitSize && pricingUnitSize) {
        setPricingPrice(newPricingPrice);
        const newSellingPrice = roundTo2Decimals((newPricingPrice / pricingUnitSize) * sellingUnitSize);
        if (newSellingPrice) {
          updateLineItem({ unitPrice: newSellingPrice });
        }
      }
    },
    [pricingPrice, sellingUnitSize, pricingUnitSize, updateLineItem],
  );

  // Suggested price feature is primarily for seafood sales, but available for all sapb1 tenants.
  // Since costs can change frequently, we suggest the price that
  // is the same as the last gross margin (GM) on last order for item + customer, if user input price has lower GM%
  const { data: lastInvoiceData } = useCoreApi(schemas.salesInvoiceLines.getSalesInvoiceLinesV2, {
    queryParams: { filter: { itemId, customerId: customer.foreignId }, limit: 1, offset: 0 },
  });
  const lastInvoice = lastInvoiceData?.items[0];
  const lastGrossMarginPercent = lastInvoice?.grossMarginPercent;
  // if lots are selected, adjusted suggested price to factor in lot vs selling uom unit cost difference
  const lotsAvgUnitCostFactor =
    lineItem.lotsAvgUnitCost && lineItem.unitCost ? lineItem.lotsAvgUnitCost / lineItem.unitCost : 1;
  const pricingUomCost = pricingUnitOfMeasureId && uomDefaults && uomDefaults[pricingUnitOfMeasureId]?.unitCost;
  let suggestedPricingPrice: number | null = null;
  if (lastGrossMarginPercent && pricingPrice && pricingUomCost) {
    suggestedPricingPrice = roundTo2Decimals((pricingUomCost / (1 - lastGrossMarginPercent)) * lotsAvgUnitCostFactor);
    if (suggestedPricingPrice <= pricingPrice) {
      // only show suggest pricing price, if salesrep is quoting lower than last GM%
      suggestedPricingPrice = null;
    }
  }

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={pricingPrice}
        onChange={(ev) => updatePricingPrice(ev.target.value)}
        disabled={!lineItem.foreignId}
        isLoading={lineItem.isUomDefaultsLoading}
        prefix="$"
      />
      {error && <Styled.TableCellDetail>{renderError(error)}</Styled.TableCellDetail>}
      {lastGrossMarginPercent && suggestedPricingPrice ? (
        <Tooltip
          title={`Cost may have increased. Click to match previous margin of ${roundTo2Decimals(
            lastGrossMarginPercent * 100,
          )}% from ${formatHumanDate(lastInvoice?.orderDate || '')}.`}
        >
          <Styled.TableCellDetail>
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 6px;
              `}
            >
              <ActionButton
                onClick={() => suggestedPricingPrice && updatePricingPrice(suggestedPricingPrice)}
                label={
                  <div
                    className={css`
                      display: flex;
                      gap: 4px;
                      align-items: center;
                      font-size: 12px;
                      padding: 4px 0 4px 0;
                    `}
                  >
                    <SyncOutlined />${suggestedPricingPrice}
                  </div>
                }
              />
            </div>
          </Styled.TableCellDetail>
        </Tooltip>
      ) : undefined}
    </Styled.TableCell>
  );
}

function ColumnItemGM({
  lineItem,
  updateLineItem,
}: {
  lineItem: OrderLineItemSAPB1WithInfo;
  updateLineItem: UpdateLineItemFn;
}) {
  const { unitPrice, unitCost, lotsAvgUnitCost } = lineItem;
  // track GM in useState so that we can update the GM when the user backspaces
  // instead of recomputing GM based on price/cost each time the user changes the input
  const [grossMargin, setGrossMargin] = useState<Maybe<number>>();
  // update GM when price or cost changes
  useEffect(
    () =>
      setGrossMargin(unitPrice && unitCost && calcGM(unitPrice, getLotOrSellingUnitCost(lotsAvgUnitCost, unitCost))),
    [unitPrice, unitCost, lotsAvgUnitCost],
  );

  const handleChangeGM = useCallback(
    (newGM: number | string) => {
      // allow user to backspace to empty and enter new value by allowing empty string for GM.
      if (typeof newGM === 'string') {
        newGM = (newGM !== '' ? Number(newGM) : '') as number;
      }

      if (unitCost) {
        const newUnitPrice = calcPrice(
          newGM || 0,
          getLotOrSellingUnitCost(lotsAvgUnitCost, unitCost),
          PRICE_COST_PRECISION,
        );
        // update GM value even if changing the GM didn't change the price
        if (newUnitPrice === unitPrice) {
          setGrossMargin(newGM);
        }
        updateLineItem({ unitPrice: newUnitPrice });
      }
    },
    [lotsAvgUnitCost, unitCost, unitPrice, updateLineItem],
  );

  return (
    <Styled.TableCell>
      <Input
        type="number"
        value={grossMargin || ''}
        onChange={(ev) => {
          handleChangeGM(ev.currentTarget.value);
        }}
        suffix={<PercentageOutlined />}
        disabled={!lineItem.foreignId || !unitCost}
        isLoading={lineItem.isUomDefaultsLoading}
        validate={validateGrossMargin}
      />
    </Styled.TableCell>
  );
}

function ColumnActions({
  orderType,
  lineItemIdx,
  numLineItems,
  lineItemActions: actions,
}: {
  orderType: OrderType;
  lineItemIdx: number;
  numLineItems: number;
  lineItemActions: LineItemActionsSAPB1;
}) {
  const trackDropdownClick = (dropdownClickType: QuoteEditLineItemActionsClickType) =>
    track(TrackEvent.Quotes_EditQuote_LineItem_DropDownClick, {
      dropdownClickType,
      orderType,
    });

  return (
    <Styled.TableCell>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {lineItemIdx > 0 && (
              <Menu.Item
                icon={<ArrowUpOutlined />}
                onClick={() => {
                  trackDropdownClick(QuoteEditLineItemActionsClickType.MoveUp);
                  actions.moveLineItemUp(lineItemIdx);
                }}
              >
                Move Up
              </Menu.Item>
            )}
            {lineItemIdx + 1 < numLineItems && (
              <Menu.Item
                icon={<ArrowDownOutlined />}
                onClick={() => {
                  trackDropdownClick(QuoteEditLineItemActionsClickType.MoveDown);
                  actions.moveLineItemDown(lineItemIdx);
                }}
              >
                Move Down
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item
              icon={<CopyOutlined />}
              onClick={() => {
                trackDropdownClick(QuoteEditLineItemActionsClickType.Duplicate);
                actions.duplicateLineItem(lineItemIdx);
              }}
            >
              Duplicate
            </Menu.Item>
            <Menu.Item
              className={css`
                color: ${theme.colors.danger[500]};
              `}
              icon={<DeleteOutlined />}
              onClick={() => {
                trackDropdownClick(QuoteEditLineItemActionsClickType.Delete);
                actions.deleteLineItem(lineItemIdx);
              }}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <Button icon={<DownOutlined />} style={{ width: '50px' }} />
      </Dropdown>
    </Styled.TableCell>
  );
}
