import React from 'react';

import { css, cx } from '@emotion/css';
import { Note } from '@recurrency/core-api-schema/dist/common/notes';
import { theme } from 'theme';
import { fontWeights } from 'theme/typography';

import { ActionButton } from 'components/Button/ActionButton';

import { WarningIconTooltip } from './WarningIconTooltip';

// max-height is based on line-height which is 1.5715em set in <body>
// Acquired through the calculated section of dev tools
const SECTION_LINE_HEIGHT = 1.5715;

export const NoteCell = ({
  header,
  notes,
  onViewAll,
  mandatoryTooltipText,
}: {
  header: string;
  notes: Note[];
  onViewAll: () => void;
  mandatoryTooltipText: string;
}) => (
  <div
    className={cx(
      'NoteCell',
      css`
        margin-bottom: 16px;
      `,
    )}
  >
    <div
      className={cx(
        'HeaderContainer',
        css`
          font-weight: ${fontWeights.medium};
        `,
      )}
    >
      {header}
    </div>
    {notes.map((note, idx) => (
      <div
        key={idx}
        className={cx(
          'NoteSection',
          css`
            white-space: normal;
            margin-bottom: 4px;
            padding-left: 8px;
          `,
        )}
      >
        <div
          key={idx}
          className={cx(
            'NoteTopicContainer',
            css`
              color: ${theme.colors.neutral[900]};
            `,
          )}
        >
          {note.topic}
          {note.isMandatory && <WarningIconTooltip tooltipText={mandatoryTooltipText} />}
        </div>
        <div>
          <div
            className={cx(
              'NoteContainer',
              css`
                color: ${theme.colors.neutral[600]};
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: ${SECTION_LINE_HEIGHT}em;
                max-height: ${SECTION_LINE_HEIGHT * 3}em;
              `,
            )}
          >
            {note.note}
          </div>
        </div>
      </div>
    ))}
    <div
      className={css`
        padding-left: 10px;
      `}
    >
      <ActionButton label="View Notes" onClick={onViewAll} />
    </div>
  </div>
);
