import React from 'react';

import { css } from '@emotion/css';

import { Typography } from 'components/Typography';

export interface LabelWithDescriptionProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  badge?: React.ReactNode;
}

export const LabelWithDescription = (props: LabelWithDescriptionProps) => (
  <div
    className={css`
      line-height: 1.3em;
    `}
  >
    <div>{props.title}</div>
    {props.description ? <Typography type="extrasmall">{props.description}</Typography> : null}
    {props.badge ? <> {props.badge}</> : null}
  </div>
);
