import React from 'react';

import { css } from '@emotion/css';

export const IconReferencDot = ({ color, radius = 5 }: { color: string; radius?: number }) => (
  <div
    className={css`
      display: flex;
      flex-direction: column;
      margin-right: ${radius / 2}px;
    `}
  >
    <svg height={radius * 4} width={radius * 4}>
      <circle cx={radius * 2} cy={radius * 2} r={radius} fill={color} />
    </svg>
  </div>
);
