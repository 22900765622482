import React from 'react';

import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { formatNumber } from 'utils/formatting';

import { calcLine, calcOp, calcProp, Equation } from './Equation';
import { EquationPopover } from './EquationPopover';

export interface RequiredQtyRecord {
  requiredQty: number;
  destinationMax: number;
  destinationMin: number;
  destinationNetStock: number;
  destinationBackOrderQty: number;
  destinationStatus: PurchaseTargetLineStatus;
  replenishmentMethod: string;
  unitOfMeasure: string;
}

export function RequiredQtyPopover({ record }: { record: RequiredQtyRecord }) {
  const showOpOq = record.replenishmentMethod === 'OP/OQ';
  const isNonStock = record.destinationStatus === PurchaseTargetLineStatus.Nonstock;
  const { destinationMin: orderPoint, destinationMax: orderQuantity } = record;
  const orderCount = Math.ceil((orderPoint - record.destinationNetStock) / orderQuantity);
  return (
    <EquationPopover title="Required Quantity Calculation">
      <Equation
        lines={[
          calcLine(
            calcProp('Required Quantity', `${formatNumber(record.requiredQty)} ${record.unitOfMeasure}`),
            calcOp('='),
            ...(isNonStock
              ? [
                  calcProp(
                    'Backordered Quantity',
                    `${formatNumber(record.destinationBackOrderQty)} ${record.unitOfMeasure}`,
                  ),
                ]
              : showOpOq
              ? [
                  calcProp('Number of Orders', `${formatNumber(orderCount)} ${record.unitOfMeasure}`),
                  calcOp('x'),
                  calcProp('Order Quantity', `${formatNumber(orderQuantity)} ${record.unitOfMeasure}`),
                ]
              : [
                  calcProp('Destination Max', `${formatNumber(record.destinationMax)} ${record.unitOfMeasure}`),
                  calcOp('-'),
                  calcProp(
                    'Destination Net Stock',
                    `${formatNumber(record.destinationNetStock)} ${record.unitOfMeasure}`,
                  ),
                ]),
          ),
        ]}
      />
    </EquationPopover>
  );
}
