import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { Divider, Modal as antdModal, notification } from 'antd';

import { TenantFeatureFlagsTable } from 'pages/internal/tenants/TenantDetailsPage/tabs/TenantFeatureFlagsTable';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { emailPropertyListItem } from 'components/recipes/detailPage/propertyListItemUtils';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { Tooltip } from 'components/Tooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import { dashesIfEmpty, formatDate } from 'utils/formatting';
import { LocalStorageKey, setLocalStorageItem } from 'utils/localStorage';
import { adminFeatureFlags } from 'utils/roleAndTenant';
import { IdPathParams, routes, usePathParams } from 'utils/routes';

import { EditUserDetailsModal } from './EditUserDetailsModal';
import { UserTenantsTable } from './tabs/UserTenantsTable';

const MAX_RECORDS = 1000;

export const UserDetailsPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();
  const history = useHistory();

  const { id } = usePathParams<IdPathParams>();

  const {
    data: userData,
    error: userError,
    isLoading: userIsLoading,
    reload: userReload,
  } = useCoreApi(schemas.users.getUserById, {
    pathParams: { id },
    queryParams: { includeAuth: true },
  });

  const {
    data: tenantData,
    error: tenantError,
    isLoading: tenantIsLoading,
  } = useCoreApi(schemas.tenants.getAllTenants, {
    queryParams: { limit: MAX_RECORDS },
  });

  const error = userError || tenantError;
  const isLoading = userIsLoading || tenantIsLoading;

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !userData) {
    return <CenteredLoader />;
  }

  const postRemove = async (tenantId: string) => {
    try {
      await coreApiFetch(schemas.tenants.deleteUser, {
        pathParams: { tenantId, userId: userData.id },
      });
      notification.success({ message: 'Removed user from tenant.' });
      userReload();
    } catch (err) {
      captureAndShowError(err, `Error while removing user from tenant`);
    }
  };

  const onRemove = (tenantId: string) => {
    antdModal.confirm({
      title: 'Are you sure you want to disconnect this user and tenant?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Disconnect',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postRemove(tenantId);
      },
      onCancel() {},
    });
  };

  const postDelete = async () => {
    try {
      await coreApiFetch(schemas.users.deleteUser, {
        pathParams: { id: userData.id },
      });
      notification.success({ message: 'Deleted user.' });
      history.push(routes.internal.userList());
    } catch (err) {
      captureAndShowError(err, `Error while deleting user`);
    }
  };

  const onDelete = () => {
    if (userData.id === activeUser?.id) {
      notification.error({ message: 'Cannot delete currently logged in user.' });
    } else {
      antdModal.confirm({
        title: 'Are you sure you want to delete this user?',
        icon: <ExclamationCircleOutlined />,
        content: 'This action cannot be undone.',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          postDelete();
        },
        onCancel() {},
      });
    }
  };

  const postChangePassword = async () => {
    try {
      await coreApiFetch(schemas.users.changePassword, {
        bodyParams: { id: userData.id },
      });
      notification.success({ message: 'Sent change password email!' });
    } catch (err) {
      captureAndShowError(err, `Error while sending change password email`);
    }
  };

  const onChangePassword = () => {
    antdModal.confirm({
      title: 'Are you sure you want to send a change password email?',
      okText: 'Send',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postChangePassword();
      },
      onCancel() {},
    });
  };

  const onEdit = async (record: FIXME) => {
    const refreshPage = await showAsyncModal(EditUserDetailsModal, {
      currentOrSelectedTenant: record,
      isTenantLocked: true,
      currentErpRoles: record.tenantUser?.roles,
      currentRecurrencyRoles: record.tenantUser?.recurrencyRoles,
      userData,
      tenantData,
    });
    if (refreshPage) {
      userReload();
    }
  };

  const addToTenant = async () => {
    const refreshPage = await showAsyncModal(EditUserDetailsModal, {
      currentOrSelectedTenant: { name: activeTenant?.name, id: activeTenant?.id },
      isTenantLocked: false,
      userData,
      tenantData,
    });
    if (refreshPage) {
      userReload();
    }
  };

  const getUserTypeBadge = (foreignId: Maybe<string>) => {
    if (!foreignId) {
      return BadgeStatus.UserImported;
    }
    if (foreignId.slice(0, 4) === 'waad') {
      return BadgeStatus.UserSSO;
    }
    if (foreignId.slice(0, 5) === 'auth0') {
      return BadgeStatus.UserAuth0;
    }
    return BadgeStatus.UserImported;
  };

  const getEmailTooltipMessage = (foreignId: Maybe<string>) => {
    if (getUserTypeBadge(foreignId) === BadgeStatus.UserImported) {
      return 'Imported users are not linked to a Recurrency account';
    }
    if (getUserTypeBadge(foreignId) === BadgeStatus.UserSSO) {
      return "SSO users can't have passwords reset through Recurrency";
    }
    return '';
  };

  const detailSections: DetailPageSection[] = [
    {
      title: 'User Info',
      rows: [
        [
          emailPropertyListItem({
            label: 'Email',
            value: userData.email,
          }),
          {
            icon: userData.emailVerified ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
            label: 'Email Verified',
            value: userData.emailVerified ? 'Yes' : 'No',
          },
          { icon: <IdcardOutlined />, label: 'UID', value: userData.id },
          { icon: <IdcardOutlined />, label: 'Foreign ID', value: dashesIfEmpty(userData.foreignId) },
          {
            icon: <FieldTimeOutlined />,
            label: 'Timezone',
            value: userData.timezone ? userData.timezone : 'None listed.',
          },
          {
            icon: <CalendarOutlined />,
            label: 'Last Login',
            value: formatDate(dashesIfEmpty(userData.lastLogin)),
          },
          {
            icon: <CalendarOutlined />,
            label: 'Created At',
            value: formatDate(userData.createdAt),
          },
          {
            icon: <CalendarOutlined />,
            label: 'Updated At',
            value: formatDate(userData.updatedAt),
          },
        ],
      ],
    },
  ];

  return (
    <Container>
      <PageHeader
        title={`${userData.firstName} ${userData.lastName}`}
        copyable
        entity={{
          kind: 'User',
          id: userData.email,
          badge: <StatusBadge status={getUserTypeBadge(userData.foreignId)} />,
        }}
        headerActions={
          <>
            <Tooltip title={getEmailTooltipMessage(userData.foreignId)}>
              <div>
                <Button
                  id="userdetails_changePassword"
                  key="0"
                  onClick={onChangePassword}
                  disabled={!!getEmailTooltipMessage(userData.foreignId)}
                >
                  Send Change Password Email
                </Button>
              </div>
            </Tooltip>
            <Button id="userdetails_delete" key="0" danger onClick={onDelete}>
              Delete
            </Button>
            <Button id="userdetails_edit" key="1" onClick={() => history.push(routes.internal.userEdit(userData.id))}>
              Edit
            </Button>
          </>
        }
      />

      <DetailPageSections sections={detailSections} />
      {getUserTypeBadge(userData.foreignId) !== BadgeStatus.UserImported && (
        <NavTabs
          tabs={[
            {
              header: 'Tenants',
              content: (
                <UserTenantsTable
                  user={userData}
                  onAddToTenantClick={addToTenant}
                  onEditClick={onEdit}
                  onRemoveClick={onRemove}
                />
              ),
            },

            // users can temporarily override their own feature flags for debugging
            userData.id === activeUser.id && activeUser.isRecurrencyAdmin
              ? {
                  header: 'Feature Flags',
                  content: (
                    <TenantFeatureFlagsTable
                      featureFlags={adminFeatureFlags(activeUser)}
                      onFeatureFlagsChange={(newFlags) => {
                        setLocalStorageItem(LocalStorageKey.AdminFeatureFlags, newFlags);
                      }}
                    />
                  ),
                }
              : null,
          ].filter(truthy)}
        />
      )}
      <Divider />
    </Container>
  );
};
