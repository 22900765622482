import React from 'react';

import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { Badge, BadgeProps, BadgeVariant } from '../Badge';

export const colorByStatus: Record<PurchaseTargetLineStatus, BadgeVariant> = {
  [PurchaseTargetLineStatus.Understock]: 'warning',
  [PurchaseTargetLineStatus.Overstock]: 'malibu',
  [PurchaseTargetLineStatus.Nonstock]: 'ocean',
  [PurchaseTargetLineStatus.Backordered]: 'danger',
  [PurchaseTargetLineStatus.Normal]: 'success',
};

export const labelByStatus: Record<PurchaseTargetLineStatus, string> = {
  [PurchaseTargetLineStatus.Understock]: 'Understock',
  [PurchaseTargetLineStatus.Overstock]: 'Overstock',
  [PurchaseTargetLineStatus.Nonstock]: 'Nonstock',
  [PurchaseTargetLineStatus.Backordered]: 'Backordered',
  [PurchaseTargetLineStatus.Normal]: 'Normal',
};

export type Props = BadgeProps & {
  status: PurchaseTargetLineStatus;
  inverted?: boolean;
};

export const TargetLineStatusBadge = (props: Props): JSX.Element => (
  <Badge variant={colorByStatus[props.status]} label={labelByStatus[props.status]} {...props} />
);
