/**
 * This module encapsulates data fetching needs for various select dropdown entities,
 * with an uniform UseAsyncSelectResponse interface.
 */

import React, { useCallback, useState } from 'react';

import { MailOutlined, PhoneOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ItemSupplierDTO } from '@recurrency/core-api-schema/dist/items/getItemSuppliers';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { SupplierDTO } from '@recurrency/core-api-schema/dist/suppliers/getSuppliers';
import { Divider } from 'antd';
import { fuzzyFilter, fuzzyMatch } from 'fuzzbunny';
import { theme } from 'theme';
import { colors } from 'theme/colors';
import { fontWeights } from 'theme/typography';
import { useDebounce } from 'use-debounce/lib';

import { SelectOption } from 'components/Select';

import { optArrFromVal } from 'utils/array';
import { truthy } from 'utils/boolean';
import { captureError } from 'utils/error';
import {
  formatPhoneNumber,
  formatAddress,
  formatShipTo,
  joinIdNameObj,
  formatNumber,
  splitIdNameStr,
} from 'utils/formatting';
import { objRemoveUndefinedValues } from 'utils/object';
import { isAdmin, shouldShowSearchIndex } from 'utils/roleAndTenant';
import { searchIndex } from 'utils/search/search';
import { DEFAULT_HITS_PER_PAGE } from 'utils/search/types';
import { track, TrackEvent } from 'utils/track';
import { qtyUnitConverter } from 'utils/units';

import { IdNameObj } from 'types/legacy-api';
import {
  SearchIndexCustomer,
  SearchIndexItem,
  SearchIndexSalesOrder,
  SearchIndexShipTo,
  SearchIndexVendor,
} from 'types/search-collections';

import { useCoreApi } from '../../hooks/useApi';
import { useGlobalApp } from '../../hooks/useGlobalApp';
import { usePromise } from '../../hooks/usePromise';
import * as Styled from './AsyncSelect.style';
import { highlightedLabel } from './types';

export interface UseAsyncSelectProps<DataT = unknown> {
  options: SelectOption<DataT>[];
  // Total count of options when not all are returned by api
  totalCount?: number;
  isLoading: boolean;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  onSelect?: (value: string) => void;
  reload?: () => void;
}

export interface CustomersSelectParams {
  companyId?: string;
  /** If true, append company ID in front of the label
   * This is used to differentiate customers with the same name from different companies
   * NOTE: this prepends the company values to the value as well
   */
  appendCompanyId?: boolean;
}

export interface VendorsSelectParams {
  companyId?: string;
}

export interface ItemsSelectParams {
  supplierId?: string;
}

export interface SalesOrdersSelectParams {
  customerId?: string;
}

export interface ShipTosSelectParams {
  customerId?: string;
  companyId?: string;
}

export function useSearchIndexSelectProps<HitT>({
  indexName,
  mapHitFn,
  salesRepRefined,
  filters,
}: {
  indexName: SearchIndexName;
  mapHitFn: (hit: HitT, index: number) => SelectOption;
  filters?: Obj<string[] | undefined>;
  salesRepRefined?: boolean;
}): UseAsyncSelectProps {
  const { activeErpRole } = useGlobalApp();
  const [hitsPerPage, setHitsPerPage] = useState(DEFAULT_HITS_PER_PAGE);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data: searchIndexResponse,
    isLoading,
    reload,
  } = usePromise(
    () =>
      searchIndex({
        indexName,
        query: searchQuery,
        filters: objRemoveUndefinedValues({
          salesrep_ids:
            salesRepRefined && !isAdmin(activeErpRole.foreignId, activeErpRole.name)
              ? activeErpRole.foreignIds
              : undefined,
          ...filters,
        }),
        hitsPerPage,
      }),
    [],
    {
      cacheKey: `search:${JSON.stringify([indexName, hitsPerPage, filters, searchQuery])}`,
      onError: captureError,
    },
  );

  const numHits = searchIndexResponse?.nbHits ?? 0;
  const handleLoadMoreClick = useCallback(() => {
    track(TrackEvent.Components_AlgoliaConnectedFormItem_LoadMore, {
      searchQuery,
      searchQueryLength: searchQuery.length,
      numResults: numHits,
    });
    setHitsPerPage(hitsPerPage + DEFAULT_HITS_PER_PAGE);
  }, [searchQuery, numHits, hitsPerPage]);

  // @ts-expect-error HitT is custom type, hits.map complains
  const options = searchIndexResponse ? searchIndexResponse.hits.map(mapHitFn) : [];

  if (searchIndexResponse && searchIndexResponse.nbHits > hitsPerPage) {
    // Add special load more option, if there are more hits to be shown
    options.push({
      value: '$loadMore',
      label: <div onClick={handleLoadMoreClick}>Load More ({searchIndexResponse.nbHits.toLocaleString()} total)</div>,
      disabled: true,
      style: { cursor: 'pointer', color: theme.colors.primary[600] },
    });
  }

  // reset hitsPerPage after user makes a selection
  const onSelect = useCallback(() => {
    setHitsPerPage(DEFAULT_HITS_PER_PAGE);
  }, [setHitsPerPage]);

  return {
    isLoading,
    reload,
    options: fuzzyHighlight(options, searchQuery),
    totalCount: numHits,
    searchQuery,
    setSearchQuery,
    onSelect,
  };
}

export type CustomerSelectOption = SelectOption & {
  terms_desc?: string;
  company?: string;
};

export function useCustomersSelectProps({ companyId, appendCompanyId }: CustomersSelectParams): UseAsyncSelectProps {
  return useSearchIndexSelectProps<SearchIndexCustomer>({
    indexName: SearchIndexName.Customers,
    // Here companyId can be empty string, which will cause algolia fetch failure
    filters: { company_id: companyId ? [companyId] : undefined },
    mapHitFn: (hit) => ({
      value: `${appendCompanyId ? `${hit.company_id}:` : ''}${hit.customer_id}: ${hit.customer_name}`,
      label: (
        <Styled.LabelContainer>
          <Styled.LabelTitle>
            {appendCompanyId ? `[${hit.company_id}] ` : ''}
            {hit.customer_id}: {hit.customer_name}
          </Styled.LabelTitle>
          <Styled.LabelSubtitle>
            {formatAddress(hit.phys_address1, hit.phys_city, hit.phys_state, hit.phys_postal_code)}
          </Styled.LabelSubtitle>
        </Styled.LabelContainer>
      ),
      dropdownLabel: (
        <Styled.LabelTitle>
          {appendCompanyId ? `[${hit.company_id}] ` : ''}
          {hit.customer_id}: {hit.customer_name}
        </Styled.LabelTitle>
      ),
      terms_desc: hit.terms_desc,
      company: hit.company,
      company_id: hit.company_id,
    }),
    salesRepRefined: true,
  });
}

export function useVendorsSelectProps({ companyId }: VendorsSelectParams): UseAsyncSelectProps {
  return useSearchIndexSelectProps<SearchIndexVendor>({
    indexName: SearchIndexName.Vendors,
    filters: { company_id: companyId ? [companyId] : undefined },
    mapHitFn: (hit) => ({
      value: `${hit.vendor_id}: ${hit.vendor_name}`,
    }),
  });
}

export function useItemsSelectProps({ supplierId }: ItemsSelectParams = {}): UseAsyncSelectProps {
  // use the faster, more typo tolerant search index if available, otherwise fall back to postgres
  // we call hooks conditionally here because tenant product list will never update without a page reload
  const { activeTenant } = useGlobalApp();
  return shouldShowSearchIndex(activeTenant, SearchIndexName.Items)
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useItemsSelectPropsSearchIndex({ supplierId })
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useItemsSelectPropsPostgres({ supplierId });
}

export function useItemsSelectPropsSearchIndex({ supplierId }: ItemsSelectParams = {}): UseAsyncSelectProps {
  return useSearchIndexSelectProps<SearchIndexItem>({
    indexName: SearchIndexName.Items,
    filters: { supplier_ids: supplierId ? [supplierId] : undefined },
    mapHitFn: (hit) => ({
      uid: hit.id,
      value: `${hit.item_id}: ${hit.item_desc}`,
      label: (
        <Styled.LabelContainer>
          <Styled.LabelTitle>
            {hit.item_id}: {hit.item_desc}
          </Styled.LabelTitle>
          <Styled.LabelSubtitle>{hit.extended_desc || ''}</Styled.LabelSubtitle>
        </Styled.LabelContainer>
      ),
      dropdownLabel: (
        <Styled.LabelTitle>
          {hit.item_id}: {hit.item_desc}
        </Styled.LabelTitle>
      ),
    }),
  });
}

export function useItemsSelectPropsPostgres({ supplierId }: ItemsSelectParams = {}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const { data, isLoading } = useCoreApi(schemas.items.getItems, {
    queryParams: {
      searchQuery: debouncedSearchQuery,
      supplierId,
    },
  });

  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: joinIdNameObj({ foreignId: item.itemId, name: item.itemName }),
  }));

  if (data && data.totalCount > data.items.length) {
    options.push({
      value: '$totalCount',
      label: `Showing ${data.items.length} / ${data.totalCount.toLocaleString()} results, search for more.`,
      disabled: true,
      style: { cursor: 'pointer', color: colors.neutral[500], fontSize: '12px' },
    });
  }

  return {
    isLoading,
    options,
    totalCount: data?.totalCount || 0,
    searchQuery,
    setSearchQuery,
  };
}

export function useSalesOrdersSelectProps({ customerId }: SalesOrdersSelectParams): UseAsyncSelectProps {
  return useSearchIndexSelectProps<SearchIndexSalesOrder>({
    indexName: SearchIndexName.SalesOrders,
    filters: { customer_id: customerId ? [customerId] : undefined },
    mapHitFn: (hit) => ({
      value: `${hit.order_no}: ${hit.status} Order for ${hit.customer_name}`,
    }),
  });
}

export function useItemsWithDescSelectProps({
  supplierId,
  location,
}: {
  supplierId?: string;
  location?: IdNameObj;
}): UseAsyncSelectProps {
  return useSearchIndexSelectProps<SearchIndexItem>({
    indexName: SearchIndexName.Items,
    filters: { supplier_ids: supplierId ? [supplierId] : undefined },
    mapHitFn: (hit, idx) => ({
      value: hit.item_id,
      label: (() => {
        const locationKey = Object.keys(hit.stock_by_location).find(
          (key) => splitIdNameStr(key).foreignId === location?.foreignId,
        );
        return (
          <Styled.LabelContainer key={idx}>
            <Styled.LabelTitle
              className={css`
                font-weight: ${fontWeights.medium};
              `}
            >
              {hit.item_id}
              {hit.is_assembly ? (
                <>
                  &nbsp;
                  <DeploymentUnitOutlined />
                </>
              ) : null}
              : {hit.item_desc}
            </Styled.LabelTitle>
            <Styled.LabelSubtitle>{hit.extended_desc}</Styled.LabelSubtitle>
            <Styled.LabelSubtitle>
              {location && locationKey && (
                <>
                  Location: {formatNumber(qtyUnitConverter(hit.stock_by_location[locationKey], hit.unit_size))}{' '}
                  {hit.uom}
                  <Divider
                    type="vertical"
                    className={css`
                      border-color: ${theme.colors.primary[400]};
                    `}
                  />
                </>
              )}
              Company: {formatNumber(qtyUnitConverter(hit.stock, hit.unit_size))} {hit.uom}
            </Styled.LabelSubtitle>
          </Styled.LabelContainer>
        );
      })(),
      dropdownLabel: (
        <Styled.LabelContainer>
          <Styled.LabelTitle
            className={css`
              font-weight: ${fontWeights.medium};
            `}
          >
            {hit.item_id}: {hit.item_desc}
          </Styled.LabelTitle>
          <Styled.LabelSubtitle>{hit.extended_desc}</Styled.LabelSubtitle>
        </Styled.LabelContainer>
      ),
      name: hit.item_desc,
      description: hit.extended_desc,
      lotsAssignable: hit.lots_assignable,
      invMastUid: hit.inv_mast_uid,
      isAssembly: hit.is_assembly,
      customerPartIdAssignable: hit.customer_part_ids && hit.customer_part_ids.length > 0,
    }),
  });
}

export function useSalesRepsSelectProps({
  companyId,
  customerId,
}: {
  companyId?: string;
  customerId?: string;
}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.salesReps.getSalesReps, {
    queryParams: {
      filter: { companyId, customerId },
    },
  });
  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: `${item.salesRepId}: ${item.name}`,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export interface BuyerOptionData {
  email?: string;
}

export function usePurchasingBuyersSelectProps(): UseAsyncSelectProps<BuyerOptionData> {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.buyers.getBuyers);
  const options: SelectOption<BuyerOptionData>[] = (data?.items || []).map((item) => ({
    value: `${item.buyerId}: ${item.buyerName}`,
    data: { email: item.buyerEmailAddress },
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useLocationsSelectProps({
  companyIds,
  sortByValueProp,
}: {
  companyIds?: string[];
  sortByValueProp?: boolean;
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.locations.getLocations, {
    queryParams: { filter: { companyIds } },
  });
  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: `${item.locationId}: ${item.locationName}`,
  }));

  const sortedOptions = sortByValueProp
    ? options.sort((o1, o2) => {
        if (o1.value > o2.value) return 1;
        if (o1.value < o2.value) return -1;
        return 0;
      })
    : options;

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(sortedOptions, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useBranchesSelectProps({ companyId }: { companyId?: string }): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.branches.getBranches, {
    queryParams: {
      filter: { companyIds: optArrFromVal(companyId) },
    },
  });
  const options: SelectOption[] = (data?.items || [])
    .sort((a, b) => a.branchId.localeCompare(b.branchId))
    .map((item) => ({
      value: `${item.branchId}: ${item.branchName}`,
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useCompaniesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { activeCompanyIds, userAllowedCompanies } = useGlobalApp();

  // only show active companies
  let activeCompanies = userAllowedCompanies;
  if (activeCompanyIds) {
    activeCompanies = activeCompanies.filter((c) => activeCompanyIds.includes(c.companyId));
  }

  const options: SelectOption[] = activeCompanies.map((item) => ({
    value: `${item.companyId}: ${item.companyName}`,
  }));

  return {
    isLoading: false,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

// Tenants from the user has access to
export function useActiveUserTenantsSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { activeUser } = useGlobalApp();
  const { data, isLoading } = useCoreApi(schemas.users.getUserById, {
    pathParams: { id: activeUser.id },
    queryParams: { includeAuth: true },
  });

  const options: SelectOption[] = (data?.tenants || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useTenantUsersSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { activeTenant } = useGlobalApp();
  const { data, isLoading } = useCoreApi(schemas.tenants.getTenantUsers, {
    pathParams: { tenantId: activeTenant?.id },
  });

  const options: SelectOption[] = (data?.items || []).map((tenantUser) => ({
    value: tenantUser.user.id,
    label: `${tenantUser.user.firstName} ${tenantUser.user.lastName}`,
  }));

  return {
    isLoading,
    // @ts-expect-error label is always present but optional in SelectOption - that's okay
    options: fuzzyFilterAndHighlight(options, searchQuery, ['label']),
    searchQuery,
    setSearchQuery,
  };
}

// Hardcode options for now
export function usePackingBasesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');

  const packingBases: string[] = [
    'Hold',
    'Partial',
    'Item Complete',
    'Order Complete',
    'Item Partial',
    'Partial/Order',
  ];

  const isLoading = false;
  const options: SelectOption[] = packingBases.map((item) => ({
    value: item,
    label: item,
  }));
  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useOrderPrioritiesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.orderPriorities.getOrderPriorities);

  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: item.orderPriorityId,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export type ShipToSelectOption = SelectOption & {
  freightType?: IdNameObj;
  location?: IdNameObj;
  customer?: IdNameObj;
  packingBasis?: string;
  deliveryInstructions?: string;
  shipToEmailAddress?: string;
  shippingRouteUid?: string;
  freightCharge?: IdNameObj;
  carrier?: IdNameObj;
};

export function useShipTosSelectProps({ customerId, companyId }: ShipTosSelectParams): UseAsyncSelectProps {
  return useSearchIndexSelectProps<SearchIndexShipTo>({
    indexName: SearchIndexName.ShipTos,
    // Here customerId, companyId can be empty string, which will cause algolia fetch failure
    filters: { customer_id: customerId ? [customerId] : undefined, company_id: companyId ? [companyId] : undefined },
    mapHitFn: (hit) => ({
      value: `${hit.ship_to_id}: ${hit.ship_to_name}`,
      label: (
        <Styled.LabelContainer>
          <Styled.LabelTitle>{formatShipTo(hit.ship_to_id, hit.ship_to_name)}</Styled.LabelTitle>
          <Styled.LabelSubtitle>
            {hit.ship_to_address}
            {formatPhoneNumber(hit.ship_to_phone) && (
              <>
                <Divider type="vertical" />
                <PhoneOutlined />
                {` ${formatPhoneNumber(hit.ship_to_phone)}`}
              </>
            )}
          </Styled.LabelSubtitle>
        </Styled.LabelContainer>
      ),
      dropdownLabel: <Styled.LabelTitle>{formatShipTo(hit.ship_to_id, hit.ship_to_name)}</Styled.LabelTitle>,
      freightType: hit.default_freight_code
        ? { foreignId: hit.default_freight_code_uid, name: hit.default_freight_code }
        : undefined,
      location: hit.default_location_id
        ? { foreignId: hit.default_location_id, name: hit.default_location_name }
        : undefined,
      packingBasis: hit.default_packing_basis,
      deliveryInstructions: hit.default_delivery_instructions,
      shipToEmailAddress: hit.ship_to_email,
      customer: hit.customer_id ? { foreignId: hit.customer_id, name: hit.customer_name } : undefined,
      shippingRouteUid: hit.default_shipping_route_uid,
      freightCharge: hit.default_freight_charge_uid
        ? { foreignId: hit.default_freight_charge_uid, name: hit.default_freight_charge_desc }
        : undefined,
      carrier: hit.default_carrier_id
        ? { foreignId: hit.default_carrier_id, name: hit.default_carrier_name }
        : undefined,
    }),
  });
}

export function useItemSuppliersSelectProps({
  invMastUid,
  companyId,
  locationId,
}: {
  invMastUid: string;
  companyId: string;
  locationId: string;
}): UseAsyncSelectProps<ItemSupplierDTO> {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.items.getItemSuppliers, {
    pathParams: {
      itemUid: invMastUid,
    },
    queryParams: {
      filter: {
        locationId,
        companyIds: optArrFromVal(companyId),
      },
      limit: 100,
      offset: 0,
    },
  });

  const options: Array<SelectOption<ItemSupplierDTO>> = (data?.items || [])
    // filtering out null shipToAddress because they sometimes come as null from api (dunno why)
    .filter((item) => truthy(item.supplierId))
    .map((item, idx) => ({
      value: `${item.supplierId}: ${item.supplierName}`,
      label: (
        <Styled.LabelContainer key={idx}>
          <Styled.LabelTitle>{item.supplierName}</Styled.LabelTitle>
          <Styled.LabelSubtitle>{item.supplierId}</Styled.LabelSubtitle>
        </Styled.LabelContainer>
      ),
      data: item,
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useVendorSuppliersSelectProps({
  vendorId,
  companyId,
  limit,
}: {
  vendorId?: string;
  companyId?: string;
  limit?: number;
}): UseAsyncSelectProps<SupplierDTO> {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading, reload } = useCoreApi(schemas.suppliers.getSuppliers, {
    queryParams: {
      filter: {
        vendorId,
        companyIds: optArrFromVal(companyId),
      },
      limit: limit ?? 100,
      offset: 0,
    },
  });

  const options: Array<SelectOption<SupplierDTO>> = (data?.items || [])
    .filter((item) => truthy(item.supplierId))
    .map((item, _) => ({
      value: `${item.supplierId}: ${item.supplierName}`,
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
    reload,
  };
}

export function useSuppliersSelectProps(specificCompanyId?: string): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.suppliers.getSuppliers, {
    queryParams: {
      filter: {
        searchQuery,
        companyIds: specificCompanyId ? [specificCompanyId] : undefined,
      },
    },
  });

  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: joinIdNameObj({ foreignId: item.supplierId, name: item.supplierName }),
  }));

  if (data && data.totalCount > data.items.length) {
    options.push({
      value: '$totalCount',
      label: `Showing ${data.items.length} / ${data.totalCount.toLocaleString()} results, search for more.`,
      disabled: true,
      style: { cursor: 'pointer', color: colors.neutral[500], fontSize: '12px' },
    });
  }

  return {
    isLoading,
    options: fuzzyHighlight(options, searchQuery),
    totalCount: data?.totalCount || 0,
    searchQuery,
    setSearchQuery,
  };
}

export interface ContactSelectOption extends SelectOption {
  email: string;
  phone: string;
  name: string;
}

export function useContactsSelectProps({
  customerId,
  companyId,
}: {
  customerId?: string;
  companyId?: string;
}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading, reload } = useCoreApi(
    schemas.contacts.getContacts,
    // don't load unless customerId filter is specified
    customerId
      ? {
          queryParams: {
            filter: { customerId, companyId },
            // limit: 1000 is a hack until api supports searching (after we move mongo -> postgres)
            limit: 1000,
          },
        }
      : null,
  );

  const options: ContactSelectOption[] = (data?.items || []).map((item) => ({
    value: `${item.contactId}: ${item.contactName}`,
    label: (
      <Styled.LabelContainer>
        <Styled.LabelTitle>
          {item.contactId}: {item.contactName}
        </Styled.LabelTitle>
        <Styled.LabelSubtitle>
          {item.email ? (
            <>
              <MailOutlined /> {item.email.toLowerCase()}
            </>
          ) : null}
          {item.email && item.phone ? <Divider type="vertical" /> : null}
          {item.phone ? (
            <>
              <PhoneOutlined /> {formatPhoneNumber(item.phone)}
            </>
          ) : null}
        </Styled.LabelSubtitle>
      </Styled.LabelContainer>
    ),
    email: item.email || '',
    phone: item.phone || '',
    name: item.contactName,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery, ['value', 'email', 'phone']),
    searchQuery,
    setSearchQuery,
    reload,
  };
}

export function useCarriersSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.carriers.getCarriers);

  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: `${item.carrierId}: ${item.carrierName}`,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useShippingRoutesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.shippingRoutes.getShippingRoutes);

  const options: SelectOption<string>[] = (data?.items || [])
    .sort((a, b) => a.shippingRouteId.localeCompare(b.shippingRouteId))
    .map((route) => ({
      value: route.shippingRouteId,
      label: `${route.shippingRouteId}: ${route.shippingRouteName}`,
      data: route.shippingRouteUid,
    }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery, ['label']),
    searchQuery,
    setSearchQuery,
  };
}

export function useTermsSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.terms.getTerms);

  const options: SelectOption[] = (data?.items || []).map((item) => ({
    value: item.termsName,
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useFreightsSelectProps({ companyId }: { companyId: string | undefined }): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.freights.getFreights, {
    queryParams: companyId ? { filter: { companyId } } : undefined,
  });

  const options: SelectOption[] = (data?.items || []).map((freight, idx) => ({
    value: joinIdNameObj({ foreignId: freight.freightUid, name: freight.freightId ?? '' }),
    label: (
      <Styled.LabelContainer key={idx}>
        <Styled.LabelTitle>{`${freight.freightUid}: ${freight.freightId}`}</Styled.LabelTitle>
        <Styled.LabelSubtitle>{freight.freightName}</Styled.LabelSubtitle>
      </Styled.LabelContainer>
    ),
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useFreightChargesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.freightCharges.getFreightCharges);

  const options: SelectOption[] = (data?.items || []).map((freightCharge, idx) => ({
    value: joinIdNameObj({ foreignId: freightCharge.freightChargeUid, name: freightCharge.freightChargeId }),
    label: (
      <Styled.LabelContainer key={idx}>
        <Styled.LabelTitle>{`${freightCharge.freightChargeUid}: ${freightCharge.freightChargeId}`}</Styled.LabelTitle>
      </Styled.LabelContainer>
    ),
  }));

  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useItemGroupsSelectProps({
  companyId,
  withAccountNoFields,
}: { companyId?: string; withAccountNoFields?: boolean } = {}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.itemGroups.getItemGroups, {
    queryParams: {
      withAccountNoFields,
      filter: {
        companyIds: companyId ? [companyId] : undefined,
      },
    },
  });

  const options: SelectOption[] = (data?.items || []).map((itemGroup, _) => ({
    value: `${itemGroup.itemGroupId}: ${itemGroup.itemGroupName}`,
    glAccountNo: itemGroup.glAccountNo,
    revenueAccountNo: itemGroup.revenueAccountNo,
    cosAccountNo: itemGroup.cosAccountNo,
  }));
  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useItemSalesTaxClassesSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.itemSalesTaxClasses.getItemSalesTaxClasses);

  const options: SelectOption[] = (data?.items || []).map((taxClass, _) => ({
    value: `${taxClass.classId}: ${taxClass.classDescription}`,
  }));
  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useInventoryClassesSelectProps({
  classNumber,
}: {
  classNumber: number | undefined;
}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.inventoryClasses.getInventoryClasses, {
    queryParams: classNumber ? { filter: { classNumber } } : undefined,
  });

  const options: SelectOption[] = (data?.items || []).map((ivClass, _) => ({
    value: `${ivClass.classId}: ${ivClass.classDescription}`,
  }));
  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useOrderClassesSelectProps({ classNumber }: { classNumber: number | undefined }): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.orderClasses.getOrderClasses);
  const options: SelectOption[] = (data?.items || [])
    .filter((oc) => (classNumber ? oc.classNumber === classNumber : true))
    .map((oc, _) => ({
      value: `${oc.classId}: ${oc.classDescription}`,
    }));
  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function useUnitOfMeasuresSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.unitOfMeasures.getUnitOfMeasures);

  const options: SelectOption[] = (data?.items || []).map((taxClass, _) => ({
    value: `${taxClass.unitId}: ${taxClass.unitDescription}`,
  }));
  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

export function usePurchaseClassSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useCoreApi(schemas.purchaseClasses.getPurchaseClasses, {
    queryParams: undefined,
  });

  const options: SelectOption[] = (data?.items || []).map((purchaseClass, _) => ({
    value: `${purchaseClass.purchaseClass}: ${purchaseClass.purchaseClassDescription}`,
  }));
  return {
    isLoading,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

// Team decided to hard-code right now due to (1). time constraint (2). it is a unique/short/stable table for the only target tenant (Bricos)
// TODO: pull data from tenant tax_regime_mx table
export function useTaxRegimeMxSelectProps(): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const taxRegimeMxTable = [
    { id: '601', name: 'General de Ley Personas Morales' },
    { id: '603', name: 'Personas Morales con Fines no Lucrativos' },
    { id: '605', name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { id: '606', name: 'Arrendamiento' },
    { id: '607', name: 'Régimen de Enajenación o Adquisición de Bienes' },
    { id: '608', name: 'Demás ingresos' },
    { id: '609', name: 'Consolidación' },
    { id: '610', name: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { id: '611', name: 'Ingresos por Dividendos (socios y accionistas)' },
    { id: '612', name: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { id: '614', name: 'Ingresos por intereses' },
    { id: '615', name: 'Régimen de los ingresos por obtención de premios' },
    { id: '616', name: 'Sin obligaciones fiscales' },
    { id: '620', name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { id: '621', name: 'Incorporación Fiscal' },
    { id: '622', name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { id: '623', name: 'Opcional para Grupos de Sociedades' },
    { id: '624', name: 'Coordinados' },
    { id: '625', name: 'Regimen de las Actividades Empresariales con ingresos a trav?s de Plataformas Tecnol?gicas' },
    { id: '626', name: 'Regimen Simplificado de Confianza' },
    { id: '628', name: 'Hidrocarburos' },
    { id: '629', name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { id: '630', name: 'Enajenación de acciones en bolsa de valores' },
  ];

  const options: SelectOption[] = taxRegimeMxTable.map((taxRegime, _) => ({
    value: `${taxRegime.id}: ${taxRegime.name}`,
  }));
  return {
    isLoading: false,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

/** when option list is pre-determined and only searching is desired */
export function useStaticListSelectProps({
  options,
}: {
  options: Array<{ value: string; label?: string | JSX.Element }>;
}): UseAsyncSelectProps {
  const [searchQuery, setSearchQuery] = useState('');

  return {
    isLoading: false,
    options: fuzzyFilterAndHighlight(options, searchQuery),
    searchQuery,
    setSearchQuery,
  };
}

/// helpers ///

/** filter and higlight matching values - used when doing local search */
export function fuzzyFilterAndHighlight<ExtraPropsT>(
  options: (SelectOption & ExtraPropsT)[],
  searchQuery: string,
  fields: (keyof (SelectOption & ExtraPropsT))[] = ['value'],
): Array<SelectOption & ExtraPropsT> {
  const filteredOptions = fuzzyFilter(options, searchQuery, { fields });
  return filteredOptions.map((opt, optIdx) => ({
    key: optIdx, // backend sometimes replies with duplicate values so using index as the key
    label: opt.highlights.value ? highlightedLabel(opt.highlights.value) : opt.item.value,
    ...opt.item,
  }));
}

/** only higlight matching values - used when doing remote algolia search */
export function fuzzyHighlight(options: SelectOption[], searchQuery: string): SelectOption[] {
  return options.map((opt, optIdx) => {
    const highlights = fuzzyMatch(opt.value, searchQuery)?.highlights;
    return {
      key: optIdx, // backend sometimes replies with duplicate values so using index as the key
      label: highlights ? highlightedLabel(highlights) : opt.value,
      ...opt,
    };
  });
}
