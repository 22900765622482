import React, { useCallback, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { MetadataKey } from '@recurrency/core-api-schema/dist/payments/getPaymentIntents';
import { PaymentIntentDTO } from '@recurrency/core-api-schema/dist/payments/paymentIntentCommon';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { useDebounce } from 'use-debounce';

import { AsyncCursorPaginationTable } from 'components/AsyncTable';
import { useCursorPaginationTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DateRangePicker } from 'components/DatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { PageHeader } from 'components/PageHeader';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { SearchInput } from 'components/SearchInput';
import { Select } from 'components/Select';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { formatDate, formatCents } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';

import { PaymentListHashState } from 'types/hash-state';

import { CardColumn } from './common/card';
import { RefundModal } from './RefundModal';

export function PaymentsListPage() {
  const [hashState, updateHashState] = useHashState<PaymentListHashState>();
  const { activeTenant, activeUser } = useGlobalApp();

  const handleDateRangeChange = useCallback(
    (dates, _) => {
      updateHashState({
        startDate: dates ? dates[0]?.toISOString() : undefined,
        endDate: dates ? dates[1]?.toISOString() : undefined,
      });
    },
    [updateHashState],
  );

  const [debouncedQuery] = useDebounce(hashState.searchQuery, 500);

  const getRefundButtonOnClick = (pi: PaymentIntentDTO) => {
    if (pi.latestCharge) {
      const charge = pi.latestCharge;
      const { invoice_id } = pi.metadata;
      charge.metadata.invoice_id = invoice_id || 'MISSING';
      return async () => {
        await showAsyncModal(RefundModal, {
          charge,
        });
      };
    }
    return () => {};
  };

  const tableProps = useCursorPaginationTableProps({
    schema: schemas.payments.getPaymentIntents,
    queryParams: {
      searchMetadata:
        hashState.searchKey && debouncedQuery
          ? {
              key: hashState.searchKey,
              value: debouncedQuery,
            }
          : undefined,
      dateRangeFilter:
        hashState.startDate && hashState.endDate
          ? {
              startDate: hashState.startDate,
              endDate: hashState.endDate,
            }
          : undefined,
    },
  });

  useEffect(() => {
    tableProps.reset();

    if (!hashState.searchKey) {
      // set default searchKey if not set
      updateHashState({ searchKey: MetadataKey.invoiceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashState]);

  type PaymentIntentWithStatusDTO = PaymentIntentDTO & { paymentStatus: string };
  const tableItems = useMemo(
    () =>
      tableProps.items
        .map((pi: PaymentIntentDTO) => {
          const piStatus = pi.status;
          const chargeStatus = pi.latestCharge?.status;
          const captureMethod = pi.paymentMethodOptions?.card?.captureMethod || 'manual';
          let status = '';
          if (piStatus === 'succeeded' && chargeStatus === 'succeeded') {
            status = 'Paid';
          } else if (piStatus === 'requires_capture' && chargeStatus === 'succeeded') {
            status = 'Authorized - Pending Shipment';
          } else if (chargeStatus === 'failed') {
            if (captureMethod === 'manual') {
              status = 'Failed Authorization';
            } else {
              status = 'Failed Charge';
            }
          } else if (piStatus === 'canceled') {
            if (pi.cancellationReason === 'automatic' && chargeStatus === 'succeeded') {
              status = 'Auth Exped - Pending Shipment';
            } else {
              status = 'Canceled';
            }
          } else {
            status = `Unknown:${piStatus}|${chargeStatus}`;
          }
          return { ...pi, paymentStatus: status };
        })
        .filter(truthy),
    [tableProps.items],
  );

  const columns: ColumnType<PaymentIntentWithStatusDTO>[] = [
    {
      title: 'Invoice',
      dataIndex: 'invoiceId',
      render: (_: any, record: PaymentIntentWithStatusDTO) => `${record?.metadata?.invoice_id || '-'}`,
    },
    {
      title: 'Order',
      dataIndex: 'orderId',
      render: (_: any, record: PaymentIntentWithStatusDTO) =>
        record?.metadata.order_id && (
          <Link to={routes.orders.orderDetails(record?.metadata?.order_id)}>{record?.metadata?.order_id}</Link>
        ),
    },
    {
      title: 'Date',
      dataIndex: 'created',
      render: (_: any, record: PaymentIntentWithStatusDTO) => formatDate(record.created),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (_: any, record: PaymentIntentWithStatusDTO) => formatCents(record.amount),
    },
    {
      title: 'Card',
      dataIndex: 'paymentMethodDetails',
      render: (_: any, record: PaymentIntentWithStatusDTO) => {
        const card = record.latestCharge?.paymentMethodDetails?.card;
        return card ? <CardColumn card={card} /> : '-';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        { text: 'Paid', value: 'Paid' },
        { text: 'Authorized - Pending Shipment', value: 'Authorized - Pending Shipment' },
        { text: 'Failed Charge', value: 'Failed Charge' },
        { text: 'Failed Authorization', value: 'Failed Authorization' },
        { text: 'Auth Exped - Pending Shipment', value: 'Auth Exped - Pending Shipment' },
        { text: 'Canceled', value: 'Canceled' },
      ],
      onFilter: (value, record) => record.paymentStatus === value,
      render: (_: any, record: PaymentIntentWithStatusDTO) => {
        const status = record.paymentStatus.startsWith('Failed')
          ? BadgeStatus.Review
          : record.paymentStatus === 'Authorized - Pending Shipment' ||
            record.paymentStatus === 'Auth Exped - Pending Shipment'
          ? BadgeStatus.Incomplete
          : record.latestCharge
          ? BadgeStatus.Ready
          : BadgeStatus.Pinned;
        return (
          <Tooltip
            title={
              record.latestCharge?.failureMessage
                ? `${record.latestCharge?.failureMessage} (${record.latestCharge?.failureCode})`
                : ''
            }
          >
            <div
              className={css`
                display: flex;
                gap: 8px;
              `}
            >
              <StatusBadge label={record.paymentStatus} status={status} />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Amount Refunded',
      dataIndex: 'amountRefunded',
      render: (_: any, record: PaymentIntentDTO) =>
        `${formatCents(record.latestCharge ? record.latestCharge.amountRefunded : undefined)}`,
    },
    {
      title: 'Actions',
      render: (_: any, record: PaymentIntentDTO) => (
        <>
          <Button
            size="small"
            onClick={
              getRefundButtonOnClick(record)
              // TODO
              // reload the table after the refund request so that the amount refunded is updated in the table
            }
            disabled={
              !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.PaymentsCreateRefund) ||
              record.status !== 'succeeded'
            }
          >
            Refund
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container>
      <PageHeader title="Payments" />
      <FilterBarBox dividerLine>
        <DateRangePicker
          onChange={handleDateRangeChange}
          format="MM/DD/YYYY"
          value={
            hashState.startDate && hashState.endDate
              ? [moment(hashState.startDate), moment(hashState.endDate)]
              : undefined
          }
        />
        <FlexSpacer />
        <Select
          style={{ width: 105 }}
          size="small"
          value={hashState.searchKey}
          options={[
            { value: MetadataKey.invoiceId, label: 'Invoice ID' },
            { value: MetadataKey.orderId, label: 'Order ID' },
          ]}
          onSelect={(value) => updateHashState({ searchKey: value })}
        />
        <SearchInput
          query={hashState.searchQuery}
          onQueryChange={(value) => updateHashState({ searchQuery: value })}
          placeholder="Search"
        />
      </FilterBarBox>
      <AsyncCursorPaginationTable
        columns={columns}
        rowKey={(record) => record.id}
        tableProps={{
          isLoading: tableProps.isLoading,
          items: tableItems,
          hasNextPage: tableProps.hasNextPage,
          hasPrevPage: tableProps.hasPrevPage,
          goNextPage: tableProps.goNextPage,
          goPrevPage: tableProps.goPrevPage,
        }}
      />
    </Container>
  );
}
