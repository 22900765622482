import React from 'react';

import { css } from '@emotion/css';
import { SortDirection } from '@recurrency/core-api-schema/dist/common/enums';
import { Space } from 'antd';
import { theme } from 'theme';

import { LinkButton } from 'components/Button/LinkButton';
import { Typography } from 'components/Typography';

import { routes } from 'utils/routes';

import { DefaultReport } from './defaultReports';

export function DefaultReportCard({ report }: { report: DefaultReport }) {
  const ascData = { ...report.data, sortDir: SortDirection.Asc };
  const descData = { ...report.data, sortDir: SortDirection.Desc };

  return (
    <div
      className={css`
        border-radius: 8px;
        border: 1px solid ${theme.colors.neutral[300]};
        background: ${report.color};
        display: flex;
        flex-direction: row;
        width: 100%;
      `}
    >
      <div
        className={css`
          padding: 14px;
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          align-items: flex-start;
          gap: 8px;
        `}
      >
        <Typography type="middle" style={{ fontWeight: 'bold' }}>
          {report.name}
        </Typography>
        <Typography type="subtitle">{report.description}</Typography>
        <div
          className={css`
            flex-grow: 1;
          `}
        />
        <Space>
          <LinkButton route={routes.reporting.explorer(descData)}>View Gains</LinkButton>
          <LinkButton route={routes.reporting.explorer(ascData)}>View Losses</LinkButton>
        </Space>
      </div>
      <div
        className={css`
          padding: 14px 14px 0;
          display: flex;
          align-items: flex-end;
        `}
      >
        {report.background}
      </div>
    </div>
  );
}
