import React, { useEffect } from 'react';

import { TenantIntegrationStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { Alert } from 'components/Alert';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { LocalStorageKey, useLocalStorage } from 'utils/localStorage';

export const IntegrationPausedBanner = () => {
  const { activeUser, activeTenant } = useGlobalApp();
  const [pausedBannerAcknowledgedTenants, setPausedBannerAcknowledgedTenants] = useLocalStorage(
    LocalStorageKey.IntegrationPausedBanner_AcknowledgedTenants,
    [],
  );

  useEffect(() => {
    if (
      pausedBannerAcknowledgedTenants.includes(activeTenant.databaseSchema) &&
      activeTenant.integrations?.[0]?.status !== TenantIntegrationStatus.Paused
    ) {
      setPausedBannerAcknowledgedTenants(
        pausedBannerAcknowledgedTenants.filter((dbSchema) => dbSchema !== activeTenant.databaseSchema),
      );
    }
  }, [activeTenant, pausedBannerAcknowledgedTenants, setPausedBannerAcknowledgedTenants]);

  const showPausedBanner =
    !pausedBannerAcknowledgedTenants.includes(activeTenant.databaseSchema) &&
    activeTenant.integrations?.[0]?.status === TenantIntegrationStatus.Paused &&
    activeUser.isRecurrencyInternalUser;
  const pausedBannerText = `[Internal Use Only] ${activeTenant.name} sync is paused. Data will be stale. Please contact engineering prior to demoing to resume sync.`;

  if (showPausedBanner) {
    return (
      <Alert
        noRadius
        closable
        message={pausedBannerText}
        banner
        type="warning"
        onClose={() =>
          setPausedBannerAcknowledgedTenants([...pausedBannerAcknowledgedTenants, activeTenant.databaseSchema])
        }
      />
    );
  }
  return <></>;
};
