import React from 'react';

import { css } from '@emotion/css';
import { SalesOrderLineDTO } from '@recurrency/core-api-schema/dist/salesOrderLines/getSalesOrderLines';
import { SalesQuoteLineDTO } from '@recurrency/core-api-schema/dist/salesQuoteLines/getSalesQuoteLines';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

import { QuoteOrderLineNotes } from '../orders/OrderDetailsPage/SSFC/QuoteOrderLineNotes';

export function QuoteOrderLineNotesModal({
  orderLines,
  itemId,
  onClose,
}: {
  orderLines: SalesOrderLineDTO[] | SalesQuoteLineDTO[];
  itemId: string;
  onClose: () => void;
}) {
  return (
    <Modal
      destroyOnClose
      visible
      title="Line Notes"
      width={1200}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="small">
          Close
        </Button>
      }
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <QuoteOrderLineNotes orderLines={orderLines} filter={itemId} pinned={false} />
      </div>
    </Modal>
  );
}
