import React, { useState } from 'react';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TransferThresholdSettings } from '@recurrency/core-api-schema/dist/common/enums';
import {
  LocationSettingsDTO,
  LocationSettingUpdate,
  UpdateLocationSettingsType,
} from '@recurrency/core-api-schema/dist/locations/locationSettings';
import { Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelect } from 'components/AsyncSelect';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { PageHeader } from 'components/PageHeader';
import { Select } from 'components/Select';

import { coreApiFetch } from 'utils/api';
import { splitIdNameStr } from 'utils/formatting';
import { asKeyOf, typedColumn } from 'utils/tables';

const TransferThresholdSettingsLabels: Record<TransferThresholdSettings, string> = {
  [TransferThresholdSettings.AboveMax]: 'Above Max',
  [TransferThresholdSettings.AboveMin]: 'Above Min',
  [TransferThresholdSettings.NetStock]: 'Net Stock',
};

export const LocationSettingsPage = () => {
  const [newSetting, updateNewSetting] = useState<LocationSettingsDTO>({
    locationId: null as unknown as string,
    transferThreshold: TransferThresholdSettings.NetStock,
  });

  const tableProps = useCoreApiTableProps({
    schema: schemas.locations.getLocationSettings,
  });
  // filter out any locations that don't have a transfer threshold setting
  tableProps.items = (tableProps.items?.filter((item) => !!item.transferThreshold) || []).sort(
    (a: LocationSettingsDTO, b: LocationSettingsDTO) => a.locationId.localeCompare(b.locationId),
  );

  const locationsSelectProps = useLocationsSelectProps({ sortByValueProp: true });

  const updateSettings = async (payload: LocationSettingUpdate) => {
    await coreApiFetch(schemas.locations.setLocationSettings, {
      pathParams: {
        updateType: UpdateLocationSettingsType.TransferThreshold,
      },
      bodyParams: {
        updates: [payload],
      },
    });
    tableProps.reload();
  };

  const onAddSetting = async () => {
    await updateSettings({
      locationId: splitIdNameStr(newSetting.locationId).foreignId,
      updateValue: newSetting.transferThreshold,
    });
  };

  const onEditSetting = (row: LocationSettingsDTO) => {
    updateNewSetting({
      locationId:
        (locationsSelectProps.options || []).find((loc) => splitIdNameStr(loc.value).foreignId === row.locationId)
          ?.value ?? '',
      transferThreshold: row.transferThreshold,
    });
  };

  const onDeleteSetting = async (row: LocationSettingsDTO) => {
    await updateSettings({
      locationId: row.locationId,
    });
  };

  const lineItemColumns: ColumnType<LocationSettingsDTO>[] = [
    typedColumn({
      title: 'Location ID',
      dataIndex: asKeyOf<LocationSettingsDTO>('locationId'),
      render: (val) => (
        <div
          className={css`
            margin-right: 24px;
          `}
        >
          {locationsSelectProps.options?.find((option) => splitIdNameStr(option.value).foreignId === val)?.value ?? val}
        </div>
      ),
    }),
    typedColumn({
      title: 'Transfer Threshold',
      dataIndex: asKeyOf<LocationSettingsDTO>('transferThreshold'),
      render: (val) => (
        <div
          className={css`
            margin-right: 24px;
          `}
        >
          {TransferThresholdSettingsLabels[val as TransferThresholdSettings] ?? val}
        </div>
      ),
    }),
    typedColumn({
      title: 'Actions',
      render: (row: LocationSettingsDTO) => (
        <>
          <Tooltip title="Edit Setting">
            <AsyncButton
              key="edit-setting-button"
              size="small"
              onClick={() => onEditSetting(row)}
              spinnerPosition="absolute"
            >
              <EditOutlined />
            </AsyncButton>
          </Tooltip>
          <Tooltip title="Delete Setting">
            <AsyncButton
              key="delete-setting-button"
              size="small"
              onClick={async () => {
                await onDeleteSetting(row);
              }}
              spinnerPosition="absolute"
            >
              <DeleteOutlined />
            </AsyncButton>
          </Tooltip>
        </>
      ),
    }),
  ];

  return (
    <Container>
      <PageHeader title="Location Settings" />
      <AsyncTable
        className={css`
          width: 65%;
        `}
        tableProps={tableProps}
        columns={lineItemColumns}
        pagination={false}
        size="small"
      />
      <div
        className={css`
          display: flex;
          flex-direction: row;
          margin-top: 12px;
          width: 65%;
        `}
      >
        <AsyncSelect
          className={css`
            flex-grow: 1;
            margin-right: 12px;
          `}
          selectProps={locationsSelectProps}
          placeholder="Search locations"
          entityPlural="locations"
          value={newSetting.locationId ?? undefined}
          onChange={(val) => updateNewSetting({ ...newSetting, locationId: val })}
        />
        <Select
          className={css`
            flex-grow: 1;
            margin-right: 12px;
          `}
          options={Object.values(TransferThresholdSettings).map((settingKey: string) => ({
            label: TransferThresholdSettingsLabels[settingKey as TransferThresholdSettings],
            value: settingKey as TransferThresholdSettings,
          }))}
          value={newSetting.transferThreshold}
          onChange={(val) => updateNewSetting({ ...newSetting, transferThreshold: val })}
        />
        <AsyncButton
          className={css`
            width: fit-content;
          `}
          type="primary"
          onClick={onAddSetting}
          disabled={!newSetting.locationId}
          spinnerPosition="absolute"
        >
          <PlusOutlined /> Add/Update Setting
        </AsyncButton>
      </div>
    </Container>
  );
};
