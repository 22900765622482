import React, { useEffect, useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { ContactDTO } from '@recurrency/core-api-schema/dist/contacts/getContacts';
import { Form } from 'antd';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent } from 'utils/track';

import { IdNameObj } from 'types/legacy-api';

export interface ContactCustomerObj extends IdNameObj {
  companyId?: string;
}

export interface ContactFormData {
  customer: ContactCustomerObj;
  firstName: string;
  lastName?: string;
  email?: string;
  title?: string;
  fax?: string;
  phone?: string;
}

export interface ContactModalProps {
  initialValues?: Partial<ContactFormData>;
  onClose: () => void;
  onSubmit?: (c: ContactDTO) => void;
}

export const ContactModal = ({ initialValues, onClose, onSubmit }: ContactModalProps) => {
  const { activeTenant } = useGlobalApp();
  const [contactForm] = Form.useForm();

  const [contactState, setContactState] = useState<Partial<ContactFormData>>(() => ({
    ...initialValues,
  }));

  const customerId = contactState.customer ? contactState.customer.foreignId : '';
  const customerName = contactState.customer ? contactState.customer.name : '';
  const companyId = contactState.customer ? contactState.customer.companyId : '';

  // use contactState as the source of state
  useEffect(() => {
    contactForm.setFieldsValue(contactState);
  }, [contactForm, contactState]);

  async function handleSubmit(data: ContactFormData) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Contact',
      expectedWaitSeconds: 10,
      erpType: activeTenant.erpType,
    });
    try {
      onClose();
      const response = await coreApiFetch(schemas.contacts.createContact, {
        bodyParams: {
          customer: {
            foreignId: customerId,
            name: customerName,
            companyId,
          },
          firstName: data.firstName,
          lastName: data.lastName || '',
          title: data.title || undefined,
          phone: data.phone || undefined,
          fax: data.fax || undefined,
          email: data.email || undefined,
        },
      });

      track(TrackEvent.Contacts_CreateContact_Submit, { contactId: response.data.contactId || '' });
      submitNotification.success();
      onSubmit?.(response.data);
    } catch (err) {
      submitNotification.error(err);
    }
  }

  return (
    <Modal
      visible
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" form="contactForm" htmlType="submit">
          Submit
        </Button>,
      ]}
      title="New Contact"
      onCancel={() => onClose()}
      centered
      width={900}
    >
      <Container>
        <Form.Provider>
          <Form
            name="contactForm"
            form={contactForm}
            onFinish={handleSubmit}
            onError={console.error}
            onFinishFailed={console.error}
            onValuesChange={(changedValues) => setContactState({ ...contactState, ...changedValues })}
            {...responsiveFormLayout}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'First name is required when making a contact',
                },
                { max: 15, message: 'First name cannot be more than 15 characters' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ max: 24, message: 'Last name cannot be more than 24 characters' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Title"
              name="title"
              rules={[{ max: 40, message: 'Title cannot be more than 40 characters' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Direct Phone"
              name="phone"
              rules={[{ max: 20, message: 'Phone number cannot be more than 20 characters' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Direct Fax"
              name="fax"
              rules={[{ max: 20, message: 'Fax number cannot be more than 20 characters' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Direct Email"
              name="email"
              rules={[
                { type: 'email', message: 'Must be an email' },
                { max: 255, message: 'Email cannot be more than 255 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Container>
    </Modal>
  );
};
