import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SavedViewType, TenantFeatureFlag, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { GetSalesInvoiceLinesReportGroupBy } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { theme } from 'theme';

import { Performance } from 'pages/sales/DashboardPage/Performance';

import { LinkButton } from 'components/Button/LinkButton';
import { Container } from 'components/Container';
import { CenteredError, SmallLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { SalesReportTable } from 'components/recipes/salesReport/SalesReportTable';
import { TasksTable } from 'components/recipes/TasksTable';
import { Tabs } from 'components/Tabs';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { shouldShowFeatureFlag, shouldShowProduct, shouldShowSearchIndex } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';

import { OpenOrdersTable } from './tabs/OpenOrdersTable';
import { OpenQuotesTable } from './tabs/OpenQuotesTable';

export const DashboardPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();

  const recentTabs = [
    shouldShowProduct(activeTenant, TenantProductName.SalesCrm) && {
      header: 'Tasks',
      content: <TasksTable dashboardMode />,
    },
    shouldShowSearchIndex(activeTenant, SearchIndexName.SalesOrders) && {
      header: 'Open Orders',
      content: <OpenOrdersTable />,
      infoTooltip: 'Shows most recent 15 open orders.',
    },
    shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) && {
      header: 'Open Quotes',
      content: <OpenQuotesTable />,
      infoTooltip: 'Shows most recent 15 open quotes.',
    },
  ].filter(truthy);

  const {
    data: pinnedReports,
    error: pinnedReportsError,
    isLoading,
  } = useCoreApi(schemas.savedViews.getSavedViews, {
    queryParams: { type: SavedViewType.SalesReport, filter: { isPinned: true } },
  });

  const pinnedTabs = pinnedReportsError
    ? [{ header: 'Error', content: <CenteredError error={pinnedReportsError} /> }]
    : isLoading || !pinnedReports
    ? []
    : pinnedReports.items
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((report) => ({
          header: `${report.name}`,
          content: (
            <SalesReportTable
              groupBy={report.data.groupBy!}
              report={report}
              pageSize={10}
              allowPagination={false}
              allowSort={false}
              showViewAllButton
            />
          ),
        }));

  const topSellingTabs = [
    {
      header: 'Top Customers',
      content: (
        <SalesReportTable
          groupBy={GetSalesInvoiceLinesReportGroupBy.Customer}
          pageSize={10}
          allowPagination={false}
          allowSort={false}
          showViewAllButton
        />
      ),
    },
    {
      header: 'Top Product Groups',
      content: (
        <SalesReportTable
          groupBy={GetSalesInvoiceLinesReportGroupBy.ItemGroup}
          pageSize={10}
          allowPagination={false}
          allowSort={false}
          showViewAllButton
        />
      ),
    },
    {
      header: 'Top Items',
      content: (
        <SalesReportTable
          groupBy={GetSalesInvoiceLinesReportGroupBy.Item}
          pageSize={10}
          allowPagination={false}
          allowSort={false}
          showViewAllButton
        />
      ),
    },
    {
      header: 'Top Ship Tos',
      content: (
        <SalesReportTable
          groupBy={GetSalesInvoiceLinesReportGroupBy.ShipTo}
          pageSize={10}
          allowPagination={false}
          allowSort={false}
          showViewAllButton
        />
      ),
    },
  ];

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip title="Recurrency Sales Mission Control is the central place for all things customer-facing. The data below is filtered by user/territory to only show the most relevant information. Use Pinned Reports to customize this page!">
            Sales Mission Control{' '}
            <Typography
              style={{
                color: theme.colors.primary[700],
                cursor: 'pointer',
                marginRight: 0,
                marginLeft: '4px',
                fontSize: '0.6em',
                fontWeight: 'bold',
                verticalAlign: 'top',
                marginTop: '-0.6em',
              }}
            />
          </InfoTooltip>
        }
      />
      <div
        className={css`
          gap: 12px;
          display: grid;
        `}
      >
        {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesStatistics) && <Performance />}
      </div>
      <div
        className={css`
          margin-top: 24px;
          width: 100%;
        `}
      >
        {isLoading ? (
          <SmallLoader />
        ) : (
          shouldShowProduct(activeTenant, TenantProductName.SalesReporting) && (
            <div>
              <div
                className={css`
                  margin-top: 16px;
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <Typography style={{ fontWeight: 'bold', marginTop: '16px' }}>Pinned Reports</Typography>
                <LinkButton
                  className={css`
                    position: relative;
                    top: 40px;
                    z-index: 1;
                  `}
                  route={routes.reporting.explorer()}
                  type="primary"
                  size="small"
                  icon={<PlusOutlined />}
                >
                  <Tooltip title="Use Reporting to pin custom reports to your dashboard">New Report</Tooltip>
                </LinkButton>
              </div>
              {/* Default to top selling tabs if no pinned tabs */}
              <Tabs tabs={pinnedTabs.length > 0 ? pinnedTabs : topSellingTabs} />
            </div>
          )
        )}
        {recentTabs.length > 0 && (
          <>
            <Typography style={{ fontWeight: 'bold' }}>Most Recent</Typography>
            <Tabs tabs={recentTabs} />
          </>
        )}
      </div>
    </Container>
  );
};
