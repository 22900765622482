import React, { useState } from 'react';

import { css, cx } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { CarrierDTO } from '@recurrency/core-api-schema/dist/carriers/getCarriers';
import { ItemAvailabilityDTO } from '@recurrency/core-api-schema/dist/items/getItemAvailability';
import { TransferDaysDTO } from '@recurrency/core-api-schema/dist/transferDays/getTransferDays';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { fuzzyMatch } from 'fuzzbunny';
import { theme } from 'theme';

import { Modal } from 'components/Modal';
import { Select, SelectOption } from 'components/Select';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { joinIdNameObj } from 'utils/formatting';
import { sortableNumberColumn, sortableStringColumn, typedColumn } from 'utils/tables';
import { qtyUnitConverter } from 'utils/units';

import { OrderLineTransferInfo } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import { QuoteLineItemP21WithInfo, TransferType } from '../../quoteUtils';

interface TransferDaysRow extends TransferDaysDTO {
  qtyAvailable: number;
  carrierName: string;
}

export function SourceAndShipModal({
  lineItem,
  salesLocation,
  selectedModal,
  onCancel,
  onOk,
}: {
  lineItem: QuoteLineItemP21WithInfo;
  salesLocation?: IdNameObj;
  selectedModal: string;
  onCancel: () => void;
  /** undefined means source location is same as ship location */
  onOk: (transferInfo: OrderLineTransferInfo | undefined) => void;
}) {
  const [transferInfo, setTransferInfo] = useState<OrderLineTransferInfo>({
    toLocation: lineItem.shipLocation || salesLocation,
    fromLocation: lineItem.sourceLocation || salesLocation,
    carrier: lineItem.transferCarrier,
  });

  return (
    <Modal
      visible
      title={
        selectedModal === TransferType.SourceLocation
          ? `Source Location for #${lineItem.foreignId} ${lineItem.quantity} ${lineItem.unitOfMeasure}`
          : `Ship Location for #${lineItem.foreignId} ${lineItem.quantity} ${lineItem.unitOfMeasure}`
      }
      onCancel={onCancel}
      onOk={() => onOk(transferInfo)}
      width={900}
    >
      <SourceAndShipLocationChooser
        lineItem={lineItem}
        selectedModal={selectedModal}
        salesLocation={salesLocation!}
        transferInfo={transferInfo}
        onSourceOrShipLocationChange={(transferInfo) => {
          setTransferInfo({
            ...transferInfo,
          });
        }}
      />
    </Modal>
  );
}

function SourceAndShipLocationChooser({
  lineItem,
  selectedModal,
  salesLocation,
  transferInfo,
  onSourceOrShipLocationChange,
}: {
  lineItem: QuoteLineItemP21WithInfo;
  selectedModal: string;
  salesLocation: IdNameObj;
  transferInfo: OrderLineTransferInfo;
  onSourceOrShipLocationChange: (transferInfo: OrderLineTransferInfo, transferCarrier?: IdNameObj) => void;
}) {
  const { algoliaItem } = lineItem;
  const { inv_mast_uid: itemInvMastUid } = algoliaItem!;
  const { quantity: qtyNeeded, sourceLocation, shipLocation } = lineItem;

  const locationIndex = selectedModal === TransferType.SourceLocation ? 'fromLocation' : 'toLocation';

  const transferLocation = (transferInfo && transferInfo[locationIndex]) || salesLocation;

  const { data: carriersData } = useCoreApi(schemas.carriers.getCarriers);
  const { data: transferDaysData } = useCoreApi(schemas.transferDays.getTransferDays, {
    queryParams: {
      toLocationId:
        selectedModal === TransferType.SourceLocation ? shipLocation?.foreignId || salesLocation.foreignId : '',
      fromLocationId:
        selectedModal === TransferType.ShipLocation ? sourceLocation?.foreignId || salesLocation.foreignId : '',
    },
  });

  const { data: locationAvailabilityData } = useCoreApi(schemas.items.getItemAvailability, {
    pathParams: { itemUid: itemInvMastUid },
  });

  const isLoading = !carriersData || !transferDaysData || !locationAvailabilityData;
  const availabilityByLocationId: Obj<ItemAvailabilityDTO> = isLoading
    ? {}
    : Object.fromEntries(locationAvailabilityData.items.map((item) => [item.locationId, item]));
  const carrierByCarrierId: Obj<CarrierDTO> = isLoading
    ? {}
    : Object.fromEntries(carriersData.items.map((carrier) => [carrier.carrierId, carrier]));

  const tableData: TransferDaysRow[] = isLoading
    ? []
    : transferDaysData.items
        // Both locations need to have an item inventory for transfer to work
        .filter((item) => availabilityByLocationId[item.fromLocationId])
        .filter((item) => availabilityByLocationId[item.toLocationId])
        .map((item) => {
          const fromAvailability = availabilityByLocationId[item.fromLocationId];
          return {
            ...item,
            qtyAvailable: fromAvailability
              ? qtyUnitConverter(fromAvailability.quantityAvailable, fromAvailability.unitSize)
              : 0,
            fromLocationName: item.fromLocationName || '-',
            toLocationName: item.toLocationName || '-',
            carrierName: item.carrierId ? carrierByCarrierId[item.carrierId]?.carrierName || '-' : '-',
            carrierId: item.carrierId,
          };
        })
        // The source location must have quantity to transfer, ship location does not require quantity
        .filter((item) => selectedModal === TransferType.ShipLocation || item.qtyAvailable > 0)
        .sort((a, b) => {
          // sort selected desc, days asc, qtyAvailable desc
          const idField = locationIndex === 'fromLocation' ? 'fromLocationId' : 'toLocationId';
          if (a[idField] === transferLocation?.foreignId) {
            return -1;
          }
          if (b[idField] === transferLocation?.foreignId) {
            return 1;
          }

          let diff = a.days - b.days;
          if (diff === 0) {
            diff = b.qtyAvailable - a.qtyAvailable;
          }
          return diff;
        });

  const sourceTableColumns: ColumnType<TransferDaysRow>[] = [
    typedColumn({
      title: 'ID',
      dataIndex: selectedModal === TransferType.ShipLocation ? 'toLocationId' : 'fromLocationId',
      align: 'left' as const,
      width: '10%',
    }),
    typedColumn(
      sortableStringColumn({
        title: 'Location',
        dataIndex: selectedModal === TransferType.ShipLocation ? 'toLocationName' : 'fromLocationName',
        align: 'left' as const,
      }),
    ),
    typedColumn(
      sortableNumberColumn({
        title: 'Qty Available',
        dataIndex: 'qtyAvailable',
        align: 'left' as const,
        width: '150px',
      }),
    ),
    typedColumn(
      sortableNumberColumn({
        title: 'Transfer Days',
        dataIndex: 'days',
        align: 'left' as const,
        width: '150px',
      }),
    ),
    typedColumn({
      title: 'Carrier',
      dataIndex: 'carrierId',
      render: (carrierId, record) => {
        const selectedCarrierId = transferInfo?.carrier?.foreignId || carrierId;
        const locationCompare =
          selectedModal === TransferType.ShipLocation ? record.toLocationId : record.fromLocationId;

        return (
          <div
            className={css`
              width: 200px;
            `}
          >
            {locationCompare === transferLocation?.foreignId && carriersData ? (
              <Select
                className={css`
                  display: block;
                `}
                showSearch
                size="small"
                options={carriersData.items.map((carrier) => ({
                  label: carrier.carrierName,
                  value: carrier.carrierId,
                }))}
                placeholder="Select a carrier"
                filterOption={(searchQuery, option) => !!fuzzyMatch((option as FIXME).label, searchQuery)}
                value={selectedCarrierId}
                // @ts-expect-error antd type is different from SelectOption
                onChange={(_: FIXME, option: SelectOption) => {
                  onSourceOrShipLocationChange({
                    ...transferInfo,
                    carrier: {
                      foreignId: option.value,
                      name: option.label as string,
                    },
                  });
                }}
              />
            ) : (
              <div>
                <span>{carrierId ? record.carrierName : '-'}</span>
              </div>
            )}
          </div>
        );
      },
    }),
  ];

  const shipTableColumns = sourceTableColumns.filter((tableColumn) => tableColumn.title !== 'Qty Available');
  const tableColumns = selectedModal === TransferType.SourceLocation ? sourceTableColumns : shipTableColumns;
  const pageSize = 7;

  const borderBoxCss = css`
    border: 1px solid ${theme.colors.neutral[200]};
    border-radius: 8px;
  `;

  const titleCss = css`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
    margin-left: 8px;
  `;

  const otherIndex = selectedModal === TransferType.SourceLocation ? 'toLocation' : 'fromLocation';
  const otherLocationAvailability = transferInfo[otherIndex]?.foreignId
    ? availabilityByLocationId[transferInfo[otherIndex]!.foreignId]
    : undefined;
  const otherLocation = otherLocationAvailability
    ? {
        foreignId: otherLocationAvailability.locationId,
        name: otherLocationAvailability.locationName,
      }
    : undefined;

  const shipToLocationAvailability = transferInfo.toLocation
    ? availabilityByLocationId[transferInfo.toLocation.foreignId]
    : undefined;

  const isBackOrder =
    shipToLocationAvailability && qtyNeeded
      ? qtyNeeded > qtyUnitConverter(shipToLocationAvailability.quantityAvailable, shipToLocationAvailability.unitSize)
      : false;

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      {(!otherLocation || otherLocation.foreignId === salesLocation.foreignId) && (
        <div>
          <div className={titleCss}>Use existing sales location</div>
          <div
            className={cx(
              borderBoxCss,
              css`
                padding: 16px;
                display: flex;
                gap: 16px;
                align-items: center;
              `,
            )}
          >
            <Radio
              checked={
                transferInfo[locationIndex]?.foreignId === salesLocation.foreignId ||
                transferInfo[locationIndex] === undefined
              }
              onChange={() =>
                onSourceOrShipLocationChange({
                  ...transferInfo,
                  [locationIndex]: undefined,
                  carrier: undefined,
                })
              }
            />
            <span>{`${
              selectedModal === TransferType.SourceLocation ? (isBackOrder ? `Backorder` : `Order`) : 'Ship'
            } from ${joinIdNameObj(salesLocation)}`}</span>
          </div>
        </div>
      )}
      {otherLocation && otherLocation.foreignId !== salesLocation.foreignId && (
        <div>
          <div className={titleCss}>
            {selectedModal === TransferType.ShipLocation
              ? `Use existing ship location`
              : `Use existing source location`}
          </div>
          <div
            className={cx(
              borderBoxCss,
              css`
                padding: 16px;
                display: flex;
                gap: 16px;
                align-items: center;
              `,
            )}
          >
            <Radio
              checked={transferInfo?.fromLocation?.foreignId === transferInfo.toLocation?.foreignId}
              onChange={() =>
                onSourceOrShipLocationChange({
                  ...transferInfo,
                  [locationIndex]: transferInfo?.[otherIndex],
                  carrier: undefined,
                })
              }
            />
            <span>{`${
              selectedModal === TransferType.SourceLocation ? (isBackOrder ? `Backorder` : `Order`) : 'Ship'
            } from ${
              selectedModal === TransferType.SourceLocation
                ? joinIdNameObj(transferInfo.fromLocation!)
                : joinIdNameObj(transferInfo.toLocation!)
            }`}</span>
          </div>
        </div>
      )}
      <div>
        <div className={titleCss}>
          {selectedModal === TransferType.SourceLocation ? `Change source location` : `Change ship location`}
        </div>
        <div className={borderBoxCss}>
          <Table
            isLoading={isLoading}
            data={tableData}
            columns={tableColumns}
            pageSize={pageSize}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: [transferInfo[locationIndex]?.foreignId],
              onChange: (_: any, selectedRows: TransferDaysRow[]) => {
                if (selectedRows.length) {
                  const selectedRow = selectedRows[0];
                  const selectedTransferCarrier: IdNameObj | undefined = selectedRow.carrierId
                    ? {
                        foreignId: selectedRow.carrierId,
                        name: selectedRow.carrierName,
                      }
                    : undefined;
                  onSourceOrShipLocationChange({
                    ...transferInfo,
                    [locationIndex]: {
                      foreignId: selectedRow[`${locationIndex}Id`],
                      name: selectedRow[`${locationIndex}Name`],
                    },
                    carrier: selectedTransferCarrier,
                  });
                }
              },
            }}
            rowKey={`${locationIndex}Id`}
            locale={{ emptyText: `No transfer locations found with enough available quantity.` }}
            style={{ height: 300 }}
          />
        </div>
      </div>
    </div>
  );
}
