import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';

import { isTenantGALarson } from 'utils/tenants';
import { OrderType } from 'utils/track';

import { QuoteEditHashStateP21, QuoteEditStep, QuoteLineItemP21 } from 'types/hash-state';

import { SummaryLine } from './QuoteEditFlowP21/QuoteLineItemSummaryStats';
import { QuoteLineItemP21WithInfo } from './quoteUtils';

export enum CustomModals {
  PriceOverride = 'priceOverride',
}

type CustomizationProps = {
  orderType: OrderType;
  step: QuoteEditStep;
  activeTenant: TenantDTO;
  lineItemsWithInfo: QuoteLineItemP21WithInfo[];
  quoteState: QuoteEditHashStateP21;
};

type CustomizationPayload = {
  messages: string[];
  modals: CustomModals[];
  customItems: QuoteLineItemP21[];
  quoteStateUpdate: Partial<QuoteEditHashStateP21>;
  summaryLines: SummaryLine[];
};

type TenantCustomization = {
  tenantFilter: (activeTenantId: string) => boolean;
  customFn: (props: CustomizationProps, payload: CustomizationPayload) => CustomizationPayload;
};

const tenantCustomizations: Record<QuoteEditStep, Array<TenantCustomization>> = {
  [QuoteEditStep.Header]: [],
  [QuoteEditStep.LineItems]: [],
  [QuoteEditStep.Review]: [{ tenantFilter: isTenantGALarson, customFn: setPriceOverrideModal }],
};

export function getTenantCustomizations(props: CustomizationProps) {
  const initialPayload: CustomizationPayload = {
    messages: [],
    modals: [],
    customItems: [],
    quoteStateUpdate: {},
    summaryLines: [],
  };

  return tenantCustomizations[props.step].reduce(
    (payload, customization) =>
      customization.tenantFilter(props.activeTenant.id) ? customization.customFn(props, payload) : payload,
    initialPayload,
  );
}

function setPriceOverrideModal(props: CustomizationProps, payload: CustomizationPayload) {
  if (props.lineItemsWithInfo.some((item: QuoteLineItemP21) => item.manualPriceOverride)) {
    payload.modals = [...payload.modals, CustomModals.PriceOverride];
  } else {
    payload.quoteStateUpdate = {
      ...payload.quoteStateUpdate,
      priceOverrideReason: undefined,
      priceOverrideComments: undefined,
    };
  }
  return payload;
}
