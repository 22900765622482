import { TableRowSelection } from 'antd/lib/table/interface';

import { SearchRequest, SearchResponse } from 'utils/search/types';

import { SearchFrameHashState } from 'types/hash-state';

import { GetDownloadDataFn } from '../DownloadButton';

export interface SearchFrameContext<HitT> {
  hashState: SearchFrameHashState;
  searchRequest: SearchRequest;
  tableRowSelection?: TableRowSelection<HitT> & { selectedRows?: HitT[]; selectedAll?: boolean };
  searchIndexResponse?: SearchResponse<HitT>;
  searchIndexReload: () => void;
  /** true if there is a row selection either via selectAll or selectedRows */
  canDownload: boolean;
  getDownloadData: GetDownloadDataFn;
}

export enum FacetRenderType {
  /** Facet rendered as a dropdown */
  Dropdown = 'dropdown',
  /** Facet rendered as a pill */
  Pill = 'pill',
}
