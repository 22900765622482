import React, { HTMLAttributes, forwardRef } from 'react';

import { cx, css } from '@emotion/css';
import { theme } from 'theme';

import { SkeletonBar } from 'components/SkeletonBar';

export interface BorderedReadItemProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  value: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  description?: React.ReactNode;
}

export const BorderedPropertyItem = forwardRef<HTMLDivElement, BorderedReadItemProps>(
  ({ label, value, className, isLoading, disabled, description, ...props }, ref) => (
    <div
      className={css`
        display: flex;
        align-self: stretch;
        flex-direction: column;
      `}
    >
      <div
        ref={ref}
        className={cx(
          css`
            display: flex;
            flex-direction: column;
            border: 1px solid ${theme.colors.primary[300]};
            border-radius: 8px;
            box-sizing: border-box;
            padding: 8px 8px 4px 8px;
            background-color: ${disabled ? theme.colors.neutral[75] : 'white'};
          `,
          className,
        )}
        {...props}
      >
        {label && (
          <div
            className={css`
              ${theme.typography.caption};
              color: ${theme.colors.primary[500]};
              white-space: nowrap;
            `}
          >
            {label}
          </div>
        )}
        <div
          className={css`
            ${theme.typography.pMedium};
            color: ${disabled ? theme.colors.neutral[600] : theme.colors.neutral[900]};
          `}
        >
          {isLoading ? <SkeletonBar size="large" /> : value}
        </div>
      </div>
      {description && (
        <div
          className={css`
            padding-top: 8px;
          `}
        >
          {description}
        </div>
      )}
    </div>
  ),
);
