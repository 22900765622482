import React from 'react';

import { Link } from 'react-router-dom';

import { DeploymentUnitOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { theme } from 'theme';

import { MiniTableSkeleton } from 'components/MiniTable';

import { useCoreApi } from 'hooks/useApi';

import { routes } from 'utils/routes';

export function ItemAssemblyComponentsTabContent({ itemId }: { itemId: string }) {
  const { data: componentsData, isLoading } = useCoreApi(schemas.items.getItemComponents, { pathParams: { itemId } });

  return isLoading ? (
    <MiniTableSkeleton />
  ) : (
    <div>
      {componentsData?.items.map((item) => (
        <div
          key={item.itemId}
          className={css`
            margin-bottom: 16px;
          `}
        >
          <Link to={routes.sales.itemDetails(item.itemId)}>
            {item.itemId}
            {item.isAssembly && (
              <>
                &nbsp;
                <DeploymentUnitOutlined />
              </>
            )}
          </Link>
          : {item.itemDesc}{' '}
          <div>
            {item.qtyPerAssembly} {item.unitOfMeasure.name}
          </div>
        </div>
      ))}
      <div className="border-top: 5px solid #D9D9D9" />
      <div
        className={css`
          margin-bottom: 16px;
          border-top: 1px solid ${theme.colors.primary[300]};
        `}
      />
    </div>
  );
}
