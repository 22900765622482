import { ReplenishmentRuleRequestDTO } from '@recurrency/core-api-schema/dist/replenishmentRules/common';
import { FormInstance, Rule, RuleObject } from 'antd/lib/form';

import { getSearchIndexItemExistsByItemId } from './search/search';

export const validatePrice = (rawPrice: string | number) => {
  if (!rawPrice || Number.isNaN(Number(rawPrice))) {
    return '';
  }

  const newPrice = Number(rawPrice);
  if (newPrice < 0.1) {
    return '';
  }
  return newPrice;
};

export const validateGM = (rawGM: string | number) => {
  if (!rawGM || Number.isNaN(Number(rawGM))) {
    return '';
  }

  const newGM = Math.min(100, Math.max(0, Number(rawGM)));
  if (newGM < 0.1) {
    return '';
  }
  return newGM;
};

const disallowedSymbols = {
  '`': 'a backtick',
  // eslint-disable-next-line
  "'": 'a single quote',
  '"': 'a double quote',
  '~': 'a tilde',
  '’': 'an apostrophe',
  '\t': 'a tab',
  '%': 'a percent',
  '^': 'a carat',
};

// Antd form validation rules return promises:

export const validateNumberMinimumInclusive = (
  _: Rule,
  value: number | string | undefined,
  min: number | undefined,
) => {
  const numValue = Number(value);
  if (min !== undefined && numValue !== undefined && numValue < min) {
    return Promise.reject(new Error(`Must be greater than ${min}`));
  }
  return Promise.resolve();
};

export const validateNumberMaximumInclusive = (
  _: Rule,
  value: number | string | undefined,
  max: number | undefined,
) => {
  const numValue = Number(value);
  if (max !== undefined && numValue !== undefined && numValue > max) {
    return Promise.reject(new Error(`Must be less than ${max}`));
  }
  return Promise.resolve();
};

const validateStringAgainstDisallowedSymbols = (stringToValidate: string, type: string) => {
  for (const [character, characterName] of Object.entries(disallowedSymbols)) {
    if (stringToValidate.includes(character)) {
      return Promise.reject(new Error(`${type} cannot contain ${characterName}.`));
    }
  }
  return Promise.resolve();
};

export const validatePONumber = (_: Rule, rawPONumber: string) => {
  if (rawPONumber) {
    if (rawPONumber.length > 50) {
      return Promise.reject(new Error('PO Number maximum length is 50'));
    }
    return validateStringAgainstDisallowedSymbols(rawPONumber, 'PO Number');
  }
  return Promise.resolve();
};

export const validateSupplierPartNo = (_: Rule, rawSupplierPartNo: string) => {
  if (rawSupplierPartNo) {
    return validateStringAgainstDisallowedSymbols(rawSupplierPartNo, 'Supplier Part No');
  }
  return Promise.resolve();
};

export const validateItemDescription = (_: Rule, rawSupplierPartNo: string) => {
  if (rawSupplierPartNo) {
    return validateStringAgainstDisallowedSymbols(rawSupplierPartNo, 'Item Description');
  }
  return Promise.resolve();
};

export const validateItemId = async (_: Rule, rawItemId: string) => {
  if (rawItemId) {
    try {
      await validateStringAgainstDisallowedSymbols(rawItemId, 'Item ID');
    } catch (error) {
      return Promise.reject(error);
    }
    const itemExists = await getSearchIndexItemExistsByItemId(rawItemId);
    if (itemExists) {
      return Promise.reject(new Error(`Item ID already exists.`));
    }
  }
  return Promise.resolve();
};

export const validateDateInFuture = (_: Rule, date: string) => {
  if (!Date.parse(date)) {
    return Promise.reject(new Error('Not a date string'));
  }
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (new Date(date) < today) {
    return Promise.reject(new Error());
  }
  return Promise.resolve();
};

export function isValidReplenishmentRulesRequestDTO(obj: unknown): obj is ReplenishmentRuleRequestDTO {
  return typeof obj === 'object' && obj !== null && typeof (obj as ReplenishmentRuleRequestDTO).ruleName === 'string';
}

export const requireIfOtherFieldHasValue =
  (dependentField: string, errorMessage: string) =>
  ({ getFieldValue }: { getFieldValue: FormInstance['getFieldValue'] }) => ({
    validator(_: RuleObject, value: string | number) {
      const dependentFieldValue = getFieldValue(dependentField);

      if (dependentFieldValue && !value) {
        return Promise.reject(new Error(errorMessage));
      }

      return Promise.resolve();
    },
  });
