import React from 'react';

import { ShopOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { Form } from 'antd';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { Button } from 'components/Button';
import { CheckboxFormItem } from 'components/FormItems';
import { SmallLoader } from 'components/Loaders';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { asKeyOf } from 'utils/tables';

import { UserPermissionsFields } from './types';

export const UserCompaniesSelect = ({
  formValues,
  setValue,
}: {
  formValues: UserPermissionsFields;
  setValue: (key: string, value: unknown) => void;
}) => {
  const { activeTenant } = useGlobalApp();

  const { data: companyData, isLoading } = useCoreApi(schemas.companies.getCompanies, {
    pathParams: { tenantId: activeTenant.id, erpType: activeTenant.erpType },
    queryParams: { allCompanies: true },
  });
  const companySelectProps = {
    isLoading,
    options: (companyData?.items || []).map((record) => ({
      label: `${record.companyId}: ${record.companyName}`,
      value: record.companyId,
    })),
  };
  return (
    <>
      <CheckboxFormItem
        className={css`
          margin-bottom: 5px;
        `}
        name={asKeyOf<UserPermissionsFields>('isCompanyRestricted')}
        checkboxLabel="Can only see data for specific companies."
      />
      <Form.Item
        name={asKeyOf<UserPermissionsFields>('allowedCompanyIds')}
        className={css`
          margin-bottom: 0;
          // Indent under checkbox
          margin-left: 24px;
        `}
      >
        {formValues.isCompanyRestricted &&
          (isLoading ? (
            <SmallLoader />
          ) : (
            <AsyncMultiSelect
              mode="multiple"
              queryPlaceholder="Search companies"
              selectedValues={
                formValues.allowedCompanyIds && formValues.allowedCompanyIds.length > 0
                  ? formValues.allowedCompanyIds
                  : companySelectProps.options.map((o) => o.value)
              }
              onSelectedValuesChange={(values) => setValue('allowedCompanyIds', values || [])}
              selectProps={companySelectProps}
              disableValuesTooltip
              triggerButton={
                <Button size="small">
                  <ShopOutlined />
                  {formValues.allowedCompanyIds && formValues.allowedCompanyIds.length > 0
                    ? formValues.allowedCompanyIds.length
                    : companySelectProps.options.length}{' '}
                  Companies Selected
                </Button>
              }
            />
          ))}
      </Form.Item>
    </>
  );
};
