import React, { useEffect } from 'react';

import { CheckCircleFilled, CloseCircleFilled, DashboardOutlined, SyncOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { AsyncJobDTO, AsyncJobName, AsyncJobStatus } from '@recurrency/core-api-schema/dist/asyncJobs/common';
import { Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { ButtonLink } from 'components/Links';
import { PageHeader } from 'components/PageHeader';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatDuration, formatName } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { asKeyOf, sortableDateColumn, sortableNumberColumn } from 'utils/tables';

import { ExportJobsPageHashState } from 'types/hash-state';

import { StepStatusProgressBar } from './StepStatusProgressBar';
import { formatJobName, iconStyle } from './utils';

const statusOptions: MultiSelectOption[] = [
  { label: 'All', value: '' },
  { label: 'Running', value: AsyncJobStatus.Running },
  { label: 'Success', value: AsyncJobStatus.Success },
  { label: 'Failed', value: AsyncJobStatus.Failed },
];

export const ExportJobsPage = () => {
  const { activeUser } = useGlobalApp();
  const [hashState, updateHashState] = useHashState<ExportJobsPageHashState>();
  const tableProps = useCoreApiTableProps({
    schema: schemas.asyncJobs.getAsyncJobs,
    queryParams: {
      filter: { status: hashState.status },
    },
  });

  // auto refresh page every 30s while user is on the page, to show latest progress
  useEffect(() => {
    const refreshIntervalId = setInterval(() => tableProps.reload(), 30_000);
    return () => {
      clearInterval(refreshIntervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderJobStatus = (status: AsyncJobStatus) => {
    switch (status) {
      case AsyncJobStatus.Success:
        return <CheckCircleFilled style={{ ...iconStyle, color: colors.success[500] }} />;
      case AsyncJobStatus.Running:
        return <SyncOutlined spin style={{ ...iconStyle, color: colors.info[500] }} />;
      case AsyncJobStatus.Failed:
        return <CloseCircleFilled style={{ ...iconStyle, color: colors.danger[500] }} />;
      default:
        return <></>;
    }
  };

  const columns: (ColumnType<AsyncJobDTO> | null)[] = [
    {
      title: 'Status',
      dataIndex: asKeyOf<AsyncJobDTO>('status'),
      width: 80,
      align: 'center',
      render: (status: AsyncJobStatus) => <Tooltip title={status}>{renderJobStatus(status)}</Tooltip>,
    },
    {
      title: 'Name',
      dataIndex: asKeyOf<AsyncJobDTO>('name'),
      render: (name: AsyncJobName) => formatJobName(name),
    },
    {
      title: 'User',
      dataIndex: asKeyOf<AsyncJobDTO>('user'),
      render: (user: AsyncJobDTO['user']) => formatName(user.firstName, user.lastName),
    },
    sortableNumberColumn({
      title: 'Record Count',
      dataIndex: asKeyOf<AsyncJobDTO>('recordCount'),
      sorter: true,
    }),
    sortableDateColumn({
      title: 'Started',
      dataIndex: asKeyOf<AsyncJobDTO>('createdAt'),
      sorter: true,
      withTimestamp: true,
      inLocalTime: true,
      defaultSortOrder: 'descend',
    }),
    sortableNumberColumn({
      title: 'Duration',
      dataIndex: asKeyOf<AsyncJobDTO>('durationMs'),
      render: (durationMs: number) => formatDuration(durationMs),
      sorter: true,
    }),
    {
      title: 'Progress',
      dataIndex: asKeyOf<AsyncJobDTO>('stepStatusCounts'),
      width: 200,
      render: (stepStatusCounts: AsyncJobDTO['stepStatusCounts']) =>
        stepStatusCounts && <StepStatusProgressBar statusCounts={stepStatusCounts} width={200} />,
    },
    // job details page is only shown to @recurrency.com staff users
    activeUser.isRecurrencyInternalUser
      ? {
          dataIndex: asKeyOf<AsyncJobDTO>('id'),
          render: (id) => ButtonLink(routes.settings.exportJobDetails(id)),
        }
      : null,
  ];

  return (
    <Container>
      <PageHeader title="Export Jobs Status" />
      <FilterBarBox dividerLine>
        <AsyncMultiSelect
          label="Status"
          mode="single"
          icon={<DashboardOutlined />}
          selectProps={{ options: statusOptions }}
          selectedValues={[hashState.status || statusOptions[0].value]}
          onSelectedValuesChange={(values) => updateHashState({ status: (values[0] || undefined) as AsyncJobStatus })}
        />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={columns.filter(truthy)} />
    </Container>
  );
};
