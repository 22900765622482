import React, { useState } from 'react';

import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';

import { EquationPopover } from 'components/recipes/equation/EquationPopover';
import { DemandPattern } from 'components/Tooltip/DemandPatternTooltip';

import { EOQFormulaMinEquation } from './equations/EOQFormulaMinEquation';
import { SparseReplenishmentEquation } from './equations/SparseReplenishmentEquation';

export const RecommendedMinPopover = ({
  record,
  showOpOqVariant = false,
  openSparseItemGrid,
}: {
  record: SearchPlanningDTO;
  showOpOqVariant?: boolean;
  openSparseItemGrid?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  // This is to close the popover when the modal is opened from this popover
  const onClick = () => {
    setIsOpen(false);
    if (openSparseItemGrid) openSparseItemGrid();
  };
  // This is to reset the visibility to undefined so it can be opened on hover after it is closed
  const onVisibleChange = (isVisible: boolean) => {
    const visibility = isVisible === false ? undefined : isVisible;
    setIsOpen(visibility);
  };
  return (
    <EquationPopover
      title={`Recommended ${showOpOqVariant ? 'Order Point' : 'Min'} Calculation`}
      visible={isOpen}
      onVisibleChange={onVisibleChange}
    >
      {(record.demand_pattern === DemandPattern.SPARSE && (
        <SparseReplenishmentEquation
          record={record}
          showOpOqVariant={showOpOqVariant}
          openSparseItemGrid={openSparseItemGrid ? onClick : undefined}
        />
      )) || <EOQFormulaMinEquation record={record} showOpOqVariant={showOpOqVariant} />}
    </EquationPopover>
  );
};
