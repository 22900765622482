import React from 'react';

import { css, cx } from '@emotion/css';
import { theme } from 'theme';

import { styled } from 'utils/styledComponent';

import { PRIMARY_NAVIGATION_HEIGHT, SECONDARY_NAVIGATION_WIDTH, TERTIARY_NAVIGATION_HEIGHT } from '../navConstants';

export const Container = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <div
    className={css`
      flex-grow: 0;
      flex-shrink: 0;
      width: ${isDesktopView ? `${SECONDARY_NAVIGATION_WIDTH}px` : '100%'};
      position: fixed;
      top: ${PRIMARY_NAVIGATION_HEIGHT + (isDesktopView ? 0 : TERTIARY_NAVIGATION_HEIGHT)}px;
      bottom: 0;
      z-index: ${theme.zIndices.secondaryNav};
      overflow: auto;
      background-color: ${theme.colors.neutral[200]};
      transition: all ${theme.transitions.fast};
    `}
  >
    {children}
  </div>
);

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const MenuContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Icon = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <div
    className={css`
      display: flex;
      width: ${isDesktopView ? '100%' : 'auto'};
      align-self: center;
      justify-content: ${isDesktopView ? 'center' : 'flex-start'};
      font-size: 1.45em;
    `}
  >
    {children}
  </div>
);

export const MenuItem = ({
  selected,
  className,
  ...otherProps
}: { selected: boolean } & JSX.IntrinsicElements['a']) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    className={cx(
      css`
        margin: 4px 0;
        cursor: pointer;
        padding: 12px;
        padding-left: calc(12px - 3px);
        overflow-x: hidden;
        color: ${selected ? theme.colors.neutral[900] : theme.colors.neutral[400]};
        border-left: 3px solid ${selected ? theme.colors.primary[600] : 'transparent'};

        &:hover {
          border-color: ${theme.colors.primary[600]};
          color: ${theme.colors.neutral[600]};
        }
      `,
      className,
    )}
    {...otherProps}
  />
);

export const MenuItemChild = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HamburgerTitle = styled('div')`
  width: 100%;
  margin-left: 16px;
  color: ${theme.colors.neutral[600]};
`;
