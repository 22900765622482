import React, { ReactNode } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

import { ActionButton } from 'components/Button/ActionButton';

export const EditTargetActionButton = ({ value, onClick }: { value: ReactNode; onClick: () => void }) => (
  <div
    className={css`
      display: inline-flex;
      align-items: center;
      gap: 4px;
    `}
  >
    {value}
    <ActionButton label={<EditOutlined />} onClick={onClick} />
  </div>
);
