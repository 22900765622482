import React from 'react';

import { Link } from 'react-router-dom';

import { LogoutOutlined, MessageOutlined, SettingOutlined, UserAddOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { routes } from 'utils/routes';

import { InviteTeammateModal } from './InviteTeammateModal';

export const UserProfileMenu = () => {
  const { logout } = useGlobalApp();

  return (
    <>
      <Menu style={{ backgroundColor: '#FAFAFA', borderRight: 'none' }}>
        <Menu.Item
          key="help"
          icon={<MessageOutlined />}
          onClick={() => {
            if (window.zE) {
              window.zE.show();
              window.zE('webWidget', 'open');
            }
          }}
        >
          Message us
        </Menu.Item>
        <Menu.Item key="invite" icon={<UserAddOutlined />} onClick={() => showAsyncModal(InviteTeammateModal, {})}>
          Invite a Teammate
        </Menu.Item>
        <Menu.Item key="settings" icon={<SettingOutlined />}>
          <Link to={routes.account.accountEdit()}>Settings</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item danger key="logout" icon={<LogoutOutlined />} onClick={() => logout()}>
          <span>Log Out</span>
        </Menu.Item>
      </Menu>
    </>
  );
};
