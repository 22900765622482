import React from 'react';

import { css } from '@emotion/css';

import { CollapsePanels } from '../sidePane/CollapsePanels';
import { DetailPagePropertyList, DetailPagePropertyListItem } from './DetailPagePropertyList';

export interface DetailPageSection {
  title: string;
  rows?: DetailPagePropertyListItem[][];
  children?: React.ReactChild;
}

export interface DetailPageSectionsProps {
  sections: DetailPageSection[];
}

export const DetailPageSections = ({ sections }: DetailPageSectionsProps) => (
  <CollapsePanels
    panels={sections.map((section) => ({
      title: section.title,
      content: (
        <div
          className={css`
            margin: 8px 0 0 24px;
          `}
        >
          {section.rows && <DetailPagePropertyList rows={section.rows} />}
          {section.children}
        </div>
      ),
    }))}
  />
);
