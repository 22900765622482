import React from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { DisputeDTO } from '@recurrency/core-api-schema/dist/payments/getDisputes';
import { ColumnType } from 'antd/lib/table';

import { AsyncCursorPaginationTable } from 'components/AsyncTable';
import { useCursorPaginationTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Badge } from 'components/Badge';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { PageHeader } from 'components/PageHeader';

import { convertSnakeCaseToSpaceSplit, formatCents, formatDate } from 'utils/formatting';
import { routes } from 'utils/routes';

import { CardColumn } from '../common/card';

export function DisputeListPage() {
  const columns: ColumnType<DisputeDTO>[] = [
    {
      title: 'Customer',
      dataIndex: 'customerId',
      render: (_: any, record: DisputeDTO) =>
        record.customerId ? (
          <Link to={routes.sales.customerDetails(record.customerId.split('||')[1])}>
            {record.customerId.split('||')[1]}
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Cardholder',
      dataIndex: 'cardholder',
      render: (_: any, record: DisputeDTO) => `${record.cardHolderName}`,
    },
    {
      title: 'Card',
      dataIndex: 'card',
      render: (_: any, record: DisputeDTO) => {
        const card = record?.card;
        return card ? <CardColumn card={card} /> : '-';
      },
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      render: (_: any, record: DisputeDTO) => formatDate(record.chargeCreated),
    },
    {
      title: 'Order',
      dataIndex: 'orderId',
      render: (_: any, record: DisputeDTO) =>
        record.orderId ? <Link to={routes.sales.customerDetails(record.orderId)}>{record.orderId}</Link> : '-',
    },
    {
      title: 'Invoice',
      dataIndex: 'InvoiceId',
      render: (_: any, record: DisputeDTO) => `${record.invoiceId || '-'}`,
    },
    {
      title: 'Disputed Amount',
      dataIndex: 'amount',
      render: (_: any, record: DisputeDTO) => `${formatCents(record.amount)}`, // amount is in cents
    },
    {
      title: 'Dispute Date',
      dataIndex: 'disputedDate',
      render: (_: any, record: DisputeDTO) => formatDate(record.created),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, record: DisputeDTO) => <Badge label={convertSnakeCaseToSpaceSplit(record.status)} />,
    },
    {
      title: 'Respond By',
      dataIndex: 'Respond By',
      render: (_: any, record: DisputeDTO) => new Date(record.dueBy).toLocaleString(),
    },
  ];

  const tableProps = useCursorPaginationTableProps({
    schema: schemas.payments.getDisputes,
  });
  return (
    <Container>
      <PageHeader title="Disputes" />
      <FilterBarBox dividerLine />
      <AsyncCursorPaginationTable
        columns={columns}
        rowKey={(record) => record.id}
        tableProps={{
          isLoading: tableProps.isLoading,
          items: tableProps.items,
          hasNextPage: tableProps.hasNextPage,
          goNextPage: tableProps.goNextPage,
          hasPrevPage: tableProps.hasPrevPage,
          goPrevPage: tableProps.goPrevPage,
        }}
      />
    </Container>
  );
}
