import React, { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { notification } from 'antd';
import { theme } from 'theme';

import { browserHistory } from 'utils/browserHistory';

export const linkablePhoneNumber = (phoneNumber: string | number, label?: ReactNode) => (
  <a href={`tel:${phoneNumber}`}>{label || phoneNumber}</a>
);

export const linkableEmail = (email: string, label?: ReactNode) => <a href={`mailto:${email}`}>{label || email}</a>;

export const linkableAddress = (address: string, label?: ReactNode) => (
  <a href={`http://maps.google.com/?q=${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
    {label || address}
  </a>
);

export const ButtonLink = (uri: string) => (
  <Link to={uri}>
    <RightOutlined />
  </Link>
);

export const ExternalLink = ({
  to,
  children,
  style,
  onClick,
}: {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => (
  <a href={to} style={style} target="_blank" rel="noopener noreferrer" onClick={onClick}>
    {children}
  </a>
);

/**
 * NotificationLink is needed instead of Link.
 * because antd creates a different ReactROM.render tree for notifications.
 * Since we only use a single history instance, NotificationLink references browserHistory.push
 * to prevent browser from refreshing page
 */
export const NotificationLink = ({
  to,
  children,
  style,
  notificationKey,
  onClick,
}: {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  notificationKey?: string;
  onClick?: () => void;
}) => (
  <a
    href={to}
    style={style}
    onClick={(ev) => {
      ev.preventDefault();
      browserHistory.push(to);
      notification.close(notificationKey ?? to);
      if (onClick) {
        onClick();
      }
      return false;
    }}
  >
    {children}
  </a>
);

export const linkableText = ({ onClick, text }: { onClick: () => void; text: string }) => (
  <div
    className={css`
      color: ${theme.colors.info[600]};
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: ${theme.colors.info[500]};
      }
    `}
    onClick={onClick}
  >
    {text}
  </div>
);
