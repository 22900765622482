import type { HTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

import type { CSSObject } from '@emotion/css';
import { cx, css, keyframes } from '@emotion/css';
import { theme } from 'theme';

export interface SkeletonBarProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Defines the height of the component
   */
  size?: 'regular' | 'large';
  /**
   * Defines if the SkeletonBar component should run the background animation
   */
  animate?: boolean;
  /**
   * Custom width value defined in px, if it's not passed the component will use the container width.
   */
  width?: number;
}

export const SkeletonBar = forwardRef<HTMLDivElement, SkeletonBarProps>(
  ({ size = 'regular', animate = false, width, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        css`
          ${skeletonBarHeight[size]};
          width: 100%;
          background: ${theme.colors.neutral[200]};
          ${animate &&
          css`
            background: linear-gradient(
              90deg,
              ${theme.colors.neutral[200]} 25%,
              ${theme.colors.neutral[300]} 37%,
              ${theme.colors.neutral[200]} 63%
            );
            background-size: 400% 100%;
            animation: ${backgroundAnimation} 1.4s ease infinite;
          `}
          ${width &&
          `
              width: ${width}px;
            `};
        `,
        className,
      )}
      {...props}
    />
  ),
);

export const skeletonBarHeight: { [key in NonNullable<SkeletonBarProps['size']>]: CSSObject } = {
  regular: {
    height: '8px',
  },
  large: {
    height: '32px',
  },
};

const backgroundAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
`;
