import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';

import { getNumLineItems, QuoteLineItemP21WithInfo } from 'pages/orders/quotes/quoteUtils';

import { Button } from 'components/Button';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import {
  lineItemPagePropertyListItem,
  lineItemPageShipToPropertyListItem,
} from 'components/recipes/detailPage/propertyListItemUtils';
import { LineItemRecommendationsSidePane } from 'components/recipes/sidePane/LineItemRecommendationsSidePane';
import { SplitPage } from 'components/SplitPage';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { truthy } from 'utils/boolean';
import { splitIfIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { getSearchIndexShipToByShipToId } from 'utils/search/search';
import { OrderType } from 'utils/track';
import { priceUnitConverter } from 'utils/units';

import { QuoteEditHashStateP21, QuoteLineItemP21 } from 'types/hash-state';

import { LineItemActionsP21 } from '../../types';
import { QuoteLineItemSummaryStats, SummaryLine } from '../QuoteLineItemSummaryStats';
import { getLineItemColumns } from './lineItemColumns';
import { LineItemDetailsSidePaneP21 } from './LineItemDetailsSidePaneP21';
import * as Styled from './QuoteLineItems.style';

export const QuoteLineItems = ({
  quoteState,
  lineItemsWithInfo,
  lineItemActions,
  orderType,
  additionalSummaryLines,
}: {
  quoteState: QuoteEditHashStateP21;
  lineItemsWithInfo: QuoteLineItemP21WithInfo[];
  lineItemActions: LineItemActionsP21;
  orderType: OrderType;
  additionalSummaryLines: SummaryLine[];
}) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const shouldChangeRequiredDateForLineItem = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersChangeRequiredDateForLineItem,
  );
  const [selectedLineItemRowIdx, setSelectedLineItemRowIdx] = useState<number>();

  useEffect(() => {
    // if a brand new quote, add a new blank line item to get the user started
    if (lineItemsWithInfo.length === 0) {
      lineItemActions.addEmptyLineItem();
    }

    // for new line items that don't have price, qty or unitOfMeasure, set defaults from price info
    // TODO: This can be split into its parts and done individually in each column component, but it's a bit finnicky so that will be its own PR
    for (const lineItem of lineItemsWithInfo) {
      if (lineItem.priceInfo) {
        const lineItemUpdate: Partial<QuoteLineItemP21> = {};
        if (lineItem.quantity === undefined) {
          lineItemUpdate.quantity = 1;
        }
        if (lineItem.unitOfMeasure === undefined) {
          const nextUOM = lineItem.priceInfo?.defaultUnit;
          lineItemUpdate.unitOfMeasure = nextUOM?.symbol;

          // FIXME: UOM logic needs to be better consolidated
          // NOTE: This is necessary because price could be pre-set to a non-valid UOM
          // Price has to be that value per that UOM and so we need to convert it here
          const price = lineItemUpdate.price || lineItem.price;
          if (price && nextUOM) {
            lineItemUpdate.price = priceUnitConverter(price, nextUOM.size);
          }
        }
        if (lineItem.description === undefined) {
          lineItemUpdate.description = lineItem.algoliaItem?.extended_desc || '';
        }
        if (shouldChangeRequiredDateForLineItem && lineItem.requiredDate === undefined) {
          lineItemUpdate.requiredDate = quoteState.requiredDate;
        }

        if (Object.keys(lineItemUpdate).length > 0) {
          lineItemActions.updateLineItem(lineItemsWithInfo.indexOf(lineItem), lineItemUpdate);
        }
      }
    }
  }, [lineItemActions, lineItemsWithInfo, quoteState.requiredDate, shouldChangeRequiredDateForLineItem]);

  return (
    <SplitPage
      left={
        <QuoteLineItemsList
          quoteState={quoteState}
          selectedLineItemRowIdx={selectedLineItemRowIdx}
          lineItemsWithInfo={lineItemsWithInfo}
          orderType={orderType}
          onLineItemRowIdxChange={(rowIdx) => {
            // So we don't trigger an unnecessary re-render
            if (rowIdx === selectedLineItemRowIdx) return;
            setSelectedLineItemRowIdx(rowIdx);
          }}
          lineItemActions={lineItemActions}
          additionalSummaryLines={additionalSummaryLines}
        />
      }
      right={
        <Styled.SidePaneContainer>
          {selectedLineItemRowIdx !== undefined && lineItemsWithInfo[selectedLineItemRowIdx]?.foreignId ? (
            <LineItemDetailsSidePaneP21
              orderState={quoteState}
              lineItem={lineItemsWithInfo[selectedLineItemRowIdx]}
              lineItemIdx={selectedLineItemRowIdx}
              lineItemActions={lineItemActions}
              orderType={orderType}
            />
          ) : (
            <LineItemRecommendationsSidePane
              orderState={quoteState}
              orderType={orderType}
              onRecommendedItemAdd={(recItem) => {
                lineItemActions.addLineItem({
                  foreignId: recItem.itemCode,
                  name: recItem.itemName,
                  quantity: recItem.lastQty,
                  price: recItem.lastUnitPrice,
                  unitOfMeasure: recItem.unitOfMeasure,
                });
              }}
            />
          )}
        </Styled.SidePaneContainer>
      }
    />
  );
};

export const QuoteLineItemsList = ({
  quoteState,
  lineItemsWithInfo,
  orderType,
  selectedLineItemRowIdx,
  lineItemActions,
  onLineItemRowIdxChange,
  additionalSummaryLines,
}: {
  quoteState: QuoteEditHashStateP21;
  lineItemsWithInfo: QuoteLineItemP21WithInfo[];
  orderType: OrderType;
  selectedLineItemRowIdx: number | undefined;
  lineItemActions: LineItemActionsP21;
  onLineItemRowIdxChange: (rowIdx: number | undefined) => void;
  additionalSummaryLines: SummaryLine[];
}) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const company = splitIfIdNameStr(quoteState.company);
  const customer = splitIfIdNameStr(quoteState.customer);
  const shipTo = splitIfIdNameStr(quoteState.shipTo);
  const salesLocation = splitIfIdNameStr(quoteState.location);
  const sourceLocation = splitIfIdNameStr(quoteState.sourceLocation);
  // Once sourceLocation is applied and different from salesLocation, we show both
  // at DescriptionContainer within Location
  const shouldSpecifyHeaderSourceLocation =
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersChangeSourceLocationForHeader) &&
    sourceLocation &&
    sourceLocation?.foreignId !== salesLocation?.foreignId;
  // We want to be able to handle multiple line changes happening between renders

  // if item info is loaded and item id/foreignId is present, then mark the row as selected
  useEffect(() => {
    if (selectedLineItemRowIdx !== undefined && lineItemsWithInfo[selectedLineItemRowIdx]?.foreignId) {
      onLineItemRowIdxChange(selectedLineItemRowIdx);
    }
  }, [lineItemsWithInfo, onLineItemRowIdxChange, selectedLineItemRowIdx]);

  const { data: algoliaShipTo } = usePromise(
    () => getSearchIndexShipToByShipToId(shipTo?.foreignId || '', company?.foreignId || '', customer?.foreignId || ''),
    [shipTo?.foreignId, company?.foreignId, customer?.foreignId],
  );

  const lineItemColumns =
    company && customer && salesLocation && shipTo
      ? getLineItemColumns({
          selectedRowIdx: selectedLineItemRowIdx,
          lineItems: lineItemsWithInfo,
          company,
          salesLocation,
          sourceLocation,
          shipToLocation: shipTo,
          orderType,
          numLineItems: lineItemsWithInfo.length,
          actions: lineItemActions,
        })
      : [];

  const section: DetailPageSection = {
    title: 'Details',
    rows: [
      [
        lineItemPagePropertyListItem(
          {
            label: 'Customer',
            value: customer?.name,
            id: customer?.foreignId,
          },
          shouldSpecifyHeaderSourceLocation,
        ),
        lineItemPageShipToPropertyListItem(
          {
            label: 'Ship To',
            value: algoliaShipTo,
            id: shipTo?.foreignId,
          },
          shouldSpecifyHeaderSourceLocation,
        ),
        lineItemPagePropertyListItem(
          {
            label: shouldSpecifyHeaderSourceLocation ? 'Sales Location' : 'Location',
            id: `${salesLocation?.foreignId}${
              quoteState.willCall && orderType === OrderType.Order ? ' (Will Call)' : ''
            }`,
            value: salesLocation?.name,
          },
          shouldSpecifyHeaderSourceLocation,
        ),
        shouldSpecifyHeaderSourceLocation &&
          lineItemPagePropertyListItem(
            {
              label: 'Source Location',
              id: sourceLocation?.foreignId,
              value: sourceLocation?.name,
            },
            true,
          ),
      ].filter(truthy),
    ],
  };
  return (
    <Styled.Container>
      <DetailPageSections sections={[section]} />
      <br />
      <Styled.Header>
        <Styled.HeaderLeft>
          <Typography>Items ({getNumLineItems(lineItemsWithInfo)})</Typography>
        </Styled.HeaderLeft>
      </Styled.Header>
      <Styled.ItemTable
        data={lineItemsWithInfo}
        columns={lineItemColumns}
        pagination={false}
        size="small"
        onRow={(_record: FIXME, rowIdx: number | undefined) => ({
          onClick: () => {
            onLineItemRowIdxChange(rowIdx);
          },
          onDoubleClick: () => {
            onLineItemRowIdxChange(rowIdx);
          },
        })}
      />
      <br />
      <Button block onClick={lineItemActions.addEmptyLineItem}>
        <Styled.AddButtonContent>
          <PlusOutlined /> Add Line
        </Styled.AddButtonContent>
      </Button>
      <Styled.Footer>
        <Styled.FooterContent>
          <QuoteLineItemSummaryStats lineItemsWithInfo={lineItemsWithInfo} additionalLines={additionalSummaryLines} />
        </Styled.FooterContent>
      </Styled.Footer>
    </Styled.Container>
  );
};
