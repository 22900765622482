import React, { useState, useEffect } from 'react';

export function useContainerDimensions(containerElRef: React.RefObject<HTMLElement>) {
  const [contentRect, setContentRect] = useState<DOMRectReadOnly | null>(null);

  // when useContainerDimensions is called, containerElRef.current is null
  // but by the time useEffect runs react has rendered and containerElRef.current is a valid html element
  useEffect(() => {
    const currentEl = containerElRef.current;
    if (currentEl) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setContentRect(entry.contentRect);
        }
      });
      resizeObserver.observe(currentEl);
      return () => {
        resizeObserver.unobserve(currentEl);
      };
    }
    return () => {};
  }, [containerElRef]);
  return contentRect;
}
