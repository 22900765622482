import type { HTMLAttributes, ElementType } from 'react';
import React, { forwardRef } from 'react';

import type { CSSObject } from '@emotion/css';
import { cx, css } from '@emotion/css';
import { theme } from 'theme';

export type BadgeSize = 'small' | 'large';
export type BadgeVariant = 'primary' | 'neutral' | 'success' | 'danger' | 'warning' | 'info' | 'ocean' | 'malibu';

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
  label?: React.ReactNode;
  leadIcon?: React.ReactNode;
  disabled?: boolean;
  size?: BadgeSize;
  variant?: BadgeVariant;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      label,
      variant = 'primary',
      size = 'large',
      leadIcon: LeadIcon,
      disabled = false,
      tag = 'div',
      className,
      ...props
    },
    ref,
  ) => {
    const Element = tag;
    return (
      <Element
        ref={ref}
        className={cx(
          css`
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: max-content;
            border-radius: 16px;
            transition: color ${theme.transitions.fast}, background ${theme.transitions.fast},
              border-color ${theme.transitions.fast};
            border: 1px solid;
            gap: 4px;
            ${variants[variant]};

            ${sizes({ size, label })};
          `,
          { [disabledStyles]: disabled },
          className,
        )}
        {...props}
      >
        {LeadIcon}
        {label && (
          <span
            className={cx(
              {
                [css`
                  ${theme.typography.labelSmall};
                  font-size: 11px;
                  line-height: 16px;
                `]: size === 'small',
                [css`
                  ${theme.typography.labelLarge};
                  line-height: 20px;
                  font-size: 12px;
                `]: size === 'large',
              },
              css`
                font-weight: 500;
              `,
            )}
          >
            {label}
          </span>
        )}
      </Element>
    );
  },
);

const sizes = ({ size, label }: Pick<BadgeProps, 'size' | 'label'>) => {
  if (size === 'large' && label) {
    return {
      height: '24px',
      padding: '2px 9px',
    };
  }
  if (size === 'large') {
    return {
      height: '24px',
      padding: '4px',
    };
  }
  if (size === 'small' && label) {
    return {
      height: '16px',
      padding: '0 6px',
    };
  }
  return {
    height: '16px',
    padding: '2px 4px',
  };
};

export const variants: { [variant in BadgeVariant]: CSSObject } = {
  primary: {
    color: theme.colors.primary[500],
    background: theme.colors.primary[100],
    borderColor: theme.colors.primary[500],
  },
  neutral: {
    color: theme.colors.neutral[500],
    background: theme.colors.neutral[200],
    borderColor: theme.colors.neutral[500],
  },
  info: {
    color: theme.colors.info[600],
    background: theme.colors.info[100],
    borderColor: theme.colors.info[500],
  },
  success: {
    color: theme.colors.success[600],
    background: theme.colors.success[100],
    borderColor: theme.colors.success[500],
  },
  warning: {
    color: theme.colors.warning[600],
    background: theme.colors.warning[100],
    borderColor: theme.colors.warning[600],
  },
  danger: {
    color: theme.colors.danger[600],
    background: theme.colors.danger[100],
    borderColor: theme.colors.danger[500],
  },
  ocean: {
    color: theme.colors.ocean[700],
    background: theme.colors.ocean[200],
    borderColor: theme.colors.ocean[500],
  },
  malibu: {
    color: theme.colors.malibu[700],
    background: theme.colors.malibu[100],
    borderColor: theme.colors.malibu[600],
  },
};

const disabledStyles = css`
  color: ${theme.colors.neutral[300]};
  background: ${theme.colors.neutral[200]};
  border-color: ${theme.colors.neutral[300]};
`;
