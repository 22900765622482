import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { colors } from 'theme/colors';

import { Input } from 'components/Input';

export interface SearchInputProps {
  query?: string;
  onQueryChange?: (newQuery: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  size?: SizeType;
}

export const SearchInput = ({
  query,
  onQueryChange,
  placeholder = '',
  autoFocus = false,
  className,
  size = 'small',
}: SearchInputProps) => (
  <Input
    prefix={<SearchOutlined style={{ color: colors.neutral[400] }} />}
    type="search"
    autoFocus={autoFocus}
    value={query ?? ''}
    placeholder={placeholder}
    title={placeholder}
    onChange={(event) => onQueryChange?.(event.currentTarget.value)}
    size={size}
    className={cx(
      css`
        width: 250px;
      `,
      className,
    )}
  />
);
