import React, { useEffect } from 'react';

import {
  CheckCircleFilled,
  ClockCircleOutlined,
  CloseCircleFilled,
  RetweetOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { AsyncJobStep, AsyncJobStepName, AsyncJobStepStatus } from '@recurrency/core-api-schema/dist/asyncJobs/common';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { Alert } from 'components/Alert';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';
import { EllipsisTruncatedLabel } from 'components/Typography/EllpsisTruncatedLabel';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { formatDuration } from 'utils/formatting';
import { IdPathParams, usePathParams } from 'utils/routes';
import { sortableDateColumn, sortableStringColumn } from 'utils/tables';

import { JobStatusBadge } from './JobStatusBadge';
import { StepStatusProgressBar } from './StepStatusProgressBar';
import { calcDurationMsFromDates, formatJobName, formatJobStepName, iconStyle } from './utils';

export const ExportJobDetailsPage = () => {
  const { id: jobId } = usePathParams<IdPathParams>();
  const {
    data: asyncJob,
    isLoading,
    error,
    reload: reloadJob,
  } = useCoreApi(schemas.asyncJobs.getAsyncJob, { pathParams: { jobId } });
  const steps = asyncJob?.steps || [];
  const stepStatusCounts = {
    total: steps.length,
    [AsyncJobStepStatus.Pending]: steps.filter((step) => step.status === AsyncJobStepStatus.Pending).length,
    [AsyncJobStepStatus.Success]: steps.filter((step) => step.status === AsyncJobStepStatus.Success).length,
    [AsyncJobStepStatus.Running]: steps.filter((step) => step.status === AsyncJobStepStatus.Running).length,
    [AsyncJobStepStatus.Failed]: steps.filter((step) => step.status === AsyncJobStepStatus.Failed).length,
  };

  // auto refresh page every 30s while user is on the page, to show latest progress
  useEffect(() => {
    const refreshIntervalId = setInterval(() => reloadJob(), 30_000);
    return () => {
      clearInterval(refreshIntervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderJobStepStatus = (status: AsyncJobStepStatus) => {
    switch (status) {
      case AsyncJobStepStatus.Pending:
        return <ClockCircleOutlined style={{ ...iconStyle, color: colors.neutral[500] }} />;
      case AsyncJobStepStatus.Success:
        return <CheckCircleFilled style={{ ...iconStyle, color: colors.success[500] }} />;
      case AsyncJobStepStatus.Running:
        return <SyncOutlined spin style={{ ...iconStyle, color: colors.info[500] }} />;
      case AsyncJobStepStatus.Failed:
        return <CloseCircleFilled style={{ ...iconStyle, color: colors.danger[500] }} />;
      default:
        return <></>;
    }
  };

  const columns: ColumnType<AsyncJobStep>[] = [
    {
      title: 'Status',
      dataIndex: 'status',
      width: 80,
      align: 'center',
      render: (status: AsyncJobStep['status']) => <Tooltip title={status}>{renderJobStepStatus(status)}</Tooltip>,
    },
    sortableStringColumn({
      title: 'Step',
      dataIndex: 'name',
      render: (name: AsyncJobStepName) => formatJobStepName(name),
    }),
    sortableDateColumn({
      title: 'Started',
      dataIndex: 'startedAt',
      withTimestamp: true,
      inLocalTime: true,
    }),
    sortableDateColumn({
      title: 'Finished',
      dataIndex: 'finishedAt',
      withTimestamp: true,
      inLocalTime: true,
    }),
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (_, record) => formatDuration(calcDurationMsFromDates(record.startedAt, record.finishedAt)),
      align: 'right',
    },
    {
      title: 'Error',
      dataIndex: 'error',
      width: 300,
      render: (err: string) => (err ? <EllipsisTruncatedLabel maxWidth="300px" label={err} /> : '-'),
    },
  ];

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !asyncJob) {
    return <CenteredLoader />;
  }

  return (
    <Container>
      <PageHeader
        title={formatJobName(asyncJob.name)}
        copyable
        entity={{
          kind: 'Export Job',
          id: asyncJob.id,
          badge: (
            <FlexSpace direction="row" gap={8}>
              <JobStatusBadge status={asyncJob.status} />
              <StepStatusProgressBar statusCounts={stepStatusCounts} width={200} />
            </FlexSpace>
          ),
        }}
        headerActions={
          steps.some((step) => step.status === AsyncJobStepStatus.Failed) && (
            <AsyncButton
              onClick={async () => {
                await coreApiFetch(schemas.asyncJobs.postAsyncJobRetryFailedSteps, { pathParams: { jobId } });
                reloadJob();
              }}
            >
              <RetweetOutlined /> Retry Failed Steps
            </AsyncButton>
          )
        }
      />
      {asyncJob.error && (
        <Alert
          className={css`
            margin-bottom: 16px;
          `}
          message="Error"
          description={asyncJob.error}
          type="error"
          showIcon
        />
      )}
      <DividerLine />
      <Table data={steps} columns={columns} rowKey="id" pageSize={50} />
    </Container>
  );
};
