import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { message } from 'antd';
import { theme } from 'theme';

import { ActionButton } from 'components/Button/ActionButton';
import { DEFAULT_NUM_ROWS_SHOWN, MiniTableMessage, MiniTableSkeleton } from 'components/MiniTable';

import { useCoreApi } from 'hooks/useApi';

import { showAsyncModal } from 'utils/asyncModal';
import { OrderType, track, TrackEvent, SidePanePanelType, AccessoryItemAddLocation } from 'utils/track';

import { QuoteLineItemP21 } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import { AccessoryModal } from './AccessoryModal';

export function AccessoriesPanelContent({
  lineItem,
  orderType,
  location,
  onLineItemsAdd,
}: {
  lineItem: QuoteLineItemP21;
  orderType: OrderType;
  location: IdNameObj;
  onLineItemsAdd: (lineItems: QuoteLineItemP21[]) => void;
}) {
  const { data: itemAccessoriesData, isLoading } = useCoreApi(schemas.items.getItemAccessories, {
    pathParams: { itemId: lineItem.foreignId },
  });

  if (isLoading) {
    return <MiniTableSkeleton />;
  }

  if (!itemAccessoriesData || itemAccessoriesData.totalCount === 0) {
    return <MiniTableMessage message="No accessories found." />;
  }

  return (
    <>
      {itemAccessoriesData.items.slice(0, DEFAULT_NUM_ROWS_SHOWN).map((item) => (
        <>
          <div key={item.childItem.itemId}>
            {item.childItem.itemId}: {item.childItem.itemDesc}
          </div>
          <div
            className={css`
              color: ${theme.colors.info[600]};
              margin-bottom: 10px;
              cursor: pointer;
              &:hover {
                color: ${theme.colors.info[500]};
              }
            `}
            onClick={() => {
              track(TrackEvent.Quotes_EditQuote_AddAccessoryItem, {
                addLocation: AccessoryItemAddLocation.SidePane,
                orderType,
              });
              onLineItemsAdd([
                {
                  foreignId: item.childItem.itemId,
                  name: item.childItem.itemDesc,
                  description: item.childItem.extendedDesc ?? undefined,
                  // TODO: This quantity should actually be: childQty / childUnitSize * (lineItemWithInfo.quantity* lineItemWithInfo.unitOfMeasure.unitSize / (parentQty * item.parent.unitSize))
                  // This would fail in the case that the user changes the uom and then adds accessories
                  // to fix it we need to get the unit sizes of the current line item uom and the default unit size
                  quantity:
                    (item.childUnitQuantity / item.childItem.unitOfMeasure.unitSize) *
                    ((lineItem.quantity ?? 0) / item.parentUnitQuantity),
                },
              ]);
              message.success(`Accessory Item #${item.childItem.itemId} has been added`);
            }}
          >
            Add
          </div>
        </>
      ))}
      {itemAccessoriesData.totalCount > DEFAULT_NUM_ROWS_SHOWN ? (
        <ActionButton
          label="View All"
          onClick={async () => {
            track(TrackEvent.Quotes_EditQuote_SidePane_MoreButtonClick, {
              panelType: SidePanePanelType.AccessoryItems,
              orderType,
            });
            const accessoryLineItems = await showAsyncModal(AccessoryModal, {
              lineItem,
              location,
            });
            if (accessoryLineItems) {
              track(TrackEvent.Quotes_EditQuote_AddAccessoryItem, {
                addLocation: AccessoryItemAddLocation.Modal,
                orderType,
              });
              onLineItemsAdd(accessoryLineItems);
              message.success(`${accessoryLineItems.length} Accessory Item(s) have been added`);
            }
          }}
        />
      ) : null}
    </>
  );
}
