import React, { useState } from 'react';

import { CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { FlexSpace } from 'components/FlexSpace';
import { ParsedInput } from 'components/Input/ParsedInput';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import { typedColumn } from 'utils/tables';
import { floatOrDefault } from 'utils/units';

import { track, TrackEvent } from '../../../../utils/track';
import { TransferSummary, TransferSummaryLine } from '../types';

export function PurchaseTargetTransferLinesModal({
  initialTransferSummary,
  onClose,
}: {
  initialTransferSummary: TransferSummary;
  onClose: (transferSummary: TransferSummary) => void;
}) {
  const [transferSummary, setTransferSummary] = useState(initialTransferSummary);

  const { data: sourceLocationLinesData } = useCoreApi(schemas.purchasing.getPurchaseTargetLines, {
    queryParams: {
      filter: {
        itemIds: transferSummary.lines.map((line) => line.itemId),
        locationIds: [transferSummary.sourceLocation.foreignId],
      },
    },
  });
  const sourceLocationLines = sourceLocationLinesData?.items ?? [];

  const handleQtyToTransferChange = (value: number, line: TransferSummaryLine) => {
    if (line.unitQuantity !== value) {
      const linesWithoutCurrentLine = transferSummary.lines.filter((l) => l.itemId !== line.itemId);

      // removing a transfer line set to 0 quantity will be handled at the top level (PurchaseTargetLinesPage)
      setTransferSummary({
        ...transferSummary,
        lines: [...linesWithoutCurrentLine, { ...line, unitQuantity: value }],
      });
    }
  };

  const tableColumns: Array<ColumnType<TransferSummaryLine> | null> = [
    typedColumn({
      title: 'Item',
      dataIndex: 'itemId',
      render: (_: string, transferSummaryLine: TransferSummaryLine) => (
        <div>
          <div>{transferSummaryLine.itemId}</div>
          <div>{transferSummaryLine.itemName}</div>
        </div>
      ),
    }),
    {
      title: 'Quantity Available to Transfer',
      render: (transferSummaryLine: TransferSummaryLine) =>
        // show the qty available of the source location
        sourceLocationLines.find((line) => line.itemId === transferSummaryLine.itemId)?.qtyAvailable ?? '-',
    },
    typedColumn({
      title: 'Qty to Transfer',
      dataIndex: 'unitQuantity',
      width: `300px`,
      render: (unitQuantity, record) => (
        <FlexSpace>
          <ParsedInput<number>
            className={css`
              min-width: 70px;
            `}
            size="small"
            valueParser={(value) => floatOrDefault(value, 0)}
            value={unitQuantity}
            onValueChange={(value) => {
              handleQtyToTransferChange(value, record);
            }}
          />

          <Button
            size="small"
            disabled={unitQuantity === 0}
            onClick={() => {
              track(TrackEvent.Purchasing_Optimization_ResetButtonClick, {});
              handleQtyToTransferChange(0, record);
            }}
          >
            {unitQuantity > 0 ? <CloseOutlined /> : <ReloadOutlined />}
          </Button>
        </FlexSpace>
      ),
    }),
    typedColumn({
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
      width: `300px`,
      render: (unitOfMeasure) => <div>{unitOfMeasure}</div>,
    }),
  ];
  return (
    <ViewAllModal
      title={`Lines for Transfers from #${transferSummary.sourceLocation.foreignId}: ${transferSummary.sourceLocation.name} to #${transferSummary.destinationLocation.foreignId}: ${transferSummary.destinationLocation.name}`}
      wrapClassName="purchase-target-transfer-lines-modal"
      content={
        <Table
          columns={tableColumns.filter(truthy)}
          data={transferSummary.lines.sort((a, b) => b.purchaseLineKey.localeCompare(a.purchaseLineKey))}
          verticalAlign="middle"
        />
      }
      onClose={() => onClose(transferSummary)}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose(initialTransferSummary)}>
            Cancel
          </Button>
          <Button key="ok" type="primary" onClick={() => onClose(transferSummary)}>
            OK
          </Button>
        </>
      }
    />
  );
}
