import type { HTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

import { cx, css } from '@emotion/css';
import { theme } from 'theme';

import { formatNumber } from 'utils/formatting';

export interface CounterProps extends HTMLAttributes<HTMLSpanElement> {
  value?: number;
  variant?: 'primary' | 'neutral';
  size?: 'regular' | 'large';
  disabled?: boolean;
  selected?: boolean;
}

export const Counter = forwardRef<HTMLSpanElement, CounterProps>(
  ({ value, variant = 'primary', size = 'regular', selected = false, disabled = false, className, ...props }, ref) => (
    <span
      ref={ref}
      className={cx(
        css`
          border-radius: 6px;
          font-size: 12px;
          line-height: 16px;
          padding: 0 6px;
          height: 16px;
          transition: color ${theme.transitions.fast}, background ${theme.transitions.fast};
          ${size === 'large' &&
          css`
            font-size: 14px;
            height: 24px;
            line-height: 20px;
            padding: 2px 6px;
            border-radius: 8px;
          `}
          ${variant === 'primary' &&
          css`
            background: ${theme.colors.primary[200]};
            color: ${theme.colors.primary[800]};
            ${selected &&
            css`
              color: ${theme.colors.neutral[0]};
              background: ${theme.colors.primary[500]};
            `}
          `}
            ${variant === 'neutral' &&
          css`
            background: ${theme.colors.neutral[75]};
            color: ${theme.colors.neutral[600]};
            ${selected &&
            css`
              color: ${theme.colors.neutral[0]};
              background: ${theme.colors.neutral[400]};
            `}
          `}
            ${disabled &&
          css`
            background: ${theme.colors.neutral[200]};
            color: ${theme.colors.neutral[300]};
          `}
        `,
        className,
      )}
      {...props}
    >
      {formatNumber(value)}
    </span>
  ),
);
