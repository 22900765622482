import React, { ReactNode } from 'react';

import { css } from '@emotion/css';
import { Skeleton } from 'antd';
import { theme } from 'theme';

import { ActionButton } from './Button/ActionButton';

export interface MiniTableColumn<RecordT> {
  render: (record: RecordT, rowIdx: number) => ReactNode;
  align?: 'left' | 'right';
  maxWidth?: string;
  width?: string;
  title?: (record: RecordT) => string;
}

export interface MiniTableProps<RecordT> {
  columns: MiniTableColumn<RecordT>[];
  data: RecordT[] | undefined;
  isLoading?: boolean;
  error?: Error | undefined;
  numRowsShown?: number;
  onMoreButtonClick?: () => void;
  fullWidth?: boolean;
}

export const DEFAULT_NUM_ROWS_SHOWN = 3;

export function MiniTable<RecordT>({
  columns,
  data,
  isLoading,
  error,
  onMoreButtonClick,
  numRowsShown,
  fullWidth = true,
}: MiniTableProps<RecordT>) {
  if (error) {
    return <MiniTableMessage message="Failed to load data. Please refresh to try again." />;
  }

  if (isLoading || !data) {
    return <MiniTableSkeleton />;
  }

  if (data.length === 0) {
    return <MiniTableMessage message="No records found." />;
  }

  return (
    <>
      <table
        className={css`
          ${fullWidth && 'width: 100%;'}
          td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-child {
              padding-right: 0;
              max-width: 70px;
              white-space: nowrap;
            }
          }
        `}
      >
        <tbody>
          {data.slice(0, numRowsShown).map((record, rowIdx) => (
            <tr key={rowIdx}>
              {columns.map((col, colIdx) => (
                <td
                  key={colIdx}
                  style={{
                    textAlign: col.align || 'left',
                    maxWidth: col.maxWidth ? col.maxWidth : undefined,
                    width: col.width ? col.width : undefined,
                  }}
                  title={col.title ? col.title(record) : ``}
                >
                  {col.render(record, rowIdx)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {onMoreButtonClick && <ActionButton label="View All" onClick={onMoreButtonClick} />}
    </>
  );
}

export function MiniTableRowSkeleton() {
  return (
    <Skeleton.Input
      active
      className={css`
        width: 100%;
        display: block;

        & > .ant-skeleton-input {
          border-radius: 5px;
          height: 14px;
        }
      `}
      size="small"
    />
  );
}

export function MiniTableSkeleton() {
  return (
    <div>
      <MiniTableRowSkeleton />
      <MiniTableRowSkeleton />
      <MiniTableRowSkeleton />
      <MiniTableRowSkeleton />
    </div>
  );
}

export function MiniTableMessage({ message }: { message: React.ReactChild }) {
  return (
    <div
      className={css`
        color: ${theme.colors.neutral[400]};
      `}
    >
      {message}
    </div>
  );
}
