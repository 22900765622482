import React, { useState, useCallback } from 'react';

import copy from 'copy-to-clipboard';

import { Tooltip } from 'components/Tooltip';

import { StyledTypography, StyledTypographyProps } from './Typography.style';

export interface TypographyProps extends StyledTypographyProps {
  onClick?: () => void;
}

export const Typography = (props: TypographyProps): JSX.Element => {
  const { copyable = false } = props;

  if (copyable) {
    return <CopyableTypography {...props} />;
  }

  return <StyledTypography {...props} />;
};

const CopyableTypography = (props: TypographyProps): JSX.Element => {
  const { children } = props;

  const [copyDone, setCopyDone] = useState(false);

  const handleCopy = useCallback(() => {
    copy(children as string);
    setCopyDone(true);

    setTimeout(() => {
      setCopyDone(false);
    }, 1000);
  }, [children]);

  return (
    <Tooltip title={copyDone ? 'Copied' : 'Copy to clipboard'} placement="bottom">
      <StyledTypography onClick={handleCopy} {...props} />
    </Tooltip>
  );
};

export const PageHeading = (props: TypographyProps): JSX.Element => (
  <Typography type="large" style={{ fontWeight: 'bold' }} {...props} />
);
