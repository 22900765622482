export async function zendeskAnonymousRequest({
  requesterName,
  requesterEmail = `solutions@recurrency.com`,
  subject,
  body,
}: {
  requesterName: string;
  requesterEmail?: string;
  subject: string;
  body: string;
}) {
  await fetch('https://recurrencyhelp.zendesk.com/api/v2/requests.json', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      request: {
        requester: {
          name: requesterName,
          email: requesterEmail,
        },
        subject,
        comment: {
          body,
        },
      },
    }),
  });
}

export function openZendeskModal() {
  if (window.zE) {
    window.zE.show();
    window.zE('webWidget', 'open');
  }
}
