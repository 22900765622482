import React, { useEffect, useState } from 'react';

import { Alert } from 'components/Alert';

export const NoConnectionBanner = () => {
  const pausedBannerText = `You have no internet connection.`;

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  if (!isOnline) {
    return <Alert noRadius closable message={pausedBannerText} banner type="warning" onClose={() => {}} />;
  }
  return <></>;
};
