import React from 'react';

import { DownOutlined, ShopOutlined } from '@ant-design/icons';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { Button } from 'components/Button';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { reloadPageWithUpdatedCompanySelection } from 'utils/roleAndTenant';

import * as Styled from './PrimaryNavigation.style';

export const GlobalCompanyFilterSelect = ({ isDesktopView }: { isDesktopView: boolean }) => {
  const { activeCompanyIds, userAllowedCompanies } = useGlobalApp();

  const companyOptions: MultiSelectOption[] = userAllowedCompanies.map((item) => ({
    value: item.companyId,
    label: `${item.companyId}: ${item.companyName}`,
  }));

  // Set header to "All Companies" if all companies are selected or if none are
  let companySelectHeading = 'All Companies';
  if (activeCompanyIds && activeCompanyIds.length !== companyOptions.length) {
    if (activeCompanyIds.length === 1) {
      const matchingOption = companyOptions.find((option) => option.value === activeCompanyIds[0]);
      companySelectHeading = (matchingOption?.label as string) ?? `${activeCompanyIds[0]}`;
    } else if (activeCompanyIds.length > 1) {
      companySelectHeading = `${activeCompanyIds.length} Companies Selected`;
    }
  }

  // only show the dropdown if there are multiple companies to choose from
  return (
    <>
      {companyOptions.length > 1 && (
        <Styled.DropdownWrapper>
          <AsyncMultiSelect
            queryPlaceholder="Search companies"
            selectedValues={activeCompanyIds || []}
            onSelectedValuesChange={(values) => reloadPageWithUpdatedCompanySelection(values)}
            selectProps={{ options: companyOptions }}
            disableValuesTooltip
            triggerButton={
              <Button size="small">
                <ShopOutlined />
                {isDesktopView && ` ${companySelectHeading} `}
                {isDesktopView && <DownOutlined />}
              </Button>
            }
          />
        </Styled.DropdownWrapper>
      )}
    </>
  );
};
