import React from 'react';

import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { OrderType } from 'utils/track';

import { OrderEditFlowSAPB1 } from '../orders/OrderEditFlowSAPB1';
import { QuoteEditFlowP21 } from './QuoteEditFlowP21/QuoteEditFlowP21';

export const QuoteEditPage = () => {
  const { activeTenant } = useGlobalApp();

  if (activeTenant.erpType === IntegratedErps.SAPB1) {
    return <OrderEditFlowSAPB1 orderType={OrderType.Quote} />;
  }
  return <QuoteEditFlowP21 orderType={OrderType.Quote} />;
};
