import React from 'react';

import { css } from '@emotion/css';

export interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const SplitPage = ({ left, right }: Props): JSX.Element => (
  <div
    className={css`
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    `}
  >
    <div
      className={css`
        flex: 1 0 400px;
        overflow: hidden;
      `}
    >
      {left}
    </div>
    <div>{right}</div>
  </div>
);
