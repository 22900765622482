import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router';

import { CenteredLoader } from 'components/Loaders';

import { pendingApiPromises } from 'utils/api';
import { env } from 'utils/env';

const versionCheckEnvironments = ['production', 'staging'];
const versionCheckDomains = ['app.recurrency.com', 'app-staging.recurrency.com'];

export const checkIfNewAppVersion = async () => {
  try {
    if (versionCheckEnvironments.includes(env.APP_ENV) && versionCheckDomains.includes(window.location.host)) {
      // NOTE: version.json is bundled when build is created
      const { version } = await (await fetch('/version.json')).json();
      const isLatestVersion = env.APP_VERSION === version;
      return !isLatestVersion;
    }
  } catch (err) {}
  return false;
};

export const reloadPage = () => {
  window.location.reload();
};

export const AppVersionPoller = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [haveNewAppVersion, setHaveNewAppVersion] = useState(false);

  useEffect(() => {
    setInterval(async () => {
      setHaveNewAppVersion(await checkIfNewAppVersion());
    }, 1000 * 60 * 5);
  }, []);

  // only refresh for new app version when when user navigates to a different page (pathname),
  // this ensures we don't disrupt the user's flow.
  useEffect(() => {
    // if there are network requests in flight, don't trigger page refresh
    // otherwise any long exports e.g 'Submit to P21' won't show their notification.
    const noPendingApiRequests = pendingApiPromises.size === 0;

    // if any ant notifications are shown, don't trigger page refresh
    const noNotificationsShown = document.querySelectorAll('.ant-notification-notice').length === 0;

    if (haveNewAppVersion && noPendingApiRequests && noNotificationsShown) {
      setIsLoading(true);
      reloadPage();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return children;
};
