import React, { useEffect, useState } from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ItemLotDTO } from '@recurrency/core-api-schema/dist/items/getItemLots';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';

import { Input } from 'components/Input';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { MiniTable } from 'components/MiniTable';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatUSD } from 'utils/formatting';
import {
  sortableDateColumn,
  sortableDollarWithCentsColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortableStringColumn,
  typedColumn,
} from 'utils/tables';

import { LotSelection, OrderLineItemSAPB1 } from 'types/hash-state';

interface SelectedLotsByLotId {
  [lotId: string]: {
    quantity: number;
    lotCostPerUOM?: number;
  };
}
interface WarningsByLotId {
  [lotId: string]: {
    message: string;
  };
}

interface SelectLineItemLotsModalProps {
  lineItem: OrderLineItemSAPB1;
  locationId?: string;
  onClose: (selectedLots?: LotSelection | undefined) => void;
}

export function ItemLotsModal({ lineItem, locationId, onClose }: SelectLineItemLotsModalProps): JSX.Element {
  const { lotsSelected: initialLotsSelected } = lineItem;
  const initialLotsSelectedByLotId: SelectedLotsByLotId = Object.fromEntries(
    (initialLotsSelected || []).map((lot) => [lot.lotId, { quantity: lot.quantity, lotCostPerUOM: lot.lotCostPerUOM }]),
  );
  const [selectedLotsByLotId, setSelectedLotsByLotId] = useState<SelectedLotsByLotId>(initialLotsSelectedByLotId);
  const [warningsByLotId, setWarningsByLotId] = useState<WarningsByLotId>({});
  const [totalQuantitySelected, setTotalQuantitySelected] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number | undefined>(undefined);

  const {
    data: lotsData,
    isLoading: lotsIsLoading,
    error: lotsError,
  } = useCoreApi(schemas.items.getItemLots, {
    pathParams: { itemId: lineItem.foreignId },
    queryParams: { locationId },
  });
  const lotsAvailable = lotsData?.items || [];

  function updateLotSelection(lot: ItemLotDTO, newQuantity: number) {
    const quantityRemaining = lineItem.quantity && lineItem.quantity - totalQuantitySelected;
    let quantityToAdd = newQuantity - (selectedLotsByLotId[lot.lotId]?.quantity || 0);
    if (quantityRemaining && quantityToAdd > quantityRemaining) {
      quantityToAdd = quantityRemaining;
    }
    newQuantity = (selectedLotsByLotId[lot.lotId]?.quantity || 0) + quantityToAdd;

    // validation
    if (newQuantity > lot.quantityAvailable) {
      warningsByLotId[lot.lotId] = { message: 'Cannot exceed quantity available' };
      setWarningsByLotId({ ...warningsByLotId });
      return;
    }
    if (lineItem.quantity && totalQuantitySelected + quantityToAdd > lineItem.quantity) {
      warningsByLotId[lot.lotId] = { message: 'Cannot exceed line item quantity' };
      setWarningsByLotId({ ...warningsByLotId });
      return;
    }
    delete warningsByLotId[lot.lotId];
    setWarningsByLotId({ ...warningsByLotId });

    // update selectedLotsByLotId
    if (newQuantity === 0) {
      delete selectedLotsByLotId[lot.lotId];
    } else {
      selectedLotsByLotId[lot.lotId] = { quantity: newQuantity, lotCostPerUOM: lot.unitCost };
    }
    setSelectedLotsByLotId({ ...selectedLotsByLotId });
  }

  // track cost and quantity selected for summary below table
  useEffect(() => {
    // flatten selectedLotsByLotId into array of object for easier processing
    const lotsSelectedArray = Object.keys(selectedLotsByLotId).map((key) => ({
      ...selectedLotsByLotId[key],
      lotId: key,
    }));
    setTotalQuantitySelected(lotsSelectedArray.reduce((acc, lot) => acc + lot.quantity, 0));
    if (lotsSelectedArray.every((lot) => lot.lotCostPerUOM !== undefined && lot.lotCostPerUOM > 0)) {
      setTotalCost(lotsSelectedArray.reduce((acc, lot) => acc + lot.quantity * lot.lotCostPerUOM!, 0));
    } else setTotalCost(undefined);
  }, [selectedLotsByLotId, setSelectedLotsByLotId]);

  const shouldShowBrand = lotsAvailable.some((lot) => lot.brand);

  const tableColumns: (ColumnType<ItemLotDTO> | null)[] = [
    sortableIdColumn({
      title: 'Lot',
      dataIndex: 'lotId',
      sorter: false,
      width: 2,
    }),
    shouldShowBrand
      ? sortableStringColumn({
          title: 'Brand',
          dataIndex: 'brand',
          sorter: false,
          width: 2,
        })
      : null,
    sortableDateColumn({
      title: 'In Date',
      dataIndex: 'admissionDate',
      sorter: false,
      width: 2,
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      width: 1,
      sorter: false,
    }),
    sortableNumberColumn({
      title: 'Qty Available',
      dataIndex: 'quantityAvailable',
      width: 1.5,
      sorter: false,
      render: (_, obj: ItemLotDTO) => (
        <Typography onClick={() => updateLotSelection(obj, obj.quantityAvailable)}>{obj.quantityAvailable}</Typography>
      ),
    }),
    typedColumn({
      title: 'Quantity',
      width: 2,
      render: (_, obj: ItemLotDTO) => (
        <Input
          type="number"
          min={0}
          value={selectedLotsByLotId[obj.lotId] ? selectedLotsByLotId[obj.lotId].quantity.toLocaleString() : ''}
          onChange={(ev) => updateLotSelection(obj, Number(ev.target.value))}
          hasWarning={warningsByLotId[obj.lotId] !== undefined}
          prefix={
            <Tooltip title={warningsByLotId[obj.lotId]?.message || null}>
              <WarningOutlined
                className={css`
                  color: ${theme.colors.warning[600]};
                `}
                hidden={warningsByLotId[obj.lotId] === undefined}
              />
            </Tooltip>
          }
        />
      ),
    }),
  ];

  if (lotsError) {
    return <CenteredError error={lotsError} />;
  }
  if (!lotsData || lotsIsLoading) {
    return <CenteredLoader />;
  }

  return (
    <Modal
      visible
      title={`Lots for Item #${lineItem.foreignId}`}
      width={800}
      onCancel={() => onClose()}
      onOk={() => onClose(Object.keys(selectedLotsByLotId).map((key) => ({ ...selectedLotsByLotId[key], lotId: key })))}
      closable={false}
    >
      <Table
        columns={tableColumns.filter(filterCostAndGM)}
        data={lotsAvailable}
        rowKey="lotId"
        verticalAlign="middle"
        className={css`
          height: 520px;
        `}
        sticky
      />
      <div
        className={css`
          margin-top: 16px;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-end;
        `}
      >
        <MiniTable
          data={[
            {
              name: 'Quantity Remaining',
              value: lineItem.quantity && lineItem.quantity - totalQuantitySelected,
            },
            { name: 'Total Quantity Selected', value: totalQuantitySelected },
            { name: 'Average Unit Cost', value: formatUSD(totalCost && totalCost / totalQuantitySelected, true) },
            { name: 'Total Cost', value: formatUSD(totalCost, true) },
          ]}
          columns={[
            {
              render: (record) => `${record.name}:`,
            },
            {
              render: (record) => (
                <strong
                  className={css`
                    margin-left: 16px;
                  `}
                >
                  {record.value}
                </strong>
              ),
              align: 'right',
              maxWidth: '100px',
            },
          ]}
          fullWidth={false}
        />
      </div>
    </Modal>
  );
}
