import { Container as LayoutContainer } from 'components/Container';

import { styled } from 'utils/styledComponent';

export const Container = styled(LayoutContainer)`
  padding-top: 24px;
  padding-bottom: 30px;
`;

export const Header = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderActions = styled('div')`
  display: flex;
  align-items: center;
  .ant-btn {
    margin-left: 8px;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0 16px 0;
`;

export const VerticalCenter = styled('div')`
  display: flex;
  align-items: center;
`;
