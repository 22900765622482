import React from 'react';

import { LineItemActionsP21 } from 'pages/orders/quotes/types';

import { ActionButton } from 'components/Button/ActionButton';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';

import { showAsyncModal } from 'utils/asyncModal';
import { formatDate } from 'utils/formatting';

import { ISODateStr, QuoteLineItemP21 } from 'types/hash-state';

import { ItemRequiredDateModal } from './ItemRequiredDateModal';

export function ItemRequiredDatePropertiesP21({
  lineItem,
  lineItemActions,
  lineItemIdx,
  headerRequiredDate,
}: {
  lineItem: QuoteLineItemP21;
  lineItemActions: LineItemActionsP21;
  lineItemIdx: number;
  headerRequiredDate?: ISODateStr;
}) {
  const props: LabelValueProperty[] = [
    {
      label: 'Required Date',
      value: (
        <>
          {formatDate(lineItem.requiredDate || headerRequiredDate)}
          {` | `}
          <ActionButton
            label="Edit"
            onClick={async () => {
              const selectedRequiredDate = await showAsyncModal(ItemRequiredDateModal, {
                lineItem,
                defaultRequiredDate: lineItem.requiredDate || headerRequiredDate,
              });
              if (selectedRequiredDate) {
                lineItemActions.updateLineItem(lineItemIdx, { requiredDate: selectedRequiredDate });
              }
            }}
          />
        </>
      ),
    },
  ];

  return <LabelValueProperties properties={props} />;
}
