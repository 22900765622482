import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { LeftOutlined, RightOutlined, SwapOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { ItemLotDTO } from '@recurrency/core-api-schema/dist/items/getItemLots';
import { SalesOrderPriceDefaultsUomDTO } from '@recurrency/core-api-schema/dist/salesOrders/getSalesOrderPriceDefaults';
import { Form, Space, Steps } from 'antd';

import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { FixedFooter } from 'components/FixedFooter';
import { NotificationLink } from 'components/Links';
import { PageHeader } from 'components/PageHeader';
import { Tooltip } from 'components/Tooltip';
import { TrialBanner } from 'components/TrialBanner';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureError } from 'utils/error';
import { capitalize, getErpName, splitIfIdNameStr } from 'utils/formatting';
import { objFromArray } from 'utils/object';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';
import { scrollToTop } from 'utils/scroll';
import { StatefulPromise } from 'utils/statefulPromise';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { OrderType, track, TrackEvent } from 'utils/track';

import { OrderEditHashStateSAPB1, OrderLineItemSAPB1, QuoteEditStep } from 'types/hash-state';

import * as Styled from '../../quotes/QuoteEditFlowP21/QuoteEditFlowP21.style';
import { OrderEditHeaderStep } from './OrderEditHeaderStep';
import { OrderEditLineItemsStep } from './OrderEditLineItemsStep';
import { OrderEditReviewStep } from './OrderEditReviewStep';
import {
  createOrderSAPB1BodyFromHashState,
  createQuoteSAPB1BodyFromHashState,
  getLineItemTrackProps,
  getLotInfoCacheKey,
  getPriceInfoCacheKey,
  validateLineItems,
  getDefaultLotSelection,
  fetchItemAvailableLots,
} from './orderUtils';
import { LineItemActionsSAPB1, OrderLineItemSAPB1WithInfo } from './types';

export interface OrderEditPageSAPB1Props {
  orderType: OrderType;
}

export function OrderEditFlowSAPB1({ orderType }: OrderEditPageSAPB1Props) {
  const [docState, updateDocState] = useHashState<OrderEditHashStateSAPB1>();
  const prevLineItems = useRef<OrderLineItemSAPB1WithInfo[]>([]);

  const { step: currentStep = QuoteEditStep.Header } = docState;
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [headerForm] = Form.useForm<OrderEditHashStateSAPB1>();
  const { activeTenant, activeUser } = useGlobalApp();
  const history = useHistory();
  const [priceInfoCache, setPriceInfoCache] = useState<{
    [key: string]: StatefulPromise<{ uomDefaults: Record<string, SalesOrderPriceDefaultsUomDTO> }>;
  }>({});
  // null means lots not assignable to item
  const [lotInfoCache, setLotsInfoCache] = useState<{
    [key: string]: StatefulPromise<{ lotsAvailable: ItemLotDTO[] | null }>;
  }>({});

  const handleDocStateChange = (newDocState: Partial<OrderEditHashStateSAPB1>) => {
    updateDocState(newDocState);
  };

  const handleOrderSubmit = async () => {
    const submitNotification = createSubmissionNotification({
      entityName: capitalize(orderType),
      erpType: activeTenant.erpType,
      expectedWaitSeconds: 60,
    });

    try {
      track(TrackEvent.Orders_EditOrder_Submit, getLineItemTrackProps(docState.items || []));
      const bodyParams = createOrderSAPB1BodyFromHashState(docState);
      const response = await coreApiFetch(schemas.salesOrders.createSalesOrder, { bodyParams });
      const createdOrderId = response.data.salesOrderId;
      submitNotification.success({
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.orderDetails(createdOrderId)}>
            View {entityName} #{createdOrderId}
          </NotificationLink>
        ),
      });
      history.push(routes.orders.orderDetails(createdOrderId));
    } catch (err) {
      submitNotification.error(err, {
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.orderNewSAPB1(docState)}>
            Return to {entityName}
          </NotificationLink>
        ),
      });
    }
  };

  const handleQuoteSubmit = async () => {
    const submitNotification = createSubmissionNotification({
      entityName: capitalize(orderType),
      erpType: activeTenant.erpType,
      expectedWaitSeconds: 60,
    });

    try {
      track(TrackEvent.Quotes_EditQuote_Submit, getLineItemTrackProps(docState.items || []));
      const bodyParams = createQuoteSAPB1BodyFromHashState(docState);
      const response = await coreApiFetch(schemas.salesQuotes.postCreateSalesQuote, { bodyParams });
      const createdQuoteId = response.data.salesQuoteId!;
      submitNotification.success({
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.quoteDetails(createdQuoteId)}>
            View {entityName} #{createdQuoteId}
          </NotificationLink>
        ),
      });
      history.push(routes.orders.quoteDetails(createdQuoteId));
    } catch (err) {
      submitNotification.error(err, {
        description: (notificationKey, entityName) => (
          <NotificationLink notificationKey={notificationKey} to={routes.orders.quoteNewSAPB1(docState)}>
            Return to {entityName}
          </NotificationLink>
        ),
      });
    }
  };

  const lineItemActions = useMemo((): LineItemActionsSAPB1 => {
    const updateDocStateLineItems = (lineItems: OrderLineItemSAPB1[]) => {
      updateDocState({ items: lineItems });
    };

    return {
      updateLineItem: (index: number, update: Partial<OrderLineItemSAPB1>) => {
        const newLineItems = [...(docState.items || [])];
        newLineItems[index] = { ...newLineItems[index], ...update };
        updateDocStateLineItems(newLineItems);
      },
      replaceLineItem: (index: number, newLineItem: OrderLineItemSAPB1) => {
        const newLineItems = [...(docState.items || [])];
        newLineItems[index] = newLineItem;
        updateDocStateLineItems(newLineItems);
      },
      addEmptyLineItem: () => {
        const newLineItems = [...(docState.items || []), {}];
        updateDocStateLineItems(newLineItems as OrderLineItemSAPB1[]);
      },
      addLineItem: (item: OrderLineItemSAPB1) => {
        const newLineItems = [...(docState.items || [])];
        if (newLineItems.length > 0 && !newLineItems[newLineItems.length - 1].foreignId) {
          lineItemActions.updateLineItem(newLineItems.length - 1, item);
        } else {
          updateDocStateLineItems([...newLineItems, item]);
        }
      },
      deleteLineItem: (index: number) => {
        updateDocStateLineItems(docState.items!.filter((_, idx) => idx !== index));
      },
      duplicateLineItem: (index: number) => {
        const newLineItems = [...(docState.items || [])];
        // insert duplicate item after its current index
        newLineItems.splice(index + 1, 0, { ...newLineItems[index] });
        updateDocStateLineItems(newLineItems);
      },
      moveLineItemUp: (index: number) => {
        const newLineItems = [...(docState.items || [])];
        if (index > 0) {
          const lineItem = newLineItems[index];
          newLineItems[index] = newLineItems[index - 1];
          newLineItems[index - 1] = lineItem;
          updateDocStateLineItems(newLineItems);
        }
      },
      moveLineItemDown: (index: number) => {
        const newLineItems = [...(docState.items || [])];
        if (index + 1 < newLineItems.length) {
          const lineItem = newLineItems[index];
          newLineItems[index] = newLineItems[index + 1];
          newLineItems[index + 1] = lineItem;
          updateDocStateLineItems(newLineItems);
        }
      },
    };
  }, [docState.items, updateDocState]);

  // fetch prices for items not seen before using price defaults endpoint
  useEffect(() => {
    if (!docState.customer || !docState.shipTo || !docState.items || docState.items.length === 0) {
      return;
    }

    const itemsToFetch: OrderLineItemSAPB1[] = [];
    for (const lineItem of docState.items || []) {
      if (lineItem.foreignId && !priceInfoCache[getPriceInfoCacheKey(docState, lineItem)]) {
        itemsToFetch.push(lineItem);
      }
    }
    if (itemsToFetch.length === 0) {
      return;
    }

    // fetch new items to fetch as a single bulk call
    const defaultsPromise = coreApiFetch(schemas.salesOrders.getSalesOrderPriceDefaults, {
      bodyParams: {
        customerId: splitIfIdNameStr(docState.customer)?.foreignId,
        shipToId: splitIfIdNameStr(docState.shipTo)?.foreignId,
        items: itemsToFetch.map((line) => ({
          itemId: line.foreignId,
        })),
      },
    });

    for (let idx = 0; idx < itemsToFetch.length; ++idx) {
      const item = itemsToFetch[idx];
      priceInfoCache[getPriceInfoCacheKey(docState, item)] = new StatefulPromise(
        defaultsPromise.then((res) => ({
          uomDefaults: objFromArray(
            res.data.items.find((i) => i.itemId === item.foreignId)?.unitOfMeasures ?? [],
            'unitOfMeasureId',
          ),
        })),
      );
    }

    setPriceInfoCache({ ...priceInfoCache });
    defaultsPromise
      .catch((err) => captureError(err))
      .finally(() => {
        // re-trigger react update after promise finishes loading
        requestAnimationFrame(() => {
          setPriceInfoCache({ ...priceInfoCache });
        });
      });
  }, [docState, priceInfoCache]);

  // fill in line item defaults as single useEffect call instead of multiple useEffect calls spread between components
  useEffect(() => {
    const newLineItems: OrderLineItemSAPB1[] = [...(docState.items || [])];
    if (newLineItems.length === 0) {
      // add empty line item, if no line items
      newLineItems.push({} as OrderLineItemSAPB1);
    } else {
      for (let idx = 0; idx < newLineItems.length; ++idx) {
        const lineItem = newLineItems[idx];
        if (lineItem.foreignId) {
          const newLineItem = { ...lineItem };
          if (lineItem.quantity === undefined) {
            newLineItem.quantity = 1;
          }

          const priceInfo = priceInfoCache[getPriceInfoCacheKey(docState, lineItem)];
          const uomDefaults = priceInfo?.value?.uomDefaults;
          const uomList = Object.values(uomDefaults || {});
          const sellingDefaultUom = uomList.find((uom) => uom.isSellingDefault);
          const pricingDefaultUom = uomList.find((uom) => uom.isPricingDefault);

          // selling uom, price and cost
          if (newLineItem.unitOfMeasureId === undefined) {
            if (sellingDefaultUom) {
              newLineItem.unitOfMeasure = sellingDefaultUom.unitOfMeasure;
              newLineItem.unitOfMeasureId = sellingDefaultUom.unitOfMeasureId;
              if (Number.isFinite(sellingDefaultUom.unitPrice)) {
                newLineItem.unitPrice = sellingDefaultUom.unitPrice;
              }
              if (Number.isFinite(sellingDefaultUom.unitCost)) {
                newLineItem.unitCost = sellingDefaultUom.unitCost;
              }
            }
            if (pricingDefaultUom) {
              newLineItem.pricingUnitOfMeasureId = pricingDefaultUom.unitOfMeasureId;
            }
          }

          // default lot selection
          const unitQtyHasChanged =
            prevLineItems.current[idx]?.quantity && prevLineItems.current[idx].quantity !== newLineItem.quantity;
          if (unitQtyHasChanged) {
            newLineItem.lotsSelected = undefined;
          }

          const lotsInfo = lotInfoCache[getLotInfoCacheKey(docState, lineItem.foreignId)];
          const lotsAvailable = lotsInfo?.value?.lotsAvailable;
          const shouldSetDefaultLots = lotsAvailable && newLineItem.quantity && newLineItem.lotsSelected === undefined;
          const defaultLotSelection = shouldSetDefaultLots
            ? getDefaultLotSelection(lotsAvailable, newLineItem.quantity!)
            : undefined;

          if (defaultLotSelection) {
            newLineItem.lotsSelected = defaultLotSelection;
          }

          // lots average unit cost
          if (newLineItem.lotsSelected && uomDefaults) {
            const totalLotCost = newLineItem.lotsSelected.reduce(
              (sum, lot) => sum + lot.quantity * (lot.lotCostPerUOM || 0),
              0,
            );
            const lotsAverageCost = totalLotCost / newLineItem.lotsSelected.reduce((sum, lot) => sum + lot.quantity, 0);

            // normalize lotsAverageCost to selling unit size
            let lotsToSellingUnitSizeFactor = 1;
            if (
              newLineItem.unitOfMeasureId &&
              sellingDefaultUom &&
              newLineItem.unitOfMeasureId !== sellingDefaultUom.unitOfMeasureId
            ) {
              // assume lots uom is same as selling default uom (should be true for most cases)
              lotsToSellingUnitSizeFactor =
                uomDefaults[newLineItem.unitOfMeasureId].unitSize / sellingDefaultUom.unitSize;
            }

            newLineItem.lotsAvgUnitCost = lotsAverageCost * lotsToSellingUnitSizeFactor;
          } else {
            newLineItem.lotsAvgUnitCost = undefined;
          }

          newLineItems[idx] = newLineItem;
        }
      }
    }

    // only update if new line items have changed
    if (JSON.stringify(newLineItems) !== JSON.stringify(docState.items)) {
      updateDocState({ items: newLineItems });
    }
  }, [docState, priceInfoCache, lotInfoCache, updateDocState]);

  // stitch doc.items & priceInfoCache into lineItemsWithInfo object to pass to OrderEditLineItemsStep
  const lineItemsWithInfo: OrderLineItemSAPB1WithInfo[] = useMemo(
    () =>
      (docState.items || []).map((lineItem) => {
        if (lineItem.foreignId) {
          const priceInfo = priceInfoCache[getPriceInfoCacheKey(docState, lineItem)];
          const lotsInfo = lotInfoCache[getLotInfoCacheKey(docState, lineItem.foreignId)];

          return {
            ...lineItem,
            isUomDefaultsLoading: priceInfo?.isPending() ?? true,
            uomDefaults: priceInfo?.value?.uomDefaults,
            lotsAvailable: lotsInfo?.value?.lotsAvailable,
          };
        }
        return { ...lineItem, isUomDefaultsLoading: false };
      }),
    [docState, lotInfoCache, priceInfoCache],
  );

  // get available lots for line items that track lots
  useEffect(() => {
    for (const lineItem of docState.items || []) {
      if (lineItem.foreignId && !lotInfoCache[getLotInfoCacheKey(docState, lineItem.foreignId)]) {
        const lotsPromise = fetchItemAvailableLots(lineItem.foreignId, splitIfIdNameStr(docState.location)?.foreignId);
        lotInfoCache[getLotInfoCacheKey(docState, lineItem.foreignId)] = new StatefulPromise(
          lotsPromise.then((lotsAvailable) => ({ lotsAvailable })),
        );
        setLotsInfoCache({ ...lotInfoCache });
        lotsPromise.catch((err) => captureError(err)).finally(() => setLotsInfoCache({ ...lotInfoCache }));
      }
    }
  }, [docState, docState.location, lotInfoCache]);

  const canGoToStep = useCallback(
    async (step: QuoteEditStep): Promise<boolean> => {
      if (step > QuoteEditStep.Header) {
        try {
          // antd Form makes you jumps through async try/catch hoop just to know whether a form is valid or not
          await headerForm.validateFields();
        } catch (err) {
          setValidationErrors(['Please fill required header fields.']);
          return false;
        }
      }
      if (step > QuoteEditStep.LineItems) {
        const errors = validateLineItems(lineItemsWithInfo);
        setValidationErrors(errors);
        return errors.length === 0;
      }
      setValidationErrors([]);
      return true;
    },
    [headerForm, lineItemsWithInfo],
  );

  const goToStep = async (step: QuoteEditStep) => {
    // don't go to next step, until every previous state is valid
    if (step >= currentStep && !(await canGoToStep(step))) {
      return;
    }
    updateDocState({ step });
    scrollToTop();
  };

  // track previous state to compare if unit quantity changed (use case: default lot selection)
  useEffect(() => {
    prevLineItems.current = lineItemsWithInfo;
  }, [docState, lineItemsWithInfo]);

  return (
    <Container>
      <PageHeader
        title={`New ${capitalize(orderType)}`}
        headerActions={
          <Button
            icon={<SwapOutlined />}
            onClick={() => {
              history.push(
                orderType === OrderType.Quote
                  ? routes.orders.orderNewSAPB1({ ...docState, step: QuoteEditStep.Header })
                  : routes.orders.quoteNewSAPB1({ ...docState, step: QuoteEditStep.Header }),
              );
            }}
          >
            Edit as {orderType === OrderType.Quote ? `Order` : `Quote`}
          </Button>
        }
      />
      <Form.Provider>
        <Steps current={currentStep} style={{ marginTop: 24 }} onChange={(newStep) => goToStep(newStep)}>
          {['Header', 'Line Items', 'Review'].map((step) => (
            <Steps.Step key={step} title={step} />
          ))}
        </Steps>
        {validationErrors.length ? (
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 16px;
            `}
          >
            <Alert message={validationErrors.join('\n')} banner type="error" />
          </div>
        ) : null}
        {currentStep === QuoteEditStep.Header && (
          <Styled.Content>
            <OrderEditHeaderStep form={headerForm} docState={docState} onDocStateChange={handleDocStateChange} />
          </Styled.Content>
        )}
        {currentStep === QuoteEditStep.LineItems && (
          <OrderEditLineItemsStep
            docState={docState}
            orderType={orderType}
            lineItemActions={lineItemActions}
            lineItemsWithInfo={lineItemsWithInfo}
          />
        )}
        {currentStep === QuoteEditStep.Review && <OrderEditReviewStep docState={docState} />}
        <FixedFooter>
          <TrialBanner />
          <Space>
            <Button onClick={() => goToStep(currentStep - 1)} disabled={currentStep === 0}>
              <LeftOutlined />
              Previous
            </Button>
            {currentStep === QuoteEditStep.Review ? (
              <Tooltip
                title={`This ${orderType} will be sent to ${getErpName(
                  activeTenant.erpType,
                )} and will no longer be editable in Recurrency.`}
              >
                <AsyncButton
                  type="primary"
                  onClick={orderType === OrderType.Order ? handleOrderSubmit : handleQuoteSubmit}
                  disabled={
                    orderType === OrderType.Quote
                      ? !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuote) ||
                        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateQuoteDraftOnly)
                      : !shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrder) ||
                        shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.OrdersCreateOrderViewOnly)
                  }
                >
                  Send to {getErpName(activeTenant.erpType)}
                </AsyncButton>
              </Tooltip>
            ) : (
              <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
                Next
                <RightOutlined />
              </Button>
            )}
          </Space>
        </FixedFooter>
      </Form.Provider>
    </Container>
  );
}
