import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ChargeDTO } from '@recurrency/core-api-schema/dist/payments/charge';
import { MetadataKey } from '@recurrency/core-api-schema/dist/payments/getPaymentIntents';
import { PaymentIntentDTO } from '@recurrency/core-api-schema/dist/payments/paymentIntentCommon';
import { RefundDTO } from '@recurrency/core-api-schema/dist/payments/refund';
import { Button, Card, Typography } from 'antd';
import { theme } from 'theme';

import { SmallLoader } from 'components/Loaders';

import { useCoreApi } from 'hooks/useApi';

import { EMPTY_VALUE_DASHES, formatCents } from 'utils/formatting';

import { getAmountWithCentsTo2Decimals } from '../common/paymentUtils';
import { DonutChart } from './DonutChart';

export const PaymentsTab = ({ orderNo, totalDue }: { orderNo: string; totalDue: string }) => {
  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);
  const [paymentsToShow, setPaymentsToShow] = useState<PaymentIntentDTO[] | undefined>([]);

  const { data, isLoading, reload, isReloading } = useCoreApi(schemas.payments.getPaymentIntents, {
    queryParams: {
      searchMetadata: {
        key: MetadataKey.orderId,
        value: orderNo,
      },
    },
  });

  useEffect(() => {
    const filteredPayments = data?.items.filter((pi: PaymentIntentDTO) =>
      ['succeeded', 'requires_capture'].includes(pi.status),
    );
    setPaymentsToShow(filteredPayments);
  }, [data]);

  // NOTE: this logic may break if there are more payment intents than stripe's limit of 100
  // this should be a rare case
  useMemo(() => {
    let totalAmountPaid = 0;
    paymentsToShow?.forEach((pi: PaymentIntentDTO) => {
      if (pi.status === 'succeeded') {
        const amountCharged = pi.amountReceived || 0;
        totalAmountPaid += amountCharged;
      }
    });
    setTotalAmountPaid(getAmountWithCentsTo2Decimals(totalAmountPaid));
  }, [paymentsToShow]);

  return isLoading ? (
    <div
      className={css`
        margin-top: 10px;
      `}
    >
      <SmallLoader />
    </div>
  ) : (
    <>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 6px;
        `}
      >
        <DonutChart total={parseFloat(totalDue)} paid={totalAmountPaid} />
        <Button
          onClick={reload}
          className={css`
            margin-right: 8px;
          `}
          loading={isReloading}
        >
          Refresh
        </Button>
      </div>
      <span style={{ color: theme.colors.warning[500] }}>
        There maybe up to one minute delay for authorized payments to show up, but notification from Payment Methods tab
        shows the status immediately after the authorization.
      </span>
      {paymentsToShow && paymentsToShow.length > 0
        ? paymentsToShow.map((pi, pi_index) => (
            <Card
              title={`Payment ${paymentsToShow.length - pi_index}`}
              key={pi.id}
              style={{ width: '100%', marginBottom: '10px', backgroundColor: theme.colors.primary[200] }}
            >
              <Typography.Text>
                <strong>Auth: </strong>
                <Typography.Text copyable>{pi.id}</Typography.Text>
              </Typography.Text>
              <p style={{ margin: '4px 0' }}>Amount: {pi.amount ? formatCents(pi.amount) : EMPTY_VALUE_DASHES}</p>
              <p style={{ margin: '4px 0' }}>Payment Method ID: {pi.paymentMethod}</p>
              <p style={{ margin: '4px 0' }}>Status: {pi.status}</p>
              <p style={{ margin: '4px 0' }}>Created: {pi.created?.substring(0, 19)}</p>
              <p style={{ margin: '4px 0' }}>
                Card: {pi.latestCharge?.paymentMethodDetails.card.brand}-
                {pi.latestCharge?.paymentMethodDetails.card.last4}
              </p>
              {pi.status === 'succeeded' && pi.latestCharge && <PaymentCharge paymentCharge={pi.latestCharge} />}
            </Card>
          ))
        : null}
    </>
  );
};

interface PaymentChargeProps {
  paymentCharge: ChargeDTO;
}

const PaymentCharge: React.FC<PaymentChargeProps> = ({ paymentCharge }) => {
  const { id, amountCaptured: amount, status, created, refunds } = paymentCharge;

  return (
    <Card
      style={{ width: '100%', marginBottom: '10px', backgroundColor: theme.colors.primary[100] }}
      bodyStyle={{ padding: '12px' }}
    >
      <Typography.Text>
        <strong>Capture: </strong>
        <Typography.Text copyable>{id}</Typography.Text>
      </Typography.Text>
      <p style={{ margin: '4px 0' }}>Amount: {amount ? formatCents(amount) : EMPTY_VALUE_DASHES}</p>
      <p style={{ margin: '4px 0' }}>Status: {status}</p>
      <p style={{ margin: '4px 0' }}>Created: {created}</p>
      {refunds && refunds.data.length > 0 && (
        <div title="Refunds">
          {refunds.data.map((refund) => (
            <PaymentRefund key={refund.id} paymentRefund={refund} />
          ))}
        </div>
      )}
    </Card>
  );
};

interface PaymentRefundProps {
  paymentRefund: RefundDTO;
}

const PaymentRefund: React.FC<PaymentRefundProps> = ({ paymentRefund }) => {
  const { id, amount, status, created } = paymentRefund;

  return (
    <Card style={{ width: '100%', marginBottom: '6px' }} bodyStyle={{ padding: '12px' }}>
      <Typography.Text>
        <strong>Refund: </strong>
        <Typography.Text copyable>{id}</Typography.Text>
      </Typography.Text>
      <p style={{ margin: '4px 0' }}>Amount: {amount ? formatCents(amount) : EMPTY_VALUE_DASHES}</p>
      <p style={{ margin: '4px 0' }}>Status: {status}</p>
      <p style={{ margin: '4px 0' }}>Created: {created}</p>
    </Card>
  );
};
