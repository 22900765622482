import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { ItemSubstitute } from '@recurrency/core-api-schema/dist/items/getItemSubstitutes';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { CenteredError } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { objGet } from 'utils/object';
import { routes } from 'utils/routes';
import { sortableBooleanColumn, sortableNumberColumn, sortableStringColumn } from 'utils/tables';

import { QuoteLineItemP21 } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import { QuoteLineItemP21WithInfo } from '../../quoteUtils';

export function SubstituteModal({
  lineItem,
  location,
  onCancel,
  onOk,
}: {
  lineItem: QuoteLineItemP21WithInfo;
  location: IdNameObj;
  onCancel: () => void;
  onOk: (item: QuoteLineItemP21) => void;
}) {
  const { data, error, isLoading } = useCoreApi(schemas.items.getItemSubstitutes, {
    pathParams: { itemId: lineItem.foreignId },
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<ItemSubstitute[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: ItemSubstitute[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const calculateQuantityAvailable = (record: ItemSubstitute) => {
    const availability = record.locationAvailabilities.filter(
      (locationAvailability) => locationAvailability.locationId === location.foreignId,
    )[0];
    return availability.qtyAvailable / record.unitOfMeasure.unitSize;
  };

  const columns: ColumnType<FIXME>[] = [
    sortableStringColumn({
      title: 'ID',
      dataIndex: 'itemId',
      render: (id: string) => <Link to={routes.sales.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: 'itemDesc',
    }),
    sortableBooleanColumn({
      title: 'Interchangeable',
      dataIndex: 'interchangeable',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a: Obj, b: Obj) => (objGet(a, 'interchangeable') ? 1 : 0) - (objGet(b, 'interchangeable') ? 1 : 0),
        multiple: 2,
      },
    }),
    sortableNumberColumn({
      title: 'Location Stock',
      render: calculateQuantityAvailable,
      defaultSortOrder: 'descend',
      sorter: { compare: (a, b): number => calculateQuantityAvailable(a) - calculateQuantityAvailable(b), multiple: 1 },
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: ['unitOfMeasure', 'name'],
    }),
  ];

  if (error) {
    return (
      <Modal visible onCancel={onCancel}>
        <CenteredError error={error} />
      </Modal>
    );
  }

  return (
    <Modal
      visible
      title={`Substitute for Item #${lineItem.foreignId}`}
      onOk={onCancel}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={!selectedRows.length}
            onClick={() =>
              onOk({
                foreignId: selectedRows[0].itemId,
                name: selectedRows[0].itemDesc,
                // TODO: this should use a normalized quantity based on the line items selected unit size
                quantity: lineItem.quantity,
              })
            }
            type="primary"
          >
            Substitute
          </Button>
        </>
      }
      width={900}
    >
      <Table
        columns={columns}
        data={data?.items ?? []}
        rowKey="itemId"
        isLoading={isLoading}
        rowSelection={{ type: 'radio', ...rowSelection }}
      />
    </Modal>
  );
}
