import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { MiniTable } from 'components/MiniTable';

import { useCoreApi } from 'hooks/useApi';

import { formatNumber } from 'utils/formatting';

export function UniqueCustomersOrdersPanelContent({ itemId, locationId }: { itemId: string; locationId: string }) {
  const { data, error, isLoading } = useCoreApi(schemas.inventory.getInventoryDashboardStatusItems, {
    queryParams: {
      filters: {
        locationIds: [locationId],
        itemIds: [itemId],
      },
      fields: [
        'itemCode',
        'locationId',
        'uniqueCustomers3M',
        'uniqueCustomers6M',
        'uniqueCustomers12M',
        'uniqueOrders3M',
        'uniqueOrders6M',
        'uniqueOrders12M',
      ],
    },
  });
  const item = data?.items?.[0];

  const tableData = item
    ? [
        {
          name: 'Unique Customers (3M)',
          value: formatNumber(item.uniqueCustomers3M || 0),
        },
        {
          name: 'Unique Customers (6M)',
          value: formatNumber(item.uniqueCustomers6M || 0),
        },
        {
          name: 'Unique Customers (12M)',
          value: formatNumber(item.uniqueCustomers12M || 0),
        },
        {
          name: 'Unique Hits (3M)',
          value: formatNumber(item.uniqueOrders3M || 0),
        },
        {
          name: 'Unique Hits (6M)',
          value: formatNumber(item.uniqueOrders6M || 0),
        },
        {
          name: 'Unique Hits (12M)',
          value: formatNumber(item.uniqueOrders12M || 0),
        },
      ]
    : [];

  return (
    <>
      <MiniTable
        data={tableData}
        columns={[
          { render: (record) => record.name, align: `left` },
          { render: (record) => record.value, align: `right` },
        ]}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
}
