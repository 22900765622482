import { useState } from 'react';

import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { fuzzyFilter } from 'fuzzbunny';

import { usePromise } from 'hooks/usePromise';

import { splitIdNameStr } from 'utils/formatting';
import { searchIndexForFacetValues } from 'utils/search/search';
import { FacetValues, SearchRequest } from 'utils/search/types';

import { highlightedLabel, MultiSelectOption, UseAsyncMultiSelectProps } from './types';
import { UseAsyncSelectProps } from './useAsyncSelectProps';

/**
 * useAsyncSelectProps has value = `{id}: {name}` but we want value = `{id}` and label = `{id}: {name}`
 * `{id}: name` values were a hack for Q/O entry, and we don't want to further propagate that pattern
 */
export function convertToMultiSelectProps(
  selectProps: UseAsyncSelectProps,
  { valueKey = 'foreignId' }: { valueKey?: 'foreignId' | 'uid' } = {},
): UseAsyncMultiSelectProps {
  return {
    options: selectProps.options
      .filter((opt) => !opt.disabled)
      .map(({ uid, value }) => {
        const optionValue =
          valueKey === 'uid' && uid ? uid : valueKey === 'foreignId' ? splitIdNameStr(value).foreignId : value;

        return {
          label: value,
          value: optionValue,
        };
      }),
    totalCount: selectProps.totalCount,
    isLoading: selectProps.isLoading,
    searchQuery: selectProps.searchQuery,
    setSearchQuery: selectProps.setSearchQuery,
  };
}

export interface PillFilterOption extends Omit<MultiSelectOption, 'value'> {
  value: string | string[];
}

export function getSearchFacetMultiSelectOptions({
  facetField,
  facetValues,
  searchRequest,
  labelFormatFn,
  sortBy,
  staticOptions,
}: {
  facetField: string;
  facetValues: FacetValues;
  searchRequest: SearchRequest;
  labelFormatFn?: (value: string) => string;
  sortBy?: 'alpha' | 'count' | 'none';
  staticOptions?: MultiSelectOption[];
}): () => UseAsyncMultiSelectProps {
  return function useSearchIndexFacetOptions() {
    const [searchQuery, setSearchQuery] = useState('');

    // options are inferred if static options not provided
    const options = staticOptions
      ? staticOptions.map((option) => ({
          label: option.label,
          value: option.value,
          // update counts for static options
          count: facetValues.find((fv) => fv.value === option.value)?.count || 0,
        }))
      : facetValues.map((facetValue) => ({
          label: labelFormatFn ? labelFormatFn(facetValue.value) : facetValue.value,
          value: facetValue.value,
          count: facetValue.count,
        }));

    // if searchQuery changes, search for matching options
    const { data: filteredOptions = [] } = usePromise(
      () =>
        searchQuery && !staticOptions
          ? searchIndexForFacetValues(facetField, searchQuery, searchRequest).then((resp) =>
              resp.map(({ value, count }) => ({
                label: labelFormatFn ? labelFormatFn(value) : value,
                value,
                count,
              })),
            )
          : Promise.resolve(options),
      [searchQuery, options],
    );

    if (sortBy !== 'none') {
      filteredOptions.sort(sortBy === 'alpha' ? (a, b) => a.value.localeCompare(b.value) : (a, b) => b.count - a.count);
    }
    const filteredAndHighlightedOptions = fuzzyFilterAndHighlightOptions(filteredOptions, searchQuery);

    return {
      options: filteredAndHighlightedOptions,
      // TODO: ideally we should return the total count of the facet values, but we don't currently return that from the API
      totalCount: filteredAndHighlightedOptions.length,
      searchQuery,
      setSearchQuery,
    };
  };
}

export function getPillFacetOptions({
  facetValues,
  labelFormatFn,
  staticOptions,
}: {
  facetValues: FacetValues;
  labelFormatFn?: (value: string) => string;
  staticOptions?: PillFilterOption[];
}) {
  return function useSearchIndexFacetOptions() {
    // options are inferred if static options not provided
    const options = staticOptions
      ? staticOptions.map((option) => {
          const optionsArray = Array.isArray(option.value) ? option.value : [option.value];
          const count = facetValues
            .filter((v) => optionsArray.includes(v.value))
            .reduce((total, curr) => total + curr.count, 0);

          return {
            label: option.label,
            value: option.value,
            // update counts for static options
            count,
          };
        })
      : facetValues.map((facetValue) => ({
          label: labelFormatFn ? labelFormatFn(facetValue.value) : facetValue.value,
          value: facetValue.value,
          count: facetValue.count,
        }));

    return {
      options,
      totalCount: options.length,
    };
  };
}

export function useTenantSlugSelectProps(tenants: TenantDTO[]): UseAsyncMultiSelectProps {
  const [searchQuery, setSearchQuery] = useState('');
  const options = tenants.map((tenant) => ({
    label: tenant.name,
    value: tenant.slug,
  }));

  return {
    searchQuery,
    setSearchQuery,
    options: fuzzyFilterAndHighlightOptions(options, searchQuery),
  };
}

export function fuzzyFilterAndHighlightOptions(options: MultiSelectOption[], searchQuery: string): MultiSelectOption[] {
  if (!searchQuery) return options;

  const filteredOptions = fuzzyFilter(options, searchQuery, { fields: ['label', 'value'] });
  return filteredOptions.map((option) => ({
    label: option.highlights.label ? highlightedLabel(option.highlights.label) : option.item.label,
    value: option.item.value,
    count: option.item.count,
  }));
}
