import React, { CSSProperties } from 'react';

import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';
import { css, cx } from '@emotion/css';
import { theme } from 'theme';

import { Tooltip } from 'components/Tooltip/Tooltip';

export const WarningIconTooltip = ({ tooltipText, wrapText = false }: { tooltipText: string; wrapText?: boolean }) => {
  const whiteSpaceWrapper: CSSProperties = { whiteSpace: wrapText ? 'normal' : 'nowrap' };
  return (
    <span
      className={cx(
        `WarningIconContainer`,
        css`
          margin-left: 6px;
          font-weight: bold;
        `,
      )}
    >
      <Tooltip
        title={tooltipText}
        className={css`
          cursor: help;
        `}
        overlayInnerStyle={whiteSpaceWrapper}
      >
        <WarningOutlined style={{ color: theme.colors.warning[500], marginRight: 8 }} />
      </Tooltip>
    </span>
  );
};
