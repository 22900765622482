import type { ButtonHTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import type { CSSObject } from '@emotion/css';
import { css, cx } from '@emotion/css';
import { theme } from 'theme';

export type TagSize = 'small' | 'regular';

export interface TagProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * `closable` prop turns Tag into a button that accepts additional `onClose` callback.
   */
  closable?: boolean;
  label: string | React.ReactNode;
  size?: TagSize;
  /**
   * Callback called when closable Tag is clicked.
   * When `closable` prop is not `true` `onClose` should be undefined.
   */
  onClose?: () => void;
}

export const Tag = forwardRef<HTMLButtonElement, TagProps>(({ closable = false, onClose, ...props }, ref) =>
  closable ? (
    <ClosableTag ref={ref} closable={closable} onClose={onClose} {...props} />
  ) : (
    <NotClosableTag ref={ref} closable={closable} {...props} />
  ),
);

const NotClosableTag = forwardRef<HTMLSpanElement, TagProps>(
  ({ label, size = 'regular', disabled, className, ...props }, ref) => (
    <span
      ref={ref}
      className={cx(
        tagStyles,
        css`
          ${rootSizes[size]};
        `,
        {
          [disabledStyles]: disabled,
        },
        className,
      )}
      {...props}
    >
      {label}
    </span>
  ),
);

const ClosableTag = forwardRef<HTMLButtonElement, TagProps>(
  ({ label, size = 'regular', onClose, className, ...props }, ref) => (
    <button
      type="button"
      ref={ref}
      onClick={onClose}
      className={cx(
        tagStyles,
        css`
          cursor: pointer;
          ${rootSizes[size]};

          :focus {
            outline: 4px solid ${theme.colors.neutral[200]};
          }
          :hover {
            background-color: ${theme.colors.neutral[200]};
            border-color: ${theme.colors.primary[500]};
          }
          :active {
            background-color: ${theme.colors.neutral[300]};
            border-color: ${theme.colors.primary[400]};
          }
          :disabled {
            ${disabledStyles}
            cursor: auto;
          }
        `,
        className,
      )}
      {...props}
    >
      {label}
      <CloseOutlined />
    </button>
  ),
);

const tagStyles = css`
  ${theme.typography.pSmall};
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  color: ${theme.colors.primary[800]};
  background-color: ${theme.colors.neutral[100]};
  border: 1px solid ${theme.colors.primary[300]};
`;

const disabledStyles = css`
  color: ${theme.colors.neutral[400]};
  background-color: ${theme.colors.neutral[100]};
  border: 1px solid ${theme.colors.neutral[300]};
`;

const rootSizes: { [key in TagSize]: CSSObject } = {
  small: {
    padding: '4px 8px',
    gap: '4px',
  },
  regular: {
    padding: '8px 12px',
    gap: '8px',
  },
};
