import React from 'react';

import { calcLine, calcOp, calcProp, Equation, noteLine } from 'components/recipes/equation/Equation';
import { EquationPopover } from 'components/recipes/equation/EquationPopover';

import { formatNumber } from 'utils/formatting';

import { QtyToOrderAlignmentNote } from './QtyToOrderAlignmentNote';
import { QtyToOrderEquationRecord } from './types';

export const MinMaxQtyToOrderEquation = ({
  record,
  title,
  resultProperty,
}: {
  record: QtyToOrderEquationRecord;
  title: string;
  resultProperty: string;
}) => (
  <EquationPopover title={title}>
    <Equation
      lines={[
        record.qtyToOrderUnaligned > 0
          ? [
              calcLine(
                calcProp(
                  resultProperty,
                  `${formatNumber(record.qtyToOrder)} ${record.unitOfMeasure || ''}${
                    record.alignNeeded
                      ? ` (${formatNumber(
                          (record.qtyToOrder * (record.unitSize ?? 1)) / (record.qtyToOrderAlignUnitSize ?? 1),
                        )} ${record.qtyToOrderAlignUnitOfMeasure})*`
                      : ''
                  }`,

                  record.alignNeeded ? <QtyToOrderAlignmentNote record={record} /> : undefined,
                ),
                calcOp('='),
                record.stockable
                  ? [
                      calcProp('Current Maximum', `${formatNumber(record.currentMax)} ${record.unitOfMeasure || ''}`),
                      calcOp('-'),
                      calcProp('Net Stock', `${formatNumber(record.netStock)} ${record.unitOfMeasure || ''}`),
                    ]
                  : calcProp('Qty Backordered', `${formatNumber(record.qtyBackordered)} ${record.unitOfMeasure || ''}`),
              ),
            ]
          : record.isStatusNormal && record.currentMax > record.netStock
          ? [
              calcLine(
                calcProp('Current Minimum', `${formatNumber(record.currentMin)} ${record.unitOfMeasure}`),
                calcOp('≤'),
                calcProp('Net Stock', `${formatNumber(record.netStock)} ${record.unitOfMeasure}`),
                calcOp('<'),
                calcProp('Current Maximum', `${formatNumber(record.currentMax)} ${record.unitOfMeasure}`),
              ),
              noteLine(
                'No inventory needs to be purchased as the current net stock falls in between recommended Minimum and Maximum.',
              ),
            ]
          : [
              calcLine(
                calcProp('Net Stock', `${formatNumber(record.netStock)} ${record.unitOfMeasure || ''}`),
                calcOp('≥'),
                calcProp('Current Maximum', `${formatNumber(record.currentMax)} ${record.unitOfMeasure || ''}`),
              ),
              noteLine(
                'No inventory needs to be purchased as the current net stock is greater than the Current Maximum.',
              ),
            ],
      ]}
    />
  </EquationPopover>
);
