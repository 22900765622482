import React, { useState } from 'react';

import { Button } from '.';
import { ButtonProps } from './Button.style';

/** shows loading state while onClick promise is being resolved  */
export function AsyncButton({ onClick, ...otherProps }: ButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const handleOnClick: ButtonProps['onClick'] = async (ev) => {
    if (onClick) {
      try {
        setIsLoading(true);
        await onClick(ev);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return <Button onClick={handleOnClick} loading={isLoading} {...otherProps} />;
}
