import React from 'react';

import { css } from '@emotion/css';

import { Button } from 'components/Button';

import { pluralize } from 'utils/formatting';

// foreignId for draft quotes is just the Recurrency id for the draft
type SelectionProps = {
  selection: any[];
  onClear?: () => void;
};

export const SelectionCountContext = (props: SelectionProps) => {
  const { selection, onClear } = props;
  return (
    <div
      className={css`
        align-self: center;
      `}
    >
      <b>{selection.length}</b> {`${pluralize(selection.length ?? 0, 'item')} selected `}
      {selection.length && onClear ? (
        <>
          <Button
            type="link"
            onClick={onClear}
            className={css`
              padding: 0;
              margin-left: 16px;
            `}
          >
            Clear
          </Button>
        </>
      ) : undefined}
    </div>
  );
};
