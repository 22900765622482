import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { ErpOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { PurchaseOrderDTO } from '@recurrency/core-api-schema/dist/v2/purchaseOrders/index';
import { Radio } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { AsyncSelect, AsyncSelectVariant, tableFilterSelectClassName } from 'components/AsyncSelect';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { ButtonLink } from 'components/Links';
import { RadioGroup } from 'components/Radio';
import { ResultCount } from 'components/ResultCount';

import { optArrFromVal } from 'utils/array';
import { truthy } from 'utils/boolean';
import { splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  sortableNumberColumn,
  sortableDateColumn,
  sortableDollarColumn,
  sortableIdColumn,
  erpStatusColumn,
  asKeyOf,
} from 'utils/tables';

export const PurchaseOrdersTable = ({
  companyId,
  vendorId,
  locationId,
}: {
  companyId?: string;
  vendorId: string;
  locationId?: string;
}) => {
  const columns: ColumnType<PurchaseOrderDTO>[] = [
    sortableIdColumn({
      title: 'PO No',
      dataIndex: asKeyOf<PurchaseOrderDTO>('purchaseOrderNumber'),
      render: (purchaseOrderNumber: string) => (
        <Link to={routes.purchasing.purchaseOrderDetails(purchaseOrderNumber)}>{purchaseOrderNumber}</Link>
      ),
      sorter: true,
    }),
    erpStatusColumn(),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: asKeyOf<PurchaseOrderDTO>('orderDate'),
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableDateColumn({
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: true,
    }),
    sortableIdColumn({
      title: 'Location',
      dataIndex: asKeyOf<PurchaseOrderDTO>('locationId'),
      render: (_, record: PurchaseOrderDTO) =>
        record.locationId ? `${record.locationId}: ${record.locationName}` : '-',
      sorter: true,
    }),
    sortableDollarColumn({
      title: 'Total Amount',
      dataIndex: asKeyOf<PurchaseOrderDTO>('totalAmount'),
      sorter: true,
    }),
    sortableNumberColumn({
      title: 'Line Count',
      dataIndex: asKeyOf<PurchaseOrderDTO>('lineCount'),
      sorter: true,
    }),
    {
      dataIndex: asKeyOf<PurchaseOrderDTO>('purchaseOrderNumber'),
      render: (purchaseOrderNumber: string) => ButtonLink(routes.purchasing.purchaseOrderDetails(purchaseOrderNumber)),
    },
  ].filter(truthy);

  const locationsSelectProps = useLocationsSelectProps({});
  const [status, setStatus] = useState<ErpOrderStatus>();
  const [filteredLocationId, setFilteredLocationId] = useState<string | undefined>(locationId);

  const tableProps = useCoreApiTableProps({
    schema: schemas.purchaseOrders.getPurchaseOrdersV2,
    queryParams: {
      filter: {
        status,
        companyIds: optArrFromVal(companyId),
        vendorId,
        locationIds: optArrFromVal(filteredLocationId),
      },
    },
  });

  const { setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredLocationId, status, setPage]);

  return (
    <>
      <FilterBarBox>
        <RadioGroup value={status} onChange={({ target: { value } }) => setStatus(value)}>
          <Radio.Button value={undefined}>All</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Open}>Open</Radio.Button>
          <Radio.Button value={ErpOrderStatus.Completed}>Completed</Radio.Button>
        </RadioGroup>
        {!locationId && (
          <AsyncSelect
            className={tableFilterSelectClassName}
            selectProps={locationsSelectProps}
            entityPlural="locations"
            variant={AsyncSelectVariant.Filter}
            onSelect={(_, option) => {
              setFilteredLocationId(splitIdNameStr(option.value).foreignId);
            }}
            onClear={() => setFilteredLocationId(undefined)}
            allowClear
            size="small"
          />
        )}
        <FlexSpacer />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>
      <AsyncTable tableProps={tableProps} columns={columns} />
    </>
  );
};
