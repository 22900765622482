import React, { useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { Button, Form, Modal, notification } from 'antd';
import moment, { Moment } from 'moment';

import { Alert } from 'components/Alert';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { FlexSpace } from 'components/FlexSpace';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';

export type changeSyncDateType = 'Modified' | 'Updated';

export interface SyncDatesData {
  dateType: changeSyncDateType;
  initialDate: string;
  tenantId: string;
  tableName: string;
  dbType: string;
  onDataChanged: () => void;
}

export interface EditSyncDatesModalProps {
  SyncDatesData: SyncDatesData;
  onClose: () => void;
}

export const EditSyncDatesModal = ({ SyncDatesData, onClose }: EditSyncDatesModalProps) => {
  const { dateType, initialDate, tenantId, tableName, dbType, onDataChanged } = SyncDatesData;

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Range of selectable dates for the calendar
  const disabledDates = (date: Moment) => {
    const sevenYearsAgo = moment().subtract(7, 'years');
    return date.isBefore(sevenYearsAgo) || date.isAfter(moment());
  };

  const onSaveDate = async (values: { date: any }) => {
    try {
      setIsSubmitting(true);

      const bodyParams =
        dateType === 'Modified'
          ? {
              tableName,
              dbType,
              lastModified: values.date,
            }
          : {
              tableName,
              dbType,
              updatedAt: values.date,
            };

      await coreApiFetch(schemas.integrations.updateTenantTableSyncDates, {
        pathParams: { tenantId },
        bodyParams,
      });

      notification.success({
        message: 'Changes saved successfully.',
      });
      onDataChanged();
      onClose();
    } catch (err) {
      captureAndShowError(err, 'Error saving changes. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      visible
      title="Edit Date"
      onCancel={onClose}
      footer={
        <div>
          <Button onClick={onClose}>Cancel</Button>
          <Button key="submit" type="primary" form="form" htmlType="submit" loading={isSubmitting}>
            Save
          </Button>
        </div>
      }
      centered
    >
      <FlexSpace gap={20} direction="column">
        {dateType === 'Modified' && (
          <Alert
            icon={<ExclamationCircleOutlined />}
            message="This will change the 'date_last_modified' field in public.date_last_sync_info, causing sync changes for Postgres and Snowflake."
            type="info"
            banner
          />
        )}
        {dateType === 'Updated' && (
          <Alert
            icon={<ExclamationCircleOutlined />}
            message="This will change the 'updated_at' field in public.date_last_sync_info, causing sync changes for Snowflake and Typesense."
            type="info"
            banner
          />
        )}

        <Form name="form" initialValues={{ date: initialDate }} onFinish={onSaveDate} layout="vertical">
          <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Date is required' }]}>
            <IsoDatePicker showTime disabledDates={disabledDates} />
          </Form.Item>
        </Form>
      </FlexSpace>
    </Modal>
  );
};
