import { IntegratedErps, TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { TenantConnectionStatusDetailsDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';

import { capitalize } from 'utils/formatting';

export interface ConnectionStatus {
  connection: string;
  status: 'available' | 'unavailable';
  error?: string;
}

export function getConnectionStatuses(
  tenant: TenantDTO,
  connectionStatusDetails: TenantConnectionStatusDetailsDTO,
): ConnectionStatus[] {
  const result: ConnectionStatus[] = [];

  if (connectionStatusDetails.connectionStatus) {
    result.push({
      connection: capitalize(connectionStatusDetails.connectionType || 'Error'),
      status: connectionStatusDetails.connectionStatus.status as ConnectionStatus['status'],
      error: connectionStatusDetails.connectionStatus.error,
    });
  }

  if (connectionStatusDetails.directSql) {
    result.push({
      connection: 'Direct SQL',
      status: connectionStatusDetails.directSql.status as ConnectionStatus['status'],
      error: connectionStatusDetails.directSql.error,
    });
  }

  if (tenant.erpType === IntegratedErps.P21) {
    if (
      connectionStatusDetails.transactionApi &&
      (tenant.featureFlags[TenantFeatureFlag.OrdersExportUsingTransactionApi] ||
        tenant.featureFlags[TenantFeatureFlag.PurchaseOrdersExportUsingTransactionApi] ||
        tenant.featureFlags[TenantFeatureFlag.ItemLocationMinMaxExportUsingTransactionApi])
    ) {
      result.push({
        connection: 'Transaction API',
        status: connectionStatusDetails.transactionApi.status as ConnectionStatus['status'],
        error: connectionStatusDetails.transactionApi.error,
      });
    }

    if (connectionStatusDetails.sism) {
      result.push({
        connection: 'SISM',
        status: connectionStatusDetails.sism.status as ConnectionStatus['status'],
        error: connectionStatusDetails.sism.error,
      });
    }
  } else if (tenant.erpType === IntegratedErps.SAPB1) {
    if (connectionStatusDetails.serviceLayer) {
      result.push({
        connection: 'Service Layer API',
        status: connectionStatusDetails.serviceLayer.status as ConnectionStatus['status'],
        error: connectionStatusDetails.serviceLayer.error,
      });
    }
  }
  return result;
}
