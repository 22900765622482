import React from 'react';

import { ClockCircleOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import moment from 'moment';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

export enum LastUpdatedWithFrequencyEntity {
  Forecast = 'forecast',
  SalesReport = 'salesReport',
}

// This is to prevent the concept -> frequency mapping from existing multiple places
const updateFrequencies: Record<LastUpdatedWithFrequencyEntity, string> = {
  [LastUpdatedWithFrequencyEntity.Forecast]: 'every weekend and on the first of every month',
  [LastUpdatedWithFrequencyEntity.SalesReport]: 'daily',
};

export const LastUpdatedMomentForecast = ({ includeForecastPrefix = false }: { includeForecastPrefix?: boolean }) => {
  const { data: lastUpdatedData } = useCoreApi(schemas.ml.getForecastLastRunDate, {
    queryParams: { indexName: SearchIndexName.Forecasts },
  });

  return (
    <LastUpdatedWithFrequency
      recordName={includeForecastPrefix ? 'Forecast' : undefined}
      dateString={lastUpdatedData?.date}
      lastUpdateEntity={LastUpdatedWithFrequencyEntity.Forecast}
    />
  );
};

export const LastUpdatedWithFrequency = ({
  recordName,
  dateString,
  lastUpdateEntity,
}: {
  recordName?: string;
  dateString: string | null | undefined;
  lastUpdateEntity: LastUpdatedWithFrequencyEntity;
}) => (
  <Typography style={{ color: theme.colors.neutral[400] }} type="subtitle">
    {`${recordName ? `${recordName} last` : 'Last'} updated ${
      dateString ? moment(dateString).fromNow() : '..'
    }. Updates ${updateFrequencies[lastUpdateEntity]}.`}
  </Typography>
);

export const LastUpdated = ({
  entityName,
  dateString,
  className,
  showIcon = false,
}: {
  entityName: string;
  dateString: string | null | undefined;
  className?: string;
  showIcon?: boolean;
}) =>
  dateString ? (
    <FlexSpace
      className={cx(
        css`
          gap: 4px;
        `,
        className,
      )}
    >
      {showIcon && <ClockCircleOutlined style={{ color: theme.colors.neutral[400] }} />}
      <Typography style={{ color: theme.colors.neutral[400] }} type="subtitle">
        {`${entityName} last updated ${moment(dateString).fromNow()}`}
      </Typography>
    </FlexSpace>
  ) : null;
