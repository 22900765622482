import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { DocumentsTableAgnostic } from 'components/recipes/DocumentsTableAgnostic';

// Request Param: purchaseOrderId referrs to the purchaseOrderNumber until updated.
export const PurchaseOrderDocumentsTable = ({ purchaseOrderNumber }: { purchaseOrderNumber: string }) => (
  <DocumentsTableAgnostic
    tableProps={useCoreApiTableProps({
      schema: schemas.purchaseOrders.getPurchaseOrderDocumentsV2,
      pathParams: { purchaseOrderId: purchaseOrderNumber },
    })}
  />
);
