import React from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { SalesRepDTO } from '@recurrency/core-api-schema/dist/salesReps/getSalesReps';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { sortableIdColumn, sortableStringColumn } from 'utils/tables';

export const SalesRepsTable = ({ customerId, companyId }: { customerId: string; companyId?: string }) => {
  const columns: ColumnType<SalesRepDTO>[] = [
    sortableIdColumn({
      title: 'Sales Rep ID',
      dataIndex: 'salesRepId',
    }),
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'name',
    }),
    sortableStringColumn({
      title: 'Email',
      dataIndex: 'email',
    }),
    sortableStringColumn({
      title: 'Phone Number',
      dataIndex: 'phone',
    }),
  ];

  return (
    <AsyncTable
      tableProps={useCoreApiTableProps({
        schema: schemas.salesReps.getSalesReps,
        queryParams: { filter: { customerId, companyId } },
      })}
      columns={columns}
    />
  );
};
