import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { SavedViewDTO } from '@recurrency/core-api-schema/dist/savedViews/getSavedView';
import { UpdatePartialSavedViewBody } from '@recurrency/core-api-schema/dist/savedViews/patchUpdatePartialSavedView';

import { Input } from 'components/Input';
import { Modal } from 'components/Modal';

export enum ReportModalType {
  Closed = 'closed',
  New = 'new',
  Rename = 'rename',
}

export function ReportModal({
  type,
  onCancel,
  onUpdateReport,
  onCreateReport,
  report,
}: {
  type: ReportModalType;
  onCancel: () => void;
  onUpdateReport: (updates: UpdatePartialSavedViewBody) => void;
  onCreateReport: (name: string) => void;
  report?: SavedViewDTO;
}) {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Load existing name if report already exists, clear name when modal is reopened
  useEffect(() => {
    setName(type === ReportModalType.Rename && report?.name ? report.name : '');
  }, [type, report?.name]);

  const onOk = async () => {
    setIsLoading(true);
    if (type === ReportModalType.New) {
      await onCreateReport(name);
    } else if (type === ReportModalType.Rename) {
      await onUpdateReport({ name });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      visible={type !== ReportModalType.Closed}
      destroyOnClose
      title={type === ReportModalType.New ? 'New Report' : 'Rename Report'}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{ disabled: name.length === 0 }}
      confirmLoading={isLoading}
      width={600}
    >
      <div
        className={css`
          margin-bottom: 20px;
        `}
      >
        {type === ReportModalType.New ? 'Report Name:' : `Rename report "${report?.name}" to:`}
      </div>
      <Input autoFocus value={name} onChange={(ev) => setName(ev.target.value)} />
    </Modal>
  );
}
