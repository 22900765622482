/* eslint-disable no-console */
import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TenantDTO, TenantMetadataDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Form, notification } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { InputFormItem, RadioFormItem, responsiveFormLayout2 } from 'components/FormItems';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { objRemoveNilValues } from 'utils/object';

export function TenantMetadataTable({ tenant }: { tenant: TenantDTO }) {
  const [metadataForm] = Form.useForm();
  const tableRowsData: Array<{ label: string; value: string | undefined }> = [
    { label: 'Legacy Id', value: tenant?.metadata?.legacyId },
    { label: 'Default Company Id', value: tenant?.metadata?.companyId },
    { label: 'Region', value: tenant?.metadata?.region },
    { label: 'Exporter Url', value: tenant?.metadata?.exporterUrl },
    { label: 'SSO Connection Name', value: tenant?.metadata?.ssoConnectionName },
    { label: 'Stripe Connected Account ID', value: tenant?.metadata?.stripeConnectedAccountId },
  ];

  type MetadataLabelValuePairs = {
    title: 'Name' | 'Value';
    dataIndex: 'label' | 'value';
  };
  const columns: ColumnType<MetadataLabelValuePairs>[] = [
    {
      title: 'Name',
      dataIndex: 'label',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeMetadataModal = () => {
    setIsModalOpen(false);
    // metadataForm.resetFields();
  };
  const handleSetMetadataButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleMetadataFormSubmit = async (data: TenantMetadataDTO) => {
    try {
      if (!tenant.id) {
        throw new Error('No tenant ID found');
      }
      tenant.metadata = data;
      // strip-undefined
      const payload = objRemoveNilValues(data);
      await coreApiFetch(schemas.tenants.putUpdateTenantMetadata, {
        bodyParams: { metadata: payload },
        pathParams: { tenantId: tenant.id },
      });
      closeMetadataModal();
      notification.success({ message: 'Updated metadata.' });
    } catch (err) {
      captureAndShowError(err, `Error while updating tenant metadata`);
    }
  };

  const regions = [
    { label: 'East', value: 'us-east' },
    { label: 'West', value: 'us-west' },
  ];

  return (
    <div>
      <FilterBarBox>
        <FlexSpacer />
        <Button type="primary" onClick={handleSetMetadataButtonClick}>
          <EditOutlined />
          Edit Metadata
        </Button>
      </FilterBarBox>
      <Table columns={columns} data={tableRowsData} rowKey="metadataKey" />
      <Modal
        visible={isModalOpen}
        title="Manage Metadata"
        onCancel={closeMetadataModal}
        footer={
          <div>
            <Button onClick={closeMetadataModal}>Cancel</Button>
            <Button type="primary" onClick={metadataForm.submit} disabled={!tenant.id}>
              Save Changes
            </Button>
          </div>
        }
        centered
      >
        <Form
          {...responsiveFormLayout2}
          form={metadataForm}
          initialValues={tenant?.metadata}
          onFinish={handleMetadataFormSubmit}
        >
          <InputFormItem name="legacyId" label="Legacy Id" />
          <InputFormItem name="companyId" label="Default Company Id" />
          <RadioFormItem
            label="Region"
            name="region"
            values={regions}
            rules={[{ required: true, message: 'Please select an region.' }]}
            onChange={() => {}}
          />
          <InputFormItem name="exporterUrl" label="Exporter URL" />
          <InputFormItem name="ssoConnectionName" label="SSO Connection Name" />
          <InputFormItem name="stripeConnectedAccountId" label="Stripe Connected Acct" />
        </Form>
      </Modal>
    </div>
  );
}
