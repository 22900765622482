import React from 'react';

import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { shouldShowSearchIndex } from 'utils/roleAndTenant';

import { AsyncSelect, AsyncSelectProps } from './AsyncSelect';
import {
  CustomersSelectParams,
  ItemsSelectParams,
  SalesOrdersSelectParams,
  ShipTosSelectParams,
  VendorsSelectParams,
  useCustomersSelectProps,
  useItemsSelectProps,
  useSalesOrdersSelectProps,
  useShipTosSelectProps,
  useVendorsSelectProps,
} from './useAsyncSelectProps';

interface CustomerSelect {
  searchIndex: SearchIndexName.Customers;
  selectParams?: CustomersSelectParams;
}

interface VendorsSelect {
  searchIndex: SearchIndexName.Vendors;
  selectParams?: VendorsSelectParams;
}

interface ItemsSelect {
  searchIndex: SearchIndexName.Items;
  selectParams?: ItemsSelectParams;
}

interface SalesOrdersSelect {
  searchIndex: SearchIndexName.SalesOrders;
  selectParams?: SalesOrdersSelectParams;
}

interface ShipTosSelect {
  searchIndex: SearchIndexName.ShipTos;
  selectParams?: ShipTosSelectParams;
}

type SearchParams = CustomerSelect | VendorsSelect | ItemsSelect | SalesOrdersSelect | ShipTosSelect;

interface SearchSelectProps extends Omit<AsyncSelectProps, 'selectProps'> {
  searchParams: SearchParams;
}

export function SearchSelect(props: SearchSelectProps) {
  const { searchParams } = props;
  const { activeTenant } = useGlobalApp();

  return shouldShowSearchIndex(activeTenant, searchParams.searchIndex) ? <SearchSelectInternal {...props} /> : null;
}

function SearchSelectInternal({ searchParams, ...otherProps }: SearchSelectProps) {
  let useSelectProps;
  switch (searchParams.searchIndex) {
    case SearchIndexName.Customers:
      useSelectProps = () => useCustomersSelectProps(searchParams.selectParams || {});
      break;
    case SearchIndexName.Vendors:
      useSelectProps = () => useVendorsSelectProps(searchParams.selectParams || {});
      break;
    case SearchIndexName.Items:
      useSelectProps = () => useItemsSelectProps(searchParams.selectParams || {});
      break;
    case SearchIndexName.SalesOrders:
      useSelectProps = () => useSalesOrdersSelectProps(searchParams.selectParams || {});
      break;
    case SearchIndexName.ShipTos:
      useSelectProps = () => useShipTosSelectProps(searchParams.selectParams || {});
      break;
    default:
      throw new Error(`SearchSelect: searchIndex not supported`);
  }

  return <AsyncSelect selectProps={useSelectProps} {...otherProps} />;
}
