import React from 'react';

import { CheckCircleOutlined, EditOutlined, FlagOutlined, StopOutlined } from '@ant-design/icons';
import { ErpOrderStatus, P21LeadTimeSource, P21SafetyStockType } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';

import { ActionButton } from 'components/Button/ActionButton';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { FlexSpace } from 'components/FlexSpace';
import { FillRatePanelContent } from 'components/recipes/sidePane/FillRatePanelContent';
import { InventoryUsagePanelContent } from 'components/recipes/sidePane/InventoryUsagePanelContent';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { LocationAvailabilityPanelContent } from 'components/recipes/sidePane/LocationAvailabilityPanelContent';
import { MinMaxPanelContent } from 'components/recipes/sidePane/MinMaxPanelContent';
import { OrderPointOrderQuantityPanelContent } from 'components/recipes/sidePane/OrderPointOrderQuantityPanelContent';
import { PurchaseOrderLinesPanelContent } from 'components/recipes/sidePane/PurchaseOrderLinesPanelContent';
import { SalesInvoiceLinesPanelContent } from 'components/recipes/sidePane/SalesInvoiceLinesPanelContent';
import { SalesOrderLinesPanelContext } from 'components/recipes/sidePane/SalesOrderLinesPanelContext';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { UniqueCustomersOrdersPanelContent } from 'components/recipes/sidePane/UniqueCustomersOrdersPanelContent';
import { UnitOfMeasuresPanelContent } from 'components/recipes/sidePane/UnitOfMeasuresPanelContent';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { pluralize, splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { getTenantSetting, isHubAndSpokeEnabled, showCustomLookaheadFeatures } from 'utils/tenantSettings';

import { ItemNotesPanelContent } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/ItemNotesPanelContent';
import { ReplenishmentPathsPanelContent } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/ReplenishmentPathsPanelContent';
import {
  ScheduledReleaseStockPanelContent,
  ScheduledReleaseStockPanelRecord,
} from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/ScheduledReleaseStockPanelContent';
import { SupplierNotesPanelContent } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/SupplierNotesPanelContent';
import { shouldShowOpOqVariant, UsageSourceDisplayName } from '../utils';
import { changeRecordFlagStatus } from './modals/FlagItemStatusUpdater';
import { changeRecordExcludedStatus } from './modals/IncludeExcludeStatusUpdater';
import { MultiLeadTimeModal } from './modals/MultiLeadTimeModal';
import { MultiSafetyStockModal } from './modals/MultiSafetyStockModal';
import { PlanningSupplierLocationSettingsModal } from './modals/PlanningSupplierLocationSettingsModal';
import { formatSafetyStock } from './modals/utils';

export function PlanningSidePane({
  record,
  isOnboardingVariant = false,
  searchIndexReload,
}: {
  record: SearchPlanningDTO | null;
  isOnboardingVariant?: boolean;
  searchIndexReload: () => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const showOpOqVariant = shouldShowOpOqVariant(activeTenant, activeUser, record?.replenishment_method);
  const isPlanningHubAndSpoke = isHubAndSpokeEnabled();
  const isUsingCustomLookaheadNetStock = showCustomLookaheadFeatures();

  const supplierId = splitIfIdNameStr(record?.primary_supplier)?.foreignId ?? '';
  const itemId = record?.item_id ?? '';
  const location = splitIdNameStr(record?.location ?? ':');
  const vendor = splitIfIdNameStr(record?.primary_vendor);

  const calculationUseImportedUsage = getTenantSetting(TenantSettingKey.CalculationUseImportedUsage);
  const calculationUsageQuantitySource = getTenantSetting(TenantSettingKey.CalculationUsageQuantitySource);

  if (!record) {
    return (
      <SidePane
        title=""
        content={<div>{isOnboardingVariant ? 'No item selected' : 'Please select a Planning Table row'}</div>}
      />
    );
  }

  const itemInfo: ScheduledReleaseStockPanelRecord = {
    itemId: record.item_id,
    itemUid: record.item_uid,
    unitOfMeasure: record.unit_of_measure,
    locationId: location.foreignId,
  };

  const editableProps: Array<LabelValueProperty | null> = [
    {
      label: 'Lead Time',
      value: (
        <>
          <ConditionalWrapper
            condition={record.lead_time_source === P21LeadTimeSource.override}
            wrapper={(children: React.ReactChild) => (
              <InfoTooltip useInfoIcon title="Overridden">
                {children}
              </InfoTooltip>
            )}
          >
            <>{pluralize(record.lead_time, 'day', 'days', true)}</>
          </ConditionalWrapper>
          <>
            {` | `}
            <ActionButton
              dataTestId="edit-lead-time-button"
              label={
                <>
                  <EditOutlined /> Edit
                </>
              }
              onClick={() => {
                showAsyncModal(MultiLeadTimeModal, {
                  selection: { type: 'list', items: [record], count: 1 },
                  searchIndexReload,
                });
              }}
            />
          </>
        </>
      ),
    },
    {
      label: 'Safety Stock',
      value: (
        <>
          {record.safety_stock_value === undefined ? (
            '-'
          ) : (
            <ConditionalWrapper
              condition={record.safety_stock_type === P21SafetyStockType.overrideDays}
              wrapper={(children: React.ReactChild) => (
                <InfoTooltip useInfoIcon title="Overridden">
                  {children}
                </InfoTooltip>
              )}
            >
              <>{`${formatSafetyStock(record.safety_stock_value, record.safety_stock_type)}`}</>
            </ConditionalWrapper>
          )}
          <>
            {` | `}
            <ActionButton
              label={
                <>
                  <EditOutlined /> Edit
                </>
              }
              onClick={() => {
                showAsyncModal(MultiSafetyStockModal, {
                  selection: { type: 'list', items: [record], count: 1 },
                  searchIndexReload,
                });
              }}
            />
          </>
        </>
      ),
    },
    supplierId
      ? {
          label: 'Order Cycle',
          value: (
            <>
              <InfoTooltip
                useInfoIcon
                title="Order Cycle is defined at the supplier and location level and affects all items from this supplier-location pair"
              >
                {pluralize(Number(record.order_cycle_days), 'day', 'days', true)}
              </InfoTooltip>

              {` | `}
              <ActionButton
                label={
                  <>
                    <EditOutlined /> Edit
                  </>
                }
                onClick={() => {
                  showAsyncModal(PlanningSupplierLocationSettingsModal, {
                    supplierId,
                    locationId: location.foreignId,
                    searchIndexReload,
                  });
                }}
              />
            </>
          ),
        }
      : null,
  ];

  const replenishmentPanel = showOpOqVariant
    ? {
        settingKey: 'opOq',
        title: 'Order Point / Order Quantity',
        content: (
          <OrderPointOrderQuantityPanelContent minMaxRecord={record} allowOpOqEdit onOpOqChange={searchIndexReload} />
        ),
      }
    : {
        settingKey: 'minMax',
        title: 'Min / Max',
        content: <MinMaxPanelContent minMaxRecord={record} allowMinMaxEdit onMinMaxChange={searchIndexReload} />,
      };

  const infoProps: Array<LabelValueProperty | null> = [
    record.short_code ? { label: 'Short Code', value: record.short_code } : null,
    record.primary_supplier ? { label: 'Primary Supplier', value: record.primary_supplier } : null,
    record.supplier_part_id ? { label: 'Supplier Part ID', value: record.supplier_part_id } : null,
    !isOnboardingVariant && record.replenishment_method
      ? { label: 'ERP Replenishment Method', value: record.replenishment_method }
      : null,
  ];

  return (
    <SidePane
      dataTestId="side-pane"
      title={`Item #${record.item_id} at ${record.location}`}
      settingKey={ViewSettingKey.PlanningSidePane}
      description={record.item_name}
      extendedDescription={record.item_desc}
      content={
        <>
          <LabelValueProperties properties={editableProps} />
          <br />
          <LabelValueProperties properties={infoProps} />
          <>
            <br />
            <FlexSpace gap={6} direction="row">
              <ActionButton
                label={
                  record.flagged ? (
                    <>
                      <FlagOutlined /> Resolve Flag
                    </>
                  ) : (
                    <>
                      <FlagOutlined /> Flag
                    </>
                  )
                }
                onClick={async () => {
                  const didChange = await changeRecordFlagStatus(
                    { type: 'list', count: 1, items: [record] },
                    !record.flagged,
                  );
                  if (didChange && searchIndexReload) searchIndexReload();
                }}
              />
              {` | `}
              <ActionButton
                label={
                  record.excluded ? (
                    <>
                      <CheckCircleOutlined /> Include
                    </>
                  ) : (
                    <>
                      <StopOutlined /> Exclude
                    </>
                  )
                }
                onClick={async () => {
                  const didChange = await changeRecordExcludedStatus(
                    { type: 'list', count: 1, items: [record] },
                    !record.excluded,
                  );
                  if (didChange && searchIndexReload) searchIndexReload();
                }}
              />
            </FlexSpace>
          </>
        </>
      }
      panels={[
        !isOnboardingVariant ? replenishmentPanel : null,
        {
          settingKey: 'itemNotes',
          title: 'Item Notes',
          content: (
            <ItemNotesPanelContent
              uniqueItems={[{ itemId: record.item_uid, foreignId: itemId, name: record.item_name || '' }]}
            />
          ),
        },
        {
          settingKey: 'usage',
          title: 'Usage',
          content: (
            <InventoryUsagePanelContent
              itemId={itemId}
              itemUid={record.item_uid}
              locations={[location]}
              onForecastOverridesUpdated={searchIndexReload}
            />
          ),
        },
        {
          settingKey: 'locationAvailability',
          title: 'Location Availability',
          content: <LocationAvailabilityPanelContent itemUid={record.item_uid} />,
        },
        {
          settingKey: 'unitOfMeasures',
          title: 'Unit of Measures',
          content: <UnitOfMeasuresPanelContent itemId={itemId} />,
        },
        {
          settingKey: 'purchaseOrders',
          title: 'Purchase Orders',
          content: (
            <PurchaseOrderLinesPanelContent
              itemId={itemId}
              locationIds={[location.foreignId]}
              vendorId={vendor?.foreignId}
            />
          ),
        },
        {
          settingKey: 'openOrders',
          title: 'Open Orders',
          content: (
            <SalesOrderLinesPanelContext
              itemId={itemId}
              locationIds={[location.foreignId]}
              orderStatus={ErpOrderStatus.Open}
            />
          ),
        },
        {
          settingKey: 'salesHistory',
          title: 'Sales History',
          content: <SalesInvoiceLinesPanelContent itemId={itemId} locationIds={[location.foreignId]} />,
        },
        isUsingCustomLookaheadNetStock
          ? {
              settingKey: 'scheduledReleases',
              title: 'Open Scheduled Releases',
              content: <ScheduledReleaseStockPanelContent itemInfo={itemInfo} />,
            }
          : null,
        isPlanningHubAndSpoke
          ? {
              settingKey: 'replenishmentPath',
              title: 'Replenishment Path',
              content: (
                <ReplenishmentPathsPanelContent
                  itemId={itemId}
                  itemUid={record.item_uid}
                  locationId={location.foreignId}
                />
              ),
            }
          : null,
        {
          settingKey: 'fillRate',
          title: 'Fill Rate',
          content: <FillRatePanelContent itemId={itemId} locationId={location.foreignId} />,
        },
        {
          settingKey: 'uniqueCustomersAndHits',
          title: 'Unique Customers & Hits',
          tooltip: calculationUseImportedUsage
            ? undefined
            : // Only show this tooltip for tenants using Recurrency usage until we roll this definition out to all tenants
              `Hits are defined as a single usage line, not including overrides or inheritance from other items.${
                calculationUsageQuantitySource
                  ? ` Based on your usage settings, a hit is a single ${UsageSourceDisplayName[calculationUsageQuantitySource]} line.`
                  : ''
              } For more information, see the Usage Lines tab of the Usage panel.`,
          content: <UniqueCustomersOrdersPanelContent itemId={itemId} locationId={location.foreignId} />,
        },
        record.primary_supplier
          ? {
              settingKey: 'supplierNotes',
              title: 'Supplier Notes',
              content: <SupplierNotesPanelContent uniqueSuppliers={[record.primary_supplier]} />,
            }
          : null,
      ].filter(Boolean)}
    />
  );
}
