import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import { CalendarOutlined, ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { Modal, notification } from 'antd';
import moment from 'moment';

import { TaskStatus } from 'pages/tasks/types';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { TaskModal } from 'components/recipes/TaskModal';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import { EMPTY_VALUE_DASHES, formatDate, formatName } from 'utils/formatting';
import { routes, usePathParams, IdPathParams } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

const { confirm } = Modal;

export const TaskDetailsPage = () => {
  const { id } = usePathParams<IdPathParams>();
  const history = useHistory();

  const {
    data: task,
    isLoading,
    error,
    setData,
  } = useCoreApi(schemas.tasks.getTask, {
    pathParams: { taskId: id },
  });

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !task) {
    return <CenteredLoader />;
  }

  const headerSections: DetailPageSection[] = [
    {
      title: 'Customer',
      rows: [
        [
          task.metadata.customer
            ? {
                icon: <UserOutlined />,
                label: 'Customer',
                value: (
                  <Link to={routes.sales.customerDetails(task.metadata.customer.foreignId)}>
                    {task.metadata.customer.name}
                  </Link>
                ),
                id: task.metadata.customer.foreignId,
              }
            : null,
          task.metadata.contact
            ? {
                icon: <UserOutlined />,
                label: 'Contact',
                value: task.metadata.contact.name,
                id: task.metadata.contact.foreignId,
              }
            : null,
        ].filter(truthy),
      ],
    },
    {
      title: 'Details',
      rows: [
        [
          {
            icon: <CalendarOutlined />,
            label: 'Due Date',
            value: task.dueAt ? formatDate(task.dueAt) : EMPTY_VALUE_DASHES,
          },
          {
            icon: <CalendarOutlined />,
            label: 'Reminder Date',
            value: task.reminderAt ? formatDate(task.reminderAt) : EMPTY_VALUE_DASHES,
          },
          {
            icon: <UserOutlined />,
            label: 'Assigned To',
            value: `${task.assignee?.firstName} ${task.assignee?.lastName}`,
          },
          {
            icon: <UserOutlined />,
            label: 'Created By',
            value: `${task.user?.firstName} ${task.user?.lastName}`,
          },
        ],
      ],
    },
    {
      title: 'Comments',
      children: <div style={{ whiteSpace: 'pre-line' }}>{task.body}</div>,
    },
  ];

  const toggleTaskStatus = async (status: TaskStatus) => {
    try {
      const { data: updatedTask } = await coreApiFetch(schemas.tasks.updateTask, {
        pathParams: { taskId: id },
        bodyParams: {
          status: status === TaskStatus.Completed ? TaskStatus.New : TaskStatus.Completed,
        },
      });
      track(TrackEvent.Tasks_ToggleStatus, { taskId: id });
      notification.success({
        message: status === TaskStatus.Completed ? 'Task marked as incomplete.' : 'Task marked as completed.',
      });
      setData(updatedTask);
    } catch (err) {
      captureAndShowError(err, 'Error while updating task status');
    }
  };

  const deleteTask = async () => {
    try {
      await coreApiFetch(schemas.tasks.deleteTask, { pathParams: { taskId: id } });
      track(TrackEvent.Tasks_Delete, { taskId: id });
      notification.success({ message: 'Task deleted.' });
      history.push(routes.tasks.taskList());
    } catch (err) {
      captureAndShowError(err, 'Error while deleting task');
    }
  };

  const confirmDeleteTask = async () => {
    confirm({
      title: 'Are you sure you want to delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteTask();
      },
      onCancel() {},
    });
  };

  return (
    <Container>
      <PageHeader
        title={task.title}
        copyable
        entity={{
          kind: 'Task',
          badge: (
            <StatusBadge
              status={task.status !== TaskStatus.Completed ? BadgeStatus.Incomplete : BadgeStatus.Completed}
            />
          ),
        }}
        headerActions={
          <>
            <Button id="taskdetails_delete" key="3" onClick={confirmDeleteTask}>
              Delete
            </Button>
            <Button
              id="taskdetails_edit"
              key="2"
              onClick={async () => {
                const editedTask = await showAsyncModal(TaskModal, {
                  taskId: task.id,
                  initialValues: {
                    title: task.title,
                    status: task.status,
                    body: task.body,
                    dueAt: moment(task.dueAt),
                    reminderAt: task.reminderAt ? moment(task.reminderAt) : undefined,
                    customer: task.metadata.customer,
                    contact: task.metadata.contact,
                    assigneeUserId: task.assigneeUserId,
                    createdBy: formatName(task.user.firstName, task.user.lastName),
                  },
                });
                if (editedTask) {
                  setData(editedTask);
                }
              }}
            >
              Edit
            </Button>
            <Button id="taskdetails_complete" key="1" type="primary" onClick={() => toggleTaskStatus(task.status)}>
              {task.status === TaskStatus.Completed ? 'Mark Incomplete' : 'Complete'}
            </Button>
          </>
        }
      />
      <DetailPageSections sections={headerSections} />
    </Container>
  );
};
