import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

import * as Styled from 'pages/orders/quotes/QuoteEditFlowP21/QuoteLineItems/QuoteLineItems.style';

import { Button } from 'components/Button';
import { MiniTable } from 'components/MiniTable';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatUSD } from 'utils/formatting';
import { isTenantErpTypeSAPB1 } from 'utils/tenants';

import { PurchaseOrderEditHashState, PurchaseOrderLineItem } from 'types/hash-state';

import { getLineItemActions, getLineItemColumns, useFetchLineItemsContext } from './lineItemUtils';
import { getNumLineItems, getTotalPrice } from './utils';

export function PurchaseOrderEditLineItemsStep({
  docState,
  onDocStateChange,
}: {
  docState: PurchaseOrderEditHashState;
  onDocStateChange: (update: Partial<PurchaseOrderEditHashState>) => void;
}) {
  const [selectedRowIdx, setSelectedRowIdx] = useState<number>();
  const lineItems = docState.lineItems || [];
  const { activeTenant } = useGlobalApp();
  const isTenantSAPB1 = isTenantErpTypeSAPB1(activeTenant.erpType);

  if (lineItems.length === 0) {
    // add empty line item, if no line items
    requestAnimationFrame(() => onDocStateChange({ lineItems: [{ item: undefined }] }));
  }

  const lineItemsContext = useFetchLineItemsContext(lineItems, docState);
  const lineItemActions = getLineItemActions(docState, onDocStateChange);
  const lineItemColumns = docState.supplier
    ? getLineItemColumns({
        docState,
        selectedRowIdx,
        lineItems,
        lineItemActions,
        lineItemsContext,
        isTenantSAPB1,
      })
    : [];

  return (
    <Styled.Container>
      <Styled.ItemTable
        data={lineItems}
        columns={lineItemColumns}
        pagination={false}
        size="small"
        onRow={(_, rowIdx: number | undefined) => ({
          onClick: () => {
            setSelectedRowIdx(rowIdx);
          },
          onDoubleClick: () => {
            setSelectedRowIdx(rowIdx);
          },
        })}
      />
      <br />
      <Button block onClick={() => lineItemActions.addEmptyLineItem()}>
        <Styled.AddButtonContent>
          <PlusOutlined /> Add Line
        </Styled.AddButtonContent>
      </Button>
      <Styled.Footer>
        <Styled.FooterContent>
          <SummaryStats lineItems={lineItems} />
        </Styled.FooterContent>
      </Styled.Footer>
    </Styled.Container>
  );
}

export function SummaryStats({ lineItems }: { lineItems: PurchaseOrderLineItem[] }) {
  const summaryStats = [
    { name: 'Total Lines', value: getNumLineItems(lineItems) },
    { name: 'Total Price', value: formatUSD(getTotalPrice(lineItems), true) },
  ];

  return (
    <MiniTable
      numRowsShown={summaryStats.length}
      data={summaryStats}
      columns={[
        {
          render: (record) => `${record.name}:`,
          align: 'right',
        },
        {
          render: (record) => (
            <strong
              className={css`
                margin-left: 16px;
              `}
            >
              {record.value}
            </strong>
          ),
          align: 'right',
          maxWidth: '200px',
        },
      ]}
    />
  );
}
