import React from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import moment from 'moment';

import { FORECAST_COLOR, HISTORICAL_COLOR } from 'pages/purchasing/utils';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';
import { getUsageTableRowsFromForecastRecord, InventoryUsageTable } from 'components/recipes/InventoryUsageTable';
import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';
import { Sparkline } from 'components/Sparkline';
import { getMonthlyFilledSeries } from 'components/Sparkline/utils';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { showAsyncModal } from 'utils/asyncModal';
import { captureError } from 'utils/error';
import { formatIdNameObj, joinObjectId } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { searchIndex } from 'utils/search/search';

import { IdNameObj } from 'types/legacy-api';

import { getAncestorItemCodes, getMergedForecastRecords } from './sidePane.utils';
import { UsageInheritanceInfo } from './UsageInheritanceInfo';

export function InventoryUsagePanelContent({
  itemId,
  itemUid,
  locations,
  purchaseGroupName,
}: {
  itemId: string;
  itemUid: string;
  locations: IdNameObj[];
  purchaseGroupName?: string;
}) {
  const { activeTenant, activeUser } = useGlobalApp();
  const showUsageInheritance = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeatureItemUsageInheritance,
  );

  const {
    data: searchIndexResponse,
    isLoading,
    error,
  } = usePromise(
    () =>
      searchIndex<SearchForecastDTO>({
        indexName: SearchIndexName.Forecasts,
        filters: { id: locations.map((l) => joinObjectId([itemUid, l.foreignId])) },
      }),
    [itemId, locations],
    {
      cacheKey: `search:${JSON.stringify([SearchIndexName.Forecasts, itemId, ...locations.map((l) => l.foreignId)])}`,
      onError: captureError,
    },
  );

  // Filter for exact hits before summing due to typesense special character strangeness
  // More context: https://recurrency.atlassian.net/browse/PE-3064
  const exactHits = searchIndexResponse?.hits?.filter((hit) => hit.item_id === itemId);
  const forecastRecord = getMergedForecastRecords(exactHits);
  const usageTableRows = getUsageTableRowsFromForecastRecord(forecastRecord);
  const ancestorData = getAncestorItemCodes(exactHits);

  return (
    <>
      {forecastRecord && usageTableRows.length > 0 && !isLoading ? (
        <div>
          {showUsageInheritance && (
            <div
              className={css`
                margin-bottom: 16px;
              `}
            >
              <UsageInheritanceInfo processedAncestorData={ancestorData ?? []} itemUid={itemUid} itemId={itemId} />
            </div>
          )}
          <Sparkline
            className={css`
              margin-bottom: 12px;
            `}
            height={32}
            series={[
              ...getMonthlyFilledSeries({
                data: usageTableRows,
                dateField: 'date',
                valueField: 'usage',
                numPrevYears: 2,
                // usage data is calculated at end of month, so current month is always 0, we show forecast instead
                curMonthOffset: -1,
                title: 'Qty Used',
                formatFn: (value: number) => `${value} ${forecastRecord.unit_of_measure}`,
                color: HISTORICAL_COLOR,
              }),
              {
                data: forecastRecord.forecast_dates.slice(0, 3 /* 3 month forecast */).map((_, i) => ({
                  date: forecastRecord.forecast_dates[i],
                  value: forecastRecord.forecast_demand[i] ?? 0,
                  dotted: true,
                })),
                title: 'Qty Forecasted',
                formatFn: (value: number) => `${value} ${forecastRecord.unit_of_measure}`,
                color: FORECAST_COLOR,
              },
            ]}
          />
        </div>
      ) : null}
      <MiniTable
        columns={[
          { render: (record) => moment(record.date).format('YYYY MMMM') },
          { render: (record) => `${record.usage} ${record.unitOfMeasure || ``}`, align: 'right' },
        ]}
        data={usageTableRows}
        isLoading={isLoading}
        error={error}
        numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
        onMoreButtonClick={() => {
          showAsyncModal(ViewAllModal, {
            title: (
              <ConditionalWrapper
                condition={!!purchaseGroupName}
                wrapper={(children) => (
                  <InfoTooltip useInfoIcon title={[locations?.map((sl, i) => <div key={i}>{sl.name}</div>)]}>
                    {children}
                  </InfoTooltip>
                )}
              >
                <>
                  Usage for Item #{itemId} at Location {purchaseGroupName ?? formatIdNameObj(locations[0])}
                </>
              </ConditionalWrapper>
            ),
            content: (
              <InventoryUsageTable
                exactHits={exactHits}
                itemUid={itemUid}
                locations={locations}
                isLoading={isLoading}
              />
            ),
          });
        }}
      />
    </>
  );
}
