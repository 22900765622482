import React from 'react';

import { Link } from 'react-router-dom';

import { AppstoreOutlined } from '@ant-design/icons';
import { IntegratedErps } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { formatPercent, formatUSD, getChangeColor, splitIfIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import { changeDollarColumn, dollarColumn, numberColumn } from 'utils/tables';

import { SearchIndexVendor } from 'types/search-collections';

export const VendorListPage = () => {
  const { activeTenant } = useGlobalApp();
  const columns: (ColumnType<SearchIndexVendor> | null)[] = [
    {
      title: 'ID',
      dataIndex: 'vendor_id',
      render: (id: string, record: SearchIndexVendor) => (
        <Link to={routes.purchasing.vendorDetails(id, { companyId: record.company_id })}>{id}</Link>
      ),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
    },
    activeTenant.erpType === IntegratedErps.P21
      ? {
          title: 'Company',
          dataIndex: 'company',
          render: (company: string) => splitIfIdNameStr(company)?.name,
        }
      : null,
    dollarColumn({
      title: 'Purchases YTD',
      dataIndex: 'purchases_ytd',
    }),
    dollarColumn({
      title: 'Purchases LYTD',
      dataIndex: 'purchases_lytd',
    }),
    changeDollarColumn({
      title: '∆ Purchases',
      dataIndex: 'purchases_delta_raw',
      align: 'right' as const,
      render: (amt: string) => <span style={{ color: getChangeColor(amt) }}>{formatUSD(amt)}</span>,
    }),
    numberColumn({
      title: '∆% Purchases',
      dataIndex: 'purchases_delta_pct',
      render: (pct: string) => {
        const formatted = formatPercent(pct, 0);
        return <span style={{ color: getChangeColor(formatted) }}>{formatted}</span>;
      },
    }),
    {
      title: '',
      dataIndex: 'vendor_id',
      render: (id: string, record: SearchIndexVendor) =>
        ButtonLink(routes.purchasing.vendorDetails(id, { companyId: record.company_id })),
    },
  ];

  const valueFacets: (ValueFacet<SearchIndexVendor> | null)[] = [
    {
      title: 'Product Group',
      field: 'product_group_desc',
      queryPlaceholder: 'Search product groups',
      icon: <AppstoreOutlined />,
    },
  ];

  return (
    <SearchFrame<SearchIndexVendor>
      title="Vendors"
      indexName={SearchIndexName.Vendors}
      queryPlaceholder="Search vendors by ID or name"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
    />
  );
};
