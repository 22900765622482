import React, { useState } from 'react';

import { Redirect } from 'react-router';

import { RecurrencyRole } from '@recurrency/core-api-schema/dist/common/enums';
import { notification, Typography } from 'antd';

import { Button } from 'components/Button';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { captureAndShowError } from 'utils/error';
import { isRecurrencyRole } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { zendeskAnonymousRequest } from 'utils/zendesk';

export const InviteTeammateModal = ({ onClose }: { onClose: () => void }) => {
  const { activeUser, activeTenant } = useGlobalApp();

  const [teammateEmail, setTeammateEmail] = useState('');

  const closeInviteModal = () => {
    setTeammateEmail('');
    onClose();
  };

  const onInviteTeammateSubmit = async () => {
    try {
      await zendeskAnonymousRequest({
        requesterName: activeUser.fullName,
        requesterEmail: activeUser.email,
        subject: `Please invite ${teammateEmail}`,
        body: `I'd like to invite my teammate: ${teammateEmail}`,
      });
      notification.success({ message: `Invited ${teammateEmail}.` });
    } catch (err) {
      captureAndShowError(err, `Error while inviting teammate`);
    }
    closeInviteModal();
  };

  // Redirect users with permissions to self-serve user management to do it themselves
  if (isRecurrencyRole(activeTenant, RecurrencyRole.TenantAdmin)) {
    return <Redirect to={routes.settings.users()} />;
  }

  return (
    <Modal
      visible
      title="Invite a Teammate"
      onCancel={closeInviteModal}
      footer={
        <div>
          <Button onClick={closeInviteModal}>Cancel</Button>
          <AsyncButton type="primary" onClick={onInviteTeammateSubmit}>
            Invite
          </AsyncButton>
        </div>
      }
      centered
    >
      <Typography>Work with anyone else who would find Recurrency useful?</Typography>
      <br />
      <Typography>
        Enter their email below, and we'll send them an invite. Recurrency never charges for seat licenses, so it's
        completely free.
      </Typography>
      <br />
      <Typography>Thank you for helping make someone's life a little easier!</Typography>
      <br />
      <Input
        name="inviteForm"
        placeholder="Enter teammate's email"
        onChange={(ev) => setTeammateEmail(ev.target.value)}
        size="large"
      />
    </Modal>
  );
};
