import React from 'react';

import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';

import { InventoryUsagePanelContent } from 'components/recipes/sidePane/InventoryUsagePanelContent';
import { LabelValueProperties, LabelValueProperty } from 'components/recipes/sidePane/LabelValueProperties';
import { LocationAvailabilityPanelContent } from 'components/recipes/sidePane/LocationAvailabilityPanelContent';
import { PurchaseOrderLinesPanelContent } from 'components/recipes/sidePane/PurchaseOrderLinesPanelContent';
import { SalesInvoiceLinesPanelContent } from 'components/recipes/sidePane/SalesInvoiceLinesPanelContent';
import { SalesOrderLinesPanelContext } from 'components/recipes/sidePane/SalesOrderLinesPanelContext';
import { SidePane } from 'components/recipes/sidePane/SidePane';
import { UnitOfMeasuresPanelContent } from 'components/recipes/sidePane/UnitOfMeasuresPanelContent';

import { splitIdNameStr } from 'utils/formatting';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

export function ForecastingSidePane({ record }: { record: SearchForecastDTO | null }) {
  if (!record) {
    return <SidePane title="" content={<div>Please select a table row</div>} />;
  }

  const { foreignId: locationId, name: locationName } = splitIdNameStr(record.location);

  const infoProps: Array<LabelValueProperty | null> = [
    record.short_code ? { label: 'Short Code', value: record.short_code } : null,
    record.primary_supplier ? { label: 'Primary Supplier', value: record.primary_supplier } : null,
  ];

  return (
    <SidePane
      title={`Item #${record.item_id} at ${record.location}`}
      settingKey={ViewSettingKey.ForecastingSidePane}
      description={record.item_name}
      extendedDescription={record.item_desc}
      content={<LabelValueProperties properties={infoProps} />}
      panels={[
        {
          settingKey: 'usage',
          title: 'Usage',
          content: (
            <InventoryUsagePanelContent
              itemId={record.item_id}
              itemUid={record.item_uid}
              locations={[{ foreignId: locationId, name: locationName }]}
            />
          ),
        },
        {
          settingKey: 'locationAvailability',
          title: 'Location Availability',
          content: <LocationAvailabilityPanelContent itemUid={record.item_uid} />,
        },
        {
          settingKey: 'unitOfMeasure',
          title: 'Unit of Measures',
          content: <UnitOfMeasuresPanelContent itemId={record.item_id} />,
        },
        {
          settingKey: 'purchaseOrders',
          title: 'Purchase Orders',
          content: (
            <PurchaseOrderLinesPanelContent
              itemId={record.item_id}
              companyId={record.company_id}
              vendorId={record.primary_vendor ? splitIdNameStr(record.primary_vendor).foreignId : undefined}
              locationIds={[locationId]}
            />
          ),
        },
        {
          settingKey: 'salesOrders',
          title: 'Sales Orders',
          content: (
            <SalesOrderLinesPanelContext
              itemId={record.item_id}
              locationIds={[locationId]}
              companyId={record.company_id}
            />
          ),
        },
        {
          settingKey: 'salesHistory',
          title: 'Sales History',
          content: (
            <SalesInvoiceLinesPanelContent
              itemId={record.item_id}
              locationIds={[locationId]}
              companyId={record.company_id}
            />
          ),
        },
      ]}
    />
  );
}
