import { SalesQuoteDraft } from '@recurrency/core-api-schema/dist/salesQuoteDrafts/common';

import { QuoteLineItemP21 } from 'types/hash-state';

export enum QuoteStatusType {
  Submitted = 'submitted',
  Draft = 'draft',
}

export enum Disposition {
  BackOrder = 'B',
  DirectShip = 'D',
  Special = 'S',
  Hold = 'H',
  Cancel = 'C',
  Transfer = 'T',
}

// need to omit functions from model to appease tsc
export interface QuoteWithTotalInfo extends Omit<SalesQuoteDraft, 'isValidToSubmit' | 'toObject' | 'toJSON'> {
  totalLines: number;
  totalPrice: number;
}

export interface LineItemActionsP21 {
  updateLineItem: (index: number, update: Partial<QuoteLineItemP21>, jobNumber?: string) => void;
  setLineItem: (index: number, update: QuoteLineItemP21) => void;
  addEmptyLineItem: () => void;
  addLineItem: (item: QuoteLineItemP21) => void;
  addLineItems: (items: QuoteLineItemP21[]) => void;
  deleteLineItem: (index: number) => void;
  duplicateLineItem: (index: number) => void;
  moveLineItemUp: (index: number) => void;
  moveLineItemDown: (index: number) => void;
}
